<div class="create-note-modal" *transloco="let t">
  <div class="modal-header">
    <h5 class="modal-title">{{ modalData?.action }}</h5>
    <button type="button" class="close" (click)="close()">
      <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="noteForm" class="position-relative">
      <textarea
        id="title"
        t-id="note-title"
        formControlName="title"
        [placeholder]="t('modals.note.title-placeholder')"
        required
        class="form-control title-control"
        [class.title-control--light]="config?.layout.variant === 'Light'"
        [class.title-control--dark]="config?.layout.variant === 'Dark'"
        [formControl]="noteForm.controls.title"
        [appFocus]="!modalData?.note?.title && platform === 'web'"
        [attr.data-tabindex]="tabIndexes[0]?.tabIndex"
        (keydown.enter)="$event.preventDefault()"
      ></textarea>

      <ng-select
        t-id="note-create-labels"
        class="notes-labels"
        [placeholder]="t('modals.note.labels-placeholder')"
        bindLabel="label"
        bindValue="_id"
        [multiple]="true"
        [items]="labels"
        [addTag]="addLabelPromise"
        [loading]="loading"
        [formControl]="noteForm.controls.labels"
        [(ngModel)]="selectedLabels"
      >
        <ng-template ng-tag-tmp let-search="searchTerm">
          <b>{{ t('modals.note.btn-add-new') }}</b
          >: {{ search }}
        </ng-template>
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          {{ item.label }}
          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
        </ng-template>
      </ng-select>

      <quill-editor
        t-id="note-content"
        class="content-editor mt-1"
        bounds="self"
        [class.ql-editor-mobile]="platform !== 'web'"
        [modules]="editorModules"
        [placeholder]="t('modals.note.type-content-here-placeholder')"
        [formControl]="noteForm.controls.content"
        [attr.data-tabindex]="tabIndexes[1]?.tabIndex"
        (onEditorCreated)="editorDescriptionCreated($event)"
        (click)="handleClickInEditor($event)"
      ></quill-editor>
    </form>
  </div>

  <div class="modal-footer">
    <!-- CONVERSION BUTTON -->
    <span
      *ngIf="modalData?.action === 'Edit Note' && !modalData?.note.linkObject"
      class="dropdown convert-note-dropdown"
      ngbDropdown
      placement="top"
      [autoClose]="true"
      (openChange)="convertDropdownToggle($event)"
    >
      <button
        t-id="note-convert-into"
        ngbDropdownToggle
        data-toggle="dropdown"
        class="dropdown-toggle btn btn-sm svg-icon convert-note"
        triggers="hover"
        [ngbTooltip]="t('modals.note.convert-into-placeholder')"
        [disableTooltip]="convertDropdownOpen || platform !== 'web'"
      >
        <app-svg name="arrow-right-arrow-left" width="18" height="16"></app-svg>
      </button>
      <span ngbDropdownMenu class="dropdown-menu m-0 pb-0">
        <a
          *ngFor="let item of convertIntoList"
          href="javascript:;"
          class="dropdown-item d-flex align-items-center"
          (click)="
            convertNote(
              item.action,
              modalData?.note._id,
              modalData?.note.title,
              modalData?.note.text
            )
          "
        >
          <app-svg name="{{ item.icon }}" width="16" height="16"></app-svg>
          <span t-id="note-convert-into-list-options" class="ml-2">{{ item.title }}</span>
        </a>
      </span>
    </span>

    <!-- DELETE NOTE BUTTON -->
    <app-svg
      *ngIf="modalData?.action === 'Edit Note'"
      t-id="note-delete-actions"
      class="delete-note svg-icon"
      name="trash"
      width="14"
      height="16"
      triggers="hover"
      [ngbTooltip]="t('modals.note.delete-note-placeholder')"
      (click)="deleteNote(modalData?.note._id)"
    ></app-svg>

    <button t-id="note-cancel-action" type="button" class="btn btn-subtle" (click)="close()">
      {{ t('common.btn-cancel') }}
    </button>

    <button
      t-id="note-save-action"
      type="button"
      class="btn btn-solid"
      (click)="formSubmit()"
      [disabled]="isNoteCreating"
    >
      {{ t('common.btn-save') }}
      <i *ngIf="isNoteCreating" class="fa fa-spinner" aria-hidden="true"></i>
    </button>
  </div>
</div>

<!-- image full-size preview modal -->
<ng-template #dataRoomImagePreviewModal let-c="close" let-d="dismiss">
  <app-chat-image-preview
    #imagePreviewComponent
    [platform]="platform"
    [isNote]="true"
    [tz]="currTzAbbr"
    [object]="modalData.object"
    [objectId]="modalData.objectId"
    [imagePreviewMessage]="fullImagePreviewService.imagePreview"
    [isLastPreviewImage]="fullImagePreviewService.isLastPreviewImage"
    [isFirstPreviewImage]="fullImagePreviewService.isFirstPreviewImage"
    [updateEvent]="fullImagePreviewService.updateEvent"
    [isOpenNotFromTheChat]="true"
    (closePreviewModal)="previewModal.close()"
    (goToPreviousImage)="fullImagePreviewService.goToPreviousImage()"
    (goToNextImage)="fullImagePreviewService.goToNextImage()"
    (downloadFile)="fullImagePreviewService.downloadFile()"
  ></app-chat-image-preview>
</ng-template>
