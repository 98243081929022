<div *ngIf="message.showDetails" class="message-details py-1">
  <span
    class="message-username"
    [ngClass]="{
      'message-username-light': config?.layout.variant === 'Light',
      'message-username-dark': config?.layout.variant === 'Dark'
    }"
  >
    <ng-container *ngIf="message.userId | userNameAlone | async as user">
      <ng-container *ngIf="user.loading; else showUserName">
        <div role="status" class="max-w-sm animate-pulse">
          <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-24"></div>
        </div>
      </ng-container>
      <ng-template #showUserName>
        {{ user.userName }}
        @if (user.state && user.state !== null) {
          @if (user.state_end) {
            <span
              [appTmpTooltip]="user.state_end | date: 'dd/MM/yyyy HH:mm'"
              class="cursor-pointer"
            >
              @if (user.state_icon) {
                {{ user.state_icon }}
              } @else {
                {{ 'user.state.' + user.state + '.emoji' | transloco }}
              }
            </span>
          } @else {
            @if (user.state_icon) {
              {{ user.state_icon }}
            } @else {
              {{ 'user.state.' + user.state + '.emoji' | transloco }}
            }
          }
        }
      </ng-template>
    </ng-container>
  </span>
  <span
    *ngIf="message.created_at !== ''"
    class="message-date ml-1"
    [ngClass]="{
      'message-date-light': config?.layout.variant === 'Light',
      'message-date-dark': config?.layout.variant === 'Dark'
    }"
  >
    {{ message.created_at | date: 'dd.MM.yy - HH:mm' }}
  </span>
</div>
