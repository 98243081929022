import { TicketsDbDto } from '../../api/models/tickets-db-dto';
import { UsersPublicFieldsResDto } from '../../api/models/users-public-fields-res-dto';
import { TicketsFieldsValuesReqDto } from '../../api/models/tickets-fields-values-req-dto';
import { EstimationSessionsMembersDbDto } from '../../api/models/estimation-sessions-members-db-dto';
import { UsersDbDto } from '../../api/models/users-db-dto';
import { TicketsDraftsResDto } from '../../api/models/tickets-drafts-res-dto';

export enum Tabs {
  comments = 'comments',
  history = 'history',
}

export interface TicketData {
  id: string;
  object: 'spaces' | 'projects' | 'general';
  objectId: string;
  isEpic: boolean;
  boardColorClass: string;
  platform: string;
  title: string;
  description: string;
  boardAbbreviation: string;
  counter: number;
  parentId: string;
  epicId: string;
  data: Partial<TicketsDbDto>;
  users: UsersPublicFieldsResDto[];
  fileData: any[];
  selectedMembersIds: string[];
  ticketsMembers: any[];
  start?: string;
  end: string;
  estimate: any;
  ticketCreator: string;
  ticketUpdatedBy: string;
  isBlocked: boolean;
  chatMessageId?: string;
  chatType?: string;
  noteId?: string;
  showToastMessage?: boolean;
  lastTicketAbbr?: any;
  sprintId?: string;
  ticket?: any;
  tickets?: any;
  childrenList?: any[];
  subTicketsEpic: any[];
  ticketCreatedFromRecord?: boolean;
  allDay: true;
  isCopyTicket?: boolean;
  isNeedToSelectObject?: boolean;
  isExpand?: boolean;
  draft?: TicketsDraftsResDto;
  userBoardTicketOrder?: number;
  isConvert?: boolean;
  startDate?: string;
  StartDateReminder?: string;
  StartDateReminderState?: boolean;
}

export interface ITicketForm {
  objectId: string;
  title: string;
  description: string;
  columnId: string;
  parentId: string;
  epicId: string;
  selectedSubtask: null;
  estimateValue: null;
  estimateUnit: null;
  dueDate: null;
  startDate: null;
  startDateReminder: null;
  // releaseDate: [item.releaseDate || ''],
  releaseVersion: string;
  labels: [];
  realTime: string;
  ticketMembers: [];
  files: File;
  isBlocked: false;
  bgColor: string;
  boardAbbreviation: string;
  counter: number;
  customFields: Array<TicketsFieldsValuesReqDto>;
  priority: number;
}

export interface EstimateSessionMembers extends EstimationSessionsMembersDbDto {
  user?: UsersDbDto;
}
