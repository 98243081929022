import { Component, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Actions, Store } from '@ngxs/store';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { QuillModulesForChat, QuillModulesForDescription } from '../../../data/quill-configuration';
import { QuillInitializeService } from '../../../services/quill/quill-init.service';
import { SocketsService } from '../../../services/sockets.service';
import { ConfigService } from '../../../services/config.service';
import { AuthState } from '../../../store/states/auth.state';
import { UsersState } from '../../../store/states/users.state';
import { ChatsState } from '../../../store/states/chats.state';
import { ChatsMessageCreate } from '../../../store/actions/chats.action';
import { ChatMessageContentComponent } from '../chat-message-content/chat-message-content.component';
import { AvatarComponent } from '../../../../standalone/components/avatar/avatar.component';
import { QuillEditorComponent } from 'ngx-quill';
import { FormsModule } from '@angular/forms';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgClass, NgIf, DatePipe } from '@angular/common';
import { SvgComponent } from '../../../svgs/svg/svg.component';
import { MixpanelService } from '../../../../plugins/mixpanel/mixpanel.service';

@Component({
  selector: 'app-chat-forward-modal',
  templateUrl: './chat-forward-modal.component.html',
  styleUrls: ['./chat-forward-modal.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    SvgComponent,
    NgClass,
    AutocompleteLibModule,
    FormsModule,
    QuillEditorComponent,
    forwardRef(() => AvatarComponent),
    NgIf,
    ChatMessageContentComponent,
    DatePipe,
  ],
})
export class ChatForwardModalComponent implements OnInit, OnDestroy {
  @Input() platform = 'web';
  @Input() tz = null;

  config: any;
  editorModules: any;
  message;
  mentionChatMembers: string[];
  searchKeyword = 'userName';
  comment = '';
  membersChat: Array<any>;
  destroy$: Subject<void> = new Subject<void>();
  selectedRecipient: any;

  constructor(
    private actions: Actions,
    private activeModal: NgbActiveModal,
    private store: Store,
    private quillInitializeService: QuillInitializeService,
    private socketsService: SocketsService,
    private configService: ConfigService,
    private toastr: ToastrService,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit(): void {
    this.getPlatform();
    this.getUserInfo();
    this.setEditor();
  }

  close() {
    MixpanelService.trackEvent('Chat Forward: Close');
    this.comment = '';
    this.selectedRecipient = undefined;
    this.activeModal.close();
  }

  sendMessage() {
    MixpanelService.trackEvent('Chat Forward: Send Message');
    const socket = this.socketsService.get();

    if (typeof this.selectedRecipient === 'object' && this.selectedRecipient !== null) {
      const selectedRecipientChat = this.store
        .selectSnapshot(ChatsState.getChats)
        .find(
          (item) => item.type === 'direct' && item.chatName === this.selectedRecipient.userName,
        );

      if (selectedRecipientChat?._id) {
        this.store.dispatch(
          new ChatsMessageCreate({
            chatId: selectedRecipientChat._id,
            sharedMessageId: this.message._id,
            text: this.comment.trim(),
          }),
        );

        this.close();
      } else {
        this.toastr.error(
          this.translocoService.translate('toastr.create-chat-with-recipient-first'),
          this.translocoService.translate('toastr.title-error'),
        );
      }
    } else {
      this.toastr.error(
        this.translocoService.translate('toastr.choose-chat-member'),
        this.translocoService.translate('toastr.title-error'),
      );
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getPlatform(): void {
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);
  }

  getUserInfo(): void {
    this.store
      .select(UsersState.getUsersInfo)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        const userId = this.store.selectSnapshot(AuthState.getUser)._id;
        const keys = Object.keys(result).filter((key) => key !== userId);
        this.membersChat = keys.map((key) => Object.assign({}, result[key], { _id: key }));
      });
  }

  setEditor(): void {
    this.editorModules = {
      ...(this.platform === 'web' ? QuillModulesForDescription : QuillModulesForChat),
      magicUrl: true,
    };
  }

  editorCreated(editor: any) {
    QuillInitializeService.handleEditorCreated(editor);
  }
}
