import { Component, ChangeDetectorRef, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ConfigService } from '../../services/config.service';
import { CheckPermissionPipe } from '../../pipes/check-permission.pipe';
import { UserBoardComponent } from '../board/user-board/user-board.component';
import { CalendarComponent } from '../calendar/calendar.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  imports: [NgIf, CalendarComponent, UserBoardComponent, CheckPermissionPipe],
})
export class DashboardComponent implements OnChanges, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  config: any = {};
  scrollConfigX = {
    suppressScrollX: false,
    suppressScrollY: true,
    wheelPropagation: true,
  };

  constructor(
    private configService: ConfigService,
    public cdr: ChangeDetectorRef,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
        this.cdr.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
