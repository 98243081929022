import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigService, ITemplateConfig } from '../../shared/services/config.service';
import { Subject } from 'rxjs';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CDK_DRAG_CONFIG,
  CdkDragDrop,
  moveItemInArray,
  CdkDropList,
  CdkDrag,
} from '@angular/cdk/drag-drop';
import { takeUntil } from 'rxjs/operators';
import { reminderTime } from './const/reminderTime';
import {
  ChatsClearMessageDraft,
  ChatsGet,
  ChatsMessageCreate,
  ChatsNewMessageToUpload,
  ChatsSocketNewMessage,
} from '../../shared/store/actions/chats.action';
import { Store } from '@ngxs/store';
import moment from 'moment-timezone';
import { SocketsService } from '../../shared/services/sockets.service';
import { UsersPublicFieldsResDto } from '../../api/models/users-public-fields-res-dto';
import { NgSelectModule } from '@ng-select/ng-select';
import { DateTimePickerComponent } from '../../shared/components/date-time-picker/date-time-picker.component';
import { SvgComponent } from '../../shared/svgs/svg/svg.component';
import { NgFor, NgIf } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';
import { TranslocoDirective } from '@ngneat/transloco';

export interface PollData {
  object: string;
  objectId: string;
  chatId: string;
  currentUser: UsersPublicFieldsResDto;
}

const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000,
};

@Component({
  selector: 'app-poll-modal',
  templateUrl: './poll-modal.component.html',
  styleUrls: ['./poll-modal.component.scss'],
  providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgScrollbar,
    FormsModule,
    ReactiveFormsModule,
    CdkDropList,
    NgFor,
    CdkDrag,
    SvgComponent,
    NgIf,
    DateTimePickerComponent,
    NgSelectModule,
  ],
})
export class PollModalComponent implements OnInit, OnDestroy {
  platform = 'web';
  answers = [];
  isValidDate = true;
  config: ITemplateConfig;
  pollData: PollData;
  destroy$: Subject<void> = new Subject<void>();
  currentOption = new FormControl();
  isLoading = false;
  object: string;
  objectId: string;
  chatId: string;
  currentUser: UsersPublicFieldsResDto;
  reminderData = reminderTime;

  pollForm: FormGroup = this.formBuilder.group({
    question: ['', Validators.required],
    answers: ['', Validators.required],
    anonymous: [false],
    multiChoice: [false],
    showResult: [false],
    deadline: [false],
    reminder: [false],
    dueDate: [''],
    reminderData: [],
  });

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private configService: ConfigService,
    protected store: Store,
    protected socketsService: SocketsService,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit(): void {
    this.initializeData(this.pollData);
    this.formListener();
  }

  initializeData(data: PollData) {
    this.object = data.object;
    this.objectId = data.objectId;
    this.chatId = data.chatId;
    this.currentUser = data.currentUser;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // * Getters *

  public get checkValidate(): boolean {
    return (
      (this.currentOption.value && this.currentOption.value.trim() === '') ||
      this.currentOption.value === null
    );
  }

  public get isDeadline(): boolean {
    return this.pollForm.controls.deadline.value;
  }

  public get isReminder(): boolean {
    return this.pollForm.controls.reminder.value;
  }

  public get isOptionValid(): boolean {
    return this.pollForm.controls.answers.value.length > 1;
  }
  // * Methods *

  public submitForm() {
    const result = this.pollForm.value;

    const socket = this.socketsService.get();
    const time = new Date();

    const data: any = {
      title: result.question,
      options: result.answers,
      isAnonymous: result.anonymous,
      isMultiChoice: result.multiChoice,
      isShowResultsAfter: result.showResult,
    };

    if (!this.isValidDate) {
      return;
    }

    if (this.isDeadline) {
      data.dueDate = result.dueDate;
    }

    if (this.isReminder && this.pollForm.controls.reminderData.value) {
      const notifyDate = moment(result.dueDate)
        .subtract(result.reminderData[0], result.reminderData[1])
        .toDate();
      data.notifyDate = notifyDate;
    }

    this.isLoading = true;

    const message = {
      chatId: this.chatId,
      text: '',
      type: 'general',
      poll: data,
      userId: this.currentUser._id,
      userName: this.currentUser.userName,
      created_at: time.toISOString(),
      timestamp: time.getTime(),
      isUploading: true,
    };
    this.store
      .dispatch([
        new ChatsNewMessageToUpload({ message }),
        new ChatsSocketNewMessage({ message }),
        new ChatsClearMessageDraft({ chatId: this.chatId }),
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.isLoading = false;
          this.closeModal();
        },
        () => {
          this.isLoading = false;
        },
      );

    this.store.dispatch(new ChatsMessageCreate(message));

    if (this.platform !== 'web') {
      this.store.dispatch(new ChatsGet());
    }
  }
  public formListener() {
    this.pollForm.controls.deadline.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.isDeadline) {
        this.pollForm.get('dueDate').setValidators(Validators.required);
      } else {
        this.pollForm.get('dueDate').clearValidators();
      }

      this.pollForm.get('dueDate').updateValueAndValidity();
    });

    this.pollForm.controls.dueDate.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      if (!val) {
        return;
      }

      this.isValidDate = !moment(val).isSameOrBefore(moment());
    });
  }

  public pushOption(): void {
    this.answers = [...this.answers, this.currentOption.value];
    this.pollForm.get('answers').setValue(this.answers);
    this.currentOption.setValue(null);
  }

  public delete(item): void {
    const index = this.answers.indexOf(item);
    if (index !== -1) {
      this.answers.splice(index, 1);
      if (!this.answers.length) {
        this.pollForm.get('answers').setValue(null);
      }
    }
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  public dragDropOption(event: CdkDragDrop<any[]>): void {
    const arrayForSort = [...this.answers];
    moveItemInArray(arrayForSort, event.previousIndex, event.currentIndex);
    this.answers = [...arrayForSort];
    this.pollForm.get('answers').setValue(arrayForSort);
  }
}
