<ng-container *transloco="let t; read: 'modals.board-ticket'">
  <div
    appContainerRef
    *ngIf="modalData?.data"
    t-id="board-ticket-create-modal-view"
    class="board-modal"
    [class.minimized]="isMinimized"
    [ngClass]="{
      'board-modal-mobile': platform !== 'web',
      dark: config.layout.variant === 'Dark'
    }"
    appDragDrop
    [component]="'Tickets'"
    (draggingFile)="setIsDraggingFile(true)"
  >
    <div class="modal-header" *ngIf="!isMinimized; else minimizeHeader">
      <div class="close close--mobile" aria-label="Close" (click)="close()">
        <app-svg name="arrow-right-arrow-left" width="18" height="16"></app-svg>
      </div>

      <ng-template [ngTemplateOutlet]="headerTitle"></ng-template>

      <div class="actions">
        <div
          *ngIf="modalData?.id"
          class="pin ml-2"
          aria-label="Pin"
          [ngClass]="{
            'pinned-icon-light': config?.layout.variant === 'Light',
            'pinned-icon-dark': config?.layout.variant === 'Dark'
          }"
        >
          <ng-container *ngIf="!isLoadingPin; else isLoadingTicket">
            <svg-icon
              *ngIf="isLimitPins && !isPinned; else pinTicket"
              (click)="pin()"
              class="pin-icon-modal"
              src="assets/icons/pin/pin-modal.svg"
              [applyClass]="true"
            ></svg-icon>
            <ng-template #pinTicket>
              <svg-icon
                *ngIf="isPinned && canUnpinTicket"
                (click)="unPin()"
                class="unpin-icon-modal"
                src="assets/icons/pin/unpin-message.svg"
                [applyClass]="true"
              ></svg-icon>
            </ng-template>
          </ng-container>
          <ng-template #isLoadingTicket>
            <i class="fa fa-spinner fa-spin connection_spinner" aria-hidden="true"></i>
          </ng-template>
        </div>
        <div *ngIf="platform === 'web'" class="actions-ticket-hide svg-icon" (click)="toDraft()">
          <svg-icon
            src="assets/icons/common/hide.svg"
            [svgStyle]="{ 'width.px': '14', 'height.px': '16' }"
          ></svg-icon>
        </div>
        <div
          *ngIf="platform === 'web'"
          class="actions-ticket-minimize svg-icon"
          (click)="minimizeTicket()"
        >
          <svg-icon
            src="assets/icons/common/minimize.svg"
            [svgStyle]="{ 'width.px': '16', 'height.px': '16' }"
          ></svg-icon>
        </div>
        <div class="actions-ticket-close svg-icon" aria-label="Close" (click)="close()">
          <svg-icon
            src="assets/icons/common/close.svg"
            [svgStyle]="{ 'width.px': '11', 'height.px': '11' }"
            aria-hidden="true"
          ></svg-icon>
        </div>
      </div>
    </div>
    <ng-template #minimizeHeader>
      <div class="modal-header-minimize">
        <ng-template [ngTemplateOutlet]="headerTitle"></ng-template>

        <div class="actions">
          <div *ngIf="platform === 'web'" class="actions-ticket-hide svg-icon" (click)="toDraft()">
            <svg-icon
              src="assets/icons/common/hide.svg"
              [svgStyle]="{ 'width.px': '14', 'height.px': '16' }"
            ></svg-icon>
          </div>
          <div class="actions-ticket-minimize svg-icon" (click)="expandTicket()">
            <svg-icon
              src="assets/icons/common/expand.svg"
              [svgStyle]="{ 'width.px': '16', 'height.px': '16' }"
            ></svg-icon>
          </div>
          <div class="actions-ticket-close svg-icon" aria-label="Close" (click)="close()">
            <svg-icon
              src="assets/icons/common/close.svg"
              [svgStyle]="{ 'width.px': '11', 'height.px': '11' }"
              aria-hidden="true"
            ></svg-icon>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #headerTitle>
      <h5 class="modal-title m-0">
        <span class="modal-title-ctr d-flex item-center">
          <div class="breadcrumb-title">
            <ng-container *ngTemplateOutlet="breadcrumbs"> </ng-container>
          </div>
          <div class="d-flex item-center ticket-title">
            {{ pageTitle }}
            <span *ngIf="!isCopyTicket && modalData.data?.boardAbbreviation" class="d-flex ml-2">
              <span
                *ngIf="modalData.data?.parentId && parentTask?.boardAbbreviation"
                class="d-flex flex-row align-items-center"
              >
                <svg-icon
                  src="assets/icons/ticket/sub-task.svg"
                  class="actions svg-icon mr-2"
                  [svgStyle]="{ 'width.px': '22', 'height.px': '20' }"
                  [class.dark]="config.layout.variant === 'Dark'"
                  ngbTooltip="{{ t('tooltip-subtask-has-parent') }}"
                  placement="bottom"
                ></svg-icon>
                {{ parentTask.boardAbbreviation }}-{{ parentTask.counter }}
                <span class="mx-1">/</span>
              </span>
              <span
                *ngIf="
                  ticketData.draft &&
                    !ticketData.draft?.ticketId &&
                    ticketData.draft.ticket.counter &&
                    ticketData.draft.ticket.boardAbbreviation;
                  else counterBlock
                "
                class="copy-ticket-title"
              >
                {{ t('recovered-from') }}
                {{ modalData.data?.boardAbbreviation }}-{{ modalData.data?.counter }}
              </span>
              <ng-template #counterBlock>
                <b t-id="board-ticket-number-task"
                  >{{ modalData.data?.boardAbbreviation }}-{{ modalData.data?.counter }}</b
                >
              </ng-template>
            </span>
            <span *ngIf="isCopyTicket" class="copy-ticket-title">
              {{ t('from-ticket') }}
              <b>{{ modalData.data?.boardAbbreviation }}-{{ modalData.data?.counter }}</b>
            </span>
          </div>
          <span
            *ngIf="modalData?.lastTicketAbbr"
            class="badge badge-disabled font-small-2 ml-2"
            t-id="board-ticket-last-created"
          >
            <span
              >{{ t('last-ticket') }}: <b>{{ modalData?.lastTicketAbbr }}</b></span
            >
          </span>
        </span>
        <span class="modal-title-ctr--mobile text-capitalize">{{ pageTitleBack }}</span>
      </h5>
    </ng-template>

    <form
      [class.minimized]="isMinimized"
      *ngIf="ticketForm"
      [formGroup]="ticketForm"
      (ngSubmit)="submitForm()"
    >
      <div
        class="modal-body"
        [ngClass]="{
          'modal-light': config?.layout.variant === 'Light',
          'modal-dark': config?.layout.variant === 'Dark'
        }"
      >
        <ng-scrollbar
          #scrollbarContainer
          [autoHeightDisabled]="!isMinimized"
          class="scroll-event scrollable-container"
          thumbClass="white-scrollbars"
        >
          <div class="ticket-modal-content">
            <div class="col-xl-7" [class.minimized]="isMinimized">
              <div #scrollContainer class="breadcrumb-mobile">
                <ng-container *ngTemplateOutlet="breadcrumbs"> </ng-container>
              </div>

              <div class="d-flex align-items-center mb-3" *ngIf="isMinimized">
                <ng-template #miniSpace let-mini_space>
                  <div class="minimize__space-project d-flex align-items-center">
                    <app-space-avatar
                      [containerWidth]="24"
                      [containerHeight]="24"
                      [space]="mini_space"
                      [mr]="false"
                      class="sp-list-item-avatar"
                    ></app-space-avatar>
                    <span class="minimize__space-project-title ml-1">{{
                      mini_space?.spaceName
                    }}</span>
                  </div>
                </ng-template>
                <ng-template #miniProject let-mini_project>
                  <svg-icon
                    src="assets/icons/common/arrow-down.svg"
                    class="ml-2"
                    [svgStyle]="{
                      'width.px': '11',
                      'height.px': '11',
                      transform: 'rotate(-90deg)'
                    }"
                  ></svg-icon>
                  <div class="minimize__space-project d-flex align-items-center ml-2">
                    <app-project-avatar
                      style="width: 24px"
                      [containerWidth]="24"
                      [containerHeight]="24"
                      [project]="mini_project"
                      [mr]="false"
                      class="sp-list-item-avatar"
                    ></app-project-avatar>
                    <span class="minimize__space-project-title ml-1">{{
                      mini_project?.projectName
                    }}</span>
                  </div>
                </ng-template>

                <ng-template
                  [ngTemplateOutlet]="miniSpace"
                  [ngTemplateOutletContext]="{
                    $implicit: getSpace(currentProject?.spaceId)
                  }"
                ></ng-template>
                <ng-template
                  *ngIf="currentProject"
                  [ngTemplateOutlet]="miniProject"
                  [ngTemplateOutletContext]="{ $implicit: currentProject }"
                ></ng-template>
              </div>
              <div class="d-flex mb-2">
                <div
                  class="fieldset-status position-relative"
                  [class.fieldset-status-mobile]="platform !== 'web'"
                >
                  <ng-select
                    *ngIf="!modalData.isEpic"
                    t-id="board-ticket-status"
                    id="ticketStatus"
                    name="columnId"
                    placeholder="Set status"
                    bindLabel="title"
                    bindValue="_id"
                    appendTo="body"
                    [searchable]="false"
                    [clearable]="false"
                    [items]="boardColumns"
                    [formControl]="ticketForm.controls['columnId']"
                    [ngClass]="modalData?.boardColorClass"
                    [attr.data-tabindex]="'tab-index-status'"
                    (click)="setFocusedControl('tab-index-status'); closeCustomize()"
                    (change)="statusSelectChange($event)"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      {{ item.title }}
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" id="status_select">
                      <span class="w-100 text-truncate {{ item?.boardColorClass }}">
                        {{ item.title }}
                      </span>
                    </ng-template>
                  </ng-select>

                  <div *ngIf="!modalData.isEpic" class="d-flex" [class.ml-2]="platform === 'web'">
                    <div
                      t-id="board-ticket-color"
                      class="color svg-icon board-ticket-color"
                      (click)="toggleCustomize()"
                      #customizeTicketButton
                    >
                      <div class="board-ticket-color_wrap">
                        <div
                          class="board-ticket-color_item"
                          [class.none]="noneColorBoardTicket"
                          [style.background-color]="colorBoardTicket"
                        ></div>
                        <svg-icon
                          src="assets/icons/common/{{
                            isShowCustomiseTicket ? 'chevron-up' : 'chevron-down'
                          }}.svg"
                          class="actions board-ticket-color_arrow"
                          [class.dark]="config.layout.variant === 'Dark'"
                          [svgStyle]="{ 'width.px': '11', 'height.px': '6' }"
                        ></svg-icon>
                      </div>
                    </div>
                    <div
                      ngbDropdown
                      placement="bottom-start"
                      display="static"
                      [autoClose]="true"
                      class="dropdown actions-dropdown"
                    >
                      <div
                        *ngIf="
                          !isTicketHasPriority && !ticketForm.value.isBlocked;
                          else priorityIcon
                        "
                        class="svg-wrapper has-background ml-2 flag"
                        ngbDropdownToggle
                      >
                        <svg-icon
                          src="assets/icons/priority/flag.svg"
                          class="svg-icon"
                          [applyClass]="true"
                          [svgStyle]="{ 'width.px': '14', 'height.px': '14' }"
                        ></svg-icon>
                      </div>
                      <ng-template #priorityIcon>
                        <div
                          class="svg-wrapper priority-toggle-icon ml-2"
                          ngbDropdownToggle
                          (click)="closeCustomize()"
                        >
                          <svg-icon
                            src="assets/icons/priority/{{
                              priorityKeys[ticketForm.value.priority].key
                            }}.svg"
                            class="svg-icon"
                            [applyClass]="true"
                            [svgStyle]="{ 'width.px': '32', 'height.px': '32' }"
                          ></svg-icon>
                        </div>
                      </ng-template>
                      <div
                        class="dropdown-menu priority text-left"
                        ngbDropdownMenu
                        [class.dark-priority]="config.layout.variant === 'Dark'"
                      >
                        <div
                          ngbDropdownToggle
                          class="cursor-pointer d-flex align-items-center priority-item"
                          (click)="onSelectPriority(4)"
                        >
                          <svg-icon
                            src="assets/icons/priority/{{ priorityKeys[4].key }}.svg"
                            class="svg-icon"
                            [applyClass]="true"
                            [svgStyle]="{ 'width.px': '20', 'height.px': '20' }"
                          ></svg-icon>
                          <span class="priority-item_title">{{ priorityKeys[4].displayName }}</span>
                        </div>
                        <div
                          ngbDropdownToggle
                          class="cursor-pointer d-flex align-items-center priority-item"
                          (click)="onSelectPriority(3)"
                        >
                          <svg-icon
                            src="assets/icons/priority/{{ priorityKeys[3].key }}.svg"
                            class="svg-icon"
                            [applyClass]="true"
                            [svgStyle]="{ 'width.px': '20', 'height.px': '20' }"
                          ></svg-icon>
                          <span class="priority-item_title">{{ priorityKeys[3].displayName }}</span>
                        </div>
                        <div
                          ngbDropdownToggle
                          class="cursor-pointer d-flex align-items-center priority-item"
                          (click)="onSelectPriority(2)"
                        >
                          <svg-icon
                            src="assets/icons/priority/{{ priorityKeys[2].key }}.svg"
                            class="svg-icon"
                            [applyClass]="true"
                            [svgStyle]="{ 'width.px': '20', 'height.px': '20' }"
                          ></svg-icon>
                          <span class="priority-item_title">{{ priorityKeys[2].displayName }}</span>
                        </div>
                        <div
                          ngbDropdownToggle
                          class="cursor-pointer d-flex align-items-center priority-item"
                          (click)="onSelectPriority(1)"
                        >
                          <svg-icon
                            src="assets/icons/priority/{{ priorityKeys[1].key }}.svg"
                            class="svg-icon"
                            [applyClass]="true"
                            [svgStyle]="{ 'width.px': '20', 'height.px': '20' }"
                          ></svg-icon>
                          <span class="priority-item_title">{{ priorityKeys[1].displayName }}</span>
                        </div>
                        <div
                          ngbDropdownToggle
                          class="cursor-pointer d-flex align-items-center priority-item"
                          (click)="onSelectPriority(0)"
                        >
                          <svg-icon
                            src="assets/icons/priority/{{ priorityKeys[0].key }}.svg"
                            class="svg-icon"
                            [applyClass]="true"
                            [svgStyle]="{ 'width.px': '20', 'height.px': '20' }"
                          ></svg-icon>
                          <span class="priority-item_title">{{ priorityKeys[0].displayName }}</span>
                        </div>
                      </div>
                    </div>

                    <div
                      *ngIf="modalData.id"
                      class="copy-link svg-icon ml-2"
                      (click)="copyTicketLink(modalData)"
                    >
                      <svg-icon
                        src="assets/icons/common/link.svg"
                        class="actions svg-icon"
                        [class.dark]="config.layout.variant === 'Dark'"
                        [svgStyle]="{ 'width.px': '14', 'height.px': '14' }"
                      ></svg-icon>
                    </div>
                  </div>
                </div>

                <div class="d-flex align-items-center ml-2 creator-container">
                  <div class="creator-title">{{ t('creator') }}</div>
                  <div class="creator-name mx-2">{{ ticketCreatorName }}</div>
                  <app-avatar [userId]="ticketCreator" [width]="36" [height]="36"></app-avatar>
                </div>
              </div>

              <fieldset class="form-group position-relative">
                <textarea
                  t-id="board-ticket-title"
                  id="ticketTitle"
                  formControlName="title"
                  placeholder="{{
                    t(ticketData.isEpic ? 'placeholder-epic-name' : 'placeholder-ticket-name')
                  }}"
                  required
                  appTextareaAutoresize
                  class="form-control title-control"
                  [formControl]="ticketForm.controls['title']"
                  [appFocus]="!modalData?.title && platform === 'web'"
                  [attr.data-tabindex]="tabIndexes[0]?.tabIndex"
                  (keydown.enter)="$event.preventDefault()"
                ></textarea>
              </fieldset>

              <legend>{{ t('description') }}</legend>
              <quill-editor
                #descriptionQuill
                id="ticketDescription"
                class="description-editor"
                bounds="self"
                [class.show-toolbar]="
                  toggleDescriptionEditVisibility ||
                  ticketForm.controls['description'].value?.length === 0
                "
                [modules]="editorModules"
                placeholder="{{
                  t(
                    ticketData.isEpic
                      ? 'placeholder-epic-description'
                      : 'placeholder-ticket-description'
                  )
                }}"
                [formControl]="ticketForm.controls['description']"
                [attr.data-tabindex]="tabIndexes[1]?.tabIndex"
                (onFocus)="toggleDescriptionEditVisibility = true"
                (onBlur)="descriptionBlur()"
                (onEditorCreated)="editorDescriptionCreated($event)"
                (paste)="handlePaste($event)"
              ></quill-editor>

              <div
                *ngIf="toggleDescriptionEditVisibility"
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <div class="font-small-1">
                  {{ t('images-size-warning', { size: '1' }) }}
                </div>
                <button
                  *ngIf="ticketForm.controls['description'].value?.length > 0"
                  class="btn btn-sm btn-subtle"
                  type="button"
                  (click)="toggleDescriptionEditVisibility = false"
                >
                  {{ t('btn-close') }}
                </button>
              </div>

              <div class="mb-2 mt-2" *ngIf="!ticketData.isEpic">
                <app-checklist [platform]="platform" [ticket]="$any(modalData)"></app-checklist>
              </div>

              <ng-container *ngTemplateOutlet="!isMinimized && uploadAndTabs"></ng-container>

              <ng-template #uploadAndTabs>
                <div [class.uploadAndTabs]="isMinimized" [style.width.%]="isMinimized && 100">
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <legend class="mb-0">{{ t('attachments') }}</legend>
                    <button
                      t-id="board-ticket-attach-a-file"
                      class="btn btn-sm btn-subtle d-flex align-items-center upload-btn"
                      type="button"
                      [attr.data-tabindex]="tabIndexes[2]?.tabIndex"
                      (click)="attachFileInput.click()"
                    >
                      <svg-icon
                        src="assets/icons/common/upload.svg"
                        class="actions svg-icon mr-1"
                        [class.dark]="config.layout.variant === 'Dark'"
                        [svgStyle]="{ 'width.px': '11', 'height.px': '11' }"
                      ></svg-icon>
                      <span>{{ t('upload-files') }}</span>
                    </button>
                  </div>

                  <ng-scrollbar
                    *ngIf="fileData?.length > 0"
                    class="scroll-event scrollable-container ticket-files"
                    thumbClass="white-scrollbars"
                    track="all"
                    visibility="always"
                  >
                    <table class="table ticket-files-table m-0">
                      <thead>
                        <tr class="head-color">
                          <th colspan="2">{{ t('name') }}</th>
                          <th class="text-center">{{ t('created-by') }}</th>
                          <th class="text-center">{{ t('created-date') }}</th>
                          <th>{{ t('actions') }}</th>
                        </tr>
                      </thead>
                      <tbody t-id="board-ticket-files-table-item">
                        <ng-container *ngFor="let attachment of fileData; let i = index">
                          <tr>
                            <td>
                              <i
                                class="ft-{{
                                  attachment?.originalFileName
                                    ? filesHelper.getFileType(attachment?.originalFileName)
                                    : 'paperclip'
                                }}"
                              ></i>
                            </td>
                            <td>
                              <span
                                class="span-attachment-title cursor-pointer"
                                (click)="showMediaPreview(attachment)"
                              >
                                {{ attachment?.originalFileName | limitTo: limitToAttachmentName }}
                              </span>
                            </td>
                            <td>
                              <div class="d-flex justify-content-center">
                                <app-avatar
                                  [userId]="attachment.ownerUserId"
                                  [height]="24"
                                  [width]="24"
                                  [statusSize]="9"
                                ></app-avatar>
                              </div>
                            </td>
                            <td class="text-center">
                              {{ attachment.created_at | fromNow }}
                            </td>
                            <td>
                              <div
                                ngbDropdown
                                class="d-flex justify-content-center"
                                placement="bottom-right"
                                container="body"
                              >
                                <svg-icon
                                  src="assets/icons/common/ellipsis.svg"
                                  class="svg-icon dropdown-toggle cursor-pointer"
                                  ngbDropdownToggle
                                  [applyClass]="true"
                                  [svgStyle]="{
                                    'width.px': '17',
                                    'height.px': '17'
                                  }"
                                ></svg-icon>
                                <div ngbDropdownMenu class="attachments-actions">
                                  <button
                                    ngbDropdownItem
                                    type="button"
                                    *ngIf="attachment?.url"
                                    (click)="copyAttachmentLink(attachment.url)"
                                  >
                                    <svg-icon
                                      src="assets/icons/ticket/copy.svg"
                                      class="actions mr-2"
                                      [class.dark]="config.layout.variant === 'Dark'"
                                      [svgStyle]="{
                                        'width.px': '16',
                                        'height.px': '16'
                                      }"
                                    ></svg-icon>
                                    <span>{{ t('btn-copy-link') }}</span>
                                  </button>
                                  <button
                                    ngbDropdownItem
                                    type="button"
                                    *ngIf="attachment?.url"
                                    (click)="downloadAttachment(attachment)"
                                  >
                                    <svg-icon
                                      src="assets/icons/common/download.svg"
                                      class="actions mr-2"
                                      [class.dark]="config.layout.variant === 'Dark'"
                                      [svgStyle]="{
                                        'width.px': '16',
                                        'height.px': '16'
                                      }"
                                    ></svg-icon>
                                    <span>{{ t('btn-download') }}</span>
                                  </button>
                                  <button
                                    ngbDropdownItem
                                    type="button"
                                    (click)="removeAttachment(i, attachment.originalFileName)"
                                  >
                                    <svg-icon
                                      src="assets/icons/common/trash.svg"
                                      class="actions mr-2"
                                      [class.dark]="config.layout.variant === 'Dark'"
                                      [svgStyle]="{
                                        'width.px': '16',
                                        'height.px': '16'
                                      }"
                                    ></svg-icon>
                                    <span>{{ t('btn-remove') }}</span>
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </ng-scrollbar>

                  <ul
                    ngbNav
                    #ticketModalsTab="ngbNav"
                    [(activeId)]="currentTab"
                    (navChange)="onNavChange($event)"
                    class="nav-tabs d-flex justify-content-start"
                  >
                    <li *ngIf="modalData.id" [ngbNavItem]="'comments'">
                      <a ngbNavLink>{{ t('link-comments') }}</a>

                      <ng-template ngbNavContent>
                        <app-chat-thread
                          *ngIf="mentionThreadMembers"
                          class="ticket-thread"
                          [isSeparate]="true"
                          [apiUrl]="env?.api_root"
                          [platform]="platformOS"
                          [message]="message"
                          [mentionThreadMembers]="mentionThreadMembers"
                          [object]="modalData.object"
                          [objectId]="modalData.objectId"
                          linkObject="tickets"
                          [linkObjectId]="modalData.id"
                        ></app-chat-thread>
                      </ng-template>
                    </li>

                    <li *ngIf="modalData.id" [ngbNavItem]="'history'">
                      <a ngbNavLink>{{ t('link-history') }}</a>

                      <ng-template ngbNavContent>
                        <div class="history-wrapper">
                          <div *ngFor="let item of ticketHistory" class="history-item">
                            <app-avatar
                              [userId]="item.updatedBy"
                              [height]="40"
                              [width]="40"
                              [statusSize]="11"
                              class="ml-2"
                            ></app-avatar>
                            <div class="history-item-content flex-grow-1">
                              <div>
                                <span class="ticket-updated-by">{{ item.updaterName }}</span>
                                <span class="ticket-date"
                                  >{{ t('at') }}
                                  {{
                                    item.updated_at | date: 'dd.MM.yyyy - HH:mm' : currTzAbbr
                                  }}</span
                                >
                              </div>
                              <div class="history-comment">
                                {{ t('ticket-updated') }}
                              </div>
                            </div>
                          </div>
                          <div class="history-item">
                            <app-avatar
                              [userId]="ticketCreator"
                              [height]="40"
                              [width]="40"
                              [statusSize]="11"
                              class="ml-2"
                            ></app-avatar>
                            <div class="history-item-content flex-grow-1">
                              <div>
                                <span class="ticket-updated-by">{{ ticketCreatorName }}</span>
                                <span class="ticket-date"
                                  >{{ t('at') }}
                                  {{
                                    ticketCreatedAt | date: 'dd.MM.yyyy - HH:mm' : currTzAbbr
                                  }}</span
                                >
                              </div>
                              <div class="history-comment">
                                {{ t('ticket-created') }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </li>

                    <li *ngIf="modalData.id" [ngbNavItem]="'estimation'">
                      <a ngbNavLink>{{ t('link-estimation') }}</a>

                      <ng-template ngbNavContent>
                        <div
                          *ngIf="!isUserInsideEstimateSession"
                          class="estimation-start-session my-2"
                        >
                          <button
                            *ngIf="!isHiddenEstimateSession"
                            class="btn btn-solid btn-save"
                            type="button"
                            (click)="startOrJoinEstimationSession()"
                          >
                            <i
                              *ngIf="estimationIsLoadingStart"
                              class="fa fa-spinner fa-spin connection_spinner"
                              aria-hidden="true"
                            ></i>
                            {{
                              estimateSession
                                ? !isUserInsideEstimateSession
                                  ? t('btn-join-estimation')
                                  : ''
                                : t('btn-start-estimation')
                            }}
                          </button>
                        </div>

                        <div *ngIf="estimateSession" class="estimation">
                          <div class="d-flex align-content-center">
                            <div
                              *ngIf="isOwnerEstimateSession"
                              class="estimation-header d-flex align-items-center"
                            >
                              <div
                                class="estimation-header__switch mr-4 custom-control custom-switch switch-md"
                              >
                                <input
                                  #lockSwitch
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="lockSwitch"
                                  [formControl]="estimationCreatorLock"
                                />
                                <label
                                  class="custom-control-label d-flex align-content-center cursor-pointer"
                                  for="lockSwitch"
                                  t-id="chat-threads-list-only-for"
                                >
                                  <span class="mr-1">{{ t('lock-or-show-values') }}</span>
                                </label>
                              </div>
                            </div>

                            <div
                              *ngIf="isEstimationSessionLocked || isOwnerEstimateSession"
                              class="d-flex align-content-center"
                            >
                              <div class="estimation-header__calculation mr-4">
                                {{ t('min') }}: {{ estimationMinMaxAvg.min }}
                              </div>
                              <div class="estimation-header__calculation mr-4">
                                {{ t('max') }}: {{ estimationMinMaxAvg.max }}
                              </div>
                              <div class="estimation-header__calculation mr-4">
                                {{ t('avg') }}: {{ estimationMinMaxAvg.avg }}
                              </div>
                            </div>
                          </div>

                          <div
                            *ngIf="!isEstimationSessionLocked && isUserInsideEstimateSession"
                            class="estimation-answer d-flex align-items-center mt-2 mb-2"
                          >
                            <input
                              type="number"
                              class="form-control mr-2 estimation-value"
                              placeholder="{{ t('placeholder-enter-value') }}"
                              [formControl]="estimationUserValue"
                            />
                            <button
                              class="btn btn-solid btn-save"
                              type="button"
                              [disabled]="!estimationUserValue.valid"
                              (click)="saveMemberEstimateValue()"
                            >
                              {{ t('btn-save') }}
                            </button>
                          </div>

                          <div class="estimation-title grid-layout mt-4">
                            <div>{{ t('username') }}</div>
                            <div>{{ t('estimation') }}</div>
                          </div>

                          <div
                            *ngFor="let member of estimateSessionMembers"
                            class="estimation-list mt-4"
                          >
                            <div class="estimation-list__user grid-layout">
                              <div class="estimation-list__user-info d-flex align-center">
                                <div class="avatar mr-4">
                                  <app-avatar [userId]="member.userId"></app-avatar>
                                </div>
                                <div class="estimation-list__user-name align-center">
                                  {{ member?.user?.userName || t('not-available') }}
                                </div>
                              </div>
                              <div
                                class="estimation-list__user-estimate"
                                [class.transparent]="
                                  !(
                                    isEstimationSessionLocked ||
                                    isUserEstimate(member) ||
                                    isOwnerEstimateSession
                                  ) && member.value
                                "
                              >
                                {{
                                  isEstimationSessionLocked ||
                                  isUserEstimate(member) ||
                                  isOwnerEstimateSession
                                    ? memberAnswerStatusForCurrentUser(member)
                                    : memberAnswerStatusForGuest(member)
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </li>
                  </ul>

                  <div class="ticket-modal-tabs" [ngbNavOutlet]="ticketModalsTab"></div>
                </div>
              </ng-template>
            </div>

            <div class="col-xl-5" [class.minimized]="isMinimized" [class.expanded]="!isMinimized">
              <!-- BOARD SELECT -->
              <div *ngIf="modalData.isNeedToSelectObject" class="boards-wrapper">
                <legend>{{ t('board') }}</legend>
                <div class="boards-buttons no-padding position-relative w-100">
                  <div class="btn-group w-100" role="group">
                    <button
                      *ngIf="(spaces | async)?.length > 0"
                      type="button"
                      t-id="board-ticket-boards-buttons-space"
                      class="btn object-btn object-btn-space"
                      [ngClass]="{
                        'selected-boards-btn': modalData?.object === 'spaces',
                        'btn-solid': modalData?.object === 'spaces',
                        'btn-outline': modalData?.object !== 'spaces'
                      }"
                      (click)="spacesBtnClicked()"
                    >
                      {{ t('space') }}
                    </button>
                    <button
                      *ngIf="(projects | async)?.length > 0"
                      type="button"
                      t-id="board-ticket-boards-buttons-project"
                      class="btn object-btn object-btn-project"
                      [ngClass]="{
                        'selected-boards-btn': modalData?.object === 'projects',
                        'btn-solid': modalData?.object === 'projects',
                        'btn-outline': modalData?.object !== 'projects'
                      }"
                      (click)="projectsBtnClicked()"
                    >
                      {{ t('project') }}
                    </button>
                  </div>
                </div>

                <div class="form-group boards-select-wrapper">
                  <!-- SPACES NG-SELECT -->
                  <ng-select
                    [class.d-none]="modalData.object !== 'spaces'"
                    t-id="board-ticket-select-space"
                    id="ticketSpace"
                    class="calendar-dropdown"
                    required
                    bindLabel="spaceName"
                    bindValue="_id"
                    placeholder="{{ t('placeholder-select-space') }}"
                    name="event-spaceId"
                    appendTo="body"
                    [items]="spaces | async"
                    [searchable]="platform === 'web'"
                    [clearOnBackspace]="true"
                    [readonly]="invitedUser"
                    [attr.data-tabindex]="'tab-index-spaces'"
                    [formControl]="ticketForm.controls['objectId']"
                    [(ngModel)]="modalData.objectId"
                    (change)="
                      attachDataLists({
                        object: 'spaces',
                        objectId: $event?._id
                      })
                    "
                  >
                    <ng-template ng-label-tmp let-item="item">
                      <div class="sp-list-item">
                        <app-space-avatar
                          [containerWidth]="24"
                          [containerHeight]="24"
                          [space]="item"
                          class="sp-list-item-avatar mr-1"
                        ></app-space-avatar>
                        <span>{{ item.spaceName }}</span>
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                      <div class="sp-list-item">
                        <app-space-avatar
                          [containerWidth]="24"
                          [containerHeight]="24"
                          [space]="item"
                          class="sp-list-item-avatar mr-1"
                        ></app-space-avatar>
                        <span>{{ item.spaceName }}</span>
                      </div>
                    </ng-template>
                  </ng-select>

                  <!-- PROJECTS NG-SELECT -->
                  <ng-select
                    [class.d-none]="modalData.object !== 'projects'"
                    t-id="board-ticket-select-project"
                    id="ticketProject"
                    bindLabel="projectName"
                    bindValue="_id"
                    placeholder="{{ t('placeholder-select-project') }}"
                    name="event-projectId"
                    appendTo="body"
                    [items]="projects | async"
                    [searchable]="platform === 'web'"
                    [readonly]="invitedUser"
                    [attr.data-tabindex]="'tab-index-projects'"
                    [formControl]="ticketForm.controls['objectId']"
                    [(ngModel)]="modalData.objectId"
                    (change)="
                      attachDataLists({
                        object: 'projects',
                        objectId: $event?._id
                      })
                    "
                  >
                    <ng-template ng-label-tmp let-item="item">
                      <div class="sp-list-item">
                        <app-space-avatar
                          style="width: 24px"
                          [containerWidth]="24"
                          [containerHeight]="24"
                          [space]="{ _id: item.spaceId }"
                          class="sp-list-item-avatar mr-1"
                        ></app-space-avatar>
                        <app-project-avatar
                          style="width: 24px"
                          [containerWidth]="24"
                          [containerHeight]="24"
                          [project]="item"
                          class="sp-list-item-avatar mr-1"
                        ></app-project-avatar>
                        <span>{{ item.projectName }}</span>
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                      <div class="sp-list-item">
                        <app-space-avatar
                          style="width: 24px"
                          [containerWidth]="24"
                          [containerHeight]="24"
                          [space]="{ _id: item.spaceId }"
                          class="sp-list-item-avatar mr-1"
                        ></app-space-avatar>
                        <app-project-avatar
                          style="width: 24px"
                          [containerWidth]="24"
                          [containerHeight]="24"
                          [project]="item"
                          class="sp-list-item-avatar mr-1"
                        ></app-project-avatar>
                        <span>{{ item.projectName }}</span>
                      </div>
                    </ng-template>
                  </ng-select>
                </div>

                <div *ngIf="invitedUser" class="block-overlay position-absolute"></div>
              </div>

              <app-custom-fields
                [modalData]="$any(modalData)"
                [isInternalState]="isInternalState"
                [isMinimized]="isMinimized"
                [isMinimized$]="isMinimized$"
                [isDraft]="!!ticketData.draft"
                [ticketFormData]="ticketForm.value"
                [ticketSelectParent]="ticketSelectParent"
                [customFieldsValues]="ticket?.customFields || []"
                [childrenList]="childrenList"
                [parentIsConflict]="parentIsConflict"
                [datePickedWithoutTime]="datePickedWithoutTime"
                [selectedLabelsForDraft$]="selectedLabelsForDraft$"
                (onChangeCustomFieldData)="onChangeCustomFieldData($event)"
                (detectValidForm)="detectValidForm($event)"
              ></app-custom-fields>
              <div *ngIf="modalData.id && !isParentHasValueInCustomField && !isCopyTicket">
                <legend>{{ t('sub-tasks') }}</legend>
                <fieldset class="position-relative">
                  <div
                    *ngIf="ticketData.isEpic ? subTicketsEpic.length : childrenList?.length"
                    class="sub-tasks"
                  >
                    <div
                      *ngFor="let child of ticketData.isEpic ? subTicketsEpic : childrenList"
                      ngbTooltip="{{ child.title }}"
                      [class.conflicted]="isWrongSubTask(child)"
                      placement="top"
                      class="sub-task-wrapper"
                      [class.has-flag]="child.isBlocked"
                    >
                      <div class="task-flag"></div>
                      <div class="sub-task cursor-pointer flex-grow-1" (click)="openTicket(child)">
                        <span class="ticket-number"
                          >{{ child.boardAbbreviation }}-{{ child.counter }}</span
                        >
                        <span
                          class="ticket-title"
                          t-id="board-ticket-selected-ticket-title-child"
                          >{{ child.title }}</span
                        >
                      </div>
                      <div class="sub-task-actions">
                        <app-svg
                          name="trash"
                          width="14"
                          height="16"
                          class="svg-icon"
                          [hoverEffect]="true"
                          (click)="removeSubTask(child)"
                        ></app-svg>
                      </div>
                      <div class="sub-task-status" [ngClass]="getColumnColor(child.columnId)">
                        {{ getColumnTitle(child.columnId) }}
                      </div>
                    </div>
                  </div>

                  <button
                    *ngIf="!showSubtaskSelect"
                    t-id="board-ticket-add-subtask"
                    class="d-flex align-items-center btn btn-sm btn-subtle add-sub-task"
                    type="button"
                    [attr.data-tabindex]="tabIndexes[9]?.tabIndex"
                    (click)="
                      setFocusedControl(tabIndexes[9]?.tabIndex);
                      showSubtaskSelect = !showSubtaskSelect
                    "
                  >
                    <svg-icon
                      src="assets/icons/common/plus.svg"
                      class="actions mr-1"
                      [svgStyle]="{ 'width.px': '9', 'height.px': '9' }"
                      [applyClass]="true"
                      [class.dark-purple]="config.layout.variant === 'Dark'"
                    ></svg-icon>
                    <span>{{ t('btn-add-sub-task') }}</span>
                  </button>

                  <div *ngIf="showSubtaskSelect">
                    <ng-select
                      t-id="board-ticket-add-search-subtask"
                      id="ticketSubTask"
                      class="subtask-select"
                      bindLabel="title"
                      bindValue="_id"
                      [placeholder]="
                        !ticketData?.draft
                          ? t('placeholder-search-or-add-task')
                          : t('placeholder-search-task')
                      "
                      [items]="ticketsSelect"
                      [addTag]="addSubtaskPromise && !ticketData?.draft"
                      [loading]="loading"
                      [formControl]="ticketForm.controls['selectedSubtask']"
                    >
                      <ng-template ng-tag-tmp let-search="searchTerm">
                        <b>{{ t('add-new') }}</b
                        >: {{ search }}
                      </ng-template>
                      <ng-template
                        ng-label-tmp
                        let-item="item"
                        let-clear="clear"
                        id="task_selected"
                      >
                        <div
                          class="d-flex align-items-center parent-task w-100"
                          [class.conflicted]="isWrongSubTask(item)"
                          ngbTooltip="{{ item.title }}"
                          container="body"
                          placement="top"
                        >
                          <span *ngIf="item?.counter" class="ticket-number"
                            >{{ item?.boardAbbreviation }}-{{ item?.counter }}</span
                          >
                          <span class="ticket-title">{{ item.title }}</span>
                        </div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-index="index" id="task_select">
                        <div class="parent-task" [class.conflicted]="isWrongSubTask(item)">
                          <span *ngIf="item?.counter" class="ticket-number"
                            >{{ item.boardAbbreviation }}-{{ item.counter }}</span
                          >
                          <span class="ticket-title" t-id="board-ticket-set-ticket-title-child">{{
                            item.title
                          }}</span>
                        </div>
                      </ng-template>
                    </ng-select>

                    <div class="d-flex align-items-center justify-content-end">
                      <button
                        type="button"
                        class="d-flex align-items-center btn btn-sm btn-subtle"
                        (click)="closeSubtaskSelect()"
                      >
                        <span>{{ t('btn-cancel') }}</span>
                      </button>

                      <button
                        type="button"
                        class="d-flex align-items-center btn btn-sm btn-solid add-sub-task-submit"
                        (click)="addSubTask(ticketForm.controls['selectedSubtask'].value)"
                      >
                        <svg-icon
                          src="assets/icons/common/plus.svg"
                          class="actions mr-1"
                          [svgStyle]="{ 'width.px': '9', 'height.px': '9' }"
                          [class.dark-purple]="config.layout.variant === 'Dark'"
                          [applyClass]="true"
                        ></svg-icon>
                        <span>{{ t('btn-add-sub-task') }}</span>
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>

              <ng-container *ngTemplateOutlet="isMinimized && uploadAndTabs"></ng-container>
            </div>

            <ng-container *ngIf="!modalData.isEpic && isShowCustomiseTicket">
              <div
                class="board-modal_customise-ticket"
                [style.top.px]="customiseTicketXY.y"
                [style.left.px]="customiseTicketXY.x"
              >
                <app-ticket-coloring
                  [object]="modalData.object"
                  [objectId]="modalData.objectId"
                  [ticket]="ticketForm.value"
                  [labels]="labels"
                  (onChangeColor)="onChangeTicketColor($event)"
                  (onClose)="toggleCustomize($event)"
                ></app-ticket-coloring>
              </div>
            </ng-container>
          </div>
        </ng-scrollbar>
      </div>

      <div
        class="modal-footer d-flex align-items-center"
        [class.minimized]="isMinimized"
        [class.expanded]="!isMinimized"
      >
        <button
          *ngIf="submitButtonTitle !== 'Create' && !isCopyTicket && !ticketData.draft"
          t-id="board-ticket-delete-action"
          class="svg-btn"
          title="Delete ticket"
          type="button"
          (click)="deleteTicket()"
        >
          <svg-icon
            src="assets/icons/common/trash.svg"
            class="actions svg-icon"
            [class.dark]="config.layout.variant === 'Dark'"
            [svgStyle]="{ 'width.px': '16', 'height.px': '16' }"
          ></svg-icon>
        </button>

        <button
          class="btn btn-subtle"
          type="button"
          (click)="close()"
          t-id="board-ticket-cancel-action"
        >
          {{ t('btn-cancel') }}
        </button>

        <button
          *ngIf="
            submitButtonTitle === t('btn-create') &&
            !ticketData.isEpic &&
            !isCopyTicket &&
            !ticketData?.draft
          "
          t-id="board-ticket-create-next-action"
          class="btn btn-solid btn-save-next"
          [disabled]="isUploading"
          (click)="needCreateNext = true"
        >
          <i
            *ngIf="isUploading"
            class="fa fa-spinner fa-spin connection_spinner"
            aria-hidden="true"
          ></i>
          {{ isUploading ? '' : t('btn-create-next') }}
        </button>

        <button
          *ngIf="
            !ticketData.isEpic &&
            !isCopyTicket &&
            modalData?.id &&
            !isMinimized &&
            !ticketData.draft
          "
          t-id="board-ticket-create-next-action"
          class="btn btn-solid btn-save-next"
          type="button"
          [disabled]="isUploading"
          (click)="copyTicketModal()"
        >
          <i
            *ngIf="isUploading"
            class="fa fa-spinner fa-spin connection_spinner"
            aria-hidden="true"
          ></i>
          {{ isUploading ? '' : t('btn-copy-ticket') }}
        </button>

        <button
          [disabled]="isUploading"
          t-id="board-ticket-create-action"
          class="btn btn-solid btn-save"
        >
          <i
            *ngIf="isUploading"
            class="fa fa-spinner fa-spin connection_spinner"
            aria-hidden="true"
          ></i>
          {{ isUploading ? '' : submitButtonTitle }}
        </button>
      </div>
    </form>

    <input
      #attachFileInput
      type="file"
      multiple
      style="display: none"
      onclick="value=null"
      (change)="fileChange($event)"
      t-id="board-ticket-uploader-attachment"
    />

    <app-drag-drop-view
      [component]="'Tickets'"
      [isDraggingFile]="isDraggingFile"
      (draggingFile)="setIsDraggingFile($event)"
      (FileDropped)="fileDropped($event)"
    ></app-drag-drop-view>
  </div>

  <ng-template #breadcrumbs>
    <div class="modal-breadcrumb">
      <div *ngIf="!spaceInfo" class="no-wrap">{{ pageTitle }}</div>
      <!-- space -->
      <div
        class="d-flex item-center cursor-pointer no-wrap"
        (click)="navigateToChat(spaceChat?._id)"
      >
        <app-space-avatar
          *ngIf="spaceInfo"
          [space]="spaceInfo"
          [isBreadcrumb]="true"
          class="space-avatar max-w-[24px] max-h-[24px] mr-2 aspect-square"
        ></app-space-avatar>
        {{ spaceInfo?.spaceName }}
      </div>
      <div class="breadcrumb-arrow" *ngIf="spaceInfo"></div>

      <!-- project -->
      <div
        class="d-flex item-center cursor-pointer no-wrap"
        (click)="navigateToChat(projectChat?._id)"
      >
        <app-project-avatar
          style="width: 24px"
          *ngIf="projectInfo"
          [project]="projectInfo"
          [isBreadcrumb]="true"
          class="project-avata max-w-[24px] max-h-[24px] mr-2 aspect-square"
        ></app-project-avatar>
        {{ projectInfo?.projectName }}
      </div>
      <div class="breadcrumb-arrow" *ngIf="projectInfo"></div>

      <!-- epic -->

      <div
        *ngIf="epicBreadcrumb"
        class="element"
        [class.dark-element]="config.layout.variant === 'Dark'"
        (click)="routeToEpic()"
      >
        <div class="breadcrumb-epic"></div>
        {{ epicBreadcrumb.boardAbbreviation }}-{{ epicBreadcrumb.counter }}
      </div>
      <div *ngIf="epicBreadcrumb" class="breadcrumb-arrow"></div>

      <!-- parent -->
      <div
        *ngIf="modalData.data?.parentId && modalData.data?.parentTicket"
        class="breadcrumb-task"
      ></div>
      <span
        *ngIf="modalData.data?.parentId && modalData.data?.parentTicket"
        class="element"
        [class.dark-element]="config.layout.variant === 'Dark'"
        (click)="routeToTask()"
      >
        {{ modalData.data?.parentTicket?.boardAbbreviation }}-{{
          modalData?.data?.parentTicket?.counter
        }}
      </span>
      <div
        *ngIf="modalData.data?.parentId && modalData.data?.parentTicket"
        class="breadcrumb-arrow"
      ></div>

      <span
        *ngIf="!isCopyTicket && modalData.data?.boardAbbreviation"
        class="d-flex element"
        [class.dark-element]="config.layout.variant === 'Dark'"
      >
        <span
          *ngIf="
            ticketData.draft &&
              !ticketData.draft?.ticketId &&
              ticketData.draft.ticket.counter &&
              ticketData.draft.ticket.boardAbbreviation;
            else counterBlock
          "
          class="copy-ticket-title"
        >
          {{ t('recovered-from') }} {{ modalData.data?.boardAbbreviation }}-{{
            modalData.data?.counter
          }}
        </span>

        <ng-template #counterBlock>
          <div *ngIf="modalData?.data?.isEpic" class="breadcrumb-epic"></div>

          <b t-id="board-ticket-number-task"
            >{{ modalData.data?.boardAbbreviation }}-{{ modalData.data?.counter }}</b
          >
        </ng-template>
      </span>
    </div>
  </ng-template>
</ng-container>
