<div id="videoCallModal" *transloco="let t; read: 'modals.video-call'">
  <div id="jitsi-iframe" class="jitsi" [class.jitsi-external]="externalKey">
    <div
      *ngIf="!externalKey"
      class="action-buttons d-flex align-items-center justify-content-end"
      [class.action-buttons-mobile]="platform !== 'web'"
    >
      <!--      <div id="vcModalMoveBtn" class="jitsi_move btn btn-sm btn-solid mr-1">-->
      <!--        <i class="ft-move"></i>-->
      <!--      </div>-->
      <button
        *ngIf="!isMinimized"
        class="jitsi_minimize btn btn-sm btn-solid"
        (click)="execute('minimize')"
      >
        <i class="ft-minimize"></i>
      </button>
      <button
        *ngIf="isMinimized"
        class="jitsi_maximize btn btn-sm btn-solid"
        (click)="execute('maximize')"
      >
        <i class="ft-maximize"></i>
      </button>
      <button class="jitsi_close btn btn-sm btn-danger ml-1" (click)="closeModal()">
        <i class="ft-x"></i>
      </button>
    </div>

    <div
      *ngIf="externalKey && !isCallStarted && !isCallFinished"
      class="d-flex flex-column align-items-center justify-content-center text-center h-100 w-100"
    >
      <h1>
        {{ t('video-call-not-started-yet') }}
        <br />
        {{ t('try-to-connect-later') }}
      </h1>
      <br />
      <button class="btn btn-lg btn-solid" (click)="reloadPage()">
        {{ t('btn-reload') }}
      </button>
    </div>

    <div
      *ngIf="externalKey && isCallFinished"
      class="d-flex align-items-center justify-content-center text-center h-100 w-100"
    >
      <h1>
        {{ t('video-call-already-finished') }}
        <br />
        {{ t('goodbye') }}
      </h1>
    </div>
  </div>
</div>
