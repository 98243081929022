import { Component, Input } from '@angular/core';

import { SpacesDbDto } from '../../../api/models/spaces-db-dto';
import { ConfigService } from '../../services/config.service';
import { SpaceService } from '../../services/space.service';
import { ProjectService } from '../../services/project.service';
import { LocalStorageService } from 'ngx-localstorage';
import { LocalStorageKeys } from '../../../types/local-storage-keys.enum';
import { FromNowPipe } from '../../pipes/from-now.pipe';
import { CheckPermissionPipe } from '../../pipes/check-permission.pipe';
import { RouterTenantPipe } from '../../pipes/router-tenant.pipe';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { RouterLink } from '@angular/router';
import { ProjectAvatarComponent } from './project-avatar/project-avatar.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { SvgComponent } from '../../svgs/svg/svg.component';
import { NgIf, NgClass, NgFor, AsyncPipe } from '@angular/common';
import { TranslocoDirective } from '@ngneat/transloco';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-space-projects',
  templateUrl: './space-projects.component.html',
  styleUrls: ['./space-projects.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgIf,
    SvgComponent,
    NgScrollbar,
    NgClass,
    NgFor,
    ProjectAvatarComponent,
    RouterLink,
    NgbTooltip,
    AsyncPipe,
    RouterTenantPipe,
    CheckPermissionPipe,
    FromNowPipe,
  ],
})
export class SpaceProjectsComponent {
  @Input() space: SpacesDbDto;
  @Input() projects: Observable<any[]>;
  @Input() platform: string;

  config: any = {};
  lang = this.localStorage.get(LocalStorageKeys.language);

  constructor(
    private configService: ConfigService,
    private localStorage: LocalStorageService,
    public spaceService: SpaceService,
    public projectService: ProjectService,
  ) {
    this.config = this.configService.templateConf;
  }
}
