<div id="users-list" class="list-group h-100 pt-1" *transloco="let t; read: 'chat'">
  <div class="users-list position-relative">
    <div
      class="d-flex align-items-center list-group-header"
      [ngClass]="{
        'list-group-header-light': config?.layout.variant === 'Light',
        'list-group-header-dark': config?.layout.variant === 'Dark'
      }"
    >
      <div class="chat-members-title flex-grow-1">
        {{ t('users-list-username') }}
      </div>
      <div class="chat-members-title timezone">
        {{ t('users-list-timezone') }}
      </div>
    </div>

    <ng-scrollbar #scrollableUsers="ngScrollbar" class="scroll-event list-group-container">
      <div
        *ngFor="let user of members"
        class="list-group-item"
        [ngClass]="{
          'list-group-item-light': config?.layout.variant === 'Light',
          'list-group-item-dark': config?.layout.variant === 'Dark',
          'list-group-item-loading': chatIsOpening
        }"
      >
        <div
          class="media chat-list-text d-flex align-items-center"
          [ngClass]="{
            'chat-members-light': config?.layout.variant === 'Light',
            'chat-members-dark': config?.layout.variant === 'Dark'
          }"
          t-id="chat-users-list-members-list"
        >
          <app-avatar
            [userId]="user?.userId ? user.userId : user._id"
            [width]="36"
            [height]="36"
          ></app-avatar>

          <div
            class="flex-grow-1"
            (click)="viewDirectChat.emit(user?.userId ? user.userId : user._id)"
            t-id="chat-users-list-members-name"
          >
            <div class="chat-members-name" t-id="chat-users-list-members">
              {{ user?.userName }}
            </div>
            <div class="chat-members-role" *ngIf="objectId || object">
              {{ user?.roleName }}
            </div>
          </div>

          <div
            *ngIf="user.time"
            class="chat-members-time"
            triggers="hover"
            [ngbTooltip]="t('member-local-time-tooltip', { value: user.tzDiff })"
            container="body"
            placement="left"
            (click)="viewDirectChat.emit(user?.userId ? user.userId : user._id)"
          >
            {{ user.time }}
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </div>
</div>
