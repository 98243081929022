// Common
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

// Services
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji/data/data.interfaces';
import { Emoji } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { ConfigService, ITemplateConfig } from '../../shared/services/config.service';
import { Store } from '@ngxs/store';
import { ChatsCreatePin, ChatsUpdatePin } from '../../shared/store/actions/chats.action';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  CreatePinModel,
  UpdatePinModel,
} from '../../shared/components/chat/chat-pinned-messages/interface/CreatePinModel';
import { NgxMaskDirective } from 'ngx-mask';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgIf } from '@angular/common';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-pin-create',
  templateUrl: './pin-create.component.html',
  styleUrls: ['./pin-create.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    SvgIconComponent,
    PickerComponent,
    NgxMaskDirective,
  ],
})
export class PinCreateComponent implements OnInit {
  public config: ITemplateConfig;
  public isLoading = false;
  public icon: string;
  public isEditable = false;
  public submitTitle: string;
  public isOpenEmojiPicker = false;
  public isInvalid = false;
  private chatId: string;
  private object: string;
  private objectId: string;
  private readonly pinId: string;
  private readonly emojiPickerImage = 'assets/img/emojis/emojis.png';
  private readonly regularUrl = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  public readonly customPatterns = { A: { pattern: new RegExp('.') } };

  public pinForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    url: ['', [Validators.required, Validators.pattern(this.regularUrl)]],
    isFrame: [false],
  });
  constructor(
    private configService: ConfigService,
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private store: Store,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit(): void {
    this.submitTitle = this.isEditable
      ? this.translocoService.translate('common.btn-save')
      : this.translocoService.translate('common.btn-add');

    this.pinForm.controls.url.valueChanges.subscribe((value) => {
      // If url has https replace first part of https to empty string
      const urlControl = this.pinForm.controls.url;
      const regex = /https:\/\//g;
      // Regular for search https
      const matches = value.match(regex);

      if (matches && matches.length > 1) {
        // If url has matches more than 1, replace
        urlControl.setValue(urlControl.value.replace('https://', ''));
      }
    });
  }

  // Getters
  public get modalTitle(): string {
    return this.isEditable
      ? this.translocoService.translate('modals.pin.title-update-pin')
      : this.translocoService.translate('modals.pin.title-add-link');
  }

  public get isDarkTheme(): boolean {
    return this.config?.layout.variant === 'Dark';
  }

  public get isValidButton(): boolean {
    return (
      (this.pinForm.controls.name.invalid && this.pinForm.controls.name.errors?.required) ||
      (this.pinForm.controls.url.invalid && this.pinForm.controls.url.errors?.required)
    );
  }

  // Methods
  public closeModal(): void {
    this.activeModal.close();
  }
  public emojiPickerImageFn: Emoji['backgroundImageFn'] = (_set: string, _sheetSize: number) =>
    this.emojiPickerImage;
  public emojiPickerToggle() {
    this.isOpenEmojiPicker = !this.isOpenEmojiPicker;
  }
  public addEmoji(emojiData: { emoji: EmojiData }): void {
    this.icon = emojiData?.emoji?.native;
    this.isOpenEmojiPicker = !this.isOpenEmojiPicker;
  }
  public clearEmoji(event): void {
    event.stopPropagation();
    this.icon = '';
  }
  public submitForm(): void {
    if (this.pinForm.controls.url.invalid || this.pinForm.controls.name.invalid) {
      this.isInvalid = true;
      return;
    }

    this.isLoading = true;
    this.isInvalid = false;

    if (this.isEditable) {
      const data: UpdatePinModel = {
        id: this.chatId,
        pinnedMessageId: this.pinId,
        body: {
          name: this.pinForm.controls.name.value,
          url: this.pinForm.controls.url.value,
          openInFrame: this.pinForm.controls.isFrame.value,
          emojiName: this.icon || '',
          object: this.object || 'spaces',
          objectId: this.objectId,
        },
      };

      this.store
        .dispatch(new ChatsUpdatePin(data))
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            this.isLoading = false;
            this.closeModal();
          },
          () => {
            this.isLoading = false;
          },
        );
    } else {
      const data: CreatePinModel = {
        id: this.chatId,
        body: {
          name: this.pinForm.controls.name.value,
          url: this.pinForm.controls.url.value,
          openInFrame: this.pinForm.controls.isFrame.value,
          emojiName: this.icon || '',
          object: this.object || 'spaces',
          objectId: this.objectId,
        },
      };

      this.store
        .dispatch(new ChatsCreatePin(data))
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            this.isLoading = false;
            this.closeModal();
          },
          () => {
            this.isLoading = false;
          },
        );
    }
  }
}
