import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[appClick]',
  standalone: true,
})
export class ClickDirective {
  private clickTimeout: any;
  private clickCount = 0;

  // tslint:disable-next-line:no-output-rename
  @Output() appClick: EventEmitter<any> = new EventEmitter();

  @HostListener('click', ['$event'])
  onClick() {
    this.clickCount++;

    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      if (this.clickCount === 1) {
        this.appClick.emit('single');
      }
      this.clickCount = 0;
    }, 300);
  }

  @HostListener('dblclick', ['$event'])
  onDblClick() {
    clearTimeout(this.clickTimeout);
    this.appClick.emit('double');
    this.clickCount = 0;
  }
}
