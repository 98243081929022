import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Emoji } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';

import { Message } from '../chat.model';
import { environment } from '../../../../../environments/environment';
import { SocketsService } from '../../../services/sockets.service';
import { ConfigService } from '../../../services/config.service';
import {
  ThreadsCloseSidebarOnDelete,
  ThreadsSetCurrentPage,
  ThreadsSetCurrentThreadHeadMessage,
  ThreadsUploadFile,
} from '../../../store/actions/threads.action';
import {
  ChatsEmojiPicker,
  ChatsGetMembers,
  ChatsOpenThreadSidebar,
  ChatsThreadSetEmoji,
  MarkAll,
  ThreadGetMessages,
} from '../../../store/actions/chats.action';
import { EmojiCreate } from '../../../store/actions/emojis.action';
import { AuthState } from '../../../store/states/auth.state';
import { ChatsState } from '../../../store/states/chats.state';
import { ThreadsState } from '../../../store/states/threads.state';
import { SpacesState } from '../../../store/states/spaces.state';
import { ProjectsState } from '../../../store/states/projects.state';
import { UploadFileService } from '../../../services/upload-file.service';
import {
  LinkedEntityActionType,
  ChatMessagesComponent,
} from '../chat-messages/chat-messages.component';
import { ChatService } from '../chat.service';
import { ImageService } from '../../../services/image.service';
import { LocalStorageKeys } from '../../../../types/local-storage-keys.enum';
import de from '../../../../../assets/emoji-i18n/de.json';
import { LocalStorageService } from 'ngx-localstorage';
import { RouterTenantPipe } from '../../../pipes/router-tenant.pipe';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { DragDropViewComponent } from '../../documents/drag-drop-view/drag-drop-view.component';
import { DragDropDirective } from '../../../directives/drag-drop.directive';
import { ThreadAppFormComponent } from '../thread-app-form/thread-app-form.component';
import { AvatarComponent } from '../../../../standalone/components/avatar/avatar.component';
import { GroupAvatarComponent } from '../../space-projects/group-avatar/group-avatar.component';
import { ProjectAvatarComponent } from '../../space-projects/project-avatar/project-avatar.component';
import { SpaceAvatarComponent } from '../../space-projects/space-avatar/space-avatar.component';
import { SvgComponent } from '../../../svgs/svg/svg.component';
import { LinkedObjectBadgeComponent } from '../../linked-object-badge/linked-object-badge.component';
import { NgIf, NgClass, NgStyle, CommonModule } from '@angular/common';

@Component({
  selector: 'app-chat-thread',
  templateUrl: './chat-thread.component.html',
  styleUrls: ['./chat-thread.component.scss'],
  providers: [UploadFileService],
  standalone: true,
  imports: [
    CommonModule,
    TranslocoDirective,
    NgClass,
    LinkedObjectBadgeComponent,
    SvgComponent,
    SpaceAvatarComponent,
    RouterLink,
    ProjectAvatarComponent,
    GroupAvatarComponent,
    forwardRef(() => AvatarComponent),
    ThreadAppFormComponent,
    DragDropDirective,
    ChatMessagesComponent,
    DragDropViewComponent,
    PickerComponent,
    NgStyle,
    RouterTenantPipe,
  ],
})
export class ChatThreadComponent implements OnInit, OnDestroy, OnChanges {
  @Input() apiUrl = environment.api_root;
  @Input() message: any;
  @Input() mentionThreadMembers: string[];
  @Input() platform = 'web';
  @Input() isSeparate = false;
  @Input() isHidden = true;
  @Input() isMobileThread = false;
  @Input() heightKeyboard = 0;
  @Input() isPreview = false;
  @Input() isPreviewMobile = false;
  @Input() isWiki = false;
  @Input() object: string;
  @Input() objectId: string;
  @Input() linkObject: string;
  @Input() linkObjectId: string;
  @Input() isDataRoomFiles = false;

  @Output() clearThreadId = new EventEmitter();
  @Output() copyText = new EventEmitter();

  destroy$: Subject<void> = new Subject<void>();
  thread$: Subscription;
  members$: Subscription;

  mainMessage: any;
  threadId: string;
  darkMode = false;
  config: any = {};
  customRecentEmojis = [];
  messages: Message[] = [];
  numberOfReplies = 0;
  selectedFile: File;
  threadMessage = null;
  isDraggingFile = false;
  isVisible = false;

  emojiPickerImage = 'assets/img/emojis/emojis.png';
  threadEmojiIsOpen = false;
  threadEmojiReactionIsOpen = false;
  threadEmojiReactionLeft = 0;
  threadEmojiReactionTop = 0;

  onNewThreadCreated$: BehaviorSubject<string> = new BehaviorSubject(null);

  perPage = 20;
  isOffline = false;
  perChat = 800;
  currentPage = 1;
  chatMessageCnt = 0;
  lastMessageLoaded: boolean;

  backgroundMobile = true;

  clearAfterUpload$: Subject<boolean> = new Subject<boolean>();
  messageDraft: string;

  i18n = this.localStorage.get(LocalStorageKeys.language) === 'de' ? de : null;

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    this.backgroundMobile = !document.hidden;
    this.emitThreadMarkAsRead();
  }

  constructor(
    public cdr: ChangeDetectorRef,
    private actions: Actions,
    private router: Router,
    private store: Store,
    private socketsService: SocketsService,
    private configService: ConfigService,
    public uploadFileService: UploadFileService,
    private toastr: ToastrService,
    private chatService: ChatService,
    private imageService: ImageService,
    private translocoService: TranslocoService,
    private localStorage: LocalStorageService,
  ) {
    this.customRecentEmojis = this.configService.CUSTOM_RECENT_EMOJIS;
    this.perPage = this.configService.MESSAGES_PER_PAGE;
  }

  ngOnInit(): void {
    this.getLayoutConfig();
    this.setStoreSelectors();
    this.setActionDispatchesListeners();

    if (!this.mentionThreadMembers?.length) {
      this.store.dispatch(new ChatsGetMembers(this.message?.chatId));
    }

    if (
      (this.mainMessage?.linkObject === LinkedEntityActionType.Ticket ||
        this.mainMessage?.linkObject === LinkedEntityActionType.Event) &&
      this.mainMessage?.chatId
    ) {
      this.store
        .select(ChatsState.getMessages)
        .pipe(
          takeUntil(this.destroy$),
          map((filterFn) => filterFn(this.mainMessage.chatId)),
        )
        .subscribe((messages) => {
          const mainMessage = messages?.find((message) => message._id === this.mainMessage._id);
          if (mainMessage) {
            this.mainMessage = {
              ...this.mainMessage,
              ...mainMessage,
            };
          }
        });
    }

    this.socketsService.get().on('connect', this.handleSocketReconnection);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setThreadData();

    if (changes.message) {
      this.uploadFileService.setFiles([]);
    }
  }

  ngOnDestroy() {
    if (!this.isSeparate && !this.isWiki && !this.isDataRoomFiles) {
      this.store.dispatch(new ChatsOpenThreadSidebar(false));
    }
    this.socketsService.get().removeListener('connect', this.handleSocketReconnection);
    this.thread$?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
    this.closeThreadSidebar();
  }

  getLayoutConfig(): void {
    this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
        this.darkMode = this.config.layout.variant !== 'Light';
        this.cdr.detectChanges();
      }
    });
  }

  setStoreSelectors(): void {
    this.store
      .select(ChatsState.getChatsThreadEmojiPicker)
      .pipe(takeUntil(this.destroy$))
      .subscribe((isOpen) => {
        this.threadEmojiIsOpen = isOpen;
        this.threadEmojiReactionIsOpen = false;
      });

    this.store
      .select(ChatsState.getChatsThreadEmojiReaction)
      .pipe(takeUntil(this.destroy$))
      .subscribe((threadEmojiReactionIsOpen) => {
        this.threadEmojiIsOpen = threadEmojiReactionIsOpen;
        this.threadEmojiReactionIsOpen = threadEmojiReactionIsOpen;

        if (threadEmojiReactionIsOpen) {
          const position = this.store.selectSnapshot(ChatsState.getChatsEmojiReactionPosition);

          this.threadEmojiReactionLeft = position.left;
          this.threadEmojiReactionTop = position.top;
        }
      });

    this.store
      .select(ChatsState.isOpenThreadSidebar)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((isOpen) => {
        if (isOpen === false) {
          this.closeThreadSidebar();
        }
      });
  }

  setActionDispatchesListeners(): void {
    this.actions
      .pipe(takeUntil(this.destroy$), ofActionDispatched(ThreadsCloseSidebarOnDelete))
      .subscribe(() => {
        this.toastr.info(this.translocoService.translate('toastr.thread-was-deleted'));
        this.closeThreadSidebar();
        if (this.isMobileThread) {
          this.router.navigate(['../']);
        }
      });

    this.store
      .select(ChatsState.getStatus)
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.isOffline = val;
      });
  }

  setThreadData(): void {
    this.imageService.renewThreadImagesSubject();
    const chat = this.getChatByChatId(this.message?.chatId);
    let space = null;
    let project = null;

    if (chat?.spaceId || chat?.objectId) {
      space = this.store.selectSnapshot(SpacesState.getSpace)(chat?.spaceId || chat?.objectId);
    }

    if (space == undefined) {
      return;
    }

    const spaceChat = this.store
      .selectSnapshot(ChatsState.getChats)
      ?.find((item) => item.object === 'spaces' && item.objectId === space._id);

    if (chat?.object === 'projects') {
      project = this.store.selectSnapshot(ProjectsState.getProject)(chat?.objectId);
    }

    this.mainMessage = {
      ...this.message,
      showDetails: true,
      object: chat?.type === 'group' ? 'spaces' : chat?.object,
      objectId: chat?.objectId,
      chatUserId: chat?.userId,
      chatName: chat?.chatName,
      spaceName: space?.spaceName,
      spaceChatId: spaceChat?._id,
      space,
      project,
    };
    this.threadId = this.mainMessage?.threadId;

    if (!this.threadId) {
      this.isVisible = true;
    }

    this.store.dispatch(
      new ThreadsSetCurrentThreadHeadMessage({
        messageId: this.mainMessage?._id,
        threadId: this.mainMessage?.threadId,
      }),
    );

    this.onNewThreadCreated$.next(this.threadId);
    this.subscribeToThread();
    this.getChatMembers();

    if (this.message?.sharedMessage) {
      this.message.sharedMessage = {
        ...this.message.sharedMessage,
        showDetails: true,
      };
    }

    if (!this.messages.length) {
      this.getThreadMessages();
    } else {
      this.emitThreadMarkAsRead();
    }
  }

  emitThreadMarkAsRead() {
    if (this.backgroundMobile) {
      this.socketsService.get().emit('chats:threadMarkAsRead', this.threadId);
    }
  }

  getRouteToChat(thread, isSpace = false) {
    if (isSpace) {
      return this.platform !== 'web' && thread.space?._id
        ? '/space/' + thread.space?._id
        : '/chat/' + thread.spaceChatId;
    }
    return this.platform !== 'web' && thread.project?._id
      ? '/project/' + thread.project?._id
      : '/chat/' + thread.chatId;
  }

  getThreadMessages() {
    if (this.mainMessage.threadsMessagesInfo?.messagesCount) {
      this.store.dispatch(
        new ThreadsSetCurrentPage({
          threadId: this.threadId,
          currentPage: this.currentPage,
        }),
      );
      const args = {
        threadId: this.threadId,
        page: this.currentPage,
        perPage: this.perPage,
      };
      this.store.dispatch(new ThreadGetMessages(args));
    }
  }

  scrollToBottom(): void {
    const selector = this.isSeparate ? 'thread-messages-separate' : 'thread-messages';
    const messagesElement = document.querySelector(`#${selector} .chat-messages`);
    messagesElement?.scrollIntoView(false);
  }

  getNextChatPage() {
    this.currentPage++;
    this.getThreadMessages();
  }

  getChatByChatId(chatId) {
    return this.store.selectSnapshot(ChatsState.getChats)?.find((item) => item._id === chatId);
  }

  fileDropped(files: FileList): void {
    if (files?.length) {
      this.uploadFileService.setFiles(Array.from(files));
    }
  }

  uploadClipboardFile(uploadData): void {
    if (uploadData.type === 'thread') {
      this.selectedFile = uploadData.retrievedFile;
    }
  }

  uploadFileToThread({ caption = undefined, toastId = undefined, file = undefined }) {
    const body: any = {
      messageId: this.message._id,
      threadId: this.threadId,
      replyingTo: this.message?._id,
      file: file || this.selectedFile,
      linkObject: this.linkObject,
      linkObjectId: this.linkObjectId,
    };

    // in case if we have caption filled in to avoid creating message issue at the back-end
    if (caption) {
      body.text = caption;
    }

    this.store
      .dispatch(new ThreadsUploadFile({ toastId, body }))
      .pipe(take(1))
      .subscribe(
        () => {
          this.selectedFile = null;
        },
        (err) => {
          this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
          this.selectedFile = null;
        },
      );
  }

  onAudioRecorded({ file }) {
    this.uploadFileToThread({ file });
  }

  onFileChanged(files: File[]): void {
    if (files?.length) {
      this.uploadFileService.setFiles(Array.from(files));
    }
  }

  onMessageChanged(text: string): void {
    this.threadMessage = text?.replace(/<[^>]+>/g, '').trim();
  }

  closeThreadSidebar() {
    this.store.dispatch(
      new MarkAll({ chatId: this.message?.chatId, isThread: true, isChat: false }),
    );
    this.store.dispatch(
      new ThreadsSetCurrentThreadHeadMessage({
        messageId: null,
        threadId: null,
      }),
    );
    this.clearThreadId.emit();
  }

  emojiPickerImageFn: Emoji['backgroundImageFn'] = (_set: string, _sheetSize: number) =>
    this.emojiPickerImage;

  // close thread emoji reactions when clicked outside
  @HostListener('document:click', ['$event'])
  onClick(event) {
    const emojiPickerThreadElement = document.querySelector('.emoji-picker-thread');

    if (!event.target.classList.contains('emoji-reactions-button')) {
      if (this.threadEmojiReactionIsOpen && !emojiPickerThreadElement?.contains(event.target)) {
        this.closeEmojiReactions();
      }
    }
  }

  closeEmojiReactions() {
    this.threadEmojiReactionIsOpen = false;
    this.store.dispatch(
      new ChatsEmojiPicker({
        emojiPickerIsOpen: false,
        isThreadEmojiReaction: true,
      }),
    );
  }

  addEmoji({ emoji }) {
    if (!this.threadEmojiReactionIsOpen) {
      this.store.dispatch(new ChatsThreadSetEmoji({ selectedEmoji: emoji }));
    } else {
      const { object, objectId, message } = this.store.selectSnapshot(ChatsState.getChatsInfo);
      this.closeEmojiReactions();

      this.chatService.isReactionProcess = true;

      this.store.dispatch(
        new EmojiCreate({
          body: {
            object: object || 'users',
            objectId: objectId || this.store.selectSnapshot(AuthState.getUser)._id,
            messageObject: 'thread-messages',
            messageObjectId: message._id,
            emojiName: emoji.native,
          },
        }),
      );
      this.getThreadMessages();
    }
  }

  getChatMembers(): void {
    this.members$?.unsubscribe();
    this.members$ = this.store
      .select(ChatsState.getChatMembers)
      .pipe(
        takeUntil(this.destroy$),
        map((filterFn) => filterFn(this.message?.chatId)),
      )
      .subscribe((members) => {
        if (members) {
          const userId = this.store.selectSnapshot(AuthState.getUser)._id;
          this.mentionThreadMembers = [
            'all',
            ...members.filter((item) => item.userId !== userId).map((item) => item.userName),
          ];
          this.cdr.markForCheck();
        }
      });
  }

  subscribeToThread(): void {
    this.thread$?.unsubscribe();
    this.thread$ = this.store
      .select(ThreadsState.getThreadById)
      .pipe(
        takeUntil(this.destroy$),
        map((filterFn) => filterFn(this.threadId)),
      )
      .subscribe((thread) => {
        this.messages = thread?.messages || [];
        this.chatMessageCnt = thread?.messages?.length;
        this.currentPage = thread?.currentPage || 1;
        this.numberOfReplies = thread?.totalCount;
        this.lastMessageLoaded = thread?.messages?.length
          ? thread?.messages[0]?._id === thread?.lastMessageId
          : null;
        this.handleDraftMessage(thread);
        this.cdr.markForCheck();
      });
  }

  private handleSocketReconnection = () => {
    this.currentPage = 1;
    this.getThreadMessages();
  };

  setIsDraggingFile(isDragging: boolean): void {
    this.isDraggingFile = isDragging;
  }

  handleDraftMessage(thread: { threadId?: string; messageDraft?: string }) {
    // set messageDraft - used only when attach files in new thread
    if (!thread?.threadId) {
      this.messageDraft = thread?.messageDraft;
    } else if (thread?.threadId && this.messageDraft) {
      this.messageDraft = null;
    }
  }

  copyTextMobile(value) {
    this.copyText.emit(value);
  }
}
