import { environment } from '../../../environments/environment';
import {
  ChatsVideoCallParticipantJoined,
  ChatsVideoCallParticipantLeft,
} from '../store/actions/chats.action';

export const jitsiApp = {
  id: environment.jitsiAppId,
  domainWeb: '8x8.vc',
  idMobile: 'vpaas-magic-cookie-63952a90c17e476399c3bef350cd8d3b/',
  domainMobile: 'https://8x8.vc',
};

export const JitsiSocketEventsHandler = {
  PARTICIPANT_JOINED: ChatsVideoCallParticipantJoined,
  PARTICIPANT_LEFT: ChatsVideoCallParticipantLeft,
};
