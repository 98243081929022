<div
  #actions
  id="msg-actions-{{ isThread ? 'thread-' + messageIndex : messageIndex }}"
  class="chat-actions flex items-center"
  [ngClass]="chatActionsClass"
  *transloco="let t; read: 'chat'"
>
  <ng-container *ngIf="platform === 'web'">
    <!-- EMOJI REACTION BUTTON -->
    <button
      t-id="message-actions-emoji-reaction"
      class="svg-btn btn btn-sm emoji-reactions-button"
      triggers="hover"
      [ngbTooltip]="t('add-reaction-tooltip')"
      type="button"
      [placement]="'auto'"
      (click)="_openMessageReaction($event, message, messageIndex)"
    >
      <app-svg name="add-smile" width="21" height="18"></app-svg>
    </button>

    <!-- EDIT MESSAGE BUTTON -->
    <button
      *ngIf="message.userId === user?._id && message.text && !message.webhookId"
      id="{{
        !isThread ? 'edit-chat-message-' + messageIndex : 'edit-thread-message-' + messageIndex
      }}"
      t-id="message-actions-edit-action"
      class="svg-btn btn btn-sm"
      triggers="hover"
      [ngbTooltip]="t('edit-message-tooltip')"
      type="button"
      [placement]="'auto'"
      (click)="_onMessageEditStart($event, message, messageIndex)"
    >
      <app-svg name="pen" width="16" height="16"></app-svg>
    </button>

    <!-- DELETE MESSAGE BUTTON -->
    <button
      *ngIf="message.userId === user?._id && message.linkObject !== 'tickets'"
      t-id="message-actions-delete-action"
      class="svg-btn btn btn-sm"
      triggers="hover"
      [ngbTooltip]="t('delete-message-tooltip')"
      type="button"
      [placement]="'auto'"
      (click)="_onMessageDelete($event, message, isThread)"
    >
      <app-svg name="trash" width="14" height="16"></app-svg>
    </button>

    <!-- CONVERSION BUTTON -->
    <span
      *ngIf="!message?.linkObject && !isThread && !message.pollId"
      class="dropdown"
      ngbDropdown
      [autoClose]="true"
      [placement]="'auto'"
      (openChange)="convertDropdownToggle($event)"
    >
      <button
        t-id="message-actions-convert-into-action"
        class="svg-btn btn btn-sm dropdown-toggle"
        ngbDropdownToggle
        data-toggle="dropdown"
        triggers="hover"
        [ngbTooltip]="t('convert-into-tooltip')"
        type="button"
        [placement]="'auto'"
        [disableTooltip]="convertDropdownOpen || platform !== 'web'"
      >
        <app-svg name="arrow-right-arrow-left" width="18" height="16"></app-svg>
      </button>
      <span t-id="message-actions-convert-menu-item" ngbDropdownMenu class="dropdown-menu m-0 pb-0">
        <a
          href="javascript:"
          class="dropdown-item flex items-center"
          *ngFor="let item of convertIntoList"
          (click)="_convertMessage(item.action, message)"
        >
          <app-svg name="{{ item.icon }}" width="16" height="16"></app-svg>
          <span t-id="message-actions-buttons-convert-item" class="ml-2">{{ item.title }}</span>
        </a>
      </span>
    </span>
    <!-- Mark as unread BUTTON -->
    <button
      t-id="message-actions-attachment-download-action"
      class="svg-btn btn btn-sm"
      triggers="hover"
      [ngbTooltip]="message.isUnread ? t('mark-as-read') : t('mark-as-unread')"
      type="button"
      [placement]="'auto'"
      (click)="_markAsUnread(message)"
    >
      @if (!message.isUnread) {
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="#A8AABD"
          class="size-8"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
          />
        </svg>
      } @else {
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="#A8AABD"
          class="size-8"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
          />
        </svg>
      }
    </button>

    <!-- DOWNLOAD ATTACHMENT BUTTON -->
    <button
      *ngIf="message.fileData && !isDocument(message.fileData)"
      t-id="message-actions-attachment-download-action"
      class="svg-btn btn btn-sm"
      triggers="hover"
      [ngbTooltip]="t('download-file-tooltip')"
      type="button"
      [placement]="'auto'"
      (click)="_downloadFile(message)"
    >
      <app-svg name="download" width="16" height="16"></app-svg>
    </button>

    <!-- DOWNLOAD SHARED MESSAGE ATTACHMENT BUTTON -->
    <button
      *ngIf="message.sharedMessage?.fileData"
      t-id="message-actions-attachment-download-action"
      class="svg-btn btn btn-sm"
      triggers="hover"
      [ngbTooltip]="t('download-shared-file-tooltip')"
      type="button"
      [placement]="'auto'"
      (click)="_downloadFile(message.sharedMessage)"
    >
      <app-svg name="download" width="16" height="16"></app-svg>
    </button>

    <!-- OPEN ATTACHMENT BUTTON -->
    <button
      *ngIf="message.fileData"
      t-id="message-actions-save-data-room-action"
      class="svg-btn btn btn-sm"
      triggers="hover"
      [ngbTooltip]="t('save-to-dataroom-tooltip')"
      type="button"
      [placement]="'auto'"
      (click)="_openDataRoomModal(message)"
    >
      <app-svg name="folder-plus" width="16" height="14"></app-svg>
    </button>

    <!-- SHARE MESSAGE BUTTON -->
    <button
      *ngIf="!isThread && !message.sharedMessage && !space?.isPersonal && !message.pollId"
      t-id="message-actions-share-action"
      class="svg-btn btn btn-sm"
      triggers="hover"
      [ngbTooltip]="t('share-message-tooltip')"
      type="button"
      [placement]="'auto'"
      (click)="_onMessageForward(message)"
    >
      <app-svg name="share" width="17" height="16"></app-svg>
    </button>

    <!-- SHOW THREAD BUTTON -->
    <button
      *ngIf="!isThread"
      t-id="message-actions-show-thread-action"
      class="svg-btn btn btn-sm open-thread"
      triggers="hover"
      [ngbTooltip]="t('reply-in-thread-tooltip')"
      type="button"
      [placement]="'auto'"
      (click)="_openThreadSidebar(message)"
    >
      <app-svg name="comment-dots" width="17" height="14"></app-svg>
    </button>

    <!-- UNPIN MESSAGE BUTTON -->
    <button
      *ngIf="
        checkDirectMessage &&
          !isThread &&
          canUnpinMessage &&
          (message | checkIsPin: pinnedMessage : 'messages');
        else pinMessage
      "
      t-id="message-actions-unpin-action"
      class="svg-btn btn btn-sm"
      triggers="hover"
      [ngbTooltip]="t('unpin-message-tooltip')"
      type="button"
      [placement]="'auto'"
      [disabled]="isLoadingPin"
      (click)="_unpinMessage(message)"
    >
      <svg-icon
        *ngIf="!isLoadingPin; else isLoadingMessage"
        class="pin-icon-action"
        src="assets/icons/pin/unpin-message.svg"
        [applyClass]="true"
      ></svg-icon>
      <ng-template #isLoadingMessage>
        <i class="mr-1 fa fa-spinner fa-spin connection_spinner" aria-hidden="true"></i>
      </ng-template>
    </button>

    <ng-template #pinMessage>
      <!-- PIN MESSAGE BUTTON -->
      <button
        *ngIf="
          checkDirectMessage &&
          !isThread &&
          checkMessageMax &&
          !(message | checkIsPin: pinnedMessage : 'messages')
        "
        t-id="message-actions-pin-action"
        class="svg-btn btn btn-sm"
        ngbTooltip="Pin message"
        triggers="hover"
        [ngbTooltip]="t('pin-message-tooltip')"
        type="button"
        [placement]="'auto'"
        [disabled]="isLoadingPin"
        (click)="_pinMessage(message)"
      >
        <svg-icon
          *ngIf="!isLoadingPin; else isLoadingMessage"
          class="pin-icon-action"
          src="assets/icons/pin/pin-action.svg"
          [applyClass]="true"
        ></svg-icon>
        <ng-template #isLoadingMessage>
          <i class="fa fa-spinner fa-spin connection_spinner" aria-hidden="true"></i>
        </ng-template>
      </button>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="platform !== 'web'">
    <!-- EMOJI REACTION BUTTON -->
    <button
      class="svg-btn btn btn-sm"
      triggers="hover"
      [ngbTooltip]="t('actions-tooltip')"
      type="button"
      [placement]="placement"
      (click)="_openActionsSheet(message, messageIndex)"
    >
      <app-svg name="ellipsis" width="17" height="18"></app-svg>
    </button>
  </ng-container>
</div>
