<div
  #textMessage
  *ngIf="message?.text"
  t-id="text-message-chat"
  data-sentry-mask
  id="message-text-{{ message._id }}"
  appExternalLink
  class="message-text ql-editor"
  [ngClass]="{
    'message-text-light': config?.layout.variant === 'Light',
    'message-text-dark': config?.layout.variant === 'Dark',
    'message-text-uploading': !message._id
  }"
  [ngStyle]="{ 'font-size': isEmojisOnly(message.text) ? '25px' : '14px' }"
  [innerHtml]="
    message.text
      | highlightChatMembers: mentionChatMembers
      | highlightTickets: message.chatTickets
      | safeHtml
  "
></div>
