import { Component, Input, OnInit } from '@angular/core';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-sub-tabs',
  templateUrl: './sub-tabs.component.html',
  styleUrls: ['./sub-tabs.component.scss'],
  standalone: true,
  imports: [NgFor, RouterLinkActive, RouterLink],
})
export class SubTabsComponent implements OnInit {
  @Input() tabs: { title: string; route: string }[] = [];
  @Input() isMobile = false;

  constructor() {}

  ngOnInit(): void {}
}
