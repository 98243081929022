import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ConfigService } from '../../shared/services/config.service';
import { SprintCreate, SprintUpdate } from '../../shared/store/actions/sprint.action';
import { SprintModal } from '../../shared/store/models/SprintState';
import { AuthState } from '../../shared/store/states/auth.state';
import { DateTimePickerComponent } from '../../shared/components/date-time-picker/date-time-picker.component';
import { SvgComponent } from '../../shared/svgs/svg/svg.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-sprint-create-edit',
  templateUrl: './sprint-create-edit.component.html',
  styleUrls: ['./sprint-create-edit.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    SvgComponent,
    DateTimePickerComponent,
  ],
})
export class SprintCreateEditComponent implements OnInit {
  @Output() onCreateSprint = new EventEmitter<void>();
  platform = 'web';
  public sprintForm: FormGroup;
  public object: any;

  public objectId: string;

  public sprint: SprintModal | null = null;

  public isStartSprint = false;
  public isUploading: boolean;

  public isErrorAboutTimeRange = false;

  public config: any = {};

  private destroy$: Subject<void> = new Subject<void>();
  isPastDate = false;

  constructor(
    private fb: FormBuilder,
    private activeModal: NgbActiveModal,
    private store: Store,
    protected configService: ConfigService,
    private readonly toast: ToastrService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);
    this.config = this.configService.templateConf;
    this.initializeSprintForm();
    this.initializeSubscribers();
    this.handleEditModeForSprint();
  }

  /* Getters */
  public get title(): string {
    return this.sprint
      ? this.isStartSprint
        ? this.translocoService.translate('modals.sprint.start-sprint-title')
        : this.translocoService.translate('modals.sprint.edit-sprint-title')
      : this.translocoService.translate('modals.sprint.create-sprint-title');
  }

  public get rightBtnLabel(): string {
    return this.sprint
      ? this.isStartSprint
        ? this.translocoService.translate('modals.sprint.btn-start')
        : this.translocoService.translate('modals.sprint.btn-save-changes')
      : this.translocoService.translate('modals.sprint.btn-create');
  }

  public get isDisabledBtn(): boolean {
    return (
      this.isErrorAboutTimeRange ||
      !this.sprintForm.controls.name.valid ||
      !this.sprintForm.controls.startDate.valid ||
      !this.sprintForm.controls.endDate.valid ||
      this.isUploading ||
      this.isPastDate
    );
  }

  public createOrSaveSprint(): void {
    const payload = {
      object: this.object,
      objectId: this.objectId,
      title: this.sprintForm.value.name,
      order: this.sprint ? this.sprint.order : 0,
      start: this.sprintForm.value.startDate.toISOString(),
      end: this.sprintForm.value.endDate.toISOString(),
      goal: this.sprintForm.value.goals,
      isActive: this.isStartSprint || (this.sprint ? this.sprint.isActive : false),
    };

    this.isUploading = true;

    if (this.sprint) {
      const preparePayloadForUpdate = {
        id: this.sprint._id,
        body: payload,
      };
      this.store
        .dispatch(new SprintUpdate(preparePayloadForUpdate))
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.toast.success(
              this.isStartSprint
                ? this.translocoService.translate('toastr.sprint-started')
                : this.translocoService.translate('toastr.sprint-updated'),
            );
            this.closeModal();
          },
          error: () => {
            this.isUploading = false;
            this.toast.error(this.translocoService.translate('toastr.something-went-wrong'));
          },
        });
    } else {
      this.store
        .dispatch(new SprintCreate(payload))
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.toast.success(this.translocoService.translate('toastr.sprint-created'));
            this.onCreateSprint.emit();
            this.closeModal();
          },
          error: () => {
            this.toast.error(this.translocoService.translate('toastr.something-went-wrong'));
            this.isUploading = false;
          },
        });
    }
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  private handleEditModeForSprint(): void {
    if (this.sprint) {
      this.sprintForm.patchValue({
        name: this.sprint.title,
        startDate: new Date(this.sprint.start),
        endDate: new Date(this.sprint.end),
        goals: this.sprint.goal,
      });
    }
  }

  private handleErrorWithTimeRange(values): void {
    this.isErrorAboutTimeRange = moment(values.startDate).diff(moment(values.endDate)) >= 0;
  }

  private initializeSprintForm(): void {
    this.sprintForm = this.fb.group({
      name: ['', [Validators.required]],
      startDate: [new Date(), [Validators.required]],
      endDate: [moment().add(10, 'days').toDate(), [Validators.required]],
      goals: ['', []],
    });
  }

  private initializeSubscribers(): void {
    this.sprintForm.valueChanges
      .pipe(
        tap((values) => {
          this.handleErrorWithTimeRange(values);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  setIsPastDate(value: boolean) {
    this.isPastDate = value;
  }
}
