<!-- Taskboard Starts -->
<section
  id="taskboard"
  class="user-taskboard scrollable-container"
  [ngClass]="{
    'board-light': !(isDark$ | async),
    'board-dark': isDark$ | async,
    'board-android': platform === 'android',
    'board-ios': platform === 'ios',
    'board-landscape': orientation === 'landscape'
  }"
  *transloco="let t; read: 'board'"
  t-id="user-board-taskboard"
>
  <ng-scrollbar [autoHeightDisabled]="false" visibility="hover" thumbClass="white-scrollbars">
    <app-custom-spinner
      *ngIf="isLoading | async"
      [smallSize]="true"
      [fullContainer]="true"
    ></app-custom-spinner>

    <div class="board-filters d-flex align-items-center px-2 pb-2">
      <div class="search-container d-flex align-items-center">
        <input
          #searchInput
          id="searchTickets"
          class="form-control search-field"
          type="text"
          [placeholder]="'&#xE8BD;&nbsp; ' + t('user-search-tickets-placeholder')"
          required
          [(ngModel)]="searchedText"
        />
        <span
          *ngIf="searchedText?.length > 0"
          class="d-flex justify-content-end align-items-center"
        >
          <app-svg
            name="times-circle"
            (click)="clearSearch()"
            class="cursor-pointer"
            [width]="14"
            [height]="14"
          ></app-svg>
        </span>
      </div>

      <ng-select
        class="labels-filter mx-2"
        [placeholder]="t('user-labels-filter-placeholder')"
        bindLabel="label"
        [items]="groupedLabels"
        [multiple]="true"
        [closeOnSelect]="false"
        [searchable]="true"
        [(ngModel)]="filteredLabels"
        (change)="filterTicketsByLabels()"
      >
        <ng-template ng-multi-label-tmp let-items="items">
          <span class="ng-placeholder d-flex align-items-center">
            {{
              t('labels-filter-option-placeholder', {
                value: getSelectedLabelsCount(items, groupedLabels) > 1 ? 's' : ''
              })
            }}

            <span class="primary-circle board-filters-circle ml-1">
              {{ getSelectedLabelsCount(items, groupedLabels) }} /
              {{ groupedLabels?.length }}
            </span>
          </span>
        </ng-template>

        <ng-template ng-option-tmp let-item="item" let-item$="item$">
          <input type="checkbox" class="mr-2" [ngModel]="item$.selected" />
          <span>{{ item.label }}</span>
        </ng-template>
      </ng-select>

      <ng-select
        bindLabel="title"
        bindValue="_id"
        [placeholder]="t('filter-by-boards')"
        class="board-select"
        [items]="boards"
        [multiple]="true"
        [clearable]="false"
        [searchable]="false"
        [closeOnSelect]="false"
        [selectableGroup]="true"
        [selectableGroupAsModel]="true"
        [(ngModel)]="isCheckedBoards"
        (change)="changeSelectedBoards()"
      >
        <ng-template ng-multi-label-tmp let-items="items">
          <span class="ng-placeholder d-flex align-items-center">
            {{
              t('boards-filter-option-placeholder', {
                value: getSelectedItemsCount(items, boards) > 1 ? 's' : ''
              })
            }}
            <span class="primary-circle board-filters-circle ml-1">
              {{ getSelectedItemsCount(items, boards) }} / {{ boards.length }}
            </span>
          </span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <input
            id="item-{{ index }}"
            type="checkbox"
            [ngModel]="item$.selected"
            class="mr-2"
            [class.ml-2]="item.spaceId"
          />
          <span>{{ item.title }}</span>
        </ng-template>
      </ng-select>
    </div>

    <div id="columnsScroll" class="d-flex scrollable-container">
      <div
        t-id="kanban-board-item"
        class="columns-container"
        [ngClass]="{
          'columns-light': !(isDark$ | async),
          'columns-dark': isDark$ | async
        }"
      >
        <div class="d-flex column-container">
          <div id="columns" class="d-flex">
            <ng-container
              *ngIf="columnsData"
              [ngTemplateOutlet]="columnTemplate"
              [ngTemplateOutletContext]="{
                $implicit: columns[0].title,
                tickets$: todoTickets$
              }"
            ></ng-container>
            <ng-container
              *ngIf="columnsData"
              [ngTemplateOutlet]="columnTemplate"
              [ngTemplateOutletContext]="{
                $implicit: columns[1].title,
                tickets$: inWorkTickets$
              }"
            ></ng-container>
          </div>
        </div>

        <div *ngIf="isBoardEmpty" class="empty-state">
          <div class="empty-state-container">
            <img
              src="assets/img/svg/board-empty-state-{{ !(isDark$ | async) ? 'light' : 'dark' }}.svg"
              alt="empty-state"
            />
            <div class="title">{{ t('empty-state-title') }}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-scrollbar>
</section>
<!-- Taskboard Ends -->

<ng-template #columnTemplate let-columnName let-tickets$="tickets$">
  <div
    id="column-{{ columnsData[columnName]?.id }}"
    class="column-item-container"
    [class.dark]="isDark$ | async"
  >
    <div class="column-item">
      <div class="fixed-header">
        <h5 class="column-header d-flex align-items-center">
          <span class="column-title flex-grow-1">
            {{ translateTitle(columnsData[columnName]?.slug) }}
            <!--{{ columnsData[columnName].isLocked && columnsData[columnName].displayName ? columnsData[columnName].displayName : columnName }}-->
          </span>
          <!-- TODO(David): avoid using $any() -->
          <ng-container *ngIf="$any(tickets$ | async)?.length">
            <span
              class="badge column-title-badge mr-1"
              [ngClass]="{
                'column-issue-badge-light': !(isDark$ | async),
                'column-issue-badge-dark': isDark$ | async
              }"
            >
              {{ $any(tickets$ | async)?.length }}
            </span>
          </ng-container>
        </h5>
      </div>

      <div
        *ngIf="!isBoardEmpty"
        class="column-body"
        id="column-body-{{ columnsData[columnName]?.id }}"
        t-id="kanban-board-body-column"
        title="{{ translateTitle(columnsData[columnName]?.slug) }}"
      >
        <div class="dragdrop-container" [id]="columnsData[columnName]?.id" [title]="columnName">
          <app-kanban-board-ticket
            *rxFor="let ticket of tickets$; let i = index; trackBy: identify"
            [ticket]="ticket"
            [tickets]="tickets$ | async"
            [columnName]="columnName"
            [isFromDashboard]="true"
            [customFieldsConfig]="customFieldsConfig"
            [getLinkSpaceSprint]="getLinkSpaceSprint"
            [getLinkProjectSprint]="getLinkProjectSprint"
            [deleteTicket]="deleteTicket.bind(this)"
          ></app-kanban-board-ticket>
        </div>
      </div>
    </div>
  </div>
</ng-template>
