export enum BoardTypes {
  Backlog = 'backlog',
  Sprint = 'sprint',
  Board = 'kanban',
  Archive = 'archive',
}

// used in drop down board menu be careful with changes
export enum BoardLabels {
  Backlog = 'Backlog',
  Sprint = 'Sprint',
  Board = 'Board',
  Archive = 'Archive',
}

export enum TicketTypes {
  ForBacklog,
  ForSprint,
  ForBoard,
  ForArchive,
}
