import {
  ElementRef,
  ViewContainerRef,
  ChangeDetectorRef,
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigService } from '../../../../services/config.service';
import { ChatsState } from '../../../../store/states/chats.state';
import { SpacesState } from '../../../../store/states/spaces.state';
import { SpacesDbDto } from '../../../../../api/models/spaces-db-dto';
import { PinType } from '../../chat-pinned-messages/enums/pin-type.enum';
import { RolesTypeEnum } from '../../chat-pinned-messages/enums/roles-type.enum';
import { LocalStorageService } from 'ngx-localstorage';
import { Router } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { CheckIsPinPipeAlone } from '../../../../../standalone/pipes/check-isPin.pipe';
import { SvgComponent } from '../../../../svgs/svg/svg.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MarkAsRead, MarkAsUnread } from '../../../../store/actions/chats.action';

export enum ConversionActionType {
  Ticket = 'TICKET',
  Event = 'EVENT',
  Note = 'NOTE',
}

@Component({
  selector: 'app-message-actions-buttons',
  templateUrl: './message-actions-buttons.component.html',
  styleUrls: ['./message-actions-buttons.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    NgbTooltipModule,
    NgbModule,
    CheckIsPinPipeAlone,
    SvgComponent,
    AngularSvgIconModule,
  ],
})
export class MessageActionsButtonsComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() message: any;
  @Input() user: any;
  @Input() isThread: any;
  @Input() platform: string;
  @Input() messageIndex: any;
  @Input() objectId = null;
  @Input() isLoadingPin = false;

  @Output() downloadFile: EventEmitter<any> = new EventEmitter(null);
  @Output() openThreadSidebar: EventEmitter<any> = new EventEmitter(null);
  @Output() onMessageForward: EventEmitter<any> = new EventEmitter(null);
  @Output() openDataRoomModal: EventEmitter<any> = new EventEmitter(null);
  @Output() onMarkAsUnread: EventEmitter<any> = new EventEmitter(null);
  @Output() convertMessage: EventEmitter<any> = new EventEmitter(null);
  @Output() onMessageDelete: EventEmitter<any> = new EventEmitter(null);
  @Output() onMessageEditStart: EventEmitter<any> = new EventEmitter(null);
  @Output() openMessageReaction: EventEmitter<any> = new EventEmitter(null);
  @Output() openActionsSheet: EventEmitter<any> = new EventEmitter(null);
  @Output() pinMessage: EventEmitter<any> = new EventEmitter(null);
  @Output() unPinMessage: EventEmitter<any> = new EventEmitter(null);

  @ViewChild('actions', { read: ViewContainerRef })
  actionsElement: ViewContainerRef;

  destroy$: Subject<void> = new Subject<void>();
  config: any;
  space: SpacesDbDto;
  chatActionsClass: any;
  placement = 'top';
  canUnpinMessage = true;
  stopMarkAll = false;

  convertDropdownOpen = false;
  convertIntoList: {
    title: string;
    icon: string;
    action: ConversionActionType;
    translation?: string;
  }[] = [
    {
      title: this.translacoService.translate('chat.event-convert'),
      icon: 'calendar',
      action: ConversionActionType.Event,
      translation: 'chat.event-convert',
    },
    {
      title: this.translacoService.translate('chat.ticket-convert'),
      icon: 'board',
      action: ConversionActionType.Ticket,
      translation: 'chat.ticket-convert',
    },
    {
      title: this.translacoService.translate('chat.note-convert'),
      icon: 'pen',
      action: ConversionActionType.Note,
      translation: 'chat.note-convert',
    },
  ];

  constructor(
    private configService: ConfigService,
    private store: Store,
    public cdr: ChangeDetectorRef,
    private el: ElementRef,
    private router: Router,
    private localStorageService: LocalStorageService,
    private translacoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit() {
    this.space = this.store
      .selectSnapshot(SpacesState.getLoadedSpaces)
      .find((space) => space._id === this.objectId);

    const pinMessage = this.getPinnedMessage(this.message);
    if (pinMessage) {
      this.canUnpinMessage = this.canDeletePin(pinMessage);
    }

    this.translacoService.langChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const newItems = this.convertIntoList.map((item) => ({
        ...item,
        title: this.translacoService.translate(item.translation),
      }));
      this.convertIntoList = [...newItems];
    });

    this.getTooltipPlacement();
  }

  ngAfterViewInit() {
    this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;

        this.chatActionsClass = {
          'chat-actions-light': this.config?.layout.variant === 'Light',
          'chat-actions-dark': this.config?.layout.variant === 'Dark',
        };

        this.cdr.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public get pinnedMessage() {
    return this.store.selectSnapshot(ChatsState.getPinMessages);
  }

  public get checkDirectMessage() {
    return !this.router.url.includes('page=dm');
  }

  public get checkMessageMax(): boolean {
    return (
      this.pinnedMessage.filter((pin) => pin.linkDocument && pin.linkDocument === PinType.Message)
        ?.length <= 14
    );
  }

  convertDropdownToggle(e) {
    this.convertDropdownOpen = e;
  }

  _openMessageReaction(e, message: any, messageIndex: number): void {
    e.preventDefault();
    e.stopPropagation();

    const element = document.getElementById(
      `msg-actions${this.isThread ? '-thread' : ''}-${messageIndex}`,
    );

    this.openMessageReaction.emit({
      message,
      messageIndex,
      quickReaction: false,
      passedElement: element,
    });
  }

  _onMessageEditStart(e, message, index): void {
    e.preventDefault();
    e.stopPropagation();

    const element = document.getElementById(
      `edit-${this.isThread ? 'thread' : 'chat'}-message-${index}`,
    );

    this.onMessageEditStart.emit({ message, passedElement: element });
  }

  _onMessageDelete(e, message, isThread): void {
    e.preventDefault();
    e.stopPropagation();

    this.onMessageDelete.emit({ message, isThread });
  }

  _convertMessage(action: ConversionActionType, message: any): void {
    this.convertMessage.emit({
      action,
      messageId: message._id,
      chatType: message.chatType,
      text: message.text,
      attachment: message.fileData,
    });
  }

  _downloadFile(message: any): void {
    this.downloadFile.emit(message);
  }

  _openDataRoomModal(message: any): void {
    this.openDataRoomModal.emit(message);
  }

  _markAsUnread(message: any): void {
    if (message.isUnread) {
      this.store.dispatch(new MarkAsRead({ message }));
    } else {
      this.store.dispatch(new MarkAsUnread({ message }));
      this.stopMarkAll = true;
    }
    this.onMarkAsUnread.emit(message);
  }

  _onMessageForward(message: any): void {
    this.onMessageForward.emit(message);
  }

  _openThreadSidebar(message: any): void {
    this.openThreadSidebar.emit(message);
  }

  _pinMessage(message: any): void {
    this.pinMessage.emit(message);
  }

  _unpinMessage(message: any): void {
    this.unPinMessage.emit(message);
  }

  _openActionsSheet(message, messageIndex) {
    this.openActionsSheet.emit({ message, messageIndex });
  }

  getTooltipPlacement(): void {
    const lastMessageId = this.store.selectSnapshot(ChatsState.getLastMessageId);
    if (
      this.el.nativeElement.getBoundingClientRect().top < 400 ||
      this.message?._id === lastMessageId
    ) {
      this.placement = 'bottom';
    }
  }

  private getPinnedMessage(message) {
    return this.store
      .selectSnapshot(ChatsState.getPinMessages)
      .find((pin: any) => pin.linkDocument && pin.linkMessage?._id === message._id);
  }

  private canDeletePin(item): boolean {
    const userId = this.localStorageService.get('userId') || '';
    const chatId = this.store
      .selectSnapshot(ChatsState.getChats)
      .find((chat) => chat.objectId === this.objectId)?._id;
    const allUser = this.store.selectSnapshot(ChatsState.getChatMembers)(chatId);
    const currentUser = allUser?.find((user) => user.userId === userId);

    return (
      userId === item.creatorId ||
      currentUser?.roleName?.includes(
        RolesTypeEnum.Owner || RolesTypeEnum.SpaceLeader || RolesTypeEnum.ProjectLeader,
      )
    );
  }

  isDocument(fileData: any): boolean {
    const allowedExtensions = ['.pdf', '.zip', '.doc', '.webp', '.docx', '.xlsx', '.pptx'];
    const fileName = fileData?.fileName || '';
    const fileExtension = fileName.substr(fileName.lastIndexOf('.'));
    return allowedExtensions.includes(fileExtension);
  }
}
