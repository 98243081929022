import { Action, Selector, State, StateContext } from '@ngxs/store';
import { BoardsFieldStateModel } from '../models/BoardsFieldState';
import { Injectable } from '@angular/core';
import {
  CreateField,
  DeleteField,
  FieldGetList,
  UpdateField,
  UpdateFields,
} from '../actions/board-field.action';
import { BoardsFieldsService } from '../../../api/services/boards-fields.service';
import { tap } from 'rxjs/operators';
import { TicketService } from '../../services/ticket.service';

@State<BoardsFieldStateModel>({
  name: 'BoardsField',
  defaults: {
    fields: null,
  },
})
@Injectable()
export class BoardsFieldState {
  /**
   * Get tickets list
   * @param  {BoardsFieldStateModel} state
   */
  @Selector()
  static getFieldList(state: BoardsFieldStateModel) {
    return state.fields;
  }

  @Selector()
  static getFieldsList(state: BoardsFieldStateModel) {
    return state.fields.fields;
  }

  constructor(
    private boardFieldService: BoardsFieldsService,
    private ticketService: TicketService,
  ) {}

  @Action(FieldGetList)
  field_get({ patchState }: StateContext<BoardsFieldStateModel>, action: FieldGetList) {
    const { isInternalState, ...rest } = action.payload;
    return this.boardFieldService.ticketsFieldsGetList(rest).pipe(
      tap(
        (result) => {
          if (isInternalState) {
            // set ticket fields into service state without changing in global
            this.ticketService.setTicketFields(result);
          } else {
            patchState({ fields: result });
            return result;
          }
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Create field
   * @param  {BoardsFieldStateModel} state
   * @param  {CreateField} action
   */
  @Action(CreateField)
  field_create({ getState, patchState }: StateContext<BoardsFieldStateModel>, action: CreateField) {
    return this.boardFieldService.ticketsFieldsCreate({ body: action.payload }).pipe(
      tap(
        (result) => {
          const state = getState();
          patchState({
            fields: {
              fields: [...state.fields.fields, result],
              dateFormats: state.fields.dateFormats,
              dateTimeFormats: state.fields.dateTimeFormats,
              types: state.fields.types,
            },
          });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update field
   * @param  {BoardsFieldStateModel} state
   * @param  {UpdateField} action
   */
  @Action(UpdateField)
  field_update({ getState, patchState }: StateContext<BoardsFieldStateModel>, action: UpdateField) {
    return this.boardFieldService.ticketsFieldsBulkUpdate_1(action.payload).pipe(
      tap(
        (result) => {
          const state = getState();
          patchState({
            fields: {
              fields: state.fields.fields.map((field) => {
                if (field._id === result._id) {
                  return result;
                }
                return field;
              }),
              dateFormats: state.fields.dateFormats,
              dateTimeFormats: state.fields.dateTimeFormats,
              types: state.fields.types,
            },
          });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update field
   * @param  {BoardsFieldStateModel} state
   * @param  {UpdateField} action
   */
  @Action(DeleteField)
  field_delete({ getState, patchState }: StateContext<BoardsFieldStateModel>, action: DeleteField) {
    return this.boardFieldService.ticketsFieldsDelete(action.payload).pipe(
      tap(
        () => {
          const state = getState();
          patchState({
            fields: {
              fields: state.fields.fields.filter((field) => field._id !== action.payload.id),
              dateFormats: state.fields.dateFormats,
              dateTimeFormats: state.fields.dateTimeFormats,
              types: state.fields.types,
            },
          });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Get field values
   * @param  {BoardsFieldStateModel} state
   * @param  {UpdateFields} action
   */
  @Action(UpdateFields)
  field_values_get(
    { getState, patchState }: StateContext<BoardsFieldStateModel>,
    action: UpdateFields,
  ) {
    return this.boardFieldService.ticketsFieldsBulkUpdate({ body: action.payload }).pipe(
      tap(
        (res) => {
          const state = getState();
          patchState({ fields: { ...state.fields, fields: res } });
          return res;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }
}
