import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { Store } from '@ngxs/store';

import { FilesHelper } from '../../../../utils/files-helper';
import { ConfigService } from '../../../../services/config.service';
import { GenericMessageComponent } from '../generic-message/generic-message.component';
import { MessageFilesComponent } from '../../chat-messages/message-files/message-files.component';
import { TextMessageComponent } from '../text-message/text-message.component';

@Component({
  selector: 'app-doc-message',
  templateUrl: './doc-message.component.html',
  styleUrls: ['./doc-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TextMessageComponent, MessageFilesComponent],
})
export class DocMessageComponent extends GenericMessageComponent {
  @HostBinding('attr.t-id') elementTestId = 'doc-message';
  @Input() platform: string;
  @Input() object: string;
  @Input() objectId: string;

  constructor(
    cdr: ChangeDetectorRef,
    configService: ConfigService,
    public filesHelper: FilesHelper,
    store: Store,
  ) {
    super(cdr, filesHelper, configService, store);
  }

  get isMobile(): boolean {
    return this.platform !== 'web';
  }
}
