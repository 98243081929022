<ng-container *transloco="let t; read: 'modals.board-ticket'">
  <div
    *ngIf="modalData?.data"
    t-id="board-ticket-create-modal-view"
    class="board-modal"
    [ngClass]="{
      'board-modal-mobile': platform !== 'web',
      dark: config.layout.variant === 'Dark'
    }"
  >
    <div class="modal-header">
      <div class="close close--mobile" aria-label="Close" (click)="close()">
        <app-svg name="arrow-right-arrow-left" width="18" height="16"></app-svg>
      </div>

      <h5 class="modal-title m-0">
        <span class="modal-title-ctr d-flex">
          {{ pageTitle }}
          <span *ngIf="modalData.data?.boardAbbreviation" class="d-flex ml-2">
            <span
              *ngIf="modalData.data?.parentId && parentTask?.boardAbbreviation"
              class="d-flex flex-row align-items-center"
            >
              <app-svg
                name="sub-task"
                width="22"
                height="20"
                class="mr-2"
                ngbTooltip="This subtask has parent ticket"
                container="body"
              ></app-svg>
              {{ parentTask.boardAbbreviation }}-{{ parentTask.counter }}
              <span class="mx-1">/</span>
            </span>
            <b t-id="board-ticket-number-task"
              >{{ modalData.data?.boardAbbreviation }}-{{ modalData.data?.counter }}</b
            >
          </span>

          <span
            *ngIf="modalData?.lastTicketAbbr"
            class="badge badge-disabled font-small-2 ml-2"
            t-id="board-ticket-last-created"
          >
            <span
              >{{ t('last-ticket') }}: <b>{{ modalData?.lastTicketAbbr }}</b></span
            >
          </span>
        </span>
        <span class="modal-title-ctr--mobile text-capitalize">{{ pageTitleBack }}</span>
      </h5>
      <div class="close close--desktop" aria-label="Close" (click)="close()">
        <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
      </div>
    </div>

    <form *ngIf="ticketForm" [formGroup]="ticketForm" (ngSubmit)="submitForm()">
      <div
        class="modal-body"
        [ngClass]="{
          'modal-light': config?.layout.variant === 'Light',
          'modal-dark': config?.layout.variant === 'Dark'
        }"
      >
        <ng-scrollbar class="scroll-event scrollable-container" thumbClass="white-scrollbars">
          <div class="ticket-modal-content">
            <div class="col-xl-7">
              <div class="d-flex mb-2">
                <div class="fieldset-status position-relative"></div>
                <div class="d-flex align-items-center ml-2 creator-container">
                  <div class="creator-title">{{ t('creator') }}</div>
                  <div class="creator-name mx-2">{{ ticketCreatorName }}</div>
                  <app-avatar [userId]="ticketCreator" [width]="36" [height]="36"></app-avatar>
                </div>
              </div>

              <fieldset class="form-group position-relative">
                <textarea
                  t-id="board-ticket-title"
                  id="ticketTitle"
                  formControlName="title"
                  [placeholder]="t('placeholder-epic-name')"
                  required
                  appTextareaAutoresize
                  class="form-control title-control"
                  formControlName="title"
                  [appFocus]="!modalData?.title && platform === 'web'"
                  [attr.data-tabindex]="tabIndexes[0]?.tabIndex"
                  (keydown.enter)="$event.preventDefault()"
                  (focus)="inputFocused('ticketTitle')"
                ></textarea>
              </fieldset>

              <legend>{{ t('description') }}</legend>
              <quill-editor
                id="ticketDescription"
                class="description-editor"
                bounds="self"
                [class.show-toolbar]="
                  toggleDescriptionEditVisibility ||
                  ticketForm.controls['description'].value?.length === 0
                "
                [modules]="editorModules"
                [placeholder]="t('placeholder-epic-description')"
                formControlName="description"
                [attr.data-tabindex]="tabIndexes[1]?.tabIndex"
                (onFocus)="
                  toggleDescriptionEditVisibility = true; inputFocused('ticketDescription')
                "
                (onBlur)="descriptionBlur()"
                (onEditorCreated)="editorDescriptionCreated($event)"
              ></quill-editor>

              <div
                *ngIf="toggleDescriptionEditVisibility"
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <div class="font-small-1">
                  {{ t('images-size-warning', { size: '1' }) }}
                </div>
                <button
                  *ngIf="ticketForm.controls['description'].value?.length > 0"
                  class="btn btn-sm btn-subtle"
                  type="button"
                  (click)="toggleDescriptionEditVisibility = false"
                >
                  {{ t('btn-close') }}
                </button>
              </div>

              <div class="d-flex align-items-center justify-content-between mb-1">
                <legend class="mb-0">{{ t('attachments') }}</legend>
                <button
                  t-id="board-ticket-attach-a-file"
                  class="btn btn-sm btn-subtle d-flex align-items-center upload-btn"
                  type="button"
                  [attr.data-tabindex]="tabIndexes[2]?.tabIndex"
                  (click)="attachFileInput.click()"
                >
                  <app-svg name="upload" width="11" height="11" class="mr-1"></app-svg>
                  <span>{{ t('upload-files') }}</span>
                </button>
              </div>

              <ng-scrollbar
                *ngIf="fileData?.length > 0"
                class="scroll-event scrollable-container ticket-files"
                thumbClass="white-scrollbars"
                track="all"
                visibility="always"
              >
                <table class="table ticket-files-table m-0">
                  <thead>
                    <tr class="head-color">
                      <th colspan="2">{{ t('name') }}</th>
                      <th>{{ t('created-by') }}</th>
                      <th>{{ t('created-date') }}</th>
                      <th>{{ t('actions') }}</th>
                    </tr>
                  </thead>
                  <tbody t-id="board-ticket-files-table-item">
                    <ng-container *ngFor="let attachment of fileData; let i = index">
                      <tr>
                        <td>
                          <i
                            class="ft-{{
                              attachment?.originalFileName
                                ? filesHelper.getFileType(attachment?.originalFileName)
                                : 'paperclip'
                            }}"
                          ></i>
                        </td>
                        <td>
                          <span
                            class="span-attachment-title cursor-pointer"
                            (click)="showMediaPreview(attachment)"
                          >
                            {{ attachment?.originalFileName | limitTo: limitToAttachmentName }}
                          </span>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center">
                            <app-avatar
                              [userId]="attachment.ownerUserId"
                              [height]="24"
                              [width]="24"
                              [statusSize]="9"
                            ></app-avatar>
                          </div>
                        </td>
                        <td>{{ attachment.created_at | fromNow }}</td>
                        <td>
                          <div
                            ngbDropdown
                            class="d-flex justify-content-center"
                            placement="bottom-right"
                            container="body"
                          >
                            <app-svg
                              name="ellipsis"
                              width="17"
                              height="4"
                              [hoverEffect]="true"
                              class="dropdown-toggle cursor-pointer"
                              ngbDropdownToggle
                            ></app-svg>

                            <div ngbDropdownMenu class="attachments-actions">
                              <button
                                ngbDropdownItem
                                type="button"
                                *ngIf="attachment?.url"
                                (click)="copyAttachmentLink(attachment.url)"
                              >
                                <app-svg
                                  name="copy"
                                  width="16"
                                  height="16"
                                  class="mr-2"
                                  aria-hidden="true"
                                ></app-svg>
                                <span>{{ t('btn-copy-link') }}</span>
                              </button>
                              <button
                                ngbDropdownItem
                                type="button"
                                *ngIf="attachment?.url"
                                (click)="downloadAttachment(attachment)"
                              >
                                <app-svg
                                  name="download"
                                  width="16"
                                  height="16"
                                  class="mr-2"
                                  aria-hidden="true"
                                ></app-svg>
                                <span>{{ t('btn-download') }}</span>
                              </button>
                              <button
                                ngbDropdownItem
                                type="button"
                                (click)="removeAttachment(i, attachment.originalFileName)"
                              >
                                <app-svg
                                  name="trash"
                                  width="16"
                                  height="16"
                                  class="mr-2"
                                  aria-hidden="true"
                                ></app-svg>
                                <span>{{ t('btn-remove') }}</span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </ng-scrollbar>

              <ul
                ngbNav
                #ticketModalsTab="ngbNav"
                [(activeId)]="currentTab"
                (navChange)="onNavChange($event)"
                class="nav-tabs d-flex justify-content-start"
              >
                <li *ngIf="modalData.id" [ngbNavItem]="'comments'">
                  <a ngbNavLink>{{ t('link-comments') }}</a>

                  <ng-template ngbNavContent>
                    <app-chat-thread
                      *ngIf="mentionThreadMembers"
                      class="ticket-thread"
                      [isSeparate]="true"
                      [apiUrl]="env?.api_root"
                      [platform]="platformOS"
                      [message]="message"
                      [mentionThreadMembers]="mentionThreadMembers"
                      [object]="modalData.object"
                      [objectId]="modalData.objectId"
                      linkObject="tickets"
                      [linkObjectId]="modalData.id"
                    ></app-chat-thread>
                  </ng-template>
                </li>

                <li *ngIf="modalData.id" [ngbNavItem]="'history'">
                  <a ngbNavLink>{{ t('link-history') }}</a>

                  <ng-template ngbNavContent>
                    <div class="history-wrapper">
                      <div *ngFor="let item of ticketHistory" class="history-item">
                        <app-avatar
                          [userId]="item.updatedBy"
                          [height]="40"
                          [width]="40"
                          [statusSize]="11"
                          class="ml-2"
                        ></app-avatar>
                        <div class="history-item-content flex-grow-1">
                          <div>
                            <span class="ticket-updated-by">{{ item.updaterName }}</span>
                            <span class="ticket-date"
                              >{{ t('at') }}
                              {{ item.updated_at | date: 'dd.MM.yyyy - HH:mm' : currTzAbbr }}</span
                            >
                          </div>
                          <div class="history-comment">
                            {{ t('ticket-updated') }}
                          </div>
                        </div>
                      </div>
                      <div class="history-item">
                        <app-avatar
                          [userId]="ticketCreator"
                          [height]="40"
                          [width]="40"
                          [statusSize]="11"
                          class="ml-2"
                        ></app-avatar>
                        <div class="history-item-content flex-grow-1">
                          <div>
                            <span class="ticket-updated-by">{{ ticketCreatorName }}</span>
                            <span class="ticket-date"
                              >{{ t('at') }}
                              {{ ticketCreatedAt | date: 'dd.MM.yyyy - HH:mm' : currTzAbbr }}</span
                            >
                          </div>
                          <div class="history-comment">
                            {{ t('ticket-created') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>

                <li *ngIf="modalData.id" [ngbNavItem]="'estimation'">
                  <a ngbNavLink>{{ t('link-estimation') }}</a>

                  <ng-template ngbNavContent>
                    <div *ngIf="!isUserInsideEstimateSession" class="estimation-start-session my-2">
                      <button
                        *ngIf="!isHiddenEstimateSession"
                        class="btn btn-solid btn-save"
                        type="button"
                        (click)="startOrJoinEstimationSession()"
                      >
                        <i
                          *ngIf="estimationIsLoadingStart"
                          class="fa fa-spinner fa-spin connection_spinner"
                          aria-hidden="true"
                        ></i>
                        {{
                          estimateSession
                            ? !isUserInsideEstimateSession
                              ? t('btn-join-estimation')
                              : ''
                            : t('btn-start-estimation')
                        }}
                      </button>
                    </div>

                    <div *ngIf="estimateSession" class="estimation">
                      <div class="d-flex align-content-center">
                        <div
                          *ngIf="isOwnerEstimateSession"
                          class="estimation-header d-flex align-items-center"
                        >
                          <div
                            class="estimation-header__switch mr-4 custom-control custom-switch switch-md"
                          >
                            <input
                              #lockSwitch
                              type="checkbox"
                              class="custom-control-input"
                              id="lockSwitch"
                              [formControl]="estimationCreatorLock"
                            />
                            <label
                              class="custom-control-label d-flex align-content-center cursor-pointer"
                              for="lockSwitch"
                              t-id="chat-threads-list-only-for"
                            >
                              <span class="mr-1">{{ t('lock-or-show-values') }}</span>
                            </label>
                          </div>
                        </div>

                        <div
                          *ngIf="isEstimationSessionLocked || isOwnerEstimateSession"
                          class="d-flex align-content-center"
                        >
                          <div class="estimation-header__calculation mr-4">
                            {{ t('min') }}: {{ estimationMinMaxAvg.min }}
                          </div>
                          <div class="estimation-header__calculation mr-4">
                            {{ t('max') }}: {{ estimationMinMaxAvg.max }}
                          </div>
                          <div class="estimation-header__calculation mr-4">
                            {{ t('avg') }}: {{ estimationMinMaxAvg.avg }}
                          </div>
                        </div>
                      </div>

                      <div
                        *ngIf="!isEstimationSessionLocked && isUserInsideEstimateSession"
                        class="estimation-answer d-flex align-items-center mt-2 mb-2"
                      >
                        <input
                          type="number"
                          class="form-control mr-2 estimation-value"
                          placeholder="{{ t('placeholder-enter-value') }}"
                          [formControl]="estimationUserValue"
                        />
                        <button
                          class="btn btn-solid btn-save"
                          type="button"
                          [disabled]="!estimationUserValue.valid"
                          (click)="saveMemberEstimateValue()"
                        >
                          {{ t('btn-save') }}
                        </button>
                      </div>

                      <div class="estimation-title grid-layout mt-4">
                        <div>{{ t('username') }}</div>
                        <div>{{ t('estimation') }}</div>
                      </div>

                      <div
                        *ngFor="let member of estimateSessionMembers"
                        class="estimation-list mt-4"
                      >
                        <div class="estimation-list__user grid-layout">
                          <div class="estimation-list__user-info d-flex align-center">
                            <div class="avatar mr-4">
                              <app-avatar [userId]="member.userId"></app-avatar>
                            </div>
                            <div class="estimation-list__user-name align-center">
                              {{ member?.user?.userName || t('not-available') }}
                            </div>
                          </div>
                          <div
                            class="estimation-list__user-estimate"
                            [class.transparent]="
                              !(
                                isEstimationSessionLocked ||
                                isUserEstimate(member) ||
                                isOwnerEstimateSession
                              ) && member.value
                            "
                          >
                            {{
                              isEstimationSessionLocked ||
                              isUserEstimate(member) ||
                              isOwnerEstimateSession
                                ? memberAnswerStatusForCurrentUser(member)
                                : memberAnswerStatusForGuest(member)
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
              </ul>

              <div class="ticket-modal-tabs" [ngbNavOutlet]="ticketModalsTab"></div>
            </div>

            <div class="col-xl-5">
              <!-- BOARD SELECT -->
              <div
                *ngIf="!modalData.id && (modalData.noteId || modalData.chatType === 'direct')"
                class="boards-wrapper"
              >
                <legend>{{ t('board') }}</legend>
                <div class="boards-buttons no-padding position-relative w-100">
                  <div class="btn-group w-100" role="group">
                    <button
                      *ngIf="(spaces | async)?.length > 0"
                      type="button"
                      t-id="board-ticket-boards-buttons-space"
                      class="btn object-btn object-btn-space"
                      [ngClass]="{
                        'selected-boards-btn': modalData?.object === 'spaces',
                        'btn-solid': modalData?.object === 'spaces',
                        'btn-outline': modalData?.object !== 'spaces'
                      }"
                      (click)="spacesBtnClicked()"
                    >
                      {{ t('space') }}
                    </button>
                    <button
                      *ngIf="(projects | async)?.length > 0"
                      type="button"
                      t-id="board-ticket-boards-buttons-project"
                      class="btn object-btn object-btn-project"
                      [ngClass]="{
                        'selected-boards-btn': modalData?.object === 'projects',
                        'btn-solid': modalData?.object === 'projects',
                        'btn-outline': modalData?.object !== 'projects'
                      }"
                      (click)="projectsBtnClicked()"
                    >
                      {{ t('project') }}
                    </button>
                  </div>
                </div>

                <div class="form-group boards-select-wrapper">
                  <!-- SPACES NG-SELECT -->
                  <ng-select
                    [class.d-none]="modalData.object !== 'spaces'"
                    t-id="board-ticket-select-space"
                    id="ticketSpace"
                    class="calendar-dropdown"
                    required
                    bindLabel="spaceName"
                    bindValue="_id"
                    placeholder="{{ t('placeholder-select-space') }}"
                    name="event-spaceId"
                    appendTo="body"
                    [items]="spaces | async"
                    [searchable]="platform === 'web'"
                    [clearOnBackspace]="true"
                    [readonly]="invitedUser"
                    [attr.data-tabindex]="'tab-index-spaces'"
                    formControlName="objectId"
                    [(ngModel)]="modalData.objectId"
                    (change)="
                      attachDataLists({
                        object: 'spaces',
                        objectId: $event?._id
                      })
                    "
                    (focus)="inputFocused('ticketSpace')"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      <div class="sp-list-item">
                        <app-space-avatar
                          [space]="item"
                          class="mr-1 sp-list-item-avatar"
                        ></app-space-avatar>
                        <b>{{ item.spaceName }}</b>
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                      <div class="sp-list-item">
                        <app-space-avatar
                          [space]="item"
                          class="mr-1 sp-list-item-avatar"
                        ></app-space-avatar>
                        <b>{{ item.spaceName }}</b>
                      </div>
                    </ng-template>
                  </ng-select>

                  <!-- PROJECTS NG-SELECT -->
                  <ng-select
                    [class.d-none]="modalData.object !== 'projects'"
                    t-id="board-ticket-select-project"
                    id="ticketProject"
                    bindLabel="projectName"
                    bindValue="_id"
                    placeholder="{{ t('placeholder-select-project') }}"
                    name="event-projectId"
                    appendTo="body"
                    [items]="projects | async"
                    [searchable]="platform === 'web'"
                    [readonly]="invitedUser"
                    [attr.data-tabindex]="'tab-index-projects'"
                    formControlName="objectId"
                    [(ngModel)]="modalData.objectId"
                    (change)="
                      attachDataLists({
                        object: 'projects',
                        objectId: $event?._id
                      })
                    "
                    (focus)="inputFocused('ticketProject')"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      <div class="sp-list-item">
                        <app-space-avatar
                          [space]="item.space"
                          class="mr-1 sp-list-item-avatar"
                        ></app-space-avatar>
                        <app-project-avatar
                          [project]="item"
                          class="mr-1 sp-list-item-avatar"
                        ></app-project-avatar>
                        <b>{{ item.projectName }}</b>
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                      <div class="sp-list-item">
                        <app-space-avatar
                          [space]="item.space"
                          class="mr-1 sp-list-item-avatar"
                        ></app-space-avatar>
                        <app-project-avatar
                          [project]="item"
                          class="mr-1 sp-list-item-avatar"
                        ></app-project-avatar>
                        <b>{{ item.projectName }}</b>
                      </div>
                    </ng-template>
                  </ng-select>
                </div>

                <div *ngIf="invitedUser" class="block-overlay position-absolute"></div>
              </div>

              <legend>{{ t('assignees') }}</legend>
              <fieldset class="form-group position-relative align-items-center">
                <ng-select
                  t-id="board-ticket-assignees"
                  id="ticketAssignees"
                  class="assignees-select"
                  name="ticketMembers"
                  placeholder="{{ t('placeholder-select-members') }}"
                  bindLabel="userName"
                  bindValue="_id"
                  [multiple]="true"
                  appendTo="body"
                  [(ngModel)]="modalData.selectedMembersIds"
                  [items]="users$ | async"
                  formControlName="ticketMembers"
                  [attr.data-tabindex]="tabIndexes[3]?.tabIndex"
                  (click)="setFocusedControl(tabIndexes[3]?.tabIndex)"
                  (focus)="inputFocused('ticketAssignees')"
                >
                  <ng-template ng-label-tmp let-item="item" let-clear="clear" id="guests_selected">
                    <div class="d-flex align-items-center">
                      <app-avatar
                        [userId]="item._id"
                        [height]="24"
                        [width]="24"
                        [statusSize]="9"
                      ></app-avatar>
                      <div class="ml-1">
                        {{ item.userName }}
                      </div>
                      <div class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">
                        ×
                      </div>
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" id="guests_select">
                    <div class="d-flex align-items-center" t-id="board-ticket-assignees-select">
                      <app-avatar
                        [userId]="item._id"
                        [height]="24"
                        [width]="24"
                        [statusSize]="9"
                      ></app-avatar>
                      <div class="ml-2">
                        <b>{{ item.userName }}</b>
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
              </fieldset>

              <div *ngIf="ticketData.isEpic">
                <legend>{{ t('start-date') }}</legend>
                <fieldset class="form-group position-relative m-0">
                  <div class="d-flex justify-content-between w-100">
                    <app-date-time-picker
                      t-id="calendar-event-date-picker-stop-epic"
                      placeholder="{{ t('placeholder-set-date') }}"
                      startOrEnd="start"
                      formControlName="startDate"
                      class="date-picker"
                      [withoutDefaultTime]="!ticketWithStartDate"
                      [allDayEvent]="ticket?.allDay"
                      [mobile]="platform === 'mobile'"
                      [mobileCalendarEvent]="platform === 'mobile'"
                      [invitedUser]="invitedUser"
                      [tabIndexes]="['tab-index-day-date', 'tab-index-day-time']"
                      [isPastDateDisabled]="true"
                      (dateWithoutTime)="datePickedWithoutTime($event)"
                      (setIsPastDate)="setIsPastDate($event)"
                    ></app-date-time-picker>
                  </div>
                </fieldset>
              </div>

              <legend>{{ t('due-date') }}</legend>
              <fieldset class="form-group position-relative m-0">
                <div class="d-flex justify-content-between w-100">
                  <app-date-time-picker
                    t-id="calendar-event-date-picker-stop"
                    placeholder="{{ t('placeholder-set-date') }}"
                    startOrEnd="start"
                    formControlName="dueDate"
                    class="date-picker"
                    [withoutDefaultTime]="!ticketWithDueDate"
                    [allDayEvent]="ticket?.allDay"
                    [mobile]="platform === 'mobile'"
                    [mobileCalendarEvent]="platform === 'mobile'"
                    [invitedUser]="invitedUser"
                    [tabIndexes]="['tab-index-day-date', 'tab-index-day-time']"
                    [isPastDateDisabled]="true"
                    (dateWithoutTime)="datePickedWithoutTime($event)"
                    (setIsPastDate)="setIsPastDate($event)"
                  ></app-date-time-picker>
                </div>
              </fieldset>

              <legend>{{ t('labels') }}</legend>
              <fieldset class="form-group position-relative">
                <ng-select
                  t-id="board-ticket-label"
                  id="ticketLabels"
                  class="labels-select"
                  [multiple]="true"
                  bindLabel="label"
                  bindValue="_id"
                  placeholder="{{ t('placeholder-search-or-add-new') }}"
                  [items]="labels"
                  [addTag]="addLabelPromise"
                  [loading]="loading"
                  formControlName="labels"
                  [attr.data-tabindex]="tabIndexes[4]?.tabIndex"
                  [(ngModel)]="selectedLabels"
                  (click)="setFocusedControl(tabIndexes[4]?.tabIndex)"
                  (focus)="inputFocused('ticketLabels')"
                >
                  <ng-template ng-tag-tmp let-search="searchTerm">
                    <b>{{ t('add-new') }}</b
                    >: {{ search }}
                  </ng-template>
                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    {{ item.label }}
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true"
                      >×</span
                    >
                  </ng-template>
                </ng-select>
              </fieldset>

              <div *ngIf="tickets">
                <div
                  *ngIf="
                    (ticketData.isEpic && modalData.id) ||
                    (modalData.id && !ticketForm.controls['parentId']?.value)
                  "
                >
                  <legend>{{ t('sub-tasks') }}</legend>
                  <fieldset class="position-relative">
                    <div
                      *ngIf="ticketData.isEpic ? subTicketsEpic.length : childrenList?.length"
                      class="sub-tasks"
                    >
                      <div
                        *ngFor="let child of ticketData.isEpic ? subTicketsEpic : childrenList"
                        ngbTooltip="{{ child.title }}"
                        placement="top"
                        class="sub-task-wrapper"
                        [class.has-flag]="child.isBlocked"
                        t-id="board-ticket-subtask-list"
                      >
                        <div class="task-flag"></div>
                        <div
                          class="sub-task cursor-pointer flex-grow-1"
                          (click)="openTicket(child)"
                        >
                          <span class="ticket-number"
                            >{{ child.boardAbbreviation }}-{{ child.counter }}</span
                          >
                          <span
                            class="ticket-title"
                            t-id="board-ticket-selected-ticket-title-child-epic"
                            >{{ child.title }}</span
                          >
                        </div>
                        <div class="sub-task-actions">
                          <app-svg
                            name="trash"
                            width="14"
                            height="16"
                            class="svg-icon"
                            [hoverEffect]="true"
                            (click)="removeSubTask(child)"
                          ></app-svg>
                        </div>
                        <div class="sub-task-status" [ngClass]="getColumnColor(child.columnId)">
                          {{ getColumnTitle(child.columnId) }}
                        </div>
                      </div>
                    </div>

                    <button
                      *ngIf="!showSubtaskSelect"
                      t-id="board-ticket-add-subtask"
                      class="d-flex align-items-center btn btn-sm btn-subtle add-sub-task"
                      type="button"
                      [attr.data-tabindex]="tabIndexes[9]?.tabIndex"
                      (click)="
                        setFocusedControl(tabIndexes[9]?.tabIndex);
                        showSubtaskSelect = !showSubtaskSelect
                      "
                    >
                      <app-svg name="plus" width="9" height="9" class="mr-1"></app-svg>
                      <span>{{ t('btn-add-sub-task') }}</span>
                    </button>

                    <div *ngIf="showSubtaskSelect">
                      <ng-select
                        t-id="board-ticket-add-search-subtask"
                        id="ticketSubTask"
                        class="subtask-select"
                        bindLabel="title"
                        bindValue="_id"
                        placeholder="{{ t('placeholder-search-or-add-task') }}"
                        [items]="ticketsSelect"
                        [addTag]="addSubtaskPromise"
                        [loading]="loading"
                        formControlName="selectedSubtask"
                        (focus)="inputFocused('ticketSubTask')"
                      >
                        <ng-template ng-tag-tmp let-search="searchTerm">
                          <b>{{ t('add-new') }}</b
                          >: {{ search }}
                        </ng-template>
                        <ng-template
                          ng-label-tmp
                          let-item="item"
                          let-clear="clear"
                          id="task_selected"
                        >
                          <div
                            class="d-flex align-items-center parent-task w-100"
                            ngbTooltip="{{ item.title }}"
                            container="body"
                            placement="top"
                          >
                            <span *ngIf="item?.counter" class="ticket-number"
                              >{{ item?.boardAbbreviation }}-{{ item?.counter }}</span
                            >
                            <span
                              class="ticket-title"
                              t-id="board-ticket-selected-ticket-title-child-1"
                              >{{ item.title }}</span
                            >
                          </div>
                        </ng-template>
                        <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-index="index"
                          id="task_select"
                        >
                          <div class="parent-task">
                            <span *ngIf="item?.counter" class="ticket-number"
                              >{{ item.boardAbbreviation }}-{{ item.counter }}</span
                            >
                            <span
                              class="ticket-title"
                              t-id="board-ticket-selected-ticket-title-child-2"
                              >{{ item.title }}</span
                            >
                          </div>
                        </ng-template>
                      </ng-select>

                      <div class="d-flex align-items-center justify-content-end">
                        <button
                          type="button"
                          class="d-flex align-items-center btn btn-sm btn-subtle"
                          (click)="closeSubtaskSelect()"
                        >
                          <span>{{ t('btn-cancel') }}</span>
                        </button>

                        <button
                          type="button"
                          class="d-flex align-items-center btn btn-sm btn-solid add-sub-task-submit"
                          (click)="addSubTask(ticketForm.controls['selectedSubtask'].value)"
                          t-id="board-ticket-add-subtask-button"
                        >
                          <app-svg name="plus" width="9" height="9" class="mr-1"></app-svg>
                          <span>{{ t('btn-add-sub-task') }}</span>
                        </button>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </ng-scrollbar>
      </div>

      <div class="modal-footer d-flex align-items-center">
        <button
          *ngIf="submitButtonTitle !== 'Create'"
          t-id="board-ticket-delete-action"
          class="svg-btn"
          title="Delete ticket"
          type="button"
          (click)="deleteTicket()"
        >
          <app-svg name="trash" width="14" height="16"></app-svg>
        </button>

        <button
          class="btn btn-subtle"
          type="button"
          (click)="close()"
          t-id="board-ticket-cancel-action"
        >
          {{ t('btn-cancel') }}
        </button>

        <button
          [disabled]="isDisabledBtn"
          t-id="board-ticket-create-action"
          class="btn btn-solid btn-save"
        >
          <i
            *ngIf="isUploading"
            class="fa fa-spinner fa-spin connection_spinner"
            aria-hidden="true"
          ></i>
          {{ isUploading ? '' : submitButtonTitle }}
        </button>
      </div>
    </form>

    <input
      #attachFileInput
      type="file"
      multiple
      style="display: none"
      onclick="value=null"
      (change)="fileChange($event)"
      t-id="board-ticket-uploader-attachment"
    />
  </div>
</ng-container>

<!-- MEDIA PREVIEW COMPONENT -->
<ng-container *ngIf="ticketMedia.length && currentMediaPath && mediaPreviewVisible">
  <app-media-preview
    class="ticket-media-preview"
    [class.ticket-media-preview-ios]="platformOS === 'ios'"
    [platform]="platform"
    [object]="modalData.object"
    [objectId]="modalData.objectId"
    [tz]="currTzAbbr"
    [media]="ticketMedia"
    [currentMediaPath]="currentMediaPath"
    (closeMediaPreview)="closeImagePreview($event)"
  ></app-media-preview>
</ng-container>
