import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';

import { ConfigService, ITemplateConfig } from '../../../../../services/config.service';
import { ChatsDeletePin } from '../../../../../store/actions/chats.action';
import { PinType } from '../../enums/pin-type.enum';
import { PinCreateComponent } from '../../../../../../modals/pin-create/pin-create.component';
import { PinnedMessagesDbDto } from '../../../../../../api/models/pinned-messages-db-dto';
import { ChatPinnedService } from '../../chat-pinned.service';
import { ConfirmAlert } from '../../../../../alerts/alerts';
import { ClickOutsideModule } from 'ng-click-outside';
import { ClickOutsideDirective } from '../../../../../directives/click-outside.directive';
import { PinContextMenuComponent } from '../pin-context-menu/pin-context-menu.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgIf, NgStyle } from '@angular/common';

@Component({
  selector: 'app-chat-pinned-item',
  templateUrl: './chat-pinned-item.component.html',
  styleUrls: ['./chat-pinned-item.component.scss'],
  standalone: true,
  imports: [
    NgbTooltip,
    NgIf,
    SvgIconComponent,
    PinContextMenuComponent,
    ClickOutsideDirective,
    NgStyle,
    ClickOutsideModule,
  ],
})
export class ChatPinnedItemComponent implements OnInit {
  @Input() item: PinnedMessagesDbDto;
  @Input() chatId: string;
  @Input() index: number;
  @Input() platform = 'web';
  @Input() object: string;
  @Input() objectId: string;

  public isDisplayContextMenu: boolean;
  public config: ITemplateConfig;
  public rightClickMenuPositionX: number;
  public rightClickMenuPositionY: number;
  public isPossibleDelete = true;

  constructor(
    private configService: ConfigService,
    private store: Store,
    private modalService: NgbModal,
    private pinnedService: ChatPinnedService,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit(): void {
    this.isPossibleDelete = this.pinnedService.canDeletePin(this.item) || false;
  }

  // Getters
  public get rightClickMenuStyle() {
    return {
      position: 'fixed',
      'z-index': 1,
      left: `${this.rightClickMenuPositionX}px`,
      top: `${this.rightClickMenuPositionY}px`,
    };
  }

  public get isTicketPin(): boolean {
    return (
      this.item.linkDocument === PinType.Ticket ||
      this.item.linkDocument === PinType.ChatFile ||
      this.item.linkDocument === PinType.ThreadsFile ||
      this.item.linkDocument === PinType.TicketFile ||
      this.item.linkDocument === PinType.Files
    );
  }

  // Methods
  public handleAction() {
    this.pinnedService.handleAction(this.item);
  }
  public deletePin() {
    this.clickOutside();

    ConfirmAlert(this.translocoService.translate('alert.pin-title'), {
      subject: this.translocoService.translate('alert.unpin-subject'),
      text: this.translocoService.translate('alert.unpin-text'),
      confirmButtonText: this.translocoService.translate('alert.unpin-btn-text'),
      cancelButtonText: this.translocoService.translate('alert.btn-cancel'),
      platform: this.platform,
    }).then(() => {
      this.store.dispatch(
        new ChatsDeletePin({
          id: this.chatId,
          pinnedMessageId: this.item._id,
          object: this.object || 'spaces',
          objectId: this.objectId,
        }),
      );
    });
  }

  public editPin() {
    const modalRef = this.modalService.open(PinCreateComponent, {
      size: 'md',
      centered: true,
    });
    modalRef.componentInstance.chatId = this.chatId;
    modalRef.componentInstance.isEditable = true;
    modalRef.componentInstance.object = this.object;
    modalRef.componentInstance.objectId = this.objectId;
    modalRef.componentInstance.pinId = this.item._id;
    modalRef.componentInstance.icon = this.item.emojiName;
    modalRef.componentInstance.pinForm.setValue({
      name: this.item?.name || '',
      url: this.item?.url || '',
      isFrame: this.item?.openInFrame || false,
    });
  }

  public copyUrlPin() {
    this.clickOutside();
    navigator.clipboard.writeText(this.item.url).then();
  }

  public callContextMenu(event): void {
    event.preventDefault();
    this.isDisplayContextMenu = true;

    this.rightClickMenuPositionX = event.clientX;
    this.rightClickMenuPositionY = event.clientY;
  }

  public clickOutside(): void {
    this.isDisplayContextMenu = false;
  }
}
