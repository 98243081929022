export enum PinType {
  Message = 'messages',
  ChatFile = 'chats.files',
  ThreadsFile = 'threads.files',
  TicketFile = 'tickets.files',
  Ticket = 'tickets',
  Files = 'files',
  Documents = 'documents',
  Links = 'links',
}
