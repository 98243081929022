import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ZoomService {
  private handleZoom = new BehaviorSubject('');
  public zoomEmit$ = this.handleZoom.asObservable();

  public emitZoom(): void {
    this.handleZoom.next('');
  }
}
