<div class="d-flex align-items-center search-container" *transloco="let t; read: 'chat'">
  <input
    id="searchInChat"
    class="form-control search-field"
    type="text"
    [placeholder]="'&#xE8BD;&nbsp; ' + t('search-field-placeholder')"
    required
    [(ngModel)]="searchedText"
    (keydown.enter)="searchMessages()"
  />

  <span
    *ngIf="searchedText?.length > 0"
    id="clearSearchInChat"
    class="d-flex align-items-center justify-content-end"
  >
    <app-svg
      name="times-circle"
      (click)="clearSearchInChat()"
      class="cursor-pointer"
      [width]="14"
      [height]="14"
    ></app-svg>
  </span>
</div>

<div class="search-content position-relative" *transloco="let t; read: 'chat'">
  <ng-scrollbar
    id="searchScrollbar"
    #scrollableSearch="ngScrollbar"
    class="scroll-event search-results"
    thumbClass="white-scrollbars"
  >
    <div *ngIf="searchedText && searchIsActive && searchResults?.length === 0">
      <h6 class="text-left font-small-3 p-2">
        {{ t('search-no-results') }}
      </h6>
    </div>

    <div
      *ngFor="let message of searchResults; trackBy: trackByMessages"
      class="chat-message chat-left"
      [ngClass]="{
        'chat-web': platform === 'web',
        'chat-mobile': platform !== 'web',
        'chat-light': config?.layout.variant === 'Light',
        'chat-dark': config?.layout.variant === 'Dark'
      }"
      (click)="openSearchedMessagePage(message)"
    >
      <div class="chat-avatar">
        <app-avatar [userId]="message?.userId"></app-avatar>
      </div>
      <div class="chat-body hoverable">
        <div class="chat-content">
          <!--MESSAGE DETAILS-->
          <app-chat-message-details
            [message]="message"
            [tz]="currTzAbbr"
          ></app-chat-message-details>
          <!--MESSAGE DETAILS-->

          <!-- [mentionChatMembers]="mentionChatMembers"-->
          <app-chat-message-content [platform]="platform" [message]="message" context="search">
          </app-chat-message-content>
        </div>
      </div>
    </div>
  </ng-scrollbar>

  <div
    *ngIf="!searchIsActive && searchResults?.length === 0"
    class="d-flex flex-column align-items-center justify-content-center w-100 start-search"
  >
    <p>{{ t('start-search') }}</p>
    <img src="assets/img/svg/start-search.svg" alt="start-search" />
  </div>
</div>
