import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, combineLatest, fromEvent } from 'rxjs';
import { debounceTime, filter, take, tap } from 'rxjs/operators';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslocoService } from '@ngneat/transloco';

import { SpacesDbDto } from '../../../../api/models/spaces-db-dto';
import { ProjectsDbDto } from '../../../../api/models/projects-db-dto';
import { customAnimations } from '../../../animations/custom-animations';
import { RouterTenantPipe } from '../../../pipes/router-tenant.pipe';
import { ConfigService } from '../../../services/config.service';
import { SocketsService } from '../../../services/sockets.service';
import { TauriService } from '../../../services/tauri.service';
import { SpaceService } from '../../../services/space.service';
import { ProjectService } from '../../../services/project.service';
import {
  ChatsMarkAsRead,
  ThreadMarkAsRead,
  ChatsThreadsListMarkAllAsRead,
  ChatsMarkAllAsRead,
  ChatsGet,
  ThreadGetCounters,
  MarkAll,
} from '../../../store/actions/chats.action';
import { SpaceOrderUpdate, SpaceSetChatMenuVisibility } from '../../../store/actions/spaces.action';
import { AuthState } from '../../../store/states/auth.state';
import { ChatsState } from '../../../store/states/chats.state';
import { SpacesState } from '../../../store/states/spaces.state';
import { ProjectsState } from '../../../store/states/projects.state';
import { UsersState } from '../../../store/states/users.state';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InviteToSpaceModalComponent } from '../../../../modals/invite-to-space-modal/invite-to-space-modal.component';
import { TenantsService } from '../../../../api/services/tenants.service';
import { ProjectOrderUpdate } from '../../../store/actions/projects.action';
import { Tenants } from '../../../store/models/TenantsState';
import { SetTenantsList } from '../../../store/actions/auth.action';
import { ChatType } from './enums/chat.enum';
import { FilterType } from './enums/filter.enum';
import Memoize from '../../../decorators/memoize.decorator';
import { EnvironmentService } from '../../../services/environment.service';
import { PaymentState } from '../../../store/states/payment.state';
import { AttributeEnum } from '../../../../modals/payment-modal/payment.const';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-chat-menu',
  template: ``,
  animations: customAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ChatMenuComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  @ViewChild('searchChat', { static: false }) searchChat: ElementRef;

  /*todo add types instead any*/
  initSpaces: any[] = [];
  initChatMenuProjectItems: any[] = [];
  initChatMenuGroupsItems: any[] = [];
  initChatMenuDirectItems: any[] = [];
  spaces: any[] = [];
  projects: any[] = [];
  chatMenuProjectItems: any[] = [];
  chatMenuGroupsItems: any[] = [];
  chatMenuDirectItems: any[] = [];
  @Select(ChatsState.getAllChatsWithUnreadMessages) unreadChats$: Observable<any>;

  unreadSpaces: any[] = [];
  unreadProjects: any[] = [];
  unreadGroupChats: any[] = [];
  unreadDirectChats: any[] = [];
  // TODO
  currentTenant: any;
  tenantList: any[] = [];
  subscription: string;

  platform: string;
  page: string = null;
  userId: string;
  userName: string;
  threads: any[];
  space: SpacesDbDto = null;
  project: ProjectsDbDto;
  dropdownRef: NgbDropdown;
  spacesContainer = 'spaces_group';
  projectsContainer = 'projects_group';
  groupChatsContainer = 'group_chats_group';
  activeChatId: string = null;
  searchedText: string;
  isSearch = false;
  level = 0;
  actionsDropdownOpen = false;
  toggleProjectsEditVisibility = true;
  toggleDirectEditVisibility = false;
  toggleSpaces = true;
  toggleUnread = true;
  toggleDirects = true;
  protected innerWidth: any;
  currentSortKey: string = 'lastMessage';
  protected config: any = {};

  isDark$ = new BehaviorSubject(false);
  initNotificationsFilters: any[];

  constructor(
    protected actions: Actions,
    protected store: Store,
    protected router: Router,
    protected cdr: ChangeDetectorRef,
    protected routerTenantPipe: RouterTenantPipe,
    protected configService: ConfigService,
    protected tauriService: TauriService,
    protected socketsService: SocketsService,
    protected toastrService: ToastrService,
    protected modalService: NgbModal,
    public spaceService: SpaceService,
    public projectService: ProjectService,
    public tenantService: TenantsService,
    protected translocoService: TranslocoService,
    protected environmentService: EnvironmentService,
  ) {
    this.innerWidth = window.innerWidth;
    this.config = this.configService.templateConf;
  }

  ngOnInit(): void {
    this.configService.templateConf$
      .pipe(untilDestroyed(this))
      .subscribe((config) => this.isDark$.next(config.layout.variant === 'Dark'));
    this.store.dispatch(new SpaceSetChatMenuVisibility());
    const user = this.store.selectSnapshot(AuthState.getUser);
    this.userId = user._id;
    this.userName = user.userName;

    // this.store.dispatch(new ThreadGetCounters());

    this.store
      .select(PaymentState.getSubscription)
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.subscription = value.plan;
        this.cdr.detectChanges();
      });

    this.actions
      .pipe(
        untilDestroyed(this),
        ofActionSuccessful(
          ChatsMarkAsRead,
          ThreadMarkAsRead,
          ChatsThreadsListMarkAllAsRead,
          ChatsMarkAllAsRead,
        ),
      )
      .subscribe(() => this.cdr.detectChanges());

    this.store
      .select(ChatsState.getChatId)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.activeChatId = res;
        this.cdr.detectChanges();
      });

    if (this.page === 'dm') {
      this.spaces = [];
      this.unreadSpaces = [];
    } else {
      combineLatest([
        this.store.select(SpacesState.getLoadedSpaces),
        this.store.select(ProjectsState.getLoadedProjects),
        this.store.select(ChatsState.getChats),
        this.store.select(ChatsState.getThreadsList),
      ])
        .pipe(untilDestroyed(this))
        .subscribe(([spaces, projects, chats, threads]) => {
          this.projects = projects;
          this.threads = threads;
          // space chats
          this.initSpaces = spaces.map((item) => ({
            ...item,
            chatItem: chats.find((chat) => chat.object === 'spaces' && chat.objectId === item._id),
          }));
          this.spaces = this.initSpaces;

          const unreadSpaces = this.spaces.map((item) => ({
            ...item,
            object: 'spaces',
            unreadUpdatedAt: item.chatItem?.unreadUpdatedAt,
            chatThreadsUnreadUpdatedAt: item.chatItem?.chatThreadsUnreadUpdatedAt,
          }));
          this.unreadSpaces = [...unreadSpaces]
            .filter((item) => {
              if (!item?.chatItem) {
                return;
              }

              const {
                chatItem: {
                  numberOfUnreadMessages,
                  numberOfUnreadMentions,
                  chatThreadsTotalUnreadMessages,
                  chatThreadsTotalUnreadMentions,
                },
              } = item;
              const showSpaceWithServiceMessages = this.noCallFilter(item.chatItem);

              return (
                showSpaceWithServiceMessages &&
                (numberOfUnreadMessages > 0 ||
                  numberOfUnreadMentions > 0 ||
                  chatThreadsTotalUnreadMessages > 0 ||
                  chatThreadsTotalUnreadMentions > 0)
              );
            })
            .sort((a, b) => {
              return a.chatItem.numberOfUnreadMessages > b.chatItem.numberOfUnreadMessages ||
                a.chatItem.numberOfUnreadMentions > b.chatItem.numberOfUnreadMentions
                ? -1
                : 1;
            });

          this.cdr.detectChanges();
        });
    }

    const mapFn = (item) => ({
      object: item.object || item.type,
      objectId: item.objectId,
      chatId: item._id,
      userId: item.userId,
      title: item.chatName,
      chatIsHidden: item.chatIsHidden,
      numberOfUnreadMessages: item.numberOfUnreadMessages,
      numberOfUnreadMentions: item.numberOfUnreadMentions + item.chatThreadsTotalUnreadMentions,
      chatThreadsTotalUnreadMessages: item.chatThreadsTotalUnreadMessages,
      chatThreadsTotalUnreadMentions: item.chatThreadsTotalUnreadMentions,
      lastMessage: item.lastMessage,
      updated_at: item.updated_at,
      unreadUpdatedAt: item.unreadUpdatedAt,
      chatThreadsUnreadUpdatedAt: item.chatThreadsUnreadUpdatedAt,
      space: null,
      order: item?.order || null,
      isOwner: item.userId === this.userId,
      notifyFilter: item.notifyFilter,
    });

    combineLatest([
      this.store.select(ChatsState.getChats),
      this.store.select(ChatsState.getThreadsList),
      this.store.select(ProjectsState.getLoadedProjects),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([chats, threads, projects]) => {
        if (chats) {
          this.threads = threads;
          // updating electron badge counter
          if (this.tauriService.isTauri) {
            const unreadChatsCount = chats.reduce((count, chat) => {
              if (chat.notifyFilter !== 'NOTHING') {
                return count + chat.numberOfUnreadMessages;
              }
              return count;
            }, 0);
            this.tauriService.updateBadgeCount(unreadChatsCount);
          }

          const allUnreadedCount = chats.reduce((count, chat) => {
            if (chat.notifyFilter !== 'NOTHING') {
              if (chat.notifyFilter == 'MENTIONS') {
                return count + chat.numberOfUnreadMentions + chat.chatThreadsTotalUnreadMentions;
              } else {
                return (
                  count +
                  chat.numberOfUnreadMessages +
                  chat.numberOfUnreadMentions +
                  chat.chatThreadsTotalUnreadMessages +
                  chat.chatThreadsTotalUnreadMentions
                );
              }
            }
            return count;
          }, 0);

          this.tauriService.updateBadgeCount(allUnreadedCount);

          // project chats
          const remappedChats = this.addAvatarForChats(
            [...this.remapIsNotArchivedChats(chats, projects)]
              .filter((item) => {
                const showChatWithServiceMessages = this.noCallFilter(item);
                return !!item.spaceId && item.type === 'general' && showChatWithServiceMessages;
              })
              .map((item) => ({
                objectId: item.objectId,
                object: item.object,
                title: item.chatName,
                chatId: item._id,
                parentObjectId: item.spaceId,
                notifyFilter: item.notifyFilter,
                numberOfUnreadMessages: item.numberOfUnreadMessages,
                numberOfUnreadMentions:
                  item.numberOfUnreadMentions + item.chatThreadsTotalUnreadMentions,
                chatThreadsTotalUnreadMessages: item.chatThreadsTotalUnreadMessages,
                chatThreadsTotalUnreadMentions: item.chatThreadsTotalUnreadMentions,
                isArchive: item.isArchive,
                isInFreeTier: item.isInFreeTier,
                space: null,
                order: item.order,
                updated_at: item.updated_at,
                chatThreadsUnreadUpdatedAt: item.chatThreadsUnreadUpdatedAt,
                unreadUpdatedAt: item.unreadUpdatedAt,
              }))
              .sort((a, b) => {
                return a.numberOfUnreadMessages > b.numberOfUnreadMessages ||
                  a.numberOfUnreadMentions > b.numberOfUnreadMentions
                  ? -1
                  : 1;
              }),
            'general',
            'projects',
          );

          this.unreadProjects = this.addAvatarForChats([...remappedChats], 'general', 'unread');
          this.initChatMenuProjectItems = this.page === 'dm' ? [] : remappedChats;
          this.chatMenuProjectItems = this.initChatMenuProjectItems;

          // group chats
          let groupChats = [...chats]
            .filter((item) => item.type === 'group')
            .map(mapFn)
            .sort((a, b) => {
              return a.numberOfUnreadMessages > b.numberOfUnreadMessages ||
                a.numberOfUnreadMentions > b.numberOfUnreadMentions
                ? -1
                : 1;
            });

          groupChats = groupChats.map((groupChat) => {
            const findSpace = this.spaces.find((space) => space._id === groupChat.objectId);
            return {
              ...groupChat,
              isInFreeTier: findSpace ? findSpace.isInFreeTier : true,
            };
          });

          this.unreadGroupChats = this.addAvatarForChats([...groupChats], 'group', 'unread');
          this.initChatMenuGroupsItems = this.page === 'dm' ? [] : groupChats;
          this.chatMenuGroupsItems = this.initChatMenuGroupsItems;

          // direct chats
          const directItems = [...chats].filter((item) => item.type === 'direct').map(mapFn);
          const directChats =
            this.page === 'dm'
              ? directItems.sort((a, b) => {
                  if (!a.lastMessage) {
                    return 1;
                  }
                  if (!b.lastMessage) {
                    return -1;
                  }
                  return a.lastMessage.created_at > b.lastMessage.created_at ? -1 : 1;
                })
              : directItems.sort((a, b) => a.title.localeCompare(b.title));

          directChats.sort((a, b) => {
            if (!a.lastMessage) {
              return 1;
            }
            if (!b.lastMessage) {
              return -1;
            }
            return a.lastMessage.created_at > b.lastMessage.created_at ? -1 : 1;
          });

          const directMembers = [this.userName, ...directItems.map((item) => item.title)];
          const tenantMembers = this.store.selectSnapshot(UsersState.getTenantUsers);

          tenantMembers.map((item) => {
            if (!directMembers.includes(item.userName)) {
              directChats.push({
                object: null,
                order: null,
                objectId: null,
                chatId: null,
                userId: item._id,
                title: item.userName,
                updated_at: null,
                chatIsHidden: true,
                numberOfUnreadMessages: 0,
                numberOfUnreadMentions: 0,
                chatThreadsTotalUnreadMessages: 0,
                chatThreadsTotalUnreadMentions: 0,
                lastMessage: null,
                chatThreadsUnreadUpdatedAt: null,
                unreadUpdatedAt: null,
                space: null,
                isOwner: false,
                notifyFilter: null,
              });
            }
          });

          this.initChatMenuDirectItems = directChats;
          this.chatMenuDirectItems = this.initChatMenuDirectItems?.filter(
            (item) => item.title !== 'Quinn' && item.title !== this.userName,
          );

          if (this.page !== 'dm') {
            this.unreadDirectChats = this.chatMenuDirectItems.filter(
              (item) =>
                item.numberOfUnreadMessages > 0 ||
                item.numberOfUnreadMentions > 0 ||
                item.chatThreadsTotalUnreadMessages > 0 ||
                item.chatThreadsTotalUnreadMentions > 0,
            );
          }

          this.clearSearchChat();
          this.cdr.detectChanges();
        }
      });

    this.getTenantsInfo();
    this.tenantNotificationsListener();
  }

  ngAfterViewInit(): void {
    fromEvent(this.searchChat?.nativeElement, 'keyup')
      .pipe(
        untilDestroyed(this),
        debounceTime(this.configService.SEARCH_DEBOUNCE_TIME),
        tap((_) => this.searchChats(this.searchedText)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {}

  @Memoize(['unreadProjects', 'unreadGroupChats', 'unreadSpaces'])
  get allUnreadMessage() {
    return [...this.unreadProjects, ...this.unreadGroupChats, ...this.unreadSpaces];
  }

  getCheckGroupProject(chat): boolean {
    return (
      chat.object === ChatType.Group ||
      chat.object === ChatType.Project ||
      chat.object === ChatType.Space
    );
  }

  get isLimited(): boolean {
    return this.environmentService.isLimited;
  }

  get isFreePlan(): boolean {
    return this.subscription === AttributeEnum.Free;
  }

  getIsRouteAccess(item): boolean {
    return this.isLimited
      ? !!item.chatItem && (!!item.isInFreeTier || !this.isFreePlan)
      : !!item.chatItem;
  }

  getIsRouteProjectAccess(item): boolean {
    return this.isLimited ? !!item.isInFreeTier || !this.isFreePlan : true;
  }

  getIsMenuAccess(item, isUnreadList: boolean): boolean {
    return this.isLimited
      ? !isUnreadList && (!!item.isInFreeTier || !this.isFreePlan)
      : !isUnreadList;
  }

  getCheckChatType(chat, direct, group, project, space) {
    switch (chat.object) {
      case ChatType.Space:
        return space;
      case ChatType.Project:
        return project;
      case ChatType.Group:
        return group;
      case ChatType.Direct:
        return direct;
      default:
        return chat;
    }
  }

  getLengthUnreadAllMessage(chat) {
    if (chat?.notifyFilter === FilterType.Mention) {
      return chat.projectName ? chat.numberOfUnreadMentions : chat.chatThreadsTotalUnreadMentions;
    }

    let totalCount = chat?.numberOfUnreadMessages;
    if (chat?.notifyFilter === FilterType.NoCall && chat?.numberOfUnreadServiceMessages) {
      totalCount -= chat?.numberOfUnreadServiceMessages;
    }
    return totalCount;
  }

  getLengthUnreadMentionMessage(chat) {
    return chat?.numberOfUnreadMentions + chat?.chatThreadsTotalUnreadMentions;
  }

  getLengthUnreadThreadMessage(chat) {
    if (chat?.notifyFilter === FilterType.Mention) {
      return chat.chatThreadsTotalUnreadMentions;
    }

    return chat?.chatThreadsTotalUnreadMessages;
  }

  getUnreadAllMessageCount(chat): string {
    const count = this.getLengthUnreadAllMessage(chat);
    return count > 9 ? '9+' : count;
  }

  isNotificationAllow(chat, isMention = false) {
    if (isMention) {
      return chat && chat.notifyFilter !== FilterType.Nothing;
    }

    return (
      chat && chat.notifyFilter !== FilterType.Nothing && chat.notifyFilter !== FilterType.Mention
    );
  }

  isSpaceChat(chat) {
    return chat.object === ChatType.Space;
  }

  getCheckIsGroup(chat): string | null {
    return chat.object === ChatType.Group ? this.space?._id : null;
  }

  getClassByTypeChat(chat): string {
    switch (chat.object) {
      case 'spaces':
        return 'chat-wrapper';
      case 'projects':
      case 'group':
        return 'chat-menu-item';
      case 'direct':
        return 'direct-message-item';
      default:
        return '';
    }
  }

  updateSpaceOrder(spaceId, spaceData): void {
    this.store
      .dispatch(new SpaceOrderUpdate({ id: spaceId, space: spaceData }))
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => {},
        (err) => {
          this.toastrService.error(
            err.message,
            this.translocoService.translate('toastr.title-error'),
          );
        },
      );
  }

  updateProjectOrder(projectId, projectData): void {
    this.store
      .dispatch(new ProjectOrderUpdate({ id: projectId, project: projectData }))
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => {},
        (err) => {
          this.toastrService.error(
            err.message,
            this.translocoService.translate('toastr.title-error'),
          );
        },
      );
  }

  addAvatarForChats(chats, type, action): any[] {
    const selectedChats =
      action === 'unread'
        ? chats.filter(
            (item) =>
              item.numberOfUnreadMessages > 0 ||
              item.numberOfUnreadMentions > 0 ||
              item.chatThreadsTotalUnreadMessages > 0 ||
              item.chatThreadsTotalUnreadMentions > 0,
          )
        : chats;

    return selectedChats.map((item) => {
      if (this.initSpaces.length > 0) {
        if (type === 'general') {
          if (action === 'projects') {
            this.projects.map((project) => {
              if (project._id === item.objectId) {
                item.projectName = project.projectName;
                item.avatarUrl = project.avatarUrl;
                item.emoji = project.emoji;
                item.isInFreeTier = project.isInFreeTier;
                item.prefix = project.prefix;
              }
            });
          } else {
            this.initSpaces.map((space) => {
              if (
                (space.chatItem &&
                  item.parentObjectId &&
                  item.parentObjectId === space.chatItem?.objectId) ||
                space._id === item.parentObjectId
              ) {
                item.space = {
                  spaceName: space.spaceName,
                  avatarUrl: space.avatarUrl,
                };
              }
            });
          }
        } else if (type === 'group') {
          this.initSpaces.map((space) => {
            if (
              (space.chatItem && item.objectId && item.objectId === space.chatItem?.objectId) ||
              space._id === item.objectId
            ) {
              item.space = {
                spaceName: space.spaceName,
                avatarUrl: space.avatarUrl,
              };
            }
          });
        }
      }

      return item;
    });
  }

  remapIsNotArchivedChats(chats, projects) {
    return chats.map((chat) => {
      if (chat.object === 'projects') {
        const foundProject = projects.find((project) => project._id === chat.objectId);
        if (foundProject) {
          return {
            ...chat,
            isArchive: foundProject.isArchive,
            order: foundProject.order,
          };
        }
        return { ...chat, isArchive: true };
      }
      return chat;
    });
  }

  getChatMenuProjectItems(list, spaceId): any[] {
    return list
      .filter((item) => item.parentObjectId === spaceId && !item.isArchive)
      .sort((a, b) => a.order - b.order);
  }

  getChatMenuGroupItems(list, spaceId): any[] {
    return list.filter((item) => item.objectId === spaceId).sort((a, b) => a?.order - b?.order);
  }

  actionsDropdownToggle(e, ref?: NgbDropdown): void {
    this.actionsDropdownOpen = e;

    if (this.actionsDropdownOpen) {
      this.dropdownRef = ref;
    }
  }

  /**
   * Invite users button click handler (emit open modal action)
   */
  openInviteMemberModal(object, objectId) {
    const modalRef = this.modalService.open(InviteToSpaceModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.object = object;
    modalRef.componentInstance.objectId = objectId;
    modalRef.componentInstance.newUsersOnly = false;
  }

  trackByChatId(index, item): string {
    return item.chatId;
  }

  trackById(index, item): string {
    return item._id;
  }

  viewDirectChat(userId): void {
    this.socketsService.get().emit('chats:getDirectChatIdByUserId', { userId });
  }

  hideDirectChat(chatId): void {
    this.socketsService.get().emit('chats:isHiddenToggle', { chatId, chatIsHidden: true });
  }

  openDirectChat(chat): void {
    if (chat.chatIsHidden) {
      this.viewDirectChat(chat.userId);
    } else {
      if (this.platform !== 'web') {
        this.store.dispatch(new ChatsGet());
      }
      this.router.navigate([this.routerTenantPipe.transform('chat/' + chat.chatId)], {
        queryParams: { page: 'dm' },
      });
    }
    this.clearSearchChat();
  }

  searchChats(text): void {
    let directChats = [];
    if (text && text.trim() !== '') {
      text = text.replace(/[\[\]\{\}]/g, '');
      const re = new RegExp(text, 'gi');

      this.isSearch = true;
      this.spaces = this.initSpaces.filter((item) => item.spaceName.search(re) !== -1);

      const chats = this.initChatMenuProjectItems.filter((item) => item.title.search(re) !== -1);
      const groupChats = this.initChatMenuGroupsItems.filter(
        (item) => item.title.search(re) !== -1,
      );
      this.chatMenuProjectItems = this.addAvatarForChats([...chats], 'general', 'search');
      this.chatMenuGroupsItems = this.addAvatarForChats([...groupChats], 'group', 'search');

      directChats = this.initChatMenuDirectItems.filter((item) => item.title.search(re) !== -1);
    } else {
      this.isSearch = false;
      this.spaces = this.initSpaces;
      this.chatMenuProjectItems = this.initChatMenuProjectItems;
      this.chatMenuGroupsItems = this.initChatMenuGroupsItems;
      directChats = this.initChatMenuDirectItems;
    }

    this.chatMenuDirectItems = [...directChats]
      .map((item) => {
        item.isShown = !!text;
        return item;
      })
      ?.filter((item) => item.title !== 'Quinn');

    this.cdr.detectChanges();
  }

  clearSearchChat(): void {
    this.isSearch = false;
    this.searchedText = null;
    this.searchChats(null);
  }

  toggleList(type: string) {
    if (type === 'unread') {
      this.toggleUnread = !this.toggleUnread;
    } else if (type === 'space') {
      this.toggleSpaces = !this.toggleSpaces;
    } else if (type === 'direct') {
      this.toggleDirects = !this.toggleDirects;
    }
  }

  readAll() {
    this.store.dispatch(new MarkAll());
  }

  getTenantsInfo(): void {
    const tenants$ = this.store.select(AuthState.getTenantList).pipe(untilDestroyed(this));

    tenants$.pipe(untilDestroyed(this)).subscribe((tenants) => {
      this.tenantList = tenants;
      this.currentTenant = tenants.find(
        (tenant) => tenant.name === this.store.selectSnapshot(AuthState.getUser).tenantName,
      );
      this.cdr.detectChanges();
    });

    tenants$
      .pipe(
        filter((tenants) => !!tenants?.length),
        take(1),
      )
      .subscribe((tenants: Tenants) => {
        tenants.forEach((tenant) => {
          if (this.currentTenant.name !== tenant.name) {
            this.socketsService.get().emit('tenants.subscribe.updates', { userId: tenant.userId });
          }
        });
      });
  }

  tenantNotificationsListener(): void {
    this.socketsService
      .get()
      .fromEvent<{ userId: string; unreadNotifications: number }>(
        'notification:send:tenantsUpdatedNotificationsBadge',
      )
      .pipe(untilDestroyed(this))
      .subscribe((notification) => {
        this.tenantList = this.tenantList.map((tenant) => {
          if (tenant.userId === notification.userId) {
            tenant = { ...tenant, badge: notification.unreadNotifications };
          }
          return tenant;
        });
        this.store.dispatch(new SetTenantsList(this.tenantList));
      });
  }

  noCallFilter(chat): boolean {
    if (!chat) {
      return;
    }

    const { notifyFilter, numberOfUnreadMessages, numberOfUnreadServiceMessages } = chat;
    return !(
      notifyFilter === FilterType.NoCall &&
      numberOfUnreadMessages &&
      numberOfUnreadServiceMessages &&
      numberOfUnreadMessages <= numberOfUnreadServiceMessages
    );
  }

  isBoldChannel(chat): boolean {
    if (!chat || chat?.notifyFilter === FilterType.Nothing) {
      return;
    }

    return this.getLengthUnreadAllMessage(chat);
  }

  sortChats(): void {
    if (this.currentSortKey === 'lastMessage') {
      this.chatMenuDirectItems.sort((a, b) => {
        if (!a.lastMessage) {
          return 1;
        }
        if (!b.lastMessage) {
          return -1;
        }
        return a.lastMessage.created_at > b.lastMessage.created_at ? -1 : 1;
      });
    } else {
      this.chatMenuDirectItems.sort((a, b) => a.title.localeCompare(b.title));
    }

    this.cdr.detectChanges();
  }

  toggleSortOrder(): void {
    this.currentSortKey = this.currentSortKey === 'lastMessage' ? 'userName' : 'lastMessage';

    this.sortChats();
  }
}
