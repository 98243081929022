import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({ name: 'fromNowAlone', standalone: true })
export class FromNowAlonePipe implements PipeTransform {
  transform(value: string, lang?: string | any): string {
    const duration = moment.duration(moment().diff(moment(value)));

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    let result = '';
    let componentsAdded = 0;

    // Check if days are greater than 0 to add to result
    if (days > 0 && componentsAdded < 2) {
      result += days + 'd ';
      componentsAdded++;
    }

    // Check if hours are greater than 0 and less than 2 components have been added
    if (hours > 0 && componentsAdded < 2) {
      result += hours + 'h ';
      componentsAdded++;
    }

    // Check if minutes are greater than 0 and less than 2 components have been added
    // Also, add minutes if days and hours are both 0 to handle "0m" case
    if ((minutes > 0 || (days === 0 && hours === 0)) && componentsAdded < 2) {
      result += minutes + 'm';
      componentsAdded++;
    }

    return result.trim();
  }
}
