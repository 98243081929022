<div
  [class.hidden]="isScrolling"
  #pinned
  class="pinned"
  [class.pinned-dark]="config?.layout.variant === 'Dark'"
  *transloco="let t; read: 'chat'"
>
  <div class="pinned__wrapper">
    <div *ngIf="!allPinnedItems?.length; else pinnedMessages" class="pinned__add">
      <div class="pinned__add-empty" (click)="addPinMessage()">
        <svg-icon
          class="pinned__add-icon"
          src="assets/icons/common/plus.svg"
          [applyClass]="true"
        ></svg-icon>
        <span>{{ t('btn-add-pin') }}</span>
      </div>
    </div>
    <ng-template #pinnedMessages>
      <div class="pinned__items" cdkDropList cdkDropListGroup>
        <div class="pinned__left">
          <app-chat-pinned-message
            *ngIf="messagesPin?.length"
            [object]="object"
            [objectId]="objectId"
            [chatId]="chatId"
            [messages]="messagesPin"
          ></app-chat-pinned-message>
          <div
            class="pinned__url"
            cdkDropList
            cdkDropListOrientation="horizontal"
            [cdkDropListData]="pinnedItems"
            (cdkDropListDropped)="dragDropPin($event)"
          >
            <app-chat-pinned-item
              *ngFor="let item of pinnedItems; let i = index"
              [item]="item"
              [chatId]="chatId"
              [object]="object"
              [objectId]="objectId"
              [platform]="platform"
              [index]="i"
              cdkDrag
              [cdkDragData]="item"
              [cdkDragStartDelay]="50"
            ></app-chat-pinned-item>
          </div>
          <div class="pinned__add-pin" *ngIf="isLimitPins">
            <svg-icon
              (click)="addPinMessage()"
              class="svg-icon"
              src="assets/icons/common/plus.svg"
              [applyClass]="true"
            ></svg-icon>
          </div>
        </div>
        <div class="pinned__all">
          <button
            *ngIf="otherPinned?.length"
            t-id="chat-navigation-tree-dot-menu"
            #dropDownOther="ngbDropdown"
            class="btn dropdown d-flex align-items-center"
            ngbDropdown
            placement="bottom-right"
            container="body"
            [autoClose]="true"
            (openChange)="actionsDropdownToggle($event)"
          >
            <svg-icon
              class="pinned__all-list"
              src="assets/icons/pin/pin-list.svg"
              [applyClass]="true"
              ngbDropdownToggle
            ></svg-icon>

            <div
              ngbDropdownMenu
              class="dropdown-menu-other"
              cdkDropList
              [cdkDropListData]="otherPinned"
              (cdkDropListDropped)="dragDropPin($event)"
            >
              <div
                *ngFor="let item of otherPinned"
                cdkDrag
                [cdkDragStartDelay]="50"
                [cdkDragData]="item"
                (click)="handlePin(item)"
                class="pinned__wrapper-other"
              >
                <div class="pinned__other">
                  <span class="pinned__other-emoji" *ngIf="item.emojiName; else iconEmoji">{{
                    item.emojiName
                  }}</span>
                  <ng-template #iconEmoji>
                    <svg-icon
                      class="pinned__other-icon"
                      src="assets/icons/common/clip.svg"
                      [applyClass]="true"
                    ></svg-icon>
                  </ng-template>
                  <span>{{ item.name }}</span>
                </div>
                <div *ngIf="pinnedService.canDeletePin(item)">
                  <svg-icon
                    (click)="$event.stopPropagation(); dropDownOther.close(); deletePin(item)"
                    class="pinned__other-cross"
                    src="assets/icons/common/cross.svg"
                    [applyClass]="true"
                  ></svg-icon>
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
