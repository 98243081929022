import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { TranslocoService } from '@ngneat/transloco';

import { TicketsDraftsResDto } from '../../api/models/tickets-drafts-res-dto';
import { DraftCreate, DraftDelete, DraftGetById, DraftUpdate } from '../store/actions/board.action';

import { BoardTicketModalComponent } from '../../modals/board-ticket/board-ticket.component';
import { TicketData } from '../../modals/board-ticket/board-ticket';

import { MinimizeService } from './minimize.service';
import { ConfirmAlert } from '../alerts/alerts';
import { IntersectionTicketsUpdateReqDtoCommonOptionalFilesArrReqDto } from '../../api/models/intersection-tickets-update-req-dto-common-optional-files-arr-req-dto';
import { IntersectionTicketsCreateReqDtoCommonOptionalFilesArrReqDto } from '../../api/models/intersection-tickets-create-req-dto-common-optional-files-arr-req-dto';

@UntilDestroy({ checkProperties: true })
@Injectable({ providedIn: 'root' })
export class DraftService {
  private activeDrafts$ = new BehaviorSubject<Array<TicketsDraftsResDto>>([]);
  constructor(
    private store: Store,
    private minimizeService: MinimizeService,
    private modalService: NgbModal,
    private translocoService: TranslocoService,
  ) {}

  setDrafts(drafts: Array<TicketsDraftsResDto>): void {
    this.activeDrafts$.next(drafts);
  }

  isHasActiveDraft(ticketId: string): TicketsDraftsResDto {
    return this.activeDrafts$.value.find((draft) => draft.ticketId === ticketId);
  }

  getDraftById(draftId: string) {
    return this.store.dispatch(new DraftGetById({ id: draftId })).pipe(
      map((store) => {
        return store.Boards.draft;
      }),
    );
  }

  openDraft(draftId: string): void {
    this.getDraftById(draftId)
      .pipe(untilDestroyed(this))
      .subscribe((draft) => {
        const modalRef = this.modalService.open(BoardTicketModalComponent, {
          size: 'xl',
          backdrop: 'static',
          scrollable: true,
          keyboard: false,
          beforeDismiss: () => modalRef.componentInstance.closeImagePreview(true),
        });
        modalRef.componentInstance.ticketData = {
          id: draft.ticketId,
          object: draft.object,
          objectId: draft.objectId,
          ticket: draft.ticket,
          draft,
        };

        this.minimizeService.minimizeInit(modalRef);
      });
  }

  openTicketOrDraft(draft: TicketsDraftsResDto, ticketData: Partial<TicketData>): void {
    ConfirmAlert(null, {
      subject: this.translocoService.translate('alert.draft-subject'),
      text: this.translocoService.translate('alert.draft-text'),
      confirmButtonText: this.translocoService.translate('alert.btn-continue-editing'),
      denyButtonText: this.translocoService.translate('alert.btn-remove-draft'),
      confirmButtonClass: 'btn-solid',
      width: 475,
      platform: 'web',
    }).then(
      () => this.openDraft(draft._id),
      () => {
        const modalRef = this.modalService.open(BoardTicketModalComponent, {
          size: 'xl',
          backdrop: 'static',
          keyboard: false,
          scrollable: true,
          beforeDismiss: () => modalRef.componentInstance.closeImagePreview(true),
        });
        modalRef.componentInstance.ticketData = ticketData;

        this.minimizeService.minimizeInit(modalRef);
      },
    );
  }

  createDraft(
    body: IntersectionTicketsCreateReqDtoCommonOptionalFilesArrReqDto,
    ticketId?: string,
  ): void {
    this.store.dispatch(new DraftCreate({ ticketId, body }));
  }

  updateDraft(
    draftId: string,
    updatedData: IntersectionTicketsUpdateReqDtoCommonOptionalFilesArrReqDto,
  ): void {
    this.activeDrafts$.next(
      this.activeDrafts$.value.map((draft) =>
        draft._id === draftId
          ? {
              ...draft,
              title: updatedData.title,
              ticket: { ...draft.ticket, ...updatedData },
            }
          : draft,
      ),
    );
    this.store.dispatch(new DraftUpdate({ id: draftId, body: updatedData }));
  }

  deleteDraft(draftId: string): void {
    this.activeDrafts$.next(this.activeDrafts$.value.filter((draft) => draft._id === draftId));

    this.store.dispatch(new DraftDelete({ id: draftId }));
  }
}
