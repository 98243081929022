import {
  Component,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnInit,
  HostListener,
  OnDestroy,
  forwardRef,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LocalStorageService } from 'ngx-localstorage';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';

import { ConfigService } from '../../services/config.service';
import { FilesHelper } from '../../utils/files-helper';
import { MediaPreview } from '../../interfaces/media-preview';
import { ChatsState } from '../../store/states/chats.state';
import { ChatsCreatePin, ChatsDeletePin, ChatsGetPin } from '../../store/actions/chats.action';
import { RolesTypeEnum } from '../chat/chat-pinned-messages/enums/roles-type.enum';
import { PinType } from '../chat/chat-pinned-messages/enums/pin-type.enum';
import { ConfirmAlert } from '../../alerts/alerts';
import { FullImagePreviewService } from '../../services/full-image-preview.service';
import { VideoPlayerComponent } from '../video-player/video-player.component';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { SvgComponent } from '../../svgs/svg/svg.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { AvatarComponent } from '../../../standalone/components/avatar/avatar.component';
import {
  NgIf,
  NgClass,
  NgStyle,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  DatePipe,
} from '@angular/common';
import { MixpanelService } from '../../../plugins/mixpanel/mixpanel.service';

export enum MediaPreviewTypes {
  Image = 'image',
  Video = 'video',
  Music = 'music',
  File = 'file',
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-media-preview',
  templateUrl: './media-preview.component.html',
  styleUrls: ['./media-preview.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    TranslocoDirective,
    forwardRef(() => AvatarComponent),
    NgClass,
    NgbTooltip,
    SvgIconComponent,
    SvgComponent,
    NgStyle,
    NgSwitch,
    NgSwitchCase,
    PinchZoomModule,
    VideoPlayerComponent,
    NgSwitchDefault,
    DatePipe,
  ],
})
export class MediaPreviewComponent implements OnInit, OnDestroy {
  @Input() platform = 'web';
  @Input() object: string;
  @Input() objectId: string;
  @Input() tz = null;
  @Input() media: MediaPreview[] = [];
  @Input() currentMediaPath: string;

  @Output() closeMediaPreview: EventEmitter<boolean> = new EventEmitter(false);

  currentMedia: MediaPreview;
  config: any;
  index = 0;
  theme: string;
  fileExt: string;
  isDownloadingFile = false;
  isLoadingPin = false;
  canUnpinImage = true;
  chatId: string;
  isPinned = false;

  messageUsername = 'message-username-light';
  imageUsername = 'image-username-light';
  messageDate = 'message-date-light';
  actionsDivider = 'image-preview-modal-actions-divider-light';

  constructor(
    private store: Store,
    private configService: ConfigService,
    private localStorageService: LocalStorageService,
    public filesHelper: FilesHelper,
    public cdr: ChangeDetectorRef,
    public fullImagePreviewService: FullImagePreviewService,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
  }

  get MediaPreviewType(): typeof MediaPreviewTypes {
    return MediaPreviewTypes;
  }

  get isPinnedMessage(): boolean {
    if (this.currentMedia.id) {
      return this.store
        .selectSnapshot(ChatsState.getPinMessages)
        .some((pin) => this.currentMedia.id && pin.linkTicketFile?._id === this.currentMedia.id);
    } else {
      return this.store
        .selectSnapshot(ChatsState.getPinMessages)
        .some((pin) => this.currentMedia.filePath && pin.url === this.currentMedia.filePath);
    }
  }

  ngOnInit(): void {
    this.setThemeVars();
    if (this.media) {
      this.setCurrentMedia();
      this.cdr.detectChanges();
    }

    this.initializeSubscribes();
  }

  ngOnDestroy() {
    this.fullImagePreviewService.currentImageElement = null;
  }

  public get isLimitPins(): boolean {
    return (
      this.store
        .selectSnapshot(ChatsState.getPinMessages)
        .filter((msg) => msg.linkDocument !== PinType.Message)?.length <= 14
    );
  }

  private setCurrentMedia(): void {
    MixpanelService.trackEvent('Media Preview: Open preview');
    this.currentMedia = this.media.find((currentMedia: MediaPreview, index: number) => {
      if (currentMedia.filePath === this.currentMediaPath) {
        this.index = index;
        const fileName = currentMedia?.fileName
          ? this.filesHelper.getFileNameWithoutExtensionHttp(currentMedia.fileName)
          : this.filesHelper.getFileNameWithoutExtension(currentMedia.filePath);
        const fileExt = this.filesHelper.getFileExtension(currentMedia.filePath);
        this.fileExt = fileExt;

        return { ...currentMedia, fileName, fileExt };
      }
    });
  }

  private setThemeVars(): void {
    this.messageUsername =
      this.config?.layout.variant === 'Dark' ? 'message-username-dark' : 'message-username-light';
    this.imageUsername =
      this.config?.layout.variant === 'Dark' ? 'image-username-dark' : 'image-username-light';

    this.messageDate =
      this.config?.layout.variant === 'Dark' ? 'message-date-dark' : 'message-date-light';
    this.actionsDivider =
      this.config?.layout.variant === 'Dark'
        ? 'image-preview-modal-actions-divider-dark'
        : 'image-preview-modal-actions-divider-light';
  }

  public prev(): void {
    MixpanelService.trackEvent('Media Preview: Prev');
    if (this.media.length > 0) {
      this.index--;
      this.currentMedia = this.media[this.index];
      this.isPinned = this.isPinnedMessage;
      this.fullImagePreviewService.onResetOptions();
    }
  }

  public next(): void {
    MixpanelService.trackEvent('Media Preview: Next');
    if (this.index < this.media.length) {
      this.index++;
      this.currentMedia = this.media[this.index];
      this.isPinned = this.isPinnedMessage;
      this.fullImagePreviewService.onResetOptions();
    }
  }

  downloadFile() {
    MixpanelService.trackEvent('Media Preview: Download file');
    if (this.currentMedia?.filePath) {
      const fileName = this.currentMedia.filePath.split('/').pop();
      this.filesHelper.downloadFile(this.currentMedia.filePath, fileName, this.platform);
    }
  }

  public pinMedia() {
    MixpanelService.trackEvent('Media Preview: Pin media');
    this.isLoadingPin = true;

    if (this.currentMedia.id) {
      this.store
        .dispatch(
          new ChatsCreatePin({
            id: this.chatId,
            body: {
              linkDocument: 'tickets.files',
              linkDocumentId: this.currentMedia.id,
              object: this.object,
              objectId: this.objectId,
            },
          }),
        )
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            this.isPinned = true;
            this.isLoadingPin = false;
          },
          () => {
            this.isLoadingPin = false;
          },
        );
    } else {
      this.store
        .dispatch(
          new ChatsCreatePin({
            id: this.chatId,
            body: {
              name: `Internal ${this.currentMedia.fileType}`,
              isInternalLink: true,
              url: this.currentMedia.filePath,
              openInFrame: true,
              object: this.object,
              objectId: this.objectId,
            },
          }),
        )
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            this.isPinned = true;
            this.isLoadingPin = false;
          },
          () => {
            this.isLoadingPin = false;
          },
        );
    }
  }

  public unPinMedia() {
    MixpanelService.trackEvent('Media Preview: Unpin media');
    const id = this.takePinPreview()?._id;

    ConfirmAlert(this.translocoService.translate('alert.pin-title'), {
      subject: this.translocoService.translate('alert.unpin-subject'),
      text: this.translocoService.translate('alert.unpin-text'),
      confirmButtonText: this.translocoService.translate('alert.unpin-btn-text'),
      platform: this.platform,
    }).then(() => {
      this.isLoadingPin = true;
      this.store
        .dispatch(
          new ChatsDeletePin({
            id: this.chatId,
            pinnedMessageId: id,
            object: this.object,
            objectId: this.objectId,
          }),
        )
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            this.isPinned = false;
            this.isLoadingPin = false;
          },
          () => {
            this.isLoadingPin = false;
          },
        );
    });
  }

  private initializeSubscribes() {
    this.chatId = this.store
      .selectSnapshot(ChatsState.getChats)
      .find((chat) => chat.objectId === this.objectId)?._id;

    if (this.chatId && !this.store.selectSnapshot(ChatsState.getPinMessages)?.length) {
      this.store
        .dispatch(new ChatsGetPin({ id: this.chatId }))
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.isPinned = this.isPinnedMessage;

          const pinnedMessage = this.takePinPreview();
          if (pinnedMessage) {
            this.canUnpinImage = this.canDeletePin(pinnedMessage);
          }
        });
    } else {
      this.isPinned = this.isPinnedMessage;

      const pinnedMessage = this.takePinPreview();
      if (pinnedMessage) {
        this.canUnpinImage = this.canDeletePin(pinnedMessage);
      }
    }
  }

  private takePinPreview() {
    if (this.currentMedia.id) {
      return this.store
        .selectSnapshot(ChatsState.getPinMessages)
        .find((pin: any) => pin.linkDocument && pin.linkTicketFile?._id === this.currentMedia.id);
    } else {
      return this.store
        .selectSnapshot(ChatsState.getPinMessages)
        .find((pin: any) => pin.url === this.currentMedia.filePath);
    }
  }

  private canDeletePin(item): boolean {
    const userId = this.localStorageService.get('userId') || '';
    const chatId = this.store
      .selectSnapshot(ChatsState.getChats)
      .find((chat) => chat.objectId === this.objectId)?._id;
    const allUser = this.store.selectSnapshot(ChatsState.getChatMembers)(chatId);
    const currentUser = allUser?.find((user) => user.userId === userId);

    return (
      userId === item.creatorId ||
      currentUser?.roleName?.includes(
        RolesTypeEnum.Owner || RolesTypeEnum.SpaceLeader || RolesTypeEnum.ProjectLeader,
      )
    );
  }

  @HostListener('wheel', ['$event'])
  onScroll(event: WheelEvent) {
    this.fullImagePreviewService.setZoomState(event);
  }
}
