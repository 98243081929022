import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { TicketsFieldsDbDto } from '../../../../../api/models/tickets-fields-db-dto';
import { TicketsFieldsValuesReqDto } from '../../../../../api/models/tickets-fields-values-req-dto';
import { FieldType } from '../../../../custom-field/enums/fieldType';
import { AuthState } from '../../../../../shared/store/states/auth.state';
import { Store } from '@ngxs/store';
import { TabIndex } from '../../custom-fields.component';
import { DateTimePickerComponent } from '../../../../../shared/components/date-time-picker/date-time-picker.component';
import { TranslocoDirective } from '@ngneat/transloco';
import { NgIf } from '@angular/common';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-date-time',
  templateUrl: 'date-time.component.html',
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, TranslocoDirective, DateTimePickerComponent],
})
export class DateTimeComponent implements OnInit {
  FieldType = FieldType;
  @Input() form: FormGroup;
  @Input() fieldConfig: TicketsFieldsDbDto;
  @Input() customFieldValues?: TicketsFieldsValuesReqDto;
  @Input() datePickedWithoutTime: (event: boolean) => void;
  @Input() timeIsEmpty: boolean;
  withoutDefaultTime: boolean;
  platform = 'web';
  isAllDay: boolean;
  tabIndexes: Array<TabIndex>;
  displayName: string;

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.form
      .get(this.fieldConfig.title)
      .valueChanges.pipe(take(1))
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.withoutDefaultTime = !value;
        this.cdr.detectChanges();
      });
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);
  }
}
