<form action="#" class="form form-horizontal" *transloco="let t; read: 'data-room'">
  <div class="form-body">
    <div class="form-group access-group">
      <label>{{ t('access-title') }}</label>

      <ng-select
        t-id="data-room-access-selector"
        class="access-selector"
        [ngClass]="{
          'icon-public': access === 'public',
          'icon-private': access === 'private'
        }"
        prefix=""
        [items]="accessViews"
        bindLabel="label"
        bindValue="value"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="access"
        [clearable]="false"
        [searchable]="false"
        [closeOnSelect]="true"
        (change)="accessChange()"
      ></ng-select>

      <label class="access-info" *ngIf="access === 'public'">
        {{
          t('access-info-public', {
            value: capitalizeFirstLetter,
            object: object === 'spaces' ? t('space') : t('project')
          })
        }}
      </label>
      <label class="access-info" *ngIf="access === 'private'">
        {{ t('access-info-private', { value: capitalizeFirstLetter }) }}
      </label>
    </div>

    <div class="form-group" *ngIf="access === 'private'">
      <label>{{ t('invited-members') }}</label>
      <ng-select
        t-id="access-control-documents-invite-members"
        [items]="users | async"
        [(ngModel)]="invitedMembers"
        (change)="membersChange()"
        [multiple]="true"
        bindLabel="userName"
        bindValue="_id"
        placeholder=""
        name="event-invitedMembers"
        appendTo="body"
      >
        <ng-template ng-label-tmp let-item="item">
          <div class="d-flex">
            <app-avatar [userId]="item._id"></app-avatar>
            <div class="mt-1 ml-2">
              <b>{{ item.userName }}</b>
            </div>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index">
          <div class="d-flex">
            <app-avatar [userId]="item._id"></app-avatar>
            <div class="mt-1 ml-2">
              <b>{{ item.userName }}</b>
            </div>
          </div>
        </ng-template>
      </ng-select>
    </div>

    <div class="form-group" *ngIf="access === 'private'">
      <label>{{ t('invited-roles') }}</label>
      <ng-select
        t-id="access-control-documents-invite-role"
        [items]="roles | async"
        [(ngModel)]="invitedRoles"
        (change)="rolesChange()"
        [multiple]="true"
        bindLabel="roleName"
        bindValue="roleName"
        placeholder=""
        name="event-invitedRoles"
        appendTo="body"
      >
        <ng-template ng-label-tmp let-item="item">
          <b t-id="access-control-documents-space-member">{{ item.roleName }}</b>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index">
          <b t-id="access-control-documents-space-leader">{{ item.roleName }}</b>
        </ng-template>
      </ng-select>
    </div>
  </div>
</form>
