import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { TicketsFieldsDbDto } from '../../../../../api/models/tickets-fields-db-dto';
import { TicketsFieldsValuesReqDto } from '../../../../../api/models/tickets-fields-values-req-dto';
import { TicketsLabelsDbDto } from '../../../../../api/models/tickets-labels-db-dto';
import { TabIndex } from '../../custom-fields.component';
import { TicketsLabelCreate } from '../../../../../shared/store/actions/board.action';
import { BoardsState } from '../../../../../shared/store/states/boards.state';
import { TicketData } from '../../../board-ticket';
import { TicketService } from '../../../../../shared/services/ticket.service';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-select',
  templateUrl: 'select-labels-tags.component.html',
  standalone: true,
  imports: [TranslocoDirective, NgIf, FormsModule, ReactiveFormsModule, NgSelectModule],
})
export class SelectLabelsTagsComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() fieldConfig: TicketsFieldsDbDto;
  @Input() customFieldValues?: TicketsFieldsValuesReqDto;

  isMinimized = false;
  inputFocused: (name: string) => void;
  setFocusedControl: (tabIndex: string) => void;
  tabIndexes: Array<TabIndex>;
  labels: Array<TicketsLabelsDbDto> = [];
  addLabelPromise: (label: any) => Promise<any>;
  loading = false;
  selectedLabels = [];
  object: string;
  modalData: TicketData;
  objectId: string;
  displayName: string;
  isForMultiplyObjects = false;
  isDraft = false;
  selectedLabelsForDraft$?: Observable<string[]>;

  trackByFn(item) {
    return item?._id;
  }
  get placeholder() {
    return `${this.translocoService.translate('modals.custom-fields.select')} ${this.fieldConfig.title}`;
  }

  ngOnChanges(changes: SimpleChanges) {}

  constructor(
    private store: Store,
    private ticketService: TicketService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    if ((this.isForMultiplyObjects || this.isDraft) && !this.isMinimized) {
      this.ticketService.labels$
        .pipe(untilDestroyed(this))
        .subscribe((res) => (this.labels = [...res]));
      this.selectedLabelsForDraft$
        ?.pipe(untilDestroyed(this))
        .subscribe((selectedLabels) => (this.selectedLabels = selectedLabels));
    } else {
      this.labels = this.store.selectSnapshot(BoardsState.getTicketsLabels);
    }

    if (this.modalData?.id && !this.isDraft) {
      const ticket = this.store.selectSnapshot(BoardsState.getTicket);
      this.selectedLabels =
        ticket?.labels?.filter((labelId) =>
          this.labels?.some((label: { _id: string }) => label._id === labelId),
        ) || [];
    }

    this.addLabelPromise = (label) => {
      return new Promise((resolve) => {
        this.loading = true;

        const body = {
          label: label,
          object: this.object,
          objectId: this.objectId,
        };
        this.store
          .dispatch(new TicketsLabelCreate(body))
          .pipe(untilDestroyed(this))
          .subscribe((res) => {
            if (res.Boards?.lastCreatedLabel) {
              this.loading = false;
              resolve(res.Boards.lastCreatedLabel);
            }
          });
      });
    };
  }
}
