/** Create emoji **/
export class EmojiCreate {
  static readonly type = '[Emojis] Create';
  constructor(public payload) {}
}

/** Delete emoji **/
export class EmojiDelete {
  static readonly type = '[Emojis] Delete';
  constructor(public payload) {}
}

export class SocketNewEmoji {
  static readonly type = '[Emojis] SocketNewEmoji';
  constructor(public payload) {}
}

export class SocketDeleteEmoji {
  static readonly type = '[Emojis] SocketDeleteEmoji';
  constructor(public payload) {}
}
