import { Router, RouterLink } from '@angular/router';
import {
  EventEmitter,
  ChangeDetectorRef,
  Component,
  Input,
  Output,
  ViewChild,
  AfterViewChecked,
  OnDestroy,
  OnInit,
  OnChanges,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgScrollbar } from 'ngx-scrollbar';
import moment from 'moment-timezone';

import { Thread } from '../chat.model';
import { RouterTenantPipe } from '../../../pipes/router-tenant.pipe';
import { ConfigService } from '../../../services/config.service';
import { SocketsService } from '../../../services/sockets.service';
import {
  ChatsGetMembers,
  ChatsOnlyUnreadThreadsFilter,
  ChatsSetIsThreadsForChat,
  ChatsSetThreadsList,
  MarkAll,
  ThreadGetList,
} from '../../../store/actions/chats.action';
import {
  CalendarEventCreate,
  CalendarEventDelete,
} from '../../../store/actions/calendar-events.action';
import { TicketsCreate, TicketsDelete } from '../../../store/actions/board.action';
import { NoteCreate, NoteDelete } from '../../../store/actions/notes.action';
import { ChatsState } from '../../../store/states/chats.state';
import { SpacesState } from '../../../store/states/spaces.state';
import { RecordService } from '../../../services/record.service';
import { ProjectsState } from '../../../store/states/projects.state';
import { CheckPermissionPipe } from '../../../pipes/check-permission.pipe';
import { SpacesDbDto } from '../../../../api/models/spaces-db-dto';
import { ProjectsDbDto } from '../../../../api/models/projects-db-dto';
import { LocalStorageKeys } from '../../../../types/local-storage-keys.enum';
import { LocalStorageService } from 'ngx-localstorage';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { MessageFilesComponent } from '../chat-messages/message-files/message-files.component';
import { LinkedObjectBadgeComponent } from '../../linked-object-badge/linked-object-badge.component';
import { ChatMessageContentComponent } from '../chat-message-content/chat-message-content.component';
import { AvatarComponent } from '../../../../standalone/components/avatar/avatar.component';
import { GroupAvatarComponent } from '../../space-projects/group-avatar/group-avatar.component';
import { ProjectAvatarComponent } from '../../space-projects/project-avatar/project-avatar.component';
import { SvgComponent } from '../../../svgs/svg/svg.component';
import { SpaceAvatarComponent } from '../../space-projects/space-avatar/space-avatar.component';
import { NgIf, NgFor, AsyncPipe, CommonModule } from '@angular/common';

@Component({
  selector: 'app-chat-threads-list',
  templateUrl: './chat-threads-list.component.html',
  styleUrls: ['./chat-threads-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslocoDirective,
    NgIf,
    NgScrollbar,
    NgFor,
    SpaceAvatarComponent,
    RouterLink,
    SvgComponent,
    ProjectAvatarComponent,
    GroupAvatarComponent,
    forwardRef(() => AvatarComponent),
    ChatMessageContentComponent,
    LinkedObjectBadgeComponent,
    MessageFilesComponent,
    AsyncPipe,
    RouterTenantPipe,
  ],
})
export class ChatThreadsListComponent implements OnInit, OnChanges, OnDestroy, AfterViewChecked {
  @ViewChild('scrollableThreads') scrollbarRef: NgScrollbar; // Get scrollbar component reference

  @Input() object: string;
  @Input() objectId: string;
  @Input() chatId: string;
  @Input() platform = 'web';
  @Input() isMobile = false;
  @Input() mentionChatMembers = [];

  @Output() openThread = new EventEmitter();

  destroy$: Subject<void> = new Subject<void>();

  config: any = {};
  chat: any;
  chats: any[];
  spaces: SpacesDbDto[] = [];
  projects: ProjectsDbDto[] = [];
  spacesChats: any[];
  threadsList: Thread[] = [];
  threadsListInfo: any;
  language: string = this.localStorage.get(LocalStorageKeys.language) || 'en';
  workspaceTitle: string;
  switchThreadsForChat = false;
  isOnlyUnreadThreads = false;
  getTimeout: any;
  isLoaded = true;
  threadsPerPage = 30;
  currentPage = 1;
  isPreloadActive = false;
  needScrollUpdate = false;
  scrollToBottomTimeout = 50;

  constructor(
    private actions: Actions,
    private store: Store,
    private router: Router,
    private routerTenantPipe: RouterTenantPipe,
    private configService: ConfigService,
    private socketsService: SocketsService,
    private checkPermissionPipe: CheckPermissionPipe,
    public cdr: ChangeDetectorRef,
    public screenRecord: RecordService,
    private localStorage: LocalStorageService,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
    this.threadsPerPage = this.configService.THREADS_PER_PAGE;
  }

  ngOnInit() {
    this.store
      .select(ChatsState.getThreadsFilters)
      .pipe(takeUntil(this.destroy$))
      .subscribe((filters) => {
        this.isOnlyUnreadThreads = filters.onlyUnreadThreads;
        this.switchThreadsForChat = filters.isThreadsForChat;
      });

    if (!this.store.selectSnapshot(ChatsState.getThreadsList).length) {
      this.getThreadListPage(1);
    }

    this.translocoService.langChanges$.pipe(takeUntil(this.destroy$)).subscribe((lang) => {
      this.language = lang || 'en';
    });

    this.store
      .select(ChatsState.getChats)
      .pipe(takeUntil(this.destroy$))
      .subscribe((chats) => (this.chats = chats));

    this.store
      .select(SpacesState.getLoadedSpaces)
      .pipe(takeUntil(this.destroy$))
      .subscribe((spaces) => (this.spacesChats = this.getSpacesChats(this.chats, spaces)));

    combineLatest([
      this.store.select(SpacesState.getLoadedSpaces),
      this.store.select(ProjectsState.getLoadedProjects),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([spaces, projects]) => {
        this.spaces = spaces.filter((item) =>
          this.checkPermissionPipe.transform('spaces::' + item._id + '::calendarEventCreate'),
        );
        this.projects = projects.filter((item) =>
          this.checkPermissionPipe.transform('projects::' + item._id + '::calendarEventCreate'),
        );
      });

    this.store
      .select(ChatsState.getThreadsListInfo)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.threadsListInfo = res;
        if (this.isPreloadActive) {
          this.needScrollUpdate = true;
          this.isPreloadActive = false;
        }
        this.cdr.detectChanges();
      });

    this.store
      .select(ChatsState.getThreadsList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((threadsList) => {
        this.getWorkspaceTitle();

        this.threadsList = threadsList
          ?.map((thread) => {
            const chatItem = this.chats?.find((c) => c._id === thread.message.chatId);

            if (chatItem) {
              const space = this.spacesChats?.find(
                (c) => c._id === (chatItem?.spaceId || chatItem?.objectId),
              );

              return {
                ...thread,
                chatId: thread.message.chatId,
                userId: thread.message.userId,
                chatUserId: chatItem.userId,
                chatName: chatItem.chatName,
                spaceName: space?.spaceName || space?.chatName,
                spaceChatId: space?.chatItem?._id,
                spaceId: space?._id,
                projectId: chatItem?.object === 'projects' ? chatItem?.objectId : null,
                lastReply: thread.threadsMessagesInfo?.lastReply,
              };
            }
          })
          .sort((a, b) => {
            const aTs = moment(a.threadsMessagesInfo.lastReply).unix();
            const bTs = moment(b.threadsMessagesInfo.lastReply).unix();
            if (aTs < bTs) {
              return 1;
            } else if (aTs > bTs) {
              return -1;
            } else {
              return 0;
            }
          });

        if (this.isOnlyUnreadThreads) {
          this.threadsList = this.threadsList.filter((thread) => {
            return (
              thread.threadsMessagesInfo.numberOfUnreadMentions ||
              thread.threadsMessagesInfo.numberOfUnreadMessages
            );
          });
        }

        if (this.switchThreadsForChat) {
          this.threadsList = this.threadsList.filter((thread) => {
            return thread.chatId === this.chatId;
          });
        }

        this.cdr.detectChanges();
      });

    this.actions
      .pipe(takeUntil(this.destroy$), ofActionSuccessful(ChatsSetThreadsList))
      .subscribe(() => {
        this.isLoaded = true;
        this.cdr.detectChanges();
      });

    this.actions
      .pipe(
        takeUntil(this.destroy$),
        ofActionSuccessful(
          CalendarEventCreate,
          CalendarEventDelete,
          TicketsCreate,
          TicketsDelete,
          NoteCreate,
          NoteDelete,
        ),
      )
      .subscribe(() => {
        clearTimeout(this.getTimeout);
        this.getTimeout = setTimeout(() => this.getThreadListPage(1), 1000);
      });
  }

  ngAfterViewChecked() {
    if (this.needScrollUpdate) {
      setTimeout(() => {
        this.scrollTopCorrection();
        this.needScrollUpdate = false;
        this.cdr.detectChanges();
      }, this.scrollToBottomTimeout);
    }

    this.scrollbarRef?.verticalScrolled
      .pipe(takeUntil(this.destroy$))
      .subscribe((e) => this.getNextListPage(e.target));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chatId && !!changes.chatId.currentValue) {
      this.currentPage = 1;
      if (this.store.selectSnapshot(ChatsState.getThreadsFilters).isThreadsForChat) {
        this.getThreadListPage(1);
      }
      this.getWorkspaceTitle();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    clearTimeout(this.getTimeout);
  }

  getWorkspaceTitle() {
    this.chat = this.chats?.find((c) => c._id === this.chatId);
    if (this.chat) {
      const space = this.spacesChats.find(
        (c) => c._id === (this.chat.spaceId || this.chat.objectId),
      );
      const spaceChat = this.chats.find(
        (c) => c.object === 'spaces' && c.objectId === (this.chat.spaceId || this.chat.objectId),
      );
      const spaceName = spaceChat?.chatName || space?.spaceName || space?.chatName;
      const spaceChatId = spaceChat?._id || space?._id;

      this.workspaceTitle = spaceName || this.chat.chatName;
      if (spaceName && this.chat._id !== spaceChatId) {
        this.workspaceTitle = this.chat.chatName;
      }
    } else {
      this.workspaceTitle = null;
    }
    this.cdr.detectChanges();
  }

  updateThreadList() {
    this.getThreadListPage(this.currentPage);
  }

  getSpacesChats(chats, spaces) {
    return spaces?.map((item) => ({
      ...item,
      chatItem: chats?.find(
        (chatItem) => chatItem.object === 'spaces' && chatItem.objectId === item._id,
      ),
    }));
  }

  toggleThreadsForChat(event) {
    this.store
      .dispatch(new ChatsSetIsThreadsForChat(event))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.switchThreadsForChat = event;
        this.currentPage = 1;
        this.getThreadListPage(1);
      });
  }

  filterUnreadThreads(filtered: boolean): void {
    this.store
      .dispatch(new ChatsOnlyUnreadThreadsFilter(filtered))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isOnlyUnreadThreads = filtered;
        this.currentPage = 1;
        this.getThreadListPage(this.currentPage);
      });
  }

  scrollTopCorrection() {
    const viewportEl = this.scrollbarRef.viewport.nativeElement;
    if (viewportEl.scrollTop === viewportEl.scrollHeight - viewportEl.clientHeight) {
      viewportEl.scrollTop -= 2;
    }
  }

  getNextListPage(target) {
    if (!this.isPreloadActive && target.scrollHeight > target.offsetHeight) {
      const scrollbar = document
        .querySelector('#threadList .scrollbar-control')
        ?.getBoundingClientRect();
      if (
        (target.scrollTop + scrollbar.height) / target.scrollHeight > 0.999 &&
        !this.store.selectSnapshot(ChatsState.getThreadsFullyLoadedStatus)
      ) {
        this.isPreloadActive = true;
        setTimeout(() => {
          this.currentPage++;
          this.getThreadListPage(this.currentPage);
        }, 0);
      }
    }
  }

  getThreadListPage(page) {
    this.isLoaded = false;
    const request = {
      chatId: this.switchThreadsForChat ? this.chatId : null,
      page: page,
      perPage: this.threadsPerPage,
      onlyUnread: this.isOnlyUnreadThreads,
    };
    this.store.dispatch(new ThreadGetList(request));
  }

  markAllAsRead(event) {
    event.preventDefault();
    this.store.dispatch(new MarkAll({ onlyThreads: true }));
    // this.socketsService.get().emit('chats:threadMarkAllAsRead');
  }

  getRouteToChat(thread, isSpace = false) {
    if (isSpace) {
      return this.isMobile && thread?.spaceId
        ? '/space/' + thread.spaceId
        : '/chat/' + thread.spaceChatId;
    }
    return this.isMobile && thread?.projectId
      ? '/project/' + thread.projectId
      : '/chat/' + thread.chatId;
  }

  getUrl(message): boolean {
    return (
      (message.ticketsUrlData && message.ticketsUrlData.length) ||
      (message.wikiPagesUrlData && message.wikiPagesUrlData.length)
    );
  }

  hasPermissionToTicket(ticket: any): boolean {
    let hasPermission = true;

    if (ticket.object === 'spaces') {
      hasPermission = this.spaces.some((space) => space._id === ticket.objectId);
    } else if (ticket.object === 'projects') {
      hasPermission = this.projects.some((project) => project._id === ticket.objectId);
    }

    return hasPermission;
  }

  getLastReplyText(date: string | Date | undefined): string {
    return moment(date, '', this.language).fromNow();
  }

  openThreadSidebar(e, thread) {
    e.preventDefault();
    e.stopPropagation();

    const message = {
      ...thread.message,
      threadsMessagesInfo: thread.threadsMessagesInfo,
    };

    if (message?.threadId) {
      this.store.dispatch(new ChatsGetMembers(message.chatId));

      if (!this.isMobile) {
        this.openThread.emit(message);
      } else {
        this.router.navigate([this.routerTenantPipe.transform('thread/' + message.threadId)]);
      }
    }
  }

  getIsFilesObject(message): boolean {
    return message.linkObject === 'files';
  }
}
