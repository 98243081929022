import { TicketsFieldsCreateReqDto } from '../../../api/models/tickets-fields-create-req-dto';
import { TicketsFieldsUpdateReqDto } from '../../../api/models/tickets-fields-update-req-dto';
import { TicketsFieldsBulkUpdateReqDto } from '../../../api/models/tickets-fields-bulk-update-req-dto';

/** Get field list events **/
export class FieldGetList {
  static readonly type = '[BoardsField] FieldGetList';
  constructor(public payload) {}
}

/** Create field list events **/
export class CreateField {
  static readonly type = '[BoardsField] CreateField';
  constructor(public payload: TicketsFieldsCreateReqDto) {}
}

export class UpdateField {
  static readonly type = '[BoardsField] UpdateField';
  constructor(public payload: { body: TicketsFieldsUpdateReqDto; id: string }) {}
}

export class UpdateFields {
  static readonly type = '[BoardsField] UpdateFields';
  constructor(public payload: TicketsFieldsBulkUpdateReqDto) {}
}

export class DeleteField {
  static readonly type = '[BoardsField] DeleteField';
  constructor(public payload: { id: string }) {}
}

export class GetFieldValues {
  static readonly type = '[BoardsField] GetFieldValues';
  constructor(public payload: { body: TicketsFieldsUpdateReqDto; id: string }) {}
}
