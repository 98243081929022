<section id="users" *transloco="let t; read: 'board'">
  <div
    class="actions-bar d-flex align-items-center"
    [class.actions-bar-mobile]="platform === 'mobile'"
  >
    <span class="flex-grow-1 font-weight-medium">
      {{
        object === 'projects'
          ? t('users-list-members-project-title')
          : t('users-list-members-title', {
              value: object === 'spaces' ? 'Space' : object === 'projects' ? 'Project' : 'Workplace'
            })
      }}
    </span>
    <button
      *ngIf="
        ((object === 'spaces' && ('spaces::' + objectId + '::spacesInvite' | checkPermission)) ||
          (object === 'projects' &&
            ('projects::' + objectId + '::projectInvite' | checkPermission)) ||
          (object === 'tenants' && ('tenants::null::tenantInvite' | checkPermission))) &&
        checkIsPersonal
      "
      t-id="users-list-invite-user"
      type="button"
      class="btn btn-outline d-flex align-items-center ml-2"
      (click)="handlerOpenInviteModal()"
    >
      <app-svg name="user-plus" width="18" height="14" class="mr-1" aria-hidden="true"></app-svg>
      <span>{{ t('btn-invite-members') }}</span>
    </button>
  </div>

  <div class="card m-0">
    <div class="card-content">
      <div class="card-body table-responsive">
        <ng-scrollbar
          class="scroll-event scrollable-container"
          track="all"
          thumbClass="white-scrollbars"
        >
          <table class="table users-list-table m-0">
            <thead>
              <tr>
                <th>{{ t('users-list-thead-username') }}</th>
                <th>{{ t('users-list-thead-role') }}</th>
                <th>{{ t('users-list-thead-status') }}</th>
                <th>{{ t('users-list-thead-actions') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of allUsers | async" t-id="users-list-users">
                <td>
                  <div class="d-flex align-items-center">
                    <app-avatar
                      *ngIf="user.userName"
                      [width]="24"
                      [height]="24"
                      [statusSize]="7"
                      [userId]="user._id"
                    ></app-avatar>
                    <i
                      *ngIf="!user.userName"
                      class="fa fa-envelope-o envelope-icon"
                      aria-hidden="true"
                    ></i>
                    <div class="cell-line-height ml-2">
                      <span class="line-height-1 mb-0">{{
                        user.userName ? user.userName : user.email
                      }}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <span
                    *ngIf="
                      user._id === userId ||
                      user.status !== 'Active' ||
                      user.roleName === 'Owner' ||
                      (object === 'spaces' &&
                        !('spaces::' + objectId + '::changeUserRole' | checkPermission)) ||
                      (object === 'projects' &&
                        !('projects::' + objectId + '::changeUserRole' | checkPermission))
                    "
                  >
                    {{ user.roleName }}
                  </span>

                  <div
                    ngbDropdown
                    class="user-actions d-inline-block"
                    container="body"
                    placement="bottom"
                    *ngIf="
                      user._id !== userId &&
                      user.status === 'Active' &&
                      user.roleName !== 'Owner' &&
                      ((object === 'spaces' &&
                        ('spaces::' + objectId + '::changeUserRole' | checkPermission)) ||
                        (object === 'projects' &&
                          ('projects::' + objectId + '::changeUserRole' | checkPermission)))
                    "
                  >
                    <a class="mr-2" ngbDropdownToggle t-id="users-list-role">
                      {{ user.roleName }}
                      <i
                        *ngIf="!isAssistant(user)"
                        class="ft-chevron-down font-small-4"
                        aria-hidden="true"
                      ></i>
                    </a>

                    <ng-container *ngIf="!isAssistant(user)">
                      <div ngbDropdownMenu>
                        <button
                          t-id="users-list-change-role"
                          ngbDropdownItem
                          *ngFor="
                            let role of filterUserRoles(rolesList, user.roleName);
                            trackBy: trackByRoleId
                          "
                          (click)="changeUserRole(user._id, role.roleName)"
                        >
                          {{ role.roleName }}
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </td>
                <td>
                  <span class="badge active">{{ user.status }}</span>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <div
                      *ngIf="user.status !== 'Newly invited'"
                      class="member-edit mr-2"
                      [class.ml-3]="!checkIsPersonal"
                      ngbTooltip="{{
                        user._id === userId ? t('edit-profile-tooltip') : t('member-info-tooltip')
                      }}"
                      (click)="handlerOpenUserViewModal(user)"
                    >
                      <app-svg
                        *ngIf="user._id === userId"
                        name="pen-to-square"
                        width="16"
                        height="16"
                      ></app-svg>
                      <app-svg
                        *ngIf="user._id !== userId"
                        name="info-circle"
                        width="16"
                        height="16"
                      ></app-svg>
                    </div>

                    <div
                      *ngIf="
                        ((user.roleName === 'Owner' && user._id === userId) ||
                          user.roleName !== 'Owner') &&
                        ((object === 'spaces' &&
                          ('spaces::' + objectId + '::spacesUnassignUser' | checkPermission)) ||
                          (object === 'projects' &&
                            ('projects::' + objectId + '::projectUnassign' | checkPermission))) &&
                        checkIsPersonal &&
                        !isAssistant(user)
                      "
                      t-id="users-list-delete-action"
                      class="member-delete"
                      triggers="hover"
                      [ngbTooltip]="t('unassign-member-tooltip')"
                      (click)="unAssignUser(user)"
                    >
                      <app-svg name="trash" width="16" height="16"></app-svg>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-scrollbar>
      </div>
    </div>
  </div>
</section>
