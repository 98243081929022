import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {
  NgbModal,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownButtonItem,
  NgbDropdownItem,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';

import { UsersDbDto } from '../../../api/models/users-db-dto';
import { ConfirmAlert } from '../../alerts/alerts';
import { RolesGet } from '../../store/actions/roles.action';
import { SpaceGetUsersList } from '../../store/actions/spaces.action';
import { ProjectGetUsersList } from '../../store/actions/projects.action';
import { ChangeUserRole, GetUsersListByTenant } from '../../store/actions/users.action';
import { ProjectsUnassignMember, ProjectsRevokeInvite } from '../../store/actions/projects.action';
import { SpaceUnassignMember, SpaceRevokeInvite } from '../../store/actions/spaces.action';
import { AuthState } from '../../store/states/auth.state';
import { UsersState } from '../../store/states/users.state';
import { RolesState } from '../../store/states/roles.state';
import { SpacesState } from '../../store/states/spaces.state';
import { ProjectsState } from '../../store/states/projects.state';
import { InviteToSpaceModalComponent } from '../../../modals/invite-to-space-modal/invite-to-space-modal.component';
import { UserViewComponent } from '../../../modals/user-view/user-view.component';
import { ConfigService, ITemplateConfig } from '../../services/config.service';
import { SpacesDbDto } from '../../../api/models/spaces-db-dto';
import { CheckPermissionPipe } from '../../pipes/check-permission.pipe';
import { AvatarComponent } from '../../../standalone/components/avatar/avatar.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { SvgComponent } from '../../svgs/svg/svg.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MixpanelService } from '../../../plugins/mixpanel/mixpanel.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgIf,
    SvgComponent,
    NgScrollbar,
    NgFor,
    forwardRef(() => AvatarComponent),
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    NgbTooltip,
    AsyncPipe,
    CheckPermissionPipe,
  ],
})
export class UsersListComponent implements OnInit, OnDestroy {
  @Input() object: string;
  @Input() objectId: string;
  @Input() platform = 'web';
  @Output() close = new EventEmitter<string>();

  destroy$: Subject<void> = new Subject<void>();
  rolesSubs$: Subscription;
  allUsers: Observable<UsersDbDto[]>;
  currentUser: any;
  userId: string;
  rolesList: any[];
  space: SpacesDbDto;
  config: ITemplateConfig;

  constructor(
    public ref: ChangeDetectorRef,
    public configService: ConfigService,
    private actions: Actions,
    private store: Store,
    private toastr: ToastrService,
    protected readonly modal: NgbModal,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
  }

  /**
   * Init component - pass api calls
   */
  ngOnInit() {
    this.currentUser = this.store.selectSnapshot(AuthState.getUser);
    this.userId = this.currentUser._id as string;
    this.space = this.store
      .selectSnapshot(SpacesState.getLoadedSpaces)
      .find((space) => space._id === this.objectId);
    this.store.dispatch(new RolesGet());
    this.rolesSubs$ = this.store
      .select(RolesState.getRoles)
      .pipe(map((items) => items.filter((item) => item.object === this.object)))
      .subscribe((rolesList) => (this.rolesList = rolesList || []));

    if (this.object === 'spaces') {
      this.actions
        .pipe(takeUntil(this.destroy$), ofActionSuccessful(SpaceGetUsersList))
        .subscribe(() => {
          this.allUsers = this.store.select(SpacesState.getAllUsers).pipe(
            takeUntil(this.destroy$),
            map((users) => {
              if (users) {
                return users.filter(
                  (user) =>
                    user.roleName !== 'Project Leader' && user.roleName !== 'Project Member',
                );
              }
            }),
          );
          this.ref.detectChanges();
        });
      this.store.dispatch(new SpaceGetUsersList({ id: this.objectId, exists: true }));
    } else if (this.object === 'projects') {
      this.actions
        .pipe(takeUntil(this.destroy$), ofActionSuccessful(ProjectGetUsersList))
        .subscribe(() => {
          this.allUsers = this.store.select(ProjectsState.getAllUsers);
          this.ref.detectChanges();
        });
      this.store.dispatch(new ProjectGetUsersList({ id: this.objectId, exists: true }));
    } else if (this.object === 'tenants') {
      this.actions
        .pipe(takeUntil(this.destroy$), ofActionSuccessful(GetUsersListByTenant))
        .subscribe(() => {
          this.allUsers = this.store.select(UsersState.getTenantUsers);
          this.ref.detectChanges();
        });
      this.store.dispatch(new GetUsersListByTenant());
    }
  }

  ngOnDestroy(): void {
    this.rolesSubs$.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  public get checkIsPersonal() {
    return this.object === 'spaces' ? this.space && !this.space.isPersonal : true;
  }

  /**
   * Invite users button click handler (emit open modal action)
   */
  handlerOpenInviteModal() {
    MixpanelService.trackEvent('Users list: Invite Users Button Clicked');
    const modalRef = this.modal.open(InviteToSpaceModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.object = this.object;
    modalRef.componentInstance.objectId = this.objectId;
    modalRef.componentInstance.newUsersOnly = this.object === 'tenants';
  }

  handlerOpenUserViewModal(user) {
    MixpanelService.trackEvent('Users list: View User Button Clicked');
    const modalRef = this.modal.open(UserViewComponent, {
      size: 'lg',
    });
    modalRef.componentInstance._id = user._id;
    modalRef.componentInstance.isMyself = user._id === this.userId;
  }

  filterUserRoles(roles, roleName) {
    return roles.filter((item) => item.roleName !== roleName);
  }

  isAssistant(user) {
    return this.store
      .selectSnapshot(UsersState.getTenantUsers)
      .some((userTenant) => userTenant._id === user?._id && userTenant.isAssistant);
  }

  changeUserRole(userId, roleName) {
    MixpanelService.trackEvent('Users list: Change User Role Button Clicked');
    ConfirmAlert(null, {
      subject: `${this.translocoService.translate('alert.change-role-subject')} "${roleName}"`,
      text: '',
      confirmButtonText: this.translocoService.translate('alert.btn-change-role'),
      platform: this.platform,
    }).then(
      () => {
        this.store
          .dispatch(
            new ChangeUserRole({
              object: this.object,
              objectId: this.objectId,
              userId: userId,
              roleName: roleName,
            }),
          )
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            () =>
              this.toastr.success(
                this.translocoService.translate('toastr.user-role-successfully-changed'),
              ),
            (err) =>
              this.toastr.error(err.message, this.translocoService.translate('toastr.title-error')),
          );
      },
      () => {},
    );
  }

  unAssignUser(user) {
    MixpanelService.trackEvent('Users list: Unassign User Button Clicked');
    ConfirmAlert(null, {
      platform: this.platform,
      subject:
        this.object === 'spaces'
          ? this.translocoService.translate('alert.delete-user-space', {
              value: `"${user.userName}"`,
            })
          : this.translocoService.translate('alert.delete-user-project', {
              value: `"${user.userName}"`,
            }),
      text: this.translocoService.translate('alert.default-text'),
      confirmButtonText: this.translocoService.translate('common.yes'),
      cancelButtonText: this.translocoService.translate('common.no'),
    }).then(
      () => {
        switch (this.object) {
          case 'spaces':
            this.store
              .dispatch(
                user.status === 'Active'
                  ? new SpaceUnassignMember({
                      userId: user._id,
                      spaceId: this.objectId,
                      status: user.status,
                    })
                  : new SpaceRevokeInvite({
                      ...(user.status === 'Pending' ? { userId: user._id } : { email: user.email }),
                      spaceId: this.objectId,
                      status: user.status,
                    }),
              )
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (data) => {
                  this.toastr.success(
                    this.translocoService.translate('toastr.user-removed-from-current-space'),
                  );
                  this.closeOnLeave(user);
                },
                (err) =>
                  this.toastr.error(
                    err.message,
                    this.translocoService.translate('toastr.title-error'),
                  ),
              );
            break;

          case 'projects':
            this.store
              .dispatch(
                user.status === 'Active'
                  ? new ProjectsUnassignMember({
                      userId: user._id,
                      projectId: this.objectId,
                      status: user.status,
                    })
                  : new ProjectsRevokeInvite({
                      ...(user.status === 'Pending' ? { userId: user._id } : { email: user.email }),
                      projectId: this.objectId,
                      status: user.status,
                    }),
              )
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (data) => {
                  this.toastr.success(
                    this.translocoService.translate('toastr.user-removed-from-current-project'),
                  );
                  this.closeOnLeave(user);
                },
                (err) =>
                  this.toastr.error(
                    err.message,
                    this.translocoService.translate('toastr.title-error'),
                  ),
              );
            break;

          default:
            break;
        }
      },
      () => {},
    );
  }

  trackByRoleId(index, item) {
    return item._id;
  }

  closeOnLeave(user: { _id: string }): void {
    MixpanelService.trackEvent('Users list: Close on Leave');
    if (this.currentUser?._id === user._id) {
      this.close.emit('saved');
    }
  }
}
