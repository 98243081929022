import { ChangeDetectionStrategy, Component } from '@angular/core';

import { GenericMessageComponent } from '../generic-message/generic-message.component';
import { VideoPlayerComponent } from '../../../video-player/video-player.component';
import { CustomSpinnerComponent } from '../../../custom-spinner/custom-spinner.component';
import { NgIf } from '@angular/common';
import { TextMessageComponent } from '../text-message/text-message.component';

@Component({
  selector: 'app-video-message',
  templateUrl: './video-message.component.html',
  styleUrls: ['./video-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TextMessageComponent, NgIf, CustomSpinnerComponent, VideoPlayerComponent],
})
export class VideoMessageComponent extends GenericMessageComponent {}
