{
  "name": "teamplate",
  "version": "1.28.0",
  "license": "",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build:dev": "ng build --configuration=development",
    "build:stage": "ng build --configuration=staging && yarn sentry:sourcemaps ",
    "build:prod": "ng build --configuration=production && yarn sentry:sourcemaps && yarn sentry:sourcemaps && yarn remove-maps",
    "build:desktop:local": "ng build --configuration=desktop-local --output-path ./desktop/dist ",
    "build:desktop:dev": "ng build --configuration=desktop-dev --output-path ./desktop/dist ",
    "build:desktop:stage": "ng build --configuration=desktop-stage --output-path ./desktop/dist ",
    "build:desktop:prod": "ng build --configuration=desktop-prod --output-path ./desktop/dist ",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "build:format:start": "yarn run generate-api:dev & yarn run format & yarn run start",
    "generate-api:dev": "ng-openapi-gen -i http://127.0.0.1:8000/api-json",
    "format": "prettier --single-quote --write \"src/**/*.ts\"",
    "cypress:open": "yarn run cypress open",
    "cypress:run": "yarn run cypress run",
    "sentry-update": "sentry-cli releases files %npm_package_version% upload-sourcemaps dist --log-level=debug",
    "sentry-update-mobile": "sentry-cli releases files %npm_package_version% upload-sourcemaps mobile/dist/mobile --log-level=debug",
    "transloco:optimize": "transloco-optimize dist/appName/assets/i18n",
    "transloco:extract": "transloco-keys-manager extract --unflat --sort",
    "transloco:find": "transloco-keys-manager find",
    "prepare": "husky install",
    "fixall": "npx eslint --fix --ext .js,.jsx,.ts,.html ./src",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org ittlb-ltd --project teamplate ./dist && sentry-cli sourcemaps upload --org ittlb-ltd --project teamplate ./dist",
    "remove-maps": "./.build-scripts/remove-maps.sh"
  },
  "private": true,
  "dependencies": {
    "@4tw/cypress-drag-drop": "^2.2.3",
    "@achimha/angular-archwizard": "^14.0.1",
    "@angular/animations": "^17.2.1",
    "@angular/cdk": "^17.2.0",
    "@angular/common": "^17.2.1",
    "@angular/compiler": "^17.2.1",
    "@angular/core": "^17.2.1",
    "@angular/forms": "^17.2.1",
    "@angular/localize": "17.2.1",
    "@angular/material": "17.2.0",
    "@angular/platform-browser": "^17.2.1",
    "@angular/platform-browser-dynamic": "^17.2.1",
    "@angular/platform-server": "17.2.1",
    "@angular/router": "^17.2.1",
    "@capacitor/browser": "^6.0.0",
    "@capacitor/cli": "^6.0.0",
    "@capacitor/core": "^6.0.0",
    "@capacitor/keyboard": "^6.0.0",
    "@capawesome/capacitor-badge": "^5.0.0",
    "@ctrl/ngx-emoji-mart": "^9.2.0",
    "@meddv/ngx-pinch-zoom": "^17.0.1",
    "@ng-bootstrap/ng-bootstrap": "^16.0.0",
    "@ng-select/ng-select": "12.0.7",
    "@ngneat/transloco": "5.0.7",
    "@ngneat/transloco-messageformat": "^5.0.0",
    "@ngneat/transloco-persist-lang": "^5.0.0",
    "@ngneat/until-destroy": "^8.1.4",
    "@ngxs/devtools-plugin": "^3.7.3",
    "@ngxs/form-plugin": "^3.7.3",
    "@ngxs/logger-plugin": "^3.7.3",
    "@ngxs/router-plugin": "^3.7.3",
    "@ngxs/store": "^3.7.3",
    "@popperjs/core": "^2.11.8",
    "@rx-angular/cdk": "^17.0.0",
    "@rx-angular/template": "^17.0.1",
    "@sentry/angular-ivy": "^7.101.1",
    "@sentry/cli": "^2.32.1",
    "@sentry/tracing": "^7.101.1",
    "@tauri-apps/api": "^1.5.3",
    "@types/memoizee": "^0.4.11",
    "@types/mixpanel-browser": "^2.49.0",
    "@types/uuid": "^9.0.1",
    "angular-archwizard": "^7.0.0",
    "angular-calendar": "^0.31.0",
    "angular-code-input": "^2.0.0",
    "angular-mentions": "^1.5.0",
    "angular-ng-autocomplete": "^2.0.12",
    "angular-split": "^17.1.1",
    "angular-svg-icon": "^17.0.0",
    "autolinker": "^3.15.0",
    "bootstrap": "4.6.0",
    "chartist": "^1.3.0",
    "commonmark": "^0.30.0",
    "core-js": "3.6.4",
    "countries-list": "^2.6.1",
    "date-fns": "2.30.0",
    "deep-equal": "^2.2.1",
    "dexie": "^3.2.5",
    "dragula": "^3.7.3",
    "event-source-polyfill": "^1.0.31",
    "flag-icon-css": "^4.1.7",
    "howler": "^2.2.1",
    "libphonenumber-js": "^1.10.37",
    "memoizee": "^0.4.17",
    "mixpanel-browser": "^2.53.0",
    "moment": "2.29.4",
    "moment-timezone": "^0.5.40",
    "ng-chartist": "^8.1.2",
    "ng-click-outside": "^9.0.1",
    "ng2-dragula": "^5.0.1",
    "ng2-file-upload": "^5.0.0",
    "ng2-pdf-viewer": "^9.1.5",
    "ngx-cookie-service": "^10.0.1",
    "ngx-device-detector": "^6.0.2",
    "ngx-doc-viewer": "^15.0.1",
    "ngx-image-cropper": "^7.2.1",
    "ngx-infinite-scroll": "^17.0.0",
    "ngx-linky": "^4.0.0",
    "ngx-localstorage": "^5.0.0",
    "ngx-mask": "^17.0.4",
    "ngx-perfect-scrollbar": "^10.1.1",
    "ngx-quill": "^22.0.0",
    "ngx-scrollbar": "^13.0.3",
    "ngx-socket-io": "^4.5.1",
    "ngx-take-until-destroy": "5.4.0",
    "ngx-toastr": "^17.0.2",
    "ngx-videogular": "^13.2.4",
    "quill": "1.3.7",
    "quill-image-resize": "^3.0.9",
    "quill-magic-url": "4.1.4",
    "quill-placeholder-module": "^0.3.1",
    "quill-table": "^1.0.0",
    "quilljs-markdown": "^1.2.0",
    "recordrtc": "^5.6.2",
    "resize-observer-polyfill": "1.5.1",
    "rxjs": "~7.8.0",
    "slugify": "^1.4.6",
    "sweetalert2": "^11.4.17",
    "tailwind-scrollbar-hide": "^1.1.7",
    "tippy.js": "^6.3.7",
    "tslib": "^2.3.0",
    "uuid": "^9.0.0",
    "validator": "^13.12.0",
    "wavesurfer.js": "^6.2.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.2.0",
    "@angular-eslint/builder": "17.2.1",
    "@angular-eslint/eslint-plugin": "17.2.1",
    "@angular-eslint/eslint-plugin-template": "17.2.1",
    "@angular-eslint/schematics": "17.2.1",
    "@angular-eslint/template-parser": "17.2.1",
    "@angular/cli": "^17.2.0",
    "@angular/compiler-cli": "^17.2.1",
    "@ngneat/transloco-keys-manager": "^3.8.0",
    "@ngneat/transloco-optimize": "^5.0.3",
    "@percy/cypress": "^3.1.2",
    "@tailwindcss/typography": "^0.5.10",
    "@types/core-js": "2.5.3",
    "@types/jasmine": "~5.1.0",
    "@types/moment": "^2.13.0",
    "@types/node": "13.9.1",
    "@types/recordrtc": "^5.6.8",
    "@typescript-eslint/eslint-plugin": "6.19.0",
    "@typescript-eslint/parser": "6.19.0",
    "autoprefixer": "^10.4.17",
    "cypress": "^13.1.0",
    "cypress-file-upload": "^5.0.8",
    "cypress-real-events": "^1.5.1",
    "cz-conventional-changelog": "^3.3.0",
    "eslint": "^8.56.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.1.3",
    "husky": "^8.0.3",
    "jasmine-core": "~5.1.0",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "lint-staged": "^15.2.0",
    "ng-openapi-gen": "^0.21.2",
    "postcss": "^8.4.33",
    "prettier": "^3.2.5",
    "prettier-eslint": "^16.3.0",
    "protractor": "~7.0.0",
    "tailwindcss": "^3.4.1",
    "ts-node": "8.6.2",
    "typescript": "~5.3.3"
  }
}
