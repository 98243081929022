import { Injectable } from '@angular/core';
import { Action, Selector, State, Store, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-localstorage';

import { VideoCallsStateModel } from '../models/VideoCallsState';
import { VideoCallsService } from '../../../api/services/video-calls.service';
import {
  VideoCallChangeIsOpened,
  VideoCallSetLastSessionId,
  VideoCallSetLastSessions,
  VideoCallsGetInviteLink,
  VideoCallsSendAction,
  VideoCallsSetInvitedRoomId,
} from '../actions/video-calls.action';

@State<VideoCallsStateModel>({
  name: 'VideoCalls',
  defaults: {
    isOpened: false,
    lastSessionId: {},
    invitedRoomId: null,
    inviteLink: null,
  },
})
@Injectable()
export class VideoCallsState {
  /**
   * get isOpened
   * @param  {VideoCallsStateModel} state
   */
  @Selector()
  static getIsOpened(state: VideoCallsStateModel) {
    return state.isOpened;
  }

  /**
   * get lastSessionId
   * @param  {VideoCallsStateModel} state
   */
  @Selector()
  static getLastSessionId(state: VideoCallsStateModel) {
    return (roomId: string) => state.lastSessionId[roomId];
  }

  /**
   * get invited chat id
   * @param {VideoCallsStateModel} state
   */
  @Selector()
  static getInvitedRoomId(state: VideoCallsStateModel) {
    return state.invitedRoomId;
  }

  constructor(
    private store: Store,
    private videoCallsService: VideoCallsService,
    private localStorage: LocalStorageService,
  ) {}

  /**
   * Change video call isOpened handler
   * @param  stateContext: StateContext<VideoCallsStateModel>
   * @param  {VideoCallChangeIsOpened} action
   */
  @Action(VideoCallChangeIsOpened)
  change_video_call_is_opened(
    { patchState }: StateContext<VideoCallsStateModel>,
    action: VideoCallChangeIsOpened,
  ) {
    const isOpened = action.payload.isOpened;
    patchState({ isOpened });
  }

  /**
   * Set last call session ids handler
   * @param  stateContext: StateContext<VideoCallsStateModel>
   */
  @Action(VideoCallSetLastSessions)
  set_last_session_ids({ patchState }: StateContext<VideoCallsStateModel>) {
    const lastSessionId: any = this.localStorage.get('vcLastSessionId') || {};

    return patchState({ lastSessionId });
  }

  /**
   * Set last call session id handler
   * @param  stateContext: StateContext<VideoCallsStateModel>
   * @param  {VideoCallSetLastSessionId} action
   */
  @Action(VideoCallSetLastSessionId)
  set_last_session_id(
    { getState, patchState }: StateContext<VideoCallsStateModel>,
    action: VideoCallSetLastSessionId,
  ) {
    const { lastSessionId } = getState();
    const vcLastSessionId = {
      ...lastSessionId,
      [action.payload.videoCallId]: action.payload.sessionId,
    };

    this.localStorage.set('vcLastSessionId', vcLastSessionId);
    patchState({ lastSessionId: vcLastSessionId });
  }

  /**
   * Set last call session id handler
   * @param  stateContext: StateContext<VideoCallsStateModel>
   * @param  {VideoCallsSendAction} action
   */
  @Action(VideoCallsSendAction)
  send_action({ patchState }: StateContext<VideoCallsStateModel>, action: VideoCallsSendAction) {
    const body = action.payload;

    return this.videoCallsService.videoCallsSendAction({ body }).pipe(
      tap(
        (res) => {
          if (res.success) {
            this.store.dispatch(new VideoCallSetLastSessionId(body));
          }

          return res;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Set room id by video-call invitation
   * @param  {patchState}: StateContext<VideoCallsStateModel>
   * @param {VideoCallsSetInvitedRoomId} action
   */
  @Action(VideoCallsSetInvitedRoomId)
  set_invited_room_id(
    { patchState }: StateContext<VideoCallsStateModel>,
    action: VideoCallsSetInvitedRoomId,
  ) {
    patchState({
      invitedRoomId: action.payload,
    });
  }

  /**
   * Get invite link by video-call invitation
   * @param  {patchState}: StateContext<VideoCallsStateModel>
   * @param {VideoCallsGetInviteLink} action
   */
  @Action(VideoCallsGetInviteLink)
  get_invite_link(
    { patchState }: StateContext<VideoCallsStateModel>,
    { payload }: VideoCallsGetInviteLink,
  ) {
    return this.videoCallsService.videoCallsGetInviteLink({ id: payload }).pipe(
      tap(
        (res) => {
          if (res) {
            patchState({
              inviteLink: res,
            });
          }

          return res;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }
}
