<ng-container *transloco="let t; read: 'data-room'">
  <section id="documents">
    <ng-template #modalContent let-close="close">
      <div class="modal-header">
        <h5 class="modal-title">{{ t('upload-document') }}</h5>
      </div>

      <div class="modal-body">
        <!--File Upload Starts-->
        <div class="row">
          <div class="file-input col-md-12 mb-2">
            <div>
              <label class="hidden">
                <input
                  #fileInput
                  t-id="documents-uploader"
                  class="file-input__input"
                  type="file"
                  multiple
                  ng2FileSelect
                  [uploader]="uploader"
                />
              </label>
              <button
                class="file-input__button btn btn-light"
                type="button"
                [disabled]="uploader?.progress > 0"
                (click)="fileInput.click()"
              >
                {{ t('btn-choose-files') }}
              </button>

              <span class="mx-2">{{ t('or') }}</span>
              <label class="hidden">
                <input
                  #folderInput
                  class="file-input__input"
                  type="file"
                  multiple
                  webkitDirectory
                  directory
                  ng2FileSelect
                  [uploader]="uploader"
                />
              </label>
              <button
                class="file-input__button btn btn-light"
                type="button"
                [disabled]="uploader?.progress > 0"
                (click)="folderInput.click()"
              >
                {{ t('btn-choose-folder') }}
              </button>

              <button
                *ngIf="uploader?.queue.length > 0"
                class="btn btn-subtle pull-right"
                type="button"
                (click)="uploader?.clearQueue()"
              >
                {{ t('btn-remove-all') }}
              </button>
            </div>

            <div *ngIf="uploader.queue.length">
              <h6 class="my-1">{{ t('files-for-uploading') }}</h6>
              <ul>
                <li *ngFor="let item of uploader.queue">
                  {{ item.file.name }}
                </li>
              </ul>
            </div>
          </div>

          <div class="col-md-12" *ngIf="uploader?.progress > 0">
            <h6>{{ t('uploading-progress') }}</h6>
            <div class="mb-1" *ngIf="uploader?.progress <= 100">
              <ngb-progressbar type="primary" [value]="uploader?.progress"></ngb-progressbar>
            </div>
            <div *ngIf="uploader.queue.length">
              <div *ngFor="let item of uploader.queue">
                <p *ngIf="!item.isError">
                  <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                  {{ t('finishing-uploading') }}{{ item.file.name }}
                </p>
                <p *ngIf="item.isError">{{ t('error-uploading-files') }}{{ item.file.name }}</p>
              </div>
            </div>
          </div>
        </div>

        <app-access-control-documents
          *ngIf="currentSpace ? !currentSpace.isPersonal : true"
          [object]="object"
          [objectId]="objectId"
          [access]="docAccess"
          [invitedMembers]="invitedMembers"
          [invitedRoles]="invitedRoles"
          [documentType]="'file'"
          (accessChanged)="docAccessChanged($event)"
          (invitedMembersChanged)="invitedMembersChanged($event)"
          (invitedRolesChanged)="invitedRolesChanged($event)"
        ></app-access-control-documents>
        <!--File Upload Ends-->
      </div>

      <div class="modal-footer">
        <button
          t-id="documents-cancel-action"
          type="button"
          class="btn btn-subtle"
          (click)="close()"
        >
          {{ t('btn-cancel') }}
        </button>
        <button
          t-id="documents-upload-action-submit"
          type="button"
          class="btn btn-solid"
          (click)="uploadFileSubmit()"
          [disabled]="(isLoading$ | async) || !uploader?.queue[0] || uploader?.progress > 0"
        >
          {{ t('btn-upload') }}
        </button>
      </div>
    </ng-template>

    <ng-template #mediaViewModalContent let-close="close">
      <div class="modal-header">
        <h5 class="modal-title">{{ t('view-document') }}</h5>
        <button type="button" class="close" (click)="close()">
          <app-svg
            name="times"
            width="10"
            height="10"
            class="svg-icon"
            aria-hidden="true"
          ></app-svg>
        </button>
      </div>
      <div class="modal-body">
        <ng-container
          *ngIf="documentIS(modalDocument) === 'File' || documentIS(modalDocument) === 'Document'"
        >
          <div class="row" *ngIf="filesHelper.getFileType(modalDocument.fileName) === 'file'">
            <div class="col-md-12">
              <p>
                {{ modalDocument.originalFileName }}
              </p>
              <a href="{{ modalDocument.url }}" target="_blank">
                {{ t('download-file') }}
              </a>
            </div>
          </div>
          <div class="row" *ngIf="filesHelper.getFileType(modalDocument.fileName) === 'video'">
            <div class="col-md-12">
              <video width="760" controls>
                <source src="{{ modalDocument.url }}" />
              </video>
            </div>
          </div>
          <div class="row" *ngIf="filesHelper.getFileType(modalDocument.fileName) === 'music'">
            <div class="col-md-12 text-center">
              <audio controls>
                <source src="{{ modalDocument.url }}" />
              </audio>
            </div>
          </div>
        </ng-container>
        <div class="row" *ngIf="documentIS(modalDocument) === 'Link'">
          <div class="col-12">
            <iframe [src]="modalDocument.link | documentLink | safeUrl" class="link"></iframe>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #folderModalContent>
      <div class="modal-header">
        <h5 class="modal-title">{{ isFolderCreated }}</h5>
      </div>
      <div class="modal-body">
        <form action="#" class="form form-horizontal">
          <div class="form-body">
            <div class="form-group">
              <label>{{ t('form-folder-name') }}</label>
              <input
                t-id="documents-folder-name"
                class="form-control"
                type="text"
                name="folder-name"
                required
                [appFocus]="true"
                [(ngModel)]="folderModalData.name"
              />
            </div>
            <p *ngIf="isLoading$ | async">
              <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
              {{ t('creating-folder') }}
            </p>
            <app-access-control-documents
              *ngIf="currentSpace ? !currentSpace.isPersonal : true"
              [object]="folderModalData?.object"
              [objectId]="folderModalData?.objectId"
              [access]="docAccess"
              [invitedMembers]="folderModalData?.invitedMembers"
              [invitedRoles]="folderModalData?.invitedRoles"
              [documentType]="'folder'"
              (accessChanged)="docAccessChanged($event)"
              (invitedMembersChanged)="invitedMembersChanged($event)"
              (invitedRolesChanged)="invitedRolesChanged($event)"
            ></app-access-control-documents>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-subtle" (click)="closeFolderModal()">
          {{ t('btn-cancel') }}
        </button>
        <button
          *ngIf="folderModalData?.isAddFolder"
          t-id="documents-create-action"
          type="button"
          class="btn btn-solid"
          [disabled]="(isLoading$ | async) || !folderModalData?.name"
          (click)="folderCreateSubmit()"
        >
          {{ t('btn-create') }}
        </button>
        <button
          *ngIf="!folderModalData?.isAddFolder"
          type="button"
          class="btn btn-solid"
          [disabled]="(isLoading$ | async) || !folderModalData?.name"
          (click)="folderEditSubmit()"
        >
          {{ t('btn-save') }}
        </button>
      </div>
    </ng-template>

    <ng-template #AccessControlModal>
      <div class="modal-header">
        <h5 class="modal-title">{{ t('access-control') }}</h5>
        <button type="button" class="close" (click)="closeAccessControlModal()">
          <app-svg
            name="times"
            width="10"
            height="10"
            class="svg-icon"
            aria-hidden="true"
          ></app-svg>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex flex-row mt-1 mb-3 access-control">
          <div class="mr-2">
            <i
              *ngIf="
                documentIS(modalDocument) === 'File' || documentIS(modalDocument) === 'Document'
              "
              class="ft-{{ filesHelper.getFileType(modalDocument.fileName) }}"
            ></i>
            <i *ngIf="documentIS(modalDocument) === 'Folder'" class="ft-folder"></i>
            <i *ngIf="documentIS(modalDocument) === 'Link'" class="ft-link"></i>
          </div>

          <div>
            <div
              *ngIf="documentIS(modalDocument) === 'Folder' || documentIS(modalDocument) === 'Link'"
            >
              {{ modalDocument.name }}
            </div>
            <div
              *ngIf="
                documentIS(modalDocument) === 'File' || documentIS(modalDocument) === 'Document'
              "
            >
              {{ modalDocument.originalFileName }}
            </div>
          </div>
        </div>

        <app-access-control-documents
          *ngIf="currentSpace ? !currentSpace.isPersonal : true"
          [document]="modalDocument"
          [object]="object"
          [objectId]="objectId"
          [access]="docAccess"
          [invitedMembers]="invitedMembers"
          [invitedRoles]="invitedRoles"
          [documentType]="getDocumentType(documentIS(modalDocument))"
          (accessChanged)="docAccessChanged($event)"
          (invitedMembersChanged)="invitedMembersChanged($event)"
          (invitedRolesChanged)="invitedRolesChanged($event)"
        ></app-access-control-documents>
      </div>
      <div class="modal-footer">
        <button
          t-id="documents-close-action"
          type="button"
          class="btn btn-subtle"
          (click)="closeAccessControlModal()"
        >
          {{ t('btn-close') }}
        </button>
        <button
          t-id="documents-save-action"
          type="button"
          class="btn btn-solid"
          (click)="updateDocPermissions()"
        >
          {{ t('btn-save') }}
        </button>
      </div>
    </ng-template>

    <ng-template #linkModalContent let-close="close">
      <div class="modal-header-v2">
        <h5 class="modal-title">
          {{
            t('add-edit-link', {
              value: linkModalData.isAddLink ? t('btn-add-text') : t('btn-edit')
            })
          }}
        </h5>
      </div>
      <div class="modal-body-v2">
        <form action="#" class="form form-horizontal">
          <div class="form-body">
            <div class="form-group">
              <label>{{ t('form-name') }}</label>
              <input
                type="text"
                name="link-name"
                class="form-control"
                [(ngModel)]="linkModalData.name"
                [appFocus]="true"
                t-id="documents-link-name-input"
              />
            </div>

            <div class="form-group">
              <label>{{ t('form-url') }}</label>
              <input
                type="text"
                name="link-url"
                required
                class="form-control"
                [(ngModel)]="linkModalData.link"
                t-id="documents-link-url-input"
              />
            </div>

            <div class="d-flex mb-2">
              <label for="switchLinkIsEmbedded">{{ t('form-not-in-frame') }}</label>
              <div class="custom-control custom-switch ml-1" t-id="documents-in-frame-switch">
                <input
                  type="checkbox"
                  name="in-new-window"
                  id="switchLinkIsEmbedded"
                  class="custom-control-input"
                  [checked]="linkModalData.inNewWindow"
                  [(ngModel)]="linkModalData.inNewWindow"
                />
                <label class="custom-control-label" for="switchLinkIsEmbedded"></label>
              </div>
            </div>

            <p *ngIf="isLoading$ | async">
              <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
              {{ t('adding-link') }}
            </p>
            <app-access-control-documents
              *ngIf="currentSpace ? !currentSpace.isPersonal : true"
              [object]="linkModalData.object"
              [objectId]="linkModalData.objectId"
              [access]="docAccess"
              [invitedMembers]="linkModalData.invitedMembers"
              [invitedRoles]="linkModalData.invitedRoles"
              [documentType]="'link'"
              (accessChanged)="docAccessChanged($event)"
              (invitedMembersChanged)="invitedMembersChanged($event)"
              (invitedRolesChanged)="invitedRolesChanged($event)"
            ></app-access-control-documents>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-subtle" (click)="closeLinkModal()">
          {{ t('btn-cancel') }}
        </button>
        <button
          *ngIf="linkModalData.isAddLink"
          type="button"
          class="btn btn-solid"
          (click)="addLinkSubmit()"
          [disabled]="(isLoading$ | async) || !linkModalData.link || !linkModalData.name"
          t-id="documents-add-link-action"
        >
          {{ t('btn-add') }}
        </button>
        <button
          *ngIf="!linkModalData.isAddLink"
          type="button"
          class="btn btn-solid"
          (click)="editLinkSubmit()"
          [disabled]="(isLoading$ | async) || !linkModalData.link || !linkModalData.name"
        >
          {{ t('btn-save') }}
        </button>
      </div>
    </ng-template>

    <div class="card m-0">
      <div class="card-content">
        <div class="card-body d-flex p-0" [class.py-0]="platform === 'web'">
          <as-split *ngIf="platform === 'web'" (dragEnd)="onResizeSplitter($event)">
            <div
              class="folders-tree"
              as-split-area
              minSize="15"
              order="1"
              [size]="getSectionSize(0) || 25"
            >
              <ng-scrollbar
                class="scroll-event scrollable-container"
                track="all"
                thumbClass="white-scrollbars"
              >
                <div
                  class="tree-item d-flex align-items-center"
                  [class.active]="currentFolder?.parentId === treeType.projectFiles"
                >
                  <i
                    class="mr-10"
                    [ngClass]="{
                      'ft-chevron-right': isCollapsed.projectFiles,
                      'ft-chevron-down': !isCollapsed.projectFiles
                    }"
                    (click)="isCollapsed.projectFiles = !isCollapsed.projectFiles"
                  ></i>
                  <span
                    class="d-flex align-items-center flex-grow-1"
                    (click)="updateCurrentFolder(treeType.projectFiles)"
                  >
                    <app-svg name="folder-open-2" width="20" height="20"></app-svg>
                    {{ treeType.projectFiles }}
                  </span>
                </div>

                <div class="ml-2" [class.d-none]="isCollapsed.projectFiles">
                  <div
                    class="tree-item ml-3 tree-item-nested d-flex align-items-center"
                    [class.active]="currentFolder?.parentId === treeType.chat"
                  >
                    <i
                      class="mr-10"
                      [ngClass]="{
                        'ft-chevron-right': isCollapsed.chat,
                        'ft-chevron-down': !isCollapsed.chat
                      }"
                      (click)="isCollapsed.chat = !isCollapsed.chat"
                    ></i>
                    <span
                      class="d-flex align-items-center flex-grow-1"
                      (click)="updateCurrentFolder(treeType.chat)"
                    >
                      <app-svg name="folder" width="20" height="20" class="svg-wrapper"></app-svg>
                      {{ treeType.chat }}
                    </span>
                  </div>

                  <div
                    class="tree-item ml-3 tree-item-nested d-flex align-items-center"
                    [class.active]="isBoardFolder()"
                  >
                    <i
                      class="mr-10"
                      [ngClass]="{
                        'ft-chevron-right': isCollapsed.board,
                        'ft-chevron-down': !isCollapsed.board
                      }"
                      (click)="isCollapsed.board = !isCollapsed.board"
                    ></i>
                    <span
                      class="d-flex align-items-center flex-grow-1"
                      (click)="updateCurrentFolder(treeType.board)"
                    >
                      <app-svg name="folder" width="20" height="20" class="svg-wrapper"></app-svg>
                      {{ treeType.board }}
                    </span>
                  </div>

                  <div
                    class="position-relative ml-3 tree-item-nested overflow-hidden"
                    [ngStyle]="{ height: isCollapsed.board ? 0 : 'auto' }"
                  >
                    <ng-container
                      [ngTemplateOutlet]="foldersTreeNodeTemplate"
                      [ngTemplateOutletContext]="{
                        $implicit: boardFolders?.get('root'),
                        type: treeType.board
                      }"
                    ></ng-container>
                  </div>

                  <div
                    class="tree-item ml-3 tree-item-nested d-flex align-items-center"
                    [class.active]="isLaneBoardFolder()"
                    t-id="documents-lane-board-folder"
                  >
                    <i
                      class="mr-10"
                      [ngClass]="{
                        'ft-chevron-right': isCollapsed.laneBoard,
                        'ft-chevron-down': !isCollapsed.laneBoard
                      }"
                      (click)="isCollapsed.laneBoard = !isCollapsed.laneBoard"
                    ></i>
                    <span
                      class="d-flex align-items-center flex-grow-1"
                      (click)="updateCurrentFolder(treeType.laneBoard)"
                    >
                      <app-svg name="folder" width="20" height="20" class="svg-wrapper"></app-svg>
                      {{ treeType.laneBoard }}
                    </span>
                  </div>
                </div>

                <div
                  class="tree-item d-flex align-items-center"
                  [class.active]="currentFolder?.parentId === treeType.storage"
                >
                  <i
                    class="mr-10"
                    [ngClass]="{
                      'ft-chevron-right': isCollapsed.storage,
                      'ft-chevron-down': !isCollapsed.storage
                    }"
                    (click)="isCollapsed.storage = !isCollapsed.storage"
                  ></i>
                  <span
                    class="d-flex align-items-center flex-grow-1"
                    (click)="updateCurrentFolder(treeType.storage)"
                  >
                    <app-svg name="folder-open-2" width="20" height="20"></app-svg>
                    {{ t('storage-folder') }}
                  </span>
                </div>
                <div
                  class="position-relative overflow-hidden ml-3"
                  [class.d-none]="isCollapsed.storage"
                >
                  <ng-container
                    [ngTemplateOutlet]="foldersTreeNodeTemplate"
                    [ngTemplateOutletContext]="{
                      $implicit: foldersTree?.get('root'),
                      type: treeType.storage
                    }"
                  ></ng-container>
                </div>
              </ng-scrollbar>
            </div>

            <!-- folders tree template -->
            <ng-template #foldersTreeNodeTemplate let-nodes let-type="type">
              <ul *ngIf="nodes?.length > 0" class="list-unstyled tree ml-2">
                <li *ngFor="let node of nodes; trackBy: trackById" class="tree__folder">
                  <div
                    class="cursor-pointer tree__name"
                    [class.open]="openFolders.has(node._id)"
                    [class.active]="node._id === currentFolder?._id"
                    (click)="toggleFolderOpen(node._id, type)"
                    #element
                    (dragover)="onDragOver($event, element)"
                    (dragenter)="onDragEnter(element)"
                    (dragleave)="onDragLeave(element)"
                    (drop)="onDrop($event, node)"
                  >
                    <app-svg
                      [class.ml-neg-1]="getFolderIcon(node) === 'folder-private'"
                      [name]="getFolderIcon(node)"
                      class="folder-icon"
                      [width]="setSize(getFolderIcon(node))"
                      [height]="setSize(getFolderIcon(node))"
                    ></app-svg>
                    <span [class.ml-10]="getFolderIcon(node) === 'folder-private'">{{
                      node.name
                    }}</span>
                  </div>

                  <ng-container
                    *ngIf="type === treeType.storage && openFolders.has(node._id)"
                    [ngTemplateOutlet]="foldersTreeNodeTemplate"
                    [ngTemplateOutletContext]="{
                      $implicit: foldersTree?.get(node._id),
                      type: type
                    }"
                  ></ng-container>
                </li>
              </ul>
            </ng-template>

            <div
              class="documents-table d-flex flex-column flex-grow-1"
              as-split-area
              minSize="15"
              order="2"
              [size]="getSectionSize(1) || 75"
              #element
              ng2FileDrop
              [uploader]="uploader"
              (fileOver)="fileOver($event, element)"
              (drop)="filesDropped($event)"
            >
              <app-data-room-header
                [object]="object"
                [objectId]="objectId"
                [currentFolder]="currentFolder"
                [documentsIsLoaded]="documentsIsLoaded"
                [breadcrumbs]="breadcrumbs"
                [isListView]="isListView"
                [documents]="documents"
                [sortByList]="sortByList"
                [sortBy]="sortBy"
                [sortDirection]="sortDirection"
                [currRootFolder]="currRootFolder"
                (breadcrumbClicked)="breadcrumbClicked($event)"
                (onSortChange)="onSortChange($event)"
                (searchAction)="searchAction($event)"
                (uploadClicked)="uploadClicked()"
                (changeViewData)="changeViewData()"
                (enableSearch)="enableSearch()"
                (folderCreate)="folderCreateClicked()"
                (documentCreate)="addDocumentClicked()"
                (linkCreate)="addLinkClicked()"
              ></app-data-room-header>

              <div class="document-search" *ngIf="searchValue">
                {{ t('search-results') }}
              </div>

              <ng-container *ngIf="!searchValue">
                <div
                  *ngIf="documentsIsLoaded && documents.length === 0 && !isDataRoomEmpty"
                  class="empty"
                >
                  <span *ngIf="currRootFolder === treeType.storage">
                    {{ t('folder-is-mt-1') }}
                  </span>
                  <span *ngIf="currRootFolder === treeType.board">
                    {{ t('folder-is-mt-2') }}
                  </span>
                </div>
                <div *ngIf="isDataRoomEmpty && currRootFolder === treeType.storage" class="empty">
                  {{ t('dataroom-is-mt') }}
                </div>
                <div *ngIf="isDataRoomEmpty && currRootFolder === treeType.chat" class="empty">
                  {{ t('no-files') }}
                </div>
                <div *ngIf="isDataRoomEmpty && currRootFolder === treeType.board" class="empty">
                  {{ t('no-tickets') }}
                </div>
                <div *ngIf="isDataRoomEmpty && currRootFolder === treeType.laneBoard" class="empty">
                  {{ t('no-tickets') }}
                </div>
              </ng-container>

              <div class="empty-search" *ngIf="searchValue && !documents?.length">
                <svg-icon
                  src="assets/icons/data-room/empty-search.svg"
                  [applyClass]="true"
                ></svg-icon>
                <p>{{ t('no-results') }}</p>
              </div>

              <ng-scrollbar
                *ngIf="documents?.length > 0"
                class="scroll-event scrollable-container"
                track="all"
                thumbClass="white-scrollbars"
                pointerEventsMethod="scrollbar"
              >
                <app-data-room-grid
                  *ngIf="!isListView"
                  [documents]="documents"
                  [object]="object"
                  [objectId]="objectId"
                  [platform]="platform"
                  [currRootFolder]="currRootFolder"
                  (viewClicked)="viewClicked($event)"
                  (removeFile)="deleteClicked($event)"
                ></app-data-room-grid>
                <table *ngIf="isListView" class="table data-room-table m-0 h-100">
                  <thead>
                    <tr class="head-color">
                      <th
                        (click)="
                          onSortChange({
                            sortBy: paginationDummyService.dataRoomSortBy().Name,
                            direction: sortDirection
                          })
                        "
                        class="cursor-pointer"
                      >
                        <app-svg name="sort-2" [width]="12" [height]="12"></app-svg>
                        {{ t('thead-name') }}
                      </th>
                      <th
                        (click)="
                          onSortChange({
                            sortBy: paginationDummyService.dataRoomSortBy().Updated_At,
                            direction: sortDirection
                          })
                        "
                        class="cursor-pointer"
                      >
                        <app-svg name="sort-2" [width]="12" [height]="12"></app-svg>
                        {{ t('thead-modified') }}
                      </th>
                      <th
                        (click)="
                          onSortChange({
                            sortBy: paginationDummyService.dataRoomSortBy().Created_By,
                            direction: sortDirection
                          })
                        "
                        class="cursor-pointer"
                      >
                        <app-svg name="sort-2" [width]="12" [height]="12"></app-svg>
                        {{ t('thead-created-by') }}
                      </th>
                      <th
                        *ngIf="
                          (object + '::' + objectId + '::documentPermissionsGet'
                            | checkPermission) &&
                          !isInAdditionalFolder() &&
                          !isLaneBoardFolder()
                        "
                      >
                        {{ t('thead-access') }}
                      </th>
                      <th *ngIf="!isLaneBoardFolder()">
                        {{ t('thead-actions') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let document of documents; trackBy: documentId">
                      <tr
                        *ngIf="documentIS(document) === 'Folder'"
                        #folder
                        draggable="true"
                        (dragstart)="onDragStart($event, document)"
                        (dragover)="onDragOver($event, folder)"
                        (dragenter)="onDragEnter(folder)"
                        (dragleave)="onDragLeave(folder)"
                        (drop)="onDrop($event, document)"
                      >
                        <ng-template
                          [ngTemplateOutlet]="row"
                          [ngTemplateOutletContext]="{
                            document: document,
                            users: users
                          }"
                        ></ng-template>
                      </tr>
                      <tr
                        *ngIf="
                          documentIS(document) === 'File' ||
                          documentIS(document) === 'Link' ||
                          documentIS(document) === 'Document'
                        "
                        draggable="true"
                        (dragstart)="onDragStart($event, document)"
                      >
                        <ng-template
                          [ngTemplateOutlet]="row"
                          [ngTemplateOutletContext]="{
                            document: document,
                            users: users
                          }"
                        ></ng-template>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
                <div class="spinner-wrapper" *ngIf="paginationDummyService.isLoading$ | async">
                  <i
                    class="fa fa-spinner fa-spin connection_spinner font-large-1"
                    aria-hidden="true"
                  ></i>
                </div>
                <div class="{{ paginationDummyService.getClasName() }}"></div>
              </ng-scrollbar>
            </div>
          </as-split>
        </div>
      </div>
    </div>
  </section>

  <ng-template #row let-document="document" let-users="users">
    <td>
      <a
        (click)="!isLaneBoardFolder() && viewClicked(document)"
        (dblclick)="isLaneBoardFolder() && viewClicked(document)"
        class="position-relative"
        t-id="documents-item-list"
      >
        <span
          *ngIf="documentIS(document) === 'Folder'"
          class="d-flex align-items-center data-room-data"
        >
          <app-svg
            [class.ml-neg-3]="getFolderIcon(document, 'folder') === 'folder-private'"
            [name]="getFolderIcon(document, 'folder')"
            [width]="getFolderIcon(document, 'folder') === 'folder-private' ? 25 : 20"
            [height]="getFolderIcon(document, 'folder') === 'folder-private' ? 25 : 20"
          ></app-svg>
          <span
            [class]="getFolderIcon(document, 'folder') === 'folder-private' ? 'ml-7' : 'ml-10'"
            >{{ document.name }}</span
          >
        </span>

        <span
          *ngIf="documentIS(document) === 'File' || documentIS(document) === 'Document'"
          class="d-flex align-items-center data-room-data"
        >
          <svg-icon [src]="getIconFile(document)" [applyClass]="true"></svg-icon>
          <span class="ml-10">{{ document.originalFileName || document.name }}</span>
        </span>

        <span
          *ngIf="documentIS(document) === 'Link'"
          class="d-flex align-items-center data-room-data"
        >
          <svg-icon src="assets/icons/data-room/link.svg" [applyClass]="true"></svg-icon>
          <span class="ml-10">{{ document.name }}</span>
        </span>
      </a>
    </td>

    <td class="font-light">{{ document.created_at | fromNow: lang }}</td>
    <td class="font-light">
      <div class="d-flex align-items-center">
        <div class="cell-line-height ml-2">
          <span class="line-height-1 mb-0">{{ users[document.ownerUserId]?.userName }}</span>
        </div>
      </div>
    </td>
    <td
      *ngIf="
        (object + '::' + objectId + '::documentPermissionsGet' | checkPermission) &&
        !isChatFolder() &&
        !isInAdditionalFolder() &&
        !isLaneBoardFolder()
      "
      t-id="documents-change-access-control"
      class="item-center font-light"
    >
      <a
        target="_blank"
        (click)="user._id === document.ownerUserId ? openAccessModal(document) : null"
      >
        <span class="d-flex align-items-center" *ngIf="isLimitedAccess(document)">
          <app-svg name="lock" class="mr-1"></app-svg> Private
        </span>
        <span class="d-flex align-items-center" *ngIf="!isLimitedAccess(document)">
          <app-svg name="globe" class="mr-1"></app-svg> Public
        </span>
      </a>
    </td>
    <td *ngIf="!isLaneBoardFolder()">
      <div ngbDropdown class="d-inline-block" (click)="getMenuPlacement($event)">
        <app-svg
          name="ellipsis"
          t-id="documents-option-action"
          class="cursor-pointer"
          [width]="17"
          [height]="17"
          [hoverEffect]="true"
          ngbDropdownToggle
        ></app-svg>

        <div ngbDropdownMenu class="document-actions">
          <button
            *ngIf="!document.link && document.originalFileName"
            ngbDropdownItem
            (click)="download(document)"
          >
            <app-svg
              name="download"
              width="16"
              height="16"
              class="mr-2"
              aria-hidden="true"
            ></app-svg>
            <span>{{ t('btn-download') }}</span>
          </button>
          <button
            *ngIf="object !== 'tenants'"
            ngbDropdownItem
            (click)="dataRoomUtilies.copyLinkClicked(document)"
          >
            <app-svg name="copy" width="16" height="16" class="mr-2" aria-hidden="true"></app-svg>
            <span>{{ t('btn-copy-link') }}</span>
          </button>
          <button
            *ngIf="
              user._id === document.ownerUserId &&
              !document.content &&
              !document.originalFileName &&
              document.parentId !== treeType.board
            "
            ngbDropdownItem
            (click)="document.link ? editLinkClicked(document) : folderEditClicked(document)"
          >
            <app-svg name="pen" width="16" height="16" class="mr-2" aria-hidden="true"></app-svg>
            <span>{{ t('btn-edit') }}</span>
          </button>
          <button
            *ngIf="!document.link && document.originalFileName"
            ngbDropdownItem
            t-id="documents-file-edit-action"
            (click)="viewClicked(document)"
          >
            <app-svg
              name="{{ dataRoomUtilies.isPlayable(document.fileName) ? 'play' : 'eye' }}"
              width="16"
              height="16"
              class="mr-2"
              aria-hidden="true"
            ></app-svg>
            <span>{{
              dataRoomUtilies.isPlayable(document.fileName) ? t('btn-play') : t('btn-view')
            }}</span>
          </button>
          <button
            *ngIf="
              user._id === document.ownerUserId &&
              document.parentId !== treeType.chat &&
              document.parentId !== treeType.board
            "
            t-id="documents-delete-action"
            ngbDropdownItem
            (click)="deleteClicked(document)"
          >
            <app-svg name="trash" width="16" height="16" class="mr-2" aria-hidden="true"></app-svg>
            <span>{{ t('btn-remove') }}</span>
          </button>
        </div>
      </div>
    </td>
  </ng-template>
</ng-container>
