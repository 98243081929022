import { CalendarEventsCreateReqDto } from '../../../api/models';
import { Nullish } from '../../utils/types';

export class SaveAvailableUsers {
  static readonly type = '[CalendarEvents] Save available users';
  constructor(public payload: Partial<{ users: { id: string; name: string }[] }>) {}
}

/** Create calendar event **/
export class CalendarEventCreate {
  static readonly type = '[CalendarEvents] Create';
  constructor(public payload: CalendarEventsCreateReqDto) {}
}

export class GetTaskList {
  static readonly type = '[Boards] GetTaskList';
  constructor(
    public payload: {
      objectId: string;
      from: string;
      to: string;
    },
  ) {}
}

export class GetTask {
  static readonly type = '[Boards] GetTask';
  constructor(
    public payload: {
      objectId: string;
      from: string;
      to: string;
    },
  ) {}
}

export class GetTaskDetails {
  static readonly type = '[Boards] GetTaskDetails';
  constructor(public payload: { id: string | number }) {}
}

export class CreateTask {
  static readonly type = '[Boards] CreateTask';
  constructor(
    public payload: {
      start: string;
      end?: string;
      allDay: boolean;
      title?: string;
      object: string;
      objectId: string;
      description?: string;
      repeat: string;
      stopRepeatAt: string;
      onlyWorkingDays?: boolean;
    },
  ) {}
}

export class CreateExceptionTaskDay {
  static readonly type = '[Boards] CreateExceptionTaskDay';
  constructor(
    public payload: {
      id: string | number;
      start: string;
      end?: string;
      allDay: boolean;
      title?: string;
      object: string;
      objectId: string;
      description?: string;
      repeat: string;
      stopRepeatAt: string;
      onlyWorkingDays?: boolean;
      createNewTask?: boolean;
    },
  ) {}
}

export class UpdateTask {
  static readonly type = '[Boards] UpdateTask';
  constructor(
    public payload: {
      id: string | number;
      start: string;
      end?: string;
      allDay: boolean;
      title?: string;
      object: string;
      objectId: string;
      description?: string;
      repeat: string;
      stopRepeatAt: string;
      onlyWorkingDays?: boolean;
    },
  ) {}
}

export class DeleteTask {
  static readonly type = '[Boards] DeleteTask';
  constructor(
    public payload: {
      id: string | number;
      object: string;
      objectId: string;
    },
  ) {}
}

/** Create calendar event **/
export class CalendarTaskCreate {
  static readonly type = '[CalendarEvents] Create task';
  constructor(public payload: CalendarEventsCreateReqDto) {}
}

/** Get calendar events **/
export class CalendarEventsSet {
  static readonly type = '[CalendarEvents] Set list';
  constructor() {}
}

/** Get calendar events **/
export class CalendarEventsGet {
  static readonly type = '[CalendarEvents] Get events list';
  constructor(public payload) {}
}
/** Get calendar tickets **/
export class CalendarTicketsGet {
  static readonly type = '[CalendarEvents] Get tickets list';
  constructor(public payload) {}
}

/** Set calendar tickets **/
export class CalendarTicketsSet {
  static readonly type = '[CalendarTickets] Set list';
  constructor(public payload) {}
}

/** Get calendar single event **/
export class CalendarEventGet {
  static readonly type = '[CalendarEvents] Get single';
  constructor(public payload) {}
}

/** Get calendar event by id **/
export class CalendarEventsGetById {
  static readonly type = '[CalendarEvents] GetById';
  constructor(public payload) {}
}

/** updated calendar event status **/
export class CalendarEventsUpdateStatus {
  static readonly type = '[CalendarEvents] Update status';
  constructor(public payload) {}
}

/** Delete calendar event **/
export class CalendarEventDelete {
  static readonly type = '[CalendarEvents] Delete';
  constructor(public payload) {}
}

/** Get calendar user event **/
export class CalendarEventGetUserEvents {
  static readonly type = '[CalendarEvents] Get User Events';
  constructor(
    public payload: { userIds: string[]; day: string; excludeEventId?: Nullish<string | number> },
  ) {}
}

/** Remove calendar event by socket notification **/
export class CalendarEventRemove {
  static readonly type = '[CalendarEvents] Remove';
  constructor(public payload) {}
}

/** Remove calendar exception event by socket notification **/
export class CalendarEventExceptions {
  static readonly type = '[CalendarEvents] Remove exception';
  constructor(public payload) {}
}

/** Delete calendar single event from series **/
export class CalendarEventDeleteSingle {
  static readonly type = '[CalendarEvents] Delete Single Event';
  constructor(public payload) {}
}

/** Update calendar event **/
export class CalendarEventsUpdate {
  static readonly type = '[CalendarEvents] send update request';
  constructor(public payload) {}
}

/** Update calendar events data **/
export class CalendarEventsDataUpdate {
  static readonly type = '[CalendarEvents] Update events data by socket event';
  constructor(public payload) {}
}

/** Update calendar single event from  series **/
export class CalendarEventsUpdateSingle {
  static readonly type = '[CalendarEvents] Update Single';
  constructor(public payload) {}
}

export class CalendarEventsCheckedCalendarsUpdate {
  static readonly type = '[CalendarEvents] Update Checked Calendars';
  constructor(public payload) {}
}

export class CalendarEventsCheckedCalendarsGet {
  static readonly type = '[CalendarEvents] Get Checked Calendars';
  constructor(public payload) {}
}

export class CalendarEventsStopRemind {
  static readonly type = '[CalendarEvents] Stop Remind';
  constructor(public payload) {}
}
