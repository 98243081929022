// Common
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import {
  NgbActiveModal,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownButtonItem,
  NgbDropdownItem,
} from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, of } from 'rxjs';
import { LocalStorageService } from 'ngx-localstorage';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';

// Utilities
import { FilesHelper } from '../../../shared/utils/files-helper';

// Components
import { DataRoomUtiliesService } from '../../../shared/components/documents/services/data-room-utilies.service';
import { FilesService } from '../../../api/services/files.service';

// States
import { UsersState } from '../../../shared/store/states/users.state';
import { FullImagePreviewService } from '../../../shared/services/full-image-preview.service';
import { SpaceGetUsersList } from '../../../shared/store/actions/spaces.action';
import { ProjectGetUsersList } from '../../../shared/store/actions/projects.action';
import { ChatsState } from '../../../shared/store/states/chats.state';
import { ThreadGetMessages } from '../../../shared/store/actions/chats.action';
import { ThreadsSocketNewMessage } from '../../../shared/store/actions/threads.action';

// Types
import { UsersDbDto } from '../../../api/models/users-db-dto';
import { TreeType } from '../../../shared/components/documents/documents.component';
import { PinModalService } from '../../../shared/services/pin-modal.service';
import { DocumentGetBase64, DocumentsGet } from '../../../shared/store/actions/documents.action';
import { AuthState } from '../../../shared/store/states/auth.state';
import { PinType } from '../../../shared/components/chat/chat-pinned-messages/enums/pin-type.enum';
import { PdfData } from '../interface/document-modal.interface';
import { DocumentAccessTypes } from '../../../api/models/document-access-types';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { PlatformService } from '../../../../app/shared/services/platform.service';
import { DownloadService } from '../../../api/services/download.service';
import { environment } from '../../../../environments/environment';
import { DocumentLinkPipe } from '../../../shared/pipes/document-link.pipe';
import { SafeUrlPipe } from '../../../shared/pipes/safe-url.pipe';
import { ChatThreadComponent } from '../../../shared/components/chat/chat-thread/chat-thread.component';
import { SvgComponent } from '../../../shared/svgs/svg/svg.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { VideoPlayerComponent } from '../../../shared/components/video-player/video-player.component';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { SvgIconComponent } from 'angular-svg-icon';
import { AvatarComponent } from '../../../standalone/components/avatar/avatar.component';
import {
  NgIf,
  NgClass,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgStyle,
  AsyncPipe,
  DatePipe,
} from '@angular/common';

export enum MediaPreviewTypes {
  Image = 'image',
  Video = 'video',
  Music = 'music',
  Link = 'link',
  File = 'file',
  Pdf = 'pdf',
  Svg = 'svg',
  Mso = 'mso',
}

export interface PreviewData {
  platform: string;
  currentMedia: any;
  object: string;
  objectId: string;
  isMobile: boolean;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-preview-media',
  templateUrl: './preview-media.component.html',
  styleUrls: ['./preview-media.component.scss'],
  providers: [PinModalService],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgIf,
    forwardRef(() => AvatarComponent),
    SvgIconComponent,
    NgClass,
    NgSwitch,
    NgSwitchCase,
    PinchZoomModule,
    VideoPlayerComponent,
    PdfViewerModule,
    NgxDocViewerModule,
    NgSwitchDefault,
    SvgComponent,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    ChatThreadComponent,
    NgStyle,
    AsyncPipe,
    DatePipe,
    SafeUrlPipe,
    DocumentLinkPipe,
  ],
})
export class PreviewMediaComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() onMinimize = new EventEmitter<void>();
  @Output() onExpand = new EventEmitter<void>();

  public previewData: PreviewData;

  public userCreator: UsersDbDto;
  public timeZone = null;
  public currentMedia: any;
  public mentionThreadMembers: any[];
  public isDownloadingFile = false;
  public isNotDataRoom = false;
  public isInlineImage = false;
  public showButton = false;
  public isDirect = false;
  public pinnedType = PinType.ChatFile;
  public media: any;
  public platform: string;
  public isOpenThread = false;
  public userId: string;
  public chatId: string;
  public message = null;
  public isMobile = false;
  public platformOS: string;
  public object: string;
  public objectId: string;
  public isMinimize = false;
  public isVideoLoad = true;
  public modalId = null;
  public isPrivate = false;
  public isInPrivateFolder = false;

  public isLoading = false;
  public url: PdfData | string;
  public isEditWiki = false;

  private touchstartX = 0;
  private touchendX = 0;
  private isDoubleTouch = false;
  private removeFile: BehaviorSubject<any> = new BehaviorSubject(null);
  private isDestroyed: BehaviorSubject<any> = new BehaviorSubject(null);
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public readonly closeIcon = 'assets/icons/common/cross.svg';
  public readonly ellipsisIcon = 'assets/icons/common/ellipsis.svg';
  public readonly unpinIcon = 'assets/icons/pin/unpin-message.svg';
  public readonly pinIcon = 'assets/icons/pin/pin-modal.svg';
  public readonly rightArrowIcon = 'assets/icons/data-room/right-arrow.svg';
  public readonly leftArrowIcon = 'assets/icons/data-room/left-arrow.svg';
  public readonly plusIcon = 'assets/icons/data-room/plus.svg';
  public readonly minusIcon = 'assets/icons/data-room/minus.svg';
  public readonly rotateIcon = 'assets/icons/data-room/rotate-right.svg';
  public readonly downloadIcon = 'assets/icons/data-room/download.svg';
  public readonly threadIcon = 'assets/icons/data-room/comment-dots.svg';
  public readonly miniIcon = 'assets/icons/common/minimize.svg';
  public readonly expandIcon = 'assets/icons/common/expand.svg';
  public readonly apiUrl = environment.api_root;

  @HostListener('document:touchstart', ['$event'])
  onTouchStart(event) {
    this.touchstartX = event.changedTouches[0].screenX;
    this.isDoubleTouch = event.touches.length > 1;
  }

  @HostListener('document:touchend', ['$event'])
  onTouchEnd(event) {
    this.touchendX = event.changedTouches[0].screenX;

    if (!this.isDoubleTouch) {
      this.handleSwipe();
    }
  }

  constructor(
    public filesHelper: FilesHelper,
    public fullImagePreviewService: FullImagePreviewService,
    public dataRoomUtilies: DataRoomUtiliesService,
    public pinModalService: PinModalService,
    private store: Store,
    private activeModal: NgbActiveModal,
    private fileService: FilesService,
    private downloadService: DownloadService,
    private router: Router,
    private actions: Actions,
    private localStorageService: LocalStorageService,
    private ref: ChangeDetectorRef,
    private translocoService: TranslocoService,
    private platformService: PlatformService,
  ) {}

  ngOnInit(): void {
    console.log('test');
    this.initiliazeSubscriber();
    this.modalId = uuidv4();
    this.fullImagePreviewService.initInModal(this.modalId);
  }

  ngOnDestroy(): void {
    this.fullImagePreviewService.onResetOptions(true);
    if (
      this.message &&
      (!this.currentMedia.chatMessage || !this.currentMedia.chatMessage.threadId) &&
      !this.isNotDataRoom
    ) {
      this.isDestroyed.next(true);
      this.store.dispatch(
        new DocumentsGet({
          _id: this.currentMedia.folderId,
          object: this.object,
          objectId: this.objectId,
        }),
      );
    }
  }

  ngAfterViewInit() {
    this.isOpenThread = false;
  }

  @HostListener('wheel', ['$event'])
  onScroll(event: WheelEvent) {
    this.fullImagePreviewService.setZoomState(event);
  }

  // Getters
  public get nameFile(): string {
    return this.currentMedia.link || this.currentMedia.openInFrame
      ? 'Link'
      : this.currentMedia.originalFileName ||
          this.filesHelper.getFileNameWithoutExtension(this.currentMedia?.fileName) ||
          'image.jpg';
  }

  public get isLastMedia(): boolean {
    return (
      !this.media ||
      this.media.some(
        (document) =>
          ((document._id && document._id === this.currentMedia._id) ||
            document.url === this.currentMedia.url) &&
          document?.isLastMedia,
      )
    );
  }

  public get showButtons(): boolean {
    return this.isMobile && this.showButton;
  }

  public get isFirstMedia(): boolean {
    return (
      !this.media ||
      this.media.some(
        (document) =>
          ((document._id && document._id === this.currentMedia._id) ||
            document.url === this.currentMedia.url) &&
          document?.isFirstMedia,
      )
    );
  }

  public get ownerId(): string {
    return this.currentMedia?.ownerUserId;
  }

  public get userOwnerName(): string {
    return this.userCreator?.userName || 'unknown';
  }

  public get createdAt(): string {
    return this.currentMedia.created_at;
  }

  public get typeMedia(): string {
    return this.currentMedia.link || this.currentMedia.openInFrame
      ? this.isInlineImage
        ? MediaPreviewTypes.Image
        : MediaPreviewTypes.Link
      : this.filesHelper.getFileType(
          this.currentMedia.fileName || this.currentMedia.originalFileName,
        );
  }

  public get textForActionPin(): string {
    return !this.pinModalService.pinnedMedia
      ? this.translocoService.translate('modals.preview-media.pin-in-chat')
      : this.translocoService.translate('modals.preview-media.unpin-in-chat');
  }

  public get isPossiblePin(): boolean {
    return (
      (!this.pinModalService.pinnedMedia && this.pinModalService.isLimitPins) ||
      (this.pinModalService.pinnedMedia && this.pinModalService.unPinMessageAccess)
    );
  }

  public get isLink(): boolean {
    return this.typeMedia === MediaPreviewTypes.Link;
  }

  public get isOpenThreadMobile(): boolean {
    return (this.isMobile && !this.isOpenThread) || !this.isMobile;
  }

  public get isImage(): boolean {
    return this.typeMedia === MediaPreviewTypes.Image;
  }

  public get MediaPreviewType(): typeof MediaPreviewTypes {
    return MediaPreviewTypes;
  }

  private get MediaTypePin(): PinType {
    if (!this.isNotDataRoom && this.pinnedType !== PinType.Links) {
      return PinType.Files;
    } else if (this.pinnedType === PinType.ThreadsFile && this.currentMedia.chatId) {
      return PinType.ChatFile;
    }

    return this.pinnedType;
  }

  private get currentMediaIndex(): number {
    return this.media.findIndex(
      (el) => (el._id && el._id === this.currentMedia._id) || el.url === this.currentMedia.url,
    );
  }

  // Methods
  public closeModal(): void {
    this.activeModal.close();
  }

  public nextMedia(): void {
    const index: number = this.currentMediaIndex + 1;
    this.changeMedia(index);
  }

  public prevMedia(): void {
    const index: number = this.currentMediaIndex - 1;
    this.changeMedia(index);
  }

  public openInBrowser(): void {
    window.open(this.currentMedia.url || this.currentMedia.link, '_blank');
  }

  public copyUrl(): void {
    this.dataRoomUtilies.copyLinkClicked(this.currentMedia);
  }

  public deleteFile(): void {
    this.removeFile.next(this.currentMedia);
    this.closeModal();
  }

  public changeStatus(fromThread = false): void {
    this.isOpenThread = fromThread ? false : !this.isOpenThread;
  }

  public actionPinMedia(): void {
    if (!this.pinModalService.pinnedMedia && this.pinModalService.isLimitPins) {
      this.pinMedia();
    } else if (this.pinModalService.pinnedMedia && this.pinModalService.unPinMessageAccess) {
      this.pinModalService.unPinMedia();
    }
  }

  public minimizeMedia() {
    this.isOpenThread = false;
    this.onMinimize.emit();
    this.isMinimize = true;
    this.fullImagePreviewService.onResetOptions(true);
  }

  public expandMedia() {
    this.onExpand.emit();
    this.isMinimize = false;
    this.fullImagePreviewService.initInModal(this.modalId);
  }

  public pinMedia(): void {
    this.pinModalService.pinMedia(this.MediaTypePin);
  }

  private getThreadMentions(users) {
    if (users) {
      this.mentionThreadMembers = [
        'all',
        ...users.filter((item) => item._id !== this.userId).map((item) => item.userName),
      ];
    }
  }

  private linkMedia(document) {
    if (document.inNewWindow) {
      window.open(document.link, '_blank');
    } else {
      if (document.type === TreeType.laneBoard) {
        this.router.navigate([document.link]);
      } else {
        this.setCurrentMedia(document);
      }
    }
  }

  private documentIS(document) {
    if (document.originalFileName) {
      return 'File';
    } else if (document.link) {
      return 'Link';
    } else {
      return 'Folder';
    }
  }

  private handleSwipe() {
    if (this.fullImagePreviewService.isZoomedImage()) {
      return;
    }

    if (this.touchendX < this.touchstartX && Math.abs(this.touchstartX - this.touchendX) > 20) {
      // swipe left
      if (!this.isLastMedia) {
        this.nextMedia();
      }
    } else if (
      this.touchendX > this.touchstartX &&
      Math.abs(this.touchendX - this.touchstartX) > 20
    ) {
      // swipe right
      if (!this.isFirstMedia) {
        this.prevMedia();
      }
    }
  }

  private takePdfFormat(): void {
    if (
      !this.isNotDataRoom &&
      (this.typeMedia === MediaPreviewTypes.Pdf || this.typeMedia === MediaPreviewTypes.Mso) &&
      !this.currentMedia.size
    ) {
      this.isLoading$.next(true);

      this.store
        .dispatch(
          new DocumentGetBase64({
            id: this.currentMedia._id,
            fromDataRoom: true,
          }),
        )
        .pipe(
          tap((res) => {
            const data = atob(res.Documents.documentBase64.split(',')[1]);
            this.url = { data };
          }),
          catchError(() => of(null)),
          finalize(() => this.isLoading$.next(false)),
        )
        .subscribe();
    } else {
      this.url = this.currentMedia.url;
    }
  }

  private initiliazeSubscriber(): void {
    this.object = this.previewData.object;
    this.objectId = this.previewData.objectId;
    this.isMobile = this.previewData.isMobile;
    this.platform = this.isMobile ? Capacitor.getPlatform() : this.previewData.platform;
    this.isInPrivateFolder = this.pinModalService.defineIsFileHasPrivateParentFolders(
      this.isNotDataRoom,
    );
    this.setCurrentMedia(this.previewData.currentMedia);

    this.userId = this.localStorageService.get('userId') || '';
    this.store.select(UsersState.getTenantUsers).subscribe((users) => {
      this.userCreator = users.find((user) => user._id === this.ownerId);
    });

    this.platformOS = this.store.selectSnapshot(AuthState.getPlatformOS);

    this.actions
      .pipe(untilDestroyed(this), ofActionDispatched(ThreadsSocketNewMessage))
      .subscribe(({ payload }) => {
        if (!this.message || !this.currentMedia?.chatMessage?.threadId) {
          this.message = payload.message;
        }
      });

    this.takePdfFormat();

    this.chatId = this.store
      .selectSnapshot(ChatsState.getChats)
      .find((chat) => chat.objectId === this.objectId)?._id;

    switch (this.object) {
      case 'spaces':
        this.store
          .dispatch(new SpaceGetUsersList({ id: this.objectId, exists: true }))
          .pipe(untilDestroyed(this))
          .subscribe(
            (res) => this.getThreadMentions(res.Spaces.users),
            (error) => console.error(error),
          );
        break;
      case 'projects':
        this.store
          .dispatch(new ProjectGetUsersList({ id: this.objectId, exists: true }))
          .pipe(untilDestroyed(this))
          .subscribe((res) => this.getThreadMentions(res.Projects.users));
        break;
    }

    this.threadInit();

    this.pinModalService.initializeSubscribe(
      this.currentMedia._id,
      this.object,
      this.objectId,
      this.platform,
      this.currentMedia.url || this.currentMedia.link,
      false,
      this.isInlineImage ? MediaPreviewTypes.Link : this.typeMedia,
    );
    this.pinModalService.isLoadingPin
      .pipe(untilDestroyed(this))
      .subscribe((value) => (this.isLoading = value));
  }

  private threadInit() {
    if (!this.currentMedia.link && this.currentMedia.chatMessage) {
      this.message = this.currentMedia.chatMessage;

      if (this.isMobile) {
        this.message = { ...this.message, fileData: this.currentMedia };
      }

      if (this.currentMedia.chatMessage.threadId) {
        this.store.dispatch(new ThreadGetMessages({ threadId: this.message.threadId }));
      }
    } else {
      this.message = null;
    }
  }

  changeMedia(index: number) {
    const document = this.media[index];
    this.isLoading$.next(true);
    this.fullImagePreviewService.onResetOptions(true);
    this.fullImagePreviewService.initInModal(this.modalId);
    this.isVideoLoad = false;
    this.url = '';

    if (this.documentIS(document) === 'Link') {
      this.linkMedia(document);
      this.pinModalService.changeMedia(this.currentMedia._id);
      this.threadInit();
      this.isLoading$.next(false);
      this.takePdfFormat();
      return;
    }

    if (document.url) {
      this.setCurrentMedia(document);
      this.pinModalService.changeMedia(this.currentMedia._id);
      this.threadInit();
      this.isLoading$.next(false);
      this.takePdfFormat();
      setTimeout(() => (this.isVideoLoad = true), 0);
      this.ref.detectChanges();
    } else {
      this.fileService.filesGetUrl({ id: document._id }).subscribe((res) => {
        this.setCurrentMedia({ ...document, url: res.url });
        this.pinModalService.changeMedia(this.currentMedia._id);
        this.threadInit();
        this.takePdfFormat();
        this.isLoading$.next(false);
        setTimeout(() => (this.isVideoLoad = true), 0);
        this.ref.detectChanges();
      });
    }
  }

  downloadDocument() {
    if (this.platformService.isCapacitor || this.platformService.isTauri) {
      this.downloadService.downloadFile(this.currentMedia);
    } else {
      this.fullImagePreviewService.dataRoomDownloadFiles({
        ...this.currentMedia,
        fromDataRoom: !this.isNotDataRoom,
      });
    }
  }

  setCurrentMedia(data) {
    this.currentMedia = data;
    this.isPrivate =
      this.isInPrivateFolder || this.currentMedia?.type === DocumentAccessTypes.Private;
  }

  handleShowButton(): void {
    if (this.showButton) {
      return;
    }
    this.showButton = true;

    setTimeout(() => (this.showButton = false), 3000);
  }
}
