<div
  class="card"
  [class.card-dark]="themeMode === 'Dark'"
  [style.backgroundColor]="card.color"
  [id]="cardId"
  cdkDrag
  [cdkDragData]="card"
  [cdkDragStartDelay]="200"
  *transloco="let t; read: 'lane-board'"
>
  <div
    *ngIf="!isShowContent && !isShowCreator && !isShowVotes && !isContentShow"
    class="card-placeholder"
  >
    👀
  </div>

  <div *ngIf="isContentShow" class="card-content">
    <div
      *ngIf="!card?.editMode; else editMode"
      class="card-content__description"
      triggers="hover"
      [ngbTooltip]="t('copy-text-tooltip')"
      (dblclick)="handleDblClick()"
      t-id="card-add-new-card-title"
    >
      {{ card.title }}
    </div>

    <ng-template #editMode>
      <textarea
        appClickOutside
        #cardTextarea
        type="text"
        class="form-control mr-4"
        [placeholder]="t('enter-card-name')"
        [class.invalid]="!title.valid"
        [formControl]="title"
        (keydown.enter)="handleEnter()"
        (clickOutside)="handleClickOutside()"
        t-id="card-add-new-card-title-input"
      ></textarea>
    </ng-template>

    <div class="card-content__dropdown" ngbDropdown>
      <div
        id="dropdown-actions-card"
        ngbDropdownToggle
        placement="top"
        container="body"
        t-id="card-dropdown-menu"
        triggers="hover"
        [ngbTooltip]="t('actions-tooltip')"
      >
        <app-svg name="ellipsis" width="13" height="13" [hoverEffect]="true"></app-svg>
      </div>

      <!--   Dropdown Menu  -->
      <div class="card-menu dropdown-menu dropdown-menu-right text-left" ngbDropdownMenu>
        <div
          class="card-menu__item align-items-center"
          (click)="handleClickMenuItem(cardActionEnum.Edit, $event)"
          t-id="card-dropdown-menu-edit"
        >
          <app-svg name="pen" width="20" height="16" class="mr-2"></app-svg>
          <span>{{ t('btn-edit-card') }}</span>
        </div>

        <div
          class="card-menu__item align-items-center"
          (click)="handleClickMenuItem(cardActionEnum.CreateTicket, $event)"
          t-id="card-dropdown-menu-convert"
        >
          <app-svg name="copy" width="20" height="16" class="mr-2"></app-svg>
          <span>{{ t('btn-create-ticket') }}</span>
        </div>

        <div
          class="card-menu__item align-items-center"
          (click)="handleClickMenuItem(cardActionEnum.Delete, $event)"
          t-id="card-dropdown-menu-delete"
        >
          <app-svg name="trash" width="20" height="16" class="mr-2"></app-svg>
          <span>{{ t('btn-delete-card') }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="card-footer">
    <div *ngIf="isCreatorShow" class="card-footer__creator">
      <app-avatar [userId]="card?.ownerUserId" [showStatus]="false"></app-avatar>
      <div class="card-footer__creator-name">{{ card?.owner?.userName }}</div>
    </div>
    <div *ngIf="card?.votes && isShowVotes" class="card-footer__votes">
      <p
        class="card-footer__votes__item"
        (click)="handleClickMenuItem(cardActionEnum.Like)"
        t-id="card-votes-add-remove"
      >
        👍
      </p>
      <p class="card-footer__votes__item" t-id="card-votes-counter">
        {{ card.votes?.length }}
      </p>
    </div>
  </div>
</div>
