import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { IElement } from '../../../../shared/components/table/table.component';
import { Store } from '@ngxs/store';

@Injectable()
export class DataService {
  private subject = new Subject<Array<IElement>>();
  private isLoaded = new BehaviorSubject<Boolean>(false);
  private searchSubject = new Subject<string>();

  constructor(protected store: Store) {}

  sendIsLoadedStatus(status) {
    this.isLoaded.next(status);
  }

  getIsLoadedStatus(): Observable<Boolean> {
    return this.isLoaded.asObservable();
  }

  sendData(data: Array<IElement>) {
    this.subject.next(data);
  }

  getData(): Observable<Array<IElement>> {
    return this.subject.asObservable();
  }

  sendSearchText(text: string) {
    this.searchSubject.next(text);
  }

  getSearchText(): Observable<string> {
    return this.searchSubject.asObservable();
  }

  groupProjectsBySpace(
    [spaces, spacesUsers, projects = [], projectsUsers = [], chats = []],
    isSpacesSelected = true,
    isProjectsSelected = false,
  ): Array<IElement> {
    spaces = spaces.map((item) => {
      return {
        ...item,
        isAccess: !!chats.find((chat) => chat.object === 'spaces' && chat.objectId === item._id),
      };
    });

    // console.log('chats', chats);
    // console.log('spaces', spaces);

    if (!spaces && !spacesUsers.length) {
      return;
    }

    return (isSpacesSelected ? spaces : projects).map((space) => {
      const spaceUsers =
        (isSpacesSelected ? spacesUsers : projectsUsers).find((user) =>
          isSpacesSelected ? user.spaceId === space._id : user.projectId === space._id,
        )?.users || [];

      return {
        name: isSpacesSelected ? space.spaceName : space.projectName,
        ownerId: spaceUsers.find(({ roleName }) => roleName === 'Owner')?._id,
        teammates: spaceUsers.length,
        prefix: space.prefix,
        projects: isProjectsSelected
          ? []
          : projects
              ?.filter((project) => project.spaceId === space._id)
              .map((project) => {
                const projectUsers =
                  projectsUsers.find(({ projectId }) => projectId === project._id)?.users || [];
                return {
                  name: project?.projectName,
                  prefix: project?.prefix,
                  teammates: projectUsers.length,
                  ownerName: projectUsers.find(({ roleName }) => roleName === 'Owner')?.userName,
                  ...project,
                };
              }) || [],
        ...space,
      };
    });
  }
}
