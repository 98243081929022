/** Get documents **/
export class DocumentsGet {
  static readonly type = '[Documents] Get';
  constructor(public payload) {}
}

/** Get documents permissions **/
export class DocumentsGetPermissions {
  static readonly type = '[Documents] Get Permissions';
  constructor(public payload) {}
}

/** Get folders **/
export class DocumentsFoldersGet {
  static readonly type = '[Documents] FoldersGet';
  constructor(public payload) {}
}

/** Create folder **/
export class DocumentsFolderCreate {
  static readonly type = '[Documents] FolderCreate';
  constructor(public payload) {}
}

/** Update folder **/
export class DocumentsFolderUpdate {
  static readonly type = '[Documents] FolderUpdate';
  constructor(public payload) {}
}

/** Delete folder **/
export class DocumentsFolderDelete {
  static readonly type = '[Documents] FolderDelete';
  constructor(public payload) {}
}

export class DocumentsToggleImageGallery {
  static readonly type = '[Documents] ToggleImageGallery';
  constructor(public payload) {}
}

/** Delete file **/
export class DocumentsDelete {
  static readonly type = '[Documents] FileDelete';
  constructor(public payload) {}
}

/** Document Save In Dataroom **/
export class DocumentSaveInDataroom {
  static readonly type = '[Documents] DocumentSaveInDataroom';
  constructor(public payload) {}
}

/** Update file **/
export class DocumentUpdateFile {
  static readonly type = '[Documents] DocumentUpdateFile';
  constructor(public payload) {}
}

/** Sort documents **/
export class DocumentsSort {
  static readonly type = '[Documents] DocumentsSort';
  constructor(public payload) {}
}

/** Add link **/
export class DocumentsLinkAdd {
  static readonly type = '[Documents] LinkAdd';
  constructor(public payload) {}
}

/** Delete link **/
export class DocumentsLinkDelete {
  static readonly type = '[Documents] LinkDelete';
  constructor(public payload) {}
}

/** Update link **/
export class DocumentsUpdateLink {
  static readonly type = '[Documents] LinkUpdate';
  constructor(public payload) {}
}

/** Search set **/
export class DocumentsSearchSet {
  static readonly type = '[Documents] DocumentsSearchSet';
  constructor(public payload) {}
}

/** Search clear **/
export class DocumentsSearchClear {
  static readonly type = '[Documents] DocumentsSearchClear';
  constructor(public payload) {}
}

/** Add document **/
export class DocumentsAddDocument {
  static readonly type = '[Documents] DocumentsAddDocument';
  constructor(public payload) {}
}

/** Update document **/
export class DocumentsUpdateDocument {
  static readonly type = '[Documents] DocumentsUpdateDocument';
  constructor(public payload) {}
}

/** Delete document **/
export class DocumentsDeleteDocument {
  static readonly type = '[Documents] DocumentsDeleteDocument';
  constructor(public payload) {}
}

/** Delete document **/
export class DocumentGetUrls {
  static readonly type = '[Documents] DocumentGetUrls';
  constructor(public payload) {}
}

/** Download document in base64 **/
export class DocumentDownloadBase64 {
  static readonly type = '[Documents] DocumentDownloadBase64';
  constructor(public payload) {}
}

/** Get document in base64 **/
export class DocumentGetBase64 {
  static readonly type = '[Documents] DocumentGetBase64';
  constructor(public payload) {}
}
