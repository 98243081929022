import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  NgbActiveModal,
  NgbNav,
  NgbNavChangeEvent,
  NgbNavItem,
  NgbNavItemRole,
  NgbNavLink,
  NgbNavLinkBase,
  NgbNavContent,
  NgbNavOutlet,
} from '@ng-bootstrap/ng-bootstrap';
import { Actions, Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';

import { ConfigService } from '../../shared/services/config.service';
import { AuthState } from '../../shared/store/states/auth.state';
import { ProjectSettingsComponent } from '../../shared/components/project-settings/project-settings.component';
import { ConfirmAlert } from '../../shared/alerts/alerts';
import { CheckPermissionPipe } from '../../shared/pipes/check-permission.pipe';
import { CustomizeTicketFieldsComponent } from '../../shared/components/customize-ticket-fields/customize-ticket-fields.component';
import { IntegrationsComponent } from '../../shared/components/integrations/integrations.component';
import { ActionLogsComponent } from '../../shared/components/action-logs/action-logs.component';
import { UsersListComponent } from '../../shared/components/users-list/users-list.component';
import { NgIf } from '@angular/common';
import { SvgComponent } from '../../shared/svgs/svg/svg.component';

export enum Tabs {
  info = 'info',
  members = 'members',
  activity = 'activity',
  integration = 'integration',
  tickets = 'tickets',
}

export interface PageData {
  object: string;
  objectId: string;
  chatId: string;
  chat: string;
  space: {};
  project: {};
  currentTab: string;
  membersChat: [];
}

@Component({
  selector: 'app-page-details-modal',
  templateUrl: './page-details-modal.component.html',
  styleUrls: ['./page-details-modal.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    SvgComponent,
    NgbNav,
    NgbNavItem,
    NgbNavItemRole,
    NgbNavLink,
    NgbNavLinkBase,
    NgbNavContent,
    NgIf,
    ProjectSettingsComponent,
    UsersListComponent,
    ActionLogsComponent,
    IntegrationsComponent,
    CustomizeTicketFieldsComponent,
    NgbNavOutlet,
    CheckPermissionPipe,
  ],
})
export class PageDetailsModalComponent implements OnInit, OnDestroy {
  @ViewChild('pageDetailsTab') pageDetailsTab: NgbNav;
  @ViewChild('projectSettingsComponent')
  projectSettings: ProjectSettingsComponent;

  /*todo add types instead any*/
  destroy$: Subject<void> = new Subject<void>();
  pageData: PageData;
  config: any = {};
  platform: string;
  object: string;
  objectId: string;
  chatId: string;
  chat: any;
  space: any;
  project: any;
  membersChat: any[];
  currentTab: string = Tabs.info;

  constructor(
    private actions: Actions,
    private store: Store,
    private configService: ConfigService,
    private activeModal: NgbActiveModal,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit(): void {
    this.initModalData(this.pageData);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.currentTab = changeEvent.nextId;
  }

  close(reason?: string) {
    if (this.projectSettings?.updateForm?.dirty && reason !== 'saved') {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.close-modal-subject'),
        text: this.translocoService.translate('alert.close-modal-text'),
        cancelButtonText: this.translocoService.translate('alert.close-modal-btn-discard'),
        confirmButtonText: this.translocoService.translate('alert.close-modal-btn-save'),
        confirmButtonClass: 'btn-solid',
        platform: this.platform,
      }).then(
        (result) => {
          if (result === 'isConfirmed') {
          }
        },
        () => {
          this.activeModal.close();
        },
      );
    } else {
      this.activeModal.close();
    }
  }

  initModalData(data: PageData): void {
    this.object = data.object;
    this.objectId = data.objectId;
    this.chatId = data.chatId;
    this.chat = data.chat;
    this.space = data.space;
    this.project = data.project;
    this.currentTab = data.currentTab;
    this.membersChat = data.membersChat;
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);
  }
}
