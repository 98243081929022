import { Subject } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';

export enum ChatTypeEnum {
  Thread = 'Thread',
  Chat = 'Chat',
}

@UntilDestroy({ checkProperties: true })
@Injectable({ providedIn: 'root' })
export class ChatService {
  public currentChatType = ChatTypeEnum.Chat;
  public currentPage = null;
  private MESSAGES_AGGREGATE_TIME = 10;

  private needScrollToBottom = new Subject<string>();
  public needScrollToBottom$ = this.needScrollToBottom.asObservable();

  public isReactionProcess = false;

  constructor() {}

  public emitScrollToBottom(chatType: ChatTypeEnum): void {
    this.needScrollToBottom.next(chatType);
  }

  /**
   Aggregate consecutive massages handler
   */
  public aggregateMessages(messages) {
    return messages?.map((msg, index) => {
      let showDetails = false;
      let userName = '';

      const curMsgWhId = msg?.webhookId ? msg?.webhookId : '';
      const prevMsgWhId = messages[index - 1]?.webhookId ? messages[index - 1]?.webhookId : '';
      if (`${msg?.userId}-${curMsgWhId}` !== `${messages[index - 1]?.userId}-${prevMsgWhId}`) {
        showDetails = true;
      } else {
        let diff =
          (new Date(msg.created_at).getTime() -
            new Date(messages[index - 1]?.created_at).getTime()) /
          1000;
        diff /= 60;
        if (Math.abs(Math.round(diff)) > this.MESSAGES_AGGREGATE_TIME) {
          showDetails = true;
        }
      }

      return { ...msg, showDetails, userName };
    });
  }
}
