import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DOCUMENT, NgClass, NgIf } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Actions, ofActionDispatched, ofActionSuccessful, Store } from '@ngxs/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import {
  NgbModal,
  NgbNav,
  NgbNavChangeEvent,
  NgbNavItem,
  NgbNavItemRole,
  NgbNavLink,
  NgbNavLinkBase,
  NgbNavContent,
  NgbNavOutlet,
} from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'ngx-localstorage';
import { ToastrService } from 'ngx-toastr';
import { isEqual } from 'lodash';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';

import { UsersDbDto } from '../../../api/models/users-db-dto';
import { ChatsDbDto } from '../../../api/models/chats-db-dto';
import { RouterTenantPipe } from '../../pipes/router-tenant.pipe';
import { RouterQueryService } from '../../services/router-query.service';
import { ConfigService } from '../../services/config.service';
import { SocketsService } from '../../services/sockets.service';
import { SentryIoService } from '../../services/sentry-io.service';
import { BoardTicketModalComponent } from '../../../modals/board-ticket/board-ticket.component';

import {
  ChatsClear,
  ChatsClearFile,
  ChatsGetMessages,
  ChatsGetPin,
  ChatsInit,
  ChatsOpenThreadSidebar,
  ChatsPrivateMessagesIsOpening,
  ChatsResetEmojiReactionIsUpdated,
  ChatsSetCurrentChatPage,
  ChatsThreadsListMarkAllAsRead,
  ChatsUploadFile,
  PinnedScrollingMessage,
  ThreadGetCounters,
  ThreadGetMessages,
  ThreadMarkAsRead,
} from '../../store/actions/chats.action';
import { NoteCreate, NoteDelete } from '../../store/actions/notes.action';
import { TicketsCreate, TicketsDelete } from '../../store/actions/board.action';
import {
  CalendarEventCreate,
  CalendarEventDelete,
} from '../../store/actions/calendar-events.action';
import { ThreadsSocketNewMessage } from '../../store/actions/threads.action';
import { AuthState } from '../../store/states/auth.state';
import { ChatsState } from '../../store/states/chats.state';
import { ConfigsState } from '../../store/states/configs.state';
import { ThreadsState } from '../../store/states/threads.state';
import { WebhooksByChatIdGet } from '../../store/actions/webhook.actions';
import { ScrollingChatService } from '../../services/scrolling-chat.service';
import { MinimizeService } from '../../services/minimize.service';
import { DraftService } from '../../services/draft.service';
import { UploadFileService } from '../../services/upload-file.service';
import { Message } from './chat.model';
import { ChatService, ChatTypeEnum } from './chat.service';
import { QuillModulesForChat } from '../../data/quill-configuration';
import { ImageService } from '../../services/image.service';
import { QuillInitializeService } from '../../services/quill/quill-init.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ChatThreadComponent } from './chat-thread/chat-thread.component';
import { ChatSearchComponent } from './chat-search/chat-search.component';
import { ChatUsersListComponent } from './chat-users-list/chat-users-list.component';
import { ChatThreadsListComponent } from './chat-threads-list/chat-threads-list.component';
import { SvgComponent } from '../../svgs/svg/svg.component';
import { ChatAppFormComponent } from './chat-app-form/chat-app-form.component';
import { ChatMessagesComponent } from './chat-messages/chat-messages.component';
import { ChatPinnedMessagesComponent } from './chat-pinned-messages/chat-pinned-messages.component';
import { AngularSplitModule } from 'angular-split';
import { MixpanelService } from '../../../plugins/mixpanel/mixpanel.service';

export enum SidebarTab {
  members = 'members',
  threads = 'threads',
  search = 'search',
  activity = 'activity',
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  providers: [UploadFileService],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgClass,
    NgIf,
    AngularSplitModule,
    ChatPinnedMessagesComponent,
    ChatMessagesComponent,
    ChatAppFormComponent,
    SvgComponent,
    NgbNav,
    NgbNavItem,
    NgbNavItemRole,
    NgbNavLink,
    NgbNavLinkBase,
    NgbNavContent,
    ChatThreadsListComponent,
    ChatUsersListComponent,
    ChatSearchComponent,
    NgbNavOutlet,
    ChatThreadComponent,
  ],
})
export class ChatComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild('chatMessages') chatMessages: ElementRef;
  @ViewChild('chatSidebarNav') chatSidebarNav: NgbNav;
  @ViewChild('fileCaptionInput', { static: false })
  fileCaptionInput: ElementRef;
  @ViewChild('AudioMessageButton') AudioMessageButton: ElementRef;

  @Input() platform = 'web';
  @Input() chatId: string;
  @Input() object: string;
  @Input() objectId: string;
  @Input() readOnly: boolean;

  @Output() closeChat = new EventEmitter();

  editorModules: any;
  config: any = {};
  isMobile = false;
  item = 0;
  heightKeyboard = 0;
  currentChatItem: any;
  currentSidebarTab: string = SidebarTab.threads;
  space: any;
  chatSize = 60;
  sidebarSize = 40;
  chatSectionsSize: number[] | {};

  user: UsersDbDto;
  membersChat: any[];
  numberOfChatMembers: number;
  showNoMessagesContainer = false;
  needScrollToBottom = false;
  isVisible = false;
  placement = 'bottom-right';

  destroy$: Subject<void> = new Subject<void>();
  chats$: Subscription;
  chatMembers$: Subscription;
  messages$: Subscription;
  threadMessages$: Subscription;
  messages: Message[] = [];

  chatMessage = null;
  selectedFile: File;
  selectedFiles: File[];
  isSendingFile: Observable<boolean>;

  chatSidebarIsOpen = true;
  isOpenThreadSidebar = false;
  searchIsActive = false;
  threadMessage: any;
  mentionChatMembers: string[];

  perPage = 20;
  perChat = 80;
  currentPage = 1;
  previousPage = 0;
  chatMessageCnt = 0;
  lastMessageLoaded: boolean;
  backgroundMobile = true;

  private isMessagesPopulated = false;

  isOffline = false;

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    this.backgroundMobile = !document.hidden;
    this.emitReadMessage();
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public activatedRoute: ActivatedRoute,
    public uploadFileService: UploadFileService,
    public cdr: ChangeDetectorRef,
    public chatService: ChatService,
    public imageService: ImageService,
    private renderer: Renderer2,
    private actions: Actions,
    private router: Router,
    private routerTenantPipe: RouterTenantPipe,
    private routerQueryService: RouterQueryService,
    private modalsService: NgbModal,
    private configService: ConfigService,
    private socketsService: SocketsService,
    private sentryService: SentryIoService,
    private scrollingService: ScrollingChatService,
    private minimizeService: MinimizeService,
    private draftService: DraftService,
    private readonly localStorageService: LocalStorageService,
    private quillInitializeService: QuillInitializeService,
    protected ref: ChangeDetectorRef,
    protected toastr: ToastrService,
    protected store: Store,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
    this.perPage = this.configService.MESSAGES_PER_PAGE;
    this.editorModules = { ...QuillModulesForChat, magicUrl: true };

    this.renderer.addClass(this.document.body, 'chat-application');

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const threadId = this.activatedRoute.snapshot.queryParams.thread;

        if (threadId && !this.activatedRoute.snapshot.queryParams.ticket) {
          this.isOpenThreadSidebar = false;
          this.store.dispatch(new ThreadGetMessages({ threadId }));
        }
      }
    });
  }

  ngOnInit(): void {
    this.chatSectionsSize = this.localStorageService.get('chatSectionsSize') || {};

    this.store
      .select(ChatsState.getStatus)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.isOffline = res;
        this.cdr.detectChanges();
      });

    this.store
      .select(AuthState.getUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => (this.user = res));

    this.scrollingService.scollingMessage.pipe(takeUntil(this.destroy$)).subscribe((message) => {
      this.scrollingToMessage(message);
    });

    if (this.platform === 'web') {
      this.store
        .select(ConfigsState.getSidebarConfigs)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          const chatSize = this.getSectionSize(0);
          if (res.isOpened && chatSize > 50 && chatSize < 99) {
            this.onResizeSplitter({ sizes: [50, 50] });
          } else if (res.isOpened && chatSize === 50 && chatSize === 50) {
            this.onResizeSplitter({ sizes: [60, 40] });
          }
        });

      this.store
        .select(ChatsState.getCurrentPage)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.currentPage = res;
          this.chatService.currentPage = this.currentPage;
          this.cdr.detectChanges();
        });

      this.store
        .select(ChatsState.getSearchIsActive)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.searchIsActive = res;
          this.cdr.detectChanges();
        });

      this.store
        .select(ChatsState.getSearchCurrentPage)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (this.searchIsActive && res > 0) {
            this.currentPage = res;
            this.chatService.currentPage = this.currentPage;
            this.previousPage = res - 1;

            this.cdr.detectChanges();
          }
        });
    }

    this.actions
      .pipe(
        takeUntil(this.destroy$),
        ofActionSuccessful(
          CalendarEventCreate,
          CalendarEventDelete,
          TicketsCreate,
          TicketsDelete,
          NoteCreate,
          NoteDelete,
        ),
      )
      .subscribe(() => {
        this.getChatMessages(this.currentPage);
        this.getThreadsCounters();
      });

    this.actions
      .pipe(
        takeUntil(this.destroy$),
        ofActionSuccessful(
          ThreadMarkAsRead,
          ChatsThreadsListMarkAllAsRead,
          ThreadsSocketNewMessage,
        ),
      )
      .subscribe(() => this.getThreadsCounters());

    this.actions
      .pipe(takeUntil(this.destroy$), ofActionDispatched(ChatsSetCurrentChatPage))
      .subscribe(({ payload }) => {
        this.currentPage = payload;
        this.chatService.currentPage = this.currentPage;
        this.previousPage = payload - 1;
      });

    this.isSendingFile = this.store.select(ChatsState.getChatSendFileLoading);
  }

  ngAfterViewInit() {
    this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
        this.cdr.detectChanges();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.objectId && !!changes.objectId.currentValue && this.platform === 'web') {
      this.openTicketFromURL();
    }

    if (changes.chatId && !!changes.chatId.currentValue) {
      this.needScrollToBottom = true;
      this.isVisible = false;
      this.isMessagesPopulated = false;
      this.messages = [];
      this.imageService.renewImagesSubject();
      this.cdr.detectChanges();
      this.currentPage = 1;
      this.chatService.currentPage = this.currentPage;
      this.previousPage = 0;

      this.chatSectionsSize = this.localStorageService.get('chatSectionsSize') || {};
      const chatSize = this.getSectionSize(0);
      this.chatSidebarIsOpen = !chatSize || chatSize < 99;

      if ((!this.object || !this.objectId) && this.currentSidebarTab === SidebarTab.activity) {
        this.selectFirstTab();
      }

      if (this.searchIsActive) {
        this.clearSearchInChat();
      }

      this.store.dispatch(new ChatsGetPin({ id: this.chatId }));
      this.uploadFileService.setFiles([]);

      // load webhooks data by chat id
      this.store.dispatch(new WebhooksByChatIdGet(this.chatId));

      this.store.dispatch(new ChatsInit({ chatId: this.chatId }));
      this.store.dispatch(new ChatsClearFile());
      this.getChatMessages(this.currentPage);

      if (
        this.store.selectSnapshot(ChatsState.getMessages)(this.chatId) !== undefined &&
        this.store.selectSnapshot(ChatsState.getChatMembers)(this.chatId) !== undefined
      ) {
        const loadedPages = this.store.selectSnapshot(ChatsState.getLoadedPages)(this.chatId);
        const LoadedPagesCount = this.store.selectSnapshot(ChatsState.getChatsLoadedPagesCount)[
          this.chatId
        ];
        if (LoadedPagesCount > 0 && !loadedPages.includes(1)) {
        } else {
          this.currentPage = Math.max.apply(null, loadedPages);
          this.chatService.currentPage = this.currentPage;
          this.previousPage = this.currentPage - 1;
        }
      }
      this.cdr.markForCheck();
      this.getCurrentChat();
      this.getChatMembers();
      this.getMessages();
    }
  }

  ngOnDestroy() {
    this.store.dispatch(new ChatsClear());
    this.destroy$.next();
    this.destroy$.complete();
  }

  getMessages(): void {
    let messagesPrev: ChatsDbDto[] = [];

    this.messages$?.unsubscribe();
    this.messages$ = this.store
      .select(ChatsState.getMessages)
      .pipe(
        map((filterFn) => filterFn(this.chatId)),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe((messageHistory) => {
        if (messageHistory !== undefined) {
          if (
            messageHistory.length &&
            (!this.isCurrentChat(messageHistory[0]) ||
              (isEqual(messageHistory, messagesPrev) && this.isMessagesPopulated))
          ) {
            return;
          }

          messagesPrev = messageHistory;
          this.chatMessageCnt = messageHistory.length;
          this.showNoMessagesContainer = messageHistory.length === 0;
          this.lastMessageLoaded = this.store.selectSnapshot(ChatsState.getLastMessageDownloaded);

          if (this.currentChatItem) {
            const sortedMessages = [...messageHistory].sort((a, b) => {
              if (a._id > b._id) {
                return 1;
              } else if (a._id < b._id) {
                return -1;
              } else {
                return 0;
              }
            });
            this.updateMessagesList(sortedMessages);
          }
          this.cdr.markForCheck();
        }
      });
  }

  getCurrentChat(): void {
    this.chats$?.unsubscribe();
    this.chats$ = this.store
      .select(ChatsState.getChats)
      .pipe(takeUntil(this.destroy$))
      .subscribe((chats) => {
        this.currentChatItem = chats?.find((item) => item._id === this.chatId);
      });
  }

  getChatMembers(): void {
    this.chatMembers$?.unsubscribe();
    this.chatMembers$ = this.store
      .select(ChatsState.getChatMembers)
      .pipe(
        map((filterFn) => filterFn(this.chatId), takeUntil(this.destroy$)),
        distinctUntilChanged(),
      )
      .subscribe((members) => {
        if (members) {
          this.membersChat = members;
          this.numberOfChatMembers = members.length;
          this.mentionChatMembers = [
            'all',
            ...members
              .filter((item) => item.userId !== this.user?._id)
              .map((item) => item.userName),
          ];
        }
      });
  }

  public get checkDirectMessage() {
    return !this.router.url.includes('page=dm');
  }

  openTicketFromURL(): void {
    const ticketId = this.activatedRoute.snapshot.queryParams.ticket;

    if (ticketId) {
      const draft = this.draftService.isHasActiveDraft(ticketId);
      MixpanelService.trackEvent('Chat: open ticket from URL');

      if (draft) {
        this.draftService.openTicketOrDraft(draft, {
          id: ticketId,
          object: this.object as 'spaces' | 'projects',
          objectId: this.objectId,
        });
      } else {
        const modalRef = this.modalsService.open(BoardTicketModalComponent, {
          size: 'xl',
          backdrop: 'static',
          scrollable: true,
          keyboard: false,
          beforeDismiss: () => modalRef.componentInstance.closeImagePreview(true),
        });
        modalRef.componentInstance.ticketData = {
          id: ticketId,
          object: this.object,
          objectId: this.objectId,
        };
        this.minimizeService.minimizeInit(modalRef);
      }
    }
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.currentSidebarTab = changeEvent.nextId;
  }

  selectFirstTab() {
    MixpanelService.trackEvent('Chat: select first tab');
    this.currentSidebarTab = SidebarTab.threads;
  }

  selectSidebarTab(id) {
    MixpanelService.trackEvent('Chat: select sidebar tab', { id });
    this.chatSidebarNav?.select(id);
  }

  getThreadsCounters() {
    const { onlyUnreadThreads, isThreadsForChat } = this.store.selectSnapshot(
      ChatsState.getThreadsFilters,
    );
    const request = {
      chatId: isThreadsForChat && this.chatId ? this.chatId : null,
      onlyUnread: onlyUnreadThreads,
    };
    // this.store.dispatch(new ThreadGetCounters(request));
  }

  emitReadMessage() {
    if (
      (this.currentChatItem?.numberOfUnreadMentions > 0 ||
        this.currentChatItem?.numberOfUnreadMessages > 0) &&
      this.backgroundMobile
    ) {
      this.socketsService.get().emit('chats:markAsRead', this.currentChatItem._id);
      this.sentryService.addBreadcrumb(
        `Chat ${this.currentChatItem._id} was marked as read manually on chat open`,
      );

      this.currentChatItem = {
        ...this.currentChatItem,
        numberOfUnreadMentions: 0,
        numberOfUnreadMessages: 0,
      };
    }
  }

  updateMessagesList(messageHistory) {
    // this.emitReadMessage();

    this.messages = messageHistory.map((message) => ({
      ...message,
      chatType: this.currentChatItem.type,
    }));
    this.isMessagesPopulated = true;

    if (this.store.selectSnapshot(ChatsState.isChatEmojiReactionUpdated)) {
      this.store.dispatch(new ChatsResetEmojiReactionIsUpdated());
    }
    this.checkThreadOpenedMessage();

    if (this.needScrollToBottom) {
      this.needScrollToBottom = false;
      this.onScrollToBottom();
    }
  }

  onScrollToBottom(): void {
    if (this.previousPage > 0) {
      this.currentPage = 1;
      this.chatService.currentPage = this.currentPage;
      this.previousPage = 0;
      this.chatMessagesLimit(true, this.chatMessageCnt);

      this.getChatMessages(this.currentPage);
    } else {
      const messagesElement = document.querySelector('#chat-messages .chat-messages');
      messagesElement?.scrollIntoView(false);
    }
  }

  scrollBottomMobile(heightKeyboard): void {
    MixpanelService.trackEvent('Chat: scroll to bottom mobile');
    if (this.platform !== 'web') {
      this.heightKeyboard = heightKeyboard;
      this.cdr.detectChanges();
    }
  }

  scrollBottom(): void {
    MixpanelService.trackEvent('Chat: scroll to bottom');
    const messagesElement = document.querySelector('#chat-messages .chat-messages');
    setTimeout(() => messagesElement?.scrollIntoView(false), 500);
  }

  chatMessagesLimit(deleteFromStart: boolean, amount: number): void {
    // this.store.dispatch(new ChatsDeleteMessages({ start: deleteFromStart, deleteCount: amount }));
  }

  getChatMessages(page, isFirstLoad = false) {
    if (!this.isOffline) {
      this.store
        .dispatch(
          new ChatsGetMessages({
            chatId: this.chatId,
            page: page,
            perPage: this.perPage,
          }),
        )
        .subscribe(
          () =>
            isFirstLoad &&
            setTimeout(() => this.chatService.emitScrollToBottom(ChatTypeEnum.Chat), 300),
        );
    }
  }

  getNextChatPage(isFirstLoad = false) {
    this.currentPage++;
    this.chatService.currentPage = this.currentPage;

    this.getChatMessages(this.currentPage, isFirstLoad);

    if (this.perChat <= this.chatMessageCnt) {
      this.previousPage++;
      this.chatMessagesLimit(true, this.perPage);
    }
  }

  getPrevChatPage(): void {
    if (this.previousPage) {
      this.getChatMessages(this.previousPage);

      this.previousPage--;
      this.currentPage--;
      this.chatService.currentPage = this.currentPage;
      this.chatMessagesLimit(
        false,
        this.lastMessageLoaded ? this.chatMessageCnt % this.perPage : this.perPage,
      );
    }
  }

  /**
   Send message with file handler
   */
  sendFile({ isAudioMessage = false, toastId = undefined, file = undefined }) {
    MixpanelService.trackEvent('Chat: send file');
    const body: any = {
      chatId: this.chatId,
      file: file || this.selectedFile,
      ...this.checkIsAudioMessage(isAudioMessage),
    };

    this.store
      .dispatch(new ChatsUploadFile({ isAudioMessage, toastId, body }))
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.selectedFile = null;
        },
        (err) => {
          this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
          this.selectedFile = null;
        },
      );
  }

  checkIsAudioMessage(isAudioMessage) {
    if (isAudioMessage) {
      return { isAudioMessage };
    }
  }

  onMessageChanged(text: string): void {
    this.chatMessage = text;
  }

  onFileChanged(files: File[]): void {
    if (files?.length) {
      this.uploadFileService.setFiles(Array.from(files));
    }
  }

  jumpToBottom(): void {
    MixpanelService.trackEvent('Chat: jump to bottom');
    this.currentPage = 1;
    this.chatService.currentPage = 1;
    this.previousPage = 0;
  }

  fileDropped(files: FileList): void {
    MixpanelService.trackEvent('Chat: file dropped');
    if (files?.length) {
      this.uploadFileService.setFiles(Array.from(files));
    }
  }

  clearSearchInChat(): void {
    MixpanelService.trackEvent('Chat: clear search');
    const searchField = document.getElementById('clearSearchInChat');
    if (searchField) {
      searchField.click();
      this.currentPage = 1;
      this.chatService.currentPage = this.currentPage;
      this.previousPage = 0;
    }
  }

  getSectionSize(section: number) {
    return this.chatSectionsSize?.[section];
  }

  onResizeSplitter({ sizes }) {
    MixpanelService.trackEvent('Chat: resize splitter');
    this.chatSidebarIsOpen = sizes[0] < 99;
    this.chatSectionsSize = sizes;
    this.localStorageService.set('chatSectionsSize', this.chatSectionsSize);
  }

  onOpenChatSidebar() {
    MixpanelService.trackEvent('Chat sidebar open');
    this.chatSidebarIsOpen = true;
    const chatSize = this.getSectionSize(0);
    if (!chatSize || chatSize === 99) {
      this.onResizeSplitter({ sizes: [this.chatSize, this.sidebarSize] });
    }
  }

  onToggleChatSidebar() {
    MixpanelService.trackEvent(this.chatSidebarIsOpen ? 'Chat sidebar close' : 'Chat sidebar open');
    this.chatSidebarIsOpen = !this.chatSidebarIsOpen;
    this.onResizeSplitter({
      sizes: this.chatSidebarIsOpen ? [this.chatSize, this.sidebarSize] : [99, 1],
    });
  }

  uploadClipboardFIle(uploadData): void {
    MixpanelService.trackEvent('Chat: upload clipboard file');
    if (uploadData.type === 'chat') {
      this.selectedFile = uploadData.retrievedFile;
    }
  }

  downloadFileCompleted() {}

  onAudioRecorded({ file }) {
    MixpanelService.trackEvent('Chat: audio recorded');
    this.sendFile({ isAudioMessage: true, file });
  }

  scrollingToMessage(message) {
    MixpanelService.trackEvent('Chat: scrolling to message');
    if (!message) {
      return;
    }
    const isExistMessage = this.messages?.some((msg) => msg._id === message._id);
    if (isExistMessage) {
      const refMessage = this.document.getElementById(`msg-${message._id}`);
      refMessage.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (this.messages) {
      this.store
        .dispatch(
          new PinnedScrollingMessage({
            id: this.chatId,
            perPage: this.perPage,
            messageId: message._id,
            takeCountOfPageAfter: 3,
          }),
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          const refMessage = this.document.getElementById(`msg-${message._id}`);
          if (refMessage) {
            refMessage.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }

          this.currentPage = res.Chats.currentPage;
          this.chatService.currentPage = this.currentPage;
          this.previousPage = res.Chats.currentPage - 5 >= 0 ? res.Chats.currentPage - 5 : 0;
        });
    }
  }

  viewDirectChat(userId) {
    MixpanelService.trackEvent('Chat: view direct chat');
    if (userId !== this.user._id) {
      this.store.dispatch(new ChatsPrivateMessagesIsOpening(true));
      this.socketsService.get().emit('chats:getDirectChatIdByUserId', { userId });
    }
  }

  clearThreadId() {
    this.isOpenThreadSidebar = false;
    this.threadMessage = null;
    this.threadMessages$?.unsubscribe();
    if (this.activatedRoute.snapshot.queryParams.thread) {
      this.routerQueryService.update({ thread: null });
    }
  }

  /**
   * Open thread method
   * @param message
   */
  openThreadSidebar(message) {
    MixpanelService.trackEvent('Chat: open thread sidebar');
    this.onOpenChatSidebar();

    if (!this.isMobile) {
      this.selectSidebarTab(SidebarTab.threads);
    } else {
      if (message.threadId) {
        this.store.dispatch(new ThreadGetMessages({ threadId: message.threadId }));
        // this.socketsService.get().emit('chats:getChatsMessageByThreadId', { threadId: message.threadId });
      }
    }
    this.threadMessage = message;
    this.isOpenThreadSidebar = true;
    this.store.dispatch(new ChatsOpenThreadSidebar(true));
    this.cdr.detectChanges();
  }

  checkThreadOpenedMessage(): void {
    const threadData = this.store.selectSnapshot(ThreadsState.getCurrentThreadHeadMessage);
    if (threadData.messageId && !threadData.threadId) {
      this.threadMessage = this.messages.find((message) => message._id === threadData.messageId);
    }
  }

  checkDeletedTopicThread(threadMessageId?: string): void {
    if (this.threadMessage && threadMessageId === this.threadMessage._id) {
      this.clearThreadId();
    }
  }

  private isCurrentChat(message: ChatsDbDto): boolean {
    return message.chatId === this.chatId;
  }
}
