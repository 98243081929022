import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgScrollbar } from 'ngx-scrollbar';
import moment from 'moment-timezone';

import { ConfigService } from '../../../services/config.service';
import { SocketsService } from '../../../services/sockets.service';
import { ChatsState } from '../../../store/states/chats.state';
import { AuthState } from '../../../store/states/auth.state';
import { ChatsGetMessages, ChatsSearchMessages } from '../../../store/actions/chats.action';
import { ChatMessageContentComponent } from '../chat-message-content/chat-message-content.component';
import { ChatMessageDetailsComponent } from '../chat-message-details/chat-message-details.component';
import { AvatarComponent } from '../../../../standalone/components/avatar/avatar.component';
import { SvgComponent } from '../../../svgs/svg/svg.component';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslocoDirective } from '@ngneat/transloco';
import { MixpanelService } from '../../../../plugins/mixpanel/mixpanel.service';

@Component({
  selector: 'app-chat-search',
  templateUrl: './chat-search.component.html',
  styleUrls: ['./chat-search.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    FormsModule,
    NgIf,
    SvgComponent,
    NgScrollbar,
    NgFor,
    NgClass,
    forwardRef(() => AvatarComponent),
    ChatMessageDetailsComponent,
    ChatMessageContentComponent,
  ],
})
export class ChatSearchComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('scrollableSearch') scrollbarRef: NgScrollbar; // Get scrollbar component reference
  @Input() platform = 'web';
  @Input() chatId: string;

  destroy$: Subject<void> = new Subject<void>();
  scrollSubs: Subscription;
  config: any = {};
  currTzAbbr = null;
  searchIsActive = false;
  searchedText = null;
  searchResults: any[];
  currentPage = 1;
  isPreloadActive = false;
  scrollToBottomTimeout = 0;

  constructor(
    public cdr: ChangeDetectorRef,
    private store: Store,
    private configService: ConfigService,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit(): void {
    this.store
      .select(AuthState.getUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (user?.timezone) {
          this.currTzAbbr = moment.tz(user.timezone).format('Z').replace(':', '');
        }
      });

    this.store
      .select(ChatsState.getSearchIsActive)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.searchIsActive = res;
        this.cdr.detectChanges();
      });

    this.store
      .select(ChatsState.getSearchResults)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.searchResults = res.map((message) => ({
          ...message,
          showDetails: true,
        }));
        if (res && this.isPreloadActive) {
          this.isPreloadActive = false;
        }
        this.cdr.detectChanges();
      });
  }

  ngAfterViewInit() {
    this.scrollSubs?.unsubscribe();
    this.scrollSubs = this.scrollbarRef?.verticalScrolled
      .pipe(takeUntil(this.destroy$))
      .subscribe((e: any) => {
        const scrollbar = document
          .querySelector(`#searchScrollbar .scrollbar-control`)
          ?.getBoundingClientRect();

        if ((e.target.scrollTop + scrollbar.height) / e.target.scrollHeight > 0.999) {
          this.onScrollToBottom();
        }
      });
  }

  ngOnDestroy() {
    this.scrollSubs?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  trackByMessages(index, item) {
    return item._id;
  }

  getChatMessages(page, searchText = null) {
    this.store.dispatch(
      new ChatsGetMessages({
        chatId: this.chatId,
        ...(searchText
          ? {
              page: page,
              perPage: this.configService.MESSAGES_PER_PAGE,
              search: searchText,
            }
          : { page: page, perPage: this.configService.MESSAGES_PER_PAGE }),
      }),
    );
  }

  openSearchedMessagePage(message) {
    this.store.dispatch(
      new ChatsGetMessages({
        chatId: message.chatId,
        messageId: message._id,
        perPage: this.configService.MESSAGES_PER_PAGE,
      }),
    );
  }

  onScrollToBottom() {
    if (!this.isPreloadActive) {
      const searchFullyLoaded = this.store.selectSnapshot(ChatsState.getSearchFullyLoadedStatus);

      if (!searchFullyLoaded) {
        this.getNextListPage();
      }
    }
  }

  getNextListPage() {
    this.isPreloadActive = true;
    setTimeout(() => {
      this.currentPage++;
      this.getChatMessages(this.currentPage, this.store.selectSnapshot(ChatsState.getSearchedText));
    }, this.scrollToBottomTimeout);
  }

  searchMessages() {
    MixpanelService.trackEvent('Chat Search: Search');
    const text = this.searchedText?.replace(/[\[\]\{\}]/g, '');
    let isSearch = false;

    if (text && text.trim() !== '' && text.length > 2) {
      this.getChatMessages(1, text);
      isSearch = true;
    }

    this.store.dispatch(new ChatsSearchMessages({ isActive: isSearch, searchText: text }));
  }

  clearSearchInChat(): void {
    this.searchedText = null;
    this.searchMessages();

    this.getChatMessages(1);
  }
}
