// Common
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

// Services
import { ConfigService, ITemplateConfig } from '../../shared/services/config.service';
import { TranslocoDirective } from '@ngneat/transloco';

@Component({
  selector: 'app-url-change-modal',
  templateUrl: './url-change-modal.component.html',
  styleUrls: ['./url-change-modal.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, FormsModule, ReactiveFormsModule],
})
export class UrlChangeModalComponent implements OnInit {
  public config: ITemplateConfig;
  public urlData = new FormControl('');
  public titleData = new FormControl('');

  constructor(
    private configService: ConfigService,
    private activeModal: NgbActiveModal,
  ) {
    this.config = this.configService.templateConf;
  }
  ngOnInit(): void {}

  public get isDarkTheme(): boolean {
    return this.config?.layout.variant === 'Dark';
  }

  public closeModal(): void {
    this.activeModal.dismiss();
  }

  public saveUrl(): void {
    this.activeModal.close({
      url: this.urlData.value,
      title: this.titleData.value,
    });
  }

  public openUrl(): void {
    window.open(this.urlData.value, '_blank').focus();
  }
}
