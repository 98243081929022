import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { FilesService } from '../../../../api/services/files.service';
import { HtmlHelper } from '../../../utils/html-helper';
import { ToastrService } from 'ngx-toastr';
import { DocumentsService } from '../../../services/documents.service';
import { FilesHelper } from '../../../utils/files-helper';
import { Subject } from 'rxjs';
import { UsersPublicFieldsResDto } from '../../../../api/models/users-public-fields-res-dto';
import { AuthState } from '../../../store/states/auth.state';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TreeType } from '../documents.component';
import { ConfirmAlert } from '../../../alerts/alerts';
import {
  DocumentsDelete,
  DocumentsFolderDelete,
  DocumentsLinkDelete,
} from '../../../store/actions/documents.action';
import { TicketsFilesDelete } from '../../../store/actions/board.action';
import { FullImagePreviewService } from '../../../services/full-image-preview.service';
import { DocumentTypes } from '../../../enums/document-types';
import { UrlHelper } from '../../../utils/url-helper';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root',
})
export class DataRoomUtiliesService {
  public editLink = new Subject();
  public editFolder = new Subject();
  public user: UsersPublicFieldsResDto;
  public treeType = TreeType;
  public environment: any;

  constructor(
    private fileService: FilesService,
    private store: Store,
    private htmlHelper: HtmlHelper,
    private toast: ToastrService,
    private filesHelper: FilesHelper,
    private documentsService: DocumentsService,
    private fullImagePreviewService: FullImagePreviewService,
    private translocoService: TranslocoService,
    private urlHelper: UrlHelper,
  ) {}

  public isPlayable(fileName) {
    const type = this.filesHelper.getFileType(fileName);
    return type === 'video' || type === 'music';
  }

  public initiliazeData() {
    this.store
      .select(AuthState.getUser)
      .pipe(untilDestroyed(this))
      .subscribe((res) => (this.user = res));
  }

  public downloadClicked(file, platform) {
    if (!file.url) {
      this.fileService.filesGetUrl({ id: file._id }).subscribe((res) => {
        this.filesHelper.downloadFile(res.url, file.originalFileName, platform, false);
      });
    } else {
      this.filesHelper.downloadFile(file.url, file.originalFileName, platform);
    }
  }

  public isEditTicket(document): boolean {
    return (
      this.user?._id === document.ownerUserId &&
      !document.originalFileName &&
      !document.content &&
      document.parentId !== this.treeType.board
    );
  }

  public isRemoveFile(document): boolean {
    return (
      this.user._id === document.ownerUserId &&
      document.parentId !== this.treeType.chat &&
      document.parentId !== this.treeType.board
    );
  }

  public copyLinkClicked(document) {
    const documentType = this.documentIS(document);
    if (documentType === DocumentTypes.File) {
      if (!document.url) {
        this.fileService.filesGetUrl({ id: document._id }).subscribe((res) => {
          this.htmlHelper.copyValueToBuffer(res.url);
          this.toast.success(
            this.translocoService.translate('toastr.copied-link-will-be-valid-30-min'),
            this.translocoService.translate('toastr.title-success'),
          );
        });
      } else {
        this.htmlHelper.copyValueToBuffer(document.url);
        this.toast.success(
          this.translocoService.translate('toastr.message-link-copied'),
          this.translocoService.translate('toastr.title-success'),
        );
      }
    } else {
      let url;
      if (documentType === DocumentTypes.Folder) {
        url = this.documentsService.getFolderUrl(document, this.environment);
      } else if (documentType === DocumentTypes.Link) {
        url = document.link;
      } else if (documentType === DocumentTypes.Document) {
        const currentUrl = this.urlHelper.getFullCurrentUrl();
        const [path, queryParams] = currentUrl.split('?');
        const queryParam = `&document=${document._id}`;
        if (path.includes('/documents')) {
          url = currentUrl + queryParam;
        } else {
          url = path + '/documents?' + queryParams + queryParam;
        }
      }
      this.htmlHelper.copyValueToBuffer(url);
      this.toast.success(
        this.translocoService.translate('toastr.message-link-copied'),
        this.translocoService.translate('toastr.title-success'),
      );
    }
  }

  public editLinkClicked(file) {
    this.editLink.next(file);
  }

  public editFolderClicked(file) {
    this.editFolder.next(file);
  }

  public deleteClicked(document, platform, object, objectId) {
    ConfirmAlert(this.documentIS(document), { platform: platform }).then(
      () => {
        let action:
          | DocumentsDelete
          | DocumentsFolderDelete
          | DocumentsLinkDelete
          | TicketsFilesDelete;
        action = new DocumentsDelete({
          id: document._id,
          body: { object: object, objectId: objectId },
        });

        this.store
          .dispatch(action)
          .pipe(untilDestroyed(this))
          .subscribe(
            () => {
              this.toast.success(
                this.translocoService.translate('toastr.entity-deleted', {
                  title: this.documentIS(document),
                }),
                this.translocoService.translate('toastr.title-success'),
              );
            },
            (err) => {
              this.toast.error(err.message, this.translocoService.translate('toastr.title-error'));
            },
          );
      },
      () => {},
    );
  }

  documentIS(document): DocumentTypes {
    if (document.originalFileName) {
      return DocumentTypes.File;
    } else if (document.link) {
      return DocumentTypes.Link;
    } else if (document.content) {
      return DocumentTypes.Document;
    } else {
      return DocumentTypes.Folder;
    }
  }
}
