<div
  class="notepad card m-0"
  [ngClass]="{
    'notepad-ios': platform === 'ios',
    'notepad-android': platform === 'android'
  }"
>
  <div class="card-content">
    <form [formGroup]="notepadForm" (ngSubmit)="submitForm()">
      <quill-editor
        t-id="notepad-note-input"
        class="content-editor"
        bounds="self"
        [modules]="editorModules"
        [placeholder]="'Type content here'"
        [formControl]="notepadForm.controls.content"
        (onContentChanged)="editorChanged($event)"
        (onEditorCreated)="editorCreated($event)"
        (click)="handleClickInEditor($event)"
      ></quill-editor>

      <div class="card-footer text-right px-2">
        <button class="btn btn-solid btn-save" [disabled]="!isChanged" t-id="notepad-note-save">
          Save
        </button>
      </div>
    </form>
  </div>
</div>

<!-- image full-size preview modal -->
<ng-template #dataRoomImagePreviewModal let-c="close" let-d="dismiss">
  <app-chat-image-preview
    #imagePreviewComponent
    [platform]="platform"
    [tz]="currTzAbbr"
    [object]="object"
    [objectId]="objectId"
    [imagePreviewMessage]="fullImagePreviewService.imagePreview"
    [isLastPreviewImage]="fullImagePreviewService.isLastPreviewImage"
    [isFirstPreviewImage]="fullImagePreviewService.isFirstPreviewImage"
    [updateEvent]="fullImagePreviewService.updateEvent"
    [isOpenNotFromTheChat]="true"
    (closePreviewModal)="previewModal.close()"
    (goToPreviousImage)="fullImagePreviewService.goToPreviousImage()"
    (goToNextImage)="fullImagePreviewService.goToNextImage()"
    (downloadFile)="fullImagePreviewService.downloadFile()"
  ></app-chat-image-preview>
</ng-template>
