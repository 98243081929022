<div
  class="column"
  [class.column-dark]="themeMode === 'Dark'"
  cdkDrag
  cdkDragBoundary=".board-lane"
  cdkDragLockAxis="x"
  [cdkDragData]="column"
  [id]="columnId"
  [cdkDragStartDelay]="50"
  *transloco="let t; read: 'lane-board'"
>
  <div class="column__header" cdkDragHandle>
    <div
      *ngIf="!column?.editMode; else editColumn"
      class="column__header__title"
      (dblclick)="handleClickMenuItem(columnActionType.Edit)"
      t-id="column-add-column-title"
    >
      {{ column.title }}
    </div>

    <ng-template #editColumn>
      <div class="column__edit-mode">
        <input
          type="text"
          class="form-control mr-4 lane-board-header__title"
          [placeholder]="t('enter-border-name-placeholder')"
          [class.disabled]="isDisableEditAction"
          [formControl]="title"
          (keyup.enter)="handleEnter()"
          t-id="column-add-column-title-input"
        />

        <app-svg
          name="pen"
          width="13"
          height="13"
          [hoverEffect]="true"
          [class.disabled]="isDisableEditAction"
          (click)="handleClickMenuItem(columnActionType.WasEdit)"
          t-id="column-add-column-title-save"
        ></app-svg>

        <app-svg
          name="close"
          width="20"
          height="20"
          [hoverEffect]="true"
          (click)="handleClickMenuItem(columnActionType.CloseEditMode)"
        ></app-svg>
      </div>
    </ng-template>

    <div
      *ngIf="!column?.editMode"
      class="column__header__actions"
      t-id="column-add-new-card"
      ngbDropdown
    >
      <!--   Add column   -->
      <app-svg
        name="plus"
        width="13"
        height="13"
        [hoverEffect]="true"
        (click)="handleClickMenuItem(columnActionType.AddCard)"
        t-id="column-add-column"
      ></app-svg>

      <!--   Dropdown   -->
      <div
        id="dropdown-actions-{{ column.title }}"
        ngbDropdownToggle
        placement="top"
        container="body"
        t-id="column-dropdown-actions"
        triggers="hover"
        [ngbTooltip]="t('actions-tooltip')"
      >
        <app-svg name="ellipsis" width="13" height="13" [hoverEffect]="true"></app-svg>
      </div>

      <!--   Dropdown Menu  -->
      <div class="column-menu dropdown-menu dropdown-menu-right text-left" ngbDropdownMenu>
        <div
          class="column-menu__item align-items-center"
          (click)="handleClickMenuItem(columnActionType.Edit)"
          t-id="column-edit-column"
        >
          <app-svg name="pen" width="20" height="16" class="mr-2"></app-svg>
          <span>{{ t('btn-edit-column') }}</span>
        </div>

        <div class="column-menu__item align-items-center">
          <label
            for="colorpicker"
            class="column-menu__item__color-picker mr-2"
            [style.background-color]="color"
          ></label>
          <input
            id="colorpicker"
            class="column-menu__item__color-picker-input mr-2"
            type="color"
            defaultValue="#fff"
            [value]="color"
            (change)="handleColorChange($event)"
            t-id="column-color-picker"
          />
          <span>{{ t('form-color-picker') }}</span>
        </div>

        <div
          class="column-menu__item align-items-center"
          (click)="handleClickMenuItem(columnActionType.Delete)"
          t-id="column-delete-column"
        >
          <app-svg name="trash" width="20" height="16" class="mr-2"></app-svg>
          <span>{{ t('btn-delete-column') }}</span>
        </div>
      </div>
    </div>
  </div>

  <div
    class="column__cards"
    cdkDropList
    [cdkDropListData]="cards"
    (cdkDropListDropped)="drop($event)"
    (cdkDropListSorted)="sorted($event)"
  >
    <app-card
      *ngFor="let card of cards"
      [card]="card"
      [currentUser]="currentUser"
      [isShowContent]="isShowContent"
      [isShowCreator]="isShowCreator"
      [isShowVotes]="isShowVotes"
      [themeMode]="themeMode"
      t-id="column-list-card"
    ></app-card>
  </div>
</div>
