import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { LocalStorageService } from 'ngx-localstorage';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

import { ConfirmAlert } from '../alerts/alerts';
import { ChatsState } from '../store/states/chats.state';
import { ChatsCreatePin, ChatsDeletePin, ChatsGetPin } from '../store/actions/chats.action';
import { RolesTypeEnum } from '../components/chat/chat-pinned-messages/enums/roles-type.enum';
import { PinType } from '../components/chat/chat-pinned-messages/enums/pin-type.enum';
import { DocumentsState } from '../store/states/documents.state';
import { DocumentAccessTypes } from '../../api/models/document-access-types';
import { MediaPreviewTypes } from '../../modals/data-room-modals/preview-media/preview-media.component';
import { ToastrService } from 'ngx-toastr';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root',
})
export class PinModalService {
  public chatId: string;

  public canUnpinImage = true;
  public isPinned = false;
  public isLoadingPin = new BehaviorSubject(false);
  public pinnedDocument = new Subject<void>();

  private platform: string;
  private id: string;
  private type: MediaPreviewTypes | string;
  private url: string;
  private object: string;
  private objectId: string;

  constructor(
    private store: Store,
    private localStorageService: LocalStorageService,
    private translocoService: TranslocoService,
    protected toastr: ToastrService,
  ) {}

  // Getters
  public get isLimitPins(): boolean {
    return (
      this.store
        .selectSnapshot(ChatsState.getPinMessages)
        .filter((msg) => msg.linkDocument !== PinType.Message)?.length <= 14
    );
  }

  public get pinnedMedia(): boolean {
    return this.isPinned;
  }

  public get unPinMessageAccess(): boolean {
    return this.canUnpinImage;
  }

  private get isLink(): boolean {
    return this.id && this.type !== MediaPreviewTypes.Link;
  }

  private handleSuccess = (message: string, title: string) => {
    this.toastr.success(message, title);
    this.isPinned = true;
    this.isLoadingPin.next(false);
    this.pinnedDocument.next();
  };

  private handleError = (message: string, title: string) => {
    this.toastr.error(message, title);
    this.isLoadingPin.next(false);
  };

  // Pin media
  public pinMedia(linkDocument = PinType.Files) {
    this.isLoadingPin.next(true);

    if (this.isLink) {
      this.store
        .dispatch(
          new ChatsCreatePin({
            id: this.chatId,
            body: {
              linkDocument: linkDocument,
              linkDocumentId: this.id,
              object: this.object,
              objectId: this.objectId,
            },
          }),
        )
        .pipe(untilDestroyed(this))
        .subscribe(
          () =>
            this.handleSuccess(
              this.translocoService.translate('modals.pin.title-pinned'),
              this.translocoService.translate('pin-modal.toastr.title-success'),
            ),
          () =>
            this.handleError(
              this.translocoService.translate('modals.pin.title-pinned-pin-error'),
              this.translocoService.translate('pin-modal.toastr.title-error'),
            ),
        );
    } else {
      this.store
        .dispatch(
          new ChatsCreatePin({
            id: this.chatId,
            body: {
              name: `Internal file`,
              isInternalLink: true,
              url: this.url,
              openInFrame: true,
              object: this.object,
              objectId: this.objectId,
            },
          }),
        )
        .pipe(untilDestroyed(this))
        .subscribe(
          () =>
            this.handleSuccess(
              this.translocoService.translate('modals.pin.pinned-success'),
              this.translocoService.translate('modals.toastr.title-success'),
            ),
          () =>
            this.handleError(
              this.translocoService.translate('modals.pin.pin-failed'),
              this.translocoService.translate('modals.toastr.title-error'),
            ),
        );
    }
  }

  // Unpin media
  public unPinMedia() {
    const id = this.pinPreview?._id;

    ConfirmAlert(this.translocoService.translate('alert.pin-title'), {
      subject: this.translocoService.translate('alert.unpin-subject'),
      text: this.translocoService.translate('alert.unpin-text'),
      confirmButtonText: this.translocoService.translate('alert.unpin-btn-text'),
      platform: this.platform,
    }).then(() => {
      this.isLoadingPin.next(true);
      this.store
        .dispatch(
          new ChatsDeletePin({
            id: this.chatId,
            pinnedMessageId: id,
            object: this.object || 'spaces',
            objectId: this.objectId,
          }),
        )
        .pipe(untilDestroyed(this))
        .subscribe(
          () =>
            this.handleSuccess(
              this.translocoService.translate('modals.pin.unpinned-success'),
              this.translocoService.translate('pin-modal.toastr.title-success'),
            ),
          () =>
            this.handleError(
              this.translocoService.translate('modals.pin.unpinned-pin-error'),
              this.translocoService.translate('pin-modal.toastr.title-error'),
            ),
        );
    });
  }

  public changeMedia(id: string) {
    this.id = id;
    this.isPinned = this.isPinnedMessage;
  }

  public initializeSubscribe(
    fileId: string,
    object: string,
    objectId: string,
    platform: string,
    url?: string,
    isDataRoom = false,
    typeMedia = MediaPreviewTypes.Image || '',
  ) {
    this.objectId = objectId;
    this.type = typeMedia;

    this.chatId = this.store
      .selectSnapshot(ChatsState.getChats)
      .find((chat) => chat.objectId === this.objectId)?._id;
    this.id = fileId;
    this.object = object;
    this.platform = platform;

    if (url) {
      this.url = url;
    }

    // Pinned listener
    if (
      this.chatId &&
      !this.store.selectSnapshot(ChatsState.getPinMessages)?.length &&
      !isDataRoom
    ) {
      this.store
        .dispatch(new ChatsGetPin({ id: this.chatId }))
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.isPinned = this.isPinnedMessage;

          const pinnedMessage = this.pinPreview;
          if (pinnedMessage) {
            this.canUnpinImage = this.canDeletePin(pinnedMessage);
          }
        });
    } else {
      this.isPinned = this.isPinnedMessage;

      const pinnedMessage = this.pinPreview;
      if (pinnedMessage) {
        this.canUnpinImage = this.canDeletePin(pinnedMessage);
      }
    }
  }

  private get isPinnedMessage(): boolean {
    return !!this.pinPreview;
  }

  private get pinPreview() {
    if (this.isLink) {
      return this.store
        .selectSnapshot(ChatsState.getPinMessages)
        .find(
          (pin: any) =>
            pin.linkDocument &&
            (pin.linkFile?._id === this.id ||
              pin.linkDocumentFile?._id === this.id ||
              pin.linkChatFile?._id === this.id ||
              pin.linkTicketFile?._id === this.id ||
              pin.linkThreadFile?._id === this.id ||
              pin.internalLinkFile?._id === this.id),
        );
    } else {
      return this.store
        .selectSnapshot(ChatsState.getPinMessages)
        .find((pin: any) => this.url && pin.url === this.url);
    }
  }

  private canDeletePin(item): boolean {
    const allUser = this.store.selectSnapshot(ChatsState.getChatMembers)(this.chatId);
    const userId = this.localStorageService.get('userId') || '';

    const currentUser = allUser?.find((user) => user.userId === userId);

    return (
      userId === item.creatorId ||
      currentUser?.roleName?.includes(
        RolesTypeEnum.Owner || RolesTypeEnum.SpaceLeader || RolesTypeEnum.ProjectLeader,
      )
    );
  }

  defineIsFileHasPrivateParentFolders(isNotDataRoom?: boolean): boolean {
    if (isNotDataRoom) {
      return false;
    }

    const breadcrumbs = this.store.selectSnapshot(DocumentsState.getBreadcrumbs);
    return breadcrumbs?.some((breadcrumb) => breadcrumb.type === DocumentAccessTypes.Private);
  }
}
