/** Get document permissions **/
export class DocPermissionsGet {
  static readonly type = '[DocPermissions] Get';
  constructor(public payload) {}
}

/** Clear document permissions **/
export class DocPermissionsClearState {
  static readonly type = '[DocPermissions] Clear';
  constructor(public payload) {}
}

/** Update files permissions **/
export class FilePermissionsUpdate {
  static readonly type = '[FilePermissions] Update';
  constructor(public payload) {}
}

/** Update folder permissions **/
export class FolderPermissionsUpdate {
  static readonly type = '[FolderPermissions] Update';
  constructor(public payload) {}
}

/** Update link permissions **/
export class LinkPermissionsUpdate {
  static readonly type = '[LinkPermissions] Update';
  constructor(public payload) {}
}
