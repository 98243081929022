<div class="wh-push-msg-wrapper">
  <div class="message-header">
    <div class="name">New commit by {{ pusherName }} to [{{ commitToFullPath }}]</div>
  </div>
  <div class="message-wrapper-push">
    <div>
      <span>
        <a class="id-link" [href]="commitUrl" *ngIf="commitId" target="_blank">{{
          getShortCommitId(commitId)
        }}</a>
        {{ description }}
      </span>
    </div>

    <div class="repository">
      {{ repositoryName }}
    </div>
  </div>
</div>
