<div id="projectSubNavbar" class="sub-navbar-menu nav-tabs d-flex" [class.mobile]="isMobile">
  <a
    *ngFor="let tab of tabs"
    [routerLinkActiveOptions]="{ exact: true }"
    routerLinkActive="active"
    class="nav-link d-flex align-items-center"
    [class.mobile]="isMobile"
    [routerLink]="tab.route"
    t-id="sub-tabs-manage-space-project"
  >
    <span t-id="sub-tabs-manage-name-tab">{{ tab.title }}</span>
  </a>
</div>
