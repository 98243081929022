<app-text-message
  [message]="message"
  [mentionChatMembers]="mentionChatMembers"
  [context]="context"
  [platform]="platform"
></app-text-message>

<div *ngIf="isMainMessage" class="video-name">{{ nameFile }}</div>
<div class="d-flex flex-row">
  <div *ngIf="message.isDownloadingFile" class="video-spinner-loading">
    <app-custom-spinner [smallSize]="true"></app-custom-spinner>
    <p class="downloading-text">Downloading...</p>
  </div>

  <div class="video-player-wrapper">
    <app-video-player [videoUrl]="message.fileData.url" [isDownloading]="message.isDownloadingFile">
    </app-video-player>
  </div>
</div>
