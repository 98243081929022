<ng-container *transloco="let t; read: 'modals.admin-user-edit'">
  <div class="modal-header">
    <h5 class="modal-title">{{ titleModal }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
    </button>
  </div>
  <div class="modal-body" id="user-state-modal">
    <form novalidate [formGroup]="userForm">
      <div class="form-row">
        <div class="col-12 col-md">
          <div class="form-group" *ngIf="!customStateSection">
            <div class="controls">
              <ng-select
                [items]="userStates"
                bindLabel="label"
                bindValue="label"
                placeholder="{{ 'user.state_placeholder' | transloco }}"
                formControlName="state"
                id="state"
              >
                <ng-template ng-label-tmp let-item="item">
                  <span
                    >{{ item.emoji }}
                    {{
                      'user.state.' + (item.label ? item.label : 'no-selected') + '.label'
                        | transloco
                    }}</span
                  >
                </ng-template>
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-index="index"
                  let-search="searchTerm"
                >
                  <span
                    >{{ item.emoji }} {{ 'user.state.' + item.label + '.label' | transloco }}</span
                  >
                </ng-template>
              </ng-select>
            </div>
          </div>
          <a (click)="toggleCustomState()" *ngIf="!customStateSection" class="btn btn-subtle mb-2">
            {{ 'user.state_set_custom' | transloco }}
          </a>
          <div *ngIf="customStateSection">
            <div class="row">
              <div class="col form-icon-emoji-picker">
                <div class="d-flex flex-column justify-content-center align-items-center ml-2">
                  @if (selectedEmoji && selectedEmoji !== '') {
                    <div class="editor-btn emoji-picker-icon ml-2">{{ selectedEmoji }}</div>
                  } @else {
                    <div (click)="emojiPickerToggle()" class="editor-btn emoji-picker-icon ml-2">
                      <app-svg
                        name="face-smile"
                        width="16"
                        height="16"
                        aria-hidden="true"
                      ></app-svg>
                    </div>
                  }
                </div>
              </div>
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  formControlName="state_name"
                  placeholder="State Name"
                />
              </div>
              <div class="col-12 col-md">
                <ng-select
                  [items]="userAvailabilities"
                  bindLabel="name"
                  bindValue="label"
                  formControlName="state_available"
                  id="state_available"
                >
                  <ng-template ng-option-tmp let-item="item">
                    {{ item.name }}
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <a
              (click)="toggleCustomState()"
              *ngIf="customStateSection"
              class="btn btn-subtle mt-2 mb-2"
              >{{ 'user.state_set_default' | transloco }}</a
            >
          </div>
        </div>
        <div class="col-12 col-md-5">
          <ng-container *ngIf="user?.state_end">
            <div>
              <b>{{ 'user.state_section_date' | transloco }}</b>
            </div>
            {{ user.state_end | date: 'dd.MM.yyyy - HH:mm' : currTzAbbr }}
            <button
              *ngIf="user.state_end"
              type="button"
              (click)="removeState()"
              class="btn btn-solid mb-1 mb-sm-0"
            >
              {{ 'user.state_clear' | transloco }}
            </button>
          </ng-container>

          <ng-container *ngIf="!user?.state_end">
            <app-date-time-picker
              placeholder="{{ 'user.state_date_placeholder' | transloco }}"
              id="date"
              formControlName="state_end"
              [allDayEvent]="true"
              [scrollToDatePicker]="false"
              [mobile]="platform === 'mobile'"
              [mobileCalendarEvent]="platform === 'mobile'"
              [tabIndexes]="['tab-index-day-date-2', 'tab-index-day-time-2']"
            ></app-date-time-picker>
          </ng-container>
        </div>
      </div>
      <div class="actions-buttons">
        <button type="button" class="btn btn-subtle" (click)="close()">
          {{ t('btn-cancel') }}
        </button>
        <button
          type="submit"
          class="btn btn-solid ml-2"
          (click)="save()"
          t-id="admin-user-edit-save-changes"
        >
          {{ 'modals.admin-user-edit.btn-save-changes' | transloco }}
        </button>
      </div>
    </form>
  </div>
</ng-container>

<emoji-mart
  *ngIf="emojiIsOpen"
  class="webhook-emoji-picker"
  title=""
  emoji=""
  [backgroundImageFn]="emojiPickerImageFn"
  [autoFocus]="true"
  [isNative]="true"
  [darkMode]="darkMode"
  [exclude]="['flags']"
  [recent]="customRecentEmojis"
  (emojiSelect)="addEmoji($event)"
></emoji-mart>
