<!-- Projects section start -->
<section id="projects" *transloco="let t; read: 'space-projects'">
  <div
    class="actions-bar d-flex align-items-center justify-content-end"
    [class.actions-bar-mobile]="platform === 'mobile'"
  >
    <button
      *ngIf="'spaces::' + space._id + '::projectCreate' | checkPermission"
      t-id="space-projects-project-create-action"
      type="button"
      class="btn btn-solid d-flex align-items-center mr-2"
      (click)="projectService.createClick(space)"
    >
      <app-svg name="plus" width="11" height="11" class="mr-1"></app-svg>
      <span>{{ t('btn-add-projects') }}</span>
    </button>

    <button
      t-id="space-project-create-group"
      type="button"
      class="btn btn-solid d-flex align-items-center"
      (click)="spaceService.chatGroupCreate(space, platform)"
    >
      <app-svg name="plus" width="11" height="11" class="mr-1"></app-svg>
      <span>{{ t('btn-add-group-chat') }}</span>
    </button>
  </div>

  <section class="projects-container">
    <div class="card m-0">
      <div class="card-content">
        <div *ngIf="(projects | async)?.length === 0" class="m-2">
          {{ t('no-projects-yet') }}
        </div>

        <div
          *ngIf="(projects | async)?.length > 0"
          t-id="space-projects-list"
          class="card-body table-responsive projects-list"
        >
          <ng-scrollbar
            [autoHeightDisabled]="false"
            visibility="hover"
            thumbClass="white-scrollbars"
          >
            <table
              class="table project-table m-0"
              [ngClass]="{
                'project-table-light': config?.layout.variant === 'Light',
                'project-table-dark': config?.layout.variant === 'Dark'
              }"
            >
              <thead>
                <tr class="head-color">
                  <th>{{ t('thead-avatar') }}</th>
                  <th>{{ t('thead-emoji') }}</th>
                  <th>{{ t('thead-project-prefix') }}</th>
                  <th>{{ t('thead-project-name') }}</th>
                  <th>{{ t('thead-last-updated') }}</th>
                  <th>{{ t('thead-actions') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let project of projects | async">
                  <td style="width: 35px">
                    <app-project-avatar
                      [project]="project"
                      [showEmoji]="false"
                      class="project-avatar"
                    ></app-project-avatar>
                  </td>
                  <td style="width: 35px">
                    <span class="project-emoji">{{ project.emoji }}</span>
                  </td>
                  <td>{{ project.prefix }}</td>
                  <td>
                    <a
                      class="line-height-1 mb-0"
                      [routerLink]="['/project/' + project._id] | routerTenant"
                    >
                      {{ project.projectName }}
                    </a>
                  </td>
                  <td>{{ project.updated_at | fromNow: lang }}</td>
                  <td>
                    <app-svg
                      name="pen"
                      width="20"
                      height="16"
                      class="mr-2"
                      aria-hidden="true"
                      triggers="hover"
                      [ngbTooltip]="t('edit-project-tooltip')"
                      *ngIf="'projects::' + project._id + '::projectUpdate' | checkPermission"
                      (click)="projectService.updateClick(project._id)"
                    ></app-svg>
                    <app-svg
                      name="trash"
                      width="20"
                      height="16"
                      aria-hidden="true"
                      triggers="hover"
                      [ngbTooltip]="t('delete-project-tooltip')"
                      *ngIf="'projects::' + project._id + '::projectDelete' | checkPermission"
                      (click)="projectService.deleteClick(project._id)"
                    ></app-svg>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
      </div>
    </div>
  </section>
</section>
