import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment-timezone';
import { Store } from '@ngxs/store';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TicketsDbDto } from '../../../../../../api/models/tickets-db-dto';
import { TicketsFieldsDbDto } from '../../../../../../api/models/tickets-fields-db-dto';
import { TicketsFieldsValuesReqDto } from '../../../../../../api/models/tickets-fields-values-req-dto';
import { RecordService } from '../../../../../../shared/services/record.service';
import { TruncatePipe } from '../../../../../../shared/pipes/truncate.pipe';
import { BoardSettingsResDto } from '../../../../../../api/models/board-settings-res-dto';
import { FieldType } from '../../../../../../modals/custom-field/enums/fieldType';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { CustomizeService } from '../../../../../../shared/components/board/customize.service';
import { BoardsService } from '../../../boards.service';
import { PRIORITY } from '../../constants/priority.constants';
import { ChatsState } from '../../../../../../shared/store/states/chats.state';
import {
  ChatsCreatePin,
  ChatsDeletePin,
} from '../../../../../../shared/store/actions/chats.action';
import { ConfigService } from '../../../../../../shared/services/config.service';
import { BoardsState } from '../../../../../../shared/store/states/boards.state';
import { BoardsFieldState } from '../../../../../../shared/store/states/boards-field.state';
import { PriorityOrderService } from '../../../../../../shared/services/priority-order.service';
import { TippyDirective } from '../../../../../../shared/directives/tippy.directive';
import { RouterTenantPipe } from '../../../../../../shared/pipes/router-tenant.pipe';
import { AvatarComponent } from '../../../../../../standalone/components/avatar/avatar.component';
import { ProgressBarComponent } from '../../../../../../shared/components/progress-bar/progress-bar.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { ProjectAvatarComponent } from '../../../../../../shared/components/space-projects/project-avatar/project-avatar.component';
import { SvgComponent } from '../../../../../../shared/svgs/svg/svg.component';
import { RouterLink } from '@angular/router';
import { SpaceAvatarComponent } from '../../../../../../shared/components/space-projects/space-avatar/space-avatar.component';
import { NgClass, NgIf, NgFor, AsyncPipe } from '@angular/common';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { TranslocoDirective } from '@ngneat/transloco';
import { MixpanelService } from '../../../../../../plugins/mixpanel/mixpanel.service';

interface IBreadcrumbs {
  spaceName: string;
  spaceId: string;
  projectName: string;
  projectId: string;
}

@UntilDestroy()
@Component({
  selector: 'app-kanban-board-ticket',
  styleUrls: ['./kanban-board-ticket.component.scss'],
  templateUrl: './kanban-board-ticket.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TruncatePipe],
  standalone: true,
  imports: [
    TranslocoDirective,
    CdkDrag,
    NgClass,
    NgIf,
    SpaceAvatarComponent,
    RouterLink,
    SvgComponent,
    ProjectAvatarComponent,
    SvgIconComponent,
    NgFor,
    ProgressBarComponent,
    forwardRef(() => AvatarComponent),
    TippyDirective,
    NgbTooltip,
    AsyncPipe,
    RouterTenantPipe,
    TruncatePipe,
  ],
})
export class KanbanBoardTicketComponent implements OnInit {
  @Input() ticket: TicketsDbDto & { breadcrumbs?: IBreadcrumbs };
  @Input() isLast: boolean;
  @Input() isFirst: boolean;
  @Input() tickets: Array<TicketsDbDto> = [];
  @Input() membersMaxView = 3;
  @Input() columnName: string;
  @Input() isFromDashboard = false;
  @Input() getLinkSpaceSprint?: (ticket: TicketsDbDto) => string;
  @Input() getLinkProjectSprint?: (ticket: TicketsDbDto) => string;
  @Input() deleteTicket?: (ticketId: string) => void;
  @Input() customFieldsConfig?: Array<TicketsFieldsDbDto>;
  @ViewChild(TippyDirective) appTippy: TippyDirective;

  isDark$ = new BehaviorSubject(false);

  public actionsDropdownOpen = false;
  public priorityKeys = PRIORITY;

  private settings$ = new BehaviorSubject<BoardSettingsResDto>(null);
  private ticketsFieldsConfig$ = new BehaviorSubject<Array<TicketsFieldsDbDto>>([]);
  constructor(
    private store: Store,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private configService: ConfigService,
    public recordService: RecordService,
    public boardService: BoardsService,
    public priorityOrderService: PriorityOrderService,
    public toastr: ToastrService,
    public customizeService: CustomizeService,
  ) {}

  ngOnInit(): void {
    this.configService.templateConf$
      .pipe(untilDestroyed(this))
      .subscribe((config) => this.isDark$.next(config.layout.variant === 'Dark'));
    if (this.isFromDashboard) {
      if (this.customFieldsConfig?.length) {
        this.ticketsFieldsConfig = this.customFieldsConfig.filter(
          (customField) => customField.objectId === this.ticket.objectId,
        );
      }
    } else {
      this.store
        .select(BoardsState.getBoardSettings)
        .pipe(untilDestroyed(this))
        .subscribe((settings) => this.settings$.next(settings));

      this.store
        .select(BoardsFieldState.getFieldsList)
        .pipe(untilDestroyed(this))
        .subscribe((ticketsFieldsConfig) => this.ticketsFieldsConfig$.next(ticketsFieldsConfig));
    }
  }

  private get settings() {
    return this.settings$.value;
  }

  private get ticketsFieldsConfig() {
    return this.ticketsFieldsConfig$.value;
  }

  private set ticketsFieldsConfig(ticketsFieldsConfig: Array<TicketsFieldsDbDto>) {
    this.ticketsFieldsConfig$.next(ticketsFieldsConfig);
  }

  public colorTicket(ticket) {
    return ticket.bgColor && ticket.bgColor !== 'null'
      ? ticket.bgColor
      : this.settings?.bgColor
        ? this.settings.bgColor
        : null;
  }

  public formatDate(date) {
    return moment(date).format(this.isDateShow.format);
  }

  public getCheckPinned(ticket: TicketsDbDto): boolean {
    return this.store
      .selectSnapshot(ChatsState.getPinMessages)
      .some((pin) => pin.linkTicket?._id === ticket._id);
  }

  public pinMessage(ticket: TicketsDbDto): void {
    MixpanelService.trackEvent('Kanban: Pin message', { ticketId: ticket._id });
    const chatId = this.store
      .selectSnapshot(ChatsState.getChats)
      .find((chat) => chat.objectId === ticket.objectId)?._id;

    if (!chatId) {
      return;
    }

    this.store.dispatch(
      new ChatsCreatePin({
        id: chatId,
        body: {
          linkDocument: 'tickets',
          linkDocumentId: ticket._id,
          object: ticket.object,
          objectId: ticket.objectId,
        },
      }),
    );
  }

  public unPinMessage(ticket: TicketsDbDto): void {
    MixpanelService.trackEvent('Kanban: Unpin message', { ticketId: ticket._id });
    const chatId = this.store
      .selectSnapshot(ChatsState.getChats)
      .find((chat) => chat.objectId === ticket.objectId)?._id;

    const id = this.store
      .selectSnapshot(ChatsState.getPinMessages)
      .find((pin: any) => pin.linkDocument && pin.linkTicket?._id === ticket._id)?._id;

    if (!id || !chatId) {
      return;
    }

    this.store.dispatch(
      new ChatsDeletePin({
        id: chatId,
        pinnedMessageId: id,
        object: ticket.object,
        objectId: ticket.objectId,
      }),
    );
  }

  public editTicket(ticket: TicketsDbDto): void {
    MixpanelService.trackEvent('Kanban: Edit ticket', { ticketId: ticket._id });
    this.boardService.editTicket(ticket);
  }

  public moveToArchive(ticket: TicketsDbDto): void {
    MixpanelService.trackEvent('Kanban: Move to archive', { ticketId: ticket._id });
    this.boardService.moveToArchive(ticket);
  }

  public copyTicketAbbrAndTitle(ticket: TicketsDbDto): void {
    MixpanelService.trackEvent('Kanban: Copy ticket abbr and title', { ticketId: ticket._id });
    this.boardService.copyTicketAbbrAndTitle(ticket);
  }
  public recountTicketOrders(ticket: TicketsDbDto, options: object): void {
    MixpanelService.trackEvent('Kanban: Recount ticket orders', { ticketId: ticket._id, options });
    this.priorityOrderService.recountTicketOrders(ticket, options);
  }

  public customFieldShow(ticket: TicketsDbDto): Array<TicketsFieldsValuesReqDto> {
    if (!ticket.customFields?.length) {
      return [];
    }

    const filterField = this.ticketsFieldsConfig
      ?.filter((field) => !field.isLocked)
      ?.map((fieldConfig) => {
        const fieldFind = ticket.customFields.find(
          (field) =>
            field.ticketFieldId === fieldConfig._id &&
            fieldConfig.isVisible &&
            fieldConfig.isOnCard,
        );
        if (fieldFind) {
          return {
            ...fieldFind,
            value: this.formatCustomDate(fieldFind, fieldConfig),
            type: fieldConfig.type,
          };
        }
      })
      .filter((isField) => isField && isField.type !== FieldType.MultiSelect);
    return filterField?.length ? filterField : [];
  }

  private formatCustomDate(fieldFind, fieldConfig) {
    return fieldConfig.type === FieldType.DateTimePicker ||
      fieldConfig.type === FieldType.DatePicker
      ? moment(fieldFind.value).format(fieldConfig.format)
      : fieldFind.value;
  }

  get progressChecklist(): number {
    const oneItemProgress = 100 / this.ticket.checkListItems?.length;
    return Math.floor(
      this.ticket.checkListItems.reduce(
        (acc, item) => (item.isCompleted ? acc + oneItemProgress : acc),
        0,
      ),
    );
  }

  get isAssignedShow(): boolean {
    return !!this.ticketsFieldsConfig?.find(
      (field) => field.isLocked && field.type === FieldType.UserPicker && field.isOnCard,
    );
  }

  get isLabelsShow(): boolean {
    return !!this.ticketsFieldsConfig?.find(
      (field) => field.isLocked && field.type === FieldType.Tags && field.isOnCard,
    );
  }

  get isParentShow(): boolean {
    return !!this.ticketsFieldsConfig?.find(
      (field) =>
        field.isLocked &&
        field.type === FieldType.LinkRelationship &&
        field.isOnCard &&
        field.title === 'Parent',
    );
  }

  get isEstimationShow(): boolean {
    return !!this.ticketsFieldsConfig?.find(
      (field) => field.isLocked && field.type === FieldType.Unit && field.isOnCard,
    );
  }

  get isReleaseVersion(): boolean {
    return !!this.ticketsFieldsConfig?.find(
      (field) => field.isLocked && field.type === FieldType.Text && field.isOnCard,
    );
  }

  get isDateShow() {
    return this.ticketsFieldsConfig?.find(
      (field) => field.isLocked && field.type === FieldType.DateTimePicker && field.isOnCard,
    );
  }

  get ticketId(): string {
    return this.ticket?._id ?? this.ticket?._id;
  }

  get ticketFlagColor(): string {
    return this.priorityKeys[this.ticket.priority]?.fill;
  }

  actionsDropdownToggle($event: boolean): void {
    MixpanelService.trackEvent('Kanban: Actions dropdown toggle', { ticketId: this.ticket._id });
    this.actionsDropdownOpen = $event;
  }

  ticketDelete(ticketId: string) {
    MixpanelService.trackEvent('Kanban: Delete ticket', {
      ticketId,
      isFromDashboard: this.isFromDashboard,
    });
    if (this.isFromDashboard) {
      this.deleteTicket(ticketId);
    } else {
      this.boardService.deleteTicket(ticketId);
    }
  }
}
