import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';

import { ConfigService } from '../../../../services/config.service';
import { FilesHelper } from '../../../../utils/files-helper';
import { ChatTypeEnum } from '../../chat.service';

@Component({
  selector: 'app-generic-message',
  templateUrl: './generic-message.component.html',
  styleUrls: ['./generic-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class GenericMessageComponent implements OnDestroy {
  config: any = {};
  destroy$: Subject<void> = new Subject<void>();
  public object: string;
  public objectId: string;

  @Input() message: any;
  @Input() mentionChatMembers: string[];
  @Input() context: string;
  @Input() platform = 'web';

  constructor(
    public cdr: ChangeDetectorRef,
    public filesHelper: FilesHelper,
    protected configService: ConfigService,
    public store: Store,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get nameFile(): string {
    return this.message?.fileData?.originalFileName || this.message?.fileData?.name;
  }

  get isMainMessage(): boolean {
    return (
      (this.message?.threadId && !this.message?.chatId) ||
      (this.message?.chatId && !this.message?.threadId)
    );
  }

  public get chatType(): ChatTypeEnum {
    return this.message?.chatId && !this.message?.threadId
      ? ChatTypeEnum.Chat
      : ChatTypeEnum.Thread;
  }
}
