import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentLink',
  standalone: true,
})
export class DocumentLinkPipe implements PipeTransform {
  transform(url: string) {
    if (url.includes('://drive.google.com/file/') && url.includes('/view?usp=sharing')) {
      return url.replace('/view?usp=sharing', '/preview');
    }
    return url;
  }
}
