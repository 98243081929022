import { PriorityEnum } from '../enums/priority.enum';

export const PRIORITY: {
  [x: number]: { key: string; fill: string; displayName?: string };
} = {
  [PriorityEnum.Lowest]: {
    key: 'Lowest',
    fill: '#439FD3',
  },
  [PriorityEnum.Low]: {
    key: 'Low',
    fill: '#29C88F',
  },
  [PriorityEnum.Medium]: {
    key: 'Medium',
    fill: 'transparent',
  },
  [PriorityEnum.High]: {
    key: 'High',
    fill: '#F29425',
  },
  [PriorityEnum.Highest]: {
    key: 'Highest',
    fill: '#FC512A',
  },
};
