<div
  [ngClass]="{
    'message-text-light': layoutVariant === 'Light',
    'message-text-dark': layoutVariant === 'Dark'
  }"
>
  <!-- bitbucket PR message -->
  <app-webhook-pr-message
    *ngIf="
      webhookMessageData?.eventName === BitbucketEventTypes.PR_MERGED ||
      webhookMessageData?.eventName === BitbucketEventTypes.PR_REJECTED ||
      webhookMessageData?.eventName === BitbucketEventTypes.PR_CREATED
    "
    [repositoryName]="prMessageData?.repository?.full_name"
    [userName]="prMessageData?.actor?.display_name"
    [userUrl]="prMessageData?.actor?.links?.html?.href"
    [prActionTitle]="prActionTitle"
    [prTitle]="prMessageData?.pullrequest?.title"
    [prDescription]="prMessageData?.pullrequest?.description"
    [prNumber]="prMessageData?.pullrequest?.id"
    [prUrl]="prMessageData?.pullrequest?.links?.html?.href"
    [sourceBranch]="prMessageData?.pullrequest?.source?.branch?.name"
    [targetBranch]="prMessageData?.pullrequest?.destination?.branch?.name"
    [showDetails]="showPRDetails"
    [reviewers]="reviewers"
  ></app-webhook-pr-message>

  <!-- bitbucket push message -->
  <app-webhook-push-message
    *ngIf="webhookMessageData?.eventName === BitbucketEventTypes.REPO_PUSH"
    [pusherName]="pushMessageData?.actor?.display_name"
    [description]="pushMsgDescription"
    [repositoryName]="pushMessageData?.repository?.full_name"
    [commitId]="headCommit?.hash"
    [commitUrl]="headCommit?.links?.html?.href"
    [commitToFullPath]="branchFullPath"
  ></app-webhook-push-message>

  <app-webhook-job-status
    *ngIf="
      webhookMessageData?.eventName === BitbucketEventTypes.REPO_COMMIT_STATUS_CREATED ||
      webhookMessageData?.eventName === BitbucketEventTypes.REPO_COMMIT_STATUS_UPDATED
    "
    [state]="statusLabel"
    [jobType]="commitJobStatusData?.commit_status?.type"
    [pipeName]="commitJobStatusData?.commit_status?.name"
    [pipeUrl]="commitJobStatusData?.commit_status?.url"
    [repoFullName]="commitJobStatusData?.repository?.full_name"
    [isFailed]="isJobFailed"
  ></app-webhook-job-status>
</div>
