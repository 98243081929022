<div
  class="modal-body poll"
  [class.poll-dark]="config?.layout.variant === 'Dark'"
  *transloco="let t"
>
  <ng-scrollbar
    [autoHeightDisabled]="false"
    visibility="hover"
    class="sidebar-tenant_scroll poll-scroll"
    thumbClass="white-scrollbars"
  >
    <h3 class="poll__title">{{ t('modals.poll.title-create-poll') }}</h3>
    <form [formGroup]="pollForm" class="pollForm">
      <div class="poll__name">
        <label for="question">{{ t('modals.poll.form-question') }}<span>*</span></label>
        <input
          t-id="poll-modal-question-name-input"
          id="question"
          [placeholder]="t('common.type-something-placeholder')"
          formControlName="question"
          tabindex="1"
          type="text"
          required
          class="form-control"
        />
      </div>

      <div class="poll__answer">
        <div class="poll__answer-input">
          <label>{{ t('modals.poll.form-answers') }}<span>*</span></label>
          <div class="poll__answer-input-wrapper">
            <input
              t-id="poll-modal-option-field-input"
              id="option"
              [placeholder]="t('common.type-something-placeholder')"
              tabindex="1"
              type="text"
              class="form-control"
              [formControl]="currentOption"
            />
            <button
              [disabled]="checkValidate"
              class="btn btn-subtle"
              t-id="poll-model-add-answer-button"
              (click)="pushOption()"
              (keyup.enter)="$event.stopPropagation(); pushOption()"
            >
              {{ t('common.btn-add') }}
            </button>
          </div>
          <div class="list-options" cdkDropList (cdkDropListDropped)="dragDropOption($event)">
            <div
              class="option"
              [class.optionDark]="config?.layout.variant === 'Dark'"
              *ngFor="let answer of answers"
              cdkDrag
              cdkDragLockAxis="y"
              [cdkDragStartDelay]="50"
            >
              <div class="option-name">{{ answer }}</div>
              <app-svg
                class="cursor-pointer cancel-icon svg-icon"
                name="times"
                width="10"
                height="10"
                aria-label="Close"
                (click)="delete(answer)"
              ></app-svg>
            </div>
          </div>
        </div>
      </div>

      <div class="poll__accepted">
        <div class="poll__accepted-box poll__accepted-anonymous">
          <div class="d-flex align-items-center">
            <input id="anonymous-poll" class="mr-2" type="checkbox" formControlName="anonymous" />
            <span>{{ t('modals.poll.form-anonymus-poll') }}</span>
          </div>
        </div>
        <div class="poll__accepted-box poll__accepted-multi">
          <div class="d-flex align-items-center">
            <input id="multi-poll" class="mr-2" type="checkbox" formControlName="multiChoice" />
            <span>{{ t('modals.poll.form-multi-choice-poll') }}</span>
          </div>
        </div>
        <div class="poll__accepted-box poll__accepted-result">
          <div class="d-flex align-items-center">
            <input id="result-poll" class="mr-2" type="checkbox" formControlName="showResult" />
            <span>{{ t('modals.poll.form-show-results-after-voting') }}</span>
          </div>
        </div>
        <div class="poll__accepted-box poll__accepted-deadline">
          <div class="d-flex align-items-center">
            <input id="result-deadline" class="mr-2" type="checkbox" formControlName="deadline" />
            <span>{{ t('modals.poll.form-deadline') }}</span>
          </div>
          <div class="poll__accepted-deadlinePicker" *ngIf="isDeadline">
            <label for="date">{{ t('modals.poll.form-due-date') }}<span>*</span></label>
            <app-date-time-picker
              [placeholder]="t('modals.poll.set-date-placeholder')"
              id="date"
              startOrEnd="start"
              formControlName="dueDate"
              class="date-picker"
              [withoutDefaultTime]="true"
              [allDayEvent]="true"
              [mobile]="platform === 'mobile'"
              [mobileCalendarEvent]="platform === 'mobile'"
              [tabIndexes]="['tab-index-day-date', 'tab-index-day-time']"
            ></app-date-time-picker>
            <div
              *ngIf="!isValidDate"
              t-id="login-form-invalid-tenant-alert"
              class="help-block mt-1 text-danger"
            >
              <i class="ft-alert-circle align-middle"></i>
              {{ t('modals.poll.form-wrong-date') }}
            </div>
          </div>
        </div>
        <div class="poll__accepted-box poll__accepted-remined">
          <div class="d-flex align-items-center">
            <input
              id="remined-poll"
              class="mr-2"
              type="checkbox"
              formControlName="reminder"
              [attr.disabled]="!isDeadline ? true : null"
              [class.disabledCheckbox]="!isDeadline"
            />
            <span>{{ t('modals.poll.form-set-reminder') }}</span>
          </div>
          <div class="poll__accepted-reminedData" *ngIf="isReminder">
            <div class="poll__accepted-reminedData-text">
              {{ t('modals.poll.nofity-me-before') }}
            </div>
            <ng-select
              class="poll__accepted-reminedData-select"
              bindLabel="label"
              bindValue="value"
              appendTo="body"
              [placeholder]="t('modals.poll.select-unit-placeholder')"
              [items]="reminderData"
              [clearable]="false"
              [searchable]="false"
              [closeOnSelect]="true"
              formControlName="reminderData"
            >
              <ng-template ng-label-tmp let-item="item" id="board-ticket-type-estimation">
                <div class="d-flex align-items-center justify-content-between w-100">
                  <span class="ticket-title">{{ item.label }}</span>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
    </form>
  </ng-scrollbar>
  <div class="poll__buttons">
    <button
      class="btn btn-subtle"
      t-id="poll-modal-cancel-button"
      type="button"
      (click)="closeModal()"
    >
      {{ t('common.btn-cancel') }}
    </button>
    <button
      [disabled]="isLoading || !this.pollForm.valid || !isOptionValid"
      class="btn btn-solid btn-save"
      t-id="poll-modal-create-button"
      (click)="submitForm()"
    >
      <i *ngIf="isLoading" class="fa fa-spinner fa-spin connection_spinner" aria-hidden="true"></i>
      {{ isLoading ? '' : t('common.btn-create') }}
    </button>
  </div>
</div>
