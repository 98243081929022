<app-text-message
  [message]="message"
  [mentionChatMembers]="mentionChatMembers"
  [context]="context"
  [platform]="platform"
></app-text-message>

<div
  class="d-flex flex-row uploaded-audio-file my-1"
  [ngClass]="{
    'uploaded-audio-file-light': config?.layout.variant === 'Light',
    'uploaded-audio-file-dark': config?.layout.variant === 'Dark'
  }"
>
  <vg-player *ngIf="!message.isAudioMessage" class="audio-player">
    <vg-controls class="audio-controls">
      <vg-play-pause
        class="audio-play-pause"
        [ngClass]="{
          'audio-play-pause-light': config?.layout.variant === 'Light',
          'audio-play-pause-dark': config?.layout.variant === 'Dark'
        }"
      ></vg-play-pause>

      <div
        *ngIf="message.isDownloadingFile"
        class="spinner-border audio-downloading-spinner"
        role="status"
      ></div>

      <div class="d-flex flex-column flex-grow-1">
        <div class="d-flex flex-row audio-time-wrapper mr-3">
          <div class="audio-file-name-wrapper">
            <span
              class="uploaded-file-name"
              [ngClass]="{
                'audio-name-light': config?.layout.variant === 'Light',
                'audio-name-dark': config?.layout.variant === 'Dark'
              }"
            >
              {{ filesHelper.getFileNameWithoutExtension(message.fileData.originalFileName) }}
            </span>
            <span
              class="file-extension"
              [ngClass]="{
                'audio-extension-light': config?.layout.variant === 'Light',
                'audio-extension-dark': config?.layout.variant === 'Dark'
              }"
            >
              .{{ filesHelper.getFileExtension(message.fileData.originalFileName) }}
            </span>
          </div>

          <div class="time-wrapper">
            <vg-time-display
              vgProperty="current"
              vgFormat="mm:ss"
              class="current-time"
              [ngClass]="{
                'audio-time-light': config?.layout.variant === 'Light',
                'audio-time-dark': config?.layout.variant === 'Dark'
              }"
            ></vg-time-display>
            <div
              [ngClass]="{
                'time-divider-light': config?.layout.variant === 'Light',
                'time-divider-dark': config?.layout.variant === 'Dark'
              }"
            >
              /
            </div>
            <vg-time-display
              vgProperty="total"
              vgFormat="mm:ss"
              [ngClass]="{
                'audio-time-light': config?.layout.variant === 'Light',
                'audio-time-dark': config?.layout.variant === 'Dark'
              }"
            ></vg-time-display>
          </div>
        </div>

        <div class="d-flex flex-row mt-2">
          <vg-scrub-bar class="scrub-bar">
            <vg-scrub-bar-current-time
              [ngClass]="{
                'audio-scrub-bar-current-time-light': config?.layout.variant === 'Light',
                'audio-scrub-bar-current-time-dark': config?.layout.variant === 'Dark'
              }"
            ></vg-scrub-bar-current-time>
            <vg-scrub-bar-buffering-time
              [class.audio-scrub-bar-buffering-time]="config?.layout.variant === 'Light'"
            ></vg-scrub-bar-buffering-time>
          </vg-scrub-bar>
        </div>
      </div>
    </vg-controls>

    <!-- TODO(David): avoid using $any() -->
    <audio #media [vgMedia]="$any(media)" id="myAudio" preload="metadata">
      <source [src]="message.fileData.url" />
    </audio>
  </vg-player>

  <div *ngIf="message.isAudioMessage" class="d-flex">
    <div
      [ngClass]="{
        'audio-message-play-pause-light': config?.layout.variant === 'Light',
        'audio-message-play-pause-dark': config?.layout.variant === 'Dark'
      }"
      (click)="isReadyAudioMessage && playPauseAudioMessage()"
    >
      <i
        *ngIf="!AudioMessageState"
        aria-hidden="true"
        class="fa fa-play"
        [ngClass]="{
          'audio-message-play-icon-light': config?.layout.variant === 'Light',
          'audio-message-play-icon-dark': config?.layout.variant === 'Dark'
        }"
      ></i>
      <i
        *ngIf="AudioMessageState"
        aria-hidden="true"
        class="fa fa-pause"
        [ngClass]="{
          'audio-message-pause-icon-light': config?.layout.variant === 'Light',
          'audio-message-pause-icon-dark': config?.layout.variant === 'Dark'
        }"
      ></i>
    </div>

    <div
      *ngIf="message.isDownloadingFile"
      class="spinner-border audio-downloading-spinner"
      role="status"
    ></div>

    <i
      *ngIf="!message.isDownloadingFile"
      aria-hidden="true"
      class="fa fa-microphone"
      [ngClass]="{
        'microphone-icon-audio-message-light': config?.layout.variant === 'Light',
        'microphone-icon-audio-message-dark': config?.layout.variant === 'Dark'
      }"
    ></i>

    <div id="waveform-{{ message._id }}-{{ context }}" class="wave-wrapper"></div>
  </div>
</div>
