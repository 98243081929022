import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ColumnActionEnum } from '../../enums/column-action.enum';

interface ColumnAction {
  type: ColumnActionEnum;
  // TODO: Put here column type after integration with back-end
  payload?: any;
}

@Injectable({
  providedIn: 'root',
})
export class ColumnService {
  private clickOnActionItem = new Subject();
  public clickOnActionItem$ = this.clickOnActionItem.asObservable();

  /* Methods */
  public broadcastClickOnActionItem(menuItem: ColumnAction): void {
    this.clickOnActionItem.next(menuItem);
  }
}
