<div
  [formGroup]="form"
  *ngIf="
    fieldConfig.isVisible &&
    ((fieldConfig.title === 'Parent' &&
      !childrenList?.length &&
      (ticketSelectParent | async)?.length) ||
      fieldConfig.title === 'Epics' ||
      fieldConfig.title === 'Assignees')
  "
>
  <ng-container *transloco="let t; read: 'modals.custom-fields'">
    <legend>{{ displayName }} <span *ngIf="fieldConfig.isMandatory">*</span></legend>
    <fieldset
      *ngIf="fieldConfig.title === 'Assignees'"
      class="form-group position-relative align-items-center"
      [class.margin-null]="fieldConfig.title === 'Assignees' && modalData?.isNeedToSelectObject"
    >
      <ng-select
        t-id="board-ticket-assignees"
        id="ticketAssignees"
        class="assignees-select"
        name="ticketMembers"
        placeholder="{{ t('placeholder-select-members') }}"
        bindLabel="userName"
        bindValue="_id"
        [multiple]="true"
        [(ngModel)]="modalData.selectedMembersIds"
        [items]="users$ | async"
        [formControlName]="fieldConfig.title"
        [notFoundText]="t('placeholder-select-not-found')"
        [attr.data-tabindex]="tabIndexes[fieldConfig.order]?.tabIndex"
        (click)="setFocusedControl(tabIndexes[fieldConfig.order]?.tabIndex)"
        (focus)="inputFocused('ticketAssignees')"
      >
        <ng-template ng-label-tmp let-item="item" let-clear="clear" id="guests_selected">
          <div class="d-flex align-items-center">
            <app-avatar
              [userId]="item._id"
              [height]="24"
              [width]="24"
              [statusSize]="9"
            ></app-avatar>
            <div class="ml-1">
              {{ item.userName }}
            </div>
            <div class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</div>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" id="guests_select">
          <div class="d-flex align-items-center" t-id="board-ticket-assignees-select">
            <app-avatar
              [userId]="item._id"
              [height]="24"
              [width]="24"
              [statusSize]="9"
            ></app-avatar>
            <div class="ml-2">
              <b>{{ item.userName }}</b>
            </div>
          </div>
        </ng-template>
      </ng-select>
    </fieldset>
    <fieldset
      *ngIf="fieldConfig.title === 'Parent' || fieldConfig.title === 'Epics'"
      class="position-relative mb-2"
    >
      <ng-select
        *ngIf="
          (fieldConfig.title === 'Parent' &&
            !childrenList?.length &&
            (ticketSelectParent | async)?.length) ||
          fieldConfig.title === 'Epics'
        "
        t-id="board-ticket-set-parent-ticket-epics-1"
        t-id="board-ticket-set-parent-ticket"
        id="ticketParentTicket"
        class="ticket-select"
        [class.conflicted]="parentIdIsConflicted"
        [class.withoutErrorMessage]="!errorMessage"
        name="parentId"
        placeholder="{{ t('placeholder-select-ticket') }}"
        bindLabel="counter"
        bindValue="_id"
        [searchable]="true"
        [clearable]="true"
        [items]="(ticketSelectParent | async)?.length ? (ticketSelectParent | async) : epicsList"
        [formControlName]="fieldConfig.title"
        [class.ticket-selected]="form.controls[fieldConfig.title].value"
        [attr.data-tabindex]="tabIndexes[8]?.tabIndex"
        [notFoundText]="t('placeholder-select-not-found')"
        (click)="setFocusedControl(tabIndexes[8]?.tabIndex)"
        (focus)="inputFocused('ticketParentTicket')"
      >
        <ng-template ng-label-tmp let-item="item" let-clear="clear" id="ticket_selected">
          <div
            class="d-flex align-items-center justify-content-between"
            ngbTooltip="{{ item.title }}"
            container="body"
            placement="top"
          >
            <div class="parent-task">
              <span class="ticket-number">{{ item?.boardAbbreviation }}-{{ item?.counter }}</span>
              <span class="ticket-title" t-id="select-link-select-parent-ticket-epics">{{
                item.title
              }}</span>
            </div>
            <svg-icon
              src="assets/icons/common/unlink.svg"
              class="actions svg-icon clear-btn"
              [class.dark]="config.layout.variant === 'Dark'"
              [svgStyle]="{ 'width.px': '20', 'height.px': '20' }"
              (click)="clear(item)"
            ></svg-icon>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" id="ticket_select">
          <div class="parent-task" [class.conflicted]="item._id === parentIdIsConflicted">
            <span class="ticket-number">{{ item.boardAbbreviation }}-{{ item.counter }}</span>
            <span class="ticket-title" t-id="select-link-set-parent-ticket-epics">{{
              item.title
            }}</span>
          </div>
        </ng-template>
      </ng-select>
      <span *ngIf="errorMessage" class="parent-error">{{ t('wrong-parent-ticket') }}</span>
    </fieldset>
    <span
      class="copy-ticket-select"
      *ngIf="fieldConfig.title === 'Assignees' && modalData?.isNeedToSelectObject"
      >{{ t('select-new-assignees') }}</span
    >
  </ng-container>
</div>
