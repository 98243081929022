import { Injectable } from '@angular/core';
import RecordRTC from 'recordrtc';
import { invokeSaveAsDialog, MediaStreamRecorder, StereoAudioRecorder } from 'recordrtc';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { ScreenPickerModalComponent } from '../../modals/screen-picker/screen-picker-modal.component';
import { Store } from '@ngxs/store';
import { BoardsState } from '../store/states/boards.state';
import { TicketsDbDto } from '../../api/models/tickets-db-dto';
import { ToastrService } from 'ngx-toastr';
import { ThreadsUploadFile } from '../store/actions/threads.action';
import { Message } from '../components/chat/chat.model';
import { WikiPageDbDto } from '../../api/models/wiki-page-db-dto';

export enum RecordStartedFrom {
  Navbar = 'Navbar',
  Chat = 'Chat',
  Thread = 'Thread',
  Ticket = 'Ticket',
  Wiki = 'wiki-pages',
}

export enum RecordType {
  Video = 'video',
  Audio = 'audio',
}

export interface DesktopCapturerSource {
  id: string;
  name: string;
  thumbnail: any;
  display_id: string;
  appIcon: any;
  imgUrl: string;
}

const recordConfig = {
  video: {
    type: 'video',
    mimeType: 'video/webm;codecs=vp9',
    recorderType: MediaStreamRecorder,
  } as RecordRTC.Options,
  audio: {
    type: 'audio',
    mimeType: 'audio/webm;codecs=pcm',
    recorderType: StereoAudioRecorder,
    numberOfAudioChannels: 1,
    desiredSampRate: 16000,
    bufferSize: 16384,
  } as RecordRTC.Options,
};

export interface TicketRecordData extends TicketsDbDto {
  chatMessage: Message;
}

@Injectable({
  providedIn: 'root',
})
export class RecordService {
  recordStream: MediaStream;
  recorder: RecordRTC;
  isRecording: boolean;
  recordType: RecordType;
  recordedFile: Blob | null;
  recordDuration$: BehaviorSubject<string> = new BehaviorSubject<string>('00:00');
  isRecordInProgress$: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  recordStartedFrom$: BehaviorSubject<RecordStartedFrom | null> =
    new BehaviorSubject<RecordStartedFrom | null>(null);
  recordObjectId$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>('');
  recordTimer: any;
  isRecordInChat: boolean;
  isRecordWithMic: boolean;
  isMacOS: boolean;
  ticketRecordData: TicketRecordData;
  wikiRecordData: WikiPageDbDto;

  constructor(
    private modalService: NgbModal,
    private store: Store,
    private toastr: ToastrService,
    private translocoService: TranslocoService,
  ) {}

  get recordObjectId(): Observable<string | null> {
    return this.recordObjectId$.asObservable();
  }

  get recordDuration(): Observable<string> {
    return this.recordDuration$.asObservable();
  }

  get isRecordInProgress(): Observable<boolean | null> {
    return this.isRecordInProgress$.asObservable();
  }

  get recordStartedFrom(): Observable<string | null> {
    return this.recordStartedFrom$.asObservable();
  }

  startScreenRecord(
    isRecordWithMic: boolean,
    recordStartedFrom: RecordStartedFrom,
    objectId?: string,
  ): void {
    this.detectPlatform();
    this.isRecordWithMic = isRecordWithMic;
    this.recordType = RecordType.Video;
    this.prepareVideoStream(recordStartedFrom, objectId);
  }

  startAudioRecord(recordStartedFrom: RecordStartedFrom, objectId: string): void {
    this.recordType = RecordType.Audio;
    this.isRecordWithMic = true;
    this.detectPlatform();
    this.prepareAudioStream(recordStartedFrom, objectId);
  }

  prepareVideoStream(recordStartedFrom: RecordStartedFrom, objectId?: string): void {
    navigator.mediaDevices
      // @ts-ignore
      .getDisplayMedia({
        video: true,
        audio: true,
      })
      .then(async (videoStream: MediaStream | DesktopCapturerSource[]) => {
        if (Array.isArray(videoStream)) {
          await this.mediaStreamsHandler(videoStream)
            .then((source) => {
              videoStream = source;
              this.prepareAudioStream(recordStartedFrom, objectId, videoStream);
            })
            .catch((err) => {
              catchError(err);
              this.recordErrorHandler();
              return;
            });
        } else {
          this.prepareAudioStream(recordStartedFrom, objectId, videoStream);
        }
      })
      .catch((err) => {
        this.recordErrorHandler();
        catchError(err);
      });
  }

  prepareAudioStream(
    recordStartedFrom: RecordStartedFrom,
    objectId?: string,
    videoStream?: MediaStream,
  ): void {
    if (this.isRecordWithMic) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then((audioStream: MediaStream) => {
          this.startRecording(
            this.recordType === RecordType.Audio
              ? audioStream
              : new MediaStream([
                  ...videoStream?.getVideoTracks(),
                  ...audioStream.getAudioTracks(),
                ]),
            recordStartedFrom,
            objectId,
          );
          videoStream?.getAudioTracks().forEach((track) => track.stop());
        })
        .catch((err) => {
          catchError(err);
          this.startRecording(videoStream as MediaStream, recordStartedFrom, objectId);
        });
    } else {
      this.startRecording(videoStream, recordStartedFrom, objectId);
    }
  }

  startRecording(stream: MediaStream, recordStartedFrom: RecordStartedFrom, objectId?: string) {
    this.recorder = new RecordRTC(stream, recordConfig[this.recordType]);
    this.recordStream = stream;
    this.recorder.startRecording();
    this.setEndStreamListener(stream.getVideoTracks()[0]);
    this.onRecordStart(recordStartedFrom, objectId);
  }

  onRecordStart(recordStartedFrom: RecordStartedFrom, objectId?: string): void {
    this.isRecording = true;
    this.isRecordInProgress$.next(true);
    this.recordObjectId$.next(objectId);
    this.recordStartedFrom$.next(recordStartedFrom);
    this.setRecordTimer();
  }

  stopRecording(): Promise<Blob> {
    this.onRecordStop();
    return new Promise<Blob>((resolve) => {
      this.recorder?.stopRecording(() => {
        if (this.recordType === RecordType.Video) {
          if (this.isMacOS) {
            this.recordedFile = this.recorder.getBlob();
            resolve(this.recordedFile);
          } else {
            this.recordedFile = this.recorder.getBlob();
            resolve(this.recordedFile);
          }
        } else {
          resolve(this.recorder.getBlob());
        }

        this.recordStream.getTracks().forEach((track) => {
          track.stop();
        });
      });
    });
  }

  onRecordStop(): void {
    clearInterval(this.recordTimer);
    this.recordObjectId$.next('');
    this.recordErrorHandler();
  }

  mediaStreamsHandler(streams: DesktopCapturerSource[]): Promise<MediaStream> {
    return new Promise<MediaStream>((resolve, reject) => {
      const modalRef = this.modalService.open(ScreenPickerModalComponent, {
        backdrop: 'static',
        keyboard: false,
        size: 'lg',
      });
      modalRef.componentInstance.streams = streams;
      modalRef.componentInstance.mediaStream.subscribe((source) => {
        source ? resolve(source) : reject(source);
        modalRef.close();
      });
    });
  }

  detectPlatform(): void {
    this.isMacOS = navigator.platform.includes('Mac');
  }

  downloadRecord(): void {
    invokeSaveAsDialog(this.recordedFile);
    this.clearRecord();
  }

  setEndStreamListener(stream: MediaStreamTrack): void {
    stream?.addEventListener('ended', () => {
      this.stopRecording();
    });
  }

  clearRecord(): void {
    this.recordedFile = null;
    this.recordDuration$.next('00:00');
  }

  setRecordTimer(): void {
    let seconds;
    let minutes;
    let recordTime = 1;
    this.recordTimer = setInterval(() => {
      seconds = Math.floor(recordTime % 60);
      minutes = Math.floor((recordTime / 60) % 60);
      this.recordDuration$.next(('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2));
      recordTime++;
    }, 1000);
  }

  recordErrorHandler(): void {
    this.isRecording = false;
    this.isRecordInChat = false;
    this.isRecordInProgress$.next(false);
    this.recordStartedFrom$.next(null);
  }

  getTicketData(): void {
    this.ticketRecordData = this.store.selectSnapshot(BoardsState.getTicket) as TicketRecordData;
  }

  sendFileToThread(): void {
    const toast = this.toastr.info(
      this.translocoService.translate('toastr.record-saving-started'),
      this.translocoService.translate('toastr.title-info'),
      {
        disableTimeOut: true,
        positionClass: 'toast-top-right',
      },
    );

    let extension = 'wav';
    if (this.recordType === RecordType.Video) {
      if (this.recordedFile.type.includes('video/mp4')) {
        extension = 'mp4';
      } else if (this.recordedFile.type.includes('video/webm')) {
        extension = 'webm';
      }
    }

    const payload = {
      isAudioMessage: false,
      toastId: toast.toastId,
      body: {
        threadId: this.ticketRecordData.chatMessage?.threadId,
        messageId: this.ticketRecordData.chatMessage?._id,
        linkObject: 'tickets',
        linkObjectId: this.ticketRecordData._id,
        file: new File([this.recordedFile], `${new Date().getTime()}.${extension}`),
      },
    };

    const ticketTitle = `${this.ticketRecordData.boardAbbreviation} - ${this.ticketRecordData.counter}`;

    this.store.dispatch(new ThreadsUploadFile(payload)).subscribe(
      () => {
        this.toastr.clear(toast.toastId);
        this.toastr.success(
          this.translocoService.translate('toastr.record-successfully-saved'),
          ticketTitle,
        );
        this.clearRecord();
      },
      (err) => {
        this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
      },
    );
  }

  sendFileWiki(): void {
    const toast = this.toastr.info(
      this.translocoService.translate('toastr.record-saving-started'),
      this.translocoService.translate('toastr.title-info'),
      {
        disableTimeOut: true,
        positionClass: 'toast-top-right',
      },
    );

    let extension = 'wav';
    if (this.recordType === RecordType.Video) {
      if (this.recordedFile.type.includes('video/mp4')) {
        extension = 'mp4';
      } else if (this.recordedFile.type.includes('video/webm')) {
        extension = 'webm';
      }
    }

    const payload = {
      isAudioMessage: false,
      toastId: toast.toastId,
      body: {
        threadId: this.wikiRecordData.chatMessage?.threadId,
        messageId: this.wikiRecordData.chatMessage?._id,
        linkObject: 'wiki-pages',
        linkObjectId: this.wikiRecordData._id,
        file: new File([this.recordedFile], `${new Date().getTime()}.${extension}`),
      },
    };

    this.store.dispatch(new ThreadsUploadFile(payload)).subscribe(
      () => {
        this.toastr.clear(toast.toastId);
        this.clearRecord();
      },
      (err) => {
        this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
      },
    );
  }
}
