<!--Calendar Starts-->
<section
  id="calendar"
  class="calendar-scroll"
  t-id="calendar-page"
  [class.with-sub-navbar]="withSubNavbar"
  *transloco="let t; read: 'calendar'"
>
  <div class="card m-0">
    <div class="card-content">
      <div
        #scrollableContainer
        [class.cal-scroll-container-dash]="object === 'users'"
        class="card-body cal-scroll-container position-relative"
      >
        <div
          class="d-sm-flex justify-content-between align-items-center gap-1 calendar-nav-tab"
          [ngClass]="{
            'header-dark': themeMode === 'Dark',
            'header-light': themeMode === 'Light',
          }"
        >
          <div class="text-center sm:text-left mb-2 sm:mb-0 flex flex-row" *ngIf="calendarTab">
            <div class="btn-group d-none d-lg-block flex-grow">
              <a
                class="btn btn-solid"
                mwlCalendarPreviousView
                [view]="view"
                [(viewDate)]="viewDate"
              >
                {{ t('btn-previous') }}
              </a>
              <a class="btn btn-danger" mwlCalendarToday [(viewDate)]="viewDate">
                {{ t('btn-today') }}
              </a>
              <a class="btn btn-solid" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate">
                {{ t('btn-next') }}
              </a>
            </div>

            <div class="btn-group d-block d-lg-none">
              <a
                class="btn btn-solid ft-chevron-left"
                mwlCalendarPreviousView
                [view]="view"
                [(viewDate)]="viewDate"
              >
                <i class="icon icon-arrows-left"></i>
              </a>
              <a class="btn btn-danger ft-calendar" mwlCalendarToday [(viewDate)]="viewDate">
                <i class="icon icon-arrows-sign-down"></i>
              </a>
              <a
                class="btn btn-solid ft-chevron-right"
                mwlCalendarNextView
                [view]="view"
                [(viewDate)]="viewDate"
              >
                <i class="icon icon-arrows-right"></i>
              </a>
            </div>
          </div>

          <div class="calendar-actions flex items-center gap-2 w-full">
            <div class="flex flex-row gap-1 items-center">
              <div
                appTippy
                #tippy="appTippy"
                [popoverContent]="dropdownMenu"
                [popoverPlacement]="'bottom-start'"
                (click)="$event.stopPropagation(); showAddEventOptions = !showAddEventOptions"
                class="dropdown actions-dropdown ml-1 w-[120px]"
              >
                <div
                  class="flex flex-row gap-2 items-center justify-between border rounded-md px-2 py-1 mx-auto cursor-pointer hover:bg-secondary-100 dark:hover:bg-secondary-800 dark:border-secondary-700 dark:text-white"
                  [ngClass]="{
                    'rounded-b-none bg-secondary-100 dark:bg-secondary-800': showAddEventOptions,
                  }"
                >
                  <div class="flex flex-row gap-2 items-center">
                    <img
                      src="assets/img/small-logo.png"
                      class="w-[20px] aspect-square select-none pointer-events-none"
                      alt="logo"
                    />
                    <span class="font-semibold select-none">
                      {{ t('btn-create') }}
                    </span>
                  </div>
                  <i
                    class="ft-chevron-down transition-transform duration-200 text-lg text-secondary-600"
                    [ngClass]="{
                      '-rotate-180': showAddEventOptions,
                    }"
                  ></i>
                </div>
                <ul
                  #dropdownMenu
                  class="text-center bg-white dark:bg-secondary-800 w-[120px] divide-y dark:divide-secondary-700 rounded-md rounded-t-none border border-t-0 dark:border-secondary-700"
                >
                  <li
                    *ngFor="let option of addEventOptions"
                    class="px-2 py-1 cursor-pointer hover:bg-secondary-100 dark:bg-secondary-900 dark:hover:bg-secondary-850 dark:text-white"
                    (click)="
                      selectAddEventOption(option); showAddEventOptions = false; tippy.close()
                    "
                  >
                    {{ option }}
                  </li>
                </ul>
              </div>

              <hr class="border h-[20px] mx-1" />

              <button
                type="button"
                class="btn btn-calendar btn-blank"
                (click)="resetCurrentDateView()"
              >
                <span>{{ t('btn-today') }}</span>
              </button>
            </div>
            <div class="flex flex-row gap-2 items-center">
              <div *ngIf="!hideSearchInput" class="search-container d-flex align-items-center">
                <input
                  class="form-control search-field"
                  type="text"
                  [placeholder]="'&#xE8BD;&nbsp;' + t('search')"
                  [(ngModel)]="searchQuery"
                  (ngModelChange)="filterEventsByQuery($event)"
                />
                <span
                  *ngIf="searchQuery?.length > 0"
                  class="d-flex justify-content-end align-items-center"
                  (click)="filterEventsByQuery('')"
                >
                  <app-svg
                    name="times-circle"
                    class="cursor-pointer"
                    [width]="14"
                    [height]="14"
                  ></app-svg>
                </span>
              </div>
              <ng-select
                *ngIf="isPersonalSpace && hideSearchInput"
                bindLabel="title"
                bindValue="id"
                [placeholder]="t('calendars-select-placeholder')"
                class="calendar-select"
                [items]="calendars"
                [multiple]="true"
                [clearable]="false"
                [searchable]="false"
                [closeOnSelect]="false"
                [selectableGroup]="true"
                [selectableGroupAsModel]="true"
                [(ngModel)]="checkedCalendars"
                (change)="changeSelectedCalendars()"
              >
                <ng-template ng-multi-label-tmp let-items="items">
                  <span class="ng-placeholder d-flex align-items-center">
                    {{ t('calendars-select-placeholder') }}
                    <span class="primary-circle calendar-filters-circle ml-1">
                      {{ checkedCalendars.length }} /
                      {{ calendars.length }}
                    </span>
                  </span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input
                    id="item-{{ index }}"
                    type="checkbox"
                    [ngModel]="item$.selected"
                    class="mr-2"
                    [class.ml-2]="item.spaceId"
                  />
                  <span>{{ item.title }}</span>
                </ng-template>
              </ng-select>
              <ng-select
                bindValue="value"
                [placeholder]="t('calendars-select-show-tickets-placeholder')"
                class="calendar-select mr-2"
                [items]="show"
                [multiple]="true"
                [clearable]="false"
                [searchable]="false"
                [closeOnSelect]="false"
                [selectableGroup]="true"
                [selectableGroupAsModel]="true"
                [(ngModel)]="elementsShown"
                (ngModelChange)="callPrepareEventsView()"
              >
                <ng-template ng-multi-label-tmp let-items="items">
                  <span class="ng-placeholder d-flex align-items-center">
                    {{ t('calendars-select-show-tickets-placeholder') }}
                    <span class="primary-circle calendar-filters-circle ml-1">
                      {{ elementsShown.length }} /
                      {{ show.length }}
                    </span>
                  </span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input
                    id="item-{{ index }}"
                    type="checkbox"
                    [ngModel]="item$.selected"
                    class="mr-2"
                  />
                  <span>{{ item.label }}</span>
                </ng-template>
              </ng-select>

              <div class="calendar-include-weekends custom-control custom-switch switch-sm">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="weekendsSwitch"
                  #weekendsCheckbox
                  [checked]="!excludeDays?.length"
                  (change)="changeIncludeWeekends(weekendsCheckbox.checked)"
                />
                <label
                  class="custom-control-label"
                  for="weekendsSwitch"
                  t-id="calendar-include-weekends-switch"
                >
                  {{ t('form-weekends-switch') }}
                </label>
              </div>
              <div *ngIf="isPersonalSpace" class="calendar-toggle" (click)="toggleSearchInput()">
                <span *ngIf="hideSearchInput">&#xE8BD;</span>
                <app-svg
                  *ngIf="!hideSearchInput"
                  name="times"
                  width="12"
                  height="12"
                  aria-hidden="true"
                >
                </app-svg>
              </div>

              <ng-select
                t-id="board-actionbar-view"
                class="calendar-select mx-2"
                [items]="views"
                bindLabel="label"
                bindValue="value"
                [ngModel]="view"
                [clearable]="false"
                [searchable]="false"
                [closeOnSelect]="true"
                (change)="changeView($event.value)"
              ></ng-select>
            </div>
          </div>

          <div class="calendar-date d-flex align-items-center text-center !border-r-0">
            <div class="calendar-period" [class.calendar-period_width]="view !== 'month'">
              <span *ngIf="view === 'week'">
                {{ startOfWeek(viewDate, { weekStartsOn: 1 }) | date: 'd' }} -
                {{ endOfWeek(viewDate, { weekStartsOn: 1 }) | date: 'd' }}
              </span>
              <span *ngIf="view === 'day'">{{ viewDate | date: 'EEE d' }}</span>
              <span>
                {{ moment(viewDate, 'MMM', lang, false).format('MMM') }}
                {{ viewDate | date: 'y' }}</span
              >
            </div>
            <div class="date-switch-arrows">
              <button class="btn-calendar-arrow">
                <app-svg
                  name="long-arrow-left"
                  mwlCalendarPreviousView
                  [width]="16"
                  [height]="10"
                  [view]="view"
                  [(viewDate)]="viewDate"
                  [hoverEffect]="true"
                  (click)="changeCalendarPeriod()"
                >
                </app-svg>
              </button>
              <button class="btn-calendar-arrow">
                <app-svg
                  name="long-arrow-right"
                  mwlCalendarNextView
                  [width]="16"
                  [height]="10"
                  [view]="view"
                  [(viewDate)]="viewDate"
                  [hoverEffect]="true"
                  (click)="changeCalendarPeriod()"
                >
                </app-svg>
              </button>
            </div>
          </div>
        </div>

        <ng-template #all_day>
          <div class="all-day-events-label">
            <span>{{ t('all-day-events-label') }}</span>
          </div>
        </ng-template>

        <!-- Weekly View Cell Template -->
        <ng-template
          #weekHourSegmentTemplate
          let-segment="segment"
          let-segmentHeight="segmentHeight"
          let-isTimeLabel="isTimeLabel"
          let-daysInWeek="daysInWeek"
        >
          <div
            [attr.aria-hidden]="
              {} | calendarA11y: (daysInWeek === 1 ? 'hideDayHourSegment' : 'hideWeekHourSegment')
            "
            class="cal-hour-segment"
            [style.height.px]="segmentHeight"
            [class.cal-hour-start]="segment.isStart"
            [class.cal-after-hour-start]="!segment.isStart"
            [ngClass]="segment.cssClass"
            [attr.data-event-date]="segment.date"
          >
            <div class="cal-time" *ngIf="isTimeLabel">
              {{ segment.displayDate | date: 'H:mm' }}
            </div>
          </div>
        </ng-template>

        <!-- Weekly Header Template -->
        <ng-template
          #weekHeaderTemplate
          let-days="days"
          let-dayHeaderClicked="dayHeaderClicked"
          let-eventDropped="eventDropped"
          let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate"
          let-dragEnter="dragEnter"
        >
          <div class="cal-day-headers" role="row">
            <div
              class="cal-header"
              *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
              [class.cal-past]="day.isPast"
              [class.cal-today]="day.isToday"
              [class.cal-future]="day.isFuture"
              [class.cal-weekend]="day.isWeekend"
              [ngClass]="day.cssClass"
              (mwlClick)="dayHeaderClicked.emit({ day: day, sourceEvent: $event })"
              mwlDroppable
              dragOverClass="cal-drag-over"
              (drop)="
                eventDropped.emit({
                  event: $event.dropData.event,
                  newStart: day.date,
                })
              "
              (dragEnter)="dragEnter.emit({ date: day.date })"
              tabindex="0"
              role="columnheader"
            >
              <span class="cal-header-month">{{
                moment(day.date, 'ddd', lang).format('ddd')
              }}</span>
              <span class="cal-header-day">{{ day.date | date: 'd' }}</span>
            </div>
          </div>
        </ng-template>

        <!-- Month Header Template -->
        <ng-template
          #monthHeaderTemplate
          let-days="days"
          let-locale="locale"
          let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate"
        >
          <div class="cal-cell-row cal-header">
            <div
              class="cal-cell"
              *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
              [class.cal-past]="day.isPast"
              [class.cal-today]="day.isToday"
              [class.cal-future]="day.isFuture"
              [class.cal-weekend]="day.isWeekend"
              [ngClass]="day.cssClass"
            >
              {{ moment(day.date, 'ddd', lang).format('ddd') }}
            </div>
          </div>
        </ng-template>

        <!-- Monthly View Cell Template -->
        <ng-template
          #cellTemplate
          let-day="day"
          let-openDay="openDay"
          let-locale="locale"
          let-tooltipPlacement="tooltipPlacement"
          let-highlightDay="highlightDay"
          let-unhighlightDay="unhighlightDay"
          let-eventClicked="eventClicked"
          let-tooltipTemplate="tooltipTemplate"
          let-tooltipAppendToBody="tooltipAppendToBody"
          let-tooltipDelay="tooltipDelay"
          let-trackByEventId="trackByEventId"
          let-validateDrag="validateDrag"
        >
          <div
            class="cal-cell-top"
            [attr.aria-label]="{ day: day, locale: locale } | calendarA11y: 'monthCell'"
            [dragula]="notesContainer"
            [attr.data-event-date]="day.date"
          >
            <span aria-hidden="true">
              <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
              <span class="cal-day-number">{{
                day.date | calendarDate: 'monthViewDayNumber' : locale
              }}</span>
            </span>
          </div>
          <div class="cal-events" *ngIf="day.events.length > 0">
            <div
              class="cal-event"
              *ngFor="let event of day.events; trackBy: trackByEventId"
              [ngStyle]="{ backgroundColor: event.color?.primary }"
              [ngClass]="event?.cssClass"
              (mouseenter)="highlightDay.emit({ event: event })"
              (mouseleave)="unhighlightDay.emit({ event: event })"
              [mwlCalendarTooltip]="event.title | calendarEventTitle: 'monthTooltip' : event"
              [tooltipPlacement]="tooltipPlacement"
              [tooltipEvent]="event"
              [tooltipTemplate]="tooltipTemplate"
              [tooltipAppendToBody]="tooltipAppendToBody"
              [tooltipDelay]="tooltipDelay"
              mwlDraggable
              [class.cal-draggable]="event.draggable"
              dragActiveClass="cal-drag-active"
              [dropData]="{ event: event, draggedFrom: day }"
              [dragAxis]="{ x: event.draggable, y: event.draggable }"
              [validateDrag]="validateDrag"
              [touchStartLongPress]="{ delay: 300, delta: 30 }"
              (mwlClick)="eventClicked.emit({ event: event, sourceEvent: $event })"
              [attr.aria-hidden]="{} | calendarA11y: 'hideMonthCellEvents'"
            ></div>
          </div>
        </ng-template>

        <ng-template #eventTemplate let-event="event" let-view="view">
          <span class="cal-event-title">
            <app-svg
              class="cal-event-img"
              height="12"
              width="{{ event.videoCallId ? '14' : '12' }}"
              name="{{ event.videoCallId ? 'video' : 'file-check' }}"
            >
            </app-svg>
            {{ event.title | calendarEventTitle: view : event }}
          </span>
          <div class="cal-event-time">
            @if (event.subtype === 'start') {
              {{ event.start | date: 'H:mm' }}
            } @else if (event.subtype === 'end') {
              {{ event.end | date: 'H:mm' }}
            } @else {
              {{ event.start | date: 'H:mm' }} - {{ event.end | date: 'H:mm' }}
            }
          </div>
        </ng-template>

        <div [ngSwitch]="view">
          <mwl-calendar-month-view
            *ngSwitchCase="'month'"
            [viewDate]="viewDate"
            [events]="groupedMonthViewEvents"
            [refresh]="eventRefresh"
            [activeDayIsOpen]="activeDayIsOpen"
            [cellTemplate]="cellTemplate"
            [excludeDays]="excludeDays"
            [weekStartsOn]="1"
            (dayClicked)="dayClicked($event.day)"
            (eventClicked)="viewEvent($event.event)"
            [headerTemplate]="monthHeaderTemplate"
            [eventTitleTemplate]="eventTemplate"
            [cellTemplate]="monthEvent"
          >
          </mwl-calendar-month-view>
          <mwl-calendar-week-view
            t-id="calendar-list-items"
            *ngSwitchCase="'week'"
            [viewDate]="viewDate"
            [events]="weekViewEvents"
            [refresh]="eventRefresh"
            [hourSegments]="1"
            [hourSegmentHeight]="81"
            [excludeDays]="excludeDays"
            [weekStartsOn]="1"
            (dayHeaderClicked)="dayClicked($event.day)"
            (hourSegmentClicked)="hourClicked($event)"
            (eventClicked)="viewEvent($event.event)"
            [allDayEventsLabelTemplate]="all_day"
            [hourSegmentTemplate]="weekHourSegmentTemplate"
            [headerTemplate]="weekHeaderTemplate"
            [eventTitleTemplate]="eventTemplate"
          >
          </mwl-calendar-week-view>
          <mwl-calendar-day-view
            *ngSwitchCase="'day'"
            [viewDate]="viewDate"
            [events]="dayViewEvents"
            [refresh]="eventRefresh"
            [hourSegments]="2"
            [hourSegmentHeight]="40"
            (eventClicked)="viewEvent($event.event)"
            (hourSegmentClicked)="hourClicked($event)"
          >
          </mwl-calendar-day-view>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template
  #monthEvent
  let-day="day"
  let-openDay="openDay"
  let-locale="locale"
  let-tooltipPlacement="tooltipPlacement"
  let-highlightDay="highlightDay"
  let-unhighlightDay="unhighlightDay"
  let-eventClicked="eventClicked"
  let-tooltipTemplate="tooltipTemplate"
  let-tooltipAppendToBody="tooltipAppendToBody"
  let-tooltipDelay="tooltipDelay"
  let-trackByEventId="trackByEventId"
  let-validateDrag="validateDrag"
>
  <div class="cal-cell-top flex justify-center">
    <span class="cal-day-number">{{ day.date | calendarDate: 'monthViewDayNumber' : locale }}</span>
  </div>
  <div *ngIf="day.events.length > 0">
    <div *ngFor="let event of day.events; trackBy: trackByEventId">
      <div
        [ngClass]="{
          'bg-[#EAF4FF] border-2 border-[#2E90FA]': event.meta.eventType === 'tasks',
          'bg-[#CD56FF] text-white':
            event.meta.eventType !== 'tasks' && event.meta.groupingEvents === undefined,
        }"
        appTippy
        [popoverContent]="moreEvents"
        [popoverPlacement]="'bottom-start'"
        (click)="
          $event.stopPropagation();
          event.meta.groupingEvents?.events?.length ? {} : viewEvent(event)
        "
        class="flex justify-between gap-2 m-0.5 rounded-md px-1 py-0.5 text-xs dropdown actions-dropdown"
        (mouseenter)="highlightDay.emit({ event: event })"
        (mouseleave)="unhighlightDay.emit({ event: event })"
        [mwlCalendarTooltip]="event.title | calendarEventTitle: 'monthTooltip' : event"
        [tooltipPlacement]="tooltipPlacement"
        [tooltipEvent]="event"
        [tooltipTemplate]="tooltipTemplate"
        [tooltipAppendToBody]="tooltipAppendToBody"
        [tooltipDelay]="tooltipDelay"
        [class.cal-draggable]="event.draggable"
        dragActiveClass="cal-drag-active"
      >
        @if (event.meta.eventType === 'tasks') {
          <svg-icon [src]="getIcon('task-list')" class="w-3 flex justify-center m-auto" />
          <span class="flex-grow truncate max-w-24 font-semibold">
            {{ event.title }}
          </span>
          <span></span>
        } @else {
          <span class="truncate max-w-24 font-semibold">
            {{ event.title }}
          </span>
          @if (!event.meta.groupingEvents) {
            <span class="my-auto">
              {{ event.start | moment: 'H:mm' }} - {{ event.end | moment: 'H:mm' }}
              {{ event.end | moment: 'A' }}
            </span>
          }
        }
        <ul
          #moreEvents
          class="text-center bg-white w-[120px] divide-y rounded-md rounded-t-none border z-10"
        >
          <li
            *ngFor="let groupedEvent of event.meta.groupingEvents?.events ?? []"
            (click)="$event.stopPropagation(); viewEvent(groupedEvent)"
          >
            {{ groupedEvent.title }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>
