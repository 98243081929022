import {
  ChangeDetectorRef,
  Component,
  HostListener,
  ViewChild,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ElementRef,
  forwardRef,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofActionDispatched, ofActionSuccessful, Store } from '@ngxs/store';
import { Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import {
  NgbActiveModal,
  NgbModal,
  NgbNav,
  NgbNavChangeEvent,
  NgbTooltip,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownButtonItem,
  NgbDropdownItem,
  NgbNavItem,
  NgbNavItemRole,
  NgbNavLink,
  NgbNavLinkBase,
  NgbNavContent,
  NgbNavOutlet,
} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { parseISO } from 'date-fns';
import moment from 'moment-timezone';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';

import { UsersDbDto } from '../../api/models/users-db-dto';
import { ColumnsDbDto } from '../../api/models/columns-db-dto';
import { TicketsDbDto } from '../../api/models/tickets-db-dto';
import { TicketsGetOneResDto } from '../../api/models/tickets-get-one-res-dto';
import { UsersPublicFieldsResDto } from '../../api/models/users-public-fields-res-dto';
import { EstimationSessionsMembersDbDto } from '../../api/models/estimation-sessions-members-db-dto';
import { MediaPreview } from '../../shared/interfaces/media-preview';

import {
  QuillModulesForDescription,
  QuillModulesForChat,
} from '../../shared/data/quill-configuration';
import { ConfirmAlert } from '../../shared/alerts/alerts';
import { FilesHelper } from '../../shared/utils/files-helper';
import { HtmlHelper } from '../../shared/utils/html-helper';
import { RouterTenantPipe } from '../../shared/pipes/router-tenant.pipe';
import { CheckPermissionPipe } from '../../shared/pipes/check-permission.pipe';
import { CalendarControlTypes } from '../calendar-edit-event/calendar-edit-event.component';
import { ConfigService } from '../../shared/services/config.service';
import { SocketsService } from '../../shared/services/sockets.service';
import { MediaService } from '../../shared/services/media.service';
import { RouterQueryService } from '../../shared/services/router-query.service';
import { QuillInitializeService } from '../../shared/services/quill/quill-init.service';
import { SpaceGetUsersList } from '../../shared/store/actions/spaces.action';
import { ProjectGetUsersList } from '../../shared/store/actions/projects.action';
import { ThreadsSocketNewMessage } from '../../shared/store/actions/threads.action';
import {
  ColumnsGetList,
  TicketsCreate,
  TicketsDelete,
  TicketsUpdate,
  TicketsFilesDelete,
  TicketsGetById,
  TicketsLabelsGet,
  TicketsLabelCreate,
  TicketsGetShortList,
  TicketsOpenTicketChange,
  TicketsCreateEstimationSession,
  TicketsGetEstimationSession,
  TicketsUpdateEstimationSession,
  TicketsJoinMemberToEstimationSession,
  TicketsUpdateMemberEstimationSession,
  TicketsSocketUpdateEstimationSession,
  TicketsGetChecklistItems,
  BoardsApplyTicketSettings,
  BoardsSetSettings,
} from '../../shared/store/actions/board.action';
import { AuthState } from '../../shared/store/states/auth.state';
import { UsersState } from '../../shared/store/states/users.state';
import { SpacesState } from '../../shared/store/states/spaces.state';
import { ProjectsState } from '../../shared/store/states/projects.state';
import { BoardsState } from '../../shared/store/states/boards.state';
import { RecordService } from '../../shared/services/record.service';
import { TicketsLabelsDbDto } from '../../api/models/tickets-labels-db-dto';
import { BoardSettingsResDto } from '../../api/models/board-settings-res-dto';
import { BoardTicketModalComponent } from '../board-ticket/board-ticket.component';
import { PlatformService } from '../../../app/shared/services/platform.service';
import { DownloadService } from '../../api/services/download.service';
import { TruncatePipe } from '../../shared/pipes/truncate.pipe';
import { FromNowPipe } from '../../shared/pipes/from-now.pipe';
import { MediaPreviewComponent } from '../../shared/components/media-preview/media-preview.component';
import { DateTimePickerComponent } from '../../shared/components/date-time-picker/date-time-picker.component';
import { ProjectAvatarComponent } from '../../shared/components/space-projects/project-avatar/project-avatar.component';
import { SpaceAvatarComponent } from '../../shared/components/space-projects/space-avatar/space-avatar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChatThreadComponent } from '../../shared/components/chat/chat-thread/chat-thread.component';
import { QuillEditorComponent } from 'ngx-quill';
import { FocusDirective } from '../../shared/directives/focus.directive';
import { TextareaAutoresizeDirective } from '../../shared/directives/textarea-autoresize.directive';
import { AvatarComponent } from '../../standalone/components/avatar/avatar.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { SvgComponent } from '../../shared/svgs/svg/svg.component';
import { NgIf, NgClass, NgFor, AsyncPipe, DatePipe } from '@angular/common';

export enum Tabs {
  comments = 'comments',
  history = 'history',
}

export interface TicketData {
  id: string;
  object: 'spaces' | 'projects';
  objectId: string;
  isEpic: boolean;
  boardColorClass: string;
  platform: string;
  title: string;
  description: string;
  boardAbbreviation: string;
  counter: number;
  parentId: string;
  epicId: string;
  data: TicketsDbDto;
  users: UsersPublicFieldsResDto[];
  fileData: any[];
  selectedMembersIds: string[];
  ticketsMembers: any[];
  start?: string;
  end: string;
  estimate: any;
  ticketCreator: string;
  ticketUpdatedBy: string;
  isBlocked: boolean;
  chatMessageId?: string;
  chatType?: string;
  noteId?: string;
  showToastMessage?: boolean;
  lastTicketAbbr?: any;
  sprintId?: string;
  ticket?: any;
  tickets?: any;
  childrenList?: any[];
  subTicketsEpic: any[];
  ticketCreatedFromRecord?: boolean;
  allDay: true;

  isCopyTicket?: boolean;
}

export interface ITicketForm {
  objectId: string;
  title: string;
  description: string;
  columnId: string;
  parentId: string;
  epicId: string;
  selectedSubtask: null;
  estimateValue: null;
  estimateUnit: null;
  dueDate: null;
  startDate: null;
  // releaseDate: [item.releaseDate || ''],
  releaseVersion: string;
  labels: [];
  realTime: string;
  ticketMembers: [];
  files: File;
  isBlocked: false;
  bgColor: string;
  boardAbbreviation: string;
  counter: number;
}

interface EstimateSessionMembers extends EstimationSessionsMembersDbDto {
  user?: UsersDbDto;
}

@Component({
  selector: 'app-epic-ticket',
  templateUrl: './epic-ticket.component.html',
  styleUrls: ['./epic-ticket.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgIf,
    NgClass,
    SvgComponent,
    NgbTooltip,
    FormsModule,
    ReactiveFormsModule,
    NgScrollbar,
    forwardRef(() => AvatarComponent),
    TextareaAutoresizeDirective,
    FocusDirective,
    QuillEditorComponent,
    NgFor,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    NgbNav,
    NgbNavItem,
    NgbNavItemRole,
    NgbNavLink,
    NgbNavLinkBase,
    NgbNavContent,
    ChatThreadComponent,
    NgbNavOutlet,
    NgSelectModule,
    SpaceAvatarComponent,
    ProjectAvatarComponent,
    DateTimePickerComponent,
    MediaPreviewComponent,
    AsyncPipe,
    DatePipe,
    FromNowPipe,
    TruncatePipe,
  ],
})
export class EpicTicketComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('attachFileInput') fileInput: HTMLInputElement;
  @ViewChild('ticketModalsTab') ticketModalsTab: NgbNav;
  @ViewChild('customizeTicketButton')
  customizeTicketButton: ElementRef<HTMLDivElement>;

  env: any;
  platform = 'web';
  platformOS = 'web';

  destroy$: Subject<void> = new Subject<void>();
  users$: Observable<any[]>;
  spaces: Observable<any[]>;
  projects: Observable<any[]>;

  modalData: TicketData;
  ticketData: TicketData;
  settings: BoardSettingsResDto;

  userData: UsersPublicFieldsResDto;
  invitedUser = false;

  socket;
  socketEstimateSessionUpdateEvent = 'notification:send:boardsTicketsEstimationSessionUpdate';

  estimationTypes = [
    { unit: 'h', title: 'Hours' },
    { unit: 'd', title: 'Days' },
    { unit: 'sp', title: 'Storypoints' },
  ];

  estimationIsLoadingStart = false;
  estimationUserValue: FormControl = new FormControl('', [Validators.required]);
  estimationCreatorLock: FormControl = new FormControl(false);
  estimationMinMaxAvg: { min: number; max: number; avg: number } = {
    min: 0,
    max: 0,
    avg: 0,
  };

  estimateSession = null;
  estimateSessionMembers: EstimateSessionMembers[] = [];
  isHiddenEstimateSession = false;

  spaceUsers: UsersDbDto[] = [];
  projectUsers: UsersDbDto[] = [];

  config: any = {};
  currentTab: string = Tabs.comments;
  MAX_FILE_SIZE: number;

  editorModules: any;
  ticketForm: FormGroup = this.formBuilder.group({
    objectId: ['', Validators.required],
    title: ['', Validators.required],
    description: ['', Validators.required],
    columnId: [''],
    parentId: [''],
    epicId: [''],
    selectedSubtask: null,
    estimateValue: [null],
    estimateUnit: [null],
    dueDate: [null],
    startDate: [null],
    // releaseDate: [item.releaseDate || ''],
    releaseVersion: [''],
    labels: [[]],
    realTime: [''],
    ticketMembers: [[]],
    files: null,
    isBlocked: false,
    bgColor: '#ffffff',
  });
  pageTitle = '';
  pageTitleBack = '';
  submitButtonTitle = '';
  columnName = '-';
  ticketCreator = '';
  ticketUpdatedBy = '';
  ticketCreatedAt = '';
  ticketUpdatedAt = '';
  isCopyTicket = false;
  ticketHistory = [];
  needCreateNext = false;

  descriptionAttachmentsBlob: File[] = [];
  fileData: {
    _id?: string;
    fileName: string;
    ownerUserId?: string;
    created_at?: string;
    originalFileName?: string;
    url?: string;
  }[] = [];
  ticketCreatorName: string;
  ticketUpdatedByName: string;
  loading = false;
  isBlocked: boolean;
  isUploading: boolean;
  isToolbarClicked = false;
  isShowCustomiseTicket = false;
  customiseTicketXY: {
    x: number;
    y: number;
  };
  prevTicketStatus = '';

  usersInfoSub$: Subscription;
  usersSub$: Subscription;

  ticket: TicketsGetOneResDto;
  tickets: TicketsDbDto[] = [];
  ticketsSelect: TicketsDbDto[];
  ticketSelectParent: TicketsDbDto[] = [];
  columns: ColumnsDbDto[];
  boardColumns: any[];
  childrenList: any[] = [];
  subTicketsEpic: TicketsDbDto[] = [];
  parentTask: any = null;
  showSubtaskSelect = false;
  selectedSubtask = null;

  currentMediaPath: string;
  ticketMedia: MediaPreview[] = [];
  mediaPreviewVisible = false;
  limitToAttachmentName: number;
  handleCtrlEnter = false;

  relatedChatId = null;
  hasError = false;
  threadId: string;
  skipEscapeControls = ['tab-index-status', 'tab-index-assignees'];
  focusedControl: string;
  toggleDescriptionEditVisibility = false;
  editorDescription: any;
  mentionThreadMembers: any[];
  message: any = null;
  epicsList: any[] = [];
  epicLabel;
  currTzAbbr = null;

  addLabelPromise: (label: any) => Promise<any>;
  addSubtaskPromise: (task: any) => Promise<any>;
  labels: Array<TicketsLabelsDbDto> = [];
  selectedLabels;
  ticketWithDueDate: boolean;

  ticketWithStartDate: boolean;
  timeIsEmpty: boolean;

  private curTabIndex = 0;
  private maxTabIndex = 10;

  isPastDate = false;

  tabIndexes: { tabIndex?: string; type?: string }[] = [
    { tabIndex: 'tab-index-title', type: '' },
    { tabIndex: 'tab-index-description', type: 'quill-editor' },
    { tabIndex: 'tab-index-upload-files', type: 'button' },
    { tabIndex: 'tab-index-assignees', type: 'select' },
    { tabIndex: 'tab-index-day-date', type: '' },
    { tabIndex: 'tab-index-day-time', type: '' },
    { tabIndex: 'tab-index-labels', type: 'select' },
    { tabIndex: 'tab-index-estimate-value', type: '' },
    { tabIndex: 'tab-index-estimate-unit', type: 'select' },
    { tabIndex: 'tab-index-release-version', type: '' },
    { tabIndex: 'tab-index-parent-id', type: 'select' },
    { tabIndex: 'tab-index-sub-task', type: 'button' },
    { tabIndex: 'tab-index-status', type: 'select' },
    { tabIndex: 'tab-index-start-date', type: '' },
    { tabIndex: 'tab-index-start-date-reminder', type: 'select' },
  ];

  constructor(
    public filesHelper: FilesHelper,
    public htmlHelper: HtmlHelper,
    public formBuilder: FormBuilder,
    protected configService: ConfigService,
    private modal: NgbModal,
    private actions: Actions,
    private store: Store,
    private ref: ChangeDetectorRef,
    private socketsService: SocketsService,
    private mediaService: MediaService,
    private toastr: ToastrService,
    private routerQueryService: RouterQueryService,
    private quillInitializeService: QuillInitializeService,
    private checkPermissionPipe: CheckPermissionPipe,
    private routerTenantPipe: RouterTenantPipe,
    private activeModal: NgbActiveModal,
    private recordService: RecordService,
    private route: ActivatedRoute,
    private downloadService: DownloadService,
    private translocoService: TranslocoService,
    private platformService: PlatformService,
  ) {
    this.config = this.configService.templateConf;
    // this.MAX_FILE_SIZE = this.configService.MAX_FILE_SIZE
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown(e) {
    if (this.handleCtrlEnter) {
      if (e.shiftKey && e.keyCode === 9) {
        // tab key shift
        e.preventDefault();
        this.handleTabKey(false);
      } else if (e.keyCode === 9) {
        // tab key
        e.preventDefault();
        this.handleTabKey(true);
      }
      if (e.keyCode === 83 && e.ctrlKey && e.altKey) {
        // handle Ctrl+Alt+S - save a ticket
        e.preventDefault();
        this.submitForm();
      } else if (e.keyCode === 65 && e.ctrlKey && e.altKey) {
        // handle Ctrl+Alt+A - open assignee select?
        e.preventDefault();
        this.setFocusOnObjectSelect('tab-index-assignees');
      } else if (e.keyCode === 13 && e.ctrlKey) {
        // handle enter
        e.preventDefault();
        this.submitForm();
      } else if (e.keyCode === 27) {
        // handle escape
        if (!this.skipEscape()) {
          e.preventDefault();
          this.close();
        }
      }
    }
  }

  @HostListener('document:click', ['$event'])
  handleClick(event: MouseEvent) {
    if (event?.target['src']) {
      this.handleEditorImageClick(event?.target);
    }

    if (event?.target['classList'].contains('ql-picker-label')) {
      this.isToolbarClicked = true;
      event.stopPropagation();
    }
  }

  ngOnInit() {
    this.env = this.store.selectSnapshot(AuthState.getEnv);
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);
    this.platformOS = this.store.selectSnapshot(AuthState.getPlatformOS);

    this.store.dispatch(new TicketsOpenTicketChange(true));

    this.store
      .select(AuthState.getUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.userData = user;
        if (user?.timezone) {
          this.currTzAbbr = moment.tz(user.timezone).format('Z').replace(':', '');
        }
      });

    this.store
      .select(SpacesState.getUsers)
      .pipe(takeUntil(this.destroy$))
      .subscribe((users) => {
        this.spaceUsers = users;
      });

    this.store
      .select(ProjectsState.getUsers)
      .pipe(takeUntil(this.destroy$))
      .subscribe((users) => {
        this.projectUsers = users;
      });

    this.actions
      .pipe(takeUntil(this.destroy$), ofActionSuccessful(ThreadsSocketNewMessage))
      .subscribe(({ payload }) => {
        if (
          payload?.parentMessage?.linkObject === 'tickets' &&
          payload?.parentMessage?.linkObjectId === this.modalData?.id
        ) {
          this.threadId = payload?.parentMessage?.threadId;
        }
      });

    this.estimationCreatorLock.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((lockStatus: boolean) => {
        if (lockStatus) {
          this.estimationMinMaxAvg = this.calculateMinMaxAvg(this.estimateSession.members);
        }

        this.updateEstimateSession();
      });

    this.initializeEstimateSocket();
    this.setTicketData();
    this.getEstimateSession();
    this.initEstimationSession();
  }

  ngAfterViewInit() {
    this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
        this.ref.markForCheck();
      }
    });
  }

  ngOnDestroy() {
    this.store.dispatch(new TicketsOpenTicketChange(false));
    this.usersSub$?.unsubscribe();
    this.usersInfoSub$?.unsubscribe();

    this.socket.removeListener(this.socketEstimateSessionUpdateEvent);
    this.destroy$.next();
    this.destroy$.complete();
  }

  /* Getters */
  public get isUserInsideEstimateSession(): boolean {
    return (
      this.estimateSessionMembers?.map((member) => member.userId).includes(this.userData?._id) &&
      this.estimateSession?.ticketId
    );
  }

  public get isOwnerEstimateSession(): boolean {
    return this.userData?._id === this.estimateSession?.ownerUserId;
  }

  public get isEstimationSessionLocked(): boolean {
    return this.estimationCreatorLock.value;
  }

  public get isDisabledBtn(): boolean {
    return this.isUploading || this.isPastDate;
  }

  /* Methods */
  public isUserEstimate(member): boolean {
    return member.userId === this.userData?._id && member.value;
  }

  onChangeTicketColor(color: string): void {
    this.ticketForm.patchValue({ bgColor: color });
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.currentTab = changeEvent.nextId;
  }

  inputFocused(elemId): void {
    setTimeout(() => {
      document
        .getElementById(`${elemId}`)
        ?.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
    }, 1000);
  }

  private buildForm(data) {
    this.ticketForm?.reset();
    const todoColumnId = this.store
      .selectSnapshot(BoardsState.getColumnsList)
      .find((column) => column.title === 'TODO' && !column.isDeleted)?._id;

    this.isBlocked = data?.isBlocked || false;
    this.ticketWithDueDate = !!data.dueDate;
    this.ticketWithStartDate = !!data.startDate;
    this.timeIsEmpty = !data.dueDate;

    this.ticketForm = this.formBuilder.group({
      objectId: [data.objectId || '', Validators.required],
      title: [this.isCopyTicket ? data.title + ' - Copy' : data.title || '', Validators.required],
      description: [data.description || '', Validators.required],
      columnId: [data.columnId || todoColumnId || this.modalData?.objectId],
      parentId: [data.parentId || this.modalData?.parentId],
      epicId: [data.epicId || this.modalData?.epicId],
      selectedSubtask: null,
      estimateValue: [data.estimate?.value || null],
      estimateUnit: [data.estimate?.unit || null],
      dueDate: [data.dueDate ? parseISO(data.dueDate) : null],
      startDate: [data.startDate ? parseISO(data.startDate) : null],
      // releaseDate: [item.releaseDate || ''],
      releaseVersion: [data.releaseVersion || ''],
      labels: [data.labels || []],
      realTime: [data.realTime || ''],
      ticketMembers: [this.modalData?.selectedMembersIds],
      files: null,
      isBlocked: this.isBlocked,
      bgColor: data.bgColor,
      boardAbbreviation: data.boardAbbreviation,
      counter: data.counter,
    });
  }

  initEstimationSession(): void {
    this.store
      .select(BoardsState.getEstimateSession)
      .pipe(takeUntil(this.destroy$))
      .subscribe((estimateSession) => {
        this.estimateSession = estimateSession;

        if (this.estimateSession && this.estimateSession?.members) {
          this.estimationMinMaxAvg = this.calculateMinMaxAvg(this.estimateSession.members);
          this.estimateSessionMembers = this.estimateSession.members.map((member) => {
            let foundUser;

            if (this.modalData?.object === 'spaces') {
              foundUser = this.spaceUsers.find((user) => user._id === member.userId);
            } else if (this.modalData?.object === 'projects') {
              foundUser = this.projectUsers.find((user) => user._id === member.userId);
            }

            return { ...member, ...{ user: foundUser } };
          });

          this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((queryParams) => {
            if (queryParams?.estimation && this.estimateSessionMembers.length) {
              const { _id } = this.store.selectSnapshot(AuthState.getUser);
              const isUserJoined = this.estimateSessionMembers.find(
                (member) => member.userId === _id,
              );

              if (!isUserJoined) {
                this.joinToEstimateSession();
              }

              this.currentTab = 'estimation';
            }
          });

          this.estimationCreatorLock.patchValue(estimateSession.isLocked, {
            emitEvent: false,
          });
        }
        this.ref.markForCheck();
      });
  }

  setTicketData(): void {
    if (this.ticketData.id) {
      this.routerQueryService.update({ ticket: this.ticketData.id });
    }

    this.getTicketsShortList();
    this.isCopyTicket = this.ticketData.isCopyTicket;

    this.modalData = this.ticketData;
    this.editorModules = {
      ...(this.platform === 'web' ? QuillModulesForDescription : QuillModulesForChat),
      magicUrl: true,
    };
    this.limitToAttachmentName = this.platform === 'web' ? 48 : 24;
    this.descriptionAttachmentsBlob = [];
    this.fileData = [];

    this.store
      .select(BoardsState.getEpicsList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.epicsList = res.filter((epic) => !epic.isDeleted);
      });
    this.ticketSelectParent = this.tickets.filter((ticket) => !ticket.parentId);

    if (this.modalData.id) {
      this.handleCtrlEnter = true;

      this.store.select(BoardsState.getBoardSettings).subscribe((settings) => {
        if (settings) {
          this.settings = settings;
          this.ref.detectChanges();
        }
      });

      combineLatest([
        this.store.dispatch(new TicketsGetById({ id: this.ticketData.id })),
        this.store.dispatch(new TicketsGetChecklistItems({ id: this.ticketData.id })),
      ])
        .pipe(takeUntil(this.destroy$))
        .subscribe((dataResponse) => {
          const [dispatchData] = dataResponse;
          const ticketUpdateBy =
            this.ticket?.changesHistory[this.ticket?.changesHistory?.length - 1 || 0]?.updatedBy;
          const data = dispatchData;
          this.ticket = data.Boards.ticket;
          this.modalData.data = data.Boards.ticket;
          this.childrenList = this.ticket.childrenList;
          this.subTicketsEpic =
            this.ticketData.tickets &&
            this.ticketData.tickets.filter(
              (ticket) => ticket.epicId === this.ticket._id && ticket._id !== this.ticketData.id,
            );
          this.ticketsSelect = this.tickets.filter((ticket) => ticket.epicId !== this.ticket._id);
          this.ticketCreator = this.ticket.ownerUserId;
          this.ticketUpdatedBy = ticketUpdateBy || this.ticket.ownerUserId;
          this.ticketHistory = this.ticket.changesHistory;
          this.ticketCreatedAt = this.ticket.created_at;
          this.ticketUpdatedAt = this.ticket.updated_at;
          this.selectedLabels = this.ticket.labels;

          this.store
            .select(BoardsState.getEpicsList)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
              this.epicsList = res.filter((epic) => !epic.isDeleted);
              this.epicLabel = res.find(
                (ticket) => this.ticket && ticket._id === this.ticket.epicId,
              );
            });

          const childrenIds = this.childrenList?.map((item) => item._id);
          if (childrenIds?.length) {
            this.tickets = this.tickets?.filter((item) => !childrenIds.includes(item._id));
          }

          this.actions
            .pipe(takeUntil(this.destroy$), ofActionDispatched(ThreadsSocketNewMessage))
            .subscribe(({ payload }) => {
              if (!this.message && this.ticket?._id === payload.message?.linkObjectId) {
                this.message = payload.message;
              }
            });

          this.usersInfoSub$?.unsubscribe();
          this.usersInfoSub$ = this.store
            .select(UsersState.getUsersInfo)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
              const userWithName: any = res[this.ticketCreator];
              const updatedUserWithName: any = res[this.ticketUpdatedBy];
              if (userWithName?.userName) {
                this.ticketCreatorName = userWithName.userName;
              }
              this.ticketUpdatedByName = updatedUserWithName?.userName
                ? updatedUserWithName.userName
                : '';

              if (this.ticketHistory) {
                this.ticketHistory = this.ticketHistory
                  .map((item) => ({
                    ...item,
                    updaterName: res[item.updatedBy]?.userName,
                  }))
                  .sort((a, b) => moment(b.updated_at).unix() - moment(a.updated_at).unix());
              }
            });

          this.modalData.selectedMembersIds = this.ticket.ticketsMembers?.map(
            (member) => member.userId,
          );

          this.getColumns(this.modalData.data.columnId);

          this.prevTicketStatus = this.modalData.data.columnId;
          this.buildForm({ ...this.modalData.data });

          if (this.modalData?.data?.fileData?.length) {
            this.fileData = JSON.parse(JSON.stringify(this.modalData?.data?.fileData));
          }

          this.ref.detectChanges();

          setTimeout(() => {
            if (this.ticket?.chatMessage) {
              this.message = this.ticket.chatMessage;
              this.threadId = this.message.threadId;
              this.relatedChatId = this.message.chatId;
            } else {
              this.message = null;
              this.threadId = undefined;
              this.relatedChatId = undefined;
            }
          }, 0);
        });
    } else {
      if (this.modalData?.data?.fileData?.length) {
        const data = {
          target: {
            files: [this.modalData?.data?.fileData[0]?.file],
          },
        };
        this.fileChange(data);
      }
      this.handleCtrlEnter = true;

      this.ticketCreator = this.modalData.data.ownerUserId
        ? this.modalData.data.ownerUserId
        : this.modalData.ticketCreator;
      this.ticketUpdatedBy = '';
      this.ticketUpdatedByName = '';

      this.modalData.selectedMembersIds = [];
      this.modalData.boardColorClass = '';
      this.buildForm({ ...this.modalData.data });

      if (this.ticketData.chatMessageId) {
        // creating a ticket from a chat message
        this.ticketForm.patchValue({
          description: this.ticketData?.data?.description,
        });
        this.modalData.chatMessageId = this.ticketData.chatMessageId;
      } else if (this.ticketData.noteId) {
        // creating a ticket from a note
        this.ticketForm.patchValue({
          description: this.ticketData?.data?.description,
        });
        this.modalData.noteId = this.ticketData.noteId;
      }

      this.usersInfoSub$?.unsubscribe();
      this.usersInfoSub$ = this.store
        .select(UsersState.getUsersInfo)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => (this.ticketCreatorName = res[this.ticketCreator].userName));

      this.getColumns();
    }

    this.spaces = this.store
      .select(SpacesState.getLoadedSpaces)
      .pipe(
        map((result) =>
          result.filter((item) =>
            this.checkPermissionPipe.transform('spaces::' + item._id + '::calendarEventCreate'),
          ),
        ),
      );

    this.projects = combineLatest([
      this.store.select(SpacesState.getLoadedSpaces),
      this.store.select(ProjectsState.getLoadedProjects),
    ]).pipe(
      map(([spaces, projects]) =>
        projects
          .filter((item) =>
            this.checkPermissionPipe.transform('projects::' + item._id + '::calendarEventCreate'),
          )
          .map((item) => this.addSpaceAvatarForProjects(spaces, item)),
      ),
    );

    if (this.modalData.noteId || this.modalData.chatType === 'direct') {
      this.modalData.object = 'projects';
      this.modalData.objectId = null;
    } else {
      this.mentionThreadMembers = null;
      this.usersSub$?.unsubscribe();

      switch (this.modalData.object) {
        case 'spaces':
          this.usersSub$ = this.store
            .dispatch(
              new SpaceGetUsersList({
                id: this.modalData.objectId,
                exists: true,
              }),
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res) => this.getThreadMentions(res.Spaces.users),
              (error) => console.error(error),
            );
          this.users$ = this.store.select(SpacesState.getUsers);
          break;
        case 'projects':
          this.usersSub$ = this.store
            .dispatch(
              new ProjectGetUsersList({
                id: this.modalData.objectId,
                exists: true,
              }),
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => this.getThreadMentions(res.Projects.users));
          this.users$ = this.store.select(ProjectsState.getUsers);
          break;
      }
    }

    if (this.modalData.object && this.modalData.objectId) {
      this.store.dispatch(
        new TicketsLabelsGet({
          object: this.modalData.object,
          objectId: this.modalData.objectId,
        }),
      );
    }

    this.store
      .select(BoardsState.getTicketsLabels)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => (this.labels = [...res]));

    this.addLabelPromise = (label) => {
      return new Promise((resolve) => {
        this.loading = true;

        const body = {
          label: label,
          object: this.modalData.object,
          objectId: this.modalData.objectId,
        };
        this.store
          .dispatch(new TicketsLabelCreate(body))
          .pipe(takeUntil(this.destroy$))
          .subscribe((res) => {
            if (res.Boards?.lastCreatedLabel) {
              resolve(res.Boards.lastCreatedLabel);
              this.loading = false;
            }
          });
      });
    };

    this.addSubtaskPromise = (task) => {
      return new Promise((resolve) => {
        this.selectedSubtask = { _id: 'new', title: task };
        resolve({ _id: 'new', title: task });
      });
    };

    this.submitButtonTitle = this.modalData.id
      ? this.translocoService.translate('modals.board-ticket.btn-save')
      : this.translocoService.translate('modals.board-ticket.btn-create-epic');
    this.pageTitle = this.modalData.id
      ? this.translocoService.translate('modals.board-ticket.title-edit-epic')
      : this.translocoService.translate('modals.board-ticket.title-create-epic');
  }

  getTickets(list) {
    this.parentTask = this.ticketData.parentId
      ? list.find((item) => item._id === this.ticketData.parentId)
      : null;

    return list
      .filter(
        (item) => item._id !== this.ticketData.id && item.columnId !== this.ticketData.objectId,
      )
      .sort((a, b) => (a.counter > b.counter ? 1 : -1));
  }

  getTicketsShortList(): void {
    if (this.ticketData.tickets) {
      this.tickets = this.getTickets(this.ticketData.tickets);
    } else {
      this.store
        .dispatch(
          new TicketsGetShortList({
            object: this.ticketData.object,
            objectId: this.ticketData.objectId,
            short: 'dataRoom',
          }),
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => (this.tickets = this.getTickets(res.Boards.ticketsInfo)));
    }
  }

  getThreadMentions(users) {
    if (users) {
      const userId = this.store.selectSnapshot(AuthState.getUser)._id;
      this.mentionThreadMembers = [
        'all',
        ...users.filter((item) => item._id !== userId).map((item) => item.userName),
      ];
    }
  }

  startOrJoinEstimationSession(): void {
    if (!this.estimateSession) {
      this.startEstimationSession();
    }

    if (this.estimateSession) {
      this.joinToEstimateSession();
    }
  }

  saveMemberEstimateValue(): void {
    const foundMember = this.estimateSessionMembers.find(
      (member) => member.userId === this.userData._id,
    );
    const payload = {
      id: this.ticketData.id,
      memberId: foundMember._id,
      body: {
        value: this.estimationUserValue.value,
      },
    };
    this.store
      .dispatch(new TicketsUpdateMemberEstimationSession(payload))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.estimationUserValue.reset();
      });
  }

  clearDataLists() {
    this.users$ = null;
    this.labels = [];
    this.modalData.objectId = null;
    this.modalData.selectedMembersIds = [];
    this.selectedLabels = [];
    this.ticketForm.controls.columnId.setValue(null);
  }

  attachDataLists({ object, objectId }) {
    let state;
    this.ticketForm.patchValue({ parentId: null });
    this.ticketForm.patchValue({ epicId: null });

    this.clearDataLists();
    this.modalData.object = object;
    this.modalData.objectId = objectId;

    if (objectId) {
      if (object === CalendarControlTypes.Spaces) {
        this.store.dispatch(new SpaceGetUsersList({ id: objectId, exists: true }));
        state = SpacesState;
      } else if (object === CalendarControlTypes.Projects) {
        this.store.dispatch(new ProjectGetUsersList({ id: objectId, exists: true }));
        state = ProjectsState;
      }
      const selected = this.store.select(state.getUsers);

      this.users$ = selected.pipe(
        map((users: []) => users?.filter((item) => this.userData?._id !== item['_id'])),
      );
      this.store.dispatch(
        new TicketsLabelsGet({
          object: this.modalData.object,
          objectId: this.modalData.objectId,
        }),
      );

      this.getTicketsShortList();

      this.getColumns();
    }
  }

  spacesBtnClicked() {
    if (this.modalData.object !== CalendarControlTypes.Spaces) {
      this.clearDataLists();
    }
    this.modalData.object = CalendarControlTypes.Spaces;
  }

  projectsBtnClicked() {
    if (this.modalData.object !== CalendarControlTypes.Projects) {
      this.clearDataLists();
    }
    this.modalData.object = CalendarControlTypes.Projects;
  }

  public updateEstimateSession(): void {
    const payload = {
      estimationSessionId: this.estimateSession._id,
      id: this.ticketData.id,
      body: {
        object: this.modalData.object,
        objectId: this.modalData.objectId,
        isLocked: this.estimationCreatorLock.value,
      },
    };

    this.store
      .dispatch(new TicketsUpdateEstimationSession(payload))
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  public memberAnswerStatusForCurrentUser(member): string {
    return member.value ? member.value : '?';
  }

  public memberAnswerStatusForGuest(member): string {
    return member.value ? '✅' : '?';
  }

  public clearEstimationSession(): void {
    this.estimateSession = null;
    this.estimateSessionMembers = [];
  }

  private initializeEstimateSocket(): void {
    this.socket = this.socketsService.get();
    this.socket.on(this.socketEstimateSessionUpdateEvent, (estimateSession) => {
      this.store.dispatch(new TicketsSocketUpdateEstimationSession(estimateSession));
    });
  }

  private getColumns(columnId: string = null): void {
    this.store
      .dispatch(
        new ColumnsGetList({
          object: this.modalData.object,
          objectId: this.modalData.objectId,
        }),
      )
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe((data) => {
        this.columns = [];
        for (const column of data.Boards.columns) {
          if (column.objectId === this.modalData.objectId && !column.isDeleted) {
            this.columns.push({
              ...column,
              title: column.isLocked && column.displayName ? column.displayName : column.title,
            });
          }
        }

        this.boardColumns = [...this.columns, { title: 'BACKLOG', _id: this.modalData.objectId }];

        if (columnId) {
          this.modalData.boardColorClass = this.setColumnBoardColor(this.columns, columnId);
        } else if (this.boardColumns[0]._id) {
          this.ticketForm.controls.columnId.setValue(this.boardColumns[0]._id);
          this.modalData.boardColorClass = this.setColumnBoardColor(
            this.columns,
            this.boardColumns[0]._id,
          );
        }
      });
  }

  private skipEscape(): boolean {
    return this.skipEscapeControls.includes(this.focusedControl);
  }

  private getEstimateSession(): void {
    if (this.ticketData.id) {
      const payload = {
        object: this.modalData.object,
        objectId: this.modalData.objectId,
        id: this.ticketData.id,
      };

      this.isHiddenEstimateSession = true;
      this.store
        .dispatch(new TicketsGetEstimationSession(payload))
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            if (
              this.estimateSession &&
              this.estimateSession.isLocked &&
              this.estimateSession.members
            ) {
              this.estimationMinMaxAvg = this.calculateMinMaxAvg(this.estimateSession.members);
            }
            this.isHiddenEstimateSession = false;
          },
          () => (this.isHiddenEstimateSession = true),
        );
    }
  }

  private startEstimationSession(): void {
    this.estimationIsLoadingStart = true;
    const payload = {
      id: this.ticketData.id,
      body: {
        object: this.modalData.object,
        objectId: this.modalData.objectId,
      },
    };

    this.store
      .dispatch(new TicketsCreateEstimationSession(payload))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.joinToEstimateSession();
        this.estimationIsLoadingStart = false;
      });
  }

  private joinToEstimateSession(): void {
    const payload = {
      id: this.ticketData.id,
      body: {
        value: 0,
      },
    };

    this.store
      .dispatch(new TicketsJoinMemberToEstimationSession(payload))
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  private calculateMinMaxAvg(members): {
    min: number;
    max: number;
    avg: number;
  } {
    const values = members.map((member) => member.value).filter(Boolean);

    if (values.length === 0) {
      return this.resetToDefaultValueMinMaxAvg();
    }

    const min = Math.min(...values);
    const max = Math.max(...values);
    const avg = Math.round(values.reduce((a, b) => a + b, 0) / values.length);
    return { min, max, avg };
  }

  private resetToDefaultValueMinMaxAvg(): {
    min: number;
    max: number;
    avg: number;
  } {
    return { min: 0, max: 0, avg: 0 };
  }

  setFocusedControl(dataTabindex: string): void {
    this.focusedControl = dataTabindex;
    const control = this.tabIndexes.find((element, index) => {
      if (element.tabIndex === dataTabindex) {
        this.curTabIndex = index;
        return element.tabIndex === dataTabindex;
      }
    });
  }

  private handleTabKey(forward: boolean): void {
    let control: any;

    if (!forward) {
      this.curTabIndex > 0 ? this.curTabIndex-- : (this.curTabIndex = this.maxTabIndex);
    } else {
      this.curTabIndex < this.maxTabIndex ? this.curTabIndex++ : (this.curTabIndex = 0);
    }

    control = <HTMLInputElement>(
      document.querySelector('[data-tabindex="' + this.tabIndexes[this.curTabIndex].tabIndex + '"]')
    );

    this.setFocusOnControl(control);
  }

  private setFocusOnControl(control: any): void {
    try {
      if (this.tabIndexes[this.curTabIndex].type === 'select') {
        this.setFocusOnObjectSelect(this.tabIndexes[this.curTabIndex].tabIndex);
      } else if (this.tabIndexes[this.curTabIndex].type === 'quill-editor') {
        switch (this.tabIndexes[this.curTabIndex].tabIndex) {
          case 'tab-index-description':
            this.editorDescription.focus();
            break;
        }
      } else {
        control.focus();
      }
    } catch (error) {}
  }

  private setFocusOnObjectSelect(object: string): void {
    const element = document
      .querySelector(`[data-tabindex="${object}"]`)
      .firstElementChild.firstElementChild.lastElementChild.getElementsByTagName('input');

    if (element.item(0)) {
      element.item(0).focus();
    }
  }

  private addSpaceAvatarForProjects(spaces, item) {
    const space = spaces.find((s) => s?._id === item?.spaceId);

    if (space) {
      item = { ...item, space: { avatarUrl: space.avatarUrl } };
    }

    return item;
  }

  /**
   * Close modal handler
   */
  close() {
    if (this.ticketForm.dirty) {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.close-modal-subject'),
        text: this.translocoService.translate('alert.close-modal-text'),
        showCancelButton: true,
        cancelButtonText: this.translocoService.translate('alert.close-modal-btn-close'),
        showDenyButton: true,
        denyButtonText: this.translocoService.translate('alert.close-modal-btn-discard'),
        denyButtonClass: 'btn-subtle',
        confirmButtonText: this.translocoService.translate('alert.close-modal-btn-save'),
        confirmButtonClass: 'btn-solid',
        platform: this.platform,
      }).then(
        (result) => {
          if (result === 'isConfirmed') {
            this.submitForm();
          }
          if (result === 'isDenied') {
            this.closeHandler();
          }
        },
        () => {},
      );
    } else {
      this.closeHandler();
    }
  }

  closeHandler(): void {
    this.store.dispatch(new TicketsOpenTicketChange(false));
    this.activeModal.close();
    this.routerQueryService.update({ ticket: null, estimation: null });
    this.handleCtrlEnter = false;
    this.ticket = null;
    this.threadId = null;
    this.message = null;
    this.selectedLabels = null;
    this.clearDataLists();
    this.clearEstimationSession();
  }

  editorDescriptionCreated(editorDescription): void {
    this.editorDescription = editorDescription;
    QuillInitializeService.handleEditorCreated(editorDescription);
  }

  descriptionBlur() {
    setTimeout(() => {
      if (!this.isToolbarClicked) {
        this.toggleDescriptionEditVisibility = false;
      }
      this.isToolbarClicked = false;
    }, 0);
  }

  statusSelectChange(selectedValue: ColumnsDbDto): void {
    if (selectedValue?._id) {
      this.modalData.boardColorClass = this.getColumnBoardColor(selectedValue?._id);
    }
  }

  private getColumnBoardColor(columnId: string): string {
    const columnColor: any = this.columns.find((col: any) => col._id === columnId);
    return columnColor?.boardColorClass ? columnColor.boardColorClass : '';
  }

  toggleFlag(): void {
    this.isBlocked = !this.isBlocked;
    this.ticketForm.patchValue({ isBlocked: this.isBlocked });
  }

  toggleCustomize(event?: { isBoardApplyAllTickets: boolean }): void {
    if (event && event?.isBoardApplyAllTickets) {
      this.submitForm(event.isBoardApplyAllTickets);
    }
    this.customiseTicketXY = {
      x: this.customizeTicketButton.nativeElement.offsetLeft,
      y: this.customizeTicketButton.nativeElement.offsetTop + 54,
    };

    this.isShowCustomiseTicket = !this.isShowCustomiseTicket;
  }

  copyTicketLink(modalData) {
    const host =
      `http${this.env.ssl ? 's' : ''}://` +
      (this.env.main_host || this.env.base_host !== 'localhost:4200'
        ? `${modalData.data.tenantName}.${this.env.main_host}`
        : `${this.env.base_host}/${modalData.data.tenantName}`);
    const title = modalData.data.column?.title;
    const board =
      !title || title === 'BACKLOG' ? 'backlog' : title === 'ARCHIVE' ? 'archive' : 'board';
    const ticketUrl = `${host}/${modalData.object.slice(0, -1)}/${modalData.objectId}/${board}?ticket=${modalData.id}`;

    this.htmlHelper.copyValueToBuffer(ticketUrl);
    this.toastr.success(
      this.translocoService.translate('toastr.message-link-copied'),
      this.translocoService.translate('toastr.title-success'),
    );
  }

  dataHasChanged(data, newData) {
    return !!(
      data.title !== newData.title ||
      data.description !== newData.description ||
      data.columnId !== newData.columnId ||
      data.estimate?.value !== newData.estimateValue ||
      data.estimate?.unit !== newData.estimateUnit ||
      data.releaseVersion !== newData.releaseVersion ||
      data.isBlocked !== newData.isBlocked ||
      data.object !== newData.object ||
      data.objectId !== newData.objectId ||
      data.parentId !== newData.parentId ||
      data.epicId !== newData.epicId ||
      data.labels?.length !== newData.labels?.length ||
      data.ticketsMembers?.length !== newData.ticketMembers?.length ||
      !data.labels.every((v, i) => v === newData.labels[i]) ||
      !data.ticketsMembers
        .map((item) => item.userId)
        .every((v, i) => v === newData.ticketMembers[i]) ||
      newData.files?.length ||
      data.dueDate !== newData.dueDate ||
      data.startDate !== newData.startDate
    );
  }

  submitForm(isBoardApplyTicketSettings?: boolean) {
    this.hasError = false;
    if (this.descriptionAttachmentsBlob?.length) {
      this.ticketForm.patchValue({ files: this.descriptionAttachmentsBlob });
    }

    if (!this.modalData.id) {
      this.addTicketSubmit(isBoardApplyTicketSettings); // Create ticket
    } else if (this.isCopyTicket) {
      this.addTicketSubmit();
    } else {
      this.editTicketSubmit(isBoardApplyTicketSettings); // Update ticket
    }
  }

  editTicketSubmit(isBoardApplyTicketSettings: boolean) {
    const ticketId = this.modalData.data._id;
    const newData = { ...this.ticketForm.value };

    if (!this.checkTitle()) {
      this.toastr.clear();
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-empty-title'),
        this.translocoService.translate('toastr.title-error'),
      );
      return;
    }

    if (this.checkFileSize(newData.files)) {
      this.toastr.clear();
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-file-size', {
          size: '1GB',
        }),
        this.checkFileSize(this.ticketForm.value.files).name,
      );
      return;
    }

    if (!this.isDueDateValid()) {
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-due-date'),
        this.translocoService.translate('toastr.title-error'),
      );
      return;
    }

    if (!this.isDateValid()) {
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-date'),
        this.translocoService.translate('toastr.title-error'),
      );
      return;
    }

    newData.allDay = this.timeIsEmpty;

    if (!newData.ticketMembers) {
      newData.ticketMembers = [];
    }

    if (newData.ticketMembers.length === 0) {
      newData.isNoMembers = true;
    }

    if (!newData.labels || newData.labels.length === 0) {
      newData.isNoLabels = true;
    }

    if (!newData.estimateUnit && !newData.estimateValue) {
      newData.estimate = 'null';
    }

    const fieldsToDelete = [
      '_id',
      'object',
      'objectId',
      'realTime',
      'boardAbbreviation',
      'counter',
    ];
    const addTimeFields = this.addTime(newData);
    this.deleteFields(newData, fieldsToDelete);

    if (typeof newData.columnId !== 'undefined' && !newData.columnId) {
      delete newData.columnId;
    }

    const body = Object.assign(
      {
        ...newData,
        object: this.modalData.object,
        objectId: this.modalData.objectId,
      },
      addTimeFields,
    );

    body.dueDate = body.dueDate ? body.dueDate.toISOString() : 'null';
    body.startDate = body.startDate ? body.startDate.toISOString() : 'null';

    if (this.checkTicketStatusChanged(newData)) {
      body.order = 0;
    }

    if (!this.hasError) {
      if (this.dataHasChanged(this.modalData.data, body)) {
        const ticketAbbr = `${this.modalData.data.boardAbbreviation}-${this.modalData.data.counter}`;
        this.isUploading = true;
        if (!body.parentId) {
          body.parentId = 'null';
        }
        if (!body.epicId) {
          body.epicId = 'null';
        }

        if (isBoardApplyTicketSettings) {
          this.store
            .dispatch(
              new BoardsSetSettings({
                bgColor: body.bgColor,
                object: body.object,
                objectId: body.objectId,
              }),
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              () => {
                this.handleTicketsColumnsProcessing(null, null, false);
                this.closeHandler();
                this.toastr.success(
                  this.translocoService.translate('toastr.message-ticket-updated', {
                    text: ticketAbbr,
                  }),
                  this.translocoService.translate('toastr.title-success'),
                  { enableHtml: true },
                );
              },
              (err) => {
                this.isUploading = false;
                this.toastr.error(
                  err.message,
                  this.translocoService.translate('toastr.title-error'),
                );
              },
            );
        } else {
          this.store
            .dispatch(new TicketsUpdate({ ticketUpdateId: ticketId, body }))
            .pipe(takeUntil(this.destroy$), take(1))
            .subscribe(
              () => {
                this.handleTicketsColumnsProcessing(null, null, false);
                this.closeHandler();
                this.toastr.success(
                  this.translocoService.translate('toastr.message-ticket-updated', {
                    text: ticketAbbr,
                  }),
                  this.translocoService.translate('toastr.title-success'),
                  { enableHtml: true },
                );
              },
              (err) => {
                this.isUploading = false;
                this.toastr.error(
                  err.message,
                  this.translocoService.translate('toastr.title-error'),
                );
              },
            );
        }
      }
    }
  }

  addTicketSubmit(isBoardApplyTicketSettings?: boolean) {
    let errorMsg = null;
    const checklist = this.store.selectSnapshot(BoardsState.getChecklist);
    const checklistFilter = checklist.map((item) => {
      return { text: item.text, isCompleted: item.isCompleted };
    });

    if (!this.checkTitle()) {
      errorMsg = this.translocoService.translate('toastr.err-message-empty-title');
    } else if (
      (this.modalData.noteId || this.modalData.chatType === 'direct') &&
      !this.checkObjectId()
    ) {
      errorMsg = this.translocoService.translate('toastr.err-message-empty-board');
    } else if (!this.ticketForm.value.columnId) {
      errorMsg = this.translocoService.translate('toastr.err-message-empty-status');
    } else if (this.checkFileSize(this.ticketForm.controls.files.value)) {
      this.needCreateNext = false;
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-file-size', {
          size: '1GB',
        }),
        this.checkFileSize(this.ticketForm.controls.files.value).name,
      );
      return;
    }

    if (!this.isDueDateValid()) {
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-start-end-date'),
        this.translocoService.translate('toastr.title-error'),
      );
      return;
    }

    if (!this.isDateValid()) {
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-date'),
        this.translocoService.translate('toastr.title-error'),
      );
      return;
    }

    if (errorMsg) {
      this.needCreateNext = false;
      this.toastr.clear();
      this.toastr.error(errorMsg, this.translocoService.translate('toastr.title-error'));
      return;
    }

    const result = this.ticketForm.value;

    if (this.modalData?.chatMessageId) {
      // creating a ticket from a chat message
      result.chatMessageId = this.modalData.chatMessageId;
    } else if (this.modalData?.noteId) {
      // creating a ticket from a note
      result.noteId = this.modalData.noteId;
    }

    if (this.modalData?.sprintId) {
      result.sprintId = this.modalData.sprintId;
    }

    result.isEpic = this.modalData.isEpic;
    result.columnId = this.modalData.objectId;

    const addData = Object.assign(
      {
        ...result,
        object: this.modalData.object,
        objectId: this.modalData.objectId,
        order: 0,
      },
      this.addTime(result),
    );

    this.deleteTimeFields(result);

    if (addData.ticketMembers?.length === 0) {
      addData.isNoMembers = true;
    }

    addData.allDay = this.timeIsEmpty;

    addData.dueDate = addData.dueDate?.toISOString();
    addData.startDate = addData.startDate?.toISOString();
    addData.checkListItems = checklistFilter;

    const fieldsToDelete = ['boardAbbreviation', 'counter'];
    this.deleteFields(addData, fieldsToDelete);
    if (!this.hasError) {
      this.isUploading = true;
      this.store
        .dispatch(new TicketsCreate(addData))
        .pipe(
          takeUntil(this.destroy$),
          withLatestFrom(this.store.select(BoardsState.getLastAddedTicketId)),
        )
        .subscribe(
          ([res, ticketId]) => {
            const ticket = res.Boards.tickets.find((item) => item._id === ticketId);
            const ticketAbbr = ticket ? `${ticket.boardAbbreviation}-${ticket.counter}` : '';

            if (this.modalData?.showToastMessage) {
              this.toastr.clear();
              this.toastr.success(
                this.translocoService.translate('toastr.message-ticket-created', {
                  text: ticketAbbr,
                }),
                this.translocoService.translate('toastr.title-success'),
                { enableHtml: true },
              );
              this.modalData.showToastMessage = false;
            }

            if (isBoardApplyTicketSettings) {
              this.store.dispatch(
                new BoardsApplyTicketSettings({
                  id: ticketId,
                }),
              );
            }

            this.handleTicketsColumnsProcessing(null, null, false);
            if (this.modalData.ticketCreatedFromRecord) {
              this.recordService.clearRecord();
            }
            if (this.needCreateNext) {
              this.needCreateNext = false;
              this.ticketForm.reset();
              this.modalData.lastTicketAbbr = ticketAbbr;
              this.routerQueryService.update({ ticket: null });
              this.ticketForm.controls.columnId.setValue(this.boardColumns[0]._id);
            } else {
              this.closeHandler();
            }
          },
          (err) => {
            this.isUploading = false;
            this.needCreateNext = false;
            this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
          },
        );
    } else {
      this.needCreateNext = false;
    }
  }

  private checkTitle(): boolean {
    return !(this.ticketForm.value.title === '' || this.ticketForm.value.title === undefined);
  }

  private checkFileSize(files: File[]): File | undefined {
    return files?.find((file) => file.size >= this.MAX_FILE_SIZE);
  }

  private checkObjectId(): boolean {
    return !(
      this.ticketForm.value.objectId === null || this.ticketForm.value.objectId === undefined
    );
  }

  private checkTicketStatusChanged(ticket: TicketsDbDto): boolean {
    return this.prevTicketStatus !== ticket.columnId;
  }

  private isDueDateValid(): boolean {
    const date = this.ticketForm.controls['dueDate'].value;
    return moment(date).isValid() || date === null;
  }

  private isDateValid(): boolean {
    const start = this.ticketForm.controls['startDate'];
    const end = this.ticketForm.controls['dueDate'];
    return start.value !== null && end.value !== null && start.value < end.value;
  }

  private handleTicketsColumnsProcessing(
    message: string,
    type: string,
    showMessage: boolean = true,
  ): void {
    this.isUploading = false;
    if (showMessage) {
      this.toastr.clear();
      this.toastr.success(message, type);
    }
  }

  datePickedWithoutTime(event: boolean): void {
    this.timeIsEmpty = event;
  }

  addTime(data) {
    let addData = {};

    if (data.estimateValue || data.estimateUnit) {
      if (data?.estimateValue <= 0) {
        this.toastr.error(
          this.translocoService.translate('toastr.err-message-estimation-value'),
          this.translocoService.translate('toastr.title-error'),
        );
        this.hasError = true;
      } else if (!data.estimateUnit) {
        this.toastr.error(
          this.translocoService.translate('toastr.err-message-estimation-unit'),
          this.translocoService.translate('toastr.title-error'),
        );
        this.hasError = true;
      } else {
        addData = Object.assign(addData, {
          estimate: { value: data.estimateValue, unit: data.estimateUnit },
        });
      }
    }

    if (data.realTime) {
      const realTime = this.formatTime(data.realTime);
      if (realTime !== 0) {
        addData = Object.assign(addData, { realTime });
      }
    }

    /* if (
      typeof data.releaseDate !== 'undefined' &&
      data.releaseDate !== null &&
      !isNaN(data.releaseDate.year) &&
      !isNaN(data.releaseDate.month) &&
      !isNaN(data.releaseDate.day)
    ) {
      const releaseDate = new Date(data.releaseDate.year, data.releaseDate.month - 1, data.releaseDate.day, 0, 0, 0, 0);
      addData = Object.assign(addData, { releaseDate: releaseDate.toISOString() });
    } else {
      addData = Object.assign(addData, { releaseDate: null });
    } */

    return addData;
  }

  formatTime(timeString) {
    const regexTime = /((?<days>\d+)\s*(d))|((?<hours>\d+)\s*(h))/gi;
    const result = [...timeString.matchAll(regexTime)];
    let timeSeconds = 0;

    if (result.length > 0) {
      for (const search of result) {
        if (search.groups) {
          for (const groupName in search.groups) {
            if (typeof search.groups[groupName] !== 'undefined') {
              switch (groupName) {
                case 'days':
                  timeSeconds += search.groups[groupName] * 28800; // 8h
                  break;
                case 'hours':
                  timeSeconds += search.groups[groupName] * 3600; // 1h
                  break;
              }
            }
          }
        }
      }
    }

    if (timeSeconds === 0 && timeString !== '') {
      this.hasError = true;
      this.toastr.error(
        this.translocoService.translate('toastr.err-message-estimation-format'),
        this.translocoService.translate('toastr.title-error'),
      );
    }

    return timeSeconds;
  }

  deleteTimeFields(data) {
    this.deleteFields(data, ['estimateValue', 'estimateUnit', 'realTime']);
  }

  deleteFields(data, fieldsToDelete) {
    for (const fieldName in data) {
      if (fieldsToDelete.indexOf(fieldName) !== -1) {
        delete data[fieldName];
      }
    }
  }

  deleteTicket() {
    if (this.ticket) {
      ConfirmAlert(
        `${this.translocoService.translate('modals.board-ticket.epic')} "${this.ticket.title}"`,
        {
          platform: this.platform,
          confirmButtonText: this.translocoService.translate('alert.confirm-button'),
          cancelButtonText: this.translocoService.translate('alert.btn-cancel'),
          subject: this.translocoService.translate('alert.delete-ticket', {
            value: `${
              this.ticketData.isEpic
                ? this.translocoService.translate('modals.board-ticket.epic')
                : this.translocoService.translate('modals.board-ticket.ticket')
            } "${this.ticket.title}"`,
          }),
          text: this.translocoService.translate('alert.default-text'),
        },
      ).then(
        () => {
          this.store
            .dispatch(new TicketsDelete({ ticketDeleteId: this.modalData.id }))
            .pipe(takeUntil(this.destroy$), take(1))
            .subscribe(
              () => {
                this.handleTicketsColumnsProcessing(
                  this.translocoService.translate('modals.board-ticket.epic-deleted'),
                  'Success',
                );
                this.closeHandler();
              },
              (err) => {
                this.toastr.error(
                  err.message,
                  this.translocoService.translate('toastr.title-error'),
                );
              },
            );
        },
        () => {},
      );
    }
  }

  private setColumnBoardColor(columns: any[], columnId: string): string {
    const column: any = columns?.find((col: any) => col._id === columnId);
    return column?.boardColorClass ? column.boardColorClass : '';
  }

  fileChange($event): void {
    if ($event.target.files?.length) {
      const files = $event.target.files;
      for (let i = 0; i < files.length; i++) {
        this.fileData.push({
          fileName: files[i].name,
          originalFileName: files[i].name,
          ownerUserId: this.userData._id as string,
        });
        this.descriptionAttachmentsBlob.push(files[i]);
      }
    }
  }

  private prepareTicketImages(data: any): void {
    // TODO: refactor it to prepareMediaPreview()
    this.ticketMedia = [];
    const imagesUrls = this.mediaService.fetchImagesFromString(data.description);

    if (imagesUrls.length) {
      imagesUrls.forEach((imageUrl) => {
        this.ticketMedia.push({
          filePath: imageUrl,
          fileName: this.filesHelper.getFileNameWithoutExtension(imageUrl),
          fileType: this.filesHelper.getFileType(imageUrl),
          user: { id: data.ownerUserId, name: this.ticketCreatorName },
          updated_at: data.updated_at,
        });
      });

      this.ticketMedia[0].isFirstPreviewMedia = true;
      this.ticketMedia[this.ticketMedia.length - 1].isLastPreviewMedia = true;
    }
  }

  private handleEditorImageClick(target: any): void {
    if (
      !this.mediaService.checkIfAvatar(target?.classList) &&
      !!target.closest('.description-editor') &&
      this.modalData &&
      this.modalData?.data?.description
    ) {
      this.currentMediaPath = target.src;
      this.prepareTicketImages(this.modalData.data); // TODO: use prepareMediaPreview()
      this.previewMedia();
    }
  }

  private prepareMediaPreview(): void {
    this.ticketMedia = [];

    if (this.fileData?.length) {
      this.fileData.forEach((file) => {
        this.ticketMedia.push({
          filePath: file.url,
          id: file._id,
          fileName: file.originalFileName,
          fileType: this.filesHelper.getFileType(file.originalFileName),
          user: {
            id: this.modalData?.data?.ownerUserId,
            name: this.ticketCreatorName,
          },
          updated_at: this.modalData?.data['updated_at'],
        });
      });

      this.ticketMedia[0].isFirstPreviewMedia = true;
      this.ticketMedia[this.ticketMedia.length - 1].isLastPreviewMedia = true;
    }
  }

  previewMedia(): void {
    if (this.modalData?.id && this.ticketMedia.length) {
      this.mediaPreviewVisible = true;
    }
  }

  closeImagePreview(close: boolean): boolean {
    if (close) {
      this.currentMediaPath = '';
      this.ticketMedia = [];
      this.mediaPreviewVisible = false;
    }
    return close;
  }

  showMediaPreview(attachment: {
    _id?: string;
    fileName: string;
    originalFileName?: string;
    url?: string;
  }): void {
    this.currentMediaPath = attachment.url;
    this.prepareMediaPreview();
    this.previewMedia();
  }

  copyAttachmentLink(url) {
    this.htmlHelper.copyValueToBuffer(url);

    this.toastr.success(
      this.translocoService.translate('toastr.message-link-copied'),
      this.translocoService.translate('toastr.title-success'),
    );
  }

  downloadAttachment(document) {
    if (this.platformService.isCapacitor || this.platformService.isTauri) {
      this.downloadService.downloadFile(document);
    } else {
      this.filesHelper.downloadFile(document.url, document.originalFileName, this.platform);
    }
  }

  removeAttachment(index: number, fileName: string): void {
    const fileIndex = this.descriptionAttachmentsBlob.findIndex((file) => file.name === fileName);
    this.descriptionAttachmentsBlob.splice(fileIndex, 1);
    this.store
      .dispatch(new TicketsFilesDelete({ id: this.fileData[index]._id }))
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe(() => this.fileData.splice(index, 1));
  }

  getColumnById(columnId) {
    return this.columns?.find((item) => item._id === columnId);
  }

  getColumnColor(columnId) {
    return this.getColumnById(columnId)?.boardColorClass;
  }

  getColumnTitle(columnId) {
    const column = this.getColumnById(columnId);
    return column?.displayName || column?.title;
  }

  openTicket(ticket) {
    this.closeHandler();

    setTimeout(() => {
      const modalRef = this.modal.open(BoardTicketModalComponent, {
        size: 'xl',
        backdrop: 'static',
        keyboard: false,
        beforeDismiss: () => modalRef.componentInstance.closeImagePreview(true),
      });
      modalRef.componentInstance.ticketData = {
        id: ticket._id,
        object: ticket.object,
        objectId: ticket.objectId,
        isEpic: this.epicLabel ? this.epicLabel.isEpic : false,
      };
    }, 0);
  }

  closeSubtaskSelect() {
    this.showSubtaskSelect = !this.showSubtaskSelect;
    this.ticketForm.controls['selectedSubtask'].setValue(null);
  }

  addSubTask(taskId) {
    if (taskId !== 'new') {
      const ticket = this.tickets?.find((item) => item._id === taskId);
      this.updateParentId({ ...ticket, epicId: this.modalData.id }, this.ticketData.isEpic);
    } else {
      const columns = this.store.selectSnapshot(BoardsState.getColumnsList);
      const addData = {
        object: this.modalData.object,
        objectId: this.modalData.objectId,
        title: this.selectedSubtask.title,
        parentId: !this.ticketData.isEpic ? this.modalData.id : 'null',
        columnId: columns.find((column) => column.title === 'TODO')?._id,
        epicId: this.ticketData.isEpic ? this.modalData.id : '',
      };

      this.store
        .dispatch(new TicketsCreate(addData))
        .pipe(
          takeUntil(this.destroy$),
          withLatestFrom(this.store.select(BoardsState.getLastAddedTicketId)),
        )
        .subscribe(
          ([res, ticketId]) => {
            const ticket = res.Boards.tickets.find((item) => item._id === ticketId);
            const ticketAbbr = ticket ? `${ticket.boardAbbreviation}-${ticket.counter}` : '';

            if (this.modalData?.showToastMessage) {
              this.toastr.clear();
              this.toastr.success(
                this.translocoService.translate('toastr.message-ticket-created', {
                  text: ticketAbbr,
                }),
                this.translocoService.translate('toastr.title-success'),
                { enableHtml: true },
              );
              this.modalData.showToastMessage = false;
            }

            this.handleTicketsColumnsProcessing(null, null, false);
            this.childrenList = [...(this.childrenList || []), ticket];
            this.subTicketsEpic = [...(this.subTicketsEpic || []), ticket];
          },
          (err) => {
            this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
          },
        );
    }
    this.ticketForm.controls['selectedSubtask'].setValue(null);
  }

  removeSubTask(ticket) {
    this.updateParentId(ticket, this.ticketData.isEpic, false);
  }

  updateParentId(ticket, isEpic = false, addParent = true) {
    const body = {
      parentId: addParent && !isEpic ? this.ticketData.id : 'null',
      description: ticket.description,
      epicId: !isEpic ? null : addParent ? ticket.epicId : 'null',
    };

    this.store
      .dispatch(new TicketsUpdate({ ticketUpdateId: ticket._id, body }))
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe(
        () => {
          if (addParent) {
            this.childrenList = [...(this.childrenList || []), ticket];
            this.subTicketsEpic = isEpic
              ? [...(this.subTicketsEpic || []), ticket]
              : this.subTicketsEpic;
            this.tickets = this.tickets?.filter((item) => item._id !== ticket._id);
            this.ticketsSelect = this.ticketsSelect.filter((item) => item._id !== ticket._id);
          } else {
            this.tickets = [...this.tickets, ticket].sort((a, b) =>
              a.counter > b.counter ? 1 : -1,
            );
            this.childrenList =
              !isEpic && this.childrenList?.filter((item) => item._id !== ticket._id);
            this.ticketsSelect = this.ticketsSelect.filter((item) => item._id !== ticket._id);
            this.subTicketsEpic = isEpic
              ? this.subTicketsEpic?.filter((item) => item._id !== ticket._id)
              : this.subTicketsEpic;
          }
        },
        (err) => {
          this.isUploading = false;
          this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
        },
      );
  }
  clearEstimate() {
    this.ticketForm.controls['estimateValue'].setValue(null);
  }

  get noneColorBoardTicket() {
    return (
      (!this.ticketForm.value.bgColor && !this.settings?.bgColor) ||
      this.ticketForm.value.bgColor === '#ffffff' ||
      this.settings?.bgColor === '#ffffff'
    );
  }

  get colorBoardTicket() {
    return this.ticketForm.value.bgColor ? this.ticketForm.value.bgColor : this.settings?.bgColor;
  }

  setIsPastDate(value: boolean) {
    this.isPastDate = value;
  }
}
