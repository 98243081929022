import isEmail from 'validator/lib/isEmail';

import { Component, OnInit, forwardRef } from '@angular/core';
import { Subject, combineLatest, Observable, firstValueFrom, merge } from 'rxjs';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UsersPublicFieldsResDto } from '../../api/models/users-public-fields-res-dto';
import { CheckPermissionPipe } from '../../shared/pipes/check-permission.pipe';
import { DateTimeHelper } from '../../shared/utils/date-time-helper';
import { QuillInitializeService } from '../../shared/services/quill/quill-init.service';
import {
  CalendarEventCreate,
  CalendarEventDelete,
  CalendarEventsGetById,
  CalendarEventsUpdate,
  CalendarEventsUpdateSingle,
  CreateExceptionTaskDay,
  CreateTask,
  DeleteTask,
  GetTaskDetails,
  SaveAvailableUsers,
  UpdateTask,
} from '../../shared/store/actions/calendar-events.action';
import { SpaceGetUsersList } from '../../shared/store/actions/spaces.action';
import { ProjectGetUsersList } from '../../shared/store/actions/projects.action';
import { AuthState } from '../../shared/store/states/auth.state';
import { SpacesState } from '../../shared/store/states/spaces.state';
import { ProjectsState } from '../../shared/store/states/projects.state';
import { CalendarEventsState } from '../../shared/store/states/calendar-events.state';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateTimePickerComponent } from '../../shared/components/date-time-picker/date-time-picker.component';
import { QuillEditorComponent } from 'ngx-quill';
import { AvatarComponent } from '../../standalone/components/avatar/avatar.component';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { CalendarDayModule, CalendarEvent } from 'angular-calendar';
import { Nullish } from '../../shared/utils/types';
import { CalendarEventViewEvent } from './calendar-view/calendar-event-view.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { CalendarEventViewModule } from './calendar-view/calendar-event-view.module';
import { ToastrService } from 'ngx-toastr';
import { CalendarEventsGetListResDto } from '../../api/models';
import moment from 'moment-timezone';
import { CustomValidators } from './validators';
import { ColumnsGetList } from '../../shared/store/actions/board.action';
import { ConfirmAlert } from '../../shared/alerts/alerts';

interface TeamplateEvent extends CalendarEvent {
  meta: {
    eventType: 'events';
    object: string;
    objectId: string;
    userName: string;
    description: string;
    place: string;
    guests: string[];
    workdays: boolean;
    timezone: string;
    repeat: string;
    reminder: string;
    addCall: boolean;
    sendEmail: boolean;
    groupingEvents?: {
      events: (TeamplateEvent | TeamplateTicket)[];
    };
  };
}

interface TeamplateTicket extends CalendarEvent {
  meta: {
    eventType: 'tickets';
    objectId: string;
    userName: string;
    object: string;
    groupingEvents?: {
      events: (TeamplateEvent | TeamplateTicket)[];
    };
  };
}

interface TeamplateTask extends CalendarEvent {
  meta: {
    eventType: 'tasks';
    objectId: string;
    userName: string;
    object: string;
    groupingEvents?: {
      events: TeamplateTask[];
    };
  };
}

enum CalendarModalActions {
  AddNewEvent = 'Add new event',
  ViewEvent = 'View event',
}

export enum CalendarControlTypes {
  Users = 'users',
  Spaces = 'spaces',
  Projects = 'projects',
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-calendar-event-modal',
  templateUrl: './calendar-edit-event.component.html',
  styleUrls: ['./calendar-edit-event.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    SvgIconComponent,
    ReactiveFormsModule,

    TranslocoDirective,
    CalendarDayModule,
    QuillEditorComponent,
    DateTimePickerComponent,

    forwardRef(() => AvatarComponent),

    CalendarEventViewModule,
  ],
})
export class CalendarEditEventComponent implements OnInit {
  private readonly ICON_BASE_URL = 'assets/icons/calendar';

  modalData!: {
    action: string;
    displayName?: string;
    event: TeamplateEvent | TeamplateTicket | TeamplateTask;
    status?: string;
    chatMessageId?: string;
    noteId?: string;
    stopChatReminder?: boolean;
  };

  eventMoreOptionsIsOpened = false;

  currentTimezone!: string;
  userData: UsersPublicFieldsResDto;
  formUpdated = new Subject<CalendarEventViewEvent>();

  quillEditorModules: {} = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['link', 'image'],
      ['clean'],
    ],
    magicUrl: true,
    placeholder: this.translocoService.translate('modals.calendar-event.description'),
  };

  private readonly reminderOptions = [
    {
      id: 'without',
      name: this.translocoService.translate('modals.calendar-event.without'),
    },
    {
      id: '0m',
      name: this.translocoService.translate('modals.calendar-event.at-the-time'),
    },
    {
      id: '5m',
      name: this.translocoService.translate('modals.calendar-event.five-min'),
    },
    {
      id: '10m',
      name: this.translocoService.translate('modals.calendar-event.ten-min'),
    },
    {
      id: '15m',
      name: this.translocoService.translate('modals.calendar-event.fifteen-min'),
    },
    {
      id: '30m',
      name: this.translocoService.translate('modals.calendar-event.thirty-min'),
    },
    {
      id: '1h',
      name: this.translocoService.translate('modals.calendar-event.one-hour'),
    },
    {
      id: '2h',
      name: this.translocoService.translate('modals.calendar-event.two-hour'),
    },
    {
      id: '1d',
      name: this.translocoService.translate('modals.calendar-event.one-day'),
    },
    {
      id: '2d',
      name: this.translocoService.translate('modals.calendar-event.two-day'),
    },
    {
      id: '1w',
      name: this.translocoService.translate('modals.calendar-event.one-week'),
    },
  ] as const;

  private readonly repeatOptions = [
    {
      id: 'never',
      name: this.translocoService.translate('modals.calendar-event.never'),
    },
    {
      id: 'daily',
      name: this.translocoService.translate('modals.calendar-event.daily'),
    },
    {
      id: 'weekly',
      name: this.translocoService.translate('modals.calendar-event.weekly'),
    },
    {
      id: 'every 2 weeks',
      name: this.translocoService.translate('modals.calendar-event.two-week'),
    },
    {
      id: 'monthly',
      name: this.translocoService.translate('modals.calendar-event.monthly'),
    },
    {
      id: 'yearly',
      name: this.translocoService.translate('modals.calendar-event.yearly'),
    },
  ] as const;

  private timezones: {
    value: string;
    label: string;
    utc: string;
  }[] = this.dtHelper.getFormattedTimezones();

  private localTimezone = moment.tz.guess();

  private privacyOptions = [
    {
      id: 'public',
      name: this.translocoService.translate('modals.calendar-event.public'),
    },
    {
      id: 'private',
      name: this.translocoService.translate('modals.calendar-event.private'),
    },
  ] as const;

  private objectTypes = [
    { id: 'users', label: 'User' },
    { id: 'spaces', label: 'Space' },
    { id: 'projects', label: 'Project' },
  ];

  get selectedObjectType(): (typeof this.objectTypes)[number]['id'] {
    return this.form.controls.objectType.value;
  }

  form = new FormGroup({
    objectType: new FormControl<(typeof this.objectTypes)[number]['id']>(this.objectTypes[0].id, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    space: new FormControl<Nullish<string>>(undefined, {
      nonNullable: true,
    }),
    project: new FormControl<Nullish<string>>(undefined, {
      nonNullable: true,
    }),
    title: new FormControl<Nullish<string>>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    type: new FormControl<'event' | 'task'>('event', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    guests: new FormControl<string[]>([], {
      nonNullable: true,
    }),
    sendEmailInvitation: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    fromDate: new FormControl<Nullish<string>>(undefined, {
      nonNullable: true,
      validators: [Validators.required, CustomValidators.isDate],
    }),
    toDate: new FormControl<Nullish<string>>(undefined, {
      nonNullable: true,
      validators: [CustomValidators.isDate],
    }),
    from: new FormControl<Nullish<Date>>(undefined, {
      nonNullable: true,
      validators: [Validators.required, CustomValidators.isDate],
    }),
    to: new FormControl<Nullish<Date>>(undefined, {
      nonNullable: true,
      validators: [Validators.required, CustomValidators.isDate],
    }),
    stopRepeatAt: new FormControl<Nullish<string>>(undefined, {
      nonNullable: true,
      validators: [CustomValidators.isDate],
    }),
    onlyWorkDays: new FormControl<boolean>(true),
    startTime: new FormControl<Nullish<string>>(undefined, {
      nonNullable: true,
      validators: [Validators.required, CustomValidators.isTime],
    }),
    endTime: new FormControl<Nullish<string>>(undefined, {
      nonNullable: true,
      validators: [Validators.required, CustomValidators.isTime],
    }),
    allDay: new FormControl<boolean>(false),
    timezone: new FormControl<Nullish<string>>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    place: new FormControl<Nullish<string>>(undefined, {
      nonNullable: true,
    }),
    addVideoCall: new FormControl<boolean>(false),
    repeat: new FormControl<string>(this.repeatOptions[0].id, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    reminder: new FormControl<string>(this.reminderOptions[0].id, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    description: new FormControl<Nullish<string>>(undefined, {
      nonNullable: true,
    }),
  });

  private spaces: Observable<{ id: string; spaceName: string }[]> = this.store
    .select(SpacesState.getLoadedSpaces)
    .pipe(
      map<
        { _id: string; spaceName: string; isPersonal: boolean }[],
        { id: string; spaceName: string }[]
      >((result) =>
        result
          .filter(
            (item) =>
              !item.isPersonal &&
              this.checkPermissionPipe.transform('spaces::' + item._id + '::calendarEventCreate'),
          )
          .map((result) => ({ spaceName: result.spaceName, id: result._id })),
      ),
    );

  private projects = this.store.select(ProjectsState.getLoadedProjects).pipe(
    map<
      { _id: string; projectName: string; spaceId: string }[],
      { id: string; projectName: string; spaceId: string }[]
    >((projects) =>
      projects
        .filter((project) =>
          this.checkPermissionPipe.transform('projects::' + project._id + '::calendarEventCreate'),
        )
        .map((project) => ({
          projectName: project.projectName,
          id: project._id,
          spaceId: project.spaceId,
        })),
    ),
  );

  vm$ = combineLatest([
    this.store.select(CalendarEventsState.getComponentData),
    this.spaces,
    this.projects,
  ]).pipe(
    map(([data, spaces, projects]) => ({
      objectTypes: this.objectTypes.map((objectType) => ({
        label: objectType.label,
        value: objectType.id,
      })),
      spaces: spaces.map((space) => ({ label: space.spaceName, value: space.id })),
      projects: projects.map((project) => ({ label: project.projectName, value: project.id })),
      guests: data.guests.map((guest) => ({ label: guest.name, value: guest.id })),
      timezones: this.timezones,
      localTimezone: this.localTimezone,
      repeat: this.repeatOptions.map((repeat) => ({ label: repeat.name, value: repeat.id })),
      reminder: this.reminderOptions.map((reminder) => ({
        label: reminder.name,
        value: reminder.id,
      })),
      privacy: this.privacyOptions.map((privacy) => ({ label: privacy.name, value: privacy.id })),
    })),
  );

  get modalEvent(): CalendarEventViewEvent {
    return {
      id: this.modalData.event.id == undefined ? undefined : `${this.modalData.event.id}`,
      end: this.form.controls.to.value,
      start: this.form.controls.from.value,
      allDay: this.form.controls.allDay.value,
      guests: this.form.controls.guests.value,
      title: this.form.controls.title.value,
    };
  }

  constructor(
    private activeModal: NgbActiveModal,
    private store: Store,
    private toastr: ToastrService,
    private checkPermissionPipe: CheckPermissionPipe,
    private quillInitializeService: QuillInitializeService,
    private dtHelper: DateTimeHelper,
    private translocoService: TranslocoService,
  ) {
    this.form.controls.startTime.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value === '') {
        return;
      }

      const [hours, minutes] = value.split(':');

      const [years, months, days] = (
        this.form.controls.from.invalid
          ? this.form.controls.fromDate.value
          : moment(this.form.controls.from.value).format('yyyy-MM-DD')
      ).split('-');

      const date = moment(`${years}-${months}-${days}T${hours}:${minutes}:00.000`);

      this.form.controls.from.setValue(date.toDate());
    });

    this.form.controls.fromDate.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value === '') {
        return;
      }

      const [years, months, days] = value.split('-');

      const [hours, minutes] = (
        this.form.controls.from.invalid
          ? this.form.controls.endTime.value
          : moment(this.form.controls.from.value).format('HH:mm')
      ).split(':');

      const date = moment(`${years}-${months}-${days}T${hours}:${minutes}:00.000`);

      this.form.controls.from.setValue(date.toDate());
    });

    this.form.controls.endTime.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value === '') {
        return;
      }

      const [hours, minutes] = value.split(':');

      const [years, months, days] = (
        this.form.controls.to.invalid
          ? this.form.controls.toDate.value
          : moment(this.form.controls.to.value).format('yyyy-MM-DD')
      ).split('-');

      const date = moment(`${years}-${months}-${days}T${hours}:${minutes}:00.000`);

      this.form.controls.to.setValue(date.toDate());
    });

    this.form.controls.toDate.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value === '') {
        return;
      }

      const [years, months, days] = value.split('-');

      const [hours, minutes] = (
        this.form.controls.to.invalid
          ? this.form.controls.endTime.value
          : moment(this.form.controls.to.value).format('HH:mm')
      ).split(':');

      const date = moment(`${years}-${months}-${days}T${hours}:${minutes}:00.000`);

      this.form.controls.to.setValue(date.toDate());
    });

    this.form.controls.timezone.valueChanges.subscribe((value) => {
      const oldOffset = moment(this.form.controls.from.value).utcOffset();

      moment.tz.setDefault(value);

      const newOffset = moment(this.form.controls.from.value).utcOffset();

      const fixedOffset = newOffset - oldOffset;

      const from = moment(this.form.controls.from.value).subtract(fixedOffset, 'minutes');
      const to = moment(this.form.controls.to.value).subtract(fixedOffset, 'minutes');

      this.form.patchValue(
        {
          startTime: from.format('HH:mm'),
          endTime: to.format('HH:mm'),
          toDate: from.format('YYYY-MM-DD'),
          fromDate: to.format('YYYY-MM-DD'),
          from: from.toDate(),
          to: to.toDate(),
        },
        { emitEvent: false, onlySelf: true },
      );

      this.formUpdated.next({
        id: this.modalData.event.id == undefined ? undefined : `${this.modalData.event.id}`,
        end: this.form.value.to,
        start: this.form.value.from,
        allDay: this.form.value.allDay,
        guests: this.form.value.guests,
        title: this.form.value.title,
      });
    });

    merge(
      this.form.controls.guests.valueChanges.pipe(map((guests) => ({ guests }))),
      this.form.controls.allDay.valueChanges.pipe(map((allDay) => ({ allDay }))),
      this.form.controls.from.valueChanges.pipe(map((start) => ({ start }))),
      this.form.controls.to.valueChanges.pipe(map((end) => ({ end }))),
      this.form.controls.title.valueChanges.pipe(map((title) => ({ title }))),
    )
      .pipe(untilDestroyed(this), debounceTime(300))
      .subscribe(
        (
          value: Partial<{
            end: Date;
            start: Date;
            allDay: boolean;
            guests: string[];
            title: string;
          }>,
        ) => {
          this.formUpdated.next({
            id: this.modalData.event.id == undefined ? undefined : `${this.modalData.event.id}`,
            end: value.end ?? this.form.value.to,
            start: value.start ?? this.form.value.from,
            allDay: value.allDay ?? this.form.value.allDay,
            guests: value.guests ?? this.form.value.guests,
            title: value.title ?? this.form.value.title,
          });
        },
      );
  }

  ngOnInit() {
    // First get user data
    firstValueFrom(this.store.select(AuthState.getUser)).then((user) => {
      this.userData = user;
      this.currentTimezone = user.timezone;

      // Initialize form with default values
      this.initializeForm();

      // Set up form control subscriptions
      this.setupFormSubscriptions();

      // Load initial users list based on selected space/project
      this.loadInitialUsersList();
    });
  }

  private initializeForm() {
    if (this.modalData.event.id == undefined) {
      let payload: {
        eventType: 'events' | 'tickets' | 'tasks';
        userName: string;
        object: string;
        objectId: string;
        description?: string;
        place?: string;
        guests?: string[];
        workdays?: boolean;
        timezone?: string;
        repeat?: string;
        reminder?: string;
        addCall?: boolean;
        sendEmail?: boolean;
      } = {
        eventType: this.modalData.event.meta.eventType,
        object: this.modalData.event.meta.object,
        objectId: this.modalData.event.meta.objectId,
        userName: this.modalData.event.meta.userName,
      };

      if (this.modalData.event.meta.eventType === 'events') {
        payload = {
          ...payload,
          description: this.modalData.event.meta.description,
          place: this.modalData.event.meta.place,
          guests: this.modalData.event.meta.guests,
          workdays: this.modalData.event.meta.workdays,
          timezone: this.modalData.event.meta.timezone,
          repeat: this.modalData.event.meta.repeat,
          reminder: this.modalData.event.meta.reminder,
          addCall: this.modalData.event.meta.addCall,
          sendEmail: this.modalData.event.meta.sendEmail,
        };
      }

      this.form.patchValue(
        {
          objectType: this.modalData.event.meta.object,

          space: this.modalData.event.meta.objectId,
          project: this.modalData.event.meta.objectId,

          startTime: moment(this.modalData.event.start).format('HH:mm'),
          endTime: moment(this.modalData.event.end).format('HH:mm'),

          fromDate: moment(this.modalData.event.start).format('YYYY-MM-DD'),
          toDate: moment(this.modalData.event.end).format('YYYY-MM-DD'),

          from: this.modalData.event.start,
          to: this.modalData.event.end,

          title: this.modalData.event.title,
          description: payload.description,
          place: payload.place,
          // INFO(Basilio): Guest filtered by current user because on backend the guests array includes the current user
          guests: payload.guests?.filter((guest) => this.userData._id !== guest) ?? [],
          allDay: this.modalData.event.allDay ?? false,
          timezone: payload.timezone ?? this.currentTimezone,
          repeat: payload.repeat ?? this.repeatOptions[0].id,
          addVideoCall: payload.addCall ?? false,
          sendEmailInvitation: payload.sendEmail ?? false,
          reminder: payload.reminder,
        },
        { emitEvent: false, onlySelf: true },
      );

      this.formUpdated.next({
        id: this.modalData.event.id == undefined ? undefined : `${this.modalData.event.id}`,
        end: this.form.value.to,
        start: this.form.value.from,
        allDay: this.form.value.allDay,
        guests: this.form.value.guests,
        title: this.form.value.title,
      });
    }
  }

  private setupFormSubscriptions() {
    // Existing space subscription
    this.form.controls.space.valueChanges
      .pipe(
        untilDestroyed(this),
        filter((space) => space != undefined),
        switchMap((space) =>
          this.store.dispatch(
            new SpaceGetUsersList({ exists: true, id: space, isInternalState: false }),
          ),
        ),
      )
      .subscribe(({ Spaces }) => {
        if (Spaces?.users) {
          this.store.dispatch(
            new SaveAvailableUsers({
              users: Spaces.users
                .filter(({ userName }) => this.userData.userName !== userName)
                .map(({ _id, userName }) => ({ id: _id, name: userName })),
            }),
          );
        }
      });

    // Existing project subscription
    this.form.controls.project.valueChanges
      .pipe(
        untilDestroyed(this),
        filter((project) => project != undefined),
        switchMap((project) =>
          this.store.dispatch(
            new ProjectGetUsersList({ exists: true, id: project, isInternalState: false }),
          ),
        ),
      )
      .subscribe(({ Projects }) => {
        if (Projects?.users) {
          this.store.dispatch(
            new SaveAvailableUsers({
              users: Projects.users
                .filter(({ userName }) => this.userData.userName !== userName)
                .map(({ _id, userName }) => ({ id: _id, name: userName })),
            }),
          );
        }
      });

    // Clear users when switching to users type
    this.form.controls.objectType.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value === 'users') {
        this.store.dispatch(new SaveAvailableUsers({ users: [] }));
      }
    });
  }

  private loadInitialUsersList() {
    const objectType = this.form.get('objectType')?.value;
    const spaceId = this.form.get('space')?.value;
    const projectId = this.form.get('project')?.value;

    if (objectType === 'spaces' && spaceId) {
      this.store
        .dispatch(
          new SpaceGetUsersList({
            exists: true,
            id: spaceId,
            isInternalState: false,
          }),
        )
        .subscribe(({ Spaces }) => {
          if (Spaces?.users) {
            this.store.dispatch(
              new SaveAvailableUsers({
                users: Spaces.users
                  .filter(({ userName }) => this.userData.userName !== userName)
                  .map(({ _id, userName }) => ({ id: _id, name: userName })),
              }),
            );
          }
        });
    } else if (objectType === 'projects' && projectId) {
      this.store
        .dispatch(
          new ProjectGetUsersList({
            exists: true,
            id: projectId,
            isInternalState: false,
          }),
        )
        .subscribe(({ Projects }) => {
          if (Projects?.users) {
            this.store.dispatch(
              new SaveAvailableUsers({
                users: Projects.users
                  .filter(({ userName }) => this.userData.userName !== userName)
                  .map(({ _id, userName }) => ({ id: _id, name: userName })),
              }),
            );
          }
        });
    }
  }

  editorDescriptionCreated(editorDescription): void {
    QuillInitializeService.handleEditorCreated(editorDescription);
  }

  changeType(type: 'event' | 'task') {
    this.form.controls.type.setValue(type);
  }

  getIcon(name: string): string {
    return `${this.ICON_BASE_URL}/${name}.svg`;
  }

  getEventType(): string {
    return this.form.controls.type.value;
  }

  eventChanged({ end, start }: { start: Date; end: Date }) {
    this.form.controls.startTime.setValue(moment(start).format('HH:mm'));
    this.form.controls.endTime.setValue(moment(end).format('HH:mm'));

    this.formUpdated.next(this.modalEvent);
  }

  close(saved?: boolean) {
    if (!saved && this.form.dirty) {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.close-modal-subject'),
        text: this.translocoService.translate('alert.close-modal-text'),
        cancelButtonText: this.translocoService.translate('alert.close-modal-btn-close'),
        showDenyButton: true,
        denyButtonText: this.translocoService.translate('alert.close-modal-btn-discard'),
        denyButtonClass: 'btn-subtle',
        confirmButtonText: this.translocoService.translate('alert.close-modal-btn-save'),
        confirmButtonClass: 'btn-solid',
        platform: 'web',
      }).then((result) => {
        if (result === 'isConfirmed') {
          this.save();
        } else {
          this.activeModal.close();
        }
      });
    } else {
      this.activeModal.close();
    }
  }

  save() {
    this.form.updateValueAndValidity();

    const values = this.form.value;

    if (this.form.controls.title.invalid) {
      this.toastr.error(
        this.translocoService.translate('modals.calendar-event.errors.title-required'),
      );
      return;
    }

    if (values.type === 'event') {
      this.saveEvent();
    } else if (values.type === 'task') {
      this.saveTask();
    }
  }

  delete() {
    this.form.updateValueAndValidity();

    const values = this.form.value;

    if (values.type === 'event') {
      this.deleteEvent();
    } else if (values.type === 'task') {
      this.deleteTask();
    }
  }

  deleteEvent() {
    this.form.updateValueAndValidity();

    const values = this.form.value;

    const externalGuests = values.guests.filter((guest) => isEmail(guest));
    const internalGuests = values.guests.filter((guest) => !isEmail(guest));

    const event = {
      id: this.modalData.event.id,
      title: values.title,
      end: values.to.toISOString(),
      start: values.from.toISOString(),
      addCall: values.addVideoCall,
      allDay: values.allDay,
      description: !values.description ? undefined : values.description,
      externalEmails: externalGuests,
      guests: internalGuests,
      place: values.place,
      reminder: values.reminder as any,
      repeat: values.repeat as any,
      sendEmail: values.sendEmailInvitation,
      stopRemind: [],
      timezone: values.timezone,
      object: values.objectType,
      objectId: values.objectType === 'spaces' ? values.space : values.project,
      stopRepeatAt: values.stopRepeatAt,
      onlyWorkDays: values.onlyWorkDays,
      remindAsChatMessage: false,

      noteId: this.modalData.noteId,
      chatMessageId: this.modalData.chatMessageId,
    };

    if (values.repeat !== 'never') {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.delete-multiple-event-subject'),
        text: this.translocoService.translate('alert.non-revert-action'),
        showDenyButton: true,
        denyButtonText: this.translocoService.translate('alert.delete-single-event'),
        confirmButtonText: this.translocoService.translate('alert.delete-multiples-event'),
        cancelButtonText: this.translocoService.translate('alert.btn-cancel'),
        confirmButtonClass: 'btn-success',
        denyButtonClass: 'btn-solid',
        platform: 'web',
      })
        .then((result) => {
          if (result === 'isConfirmed') {
            firstValueFrom(
              this.store.dispatch(new CalendarEventDelete({ id: this.modalData.event.id })),
            ).then(() => this.close(true));
          } else {
            this.store.dispatch(
              new CalendarEventsUpdateSingle({
                ...event,
                calendarEventId: this.modalData.event.id,
                exceptionDays: [this.modalData.event.start.toISOString()],
              }),
            );
          }
        })
        .then(() => this.close(true));
    } else {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.delete-event'),
        text: this.translocoService.translate('alert.non-revert-action'),
        showDenyButton: false,
        confirmButtonText: this.translocoService.translate('alert.confirm-button'),
        confirmButtonClass: 'btn-success',
        denyButtonClass: 'btn-solid',
        platform: 'web',
      })
        .then((result) => {
          if (result === 'isConfirmed') {
            firstValueFrom(
              this.store.dispatch(new CalendarEventDelete({ id: this.modalData.event.id })),
            ).then(() => this.close(true));
          }
        })
        .then(() => this.close(true));
    }
  }

  deleteTask() {
    this.form.updateValueAndValidity();

    const values = this.form.value;

    const task = {
      id: this.modalData.event.id,
      title: values.title,
      object: values.objectType === 'users' ? 'spaces' : values.objectType,
      objectId: values.objectType === 'projects' ? values.project : values.space,
      start: values.from.toISOString(),
      ...(values.allDay === false && { end: moment(values.from).add(1, 'hour').toISOString() }),
      allDay: values.allDay,
      description: !values.description ? undefined : values.description,
      repeat: values.repeat,
      stopRepeatAt: values.stopRepeatAt,
      onlyWorkingDays: values.onlyWorkDays,
    };

    if (values.repeat !== 'never') {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.delete-multiple-tasks-subject'),
        text: this.translocoService.translate('alert.non-revert-action'),
        showDenyButton: true,
        denyButtonText: this.translocoService.translate('alert.delete-single-task'),
        confirmButtonText: this.translocoService.translate('alert.delete-multiples-event'),
        cancelButtonText: this.translocoService.translate('alert.btn-cancel'),
        confirmButtonClass: 'btn-success',
        denyButtonClass: 'btn-solid',
        platform: 'web',
      })
        .then((result) => {
          if (result === 'isConfirmed') {
            firstValueFrom(
              this.store.dispatch(
                new DeleteTask({
                  id: this.modalData.event.id,
                  object: values.objectType === 'users' ? 'spaces' : values.objectType,
                  objectId: values.objectType === 'projects' ? values.project : values.space,
                }),
              ),
            ).then(() => this.close(true));
          } else {
            this.store
              .dispatch(
                new CreateExceptionTaskDay({
                  ...task,
                  start: this.modalData.event.start.toISOString(),
                  end: this.modalData.event.end.toISOString(),
                }),
              )
              .subscribe(() => this.close(true));
          }
        })
        .then(() => this.close(true));
    } else {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.delete-ticket'),
        text: this.translocoService.translate('alert.non-revert-action'),
        showDenyButton: false,
        confirmButtonText: this.translocoService.translate('alert.confirm-button'),
        confirmButtonClass: 'btn-success',
        denyButtonClass: 'btn-solid',
        platform: 'web',
      })
        .then((result) => {
          if (result === 'isConfirmed') {
            firstValueFrom(
              this.store.dispatch(
                new DeleteTask({
                  id: this.modalData.event.id,
                  object: values.objectType === 'users' ? 'spaces' : values.objectType,
                  objectId: values.objectType === 'projects' ? values.project : values.space,
                }),
              ),
            ).then(() => this.close(true));
          }
        })
        .then(() => this.close(true));
    }
  }

  saveTask() {
    const values = this.form.value;

    if (this.form.controls.fromDate.errors?.required) {
      this.toastr.error(
        this.translocoService.translate('modals.calendar-event.errors.fromDate-required'),
      );
      return;
    } else if (this.form.controls.fromDate.errors?.invalidDate) {
      this.toastr.error(
        this.translocoService.translate('modals.calendar-event.errors.fromDate-invalid'),
      );
      return;
    } else if (this.form.controls.startTime.errors?.required) {
      this.toastr.error(
        this.translocoService.translate('modals.calendar-event.errors.startTime-required'),
      );
      return;
    } else if (this.form.controls.startTime.errors?.invalidTime) {
      this.toastr.error(
        this.translocoService.translate('modals.calendar-event.errors.startTime-invalid'),
      );
      return;
    }

    const task = {
      id: this.modalData.event.id,
      title: values.title,
      object: values.objectType === 'users' ? 'spaces' : values.objectType,
      objectId: values.objectType === 'projects' ? values.project : values.space,
      start: values.from.toISOString(),
      ...(values.allDay === false && { end: moment(values.from).add(1, 'hour').toISOString() }),
      allDay: values.allDay,
      description: !values.description ? undefined : values.description,
      repeat: values.repeat,
      stopRepeatAt: values.stopRepeatAt,
      onlyWorkingDays: values.onlyWorkDays,
    };

    if (values.repeat !== 'never' && !this.isCreating()) {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.event-editing-subject'),
        text: this.translocoService.translate('alert.event-editing-text'),
        showDenyButton: true,
        denyButtonText: this.translocoService.translate('alert.event-editing-deny-btn'),
        confirmButtonText: this.translocoService.translate('alert.event-editing-confirm-btn'),
        cancelButtonText: this.translocoService.translate('alert.btn-cancel'),
        confirmButtonClass: 'btn-success',
        denyButtonClass: 'btn-solid',
        platform: 'web',
      })
        .then((result) => {
          if (result === 'isConfirmed') {
            firstValueFrom(
              this.store.dispatch(
                new CreateExceptionTaskDay({
                  ...task,
                  start: this.modalData.event.start.toISOString(),
                  end: this.modalData.event.end.toISOString(),
                  createNewTask: true,
                }),
              ),
            )
              .then(() => this.close(true))
              .catch((error) => {
                this.toastr.error(
                  this.translocoService.translate(
                    'modals.calendar-event.errors.unable-to-save-task',
                  ),
                );
              });
          } else {
            firstValueFrom(this.store.dispatch(new UpdateTask(task)))
              .then(() => this.close(true))
              .catch((error) => {
                this.toastr.error(
                  this.translocoService.translate(
                    'modals.calendar-event.errors.unable-to-save-task',
                  ),
                );
              });
          }
        })
        .then(() => this.close(true));
    } else if (this.isCreating()) {
      firstValueFrom(this.store.dispatch(new CreateTask(task)))
        .then(() => this.close(true))
        .catch((error) => {
          this.toastr.error(
            this.translocoService.translate('modals.calendar-event.errors.unable-to-save-task'),
          );
        });
    } else {
      firstValueFrom(this.store.dispatch(new UpdateTask(task)))
        .then(() => this.close(true))
        .catch((error) => {
          this.toastr.error(
            this.translocoService.translate('modals.calendar-event.errors.unable-to-save-task'),
          );
        });
    }
  }

  isCreating() {
    return this.modalData.action === CalendarModalActions.AddNewEvent;
  }

  saveEvent() {
    const values = this.form.value;

    if (this.form.controls.fromDate.errors?.required) {
      this.toastr.error(
        this.translocoService.translate('modals.calendar-event.errors.fromDate-required'),
      );
      return;
    } else if (this.form.controls.fromDate.errors?.invalidDate) {
      this.toastr.error(
        this.translocoService.translate('modals.calendar-event.errors.fromDate-invalid'),
      );
      return;
    } else if (this.form.controls.startTime.errors?.required) {
      this.toastr.error(
        this.translocoService.translate('modals.calendar-event.errors.startTime-required'),
      );
      return;
    } else if (this.form.controls.startTime.errors?.invalidTime) {
      this.toastr.error(
        this.translocoService.translate('modals.calendar-event.errors.startTime-invalid'),
      );
      return;
    } else if (this.form.controls.toDate.errors?.required) {
      this.toastr.error(
        this.translocoService.translate('modals.calendar-event.errors.toDate-required'),
      );
      return;
    } else if (this.form.controls.toDate.errors?.invalidDate) {
      this.toastr.error(
        this.translocoService.translate('modals.calendar-event.errors.toDate-invalid'),
      );
      return;
    } else if (this.form.controls.endTime.errors?.required) {
      this.toastr.error(
        this.translocoService.translate('modals.calendar-event.errors.endTime-required'),
      );
      return;
    } else if (this.form.controls.endTime.errors?.invalidTime) {
      this.toastr.error(
        this.translocoService.translate('modals.calendar-event.errors.endTime-invalid'),
      );
      return;
    } else if (moment(values.from).isAfter(values.to)) {
      this.toastr.error(
        this.translocoService.translate('modals.calendar-event.errors.from-greater-than-to'),
      );
      return;
    }

    const externalGuests = values.guests.filter((guest) => isEmail(guest));
    const internalGuests = values.guests.filter((guest) => !isEmail(guest));

    const event = {
      id: this.modalData.event.id,
      title: values.title,
      end: values.to.toISOString(),
      start: values.from.toISOString(),
      addCall: values.addVideoCall,
      allDay: values.allDay,
      description: !values.description ? undefined : values.description,
      externalEmails: externalGuests,
      guests: internalGuests,
      place: values.place,
      reminder: values.reminder as any,
      repeat: values.repeat as any,
      sendEmail: values.sendEmailInvitation,
      stopRemind: [],
      timezone: values.timezone,
      object: values.objectType,
      objectId: values.objectType === 'spaces' ? values.space : values.project,
      stopRepeatAt: values.stopRepeatAt,
      onlyWorkDays: values.onlyWorkDays,
      remindAsChatMessage: true,
    };

    if (values.repeat !== 'never' && !this.isCreating()) {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.event-editing-subject'),
        text: this.translocoService.translate('alert.event-editing-text'),
        showDenyButton: true,
        denyButtonText: this.translocoService.translate('alert.event-editing-deny-btn'),
        confirmButtonText: this.translocoService.translate('alert.event-editing-confirm-btn'),
        cancelButtonText: this.translocoService.translate('alert.btn-cancel'),
        confirmButtonClass: 'btn-success',
        denyButtonClass: 'btn-solid',
        platform: 'web',
      }).then((result) => {
        if (result === 'isConfirmed') {
          combineLatest([
            this.store.dispatch(
              new CalendarEventCreate({
                ...event,
                start: this.modalData.event.start.toISOString(),
                end: this.modalData.event.end.toISOString(),
                repeat: 'never',
              }),
            ),
            this.store.dispatch(
              new CalendarEventsUpdateSingle({
                ...event,
                calendarEventId: this.modalData.event.id,
                exceptionDays: [this.modalData.event.start.toISOString()],
              }),
            ),
          ]).subscribe({
            next: () => this.close(true),
            error: () => {
              this.toastr.error(
                this.translocoService.translate(
                  'modals.calendar-event.errors.unable-to-save-event',
                ),
              );
            },
          });
        } else {
          firstValueFrom(this.store.dispatch(new CalendarEventsUpdate(event)))
            .then(() => this.close(true))
            .catch((error) => {
              this.toastr.error(
                this.translocoService.translate(
                  'modals.calendar-event.errors.unable-to-save-event',
                ),
              );
            });
        }
      });
    } else if (this.isCreating()) {
      firstValueFrom(this.store.dispatch(new CalendarEventCreate(event)))
        .then(() => this.close(true))
        .catch((error) => {
          this.toastr.error(
            this.translocoService.translate('modals.calendar-event.errors.unable-to-save-event'),
          );
        });
    } else {
      firstValueFrom(this.store.dispatch(new CalendarEventsUpdate(event)))
        .then(() => this.close(true))
        .catch((error) => {
          this.toastr.error(
            this.translocoService.translate('modals.calendar-event.errors.unable-to-save-event'),
          );
        });
    }
  }

  customGuest(item: string): { label: string; value: string } | boolean {
    if (!isEmail(item)) {
      return false;
    }
    return { label: item, value: item };
  }

  subtractOneDay() {
    const from = moment(this.form.controls.from.value).subtract(1, 'days').toDate();
    const to = moment(this.form.controls.to.value).subtract(1, 'days').toDate();
    this.form.patchValue(
      {
        from,
        to,
        fromDate: moment(from).format('YYYY-MM-DD'),
        toDate: moment(to).format('YYYY-MM-DD'),
      },
      {
        emitEvent: false,
        onlySelf: true,
      },
    );
    this.formUpdated.next(this.modalEvent);
  }

  addOneDay() {
    const from = moment(this.form.controls.from.value).add(1, 'days').toDate();
    const to = moment(this.form.controls.to.value).add(1, 'days').toDate();
    this.form.patchValue(
      {
        from,
        to,
        fromDate: moment(from).format('YYYY-MM-DD'),
        toDate: moment(to).format('YYYY-MM-DD'),
      },
      {
        emitEvent: false,
        onlySelf: true,
      },
    );
    this.formUpdated.next(this.modalEvent);
  }

  isExternalMember(memberIdOrEmail: string) {
    return isEmail(memberIdOrEmail);
  }
}
