import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EditorService {
  private editorContentSubject = new BehaviorSubject<string>('');
  private editorTitleSubject = new BehaviorSubject<string>('');

  private isUndo = new BehaviorSubject(false);
  private isRedo = new BehaviorSubject(false);

  public undo$ = new EventEmitter<MouseEvent>();
  public redo$ = new EventEmitter<MouseEvent>();

  public editorContent$ = this.editorContentSubject.asObservable();

  constructor() {}

  set isUndoActive(isActive: boolean) {
    this.isUndo.next(isActive);
  }

  get isUndoActive() {
    return this.isUndo.value;
  }

  set isRedoActive(isActive: boolean) {
    this.isRedo.next(isActive);
  }

  get isRedoActive() {
    return this.isRedo.value;
  }

  setEditorContent(content: string): void {
    this.editorContentSubject.next(content);
  }

  getEditorContent(): string {
    return this.editorContentSubject.value;
  }

  setEditorTitle(content: string): void {
    this.editorTitleSubject.next(content);
  }

  getEditorTitle(): string {
    return this.editorTitleSubject.value;
  }
}
