import { Action, Selector, State } from '@ngxs/store';
import { TenantsSettingsStateModel } from '../models/TenantsSettingsState';
import { Injectable } from '@angular/core';
import { TenantsSettingsService } from '../../../api/services/tenants-settings.service';
import {
  DeleteChatGptApiKey,
  GetTenantsSettings,
  SetChatGptApiKey,
} from '../actions/tenant-settings.action';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@State<TenantsSettingsStateModel>({
  name: 'TenantsSettings',
  defaults: {
    _id: null,
    openAiApiKey: null,
  },
})
@Injectable()
export class TenantsSettingsState {
  @Selector()
  /**
   * get Api key ChatGpt
   * @param  {TenantsSettingsStateModel} state
   */
  static getChatGptApiKey(state: TenantsSettingsStateModel) {
    return state.openAiApiKey;
  }

  constructor(
    private tenantsSettingsService: TenantsSettingsService,
    private toastr: ToastrService,
  ) {}

  @Action(GetTenantsSettings)
  getTenantsSettings({ setState }) {
    return this.tenantsSettingsService
      .tenantsSettingsGet()
      .pipe(tap((res) => res && setState({ ...res })));
  }

  @Action(SetChatGptApiKey)
  setChatGptApiKey({ patchState }, action: SetChatGptApiKey) {
    return this.tenantsSettingsService.tenantsSettingsCreate({ body: action.payload }).pipe(
      tap(
        (res) => {
          patchState({
            _id: res._id,
            openAiApiKey: res.openAiApiKey,
          });
          this.toastr.success(
            'Your API key has been set up and is now ready to use in your tenant.',
            'Integration complete',
          );
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  @Action(DeleteChatGptApiKey)
  removeChatGptApiKey({ patchState }) {
    return this.tenantsSettingsService.tenantsSettingsDelete().pipe(
      tap(
        () => {
          patchState({
            openAiApiKey: null,
          });
          this.toastr.success(
            `API key has been removed from the tenant. You won't be able to access the Chat GPT features at the moment.`,
            'ChatGPT was disconnected',
          );
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }
}
