!function (t, e) {
  if ("object" == typeof exports && "object" == typeof module) module.exports = e();else if ("function" == typeof define && define.amd) define([], e);else {
    var r = e();
    for (var n in r) ("object" == typeof exports ? exports : t)[n] = r[n];
  }
}(window, function () {
  return function (t) {
    var e = {};
    function r(n) {
      if (e[n]) return e[n].exports;
      var o = e[n] = {
        i: n,
        l: !1,
        exports: {}
      };
      return t[n].call(o.exports, o, o.exports, r), o.l = !0, o.exports;
    }
    return r.m = t, r.c = e, r.d = function (t, e, n) {
      r.o(t, e) || Object.defineProperty(t, e, {
        enumerable: !0,
        get: n
      });
    }, r.r = function (t) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(t, "__esModule", {
        value: !0
      });
    }, r.t = function (t, e) {
      if (1 & e && (t = r(t)), 8 & e) return t;
      if (4 & e && "object" == typeof t && t && t.__esModule) return t;
      var n = Object.create(null);
      if (r.r(n), Object.defineProperty(n, "default", {
        enumerable: !0,
        value: t
      }), 2 & e && "string" != typeof t) for (var o in t) r.d(n, o, function (e) {
        return t[e];
      }.bind(null, o));
      return n;
    }, r.n = function (t) {
      var e = t && t.__esModule ? function () {
        return t.default;
      } : function () {
        return t;
      };
      return r.d(e, "a", e), e;
    }, r.o = function (t, e) {
      return Object.prototype.hasOwnProperty.call(t, e);
    }, r.p = "", r(r.s = 15);
  }([function (t, e, r) {
    "use strict";

    function n(t) {
      return (n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
        return typeof t;
      } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
      })(t);
    }
    var o = r(4),
      i = "function" == typeof Symbol && "symbol" === n(Symbol("foo")),
      s = Object.prototype.toString,
      a = Array.prototype.concat,
      u = Object.defineProperty,
      p = u && function () {
        var t = {};
        try {
          for (var e in u(t, "x", {
            enumerable: !1,
            value: t
          }), t) return !1;
          return t.x === t;
        } catch (t) {
          return !1;
        }
      }(),
      c = function (t, e, r, n) {
        var o;
        (!(e in t) || "function" == typeof (o = n) && "[object Function]" === s.call(o) && n()) && (p ? u(t, e, {
          configurable: !0,
          enumerable: !1,
          value: r,
          writable: !0
        }) : t[e] = r);
      },
      l = function (t, e) {
        var r = arguments.length > 2 ? arguments[2] : {},
          n = o(e);
        i && (n = a.call(n, Object.getOwnPropertySymbols(e)));
        for (var s = 0; s < n.length; s += 1) c(t, n[s], e[n[s]], r[n[s]]);
      };
    l.supportsDescriptors = !!p, t.exports = l;
  }, function (t, e, r) {
    "use strict";

    var n = r(20);
    t.exports = Function.prototype.bind || n;
  }, function (t, e, r) {
    function n(t) {
      return (n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
        return typeof t;
      } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
      })(t);
    }
    var o = r(16),
      i = r(3),
      s = r(12),
      a = r(30),
      u = String.fromCharCode(0),
      p = function (t) {
        Array.isArray(t) ? this.ops = t : null != t && Array.isArray(t.ops) ? this.ops = t.ops : this.ops = [];
      };
    p.prototype.insert = function (t, e) {
      var r = {};
      return 0 === t.length ? this : (r.insert = t, null != e && "object" === n(e) && Object.keys(e).length > 0 && (r.attributes = e), this.push(r));
    }, p.prototype.delete = function (t) {
      return t <= 0 ? this : this.push({
        delete: t
      });
    }, p.prototype.retain = function (t, e) {
      if (t <= 0) return this;
      var r = {
        retain: t
      };
      return null != e && "object" === n(e) && Object.keys(e).length > 0 && (r.attributes = e), this.push(r);
    }, p.prototype.push = function (t) {
      var e = this.ops.length,
        r = this.ops[e - 1];
      if (t = s(!0, {}, t), "object" === n(r)) {
        if ("number" == typeof t.delete && "number" == typeof r.delete) return this.ops[e - 1] = {
          delete: r.delete + t.delete
        }, this;
        if ("number" == typeof r.delete && null != t.insert && (e -= 1, "object" !== n(r = this.ops[e - 1]))) return this.ops.unshift(t), this;
        if (i(t.attributes, r.attributes)) {
          if ("string" == typeof t.insert && "string" == typeof r.insert) return this.ops[e - 1] = {
            insert: r.insert + t.insert
          }, "object" === n(t.attributes) && (this.ops[e - 1].attributes = t.attributes), this;
          if ("number" == typeof t.retain && "number" == typeof r.retain) return this.ops[e - 1] = {
            retain: r.retain + t.retain
          }, "object" === n(t.attributes) && (this.ops[e - 1].attributes = t.attributes), this;
        }
      }
      return e === this.ops.length ? this.ops.push(t) : this.ops.splice(e, 0, t), this;
    }, p.prototype.chop = function () {
      var t = this.ops[this.ops.length - 1];
      return t && t.retain && !t.attributes && this.ops.pop(), this;
    }, p.prototype.filter = function (t) {
      return this.ops.filter(t);
    }, p.prototype.forEach = function (t) {
      this.ops.forEach(t);
    }, p.prototype.map = function (t) {
      return this.ops.map(t);
    }, p.prototype.partition = function (t) {
      var e = [],
        r = [];
      return this.forEach(function (n) {
        (t(n) ? e : r).push(n);
      }), [e, r];
    }, p.prototype.reduce = function (t, e) {
      return this.ops.reduce(t, e);
    }, p.prototype.changeLength = function () {
      return this.reduce(function (t, e) {
        return e.insert ? t + a.length(e) : e.delete ? t - e.delete : t;
      }, 0);
    }, p.prototype.length = function () {
      return this.reduce(function (t, e) {
        return t + a.length(e);
      }, 0);
    }, p.prototype.slice = function (t, e) {
      t = t || 0, "number" != typeof e && (e = 1 / 0);
      for (var r = [], n = a.iterator(this.ops), o = 0; o < e && n.hasNext();) {
        var i;
        o < t ? i = n.next(t - o) : (i = n.next(e - o), r.push(i)), o += a.length(i);
      }
      return new p(r);
    }, p.prototype.compose = function (t) {
      var e = a.iterator(this.ops),
        r = a.iterator(t.ops),
        n = [],
        o = r.peek();
      if (null != o && "number" == typeof o.retain && null == o.attributes) {
        for (var s = o.retain; "insert" === e.peekType() && e.peekLength() <= s;) s -= e.peekLength(), n.push(e.next());
        o.retain - s > 0 && r.next(o.retain - s);
      }
      for (var u = new p(n); e.hasNext() || r.hasNext();) if ("insert" === r.peekType()) u.push(r.next());else if ("delete" === e.peekType()) u.push(e.next());else {
        var c = Math.min(e.peekLength(), r.peekLength()),
          l = e.next(c),
          f = r.next(c);
        if ("number" == typeof f.retain) {
          var y = {};
          "number" == typeof l.retain ? y.retain = c : y.insert = l.insert;
          var h = a.attributes.compose(l.attributes, f.attributes, "number" == typeof l.retain);
          if (h && (y.attributes = h), u.push(y), !r.hasNext() && i(u.ops[u.ops.length - 1], y)) {
            var b = new p(e.rest());
            return u.concat(b).chop();
          }
        } else "number" == typeof f.delete && "number" == typeof l.retain && u.push(f);
      }
      return u.chop();
    }, p.prototype.concat = function (t) {
      var e = new p(this.ops.slice());
      return t.ops.length > 0 && (e.push(t.ops[0]), e.ops = e.ops.concat(t.ops.slice(1))), e;
    }, p.prototype.diff = function (t, e) {
      if (this.ops === t.ops) return new p();
      var r = [this, t].map(function (e) {
          return e.map(function (r) {
            if (null != r.insert) return "string" == typeof r.insert ? r.insert : u;
            throw new Error("diff() called " + (e === t ? "on" : "with") + " non-document");
          }).join("");
        }),
        n = new p(),
        s = o(r[0], r[1], e),
        c = a.iterator(this.ops),
        l = a.iterator(t.ops);
      return s.forEach(function (t) {
        for (var e = t[1].length; e > 0;) {
          var r = 0;
          switch (t[0]) {
            case o.INSERT:
              r = Math.min(l.peekLength(), e), n.push(l.next(r));
              break;
            case o.DELETE:
              r = Math.min(e, c.peekLength()), c.next(r), n.delete(r);
              break;
            case o.EQUAL:
              r = Math.min(c.peekLength(), l.peekLength(), e);
              var s = c.next(r),
                u = l.next(r);
              i(s.insert, u.insert) ? n.retain(r, a.attributes.diff(s.attributes, u.attributes)) : n.push(u).delete(r);
          }
          e -= r;
        }
      }), n.chop();
    }, p.prototype.eachLine = function (t, e) {
      e = e || "\n";
      for (var r = a.iterator(this.ops), n = new p(), o = 0; r.hasNext();) {
        if ("insert" !== r.peekType()) return;
        var i = r.peek(),
          s = a.length(i) - r.peekLength(),
          u = "string" == typeof i.insert ? i.insert.indexOf(e, s) - s : -1;
        if (u < 0) n.push(r.next());else if (u > 0) n.push(r.next(u));else {
          if (!1 === t(n, r.next(1).attributes || {}, o)) return;
          o += 1, n = new p();
        }
      }
      n.length() > 0 && t(n, {}, o);
    }, p.prototype.transform = function (t, e) {
      if (e = !!e, "number" == typeof t) return this.transformPosition(t, e);
      for (var r = a.iterator(this.ops), n = a.iterator(t.ops), o = new p(); r.hasNext() || n.hasNext();) if ("insert" !== r.peekType() || !e && "insert" === n.peekType()) {
        if ("insert" === n.peekType()) o.push(n.next());else {
          var i = Math.min(r.peekLength(), n.peekLength()),
            s = r.next(i),
            u = n.next(i);
          if (s.delete) continue;
          u.delete ? o.push(u) : o.retain(i, a.attributes.transform(s.attributes, u.attributes, e));
        }
      } else o.retain(a.length(r.next()));
      return o.chop();
    }, p.prototype.transformPosition = function (t, e) {
      e = !!e;
      for (var r = a.iterator(this.ops), n = 0; r.hasNext() && n <= t;) {
        var o = r.peekLength(),
          i = r.peekType();
        r.next(), "delete" !== i ? ("insert" === i && (n < t || !e) && (t += o), n += o) : t -= Math.min(o, t - n);
      }
      return t;
    }, t.exports = p;
  }, function (t, e, r) {
    function n(t) {
      return (n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
        return typeof t;
      } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
      })(t);
    }
    var o = r(4),
      i = r(18),
      s = r(19),
      a = r(25),
      u = r(27),
      p = r(29),
      c = Date.prototype.getTime;
    function l(t, e, r) {
      var h = r || {};
      return !!(h.strict ? s(t, e) : t === e) || (!t || !e || "object" !== n(t) && "object" !== n(e) ? h.strict ? s(t, e) : t == e : function (t, e, r) {
        var s, h;
        if (n(t) !== n(e)) return !1;
        if (f(t) || f(e)) return !1;
        if (t.prototype !== e.prototype) return !1;
        if (i(t) !== i(e)) return !1;
        var b = a(t),
          m = a(e);
        if (b !== m) return !1;
        if (b || m) return t.source === e.source && u(t) === u(e);
        if (p(t) && p(e)) return c.call(t) === c.call(e);
        var d = y(t),
          g = y(e);
        if (d !== g) return !1;
        if (d || g) {
          if (t.length !== e.length) return !1;
          for (s = 0; s < t.length; s++) if (t[s] !== e[s]) return !1;
          return !0;
        }
        if (n(t) !== n(e)) return !1;
        try {
          var v = o(t),
            S = o(e);
        } catch (t) {
          return !1;
        }
        if (v.length !== S.length) return !1;
        for (v.sort(), S.sort(), s = v.length - 1; s >= 0; s--) if (v[s] != S[s]) return !1;
        for (s = v.length - 1; s >= 0; s--) if (h = v[s], !l(t[h], e[h], r)) return !1;
        return !0;
      }(t, e, h));
    }
    function f(t) {
      return null == t;
    }
    function y(t) {
      return !(!t || "object" !== n(t) || "number" != typeof t.length) && "function" == typeof t.copy && "function" == typeof t.slice && !(t.length > 0 && "number" != typeof t[0]);
    }
    t.exports = l;
  }, function (t, e, r) {
    "use strict";

    var n = Array.prototype.slice,
      o = r(5),
      i = Object.keys,
      s = i ? function (t) {
        return i(t);
      } : r(17),
      a = Object.keys;
    s.shim = function () {
      Object.keys ? function () {
        var t = Object.keys(arguments);
        return t && t.length === arguments.length;
      }(1, 2) || (Object.keys = function (t) {
        return o(t) ? a(n.call(t)) : a(t);
      }) : Object.keys = s;
      return Object.keys || s;
    }, t.exports = s;
  }, function (t, e, r) {
    "use strict";

    function n(t) {
      return (n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
        return typeof t;
      } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
      })(t);
    }
    var o = Object.prototype.toString;
    t.exports = function (t) {
      var e = o.call(t),
        r = "[object Arguments]" === e;
      return r || (r = "[object Array]" !== e && null !== t && "object" === n(t) && "number" == typeof t.length && t.length >= 0 && "[object Function]" === o.call(t.callee)), r;
    };
  }, function (t, e, r) {
    "use strict";

    var n = r(1),
      o = r(21)("%Function%"),
      i = o.apply,
      s = o.call;
    t.exports = function () {
      return n.apply(s, arguments);
    }, t.exports.apply = function () {
      return n.apply(i, arguments);
    };
  }, function (t, e) {
    function r(t) {
      return (r = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
        return typeof t;
      } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
      })(t);
    }
    var n;
    n = function () {
      return this;
    }();
    try {
      n = n || new Function("return this")();
    } catch (t) {
      "object" === ("undefined" == typeof window ? "undefined" : r(window)) && (n = window);
    }
    t.exports = n;
  }, function (t, e, r) {
    "use strict";

    var n = function (t) {
      return t != t;
    };
    t.exports = function (t, e) {
      return 0 === t && 0 === e ? 1 / t == 1 / e : t === e || !(!n(t) || !n(e));
    };
  }, function (t, e, r) {
    "use strict";

    var n = r(8);
    t.exports = function () {
      return "function" == typeof Object.is ? Object.is : n;
    };
  }, function (t, e, r) {
    "use strict";

    var n = Object,
      o = TypeError;
    t.exports = function () {
      if (null != this && this !== n(this)) throw new o("RegExp.prototype.flags getter called on non-object");
      var t = "";
      return this.global && (t += "g"), this.ignoreCase && (t += "i"), this.multiline && (t += "m"), this.dotAll && (t += "s"), this.unicode && (t += "u"), this.sticky && (t += "y"), t;
    };
  }, function (t, e, r) {
    "use strict";

    var n = r(10),
      o = r(0).supportsDescriptors,
      i = Object.getOwnPropertyDescriptor,
      s = TypeError;
    t.exports = function () {
      if (!o) throw new s("RegExp.prototype.flags requires a true ES5 environment that supports property descriptors");
      if ("gim" === /a/gim.flags) {
        var t = i(RegExp.prototype, "flags");
        if (t && "function" == typeof t.get && "boolean" == typeof /a/.dotAll) return t.get;
      }
      return n;
    };
  }, function (t, e, r) {
    "use strict";

    function n(t) {
      return (n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
        return typeof t;
      } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
      })(t);
    }
    var o = Object.prototype.hasOwnProperty,
      i = Object.prototype.toString,
      s = Object.defineProperty,
      a = Object.getOwnPropertyDescriptor,
      u = function (t) {
        return "function" == typeof Array.isArray ? Array.isArray(t) : "[object Array]" === i.call(t);
      },
      p = function (t) {
        if (!t || "[object Object]" !== i.call(t)) return !1;
        var e,
          r = o.call(t, "constructor"),
          n = t.constructor && t.constructor.prototype && o.call(t.constructor.prototype, "isPrototypeOf");
        if (t.constructor && !r && !n) return !1;
        for (e in t);
        return void 0 === e || o.call(t, e);
      },
      c = function (t, e) {
        s && "__proto__" === e.name ? s(t, e.name, {
          enumerable: !0,
          configurable: !0,
          value: e.newValue,
          writable: !0
        }) : t[e.name] = e.newValue;
      },
      l = function (t, e) {
        if ("__proto__" === e) {
          if (!o.call(t, e)) return;
          if (a) return a(t, e).value;
        }
        return t[e];
      };
    t.exports = function t() {
      var e,
        r,
        o,
        i,
        s,
        a,
        f = arguments[0],
        y = 1,
        h = arguments.length,
        b = !1;
      for ("boolean" == typeof f && (b = f, f = arguments[1] || {}, y = 2), (null == f || "object" !== n(f) && "function" != typeof f) && (f = {}); y < h; ++y) if (null != (e = arguments[y])) for (r in e) o = l(f, r), f !== (i = l(e, r)) && (b && i && (p(i) || (s = u(i))) ? (s ? (s = !1, a = o && u(o) ? o : []) : a = o && p(o) ? o : {}, c(f, {
        name: r,
        newValue: t(b, a, i)
      })) : void 0 !== i && c(f, {
        name: r,
        newValue: i
      }));
      return f;
    };
  }, function (t, e) {
    (function (e) {
      t.exports = e;
    }).call(this, {});
  }, function (t, e, r) {
    "use strict";

    function n(t, e) {
      var r = Object.keys(t);
      if (Object.getOwnPropertySymbols) {
        var n = Object.getOwnPropertySymbols(t);
        e && (n = n.filter(function (e) {
          return Object.getOwnPropertyDescriptor(t, e).enumerable;
        })), r.push.apply(r, n);
      }
      return r;
    }
    function o(t, e, r) {
      return e in t ? Object.defineProperty(t, e, {
        value: r,
        enumerable: !0,
        configurable: !0,
        writable: !0
      }) : t[e] = r, t;
    }
    function i(t) {
      return function (t) {
        if (Array.isArray(t)) return u(t);
      }(t) || function (t) {
        if ("undefined" != typeof Symbol && Symbol.iterator in Object(t)) return Array.from(t);
      }(t) || a(t) || function () {
        throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
      }();
    }
    function s(t, e) {
      return function (t) {
        if (Array.isArray(t)) return t;
      }(t) || function (t, e) {
        if ("undefined" == typeof Symbol || !(Symbol.iterator in Object(t))) return;
        var r = [],
          n = !0,
          o = !1,
          i = void 0;
        try {
          for (var s, a = t[Symbol.iterator](); !(n = (s = a.next()).done) && (r.push(s.value), !e || r.length !== e); n = !0);
        } catch (t) {
          o = !0, i = t;
        } finally {
          try {
            n || null == a.return || a.return();
          } finally {
            if (o) throw i;
          }
        }
        return r;
      }(t, e) || a(t, e) || function () {
        throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
      }();
    }
    function a(t, e) {
      if (t) {
        if ("string" == typeof t) return u(t, e);
        var r = Object.prototype.toString.call(t).slice(8, -1);
        return "Object" === r && t.constructor && (r = t.constructor.name), "Map" === r || "Set" === r ? Array.from(t) : "Arguments" === r || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(r) ? u(t, e) : void 0;
      }
    }
    function u(t, e) {
      (null == e || e > t.length) && (e = t.length);
      for (var r = 0, n = new Array(e); r < e; r++) n[r] = t[r];
      return n;
    }
    var p = "undefined" == typeof URL ? r(31).URL : URL,
      c = function (t, e) {
        return e.some(function (e) {
          return e instanceof RegExp ? e.test(t) : e === t;
        });
      },
      l = function (t, e) {
        if (e = function (t) {
          for (var e = 1; e < arguments.length; e++) {
            var r = null != arguments[e] ? arguments[e] : {};
            e % 2 ? n(Object(r), !0).forEach(function (e) {
              o(t, e, r[e]);
            }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(t, Object.getOwnPropertyDescriptors(r)) : n(Object(r)).forEach(function (e) {
              Object.defineProperty(t, e, Object.getOwnPropertyDescriptor(r, e));
            });
          }
          return t;
        }({
          defaultProtocol: "http:",
          normalizeProtocol: !0,
          forceHttp: !1,
          forceHttps: !1,
          stripAuthentication: !0,
          stripHash: !1,
          stripWWW: !0,
          removeQueryParameters: [/^utm_\w+/i],
          removeTrailingSlash: !0,
          removeDirectoryIndex: !1,
          sortQueryParameters: !0
        }, e), Reflect.has(e, "normalizeHttps")) throw new Error("options.normalizeHttps is renamed to options.forceHttp");
        if (Reflect.has(e, "normalizeHttp")) throw new Error("options.normalizeHttp is renamed to options.forceHttps");
        if (Reflect.has(e, "stripFragment")) throw new Error("options.stripFragment is renamed to options.stripHash");
        if (t = t.trim(), /^data:/i.test(t)) return function (t, e) {
          var r = e.stripHash,
            n = t.match(/^data:([^,]*?),([^#]*?)(?:#(.*))?$/);
          if (!n) throw new Error("Invalid URL: ".concat(t));
          var o = n[1].split(";"),
            a = n[2],
            u = r ? "" : n[3],
            p = !1;
          "base64" === o[o.length - 1] && (o.pop(), p = !0);
          var c = (o.shift() || "").toLowerCase(),
            l = i(o.map(function (t) {
              var e = s(t.split("=").map(function (t) {
                  return t.trim();
                }), 2),
                r = e[0],
                n = e[1],
                o = void 0 === n ? "" : n;
              return "charset" === r && "us-ascii" === (o = o.toLowerCase()) ? "" : "".concat(r).concat(o ? "=".concat(o) : "");
            }).filter(Boolean));
          return p && l.push("base64"), (0 !== l.length || c && "text/plain" !== c) && l.unshift(c), "data:".concat(l.join(";"), ",").concat(p ? a.trim() : a).concat(u ? "#".concat(u) : "");
        }(t, e);
        var r = t.startsWith("//");
        !r && /^\.*\//.test(t) || (t = t.replace(/^(?!(?:\w+:)?\/\/)|^\/\//, e.defaultProtocol));
        var a = new p(t);
        if (e.forceHttp && e.forceHttps) throw new Error("The `forceHttp` and `forceHttps` options cannot be used together");
        if (e.forceHttp && "https:" === a.protocol && (a.protocol = "http:"), e.forceHttps && "http:" === a.protocol && (a.protocol = "https:"), e.stripAuthentication && (a.username = "", a.password = ""), e.stripHash && (a.hash = ""), a.pathname && (a.pathname = a.pathname.replace(/((?!:).|^)\/{2,}/g, function (t, e) {
          return /^(?!\/)/g.test(e) ? "".concat(e, "/") : "/";
        })), a.pathname && (a.pathname = decodeURI(a.pathname)), !0 === e.removeDirectoryIndex && (e.removeDirectoryIndex = [/^index\.[a-z]+$/]), Array.isArray(e.removeDirectoryIndex) && e.removeDirectoryIndex.length > 0) {
          var u = a.pathname.split("/"),
            l = u[u.length - 1];
          c(l, e.removeDirectoryIndex) && (u = u.slice(0, u.length - 1), a.pathname = u.slice(1).join("/") + "/");
        }
        if (a.hostname && (a.hostname = a.hostname.replace(/\.$/, ""), e.stripWWW && /^www\.([a-z\-\d]{2,63})\.([a-z.]{2,5})$/.test(a.hostname) && (a.hostname = a.hostname.replace(/^www\./, ""))), Array.isArray(e.removeQueryParameters)) for (var f = 0, y = i(a.searchParams.keys()); f < y.length; f++) {
          var h = y[f];
          c(h, e.removeQueryParameters) && a.searchParams.delete(h);
        }
        return e.sortQueryParameters && a.searchParams.sort(), e.removeTrailingSlash && (a.pathname = a.pathname.replace(/\/$/, "")), t = a.toString(), !e.removeTrailingSlash && "/" !== a.pathname || "" !== a.hash || (t = t.replace(/\/$/, "")), r && !e.normalizeProtocol && (t = t.replace(/^http:\/\//, "//")), e.stripProtocol && (t = t.replace(/^(?:https?:)?\/\//, "")), t;
      };
    t.exports = l, t.exports.default = l;
  }, function (t, e, r) {
    "use strict";

    r.r(e), r.d(e, "default", function () {
      return h;
    });
    var n = r(2),
      o = r.n(n),
      i = r(14),
      s = r.n(i);
    function a(t, e) {
      return function (t) {
        if (Array.isArray(t)) return t;
      }(t) || function (t, e) {
        if ("undefined" == typeof Symbol || !(Symbol.iterator in Object(t))) return;
        var r = [],
          n = !0,
          o = !1,
          i = void 0;
        try {
          for (var s, a = t[Symbol.iterator](); !(n = (s = a.next()).done) && (r.push(s.value), !e || r.length !== e); n = !0);
        } catch (t) {
          o = !0, i = t;
        } finally {
          try {
            n || null == a.return || a.return();
          } finally {
            if (o) throw i;
          }
        }
        return r;
      }(t, e) || function (t, e) {
        if (!t) return;
        if ("string" == typeof t) return u(t, e);
        var r = Object.prototype.toString.call(t).slice(8, -1);
        "Object" === r && t.constructor && (r = t.constructor.name);
        if ("Map" === r || "Set" === r) return Array.from(t);
        if ("Arguments" === r || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(r)) return u(t, e);
      }(t, e) || function () {
        throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
      }();
    }
    function u(t, e) {
      (null == e || e > t.length) && (e = t.length);
      for (var r = 0, n = new Array(e); r < e; r++) n[r] = t[r];
      return n;
    }
    function p(t, e) {
      var r = Object.keys(t);
      if (Object.getOwnPropertySymbols) {
        var n = Object.getOwnPropertySymbols(t);
        e && (n = n.filter(function (e) {
          return Object.getOwnPropertyDescriptor(t, e).enumerable;
        })), r.push.apply(r, n);
      }
      return r;
    }
    function c(t) {
      for (var e = 1; e < arguments.length; e++) {
        var r = null != arguments[e] ? arguments[e] : {};
        e % 2 ? p(Object(r), !0).forEach(function (e) {
          l(t, e, r[e]);
        }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(t, Object.getOwnPropertyDescriptors(r)) : p(Object(r)).forEach(function (e) {
          Object.defineProperty(t, e, Object.getOwnPropertyDescriptor(r, e));
        });
      }
      return t;
    }
    function l(t, e, r) {
      return e in t ? Object.defineProperty(t, e, {
        value: r,
        enumerable: !0,
        configurable: !0,
        writable: !0
      }) : t[e] = r, t;
    }
    function f(t, e) {
      for (var r = 0; r < e.length; r++) {
        var n = e[r];
        n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(t, n.key, n);
      }
    }
    var y = {
        globalRegularExpression: /(https?:\/\/|www\.)[\w-\.]+\.[\w-\.]+(\/([\S]+)?)?/gi,
        urlRegularExpression: /(https?:\/\/|www\.)[\w-\.]+\.[\w-\.]+(\/([\S]+)?)?/gi,
        globalMailRegularExpression: /([\w-\.]+@[\w-\.]+\.[\w-\.]+)/gi,
        mailRegularExpression: /([\w-\.]+@[\w-\.]+\.[\w-\.]+)/gi,
        normalizeRegularExpression: /(https?:\/\/|www\.)[\S]+/i,
        normalizeUrlOptions: {
          stripWWW: !1
        }
      },
      h = function () {
        function t(e, r) {
          var n = this;
          !function (t, e) {
            if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
          }(this, t), this.quill = e, r = r || {}, this.options = c(c({}, y), r), this.urlNormalizer = function (t) {
            return n.normalize(t);
          }, this.mailNormalizer = function (t) {
            return "mailto:".concat(t);
          }, this.registerTypeListener(), this.registerPasteListener(), this.registerBlurListener();
        }
        var e, r, n;
        return e = t, (r = [{
          key: "registerPasteListener",
          value: function () {
            var t = this;
            this.quill.clipboard.addMatcher("A", function (t, e) {
              var r = t.getAttribute("href"),
                n = e.ops[0].attributes;
              return null != n && null != n.link && (n.link = r), e;
            }), this.quill.clipboard.addMatcher(Node.TEXT_NODE, function (e, r) {
              if ("string" == typeof e.data) {
                var n = t.options.globalRegularExpression,
                  i = t.options.globalMailRegularExpression;
                n.lastIndex = 0, i.lastIndex = 0;
                for (var s = new o.a(), a = 0, u = n.exec(e.data), p = i.exec(e.data), c = function (t, r, n) {
                    var o = e.data.substring(a, t.index);
                    s.insert(o);
                    var i = t[0];
                    return s.insert(i, {
                      link: n(i)
                    }), a = r.lastIndex, r.exec(e.data);
                  }; null !== u || null !== p;) if (null === u) p = c(p, i, t.mailNormalizer);else if (null === p) u = c(u, n, t.urlNormalizer);else if (p.index <= u.index) {
                  for (; null !== u && u.index < i.lastIndex;) u = n.exec(e.data);
                  p = c(p, i, t.mailNormalizer);
                } else {
                  for (; null !== p && p.index < n.lastIndex;) p = i.exec(e.data);
                  u = c(u, n, t.urlNormalizer);
                }
                if (a > 0) {
                  var l = e.data.substring(a);
                  s.insert(l), r.ops = s.ops;
                }
                return r;
              }
            });
          }
        }, {
          key: "registerTypeListener",
          value: function () {
            var t = this;
            this.quill.on("text-change", function (e) {
              var r = e.ops;
              if (!(!r || r.length < 1 || r.length > 2)) {
                var n = r[r.length - 1];
                n.insert && "string" == typeof n.insert && n.insert.match(/\s/) && t.checkTextForUrl(n.insert.match(/ |\t/));
              }
            });
          }
        }, {
          key: "registerBlurListener",
          value: function () {
            var t = this;
            this.quill.root.addEventListener("blur", function () {
              t.checkTextForUrl();
            });
          }
        }, {
          key: "checkTextForUrl",
          value: function () {
            var t = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
              e = this.quill.getSelection();
            if (e) {
              var r = this.quill.getLeaf(e.index),
                n = a(r, 1),
                o = n[0],
                i = this.quill.getIndex(o);
              if (o.text) {
                var s = e.index - i,
                  u = o.text.slice(0, s);
                if (u && "a" !== o.parent.domNode.localName) {
                  var p = o.text[s];
                  if (null == p || !p.match(/\S/)) {
                    var c = t ? /\s\s$/ : /\s$/;
                    if (!u.match(c)) {
                      var l = u.match(this.options.urlRegularExpression),
                        f = u.match(this.options.mailRegularExpression);
                      l ? this.handleMatches(i, u, l, this.urlNormalizer) : f && this.handleMatches(i, u, f, this.mailNormalizer);
                    }
                  }
                }
              }
            }
          }
        }, {
          key: "handleMatches",
          value: function (t, e, r, n) {
            var o = r.pop(),
              i = e.lastIndexOf(o);
            e.split(o).pop().match(/\S/) || this.updateText(t + i, o.trim(), n);
          }
        }, {
          key: "updateText",
          value: function (t, e, r) {
            var n = new o.a().retain(t).retain(e.length, {
              link: r(e)
            });
            this.quill.updateContents(n);
          }
        }, {
          key: "normalize",
          value: function (t) {
            if (this.options.normalizeRegularExpression.test(t)) try {
              return s()(t, this.options.normalizeUrlOptions);
            } catch (t) {
              console.error(t);
            }
            return t;
          }
        }]) && f(e.prototype, r), n && f(e, n), t;
      }();
    null != window && window.Quill && window.Quill.register("modules/magicUrl", h);
  }, function (t, e) {
    function r(t, e, s) {
      if (t == e) return t ? [[0, t]] : [];
      (s < 0 || t.length < s) && (s = null);
      var u = o(t, e),
        p = t.substring(0, u);
      u = i(t = t.substring(u), e = e.substring(u));
      var c = t.substring(t.length - u),
        l = function (t, e) {
          var s;
          if (!t) return [[1, e]];
          if (!e) return [[-1, t]];
          var a = t.length > e.length ? t : e,
            u = t.length > e.length ? e : t,
            p = a.indexOf(u);
          if (-1 != p) return s = [[1, a.substring(0, p)], [0, u], [1, a.substring(p + u.length)]], t.length > e.length && (s[0][0] = s[2][0] = -1), s;
          if (1 == u.length) return [[-1, t], [1, e]];
          var c = function (t, e) {
            var r = t.length > e.length ? t : e,
              n = t.length > e.length ? e : t;
            if (r.length < 4 || 2 * n.length < r.length) return null;
            function s(t, e, r) {
              for (var n, s, a, u, p = t.substring(r, r + Math.floor(t.length / 4)), c = -1, l = ""; -1 != (c = e.indexOf(p, c + 1));) {
                var f = o(t.substring(r), e.substring(c)),
                  y = i(t.substring(0, r), e.substring(0, c));
                l.length < y + f && (l = e.substring(c - y, c) + e.substring(c, c + f), n = t.substring(0, r - y), s = t.substring(r + f), a = e.substring(0, c - y), u = e.substring(c + f));
              }
              return 2 * l.length >= t.length ? [n, s, a, u, l] : null;
            }
            var a,
              u,
              p,
              c,
              l,
              f = s(r, n, Math.ceil(r.length / 4)),
              y = s(r, n, Math.ceil(r.length / 2));
            if (!f && !y) return null;
            a = y ? f && f[4].length > y[4].length ? f : y : f;
            t.length > e.length ? (u = a[0], p = a[1], c = a[2], l = a[3]) : (c = a[0], l = a[1], u = a[2], p = a[3]);
            var h = a[4];
            return [u, p, c, l, h];
          }(t, e);
          if (c) {
            var l = c[0],
              f = c[1],
              y = c[2],
              h = c[3],
              b = c[4],
              m = r(l, y),
              d = r(f, h);
            return m.concat([[0, b]], d);
          }
          return function (t, e) {
            for (var r = t.length, o = e.length, i = Math.ceil((r + o) / 2), s = i, a = 2 * i, u = new Array(a), p = new Array(a), c = 0; c < a; c++) u[c] = -1, p[c] = -1;
            u[s + 1] = 0, p[s + 1] = 0;
            for (var l = r - o, f = l % 2 != 0, y = 0, h = 0, b = 0, m = 0, d = 0; d < i; d++) {
              for (var g = -d + y; g <= d - h; g += 2) {
                for (var v = s + g, S = (A = g == -d || g != d && u[v - 1] < u[v + 1] ? u[v + 1] : u[v - 1] + 1) - g; A < r && S < o && t.charAt(A) == e.charAt(S);) A++, S++;
                if (u[v] = A, A > r) h += 2;else if (S > o) y += 2;else if (f) {
                  if ((x = s + l - g) >= 0 && x < a && -1 != p[x]) {
                    var j = r - p[x];
                    if (A >= j) return n(t, e, A, S);
                  }
                }
              }
              for (var w = -d + b; w <= d - m; w += 2) {
                for (var x = s + w, O = (j = w == -d || w != d && p[x - 1] < p[x + 1] ? p[x + 1] : p[x - 1] + 1) - w; j < r && O < o && t.charAt(r - j - 1) == e.charAt(o - O - 1);) j++, O++;
                if (p[x] = j, j > r) m += 2;else if (O > o) b += 2;else if (!f) {
                  if ((v = s + l - w) >= 0 && v < a && -1 != u[v]) {
                    var A = u[v];
                    S = s + A - v;
                    if (A >= (j = r - j)) return n(t, e, A, S);
                  }
                }
              }
            }
            return [[-1, t], [1, e]];
          }(t, e);
        }(t = t.substring(0, t.length - u), e = e.substring(0, e.length - u));
      return p && l.unshift([0, p]), c && l.push([0, c]), function t(e) {
        e.push([0, ""]);
        var r,
          n = 0,
          s = 0,
          a = 0,
          u = "",
          p = "";
        for (; n < e.length;) switch (e[n][0]) {
          case 1:
            a++, p += e[n][1], n++;
            break;
          case -1:
            s++, u += e[n][1], n++;
            break;
          case 0:
            s + a > 1 ? (0 !== s && 0 !== a && (0 !== (r = o(p, u)) && (n - s - a > 0 && 0 == e[n - s - a - 1][0] ? e[n - s - a - 1][1] += p.substring(0, r) : (e.splice(0, 0, [0, p.substring(0, r)]), n++), p = p.substring(r), u = u.substring(r)), 0 !== (r = i(p, u)) && (e[n][1] = p.substring(p.length - r) + e[n][1], p = p.substring(0, p.length - r), u = u.substring(0, u.length - r))), 0 === s ? e.splice(n - a, s + a, [1, p]) : 0 === a ? e.splice(n - s, s + a, [-1, u]) : e.splice(n - s - a, s + a, [-1, u], [1, p]), n = n - s - a + (s ? 1 : 0) + (a ? 1 : 0) + 1) : 0 !== n && 0 == e[n - 1][0] ? (e[n - 1][1] += e[n][1], e.splice(n, 1)) : n++, a = 0, s = 0, u = "", p = "";
        }
        "" === e[e.length - 1][1] && e.pop();
        var c = !1;
        n = 1;
        for (; n < e.length - 1;) 0 == e[n - 1][0] && 0 == e[n + 1][0] && (e[n][1].substring(e[n][1].length - e[n - 1][1].length) == e[n - 1][1] ? (e[n][1] = e[n - 1][1] + e[n][1].substring(0, e[n][1].length - e[n - 1][1].length), e[n + 1][1] = e[n - 1][1] + e[n + 1][1], e.splice(n - 1, 1), c = !0) : e[n][1].substring(0, e[n + 1][1].length) == e[n + 1][1] && (e[n - 1][1] += e[n + 1][1], e[n][1] = e[n][1].substring(e[n + 1][1].length) + e[n + 1][1], e.splice(n + 1, 1), c = !0)), n++;
        c && t(e);
      }(l), null != s && (l = function (t, e) {
        var r = function (t, e) {
            if (0 === e) return [0, t];
            for (var r = 0, n = 0; n < t.length; n++) {
              var o = t[n];
              if (-1 === o[0] || 0 === o[0]) {
                var i = r + o[1].length;
                if (e === i) return [n + 1, t];
                if (e < i) {
                  t = t.slice();
                  var s = e - r,
                    a = [o[0], o[1].slice(0, s)],
                    u = [o[0], o[1].slice(s)];
                  return t.splice(n, 1, a, u), [n + 1, t];
                }
                r = i;
              }
            }
            throw new Error("cursor_pos is out of bounds!");
          }(t, e),
          n = r[1],
          o = r[0],
          i = n[o],
          s = n[o + 1];
        if (null == i) return t;
        if (0 !== i[0]) return t;
        if (null != s && i[1] + s[1] === s[1] + i[1]) return n.splice(o, 2, s, i), a(n, o, 2);
        if (null != s && 0 === s[1].indexOf(i[1])) {
          n.splice(o, 2, [s[0], i[1]], [0, i[1]]);
          var u = s[1].slice(i[1].length);
          return u.length > 0 && n.splice(o + 2, 0, [s[0], u]), a(n, o, 3);
        }
        return t;
      }(l, s)), l = function (t) {
        for (var e = !1, r = function (t) {
            return t.charCodeAt(0) >= 56320 && t.charCodeAt(0) <= 57343;
          }, n = 2; n < t.length; n += 1) 0 === t[n - 2][0] && (o = t[n - 2][1]).charCodeAt(o.length - 1) >= 55296 && o.charCodeAt(o.length - 1) <= 56319 && -1 === t[n - 1][0] && r(t[n - 1][1]) && 1 === t[n][0] && r(t[n][1]) && (e = !0, t[n - 1][1] = t[n - 2][1].slice(-1) + t[n - 1][1], t[n][1] = t[n - 2][1].slice(-1) + t[n][1], t[n - 2][1] = t[n - 2][1].slice(0, -1));
        var o;
        if (!e) return t;
        var i = [];
        for (n = 0; n < t.length; n += 1) t[n][1].length > 0 && i.push(t[n]);
        return i;
      }(l);
    }
    function n(t, e, n, o) {
      var i = t.substring(0, n),
        s = e.substring(0, o),
        a = t.substring(n),
        u = e.substring(o),
        p = r(i, s),
        c = r(a, u);
      return p.concat(c);
    }
    function o(t, e) {
      if (!t || !e || t.charAt(0) != e.charAt(0)) return 0;
      for (var r = 0, n = Math.min(t.length, e.length), o = n, i = 0; r < o;) t.substring(i, o) == e.substring(i, o) ? i = r = o : n = o, o = Math.floor((n - r) / 2 + r);
      return o;
    }
    function i(t, e) {
      if (!t || !e || t.charAt(t.length - 1) != e.charAt(e.length - 1)) return 0;
      for (var r = 0, n = Math.min(t.length, e.length), o = n, i = 0; r < o;) t.substring(t.length - o, t.length - i) == e.substring(e.length - o, e.length - i) ? i = r = o : n = o, o = Math.floor((n - r) / 2 + r);
      return o;
    }
    var s = r;
    function a(t, e, r) {
      for (var n = e + r - 1; n >= 0 && n >= e - 1; n--) if (n + 1 < t.length) {
        var o = t[n],
          i = t[n + 1];
        o[0] === i[1] && t.splice(n, 2, [o[0], o[1] + i[1]]);
      }
      return t;
    }
    s.INSERT = 1, s.DELETE = -1, s.EQUAL = 0, t.exports = s;
  }, function (t, e, r) {
    "use strict";

    function n(t) {
      return (n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
        return typeof t;
      } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
      })(t);
    }
    var o;
    if (!Object.keys) {
      var i = Object.prototype.hasOwnProperty,
        s = Object.prototype.toString,
        a = r(5),
        u = Object.prototype.propertyIsEnumerable,
        p = !u.call({
          toString: null
        }, "toString"),
        c = u.call(function () {}, "prototype"),
        l = ["toString", "toLocaleString", "valueOf", "hasOwnProperty", "isPrototypeOf", "propertyIsEnumerable", "constructor"],
        f = function (t) {
          var e = t.constructor;
          return e && e.prototype === t;
        },
        y = {
          $applicationCache: !0,
          $console: !0,
          $external: !0,
          $frame: !0,
          $frameElement: !0,
          $frames: !0,
          $innerHeight: !0,
          $innerWidth: !0,
          $onmozfullscreenchange: !0,
          $onmozfullscreenerror: !0,
          $outerHeight: !0,
          $outerWidth: !0,
          $pageXOffset: !0,
          $pageYOffset: !0,
          $parent: !0,
          $scrollLeft: !0,
          $scrollTop: !0,
          $scrollX: !0,
          $scrollY: !0,
          $self: !0,
          $webkitIndexedDB: !0,
          $webkitStorageInfo: !0,
          $window: !0
        },
        h = function () {
          if ("undefined" == typeof window) return !1;
          for (var t in window) try {
            if (!y["$" + t] && i.call(window, t) && null !== window[t] && "object" === n(window[t])) try {
              f(window[t]);
            } catch (t) {
              return !0;
            }
          } catch (t) {
            return !0;
          }
          return !1;
        }();
      o = function (t) {
        var e = null !== t && "object" === n(t),
          r = "[object Function]" === s.call(t),
          o = a(t),
          u = e && "[object String]" === s.call(t),
          y = [];
        if (!e && !r && !o) throw new TypeError("Object.keys called on a non-object");
        var b = c && r;
        if (u && t.length > 0 && !i.call(t, 0)) for (var m = 0; m < t.length; ++m) y.push(String(m));
        if (o && t.length > 0) for (var d = 0; d < t.length; ++d) y.push(String(d));else for (var g in t) b && "prototype" === g || !i.call(t, g) || y.push(String(g));
        if (p) for (var v = function (t) {
            if ("undefined" == typeof window || !h) return f(t);
            try {
              return f(t);
            } catch (t) {
              return !1;
            }
          }(t), S = 0; S < l.length; ++S) v && "constructor" === l[S] || !i.call(t, l[S]) || y.push(l[S]);
        return y;
      };
    }
    t.exports = o;
  }, function (t, e, r) {
    "use strict";

    function n(t) {
      return (n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
        return typeof t;
      } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
      })(t);
    }
    var o = "function" == typeof Symbol && "symbol" === n(Symbol.toStringTag),
      i = Object.prototype.toString,
      s = function (t) {
        return !(o && t && "object" === n(t) && Symbol.toStringTag in t) && "[object Arguments]" === i.call(t);
      },
      a = function (t) {
        return !!s(t) || null !== t && "object" === n(t) && "number" == typeof t.length && t.length >= 0 && "[object Array]" !== i.call(t) && "[object Function]" === i.call(t.callee);
      },
      u = function () {
        return s(arguments);
      }();
    s.isLegacyArguments = a, t.exports = u ? s : a;
  }, function (t, e, r) {
    "use strict";

    var n = r(0),
      o = r(6),
      i = r(8),
      s = r(9),
      a = r(24),
      u = o(s(), Object);
    n(u, {
      getPolyfill: s,
      implementation: i,
      shim: a
    }), t.exports = u;
  }, function (t, e, r) {
    "use strict";

    var n = "Function.prototype.bind called on incompatible ",
      o = Array.prototype.slice,
      i = Object.prototype.toString;
    t.exports = function (t) {
      var e = this;
      if ("function" != typeof e || "[object Function]" !== i.call(e)) throw new TypeError(n + e);
      for (var r, s = o.call(arguments, 1), a = function () {
          if (this instanceof r) {
            var n = e.apply(this, s.concat(o.call(arguments)));
            return Object(n) === n ? n : this;
          }
          return e.apply(t, s.concat(o.call(arguments)));
        }, u = Math.max(0, e.length - s.length), p = [], c = 0; c < u; c++) p.push("$" + c);
      if (r = Function("binder", "return function (" + p.join(",") + "){ return binder.apply(this,arguments); }")(a), e.prototype) {
        var l = function () {};
        l.prototype = e.prototype, r.prototype = new l(), l.prototype = null;
      }
      return r;
    };
  }, function (t, e, r) {
    "use strict";

    function n(t) {
      return (n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
        return typeof t;
      } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
      })(t);
    }
    var o = TypeError,
      i = Object.getOwnPropertyDescriptor;
    if (i) try {
      i({}, "");
    } catch (t) {
      i = null;
    }
    var s = function () {
        throw new o();
      },
      a = i ? function () {
        try {
          return s;
        } catch (t) {
          try {
            return i(arguments, "callee").get;
          } catch (t) {
            return s;
          }
        }
      }() : s,
      u = r(22)(),
      p = Object.getPrototypeOf || function (t) {
        return t.__proto__;
      },
      c = void 0,
      l = "undefined" == typeof Uint8Array ? void 0 : p(Uint8Array),
      f = {
        "%Array%": Array,
        "%ArrayBuffer%": "undefined" == typeof ArrayBuffer ? void 0 : ArrayBuffer,
        "%ArrayBufferPrototype%": "undefined" == typeof ArrayBuffer ? void 0 : ArrayBuffer.prototype,
        "%ArrayIteratorPrototype%": u ? p([][Symbol.iterator]()) : void 0,
        "%ArrayPrototype%": Array.prototype,
        "%ArrayProto_entries%": Array.prototype.entries,
        "%ArrayProto_forEach%": Array.prototype.forEach,
        "%ArrayProto_keys%": Array.prototype.keys,
        "%ArrayProto_values%": Array.prototype.values,
        "%AsyncFromSyncIteratorPrototype%": void 0,
        "%AsyncFunction%": void 0,
        "%AsyncFunctionPrototype%": void 0,
        "%AsyncGenerator%": void 0,
        "%AsyncGeneratorFunction%": void 0,
        "%AsyncGeneratorPrototype%": void 0,
        "%AsyncIteratorPrototype%": c && u && Symbol.asyncIterator ? c[Symbol.asyncIterator]() : void 0,
        "%Atomics%": "undefined" == typeof Atomics ? void 0 : Atomics,
        "%Boolean%": Boolean,
        "%BooleanPrototype%": Boolean.prototype,
        "%DataView%": "undefined" == typeof DataView ? void 0 : DataView,
        "%DataViewPrototype%": "undefined" == typeof DataView ? void 0 : DataView.prototype,
        "%Date%": Date,
        "%DatePrototype%": Date.prototype,
        "%decodeURI%": decodeURI,
        "%decodeURIComponent%": decodeURIComponent,
        "%encodeURI%": encodeURI,
        "%encodeURIComponent%": encodeURIComponent,
        "%Error%": Error,
        "%ErrorPrototype%": Error.prototype,
        "%eval%": eval,
        "%EvalError%": EvalError,
        "%EvalErrorPrototype%": EvalError.prototype,
        "%Float32Array%": "undefined" == typeof Float32Array ? void 0 : Float32Array,
        "%Float32ArrayPrototype%": "undefined" == typeof Float32Array ? void 0 : Float32Array.prototype,
        "%Float64Array%": "undefined" == typeof Float64Array ? void 0 : Float64Array,
        "%Float64ArrayPrototype%": "undefined" == typeof Float64Array ? void 0 : Float64Array.prototype,
        "%Function%": Function,
        "%FunctionPrototype%": Function.prototype,
        "%Generator%": void 0,
        "%GeneratorFunction%": void 0,
        "%GeneratorPrototype%": void 0,
        "%Int8Array%": "undefined" == typeof Int8Array ? void 0 : Int8Array,
        "%Int8ArrayPrototype%": "undefined" == typeof Int8Array ? void 0 : Int8Array.prototype,
        "%Int16Array%": "undefined" == typeof Int16Array ? void 0 : Int16Array,
        "%Int16ArrayPrototype%": "undefined" == typeof Int16Array ? void 0 : Int8Array.prototype,
        "%Int32Array%": "undefined" == typeof Int32Array ? void 0 : Int32Array,
        "%Int32ArrayPrototype%": "undefined" == typeof Int32Array ? void 0 : Int32Array.prototype,
        "%isFinite%": isFinite,
        "%isNaN%": isNaN,
        "%IteratorPrototype%": u ? p(p([][Symbol.iterator]())) : void 0,
        "%JSON%": "object" === ("undefined" == typeof JSON ? "undefined" : n(JSON)) ? JSON : void 0,
        "%JSONParse%": "object" === ("undefined" == typeof JSON ? "undefined" : n(JSON)) ? JSON.parse : void 0,
        "%Map%": "undefined" == typeof Map ? void 0 : Map,
        "%MapIteratorPrototype%": "undefined" != typeof Map && u ? p(new Map()[Symbol.iterator]()) : void 0,
        "%MapPrototype%": "undefined" == typeof Map ? void 0 : Map.prototype,
        "%Math%": Math,
        "%Number%": Number,
        "%NumberPrototype%": Number.prototype,
        "%Object%": Object,
        "%ObjectPrototype%": Object.prototype,
        "%ObjProto_toString%": Object.prototype.toString,
        "%ObjProto_valueOf%": Object.prototype.valueOf,
        "%parseFloat%": parseFloat,
        "%parseInt%": parseInt,
        "%Promise%": "undefined" == typeof Promise ? void 0 : Promise,
        "%PromisePrototype%": "undefined" == typeof Promise ? void 0 : Promise.prototype,
        "%PromiseProto_then%": "undefined" == typeof Promise ? void 0 : Promise.prototype.then,
        "%Promise_all%": "undefined" == typeof Promise ? void 0 : Promise.all,
        "%Promise_reject%": "undefined" == typeof Promise ? void 0 : Promise.reject,
        "%Promise_resolve%": "undefined" == typeof Promise ? void 0 : Promise.resolve,
        "%Proxy%": "undefined" == typeof Proxy ? void 0 : Proxy,
        "%RangeError%": RangeError,
        "%RangeErrorPrototype%": RangeError.prototype,
        "%ReferenceError%": ReferenceError,
        "%ReferenceErrorPrototype%": ReferenceError.prototype,
        "%Reflect%": "undefined" == typeof Reflect ? void 0 : Reflect,
        "%RegExp%": RegExp,
        "%RegExpPrototype%": RegExp.prototype,
        "%Set%": "undefined" == typeof Set ? void 0 : Set,
        "%SetIteratorPrototype%": "undefined" != typeof Set && u ? p(new Set()[Symbol.iterator]()) : void 0,
        "%SetPrototype%": "undefined" == typeof Set ? void 0 : Set.prototype,
        "%SharedArrayBuffer%": "undefined" == typeof SharedArrayBuffer ? void 0 : SharedArrayBuffer,
        "%SharedArrayBufferPrototype%": "undefined" == typeof SharedArrayBuffer ? void 0 : SharedArrayBuffer.prototype,
        "%String%": String,
        "%StringIteratorPrototype%": u ? p(""[Symbol.iterator]()) : void 0,
        "%StringPrototype%": String.prototype,
        "%Symbol%": u ? Symbol : void 0,
        "%SymbolPrototype%": u ? Symbol.prototype : void 0,
        "%SyntaxError%": SyntaxError,
        "%SyntaxErrorPrototype%": SyntaxError.prototype,
        "%ThrowTypeError%": a,
        "%TypedArray%": l,
        "%TypedArrayPrototype%": l ? l.prototype : void 0,
        "%TypeError%": o,
        "%TypeErrorPrototype%": o.prototype,
        "%Uint8Array%": "undefined" == typeof Uint8Array ? void 0 : Uint8Array,
        "%Uint8ArrayPrototype%": "undefined" == typeof Uint8Array ? void 0 : Uint8Array.prototype,
        "%Uint8ClampedArray%": "undefined" == typeof Uint8ClampedArray ? void 0 : Uint8ClampedArray,
        "%Uint8ClampedArrayPrototype%": "undefined" == typeof Uint8ClampedArray ? void 0 : Uint8ClampedArray.prototype,
        "%Uint16Array%": "undefined" == typeof Uint16Array ? void 0 : Uint16Array,
        "%Uint16ArrayPrototype%": "undefined" == typeof Uint16Array ? void 0 : Uint16Array.prototype,
        "%Uint32Array%": "undefined" == typeof Uint32Array ? void 0 : Uint32Array,
        "%Uint32ArrayPrototype%": "undefined" == typeof Uint32Array ? void 0 : Uint32Array.prototype,
        "%URIError%": URIError,
        "%URIErrorPrototype%": URIError.prototype,
        "%WeakMap%": "undefined" == typeof WeakMap ? void 0 : WeakMap,
        "%WeakMapPrototype%": "undefined" == typeof WeakMap ? void 0 : WeakMap.prototype,
        "%WeakSet%": "undefined" == typeof WeakSet ? void 0 : WeakSet,
        "%WeakSetPrototype%": "undefined" == typeof WeakSet ? void 0 : WeakSet.prototype
      },
      y = r(1).call(Function.call, String.prototype.replace),
      h = /[^%.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|%$))/g,
      b = /\\(\\)?/g,
      m = function (t) {
        var e = [];
        return y(t, h, function (t, r, n, o) {
          e[e.length] = n ? y(o, b, "$1") : r || t;
        }), e;
      },
      d = function (t, e) {
        if (!(t in f)) throw new SyntaxError("intrinsic " + t + " does not exist!");
        if (void 0 === f[t] && !e) throw new o("intrinsic " + t + " exists, but is not available. Please file an issue!");
        return f[t];
      };
    t.exports = function (t, e) {
      if ("string" != typeof t || 0 === t.length) throw new TypeError("intrinsic name must be a non-empty string");
      if (arguments.length > 1 && "boolean" != typeof e) throw new TypeError('"allowMissing" argument must be a boolean');
      for (var r = m(t), n = d("%" + (r.length > 0 ? r[0] : "") + "%", e), s = 1; s < r.length; s += 1) if (null != n) if (i && s + 1 >= r.length) {
        var a = i(n, r[s]);
        if (!e && !(r[s] in n)) throw new o("base intrinsic for " + t + " exists, but the property is not available.");
        n = a ? a.get || a.value : n[r[s]];
      } else n = n[r[s]];
      return n;
    };
  }, function (t, e, r) {
    "use strict";

    (function (e) {
      function n(t) {
        return (n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
          return typeof t;
        } : function (t) {
          return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
        })(t);
      }
      var o = e.Symbol,
        i = r(23);
      t.exports = function () {
        return "function" == typeof o && "function" == typeof Symbol && "symbol" === n(o("foo")) && "symbol" === n(Symbol("bar")) && i();
      };
    }).call(this, r(7));
  }, function (t, e, r) {
    "use strict";

    function n(t) {
      return (n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
        return typeof t;
      } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
      })(t);
    }
    t.exports = function () {
      if ("function" != typeof Symbol || "function" != typeof Object.getOwnPropertySymbols) return !1;
      if ("symbol" === n(Symbol.iterator)) return !0;
      var t = {},
        e = Symbol("test"),
        r = Object(e);
      if ("string" == typeof e) return !1;
      if ("[object Symbol]" !== Object.prototype.toString.call(e)) return !1;
      if ("[object Symbol]" !== Object.prototype.toString.call(r)) return !1;
      for (e in t[e] = 42, t) return !1;
      if ("function" == typeof Object.keys && 0 !== Object.keys(t).length) return !1;
      if ("function" == typeof Object.getOwnPropertyNames && 0 !== Object.getOwnPropertyNames(t).length) return !1;
      var o = Object.getOwnPropertySymbols(t);
      if (1 !== o.length || o[0] !== e) return !1;
      if (!Object.prototype.propertyIsEnumerable.call(t, e)) return !1;
      if ("function" == typeof Object.getOwnPropertyDescriptor) {
        var i = Object.getOwnPropertyDescriptor(t, e);
        if (42 !== i.value || !0 !== i.enumerable) return !1;
      }
      return !0;
    };
  }, function (t, e, r) {
    "use strict";

    var n = r(9),
      o = r(0);
    t.exports = function () {
      var t = n();
      return o(Object, {
        is: t
      }, {
        is: function () {
          return Object.is !== t;
        }
      }), t;
    };
  }, function (t, e, r) {
    "use strict";

    function n(t) {
      return (n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
        return typeof t;
      } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
      })(t);
    }
    var o = r(26),
      i = RegExp.prototype.exec,
      s = Object.getOwnPropertyDescriptor,
      a = Object.prototype.toString,
      u = "function" == typeof Symbol && "symbol" === n(Symbol.toStringTag);
    t.exports = function (t) {
      if (!t || "object" !== n(t)) return !1;
      if (!u) return "[object RegExp]" === a.call(t);
      var e = s(t, "lastIndex");
      return !(!e || !o(e, "value")) && function (t) {
        try {
          var e = t.lastIndex;
          return t.lastIndex = 0, i.call(t), !0;
        } catch (t) {
          return !1;
        } finally {
          t.lastIndex = e;
        }
      }(t);
    };
  }, function (t, e, r) {
    "use strict";

    var n = r(1);
    t.exports = n.call(Function.call, Object.prototype.hasOwnProperty);
  }, function (t, e, r) {
    "use strict";

    var n = r(0),
      o = r(6),
      i = r(10),
      s = r(11),
      a = r(28),
      u = o(i);
    n(u, {
      getPolyfill: s,
      implementation: i,
      shim: a
    }), t.exports = u;
  }, function (t, e, r) {
    "use strict";

    var n = r(0).supportsDescriptors,
      o = r(11),
      i = Object.getOwnPropertyDescriptor,
      s = Object.defineProperty,
      a = TypeError,
      u = Object.getPrototypeOf,
      p = /a/;
    t.exports = function () {
      if (!n || !u) throw new a("RegExp.prototype.flags requires a true ES5 environment that supports property descriptors");
      var t = o(),
        e = u(p),
        r = i(e, "flags");
      return r && r.get === t || s(e, "flags", {
        configurable: !0,
        enumerable: !1,
        get: t
      }), t;
    };
  }, function (t, e, r) {
    "use strict";

    function n(t) {
      return (n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
        return typeof t;
      } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
      })(t);
    }
    var o = Date.prototype.getDay,
      i = Object.prototype.toString,
      s = "function" == typeof Symbol && "symbol" === n(Symbol.toStringTag);
    t.exports = function (t) {
      return "object" === n(t) && null !== t && (s ? function (t) {
        try {
          return o.call(t), !0;
        } catch (t) {
          return !1;
        }
      }(t) : "[object Date]" === i.call(t));
    };
  }, function (t, e, r) {
    function n(t) {
      return (n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
        return typeof t;
      } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
      })(t);
    }
    var o = r(3),
      i = r(12),
      s = {
        attributes: {
          compose: function (t, e, r) {
            "object" !== n(t) && (t = {}), "object" !== n(e) && (e = {});
            var o = i(!0, {}, e);
            for (var s in r || (o = Object.keys(o).reduce(function (t, e) {
              return null != o[e] && (t[e] = o[e]), t;
            }, {})), t) void 0 !== t[s] && void 0 === e[s] && (o[s] = t[s]);
            return Object.keys(o).length > 0 ? o : void 0;
          },
          diff: function (t, e) {
            "object" !== n(t) && (t = {}), "object" !== n(e) && (e = {});
            var r = Object.keys(t).concat(Object.keys(e)).reduce(function (r, n) {
              return o(t[n], e[n]) || (r[n] = void 0 === e[n] ? null : e[n]), r;
            }, {});
            return Object.keys(r).length > 0 ? r : void 0;
          },
          transform: function (t, e, r) {
            if ("object" !== n(t)) return e;
            if ("object" === n(e)) {
              if (!r) return e;
              var o = Object.keys(e).reduce(function (r, n) {
                return void 0 === t[n] && (r[n] = e[n]), r;
              }, {});
              return Object.keys(o).length > 0 ? o : void 0;
            }
          }
        },
        iterator: function (t) {
          return new a(t);
        },
        length: function (t) {
          return "number" == typeof t.delete ? t.delete : "number" == typeof t.retain ? t.retain : "string" == typeof t.insert ? t.insert.length : 1;
        }
      };
    function a(t) {
      this.ops = t, this.index = 0, this.offset = 0;
    }
    a.prototype.hasNext = function () {
      return this.peekLength() < 1 / 0;
    }, a.prototype.next = function (t) {
      t || (t = 1 / 0);
      var e = this.ops[this.index];
      if (e) {
        var r = this.offset,
          n = s.length(e);
        if (t >= n - r ? (t = n - r, this.index += 1, this.offset = 0) : this.offset += t, "number" == typeof e.delete) return {
          delete: t
        };
        var o = {};
        return e.attributes && (o.attributes = e.attributes), "number" == typeof e.retain ? o.retain = t : "string" == typeof e.insert ? o.insert = e.insert.substr(r, t) : o.insert = e.insert, o;
      }
      return {
        retain: 1 / 0
      };
    }, a.prototype.peek = function () {
      return this.ops[this.index];
    }, a.prototype.peekLength = function () {
      return this.ops[this.index] ? s.length(this.ops[this.index]) - this.offset : 1 / 0;
    }, a.prototype.peekType = function () {
      return this.ops[this.index] ? "number" == typeof this.ops[this.index].delete ? "delete" : "number" == typeof this.ops[this.index].retain ? "retain" : "insert" : "retain";
    }, a.prototype.rest = function () {
      if (this.hasNext()) {
        if (0 === this.offset) return this.ops.slice(this.index);
        var t = this.offset,
          e = this.index,
          r = this.next(),
          n = this.ops.slice(this.index);
        return this.offset = t, this.index = e, [r].concat(n);
      }
      return [];
    }, t.exports = s;
  }, function (t, e, r) {
    "use strict";

    function n(t) {
      return (n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
        return typeof t;
      } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
      })(t);
    }
    var o = r(32),
      i = r(34);
    function s() {
      this.protocol = null, this.slashes = null, this.auth = null, this.host = null, this.port = null, this.hostname = null, this.hash = null, this.search = null, this.query = null, this.pathname = null, this.path = null, this.href = null;
    }
    e.parse = S, e.resolve = function (t, e) {
      return S(t, !1, !0).resolve(e);
    }, e.resolveObject = function (t, e) {
      return t ? S(t, !1, !0).resolveObject(e) : e;
    }, e.format = function (t) {
      i.isString(t) && (t = S(t));
      return t instanceof s ? t.format() : s.prototype.format.call(t);
    }, e.Url = s;
    var a = /^([a-z0-9.+-]+:)/i,
      u = /:[0-9]*$/,
      p = /^(\/\/?(?!\/)[^\?\s]*)(\?[^\s]*)?$/,
      c = ["{", "}", "|", "\\", "^", "`"].concat(["<", ">", '"', "`", " ", "\r", "\n", "\t"]),
      l = ["'"].concat(c),
      f = ["%", "/", "?", ";", "#"].concat(l),
      y = ["/", "?", "#"],
      h = /^[+a-z0-9A-Z_-]{0,63}$/,
      b = /^([+a-z0-9A-Z_-]{0,63})(.*)$/,
      m = {
        javascript: !0,
        "javascript:": !0
      },
      d = {
        javascript: !0,
        "javascript:": !0
      },
      g = {
        http: !0,
        https: !0,
        ftp: !0,
        gopher: !0,
        file: !0,
        "http:": !0,
        "https:": !0,
        "ftp:": !0,
        "gopher:": !0,
        "file:": !0
      },
      v = r(35);
    function S(t, e, r) {
      if (t && i.isObject(t) && t instanceof s) return t;
      var n = new s();
      return n.parse(t, e, r), n;
    }
    s.prototype.parse = function (t, e, r) {
      if (!i.isString(t)) throw new TypeError("Parameter 'url' must be a string, not " + n(t));
      var s = t.indexOf("?"),
        u = -1 !== s && s < t.indexOf("#") ? "?" : "#",
        c = t.split(u);
      c[0] = c[0].replace(/\\/g, "/");
      var S = t = c.join(u);
      if (S = S.trim(), !r && 1 === t.split("#").length) {
        var j = p.exec(S);
        if (j) return this.path = S, this.href = S, this.pathname = j[1], j[2] ? (this.search = j[2], this.query = e ? v.parse(this.search.substr(1)) : this.search.substr(1)) : e && (this.search = "", this.query = {}), this;
      }
      var w = a.exec(S);
      if (w) {
        var x = (w = w[0]).toLowerCase();
        this.protocol = x, S = S.substr(w.length);
      }
      if (r || w || S.match(/^\/\/[^@\/]+@[^@\/]+/)) {
        var O = "//" === S.substr(0, 2);
        !O || w && d[w] || (S = S.substr(2), this.slashes = !0);
      }
      if (!d[w] && (O || w && !g[w])) {
        for (var A, P, E = -1, I = 0; I < y.length; I++) {
          -1 !== (k = S.indexOf(y[I])) && (-1 === E || k < E) && (E = k);
        }
        -1 !== (P = -1 === E ? S.lastIndexOf("@") : S.lastIndexOf("@", E)) && (A = S.slice(0, P), S = S.slice(P + 1), this.auth = decodeURIComponent(A)), E = -1;
        for (I = 0; I < f.length; I++) {
          var k;
          -1 !== (k = S.indexOf(f[I])) && (-1 === E || k < E) && (E = k);
        }
        -1 === E && (E = S.length), this.host = S.slice(0, E), S = S.slice(E), this.parseHost(), this.hostname = this.hostname || "";
        var U = "[" === this.hostname[0] && "]" === this.hostname[this.hostname.length - 1];
        if (!U) for (var R = this.hostname.split(/\./), T = (I = 0, R.length); I < T; I++) {
          var N = R[I];
          if (N && !N.match(h)) {
            for (var C = "", F = 0, M = N.length; F < M; F++) N.charCodeAt(F) > 127 ? C += "x" : C += N[F];
            if (!C.match(h)) {
              var D = R.slice(0, I),
                $ = R.slice(I + 1),
                L = N.match(b);
              L && (D.push(L[1]), $.unshift(L[2])), $.length && (S = "/" + $.join(".") + S), this.hostname = D.join(".");
              break;
            }
          }
        }
        this.hostname.length > 255 ? this.hostname = "" : this.hostname = this.hostname.toLowerCase(), U || (this.hostname = o.toASCII(this.hostname));
        var _ = this.port ? ":" + this.port : "",
          q = this.hostname || "";
        this.host = q + _, this.href += this.host, U && (this.hostname = this.hostname.substr(1, this.hostname.length - 2), "/" !== S[0] && (S = "/" + S));
      }
      if (!m[x]) for (I = 0, T = l.length; I < T; I++) {
        var z = l[I];
        if (-1 !== S.indexOf(z)) {
          var W = encodeURIComponent(z);
          W === z && (W = escape(z)), S = S.split(z).join(W);
        }
      }
      var H = S.indexOf("#");
      -1 !== H && (this.hash = S.substr(H), S = S.slice(0, H));
      var B = S.indexOf("?");
      if (-1 !== B ? (this.search = S.substr(B), this.query = S.substr(B + 1), e && (this.query = v.parse(this.query)), S = S.slice(0, B)) : e && (this.search = "", this.query = {}), S && (this.pathname = S), g[x] && this.hostname && !this.pathname && (this.pathname = "/"), this.pathname || this.search) {
        _ = this.pathname || "";
        var V = this.search || "";
        this.path = _ + V;
      }
      return this.href = this.format(), this;
    }, s.prototype.format = function () {
      var t = this.auth || "";
      t && (t = (t = encodeURIComponent(t)).replace(/%3A/i, ":"), t += "@");
      var e = this.protocol || "",
        r = this.pathname || "",
        n = this.hash || "",
        o = !1,
        s = "";
      this.host ? o = t + this.host : this.hostname && (o = t + (-1 === this.hostname.indexOf(":") ? this.hostname : "[" + this.hostname + "]"), this.port && (o += ":" + this.port)), this.query && i.isObject(this.query) && Object.keys(this.query).length && (s = v.stringify(this.query));
      var a = this.search || s && "?" + s || "";
      return e && ":" !== e.substr(-1) && (e += ":"), this.slashes || (!e || g[e]) && !1 !== o ? (o = "//" + (o || ""), r && "/" !== r.charAt(0) && (r = "/" + r)) : o || (o = ""), n && "#" !== n.charAt(0) && (n = "#" + n), a && "?" !== a.charAt(0) && (a = "?" + a), e + o + (r = r.replace(/[?#]/g, function (t) {
        return encodeURIComponent(t);
      })) + (a = a.replace("#", "%23")) + n;
    }, s.prototype.resolve = function (t) {
      return this.resolveObject(S(t, !1, !0)).format();
    }, s.prototype.resolveObject = function (t) {
      if (i.isString(t)) {
        var e = new s();
        e.parse(t, !1, !0), t = e;
      }
      for (var r = new s(), n = Object.keys(this), o = 0; o < n.length; o++) {
        var a = n[o];
        r[a] = this[a];
      }
      if (r.hash = t.hash, "" === t.href) return r.href = r.format(), r;
      if (t.slashes && !t.protocol) {
        for (var u = Object.keys(t), p = 0; p < u.length; p++) {
          var c = u[p];
          "protocol" !== c && (r[c] = t[c]);
        }
        return g[r.protocol] && r.hostname && !r.pathname && (r.path = r.pathname = "/"), r.href = r.format(), r;
      }
      if (t.protocol && t.protocol !== r.protocol) {
        if (!g[t.protocol]) {
          for (var l = Object.keys(t), f = 0; f < l.length; f++) {
            var y = l[f];
            r[y] = t[y];
          }
          return r.href = r.format(), r;
        }
        if (r.protocol = t.protocol, t.host || d[t.protocol]) r.pathname = t.pathname;else {
          for (var h = (t.pathname || "").split("/"); h.length && !(t.host = h.shift()););
          t.host || (t.host = ""), t.hostname || (t.hostname = ""), "" !== h[0] && h.unshift(""), h.length < 2 && h.unshift(""), r.pathname = h.join("/");
        }
        if (r.search = t.search, r.query = t.query, r.host = t.host || "", r.auth = t.auth, r.hostname = t.hostname || t.host, r.port = t.port, r.pathname || r.search) {
          var b = r.pathname || "",
            m = r.search || "";
          r.path = b + m;
        }
        return r.slashes = r.slashes || t.slashes, r.href = r.format(), r;
      }
      var v = r.pathname && "/" === r.pathname.charAt(0),
        S = t.host || t.pathname && "/" === t.pathname.charAt(0),
        j = S || v || r.host && t.pathname,
        w = j,
        x = r.pathname && r.pathname.split("/") || [],
        O = (h = t.pathname && t.pathname.split("/") || [], r.protocol && !g[r.protocol]);
      if (O && (r.hostname = "", r.port = null, r.host && ("" === x[0] ? x[0] = r.host : x.unshift(r.host)), r.host = "", t.protocol && (t.hostname = null, t.port = null, t.host && ("" === h[0] ? h[0] = t.host : h.unshift(t.host)), t.host = null), j = j && ("" === h[0] || "" === x[0])), S) r.host = t.host || "" === t.host ? t.host : r.host, r.hostname = t.hostname || "" === t.hostname ? t.hostname : r.hostname, r.search = t.search, r.query = t.query, x = h;else if (h.length) x || (x = []), x.pop(), x = x.concat(h), r.search = t.search, r.query = t.query;else if (!i.isNullOrUndefined(t.search)) {
        if (O) r.hostname = r.host = x.shift(), (k = !!(r.host && r.host.indexOf("@") > 0) && r.host.split("@")) && (r.auth = k.shift(), r.host = r.hostname = k.shift());
        return r.search = t.search, r.query = t.query, i.isNull(r.pathname) && i.isNull(r.search) || (r.path = (r.pathname ? r.pathname : "") + (r.search ? r.search : "")), r.href = r.format(), r;
      }
      if (!x.length) return r.pathname = null, r.search ? r.path = "/" + r.search : r.path = null, r.href = r.format(), r;
      for (var A = x.slice(-1)[0], P = (r.host || t.host || x.length > 1) && ("." === A || ".." === A) || "" === A, E = 0, I = x.length; I >= 0; I--) "." === (A = x[I]) ? x.splice(I, 1) : ".." === A ? (x.splice(I, 1), E++) : E && (x.splice(I, 1), E--);
      if (!j && !w) for (; E--; E) x.unshift("..");
      !j || "" === x[0] || x[0] && "/" === x[0].charAt(0) || x.unshift(""), P && "/" !== x.join("/").substr(-1) && x.push("");
      var k,
        U = "" === x[0] || x[0] && "/" === x[0].charAt(0);
      O && (r.hostname = r.host = U ? "" : x.length ? x.shift() : "", (k = !!(r.host && r.host.indexOf("@") > 0) && r.host.split("@")) && (r.auth = k.shift(), r.host = r.hostname = k.shift()));
      return (j = j || r.host && x.length) && !U && x.unshift(""), x.length ? r.pathname = x.join("/") : (r.pathname = null, r.path = null), i.isNull(r.pathname) && i.isNull(r.search) || (r.path = (r.pathname ? r.pathname : "") + (r.search ? r.search : "")), r.auth = t.auth || r.auth, r.slashes = r.slashes || t.slashes, r.href = r.format(), r;
    }, s.prototype.parseHost = function () {
      var t = this.host,
        e = u.exec(t);
      e && (":" !== (e = e[0]) && (this.port = e.substr(1)), t = t.substr(0, t.length - e.length)), t && (this.hostname = t);
    };
  }, function (t, e, r) {
    (function (t, n) {
      var o;
      function i(t) {
        return (i = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
          return typeof t;
        } : function (t) {
          return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
        })(t);
      }
      /*! https://mths.be/punycode v1.4.1 by @mathias */
      !function (s) {
        var a = "object" == i(e) && e && !e.nodeType && e,
          u = "object" == i(t) && t && !t.nodeType && t,
          p = "object" == (void 0 === n ? "undefined" : i(n)) && n;
        p.global !== p && p.window !== p && p.self !== p || (s = p);
        var c,
          l,
          f = 2147483647,
          y = /^xn--/,
          h = /[^\x20-\x7E]/,
          b = /[\x2E\u3002\uFF0E\uFF61]/g,
          m = {
            overflow: "Overflow: input needs wider integers to process",
            "not-basic": "Illegal input >= 0x80 (not a basic code point)",
            "invalid-input": "Invalid input"
          },
          d = Math.floor,
          g = String.fromCharCode;
        function v(t) {
          throw new RangeError(m[t]);
        }
        function S(t, e) {
          for (var r = t.length, n = []; r--;) n[r] = e(t[r]);
          return n;
        }
        function j(t, e) {
          var r = t.split("@"),
            n = "";
          return r.length > 1 && (n = r[0] + "@", t = r[1]), n + S((t = t.replace(b, ".")).split("."), e).join(".");
        }
        function w(t) {
          for (var e, r, n = [], o = 0, i = t.length; o < i;) (e = t.charCodeAt(o++)) >= 55296 && e <= 56319 && o < i ? 56320 == (64512 & (r = t.charCodeAt(o++))) ? n.push(((1023 & e) << 10) + (1023 & r) + 65536) : (n.push(e), o--) : n.push(e);
          return n;
        }
        function x(t) {
          return S(t, function (t) {
            var e = "";
            return t > 65535 && (e += g((t -= 65536) >>> 10 & 1023 | 55296), t = 56320 | 1023 & t), e += g(t);
          }).join("");
        }
        function O(t, e) {
          return t + 22 + 75 * (t < 26) - ((0 != e) << 5);
        }
        function A(t, e, r) {
          var n = 0;
          for (t = r ? d(t / 700) : t >> 1, t += d(t / e); t > 455; n += 36) t = d(t / 35);
          return d(n + 36 * t / (t + 38));
        }
        function P(t) {
          var e,
            r,
            n,
            o,
            i,
            s,
            a,
            u,
            p,
            c,
            l,
            y = [],
            h = t.length,
            b = 0,
            m = 128,
            g = 72;
          for ((r = t.lastIndexOf("-")) < 0 && (r = 0), n = 0; n < r; ++n) t.charCodeAt(n) >= 128 && v("not-basic"), y.push(t.charCodeAt(n));
          for (o = r > 0 ? r + 1 : 0; o < h;) {
            for (i = b, s = 1, a = 36; o >= h && v("invalid-input"), ((u = (l = t.charCodeAt(o++)) - 48 < 10 ? l - 22 : l - 65 < 26 ? l - 65 : l - 97 < 26 ? l - 97 : 36) >= 36 || u > d((f - b) / s)) && v("overflow"), b += u * s, !(u < (p = a <= g ? 1 : a >= g + 26 ? 26 : a - g)); a += 36) s > d(f / (c = 36 - p)) && v("overflow"), s *= c;
            g = A(b - i, e = y.length + 1, 0 == i), d(b / e) > f - m && v("overflow"), m += d(b / e), b %= e, y.splice(b++, 0, m);
          }
          return x(y);
        }
        function E(t) {
          var e,
            r,
            n,
            o,
            i,
            s,
            a,
            u,
            p,
            c,
            l,
            y,
            h,
            b,
            m,
            S = [];
          for (y = (t = w(t)).length, e = 128, r = 0, i = 72, s = 0; s < y; ++s) (l = t[s]) < 128 && S.push(g(l));
          for (n = o = S.length, o && S.push("-"); n < y;) {
            for (a = f, s = 0; s < y; ++s) (l = t[s]) >= e && l < a && (a = l);
            for (a - e > d((f - r) / (h = n + 1)) && v("overflow"), r += (a - e) * h, e = a, s = 0; s < y; ++s) if ((l = t[s]) < e && ++r > f && v("overflow"), l == e) {
              for (u = r, p = 36; !(u < (c = p <= i ? 1 : p >= i + 26 ? 26 : p - i)); p += 36) m = u - c, b = 36 - c, S.push(g(O(c + m % b, 0))), u = d(m / b);
              S.push(g(O(u, 0))), i = A(r, h, n == o), r = 0, ++n;
            }
            ++r, ++e;
          }
          return S.join("");
        }
        if (c = {
          version: "1.4.1",
          ucs2: {
            decode: w,
            encode: x
          },
          decode: P,
          encode: E,
          toASCII: function (t) {
            return j(t, function (t) {
              return h.test(t) ? "xn--" + E(t) : t;
            });
          },
          toUnicode: function (t) {
            return j(t, function (t) {
              return y.test(t) ? P(t.slice(4).toLowerCase()) : t;
            });
          }
        }, "object" == i(r(13)) && r(13)) void 0 === (o = function () {
          return c;
        }.call(e, r, e, t)) || (t.exports = o);else if (a && u) {
          if (t.exports == a) u.exports = c;else for (l in c) c.hasOwnProperty(l) && (a[l] = c[l]);
        } else s.punycode = c;
      }(this);
    }).call(this, r(33)(t), r(7));
  }, function (t, e) {
    t.exports = function (t) {
      return t.webpackPolyfill || (t.deprecate = function () {}, t.paths = [], t.children || (t.children = []), Object.defineProperty(t, "loaded", {
        enumerable: !0,
        get: function () {
          return t.l;
        }
      }), Object.defineProperty(t, "id", {
        enumerable: !0,
        get: function () {
          return t.i;
        }
      }), t.webpackPolyfill = 1), t;
    };
  }, function (t, e, r) {
    "use strict";

    function n(t) {
      return (n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
        return typeof t;
      } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
      })(t);
    }
    t.exports = {
      isString: function (t) {
        return "string" == typeof t;
      },
      isObject: function (t) {
        return "object" === n(t) && null !== t;
      },
      isNull: function (t) {
        return null === t;
      },
      isNullOrUndefined: function (t) {
        return null == t;
      }
    };
  }, function (t, e, r) {
    "use strict";

    e.decode = e.parse = r(36), e.encode = e.stringify = r(37);
  }, function (t, e, r) {
    "use strict";

    function n(t, e) {
      return Object.prototype.hasOwnProperty.call(t, e);
    }
    t.exports = function (t, e, r, i) {
      e = e || "&", r = r || "=";
      var s = {};
      if ("string" != typeof t || 0 === t.length) return s;
      var a = /\+/g;
      t = t.split(e);
      var u = 1e3;
      i && "number" == typeof i.maxKeys && (u = i.maxKeys);
      var p = t.length;
      u > 0 && p > u && (p = u);
      for (var c = 0; c < p; ++c) {
        var l,
          f,
          y,
          h,
          b = t[c].replace(a, "%20"),
          m = b.indexOf(r);
        m >= 0 ? (l = b.substr(0, m), f = b.substr(m + 1)) : (l = b, f = ""), y = decodeURIComponent(l), h = decodeURIComponent(f), n(s, y) ? o(s[y]) ? s[y].push(h) : s[y] = [s[y], h] : s[y] = h;
      }
      return s;
    };
    var o = Array.isArray || function (t) {
      return "[object Array]" === Object.prototype.toString.call(t);
    };
  }, function (t, e, r) {
    "use strict";

    function n(t) {
      return (n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
        return typeof t;
      } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
      })(t);
    }
    var o = function (t) {
      switch (n(t)) {
        case "string":
          return t;
        case "boolean":
          return t ? "true" : "false";
        case "number":
          return isFinite(t) ? t : "";
        default:
          return "";
      }
    };
    t.exports = function (t, e, r, u) {
      return e = e || "&", r = r || "=", null === t && (t = void 0), "object" === n(t) ? s(a(t), function (n) {
        var a = encodeURIComponent(o(n)) + r;
        return i(t[n]) ? s(t[n], function (t) {
          return a + encodeURIComponent(o(t));
        }).join(e) : a + encodeURIComponent(o(t[n]));
      }).join(e) : u ? encodeURIComponent(o(u)) + r + encodeURIComponent(o(t)) : "";
    };
    var i = Array.isArray || function (t) {
      return "[object Array]" === Object.prototype.toString.call(t);
    };
    function s(t, e) {
      if (t.map) return t.map(e);
      for (var r = [], n = 0; n < t.length; n++) r.push(e(t[n], n));
      return r;
    }
    var a = Object.keys || function (t) {
      var e = [];
      for (var r in t) Object.prototype.hasOwnProperty.call(t, r) && e.push(r);
      return e;
    };
  }]);
});