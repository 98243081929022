<div class="modal-header" *transloco="let t; read: 'modals.tenant'">
  <h5 class="modal-title">{{ t('title-members-list') }}</h5>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
  </button>
</div>

<div
  class="modal-body tenant-members-list"
  *transloco="let t; read: 'modals.tenant'"
  id="dm-members-modal"
>
  <section id="ngx">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label class="form-control-label" for="chatGroupMembers">{{ t('chat-members') }}</label>
          <ng-select
            [items]="users"
            bindLabel="userName"
            bindValue="_id"
            (change)="onSelectUser($event)"
            id="directChatMembers"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index" id="guests_select">
              <div class="d-flex align-items-center">
                <app-avatar
                  [userId]="item._id"
                  [height]="24"
                  [width]="24"
                  [statusSize]="9"
                ></app-avatar>
                <div class="ml-1">
                  <b>{{ item.userName }}</b>
                </div>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
  </section>
</div>
