import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';
import moment from 'moment-timezone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SprintModal } from '../../../../shared/store/models/SprintState';
import { SprintsDbDto } from '../../../../api/models/sprints-db-dto';
import { SprintsState } from '../../../../shared/store/states/sprints.state';
import { SprintCreateEditComponent } from '../../../../modals/sprint-create-edit/sprint-create-edit.component';
import { SprintGet } from '../../../../shared/store/actions/sprint.action';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root',
})
export class SprintsService {
  private activeSprint$$ = new BehaviorSubject<SprintModal | SprintsDbDto>(undefined);
  public activeSprint$ = this.activeSprint$$.asObservable();

  constructor(
    private store: Store,
    public modalService: NgbModal,
  ) {
    this.store
      .select(SprintsState.getActiveSprint)
      .pipe(untilDestroyed(this))
      .subscribe((activeSprint) => {
        this.activeSprint$$.next(activeSprint);
      });
  }

  /* Methods */
  public getSprintDate(sprint: SprintModal | SprintsDbDto): string {
    if (!sprint?.start || !sprint?.end) {
      return '';
    }

    return `${moment(sprint.start).format('DD MMM')} - ${moment(sprint.end).format('DD MMM')}`;
  }

  openCreateSprintModal(event, object: string, objectId: string): void {
    event.stopPropagation();
    const modalRef = this.modalService.open(SprintCreateEditComponent);
    modalRef.componentInstance.object = object;
    modalRef.componentInstance.objectId = objectId;
    modalRef.componentInstance.onCreateSprint
      .pipe(untilDestroyed(this))
      .subscribe(() => this.store.dispatch(new SprintGet({ object, objectId })));
  }
}
