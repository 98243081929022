import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext } from '@ngxs/store';

import { ConfigsStateModel } from '../models/ConfigsState';
import { ToggleSidebar } from '../actions/configs.action';

@State<ConfigsStateModel>({
  name: 'Configs',
  defaults: {
    isOpened: false,
    sidebarContentType: null,
  },
})
@Injectable()
export class ConfigsState {
  /**
   * get is opened sidebar
   * @param  {ConfigsStateModel} state
   */
  @Selector()
  static getIsOpenedSidebar(state: ConfigsStateModel) {
    return state.isOpened;
  }

  /**
   * get sidebarContentType
   * @param  {ConfigsStateModel} state
   */
  @Selector()
  static getSidebarContentType(state: ConfigsStateModel) {
    return state.sidebarContentType;
  }

  /**
   * get sidebar configs
   * @param  {ConfigsStateModel} state
   */
  @Selector()
  static getSidebarConfigs(state: ConfigsStateModel) {
    return {
      isOpened: state.isOpened,
      sidebarContentType: state.sidebarContentType,
    };
  }

  constructor() {}

  /**
   * Toggle sidebar
   * @param  {patchState}: StateContext<ConfigsStateModel>
   * @param  {ToggleSidebar} action
   */
  @Action(ToggleSidebar)
  toggle_sidebar({ patchState }: StateContext<ConfigsStateModel>, action: ToggleSidebar) {
    patchState({
      isOpened: action.payload.isOpened,
      sidebarContentType: action.payload.sidebarContentType,
    });
  }
}
