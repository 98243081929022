// Common
import {
  CDK_DRAG_CONFIG,
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDropList,
  CdkDropListGroup,
  CdkDrag,
} from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  NgbModal,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
} from '@ng-bootstrap/ng-bootstrap';
import ResizeObserver from 'resize-observer-polyfill';
import { Store } from '@ngxs/store';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';

// Components
import { PinCreateComponent } from '../../../../../app/modals/pin-create/pin-create.component';
import { ConfigService, ITemplateConfig } from '../../../services/config.service';
import { ScrollingChatService } from '../../../services/scrolling-chat.service';
import { ChatsState } from '../../../store/states/chats.state';
import { ChatsDeletePin, ChatsOrderPin } from '../../../store/actions/chats.action';
import { ChatPinnedService } from './chat-pinned.service';

// Type
import { PinType } from './enums/pin-type.enum';
import { PinnedMessagesDbDto } from '../../../../api/models/pinned-messages-db-dto';
import { ConfirmAlert } from '../../../alerts/alerts';
import { ChatPinnedItemComponent } from './components/chat-pinned-item/chat-pinned-item.component';
import { ChatPinnedMessageComponent } from './components/chat-pinned-message/chat-pinned-message.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgIf, NgFor } from '@angular/common';
import { MixpanelService } from '../../../../plugins/mixpanel/mixpanel.service';

const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000,
};
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-chat-pinned-messages',
  templateUrl: './chat-pinned-messages.component.html',
  styleUrls: ['./chat-pinned-messages.component.scss'],
  providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgIf,
    SvgIconComponent,
    CdkDropList,
    CdkDropListGroup,
    ChatPinnedMessageComponent,
    NgFor,
    ChatPinnedItemComponent,
    CdkDrag,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
  ],
})
export class ChatPinnedMessagesComponent implements OnInit, AfterViewInit {
  @Input() chatId: string;
  @Input() object: string;
  @Input() platform: string;
  @Input() objectId: string;
  public isScrolling = false;
  public pinnedItems: PinnedMessagesDbDto[] = [];
  public otherPinned: PinnedMessagesDbDto[] = [];
  public messagesPin: PinnedMessagesDbDto[] = [];
  public allPinnedItems: PinnedMessagesDbDto[] = [];
  public config: ITemplateConfig;
  public countShowingElement = 0;
  private widthPinned: number;
  private actionsDropdownOpen = false;

  @ViewChild('pinned') pinned: ElementRef;
  constructor(
    public pinnedService: ChatPinnedService,
    private modalService: NgbModal,
    private configService: ConfigService,
    private store: Store,
    private ref: ChangeDetectorRef,
    private scrollingService: ScrollingChatService,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit(): void {
    this.initializeSubscribe();
  }

  ngAfterViewInit() {
    const resizeObserver = new ResizeObserver(() => {
      this.changePinElement();
    });
    resizeObserver.observe(this.pinned.nativeElement);
  }

  // Getter
  public get isLimitPins(): boolean {
    return this.allPinnedItems.filter((msg) => msg.linkDocument !== PinType.Message).length <= 14;
  }

  private get takeWidth() {
    return this.messagesPin?.length
      ? this.pinned?.nativeElement.clientWidth - 280
      : this.pinned?.nativeElement.clientWidth;
  }

  // Methods
  public addPinMessage(): void {
    MixpanelService.trackEvent('Chat Pin: Add Pin');
    const modalRef = this.modalService.open(PinCreateComponent, {
      size: 'md',
      centered: true,
    });
    modalRef.componentInstance.chatId = this.chatId;
    modalRef.componentInstance.object = this.object;
    modalRef.componentInstance.objectId = this.objectId;
  }

  public actionsDropdownToggle(e: boolean): void {
    this.actionsDropdownOpen = e;
  }

  public dragDropPin(event: CdkDragDrop<any[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }

    const orderedMessage = [...this.messagesPin, ...this.pinnedItems, ...this.otherPinned];
    const dataArray = orderedMessage.map((msg, index) => ({
      _id: msg._id,
      order: index,
    }));

    this.changePinElement(orderedMessage);

    this.store.dispatch(
      new ChatsOrderPin({
        id: this.chatId,
        body: {
          actionPinnedMessageId: event.item.data._id,
          pinnedMessages: dataArray,
        },
      }),
    );
  }

  public deletePin(message) {
    MixpanelService.trackEvent('Chat Pin: Delete Pin');
    ConfirmAlert(this.translocoService.translate('alert.pin-title'), {
      subject: this.translocoService.translate('alert.unpin-subject'),
      text: this.translocoService.translate('alert.unpin-text'),
      confirmButtonText: this.translocoService.translate('alert.unpin-btn-text'),
      platform: this.platform,
    }).then(() => {
      this.store.dispatch(
        new ChatsDeletePin({
          id: this.chatId,
          pinnedMessageId: message._id,
          object: this.object || 'spaces',
          objectId: this.objectId,
        }),
      );
    });
  }

  public handlePin(item) {
    this.pinnedService.handleAction(item);
  }

  private changePinElement(orders = []): void {
    this.pinnedItems = [...this.allPinnedItems];
    this.widthPinned = this.takeWidth;

    const filteredMessage = (orders.length ? orders : this.allPinnedItems)
      .filter((msg) => msg.linkDocument !== PinType.Message)
      .map((pin) => {
        if (pin.linkDocument === PinType.Ticket) {
          return {
            ...pin,
            name: `Ticket ${pin.linkTicket?.boardAbbreviation}-${pin.linkTicket?.counter}`,
          };
        } else if (pin.linkDocument === PinType.ChatFile) {
          return { ...pin, name: pin.linkChatFile?.originalFileName };
        } else if (pin.linkDocument === PinType.ThreadsFile) {
          return { ...pin, name: pin.linkThreadFile?.originalFileName };
        } else if (pin.linkDocument === PinType.TicketFile) {
          return { ...pin, name: pin.linkTicketFile?.originalFileName };
        } else if (pin.linkDocument === PinType.Files) {
          return { ...pin, name: pin.linkFile?.originalFileName };
        } else if (pin.linkDocument === PinType.Documents) {
          return { ...pin, name: pin.linkDocumentFile?.name };
        } else if (pin.linkDocument === PinType.Links) {
          return {
            ...pin,
            name: pin.internalLinkFile?.name,
            url: pin.internalLinkFile?.link,
            openInFrame: !pin.internalLinkFile?.inNewWindow,
          };
        } else {
          return pin;
        }
      });

    filteredMessage.forEach((result) => {
      if (result.name?.length >= 18) {
        this.widthPinned -= 210;
      } else {
        this.widthPinned -= 50 + result.name?.length * 8;
      }
      if (this.widthPinned >= 0) {
        this.countShowingElement += 1;
      }
    });
    this.pinnedItems = filteredMessage.slice(0, this.countShowingElement);
    this.otherPinned = filteredMessage.slice(this.countShowingElement, this.allPinnedItems.length);

    this.widthPinned = this.takeWidth;
    this.countShowingElement = 0;
  }

  private initializeSubscribe() {
    this.store
      .select(ChatsState.getPinMessages)
      .pipe(untilDestroyed(this))
      .subscribe((val) => {
        if (this.objectId) {
          this.pinnedService.initData(this.object, this.objectId, this.platform);
        }

        this.allPinnedItems = [...val];
        this.messagesPin = this.allPinnedItems.filter(
          (msg) => msg.linkDocument && msg.linkDocument === PinType.Message,
        );

        this.changePinElement();
        this.ref.detectChanges();
      });

    this.scrollingService.isScrolling.pipe(untilDestroyed(this)).subscribe((val) => {
      this.isScrolling = val;
      this.ref.detectChanges();
    });
  }
}
