interface IBasePaginationQuery {
  page?: number;
  perPage?: number;
  sort?: string;
  sortBy?: string;
}

export interface IChatFilesPaginationQuery extends IBasePaginationQuery {
  object: string;
  objectId?: string;
}

export enum DataRoomPageType {
  Chat = 'Chat',
}

export enum DataRoomSortBy {
  Name = 'name',
  Created_By = 'created_by',
  Updated_At = 'updated_at',
}

export enum ChatFileSortBy {
  name = 'fileData.originalFileName',
  created_by = 'fileData.ownerUserId',
  updated_at = 'fileData.updated_at',
}
