export enum ImagePath {
  PrivateIcon = 'assets/icons/data-room/private-folder.svg',
  PublicIcon = 'assets/icons/data-room/folder.svg',
  Image = 'assets/icons/data-room/image.svg',
  Video = 'assets/icons/data-room/video.svg',
  Music = 'assets/icons/data-room/video.svg',
  Archive = 'assets/icons/data-room/archive.svg',
  Link = 'assets/icons/data-room/link.svg',
  Presentation = 'assets/icons/data-room/present.svg',
  File = 'assets/icons/data-room/file.svg',
  PinFile = 'assets/icons/pin/pin-action.svg',
  UnPinFile = 'assets/icons/pin/unpin-message.svg',
}

export enum PreviewImageGrid {
  Image = 'assets/icons/data-room/image-grid.svg',
  Video = 'assets/icons/data-room/video-grid.svg',
  Archive = 'assets/icons/data-room/archive-grid.svg',
  File = 'assets/icons/data-room/file-grid.svg',
  Link = 'assets/icons/data-room/link-grid.svg',
  Presentation = 'assets/icons/data-room/present-grid.svg',
}
