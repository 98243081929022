import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-content',
  templateUrl: './empty-content.component.html',
  styleUrls: ['./empty-content.component.scss'],
  standalone: true,
})
export class EmptyContentComponent {
  @Input() src: string;
  @Input() title: string;
  @Input() description: string;
}
