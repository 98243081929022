export const undoIcon = `<svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M10.9088 5.01468C13.7694 5.01468 16.066 7.29855 16.066 10.0549C16.066 10.6062 16.5092 11 17.033 11C17.5971 11 18 10.6062 18 10.0549C18 6.23537 14.8573 3.12457 10.9088 3.12457H5.14717V0.919456C5.14717 0.565062 4.94572 0.210668 4.5831 0.0531591C4.22048 -0.0649723 3.77728 0.013782 3.49524 0.289422L0.271965 3.43959C-0.0906544 3.79399 -0.0906544 4.38464 0.271965 4.73904L3.49524 7.88921C3.77728 8.16485 4.22048 8.2436 4.5831 8.12547C4.94572 7.96796 5.14717 7.61357 5.14717 7.2198V5.01468H10.9088Z"
    fill="#C6C8D7"
  />
</svg>`;

export const redoIcon = `<svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M7.09121 5.01468C4.23055 5.01468 1.93397 7.29855 1.93397 10.0549C1.93397 10.6062 1.49077 11 0.966984 11C0.40291 11 0 10.6062 0 10.0549C0 6.23537 3.1427 3.12457 7.09121 3.12457H12.8528V0.919456C12.8528 0.565062 13.0543 0.210668 13.4169 0.0531591C13.7795 -0.0649723 14.2227 0.013782 14.5048 0.289422L17.728 3.43959C18.0907 3.79399 18.0907 4.38464 17.728 4.73904L14.5048 7.88921C14.2227 8.16485 13.7795 8.2436 13.4169 8.12547C13.0543 7.96796 12.8528 7.61357 12.8528 7.2198V5.01468H7.09121Z"
    fill="#C6C8D7"
  />
</svg>`;

export const addIconPath = 'assets/icons/common/plus.svg';
export const searchIconPath = 'assets/icons/common/search.svg';
export const clearIconPath = 'assets/icons/common/clear.svg';

export const enContent =
  '<p>👋 Welcome to your first document!</p><p>Here are the basics:</p><ul><li><p>Click the + button in your sidebar to add new documents</p></li><li><p>Select three dots menu on the document line to create new pages.</p></li><li><p>Click Edit button at the top right corner to start editing the document</p></li><li><p>Highlight any text, and select the style of heading you like</p></li><li><p>Drag any documents to change their order. You can do it to pages as well</p></li><li><p>Pages in the Workspace section are shared with your team</p></li><li><p>Leave comments to your documents using “Message-cloud” icon at the top right corner</p></li><li><p>Copy link to your documents to share with teammates</p></li><li><p>Transform your text into quote or code line using editor</p></li><li><p>Upload images to your document</p></li></ul>';
export const deContent =
  '<p>👋 Willkommen zu deinem ersten Dokument!</p><p>Hier sind die Grundlagen:</p><ul><li><p>Klicke auf "+" in der Seitenleiste oben links, um neue Dokumente hinzuzufügen.</p></li><li><p>Wähle "•••" eines Dokuments, um neue Unterseiten zu erstellen.</p></li><li><p>Klicke auf "Bearbeiten" in der oberen rechten Ecke, um das Dokument zu gestalten.</p></li><li><p>Markiere einen beliebigen Text und wähle den gewünschten Stil der Überschrift aus.</p></li><li><p>Bewege Dokumente oder Seiten, um ihre Reihenfolge zu ändern.</p></li><li><p>Dokumente in diesem Channel werden ausschließlich mit dem jeweiligen Projektteam geteilt.</p></li><li><p>Hinterlasse Kommentare zu deinen Dokumenten, indem du das "Thread"-Symbol in der oberen rechten Ecke verwendest.</p></li><li><p>Kopiere den Link zu deinen Dokumenten, um ihn mit Teamkollegen zu teilen.</p></li><li><p>Transform your text into quote or code line using editor</p></li><li><p>Lade Bilder in dein Dokument.</p></li></ul>';

export const getStarted = {
  title: 'Get started',
  _id: 'get-started',
  content: enContent,
};

export const emptyState = {
  src: 'assets/img/wiki/empty-wiki.png',
  title: 'Start creating new documents and pages',
  description: 'Create your document using + button',
};
