import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

// Components
import { EditorService } from "../../../../../../../src/app/shared/components/editor/editor.service";
import { WikiService } from "../../../../../../../src/app/pages/full-pages/wiki/wiki.service";
import { NgbModal, NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { ConfigService } from "../../../../../../../src/app/shared/services/config.service";
import { Store } from "@ngxs/store";
import { LocalStorageService } from "ngx-localstorage";
import { ConfirmAlert } from "../../../../../../../src/app/shared/alerts/alerts";
import { AuthState } from "../../../../../../../src/app/shared/store/states/auth.state";
import { TranslocoDirective, TranslocoService } from "@ngneat/transloco";
import { AvatarComponent } from "../../../../../../../src/app/standalone/components/avatar/avatar.component";
import { CommonModule } from "@angular/common";
import { SvgIconComponent } from "angular-svg-icon";
import { WikiPageDbDto } from "../../../../../../../src/app/api/models/wiki-page-db-dto";
import { untilDestroyed } from "@ngneat/until-destroy";
import { LocalStorageKeys } from "../../../../../../../src/app/types/local-storage-keys.enum";
import moment from "moment";
import { UsersState } from "../../../../../../../src/app/shared/store/states/users.state";

@Component({
  selector: "app-wiki-mobile-header",
  templateUrl: "./wiki-mobile-header.component.html",
  styleUrls: [
    "../../../../../../../src/app/pages/full-pages/wiki/wiki-header/wiki-header.component.scss",
    "./wiki-mobile-header.component.scss",
  ],
  standalone:true,
  imports: [TranslocoDirective, AvatarComponent, NgbTooltip, CommonModule, SvgIconComponent],
})
export class WikiMobileHeaderComponent implements OnInit{
  @Output() backAction = new EventEmitter();
  @Output() showToastLink = new EventEmitter();
  @Output() handleSave = new EventEmitter();

  @Input() isEdit = false;
  @Input() hasUnsavedChange = false;
  @Input() isLoadingButton = false;

  public date: string = '';
  public userId: string = '';
  public userName: string = '';
  public title: string = '';
  public wiki: WikiPageDbDto;
  public isDark = false;

  public readonly linkSvg = 'assets/icons/common/link.svg';
  public readonly threadSvg = 'assets/icons/data-room/comment-dots.svg';

  constructor(
    public wikiService: WikiService,
    public modalService: NgbModal,
    public configService: ConfigService,
    protected cdr: ChangeDetectorRef,
    protected store: Store,
    protected localStorage: LocalStorageService,
    private editorService: EditorService,
    protected translocoService: TranslocoService,
  ) {
  }
  ngOnInit(): void {
    this.initializeData()
  }

  public readonly shavronIcon = "assets/icons/common/arrow-down.svg";
  public readonly redoIcon = "assets/icons/wiki/redo.svg";
  public readonly undoIcon = "assets/icons/wiki/undo.svg";

  public get isUndoActive() {
    return this.editorService.isUndoActive;
  }

  public get isRedoActive() {
    return this.editorService.isRedoActive;
  }

  public handleAction() {
    this.backAction.emit();
  }

  public undoHandle($event: MouseEvent) {
    this.editorService.undo$.emit($event);
  }

  public redoHandle($event: MouseEvent) {
    this.editorService.redo$.emit($event);
  }

  public copyLink() {
    this.wikiService.copyTicketLink();
    this.showToastLink.emit();
  }

  public saveAction(): void {
    this.handleSave.emit();
  }

  private initializeData(): void {
    this.configService.templateConf$.subscribe(
      ({ layout: { variant } }) => (this.isDark = variant === 'Dark'),
    );

    this.wikiService.activeWiki$.pipe(untilDestroyed(this)).subscribe((wiki) => {
      if (wiki) {
        const lang = this.localStorage.get(LocalStorageKeys.language);

        this.wiki = wiki;
        this.title = wiki.title || this.translocoService.translate('wiki.new-doc');
        this.userId = wiki.createdBy || this.localStorage.get('userId');
        this.date =
          lang === 'de'
            ? moment(wiki.created_at, '', 'de').format(
                `DD.MM.YYYY [${this.translocoService.translate('wiki.wiki-at')}] HH:mm [Uhr]`,
              )
            : moment(wiki.created_at).format('M/D/YY [at] h:mm a');
        this.userName = this.store.selectSnapshot(UsersState.getUser)(this.userId)?.userName || '';
        this.cdr.detectChanges();
      }
    });
  }

  public toggleThread(): void {
    if (this.wiki && this.wiki._id === 'get-started') {
      if (this.wikiService.isLoadingThreadCreate) {
        return;
      }

      this.wikiService.isLoadingThreadCreate = true;
      this.wikiService.save(this.wiki.title, this.wiki.content, true, true);
    } else {
      this.wikiService.handleOpenThread.next('');
    }
  }


  public toggleEditMode() {
    if (this.wikiService.hasUnsavedChanges) {
      ConfirmAlert("", {
        platform: this.store.selectSnapshot(AuthState.getPlatform),
        confirmButtonClass: "btn-solid",
        subject: "Save changes?",
        text: "You have made changes. Do you want to save or discard your changes?",
        confirmButtonText: "Save",
        cancelButtonText: "Discard",
      }).then(
        () => {
          this.wikiService.save(
            this.editorService.getEditorTitle(),
            this.editorService.getEditorContent(),
          );
          this.wikiService.hasUnsavedChanges = false;
        },
        () => {
          this.wikiService.clearContent.next("");
          this.wikiService.setEditMode();
          this.wikiService.hasUnsavedChanges = false;
        },
      );
    } else {
      this.wikiService.setEditMode();
    }
  }
}
