import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnInit,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { QuillModulesForChat } from '../../../../data/quill-configuration';
import { QuillInitializeService } from '../../../../services/quill/quill-init.service';
import { ConfigService } from '../../../../services/config.service';
import { TranslocoDirective } from '@ngneat/transloco';
import { MentionModule } from 'angular-mentions';
import { FormsModule } from '@angular/forms';
import { FocusDirective } from '../../../../directives/focus.directive';
import { NgClass } from '@angular/common';
import { QuillEditorComponent } from 'ngx-quill';

@Component({
  selector: 'app-message-edit',
  templateUrl: './message-edit.component.html',
  styleUrls: ['./message-edit.component.scss'],
  standalone: true,
  imports: [
    QuillEditorComponent,
    NgClass,
    FocusDirective,
    FormsModule,
    MentionModule,
    TranslocoDirective,
  ],
})
export class MessageEditComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() platform = 'web';
  @Input() isThread: any;
  @Input() editingMessage: any;
  @Input() mentionChatMembers: any[];

  @Output() onMessageEditSubmit: EventEmitter<any> = new EventEmitter(null);
  @Output() onMessageEditCancel: EventEmitter<any> = new EventEmitter(null);

  destroy$: Subject<void> = new Subject<void>();
  config: any;
  editorModules: any;
  editor: any;
  message: any;
  isMentionSelectOpened = false;
  caretPosition = 0;

  constructor(
    private configService: ConfigService,
    private quillInitializeService: QuillInitializeService,
    public cdr: ChangeDetectorRef,
  ) {
    this.config = this.configService.templateConf;
    this.editorModules = { ...QuillModulesForChat, magicUrl: true };
    const bindings = {
      enter: {
        key: 13,
        handler: (range, context) => {
          if (context.sufix === '' || context.empty) {
            return !this.isMentionSelectOpened;
          } else if (!this.isMentionSelectOpened && this.platform !== 'web') {
            return true;
          }
        },
      },
    };
    this.editorModules.keyboard = { bindings };
  }

  ngOnInit(): void {
    this.message = this.editingMessage;
  }

  ngAfterViewInit() {
    this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
        this.cdr.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  _onMessageEditSubmit() {
    this.onMessageEditSubmit.emit({
      isThread: this.isThread,
      message: this.message,
    });
  }

  insertTextToEditor(text: string) {
    if (this.caretPosition === 0) {
      this.message.text = text + (this.message.text || '');
    } else if (this.caretPosition === this.message.text.length) {
      this.message.text = this.message.text + text;
    } else {
      this.editor.insertText(this.caretPosition, text);
      if (text !== ' ') {
        this.message.text = this.editor.root.innerHTML;
      }
    }
  }

  mentionItemSelected(item: any) {
    setTimeout(() => {
      this.insertTextToEditor(' ');
    }, 0);
  }

  mentionSelectOpened() {
    this.isMentionSelectOpened = true;
  }

  mentionSelectClosed() {
    this.isMentionSelectOpened = false;
  }

  editorCreated(editor: any) {
    this.editor = editor;
    editor.setSelection(editor.getLength());

    this.editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
      delta.forEach((e) => {
        if (e && e?.attributes) {
          e.attributes.color = '';
          e.attributes.background = '';
        }
      });
      return delta;
    });

    QuillInitializeService.handleEditorCreated(editor);
  }

  contentChanged(event: any) {
    const selection = event.editor.getSelection();
    if (selection) {
      this.caretPosition = selection.index;
    }
  }
}
