import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  constructor(private platform: Platform) {}

  /**
   * Checks if the current platform is desktop.
   * @returns {boolean} True if the platform is desktop, false otherwise.
   */
  public isDesktop(): boolean {
    return this.platform.ANDROID || this.platform.IOS ? false : true;
  }

  /**
   * Checks if the current platform is mobile.
   * @returns {boolean} True if the platform is mobile, false otherwise.
   */
  public isMobile(): boolean {
    return this.platform.ANDROID || this.platform.IOS;
  }

  /**
   * Checks if the current platform is mobile web.
   * @returns {boolean} True if the platform is mobile web, false otherwise.
   */
  public isMobileWeb(): boolean {
    return this.isMobile() && !this.isCapacitor();
  }

  /**
   * Checks if the current platform is Capacitor.
   * @returns {boolean} True if the platform is Capacitor, false otherwise.
   */
  public isCapacitor(): boolean {
    return Capacitor.isNativePlatform();
  }

  /**
   * Checks if the application is running in the Tauri platform.
   * @returns {boolean} True if running in Tauri, false otherwise.
   */
  public get isTauri(): boolean {
    const isTauri = (window as any).__TAURI__ !== undefined;
    return isTauri;
  }

  /**
   * Gets information about the user's system.
   * @returns {string} A string describing the user's system and application type.
   */
  public getSystemInfo(): string {
    if (this.isTauri) {
      return `Desktop-Tauri application on ${this.getOSName()}`;
    } else if (this.isCapacitor()) {
      return `Mobile application on ${this.getOSName()}`;
    } else if (this.isMobileWeb()) {
      return `Mobile web on ${this.getOSName()}`;
    } else if (this.isDesktop()) {
      return `Desktop web on ${this.getOSName()}`;
    } else {
      return 'Unknown system';
    }
  }

  /**
   * Gets the name of the operating system.
   * @returns {string} The name of the operating system.
   */
  private getOSName(): string {
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.includes('win')) return 'Windows';
    if (userAgent.includes('mac')) return 'macOS';
    if (userAgent.includes('iphone') || userAgent.includes('ipad')) return 'iOS';
    if (userAgent.includes('android')) return 'Android';
    return 'Unknown OS';
  }
}
