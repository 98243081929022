import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnDestroy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';

import { BoardTypes } from '../../enums';
import { SvgComponent } from '../../svgs/svg/svg.component';
import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { TranslocoDirective } from '@ngneat/transloco';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, NgSwitch, NgSwitchCase, NgSwitchDefault, SvgComponent],
})
export class EmptyStateComponent implements OnInit, OnDestroy {
  @Input() isShowDropStyle: boolean;
  @Input() isDarkMode: boolean;
  @Input() isMobile = false;
  @Input() type: BoardTypes;
  @Output() clickOnAddTicket: EventEmitter<void> = new EventEmitter();
  destroy$: Subject<void> = new Subject<void>();

  constructor() {}

  public get BoardType(): typeof BoardTypes {
    return BoardTypes;
  }

  ngOnInit() {
    return;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public addTicket(): void {
    this.clickOnAddTicket.emit();
  }
}
