<button
  #dropDown="ngbDropdown"
  t-id="chat-navigation-tree-dot-menu"
  class="pinned__messages"
  [class.pinned-messages-dark]="config?.layout.variant === 'Dark'"
  ngbDropdown
  placement="bottom-left"
  container="body"
  [autoClose]="true"
  (openChange)="actionsDropdownToggle($event)"
>
  <div class="pinned__messages-wrapper" ngbDropdownToggle>
    <svg-icon
      class="pinned__messages-icon"
      src="assets/icons/pin/pin.svg"
      [applyClass]="true"
    ></svg-icon>
    <span>{{ messages.length }} {{ textMessages }}</span>
  </div>

  <div ngbDropdownMenu>
    <div class="pinned__preview">
      <div
        *ngFor="let item of messages"
        (click)="scrollToMessage(item.linkMessage)"
        class="pinned__preview-message"
      >
        <div class="pinned__preview-wrapper">
          <div class="pinned__preview-info">
            <app-avatar
              [userId]="item.linkMessage?.userId"
              [width]="20"
              [height]="20"
              [showStatus]="false"
            ></app-avatar>
            <b>{{ item.linkMessage?.userName }}</b>
            <span>{{ formatDate(item.linkMessage?.updated_at) }}</span>
          </div>
          <div *ngIf="pinnedService.canDeletePin(item)">
            <svg-icon
              (click)="$event.stopPropagation(); dropDown.close(); deletePin(item)"
              class="pinned__preview-cross"
              src="assets/icons/common/cross.svg"
              [applyClass]="true"
            ></svg-icon>
          </div>
        </div>
        <div class="pinned__preview-content">
          <app-chat-message-content
            [platform]="platform"
            [message]="
              item.linkMessage?.type === 'thread' ? item.linkMessage?.message : item.linkMessage
            "
          ></app-chat-message-content>
          <div *ngIf="item.linkMessage?.pollId && item.linkMessage.poll">
            <app-message-poll [message]="item.linkMessage"></app-message-poll>
          </div>
        </div>
      </div>
    </div>
  </div>
</button>
