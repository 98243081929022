<div
  class="card"
  [class.has-parent-ticket]="ticket?.parentId"
  [style.background-color]="colorTicket(ticket)"
  [ngClass]="{
    light: isFromDashboard && boardService.config?.layout.variant === 'Light',
    dark: isFromDashboard && boardService.config?.layout.variant === 'Dark'
  }"
  [id]="ticketId"
  cdkDrag
  [cdkDragData]="ticket"
  [cdkDragStartDelay]="50"
  [cdkDragDisabled]="isFromDashboard"
  *transloco="let t; read: 'board'"
>
  <div
    *ngIf="ticket?.isBlocked || ticketFlagColor"
    class="card-flag"
    [style.border-left-color]="ticketFlagColor"
  ></div>

  <div
    class="card-body"
    [class.card-dnd]="!boardService.isMobile"
    [class.parent-ticket-bg-color]="!customizeService.colorTicket(ticket)"
    [class.dashboard]="isFromDashboard"
    [style.background-color]="colorTicket(ticket)"
    (click)="editTicket(ticket)"
  >
    <div *ngIf="ticket.previewUrl" class="ticket-preview" [class.card-dnd]="!boardService.isMobile">
      <img
        src="{{ ticket.previewUrl }}"
        alt="Ticket preview"
        [class.card-dnd]="!boardService.isMobile"
      />
    </div>

    <div *ngIf="isFromDashboard" class="breadcrumbs">
      <app-space-avatar
        *ngIf="ticket.breadcrumbs.spaceName"
        [containerHeight]="20"
        [containerWidth]="20"
        [space]="ticket.breadcrumbs"
        [isBreadcrumb]="true"
      ></app-space-avatar>
      <a
        *ngIf="ticket.breadcrumbs.spaceId"
        (click)="$event.stopPropagation()"
        [routerLink]="getLinkSpaceSprint(ticket) | routerTenant"
      >
        {{ ticket.breadcrumbs.spaceName }}
      </a>

      <app-svg
        *ngIf="ticket.breadcrumbs.projectId"
        name="angle-right"
        [width]="7"
        [height]="7"
        [fill2]="(isDark$ | async) ? '#A8AABD' : '#1E1C25'"
      ></app-svg>

      <app-project-avatar
        *ngIf="ticket.breadcrumbs.projectId"
        [containerHeight]="20"
        [containerWidth]="20"
        [project]="ticket.breadcrumbs"
        [isBreadcrumb]="true"
      ></app-project-avatar>

      <a
        *ngIf="ticket.breadcrumbs.projectId"
        (click)="$event.stopPropagation()"
        [routerLink]="getLinkProjectSprint(ticket) | routerTenant"
      >
        {{ ticket.breadcrumbs.projectName }}
      </a>
    </div>

    <div class="d-flex justify-content-between pb-2" [class.card-dnd]="!boardService.isMobile">
      <svg-icon
        *ngIf="ticket.parentId"
        src="assets/icons/ticket/sub-task.svg"
        class="actions mr-2"
        [class.dark]="isDark$ | async"
        [svgStyle]="{ 'width.px': '16', 'height.px': '14' }"
      ></svg-icon>
      <span
        class="card-title cursor-pointer"
        [class.card-dnd]="!boardService.isMobile"
        [title]="t('view-ticket')"
        t-id="kanban-board-ticket-title-card"
      >
        {{ ticket.title | limitTo: 80 }}
      </span>
    </div>

    <div
      class="badges"
      [class.card-dnd]="!boardService.isMobile"
      *ngIf="isFromDashboard || isLabelsShow"
    >
      <span
        *ngFor="let label of ticket.labels"
        class="label-badge"
        [class.card-dnd]="!boardService.isMobile"
      >
        {{ isFromDashboard ? label : boardService.getLabel(label) }}
      </span>
    </div>

    <div
      class="d-flex align-items-center my-1 flex-wrap badge-container"
      [class.card-dnd]="!boardService.isMobile"
    >
      <div
        *ngIf="ticket.estimate?.value && isEstimationShow"
        class="badge estimation-badge"
        [class.card-dnd]="!boardService.isMobile"
      >
        {{ ticket.estimate.value }} {{ ticket.estimate.unit }}
      </div>
      <div
        *ngIf="ticket.releaseVersion && isReleaseVersion"
        class="badge version-badge"
        [class.card-dnd]="!boardService.isMobile"
      >
        {{ ticket.releaseVersion }}
      </div>
      <div
        *ngIf="ticket.dueDate && ticket.dueDate !== 'null' && isDateShow"
        class="badge date-badge"
        [class.card-dnd]="!boardService.isMobile"
      >
        {{ formatDate(ticket.dueDate) }}
      </div>
      <div
        *ngFor="let custom of customFieldShow(ticket)"
        class="badge custom-badge"
        [class.card-dnd]="!boardService.isMobile"
      >
        {{ custom.value }} {{ custom.unit ? custom.unit : '' }}
      </div>
    </div>

    <div class="card-body__checklist-progress" *ngIf="ticket.checkListItems?.length">
      <div class="d-flex align-items-center">
        <svg-icon
          src="assets/icons/ticket/checkbox.svg"
          class="checkbox"
          [class.dark]="isDark$ | async"
          [svgStyle]="{ 'width.px': '15', 'height.px': '15' }"
        ></svg-icon>
        <div class="card-body__checklist-progress-number" t-id="checklist-progress-bar-number">
          {{ progressChecklist }}%
        </div>
      </div>
      <app-progress-bar
        [progress]="progressChecklist"
        class="card-body__checklist-progress-bar"
      ></app-progress-bar>
    </div>

    <span *ngIf="isFromDashboard" class="badge {{ ticket.column.boardColorClass }}">
      {{ ticket.column.displayName || ticket.column.title }}
    </span>

    <div class="d-flex align-items-center" [class.card-dnd]="!boardService.isMobile">
      <div class="d-flex align-items-center font-small-2 card-ticket">
        <div
          *ngIf="ticket.parentTicket && isParentShow"
          title="{{ boardService.getParentTicket(ticket._id).title }}"
          class="card-ticket_parent"
          [class.card-dnd]="!boardService.isMobile"
        >
          {{ boardService.getParentTicket(ticket._id).number }} / &nbsp;
        </div>
        <div [class.card-dnd]="!boardService.isMobile" t-id="kanban-board-ticket-id">
          {{ ticket.boardAbbreviation }}-{{ ticket.counter }}
        </div>
        <div class="card-body__priority">
          <svg-icon
            *ngIf="priorityKeys[this.ticket.priority]?.key"
            src="assets/icons/priority/{{ priorityKeys[this.ticket.priority]?.key }}.svg"
          ></svg-icon>
        </div>

        <div
          *ngIf="(recordService.recordObjectId | async) === ticket._id"
          class="ticket-record"
        ></div>
      </div>

      <span class="flex-grow-1"></span>

      <div
        class="d-flex align-items-center"
        *ngIf="isAssignedShow"
        [class.card-dnd]="!boardService.isMobile"
      >
        <ng-container *ngFor="let member of ticket.ticketsMembers; let i = index">
          <ng-container *ngIf="i < membersMaxView">
            <div class="member">
              <app-avatar
                [userId]="member.userId"
                [width]="24"
                [height]="24"
                [notShowProfile]="true"
              ></app-avatar>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="ticket?.ticketsMembers?.length > membersMaxView">
          <div class="font-small-3 ml-1">
            +{{ ticket?.ticketsMembers?.length - membersMaxView }}
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div
    class="card-actions svg-wrapper has-background"
    appTippy
    [popoverContent]="dropdown"
    triggers="hover"
    [ngbTooltip]="t('actions-ticket-tooltip')"
    placement="top"
  >
    <svg-icon
      src="assets/icons/common/ellipsis.svg"
      class="svg-icon"
      [applyClass]="true"
      [svgStyle]="{ 'width.px': '17', 'height.px': '18' }"
    ></svg-icon>
  </div>
  <div
    #dropdown
    class="card-actions__menu"
    [class.dark]="isDark$ | async"
    [attr.aria-labelledby]="ticket._id"
  >
    <div
      *ngIf="columnName === 'DONE'"
      (click)="moveToArchive(ticket)"
      class="card-actions__menu-item ticket-button d-flex align-items-center"
    >
      <i
        class="ft-arrow-right mr-1"
        style="width: 20px"
        t-id="kanban-board-actions-ticket-move-to"
      ></i>
      <span>{{ t('btn-move-to-archive') }}</span>
    </div>
    <div
      (click)="copyTicketAbbrAndTitle(ticket)"
      class="card-actions__menu-item ticket-button d-flex align-items-center"
      t-id="kanban-board-copy-ticket"
    >
      <svg-icon
        src="assets/icons/ticket/copy.svg"
        class="actions mr-1"
        [class.dark]="isDark$ | async"
        [svgStyle]="{ 'width.px': '20', 'height.px': '16' }"
      ></svg-icon>
      <span>{{ t('btn-copy-ticket') }}</span>
    </div>
    <div
      (click)="ticketDelete(ticket._id)"
      class="card-actions__menu-item ticket-button d-flex align-items-center"
      t-id="kanban-board-delete-ticket"
    >
      <svg-icon
        src="assets/icons/common/trash.svg"
        class="actions mr-1"
        [class.dark]="isDark$ | async"
        [svgStyle]="{ 'width.px': '20', 'height.px': '16' }"
      ></svg-icon>
      <span>{{ t('btn-delete-ticket') }}</span>
    </div>
    <div class="divider"></div>

    <div *ngIf="!isFromDashboard" class="pin-ticket">
      <div
        *ngIf="getCheckPinned(ticket); else isPinMessage"
        (click)="unPinMessage(ticket)"
        class="card-actions__menu-item ticket-button d-flex align-items-center"
      >
        <svg-icon
          class="pin-icon-ticket mr-1"
          src="assets/icons/pin/unpin-message.svg"
          [applyClass]="true"
        ></svg-icon>
        <span>{{ t('btn-unpin-in-chat') }}</span>
      </div>
      <ng-template #isPinMessage>
        <div
          class="card-actions__menu-item ticket-button d-flex align-items-center"
          (click)="pinMessage(ticket)"
        >
          <svg-icon
            class="pin-icon-ticket mr-1"
            src="assets/icons/pin/pin-action.svg"
            [applyClass]="true"
          ></svg-icon>
          <span>{{ t('btn-pin-in-chat') }}</span>
        </div>
      </ng-template>
    </div>
    <div
      *ngIf="!isFirst && !isFromDashboard"
      (click)="recountTicketOrders(ticket, { moveTo: 'top' })"
      class="card-actions__menu-item ticket-button d-flex align-items-center"
    >
      <svg-icon
        src="assets/icons/ticket/arrow-up.svg"
        class="actions mr-1"
        [class.dark]="isDark$ | async"
        [svgStyle]="{ 'width.px': '20', 'height.px': '13' }"
      ></svg-icon>
      <span>{{ t('btn-move-top') }}</span>
    </div>

    <div
      *ngIf="!isLast && !isFromDashboard"
      (click)="recountTicketOrders(ticket, { moveTo: 'bottom' })"
      class="card-actions__menu-item ticket-button d-flex align-items-center"
    >
      <svg-icon
        src="assets/icons/ticket/arrow-down.svg"
        class="actions mr-1"
        [class.dark]="isDark$ | async"
        [svgStyle]="{ 'width.px': '20', 'height.px': '13' }"
      ></svg-icon>
      <span>{{ t('btn-move-bottom') }}</span>
    </div>
  </div>
</div>
