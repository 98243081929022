export const typeDescription = [
  {
    title: 'Date Time picker',
    type: 'dateTimePicker',
    description: 'A custom filed that stores dates with time component',
  },
  {
    title: 'Date picker',
    type: 'datePicker',
    description: 'A custom filed that stores dates and uses a date picker to view them',
  },
  {
    title: 'Text input',
    type: 'text',
    description: 'A single line of text. For rich text use Text area',
  },
  {
    title: 'Text area',
    type: 'textArea',
    description:
      'A multiple text field that can store a long lenghts of text. Support rich text lines, support bold, italicized, underlined and emojii text.',
  },
  {
    title: 'Number field',
    type: 'number',
    description: 'This field validates numeric input',
  },
  {
    title: 'Unit',
    type: 'unit',
    description: 'Unit generates Input Field, where you can type in all formats needed',
  },
  {
    title: 'SelectLabelsTags List (single choice)',
    type: 'select',
    description: 'Choose single value using radioboxes',
  },
  {
    title: 'SelectLabelsTags List (multi choice)',
    type: 'multiSelect',
    description: 'Choose multiple values using checkboxes',
  },
];
