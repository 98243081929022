<app-text-message
  [message]="message"
  [mentionChatMembers]="mentionChatMembers"
  [context]="context"
  [platform]="platform"
></app-text-message>
<app-message-files
  [isMobile]="isMobile"
  [message]="message"
  [platform]="platform"
  [object]="object"
  [objectId]="objectId"
></app-message-files>
