import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LinkedEntityActionType } from '../components/chat/chat-messages/chat-messages.component';
import { PreviewMediaComponent } from '../../modals/data-room-modals/preview-media/preview-media.component';
import { FilesService } from '../../api/services/files.service';
import { MinimizeService } from './minimize.service';
import { DataRoomUtiliesService } from '../components/documents/services/data-room-utilies.service';

interface IViewLinkedEntityOptions {
  platform: string;
  object: string;
  objectId: string;
}

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(
    private fileService: FilesService,
    private modalService: NgbModal,
    private minimizeService: MinimizeService,
    private dataRoomUtilities: DataRoomUtiliesService,
  ) {}

  viewLinkedEntity(message: any, { platform, object, objectId }: IViewLinkedEntityOptions): void {
    if (message?.linkObject === LinkedEntityActionType.Files) {
      const document =
        message.linkFile ||
        message.linkChatFile ||
        message.linkTicketFile ||
        message.linkThreadFile ||
        message.linkInlineFile;
      if (document && document._id) {
        this.fileService
          .filesGetUrl({ id: document._id })
          .pipe(untilDestroyed(this))
          .subscribe((res) => {
            const currentMedia = {
              ...document,
              url: res.url,
              chatMessage: message,
            };
            const ref = this.modalService.open(PreviewMediaComponent, {
              size: 'xl',
              windowClass: platform !== 'web' ? 'media-view-modal-mobile' : 'media-view-modal',
              centered: true,
            });

            ref.componentInstance.previewData = {
              platform: platform,
              object: object,
              objectId: objectId,
              isMobile: platform !== 'web',
              currentMedia: currentMedia,
            };

            ref.componentInstance.removeFile.pipe(untilDestroyed(this)).subscribe((val) => {
              if (val) {
                this.dataRoomUtilities.deleteClicked(val, platform, object, objectId);
              }
            });

            this.minimizeService.minimizeInit(ref, {
              width: '360px',
              height: '307px',
              right: '40px',
              borderRadius: '10px',
            });
          });
      } else {
        console.error('No document found');
      }
    }
  }
}
