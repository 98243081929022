import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { CalendarEventViewComponent } from './calendar-event-view.component';
import { FormsModule } from '@angular/forms';
import { SvgIconComponent } from 'angular-svg-icon';
import { MomentPipe } from './moment.pipe';

@NgModule({
  declarations: [CalendarEventViewComponent, MomentPipe],
  exports: [CalendarEventViewComponent],
  imports: [
    CommonModule,
    FormsModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    SvgIconComponent,
  ],
})
export class CalendarEventViewModule {}
