import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// Types
import { BoardLineStyleVariablesEnum } from './enums/board-line-style-variables.enum';

// Utils
import StylesUtils from '../../../shared/utils/styles-utils';

@Injectable({
  providedIn: 'root',
})
export class BoardLaneService {
  private readonly minColumnCount: number = 3;
  private dragAndDrop = new Subject();
  dragAndDropEvents$ = this.dragAndDrop.asObservable();

  /* Methods */
  defineColumnStyles(columns: any[]): void {
    const staticColumnAddColumn = 1;
    if (!columns) {
      StylesUtils.setVariable(BoardLineStyleVariablesEnum.ColumnsCount, this.minColumnCount);
    }

    const columnsCount =
      columns.length >= this.minColumnCount
        ? columns.length + staticColumnAddColumn
        : this.minColumnCount;
    StylesUtils.setVariable(BoardLineStyleVariablesEnum.ColumnsCount, columnsCount);
  }

  broadcastDragAndDrop(data: any) {
    this.dragAndDrop.next(data);
  }
}
