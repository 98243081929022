import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'fromNow',
  standalone: true,
})
export class FromNowPipe implements PipeTransform {
  transform(value: string, lang?: string | any): string {
    return moment(value, '', lang || 'en').fromNow();
  }
}
