import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { ActionsLogsStateModel } from '../models/ActionsLogsState';
import {
  ActionsLogsGet,
  AddNewActionLog,
  AddNewNotification,
  UpdateNotifications,
} from '../actions/actions-logs.action';
import { ActionsLogsService } from '../../../api/services/actions-logs.service';
import { insertItem, patch, removeItem } from '@ngxs/store/operators';

@State<ActionsLogsStateModel>({
  name: 'ActionsLogs',
  defaults: {
    object: null,
    objectId: null,
    actionsLogs: [],
    notifications: [],
  },
})
@Injectable()
export class ActionsLogsState {
  /**
   * get actions logs (for acts/projects)
   * @param  {ActionsLogsStateModel} state
   */
  @Selector()
  static getActionsLogs(state: ActionsLogsStateModel) {
    return state.actionsLogs;
  }

  /**
   * get notifications (for user-related actions)
   * @param  {ActionsLogsStateModel} state
   */
  @Selector()
  static getNotifications(state: ActionsLogsStateModel) {
    return state.notifications;
  }

  constructor(private actionsLogsService: ActionsLogsService) {}

  /**
   * Get logs action handler
   * @param  {patchState}: StateContext<ActionsLogsStateModel>
   * @param  {ActionsLogsGet} action
   */
  @Action(ActionsLogsGet)
  actions_logs_get({ patchState }: StateContext<ActionsLogsStateModel>, action: ActionsLogsGet) {
    patchState({
      [action.payload.object === 'users' ? 'notifications' : 'actionsLogs']: [],
    });
    return this.actionsLogsService.actionsLogGetList(action.payload).pipe(
      tap(
        (result) => {
          patchState({
            [action.payload.object === 'users' ? 'notifications' : 'actionsLogs']:
              result['results'],
          });
          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Add new notification and remove last old one
   * @param  {patchState, getState}: StateContext<ActionsLogsStateModel>
   * @param  {UpdateNotifications} action
   */
  @Action(UpdateNotifications)
  notifications_update(
    { setState, getState }: StateContext<ActionsLogsStateModel>,
    action: UpdateNotifications,
  ) {
    const { notifications } = getState();
    const newNotifications = action.payload.notificationId
      ? notifications.map((item) => {
          if (item._id === action.payload.notificationId) {
            item.isUnread = false;
          }
          return item;
        })
      : notifications.map((item) => {
          item.isUnread = false;
          return item;
        });

    setState(patch({ notifications: newNotifications }));
  }

  /**
   * Add new notification and remove last old one
   * @param  {patchState, getState}: StateContext<ActionsLogsStateModel>
   * @param  {AddNewNotification} action
   */
  @Action(AddNewNotification)
  notification_add(
    { setState, getState }: StateContext<ActionsLogsStateModel>,
    action: AddNewNotification,
  ) {
    setState(
      patch({
        notifications: removeItem(getState().notifications.length - 1),
      }),
    );
    setState(
      patch({
        notifications: insertItem(action.payload, 0),
      }),
    );
  }

  /**
   * Add new action log item and remove last old one
   * @param  {patchState, getState}: StateContext<ActionsLogsStateModel>
   * @param  {AddNewActionLog} action
   */
  @Action(AddNewActionLog)
  action_log_add(
    { setState, getState }: StateContext<ActionsLogsStateModel>,
    action: AddNewActionLog,
  ) {
    setState(
      patch({
        actionsLogs: removeItem(getState().actionsLogs.length - 1),
      }),
    );
    setState(
      patch({
        actionsLogs: insertItem(action.payload, 0),
      }),
    );
  }
}
