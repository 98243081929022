import { Directive, EventEmitter, HostListener, Input, Output, ElementRef } from '@angular/core';

@Directive({
  selector: '[appClickOutside]',
  standalone: true,
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<MouseEvent>();

  /**
   * Element you want to exclude from Click Outside functionality
   * */
  @Input() clickOutsideIgnoreElement: HTMLElement;
  @Input() ignoreMouseDown = false;

  constructor(private elementRef: ElementRef) {}
  @HostListener('document:mousedown', ['$event', '$event.target'])
  onRightClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (event.button !== 2 && event.button !== 0) {
      return;
    }
    let elementToExclude = undefined;
    if (!targetElement || this.ignoreMouseDown) {
      return;
    }
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (this.clickOutsideIgnoreElement) {
      elementToExclude = this.clickOutsideIgnoreElement.contains(event.target as Node);
    }
    if (!clickedInside && !elementToExclude) {
      this.clickOutside.emit(event);
    }
  }

  @HostListener('document:click', ['$event', '$event.target'])
  click(event: MouseEvent, targetElement: HTMLElement): void {
    if (!this.ignoreMouseDown) {
      return;
    } else {
      const clickedInside = this.elementRef.nativeElement.contains(targetElement);
      if (!clickedInside) {
        this.clickOutside.emit();
      }
    }
  }
}
