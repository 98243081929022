<ng-container *transloco="let t; read: 'data-room'">
  <h3 *ngIf="folders?.length && !isMobile" class="grid__title">
    {{ t('folder-title') }}
  </h3>
  <div class="grid__folders" [class.grid__folders-mobile]="isMobile">
    <div *ngFor="let folder of folders" class="grid__folders-wrapper" (click)="clickedFile(folder)">
      <svg-icon
        class="grid__folders-icon"
        [src]="getFolderIcon(folder)"
        [applyClass]="true"
      ></svg-icon>
      <span>{{ folder.name }}</span>
      <div
        class="grid__folders-actions"
        [class.isMobile-actions]="isMobile"
        (click)="$event.stopPropagation()"
        ngbDropdown
      >
        <svg-icon
          src="assets/icons/common/ellipsis.svg"
          [applyClass]="true"
          ngbDropdownToggle
        ></svg-icon>
        <div ngbDropdownMenu class="document-actions">
          <button
            *ngIf="object !== 'tenants'"
            ngbDropdownItem
            (click)="dataRoomUtilies.copyLinkClicked(folder)"
          >
            <app-svg name="copy" width="16" height="16" class="mr-2" aria-hidden="true"></app-svg>
            <span>{{ t('btn-copy-link') }}</span>
          </button>
          <button
            *ngIf="dataRoomUtilies.isEditTicket(folder)"
            ngbDropdownItem
            (click)="dataRoomUtilies.editFolderClicked(folder)"
          >
            <app-svg name="pen" width="16" height="16" class="mr-2" aria-hidden="true"></app-svg>
            <span>{{ t('btn-edit') }}</span>
          </button>
          <button
            *ngIf="dataRoomUtilies.isRemoveFile(folder)"
            t-id="documents-delete-action"
            ngbDropdownItem
            (click)="removeFileData(folder)"
          >
            <app-svg name="trash" width="16" height="16" class="mr-2" aria-hidden="true"></app-svg>
            <span>{{ t('btn-remove') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <h3 *ngIf="files?.length && !isMobile" class="grid__title">
    {{ t('files-title') }}
  </h3>
  <div class="grid__files" [class.grid__files-mobile]="isMobile">
    <app-data-room-file-grid
      *ngFor="let file of files"
      [object]="object"
      [objectId]="objectId"
      [file]="file"
      [platform]="platform"
      [isMobile]="isMobile"
      [currRootFolder]="currRootFolder"
      (viewFile)="clickedFile($event)"
      (removeFileData)="removeFileData($event)"
      (downloadFile)="downloadFileEmit($event)"
      appClick
      (appClick)="handleClick($event, file)"
    ></app-data-room-file-grid>
  </div>
</ng-container>
