import { Injectable } from '@angular/core';
import { CalendarUtils } from 'angular-calendar';
import { GetWeekViewArgs, WeekView, WeekViewTimeEvent } from 'calendar-utils';

@Injectable()
export class CustomCalendarUtils extends CalendarUtils {
  override getWeekView(args: GetWeekViewArgs): WeekView {
    const view = super.getWeekView(args);

    for (const column of view.hourColumns) {
      if (column.events.length === 0) {
        continue;
      }

      const foreignEvents = column.events.filter(({ event }) => event.meta?.isForeignEvent);
      const otherEvents = column.events.filter(
        ({ event }) => !event.meta?.isForeignEvent && !event.meta?.isEditingEvent,
      );
      const editingEvent = column.events.find(({ event }) => event.meta?.isEditingEvent);

      column.events = [...foreignEvents, ...otherEvents, editingEvent];

      column.events.forEach((event) => {
        if (event.event.meta?.isForeignEvent) {
          event.width = 100;
          event.left = 0;
          if (editingEvent !== undefined && isNewEventOverlapping(editingEvent, event)) {
            editingEvent.width = 100;
            editingEvent.left = 0;
          }
        }
      });
    }

    return view;
  }
}

function isNewEventOverlapping(
  newEvent: WeekViewTimeEvent,
  againstEvent: WeekViewTimeEvent,
): boolean {
  const newEventEnd = newEvent.top + newEvent.height;
  const againstEventEnd = againstEvent.top + againstEvent.height;

  return newEvent.top < againstEventEnd && againstEvent.top < newEventEnd;
}
