<div class="message-text">
  <div class="message-header">
    <span class="name"
      >{{ prActionTitle }}
      <a *ngIf="!!userUrl; else userText" [href]="userUrl" target="_blank"> {{ userName }}</a>
      <ng-template #userText>
        <span> {{ userName }}</span>
      </ng-template>
    </span>
  </div>

  <div class="message-wrapper">
    <div class="pr-title">
      <a [href]="prUrl" target="_blank">{{ prTitle }} #{{ prNumber }}</a>
    </div>
    <div *ngIf="showDetails">
      <div>
        <span class="branch">{{ sourceBranch }}</span>
        <span class="arrow">&#8594;</span>
        <span class="branch">{{ targetBranch }}</span>
      </div>
      <div *ngIf="!!prDescription">
        <h5 class="section-header">Description</h5>
        <span [innerHTML]="parseMarkedText(prDescription)"></span>
      </div>

      <div *ngIf="reviewers?.length > 0">
        <h5 class="section-header">Reviewers</h5>
        <span *ngFor="let r of reviewers; first as isFirst">
          <span><span *ngIf="!isFirst">, </span>{{ r.name }}</span>
        </span>
      </div>
      <div *ngIf="assignees?.length > 0">
        <h5 class="section-header">Assignees</h5>
        <span *ngFor="let a of assignees; first as isFirst">
          <span><span *ngIf="!isFirst">, </span>{{ a.name }}</span>
        </span>
      </div>
    </div>
    <div class="repository">
      {{ repositoryName }}
    </div>
  </div>
</div>
