// Common
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';

// States
import { SpacesState } from '../../../shared/store/states/spaces.state';
import { ProjectsState } from '../../../shared/store/states/projects.state';
import { DocumentsState } from '../../../shared/store/states/documents.state';
import {
  DocumentsAddDocument,
  DocumentsUpdateDocument,
} from '../../../shared/store/actions/documents.action';

// Types
import { DataDocument, ObjectInfo } from '../interface/document-modal.interface';
import {
  QuillModulesForChat,
  QuillModulesForDescription,
} from '../../../shared/data/quill-configuration';
import { DocumentsDbDto } from '../../../api/models/documents-db-dto';
import { FullImagePreviewService } from '../../../shared/services/full-image-preview.service';
import { PinModalService } from '../../../shared/services/pin-modal.service';
import { PinType } from '../../../shared/components/chat/chat-pinned-messages/enums/pin-type.enum';

// Services
import { QuillInitializeService } from '../../../shared/services/quill/quill-init.service';
import { RouterQueryService } from '../../../shared/services/router-query.service';
import { RouterTenantPipe } from '../../../shared/pipes/router-tenant.pipe';
import { ChatImagePreviewComponent } from '../../../shared/components/chat/chat-image-preview/chat-image-preview.component';
import { QuillEditorComponent } from 'ngx-quill';
import { ProjectAvatarComponent } from '../../../shared/components/space-projects/project-avatar/project-avatar.component';
import { RouterLink } from '@angular/router';
import { SpaceAvatarComponent } from '../../../shared/components/space-projects/space-avatar/space-avatar.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgIf } from '@angular/common';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-document-data-room',
  templateUrl: './document-data-room.component.html',
  styleUrls: ['./document-data-room.component.scss'],
  providers: [PinModalService],
  standalone: true,
  imports: [
    NgIf,
    SvgIconComponent,
    TranslocoDirective,
    SpaceAvatarComponent,
    RouterLink,
    ProjectAvatarComponent,
    FormsModule,
    ReactiveFormsModule,
    QuillEditorComponent,
    ChatImagePreviewComponent,
    RouterTenantPipe,
  ],
})
export class DocumentDataRoomComponent implements OnInit {
  @ViewChild('dataRoomImagePreviewModal') dataRoomImagePreviewModal;

  public editorModules: any;
  public editorDescription: any;
  public isLoading = false;
  public isLoading$ = false;

  public platform: string;
  public object: string;
  public objectId: string;
  public isEdit = false;
  public isMobile = false;
  public document: DocumentsDbDto;
  public objectInfo: ObjectInfo;
  public isUpdate = new Subject<void>();
  private spaces = [];
  private projects = [];

  public readonly closeIcon = 'assets/icons/common/cross.svg';
  public readonly arrowRightIcon = 'assets/icons/common/arrow-right.svg';
  public readonly unpinIcon = 'assets/icons/pin/unpin-message.svg';
  public readonly pinIcon = 'assets/icons/pin/pin-modal.svg';

  private dataDocument: DataDocument;

  public documentForm: FormGroup = this.formBuilder.group({
    title: ['', Validators.required],
    content: ['', Validators.required],
  });
  previewModal: NgbModalRef;

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private store: Store,
    protected toast: ToastrService,
    public fullImagePreviewService: FullImagePreviewService,
    public pinModalService: PinModalService,
    private quillInitializeService: QuillInitializeService,
    private modalsService: NgbModal,
    private translocoService: TranslocoService,
    protected routerQueryService: RouterQueryService,
  ) {}

  ngOnInit(): void {
    this.initializeData();
    this.objectInfo = this.getBreadcrumbs();
  }

  // Getters
  public get isInvalidForm(): boolean {
    return this.documentForm.controls.title.invalid || this.documentForm.controls.content.invalid;
  }

  public get LinkProjectSprint(): string {
    return `/project/${this.objectInfo.projectId}/`;
  }

  public get titleText(): string {
    return this.isEdit
      ? this.document.name
      : this.translocoService.translate('modals.document-data-room.add-document');
  }

  public get buttonText(): string {
    return this.isEdit
      ? this.translocoService.translate('modals.document-data-room.btn-update')
      : this.translocoService.translate('modals.document-data-room.btn-add');
  }

  public get LinkSpaceSprint(): string {
    return `/space/${this.objectInfo.spaceId}/`;
  }

  public get ableToPin(): boolean {
    return !this.pinModalService.pinnedMedia && this.pinModalService.isLimitPins;
  }

  public get ableToUnpin(): boolean {
    return this.pinModalService.pinnedMedia && this.pinModalService.unPinMessageAccess;
  }

  // Methods
  public closeModal(): void {
    this.routerQueryService.update({ document: null });
    this.activeModal.close();
  }

  public editorDescriptionCreated(editorDescription): void {
    this.editorDescription = editorDescription;
    QuillInitializeService.handleEditorCreated(editorDescription);
  }

  public handleClickInEditor(event: MouseEvent) {
    if (event?.target['src']) {
      this.openImagePreview(event.target['src']);
    }
  }

  private getBreadcrumbs() {
    const project =
      this.object === 'projects' ? this.projects.find((item) => item._id === this.objectId) : null;
    const spaceId = this.object === 'spaces' ? this.objectId : project?.spaceId;

    return {
      space: this.spaces?.find((space) => space._id === spaceId),
      project,
      spaceId,
      spaceName: this.spaces?.find((space) => space._id === spaceId)?.spaceName,
      projectId: project?._id || null,
      projectName: this.object === 'projects' ? project?.projectName : null,
    };
  }

  public formSubmit(): void {
    const currFolder = this.store.selectSnapshot(DocumentsState.getCurrentFolder);
    const content = this.documentForm.controls.content.value;
    const name = this.documentForm.controls.title.value;

    this.isLoading = true;
    this.store
      .dispatch(
        new DocumentsAddDocument({
          body: {
            content,
            folderId: currFolder._id,
            name,
            object: this.object,
            objectId: this.objectId,
          },
        }),
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.toast.success(
            this.translocoService.translate('toastr.message-document-added'),
            this.translocoService.translate('toastr.title-success'),
          );
          this.isUpdate.next();
          this.closeModal();
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        },
      );
  }

  public updateDocument(): void {
    const content = this.documentForm.controls.content.value;
    const name = this.documentForm.controls.title.value;

    this.isLoading = true;
    this.store
      .dispatch(
        new DocumentsUpdateDocument({
          id: this.document._id,
          body: {
            content,
            name,
            folderId: this.document.folderId,
            type: this.document.type,
          },
        }),
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.toast.success(
            this.translocoService.translate('toastr.message-link-updated'),
            this.translocoService.translate('toastr.title-success'),
          );
          this.isUpdate.next();
          this.closeModal();
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        },
      );
  }

  public actionPinMedia(): void {
    if (!this.pinModalService.pinnedMedia && this.pinModalService.isLimitPins) {
      this.pinModalService.pinMedia(PinType.Documents);
    } else if (this.pinModalService.pinnedMedia && this.pinModalService.unPinMessageAccess) {
      this.pinModalService.unPinMedia();
    }
  }

  private initializeData(): void {
    this.object = this.dataDocument.object;
    this.objectId = this.dataDocument.objectId;
    this.platform = this.dataDocument.platform;
    this.isMobile = this.dataDocument.isMobile;
    if (this.dataDocument.isEdit) {
      this.isEdit = this.dataDocument.isEdit;
      this.document = this.dataDocument.document;

      this.pinModalService.initializeSubscribe(
        this.document._id,
        this.object,
        this.objectId,
        this.platform,
      );
      this.pinModalService.isLoadingPin
        .pipe(untilDestroyed(this))
        .subscribe((value) => (this.isLoading$ = value));
      this.pinModalService.pinnedDocument
        .pipe(untilDestroyed(this))
        .subscribe(() => this.isUpdate.next());
    }

    combineLatest([
      this.store.select(SpacesState.getLoadedSpaces),
      this.store.select(ProjectsState.getLoadedProjects),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([spaces, projects]) => {
        this.spaces = spaces;
        this.projects = projects;
      });

    this.editorModules = {
      ...(this.platform === 'web' ? QuillModulesForDescription : QuillModulesForChat),
      magicUrl: true,
    };
  }

  openImagePreview(imageUrl: string) {
    this.fullImagePreviewService.prepareImages(this.documentForm.controls.content.value);
    this.fullImagePreviewService.openImagePreview(imageUrl);

    if (this.fullImagePreviewService.imagePreview) {
      this.previewModal = this.modalsService.open(this.dataRoomImagePreviewModal, {
        backdrop: 'static',
        windowClass: 'cropper-modal',
      });
      this.fullImagePreviewService.updateModal();
    }
  }
}
