<div
  class="chat-application d-flex"
  [ngClass]="{
    'chat-application-direct': currentChatItem?.type === 'direct',
    'chat-application-group': currentChatItem?.type === 'group',
    'sidebar-closed': !chatSidebarIsOpen
  }"
  *transloco="let t; read: 'chat'"
>
  <as-split *ngIf="platform === 'web'" direction="horizontal" (dragEnd)="onResizeSplitter($event)">
    <!--Chat body starts-->
    <section
      id="chat-main-body"
      class="position-relative chat-app-window"
      [class.sidebar-open-margin-right]="chatSidebarIsOpen"
      as-split-area
      minSize="30"
      order="1"
      [size]="getSectionSize(0) || chatSize"
    >
      <div
        t-id="chat-message-panel"
        id="chat-messages"
        #chatMessages
        [ngClass]="{
          'messages-wrapper-light': config?.layout.variant === 'Light',
          'messages-wrapper-dark': config?.layout.variant === 'Dark'
        }"
      >
        <app-chat-pinned-messages
          *ngIf="checkDirectMessage"
          [platform]="platform"
          [object]="object"
          [objectId]="objectId"
          [chatId]="chatId"
        ></app-chat-pinned-messages>
        <app-chat-messages
          *ngIf="messages.length"
          class="d-block h-100"
          platform="web"
          [messages]="messages"
          [object]="object"
          [objectId]="objectId"
          [chatId]="chatId"
          [isOpenedThread]="isOpenThreadSidebar"
          [mentionChatMembers]="mentionChatMembers"
          [showNoMessagesContainer]="showNoMessagesContainer"
          [isVisible]="isVisible"
          [perPage]="perPage"
          [perChat]="perChat"
          [currentPage]="currentPage"
          [previousPage]="previousPage"
          [chatMessageCnt]="chatMessageCnt"
          [lastMessageLoaded]="lastMessageLoaded"
          (uploadClipboardFile)="uploadClipboardFIle($event)"
          (downloadFileCompleted)="downloadFileCompleted()"
          (openThread)="openThreadSidebar($event)"
          (deletedThreadMessageId)="checkDeletedTopicThread($event)"
          (jumpToBottom)="jumpToBottom()"
          (onScrolledToTop)="getNextChatPage($event)"
          (onScrolledToBottom)="getPrevChatPage()"
        ></app-chat-messages>
      </div>

      <!--Chat footer starts-->
      <ng-content select="[chat-emoji-picker]"></ng-content>

      <app-chat-app-form
        #chatForm
        [platform]="platform"
        [readOnly]="readOnly"
        [chatId]="chatId"
        [object]="object"
        [objectId]="objectId"
        [mentionChatMembers]="mentionChatMembers"
        (audioRecorded)="onAudioRecorded($event)"
        (messageChanged)="onMessageChanged($event)"
        (scrollBottom)="scrollBottom()"
        (fileChanged)="onFileChanged($event)"
      ></app-chat-app-form>
      <!--Chat footer ends-->
    </section>
    <!--Chat body ends-->

    <!-- Toggle button -->
    <div class="sidebar-toggle-icon-wrapper" (click)="onToggleChatSidebar()">
      <app-svg
        name="angle-right"
        [width]="16"
        [height]="16"
        class="sidebar-toggle-icon"
        *ngIf="chatSidebarIsOpen"
      ></app-svg>
      <app-svg
        name="angle-left"
        [width]="16"
        [height]="16"
        class="sidebar-toggle-icon"
        *ngIf="!chatSidebarIsOpen"
      ></app-svg>
    </div>

    <!--Chat sidebar starts-->
    <div
      #chatSidebar
      id="chat-sidebar"
      class="chat-sidebar d-flex"
      [ngClass]="{
        'sidebar-open-sidebar': chatSidebarIsOpen,
        'sidebar-close-sidebar': !chatSidebarIsOpen,
        'chat-sidebar-light': config?.layout.variant === 'Light',
        'chat-sidebar-dark': config?.layout.variant === 'Dark'
      }"
      as-split-area
      minSize="1"
      order="2"
      [size]="getSectionSize(1) || sidebarSize"
    >
      <div class="chat-sidebar-content flex-grow-1">
        <div *ngIf="!isOpenThreadSidebar" class="h-100">
          <ul
            ngbNav
            #chatSidebarNav="ngbNav"
            [(activeId)]="currentSidebarTab"
            (navChange)="onNavChange($event)"
            class="nav-tabs d-flex justify-content-center"
          >
            <li [ngbNavItem]="'threads'">
              <a ngbNavLink t-id="chat-thread-button">{{ t('nav-tabs-threads') }}</a>
              <ng-template ngbNavContent>
                <app-chat-threads-list
                  *ngIf="currentSidebarTab === 'threads'"
                  [object]="object"
                  [objectId]="objectId"
                  [chatId]="chatId"
                  [mentionChatMembers]="mentionChatMembers"
                  (openThread)="openThreadSidebar($event)"
                ></app-chat-threads-list>
              </ng-template>
            </li>
            <li [ngbNavItem]="'members'">
              <a ngbNavLink t-id="chat-members-button">{{ t('nav-tabs-members') }}</a>
              <ng-template ngbNavContent>
                <app-chat-users-list
                  [membersChat]="membersChat"
                  (viewDirectChat)="viewDirectChat($event)"
                  [object]="object"
                  [objectId]="objectId"
                ></app-chat-users-list>
              </ng-template>
            </li>
            <li [ngbNavItem]="'search'">
              <a ngbNavLink>{{ t('nav-tabs-search') }}</a>
              <ng-template ngbNavContent>
                <app-chat-search [chatId]="chatId"></app-chat-search>
              </ng-template>
            </li>
          </ul>

          <div [ngbNavOutlet]="chatSidebarNav"></div>
        </div>

        <div
          id="chat-thread-body"
          [ngClass]="{
            open: isOpenThreadSidebar,
            'thread-body-light': config?.layout.variant === 'Light',
            'thread-body-dark': config?.layout.variant === 'Dark'
          }"
        >
          <app-chat-thread
            *ngIf="threadMessage"
            [message]="threadMessage"
            [object]="object"
            [objectId]="objectId"
            (clearThreadId)="clearThreadId()"
          ></app-chat-thread>
        </div>
      </div>
    </div>
    <!--Chat sidebar ends-->
  </as-split>
</div>
