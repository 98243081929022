<div
  class="grid__files-card"
  [class.grid__file-mobile]="isMobile"
  *transloco="let t; read: 'data-room'"
>
  <div class="grid__files-preview">
    <svg-icon
      *ngIf="showPreviewIcon"
      class="grid__files-icon"
      [class.position-image]="isImage"
      [src]="getIconFile(true)"
      [applyClass]="true"
    ></svg-icon>
    <img
      *ngIf="isImage"
      [src]="file.croppedImageUrl"
      (load)="onImageLoad()"
      class="grid__files-image"
      [class.hidden]="!isLoaded"
      alt="file"
    />
    <video
      *ngIf="isVideo && urlVideo"
      #videoElement
      (mouseenter)="startVideo()"
      (mouseleave)="stopVideo()"
      class="grid__files-video"
      [src]="urlVideo"
      [muted]="'muted'"
      preload="auto"
      loop
    ></video>
    <div *ngIf="isVideo" class="grid__files-video-action" [class.hide-play]="isPlayingVideo">
      <svg-icon
        class="grid__files-video-play"
        src="assets/icons/data-room/video.svg"
        [applyClass]="true"
      ></svg-icon>
    </div>
    <div
      class="grid__files-actions"
      (click)="$event.stopPropagation()"
      (dblclick)="$event.stopPropagation()"
      ngbDropdown
    >
      <svg-icon
        src="assets/icons/common/ellipsis.svg"
        [applyClass]="true"
        ngbDropdownToggle
      ></svg-icon>

      <div ngbDropdownMenu class="document-actions">
        <!-- Download functionality -->
        <button *ngIf="isNotLink" ngbDropdownItem (click)="downloadDocument()">
          <app-svg name="download" width="16" height="16" class="mr-2" aria-hidden="true"></app-svg>
          <span>{{ t('btn-download') }}</span>
        </button>

        <!-- Copy link functionality -->
        <button
          *ngIf="object !== 'tenants'"
          ngbDropdownItem
          (click)="dataRoomUtilies.copyLinkClicked(file)"
        >
          <app-svg name="copy" width="16" height="16" class="mr-2" aria-hidden="true"></app-svg>
          <span>{{ t('btn-copy-link') }}</span>
        </button>

        <!-- Pin functionality -->
        <button ngbDropdownItem *ngIf="isPossiblePin && !isPrivate" (click)="actionPinMedia()">
          <svg-icon *ngIf="pinModalService.pinnedMedia" class="mr-1" [src]="iconUnPin"></svg-icon>
          <svg-icon *ngIf="!pinModalService.pinnedMedia" class="mr-1" [src]="iconPin"></svg-icon>
          <span>{{ textForActionPin }}</span>
        </button>

        <!-- Edit functionality -->
        <button
          *ngIf="dataRoomUtilies.isEditTicket(this.file)"
          ngbDropdownItem
          (click)="dataRoomUtilies.editLinkClicked(file)"
        >
          <app-svg name="pen" width="16" height="16" class="mr-2" aria-hidden="true"></app-svg>
          <span>{{ t('btn-edit') }}</span>
        </button>

        <!-- View functionality -->
        <button
          *ngIf="isNotLink"
          ngbDropdownItem
          t-id="documents-file-edit-action"
          (click)="viewClicked()"
        >
          <app-svg
            name="{{ dataRoomUtilies.isPlayable(this.file.fileName) ? 'play' : 'eye' }}"
            width="16"
            height="16"
            class="mr-2"
            aria-hidden="true"
          ></app-svg>
          <span>{{
            dataRoomUtilies.isPlayable(this.file.fileName) ? t('btn-play') : t('btn-view')
          }}</span>
        </button>

        <!-- Delete functionality -->
        <button
          *ngIf="dataRoomUtilies.isRemoveFile(this.file)"
          t-id="documents-delete-action"
          ngbDropdownItem
          (click)="deleteClicked()"
        >
          <app-svg name="trash" width="16" height="16" class="mr-2" aria-hidden="true"></app-svg>
          <span>{{ t('btn-remove') }}</span>
        </button>
      </div>
    </div>
  </div>
  <div class="grid__files-info">
    <svg-icon [src]="getIconFile()" [applyClass]="true"></svg-icon>
    <span>{{ fileName }}</span>
  </div>
</div>
