import { NotesCreateReqDto } from '../../../api/models/notes-create-req-dto';
import { NotesLabelsCreateReqDto } from '../../../api/models/notes-labels-create-req-dto';

/** Create notes action **/
export class NoteCreate {
  static readonly type = '[Notes] Create';
  constructor(public payload: NotesCreateReqDto) {}
}

/** Get notes action **/
export class NotesGet {
  static readonly type = '[Notes] Get';
  constructor(public payload) {}
}

/** Delete notes action **/
export class NoteDelete {
  static readonly type = '[Notes] Delete';
  constructor(public payload) {}
}

/** Update notes action **/
export class NoteUpdate {
  static readonly type = '[Notes] Update';
  constructor(public payload) {}
}

/** Create notes label action **/
export class NotesLabelCreate {
  static readonly type = '[Notes] Label Create';
  constructor(public payload: NotesLabelsCreateReqDto) {}
}

/** Get notes labels action **/
export class NotesLabelsGet {
  static readonly type = '[Notes] Labels Get';
  constructor(public payload) {}
}
