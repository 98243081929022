<div class="dashboard-container d-flex" [class.dark]="config.layout.variant === 'Dark'">
  <div class="calendar-container flex-grow-1">
    <!--Calendar Table starts-->
    <app-calendar
      *ngIf="'users::null::calendarEventGetList' | checkPermission"
      [object]="'users'"
      [objectId]="null"
      [withSubNavbar]="false"
    ></app-calendar>
    <!--Calendar Table Ends-->
  </div>

  <div class="board-container">
    <app-user-board></app-user-board>
  </div>
</div>
