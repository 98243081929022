export enum FieldType {
  UserPicker = 'userPicker',
  DateTimePicker = 'dateTimePicker',
  DatePicker = 'datePicker',
  Text = 'text',
  TextArea = 'textArea',
  Number = 'number',
  Tags = 'tags',
  Select = 'select',
  MultiSelect = 'multiSelect',
  Unit = 'unit',
  LinkRelationship = 'linkRelationship',
}
