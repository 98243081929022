import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthState } from '../store/states/auth.state';

@Pipe({
  name: 'highlightChatMembers',
  standalone: true,
})
export class HighlightChatMembersPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(text: string, mentionChatMembers: string[]): any {
    if (!mentionChatMembers) {
      return text;
    }

    if (!text) {
      return;
    }

    let res = text;
    const user = this.store.selectSnapshot(AuthState.getUser);
    const allMembers = [...mentionChatMembers, user.userName];
    allMembers.sort((a, b) => b.length - a.length);

    for (const item of allMembers) {
      res = res.split('@' + item).join('<span class="highlight-chat-members">@' + item + '</span>');
    }
    return res;
  }
}
