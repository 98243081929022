import { EventEmitter, Component, Input, Output, NgModule } from '@angular/core';
import { ConfigService } from '../../../services/config.service';
import { TranslocoPipe } from '@ngneat/transloco';
import { UserNameAlonePipe } from '../../../../standalone/pipes/user-name.pipe';
import { TmpTooltipDirective } from '../../../../standalone/components/tmp-tooltip/tmp-tooltip.derective';
import { NgIf, NgClass, AsyncPipe, DatePipe } from '@angular/common';

@Component({
  selector: 'app-chat-message-details',
  templateUrl: './chat-message-details.component.html',
  styleUrls: ['./chat-message-details.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    TmpTooltipDirective,
    UserNameAlonePipe,
    AsyncPipe,
    DatePipe,
    TranslocoPipe,
  ],
})
export class ChatMessageDetailsComponent {
  @Input() message: any;
  @Input() tz = null;

  config: any = {};

  constructor(private configService: ConfigService) {
    this.config = this.configService.templateConf;
  }
}
