import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TasksService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  getTaskList(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    query: {
      objectId: string;
      from: string;
      to: string;
    };
  }): Observable<{
    tasks: {
      _id?: string;
      start: string;
      object: string;
      objectId: string;
      end?: string;
      allDay: boolean;
      title?: string;
    }[];
  }> {
    const rb = new RequestBuilder(this.rootUrl, '/tasks', 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('objectId', params.query.objectId, {});
      rb.query('from', params.query.from, {});
      rb.query('to', params.query.to, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((response) => response.body),
      );
  }

  getTask(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    query: {
      objectId: string;
      from: string;
      to: string;
    };
  }): Observable<{
    id: string;
    start: string;
    object: string;
    objectId: string;
    end?: string;
    allDay: boolean;
    title: string;
  }> {
    const rb = new RequestBuilder(this.rootUrl, '/tasks', 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('objectId', params.query.objectId, {});
      rb.query('from', params.query.from, {});
      rb.query('to', params.query.to, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((response) => response.body),
      );
  }

  createTask(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: {
      start: string;
      end?: string;
      allDay: boolean;
      title?: string;
      object: string;
      objectId: string;
      description?: string;
      repeat: string;
      stopRepeatAt: string;
      onlyWorkingDays?: boolean;
    };
  }): Observable<{
    _id: string;
  }> {
    const rb = new RequestBuilder(this.rootUrl, '/tasks', 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((response) => response.body),
      );
  }

  createExceptionTaskDay(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: {
      id: string | number;
      start: string;
      end?: string;
      allDay: boolean;
      title?: string;
      object: string;
      objectId: string;
      description?: string;
      repeat: string;
      stopRepeatAt: string;
      onlyWorkingDays?: boolean;
    };
  }): Observable<{
    _id: string;
  }> {
    const rb = new RequestBuilder(
      this.rootUrl,
      `/tasks/create-exception-day/${params.body.id}`,
      'post',
    );

    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((response) => response.body),
      );
  }

  getTaskDetails(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: {
      id: string | number;
    };
  }): Observable<{
    _id?: string | number;
    start: string;
    end?: string;
    allDay: boolean;
    title?: string;
    object: string;
    objectId: string;
    description?: string;
    repeat: string;
    stopRepeatAt: string;
    onlyWorkingDays?: boolean;
  }> {
    const rb = new RequestBuilder(this.rootUrl, `/tasks/${params.body.id}`, 'get');

    if (params) {
      rb.header('x-lang', params['x-lang'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((response) => response.body),
      );
  }

  updateTask(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: {
      id: string | number;
      start: string;
      end?: string;
      allDay: boolean;
      title?: string;
      object: string;
      objectId: string;
      description?: string;
    };
  }): Observable<{
    _id: string;
  }> {
    const rb = new RequestBuilder(this.rootUrl, `/tasks/${params.body.id}`, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
      rb.query('id', params.body.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((response) => response.body),
      );
  }

  deleteTask(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: {
      id: string | number;
      object: string;
      objectId: string;
    };
  }) {
    const rb = new RequestBuilder(this.rootUrl, '/tasks/delete', 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((response) => response.body),
      );
  }
}
