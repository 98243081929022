<vg-player
  *ngIf="videoUrl"
  class="player"
  [class.player-opacity]="isDownloading"
  (onPlayerReady)="isPlayerReady = true"
>
  <ng-container *ngIf="isPlayerReady">
    <vg-overlay-play *ngIf="!isDownloading"></vg-overlay-play>
    <vg-buffering></vg-buffering>

    <vg-scrub-bar *ngIf="!isDownloading">
      <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
      <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
    </vg-scrub-bar>

    <vg-controls *ngIf="!isDownloading" [vgAutohide]="true" [vgAutohideTime]="3">
      <div>
        <vg-play-pause></vg-play-pause>
      </div>

      <div>
        <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
      </div>

      <div class="total-time">
        <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
      </div>

      <div class="mute-button">
        <vg-mute></vg-mute>
      </div>

      <div class="fullscreen-button">
        <vg-fullscreen></vg-fullscreen>
      </div>
    </vg-controls>
  </ng-container>

  <video #media preload="metadata" [vgMedia]="$any(media)" id="singleVideo" disablePictureInPicture>
    <source [src]="videoUrl + '#t=0.001'" />
  </video>
</vg-player>
