import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollingChatService {
  public isScrolling = new BehaviorSubject(false);
  public scollingMessage = new BehaviorSubject(null);

  public setScrolling(value: boolean) {
    this.isScrolling.next(value);
  }

  public setScrollingMessage(value) {
    this.scollingMessage.next(value);
  }

  public get getValue(): boolean {
    return this.isScrolling.value;
  }
}
