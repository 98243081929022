/** Get logs action **/
export class ActionsLogsGet {
  static readonly type = '[ActionsLogs] Get';
  constructor(public payload) {}
}

/** Add new notification */
export class AddNewNotification {
  static readonly type = '[ActionLogs] Add new notification';
  constructor(public payload) {}
}

/** Update notifications */
export class UpdateNotifications {
  static readonly type = '[ActionLogs] Update notifications';
  constructor(public payload) {}
}

/** Add new action log */
export class AddNewActionLog {
  static readonly type = '[ActionLogs] Add new action log item';
  constructor(public payload) {}
}
