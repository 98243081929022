export const anotherTypesDescription = [
  {
    title: 'User Picker (multiple users)',
    type: 'userPicker',
    description: '',
  },
  {
    title: 'Tags',
    type: 'tags',
    description: '',
  },
  {
    title: 'Link Relationship',
    type: 'linkRelationship',
    description: '',
  },
];
