import Quill from 'quill';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgZone } from '@angular/core';

// Modals
import { UrlChangeModalComponent } from '../../../modals/url-change-modal/url-change-modal.component';

const Link = Quill.import('formats/link');

/** https://github.com/quilljs/quill/issues/2277#issuecomment-1081783477 */

// note: this will extend the link universally across all quill instances which are open.
class LinkBlot extends Link {
  static modalService: NgbModal;
  static ngZone: NgZone;
  static create(value) {
    // fallback to when link is just an url string (default quill behaviour)
    const urlOnly = typeof value === 'string';
    const node = super.create(urlOnly ? value : value?.url);
    node.setAttribute('href', urlOnly ? value : value?.url);
    node.setAttribute('target', urlOnly ? '_blank' : value?.target || '_blank');
    node.setAttribute('rel', 'noreferrer noopener');

    // handle editing of created links
    // Note: we have hidden the native quilljs .ql-tooltip through css
    node.addEventListener('click', function (e) {
      e.preventDefault();
      e.stopPropagation();

      // NgZone for rerender modal when paste text ( modal open only after second click )
      LinkBlot.ngZone.run(() => {
        const modalRef = LinkBlot.modalService.open(UrlChangeModalComponent, {
          size: 'md',
          centered: true,
        });
        modalRef.componentInstance.urlData.setValue(node.href);
        modalRef.componentInstance.titleData.setValue(node.innerText);

        modalRef.result
          .then((result) => {
            node.innerText = result.title;
            node.setAttribute('href', result.url);
            node.setAttribute('title', result.url);
          })
          .catch(() => {});
      });
    });
    return node;
  }

  static formats(domNode) {
    return { url: domNode.getAttribute('href') };
  }

  // native quill sanitise would strip the target attr, so replace with angular
  // static sanitize(url) {
  // return rootSanitizer.sanitize(SecurityContext.URL, url) || '';
  // }

  format(name, value) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (name !== this.statics.blotName || !value?.url) {
      return super.format(name, value?.url);
    }

    // native quill sanitise here would strip the target attr
    // const sanitizedUrl = rootSanitizer.sanitize(SecurityContext.URL, value?.url);

    const sanitizedUrl = super.sanitize(value?.url);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.domNode.innerText = sanitizedUrl;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.domNode.setAttribute('href', sanitizedUrl);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.domNode.setAttribute('title', sanitizedUrl);
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
LinkBlot.blotName = 'link';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
LinkBlot.tagName = 'a';

export default LinkBlot;
