<app-text-message
  [message]="message"
  [mentionChatMembers]="mentionChatMembers"
  [context]="context"
  [platform]="platform"
></app-text-message>
<ng-container *ngIf="showImageContainer">
  <div class="d-flex flex-column my-1" t-id="doc-message-images">
    <div class="uploaded-image-wrapper">
      <div *ngIf="isMainMessage" class="image-name">{{ nameFile }}</div>
      <img
        *ngIf="(message.fileData.croppedImageUrl && message?.chatId) || message?.threadId"
        [appImgChat]="chatType"
        class="mr-2 uploaded-image {{ !message?.threadId ? 'message-chat' : 'message-thread' }}"
        [ngClass]="{ 'image-downloading': message.isDownloadingFile }"
        [src]="message.fileData.croppedImageUrl"
        [width]="message.fileData.croppedWidth"
        [height]="message.fileData.croppedHeight"
        alt="Uploaded image"
        (click)="emitImagePreview(message)"
      />
      <img
        *ngIf="!message.fileData.croppedImageUrl"
        [appImgChat]="chatType"
        class="mr-2 uploaded-image"
        [ngClass]="{ 'image-downloading': message.isDownloadingFile }"
        [src]="message.fileData.url"
        alt="Uploaded image"
        (click)="emitImagePreview(message)"
      />
      <div *ngIf="message.isDownloadingFile" class="image-spinner-loading">
        <app-custom-spinner [smallSize]="true"></app-custom-spinner>
        <p class="downloading-text">Downloading...</p>
      </div>
    </div>
  </div>
</ng-container>
