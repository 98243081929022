<div class="modal-header d-flex align-items-center">
  <app-svg
    t-id="page-details-title-action"
    class="cursor-pointer cancel-icon svg-icon"
    name="times"
    width="10"
    height="10"
    aria-label="Close"
    (click)="close()"
  ></app-svg>
</div>

<div
  class="modal-body page-archive"
  [class.dark-archive]="config?.layout.variant === 'Dark'"
  *transloco="let t; read: 'modals.archive'"
>
  <h3 class="page-archive__title">{{ title }}</h3>
  <div class="page-archive__description">
    <div *ngIf="!delete" class="page-archive__description-warn">
      {{ t('archive-description', { text: handleTextModal }) }}
    </div>
    <div
      *ngIf="delete"
      class="page-archive__description-warn"
      [innerHTML]="t('archive-delete-confirm', { text: handleTextModal, title: titleObj })"
    ></div>
    <div *ngIf="!delete">
      {{ t('that-means') }}
      <ul>
        <li *ngIf="handleTypeModal">{{ t('that-means-first') }}</li>
        <li>{{ t('that-means-second', { text: handleTextModal }) }}</li>
        <li>{{ t('that-means-third', { text: handleTextModal }) }}</li>
        <li>{{ t('that-means-fourth') }}</li>
        <li>{{ t('that-means-fifth', { text: handleTextModal }) }}</li>
      </ul>
    </div>
    <div *ngIf="delete">
      <div *ngIf="handleTypeModal">{{ t('delete-warning') }}</div>
      <div *ngIf="!handleTypeModal">{{ t('reminder-first') }}</div>
    </div>
    <div *ngIf="!delete">
      {{ t('reminder-second', { text: handleTextModal }) }}
    </div>
    <div *ngIf="delete && !handleTypeModal" class="delete-project">
      {{ t('keep-in-mind') }}
      <ul>
        <li>{{ t('keep-in-mind-first') }}</li>
        <li>{{ t('keep-in-mind-second') }}</li>
      </ul>
    </div>
  </div>
  <div class="page-archive__button">
    <button
      class="btn btn-subtle"
      type="button"
      (click)="close()"
      t-id="space-archive-cancel-button"
    >
      {{ t('btn-cancel') }}
    </button>
    <button
      *ngIf="!delete"
      class="btn btn-solid btn-save"
      type="button"
      (click)="type === 'space' ? handleArchive() : handleArchiveProject()"
      t-id="space-archive-submit-button"
    >
      {{ t('btn-archive') }}
    </button>
    <button
      *ngIf="delete"
      class="btn btn-solid btn-save"
      type="button"
      (click)="handleDelete()"
      t-id="space-archive-delete-button"
    >
      {{ t('btn-delete') }}
    </button>
  </div>
</div>
