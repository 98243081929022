import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CardActionEnum } from '../../enums/card-action.enum';

interface CardAction {
  type: CardActionEnum;
  // TODO: Put here column type after integration with back-end
  payload?: any;
}

@Injectable({
  providedIn: 'root',
})
export class CardService {
  private clickOnActionItem = new Subject();

  public clickOnActionItem$ = this.clickOnActionItem.asObservable();

  /* Methods */
  public broadcastClickOnActionItem(menuItem: CardAction): void {
    this.clickOnActionItem.next(menuItem);
  }
}
