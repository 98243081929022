import { Type } from '@angular/core';
import { TicketsFieldsValuesReqDto } from '../../../../api/models/tickets-fields-values-req-dto';
import { TextInputComponent } from '../components/TextInput/text-input.component';
import { SelectLabelsTagsComponent } from '../components/SelectLabelsTags/select-labels-tags.component';
import { DateTimeComponent } from '../components/DateTime/date-time.component';
import { InputSelectComponent } from '../components/InputSelect/input-select.component';
import { TicketsFieldsDbDto } from '../../../../api/models/tickets-fields-db-dto';
import { SelectLinkRelationshipComponent } from '../components/SelectLinkRelationship/select-link-relationship.component';
import { SelectComponent } from '../components/Select/select.component';

export class CustomFieldItem {
  constructor(
    public component: Type<
      | DateTimeComponent
      | SelectLabelsTagsComponent
      | SelectLinkRelationshipComponent
      | SelectComponent
      | InputSelectComponent
      | TextInputComponent
    >,
    public fieldConfig: TicketsFieldsDbDto,
    public customFieldValues?: TicketsFieldsValuesReqDto,
  ) {}
}
