import {
  ChangeDetectorRef,
  Component,
  Input,
  Output,
  HostListener,
  EventEmitter,
  OnDestroy,
  OnInit,
  forwardRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { TranslocoService } from '@ngneat/transloco';

import { ConfigService } from '../../../services/config.service';
import { FilesHelper } from '../../../utils/files-helper';
import { ChatsCreatePin, ChatsDeletePin, ChatsGetPin } from '../../../store/actions/chats.action';
import { ChatsState } from '../../../store/states/chats.state';
import { ChatPinnedService } from '../chat-pinned-messages/chat-pinned.service';
import { PinType } from '../chat-pinned-messages/enums/pin-type.enum';
import { FullImagePreviewService } from '../../../services/full-image-preview.service';
import { ConfirmAlert } from '../../../alerts/alerts';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { SvgComponent } from '../../../svgs/svg/svg.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgClass, NgIf, NgStyle, DatePipe } from '@angular/common';
import { AvatarComponent } from '../../../../standalone/components/avatar/avatar.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-chat-image-preview',
  templateUrl: './chat-image-preview.component.html',
  styleUrls: ['./chat-image-preview.component.scss'],
  standalone: true,
  imports: [
    forwardRef(() => AvatarComponent),
    NgClass,
    NgIf,
    SvgIconComponent,
    SvgComponent,
    NgStyle,
    PinchZoomModule,
    DatePipe,
  ],
})
export class ChatImagePreviewComponent implements OnInit, OnDestroy {
  @Input() platform = 'web';
  @Input() tz = null;
  @Input() object: string;
  @Input() objectId: string;
  @Input() isThread: boolean;
  @Input() imagePreviewMessage;
  @Input() isLastPreviewImage;
  @Input() isFirstPreviewImage;
  @Input() updateEvent;
  @Input() isOpenNotFromTheChat = false;
  @Input() isNote = false;

  @Output() closePreviewModal = new EventEmitter();
  @Output() goToNextImage = new EventEmitter();
  @Output() goToPreviousImage = new EventEmitter();
  @Output() downloadFile = new EventEmitter();
  @Output() openDataRoomModal = new EventEmitter();

  config: any;
  updateSubs: Subscription;
  isLoadingPin = false;
  canUnpinImage = true;
  chatId: string;
  isPinned = false;
  isMobile = false;

  constructor(
    private configService: ConfigService,
    public filesHelper: FilesHelper,
    public cdr: ChangeDetectorRef,
    private store: Store,
    private pinnedService: ChatPinnedService,
    public fullImagePreviewService: FullImagePreviewService,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown(e) {
    if (e.keyCode === 39 && !this.isLastPreviewImage) {
      this.goToNextImage.emit();
    }

    if (e.keyCode === 37 && !this.isFirstPreviewImage) {
      this.goToPreviousImage.emit();
    }
  }

  ngOnInit() {
    this.initialSubscribe();

    if (this.isOpenNotFromTheChat) {
      this.platform = this.fullImagePreviewService.platform;
    } else {
      this.updateSubs = this.updateEvent.subscribe((res) => {
        this.imagePreviewMessage = res.imagePreviewMessage;
        this.isLastPreviewImage = res.isLastPreviewImage;
        this.isFirstPreviewImage = res.isFirstPreviewImage;
        this.isPinned = this.isPinnedMessage;
        this.cdr.detectChanges();
      });
    }
    this.isMobile = this.platform === 'mobile';
  }

  ngOnDestroy() {
    this.updateSubs?.unsubscribe();
    this.fullImagePreviewService.currentImageElement = null;
  }

  get isPinnedMessage(): boolean {
    const allPinMessage = this.store.selectSnapshot(ChatsState.getPinMessages);

    if (this.imagePreviewMessage.fileId || this.imagePreviewMessage?.fileData?._id) {
      return allPinMessage.some(
        (pin) =>
          this.imagePreviewMessage?.fileData?._id &&
          (pin.linkChatFile?._id === this.imagePreviewMessage?.fileData?._id ||
            pin.linkThreadFile?._id === this.imagePreviewMessage?.fileData?._id),
      );
    } else {
      return allPinMessage.some((pin) => pin.url === this.imagePreviewMessage.fileData.url);
    }
  }

  public get isLimitPins(): boolean {
    return (
      this.store
        .selectSnapshot(ChatsState.getPinMessages)
        .filter((msg) => msg.linkDocument !== PinType.Message)?.length <= 14
    );
  }

  public pinMedia() {
    this.isLoadingPin = true;

    if (this.imagePreviewMessage.fileId || this.imagePreviewMessage?.fileData?._id) {
      this.store
        .dispatch(
          new ChatsCreatePin({
            id: this.chatId,
            body: {
              linkDocument:
                this.isThread && !this.imagePreviewMessage.chatId ? 'threads.files' : 'chats.files',
              linkDocumentId:
                this.imagePreviewMessage.fileId || this.imagePreviewMessage?.fileData?._id,
              object: this.object,
              objectId: this.objectId,
            },
          }),
        )
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            this.isPinned = true;
            this.isLoadingPin = false;
          },
          () => {
            this.isLoadingPin = false;
          },
        );
    } else {
      this.store
        .dispatch(
          new ChatsCreatePin({
            id: this.chatId,
            body: {
              name: `Internal Image`,
              url: this.imagePreviewMessage.fileData.url,
              isInternalLink: true,
              openInFrame: true,
              object: this.object,
              objectId: this.objectId,
            },
          }),
        )
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            this.isPinned = true;
            this.isLoadingPin = false;
          },
          () => {
            this.isLoadingPin = false;
          },
        );
    }
  }

  public unPinMedia() {
    const id: string = this.pinTakePin()._id;

    ConfirmAlert(this.translocoService.translate('alert.pin-title'), {
      subject: this.translocoService.translate('alert.unpin-subject'),
      text: this.translocoService.translate('alert.unpin-text'),
      confirmButtonText: this.translocoService.translate('alert.unpin-btn-text'),
      platform: this.platform,
    }).then(() => {
      this.isLoadingPin = true;
      this.store
        .dispatch(
          new ChatsDeletePin({
            id: this.chatId,
            pinnedMessageId: id,
            object: this.object,
            objectId: this.objectId,
          }),
        )
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            this.isPinned = false;
            this.isLoadingPin = false;
          },
          () => {
            this.isLoadingPin = false;
          },
        );
    });
  }

  private pinTakePin() {
    if (this.imagePreviewMessage.fileId || this.imagePreviewMessage?.fileData?._id) {
      return this.store
        .selectSnapshot(ChatsState.getPinMessages)
        .find(
          (pin) =>
            pin.linkDocument &&
            (pin.linkChatFile?._id === this.imagePreviewMessage?.fileData?._id ||
              pin.linkThreadFile?._id === this.imagePreviewMessage?.fileData?._id),
        );
    } else {
      return this.store
        .selectSnapshot(ChatsState.getPinMessages)
        .find((pin: any) => pin.url === this.imagePreviewMessage.fileData.url);
    }
  }

  private initialSubscribe() {
    this.chatId =
      this.imagePreviewMessage?.chatId ||
      this.store.selectSnapshot(ChatsState.getChats).find((chat) => chat.objectId === this.objectId)
        ?._id;

    if (this.chatId && !this.store.selectSnapshot(ChatsState.getPinMessages)?.length) {
      this.store
        .dispatch(new ChatsGetPin({ id: this.chatId }))
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.isPinned = this.isPinnedMessage;

          const pinnedMessage = this.pinTakePin();
          if (pinnedMessage) {
            this.canUnpinImage = this.pinnedService.canDeletePin(pinnedMessage, this.objectId);
          }
        });
    } else {
      this.isPinned = this.isPinnedMessage;

      const pinnedMessage = this.pinTakePin();
      if (pinnedMessage) {
        this.canUnpinImage = this.pinnedService.canDeletePin(pinnedMessage, this.objectId);
      }
    }
  }

  @HostListener('wheel', ['$event'])
  onScroll(event: WheelEvent) {
    this.fullImagePreviewService.setZoomState(event);
  }

  getFileName() {
    return this.isMobile &&
      this.filesHelper.isTooLongExtension(this.imagePreviewMessage.fileData.originalFileName)
      ? this.filesHelper.truncate(this.imagePreviewMessage.fileData.originalFileName)
      : this.filesHelper.getFileNameWithoutExtension(
          this.imagePreviewMessage.fileData.originalFileName,
        );
  }

  getFileExtension() {
    return this.isMobile &&
      this.filesHelper.isTooLongExtension(this.imagePreviewMessage.fileData.originalFileName)
      ? 'unknown'
      : this.filesHelper.getFileExtension(this.imagePreviewMessage.fileData.originalFileName);
  }
}
