import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor() {}

  fetchImagesFromString(imagesString: string): string[] {
    if (imagesString) {
      const imgRex = /<img.*?src="(.*?)".*?>/gim;
      const images = [];
      let img;
      while ((img = imgRex.exec(imagesString))) {
        images.push(img[1]);
      }
      return images;
    } else {
      return [];
    }
  }

  checkIfAvatar(classList: string[]): boolean {
    let isAvatar = false;

    if (classList) {
      for (let index = 0; index < classList.length; index++) {
        const element = classList[index];
        if (element === 'avatar' || element === 'psavatar') {
          isAvatar = true;
          break;
        }
      }
    }

    return isAvatar;
  }
}
