import {
  AfterViewInit,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  forwardRef,
} from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { UsersPublicFieldsResDto } from '../../../api/models/users-public-fields-res-dto';
import { CheckPermissionAlonePipe } from '../../pipes/check-permission.pipe';

import { BoardTicketModalComponent } from '../../../modals/board-ticket/board-ticket.component';
import { NotificationsState } from '../../../shared/store/states/notifications.state';
import { AuthState } from '../../../shared/store/states/auth.state';
import { MinimizeService } from '../../../shared/services/minimize.service';
import { DraftService } from '../../../shared/services/draft.service';
import {
  MarkNotificationAsRead,
  NotificationsGet,
} from '../../../shared/store/actions/notifications.action';
import { DatePipe, CommonModule } from '@angular/common';
import { FromNowAlonePipe } from '../../pipes/from-now.pipe';
import { AvatarComponent } from '../avatar/avatar.component';
import { Overlay, OverlayModule } from '@angular/cdk/overlay';
import { CdkPortal, ComponentPortal, PortalModule, TemplatePortal } from '@angular/cdk/portal';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TenantsState } from '../../../shared/store/states/tenants.state';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { CalendarEditEventComponent } from '../../../modals/calendar-edit-event/calendar-edit-event.component';
import { enterLeaveAnimation } from '../../animations/enter-leave.animation';
import { PlatformService } from '../../../shared/services/platform.service';
import { TranslocoService } from '@ngneat/transloco';
import { PreviewMediaComponent } from '../../../modals/data-room-modals/preview-media/preview-media.component';
import { TruncateAlonePipe } from '../../pipes/truncate.pipe';
import { ta } from 'date-fns/locale';
import moment from 'moment-timezone';
import { FromNowPipe } from '../../../shared/pipes/from-now.pipe';
import { MixpanelService } from '../../../plugins/mixpanel/mixpanel.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  animations: [enterLeaveAnimation],
  standalone: true,
  imports: [
    CommonModule,
    CheckPermissionAlonePipe,
    TranslocoModule,
    FromNowAlonePipe,
    forwardRef(() => AvatarComponent),
    OverlayModule,
    PortalModule,
    FromNowPipe,
    NgScrollbarModule,
    InfiniteScrollModule,
    TruncateAlonePipe,
  ],
})
export class NotificationsComponent implements OnInit, AfterViewInit {
  @HostBinding('@enterLeaveAnimation') animate = true;

  @ViewChild('templatePortalContent')
  templatePortalContent: TemplateRef<unknown>;
  @ViewChild('templateOrigin') origin: any;
  @Input() tz: string;
  @Select(NotificationsState.getUnreadMessagesCount)
  unreadNotificationsCount$: Observable<number>;
  @Select(NotificationsState.getNotifications) notifications$: Observable<any>;
  @Select(TenantsState.getTenantName) tenant$: Observable<string>;
  modalRef: NgbModalRef;
  modalData: any;
  isOpen = false;
  isOpened = false;
  userData: UsersPublicFieldsResDto;
  isSidebarOpen = false;
  templatePortal: TemplatePortal<any>;
  overlayRef = this.overlay.create();
  isPlatformMobile = this.platformService.isMobile();
  isPlatformDesktop = this.platformService.isDesktop();
  previewModal: NgbModalRef;
  constructor(
    private modal: NgbModal,
    private minimizeService: MinimizeService,
    private draftService: DraftService,
    private store: Store,
    public overlay: Overlay,
    private router: Router,
    private _viewContainerRef: ViewContainerRef,
    private platformService: PlatformService,
    private translocoService: TranslocoService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit() {
    this.store.dispatch(new NotificationsGet({ unread: true, limit: 50, offset: 0 }));
    this.userData = this.store.selectSnapshot(AuthState.getUser);
  }

  ngAfterViewInit() {
    this.templatePortal = new TemplatePortal(this.templatePortalContent, this._viewContainerRef);
  }

  protected openModal() {
    MixpanelService.trackEvent('Notifications: Open modal');
    let right = '160px';
    let top = '50px';

    if (this.isPlatformMobile) {
      right = '0px';
      top = '40px';
    }
    const positionStrategy = this.overlay.position().global().right(right).top(top);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });

    this.overlayRef.attach(this.templatePortal);
    this.overlayRef.backdropClick().subscribe(() => this.overlayRef.detach());
  }

  linkOpen(link: string | { url: string; type: 'external' | 'modal' | 'path' }, notification) {
    MixpanelService.trackEvent('Notifications: Open link', {
      link: link,
    });
    if (notification.unread === true) {
      this.markAsRead(notification._id);
    }
    if (link === null) return;
    const { tenantName } = this.userData;
    this.overlayRef.detach();
    if (this.isSidebarOpen === true) {
      this.store.dispatch(new NotificationsGet({ unread: true, limit: 50, offset: 0 }));
    }
    this.isSidebarOpen = false;

    if (typeof link === 'string') {
      if (environment.subdomains) {
        this.router.navigateByUrl(link);
      } else {
        this.router.navigateByUrl('/' + tenantName + link);
      }
    } else {
      if (link.url === undefined) {
        link = notification.redirect as {
          url: string;
          type: 'external' | 'modal' | 'path';
        };
      }
      if (link.url === undefined) return;

      switch (link.type) {
        case 'external':
          window.open(link.url, '_blank');
          break;
        case 'path':
          if (environment.subdomains) {
            this.router.navigateByUrl(link.url);
          } else {
            const currentUrl = this.router.url;
            if (currentUrl === '/' + tenantName + link.url) {
              switch (notification.module.split(':')[0]) {
                case 'calendar':
                  this.openModalCalendar(link);
                  break;
                case 'file':
                  this.openModalFile(link);
                  break;
              }
            } else {
              this.router.navigateByUrl('/' + tenantName + link.url);
            }
          }
          break;

        case 'modal':
          if (link.url.includes('/calendar')) {
            this.openModalCalendar(link);
          } else {
            this.openTicket(link);
          }
          break;
        default:
          this.openTicket(link);

          break;
      }
    }
  }

  openModalCalendar(link) {
    //eddi/space/656ef71b03d4aedc5919bdc8/calendar/65cf1db1062e4ae4fb7af87f
    //dash/calendar/65cf1d1a062e4ae4fb7af744
    let calendarId = link.url.split('/')[4];
    let place = link.url.split('/')[1] === 'space' ? 'spaces' : 'projects';
    let placeId = link.url.split('/')[2];

    if (link.url.split('/')[1] == 'dash') {
      calendarId = link.url.split('/')[3];
    }

    const modalRef = this.modal.open(CalendarEditEventComponent, {
      windowClass: 'modal-fit-content',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.modalData = {
      action: 'View event',
      displayName: this.translocoService.translate('calendar.view-event'),
      event: {
        _id: calendarId,
        meta: {
          object: place,
          objectId: placeId,
        },
      },
    };
  }

  openTicket(link) {
    if (link && link.url) {
      const ticketId = link.url.split('ticket=')[1];
      const place = link.url.split('/')[1] === 'space' ? 'spaces' : 'projects';
      const placeId = link.url.split('/')[2];
      const draft = this.draftService.isHasActiveDraft(ticketId);
      if (draft) {
        this.draftService.openTicketOrDraft(draft, {
          id: ticketId,
          object: place as 'spaces' | 'projects',
          objectId: placeId,
        });
      } else {
        const modalRef = this.modal.open(BoardTicketModalComponent, {
          size: 'xl',
          backdrop: 'static',
          scrollable: true,
          keyboard: false,
          beforeDismiss: () => modalRef.componentInstance.closeImagePreview(true),
        });
        modalRef.componentInstance.ticketData = {
          id: ticketId,
          object: place as 'spaces' | 'projects',
          objectId: placeId,
        };

        this.minimizeService.minimizeInit(modalRef);
      }
    }
  }

  openModalFile(link) {
    let fileId = link.url.split('/')[4];
    let place = link.url.split('/')[1] === 'space' ? 'spaces' : 'projects';
    let placeId = link.url.split('/')[2];

    this.previewModal = this.modal.open(PreviewMediaComponent, {
      size: 'xl',
      windowClass: 'media-view-modal',
      centered: true,
    });

    this.previewModal.componentInstance.previewData = {
      object: place,
      objectId: placeId,
      currentMedia: fileId,
    };
  }

  markAsRead(notificationId: number = null) {
    MixpanelService.trackEvent('Notifications: Mark as read', {
      notificationId: notificationId,
    });
    this.store.dispatch(new MarkNotificationAsRead({ notificationId }));
  }

  openSidebar() {
    MixpanelService.trackEvent('Notifications: Open sidebar');
    if (this.isSidebarOpen === true) {
      this.isSidebarOpen = false;
    } else {
      this.store.dispatch(new NotificationsGet({ limit: 50, offset: 0 }));
      this.isSidebarOpen = true;
    }
  }

  closeSidebar() {
    MixpanelService.trackEvent('Notifications: Close sidebar');
    if (this.isSidebarOpen === true) {
      this.isSidebarOpen = false;
      this.overlayRef.detach();
      this.store.dispatch(new NotificationsGet({ unread: true, limit: 50, offset: 0 }));
    } else {
      this.isSidebarOpen = true;
    }
  }

  prepareTranslationKeys(meta) {
    const userTimezone = this.userData.timezone;
    const startDateWithTimezone = moment(meta.params.startDate)
      .tz(userTimezone)
      .format('YYYY-MM-DD, H:m');
    return {
      ...meta.keys,
      userName: meta.author.username,
      startDate: this.datePipe.transform(meta.params.startDate, 'dd.MM.yy, HH:mm', this.tz),
    };
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;

    if (this.isClickOnBackdrop(targetElement)) {
      MixpanelService.trackEvent('Notifications: Click on backdrop');

      this.closeSidebar();
    }
  }

  private isClickOnBackdrop(targetElement: HTMLElement): boolean {
    return this.isSidebarOpen && !!targetElement.closest('.cdk-overlay-backdrop');
  }
}
