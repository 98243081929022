<div class="wh-job-msg-wrapper" [ngClass]="{ failed: isFailed }">
  <div>{{ jobType?.toUpperCase() }}</div>
  <div>
    Status: <span class="status">{{ state }}</span>
  </div>

  <a *ngIf="pipeUrl; else pipeTextLabel" [href]="pipeUrl" target="_blank">{{ pipeName }}</a>
  <ng-template #pipeTextLabel>
    <span>{{ pipeName }}</span>
  </ng-template>

  <div class="repository">
    {{ repoFullName }}
  </div>
</div>
