import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { SvgIconComponent } from 'angular-svg-icon';
import { AvatarComponent } from '../../standalone/components/avatar/avatar.component';
import { Store } from '@ngxs/store';
import { SpacesState } from '../../shared/store/states/spaces.state';
import { ProjectsState } from '../../shared/store/states/projects.state';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { QuillModules, QuillViewComponent } from 'ngx-quill';
import { ReactiveFormsModule } from '@angular/forms';
import {
  CalendarEventsGetById,
  CalendarEventsUpdateStatus,
} from '../../shared/store/actions/calendar-events.action';
import { ToastrService } from 'ngx-toastr';
import { AuthState } from '../../shared/store/states/auth.state';
import { CalendarEventsGetListResDto } from '../../api/models';

interface EventData {
  eventId: string;
  title: string;
  start: Date;
  end: Date;
  participants: {
    status: 'invited' | 'accepted' | 'declined' | 'maybe';
    id: string;
  }[];
  description: string;
  reminder: string;
  object: 'users' | 'spaces' | 'projects';
  objectId: string;
  createdBy: string;
}

interface ModalData {
  eventId: string;
}

@Component({
  selector: 'app-calendar-view-event',
  templateUrl: './calendar-view-event.component.html',
  styleUrls: ['./calendar-view-event.component.scss'],
  standalone: true,
  imports: [
    SvgIconComponent,
    CommonModule,
    TranslocoModule,
    CdkAccordionModule,
    AvatarComponent,
    NgScrollbarModule,
    QuillViewComponent,
    ReactiveFormsModule,
  ],
})
export class CalendarViewEventComponent implements OnInit {
  modalData: ModalData;
  eventData: EventData;
  isLoading = false;

  private readonly ICON_BASE_URL = 'assets/icons/calendar';

  private readonly currentUser = this.store.selectSnapshot(AuthState.getUser);
  quillEditorModules: QuillModules = {
    toolbar: false,
    magicUrl: true,
  };

  constructor(
    private readonly activeModal: NgbActiveModal,
    private store: Store,
    private readonly toastrService: ToastrService,
    private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    if (!this.modalData.eventId) {
      this.activeModal.dismiss();
      this.toastrService.error(
        this.translocoService.translate('modals.calendar-event-view.errors.event-id-is-required'),
      );
    }

    this.isLoading = true;

    this.store.dispatch(new CalendarEventsGetById({ id: this.modalData.eventId })).subscribe({
      next: ({
        CalendarEvents: { event },
      }: {
        CalendarEvents: { event: CalendarEventsGetListResDto };
      }) => {
        this.eventData = {
          eventId: event._id,
          title: event.title,
          createdBy: event.userName,
          description: event.description,
          start: new Date(event.start),
          end: new Date(event.end),
          object: event.object as 'users' | 'spaces' | 'projects',
          objectId: event.objectId,
          participants: event.calendarEventMembers.map((member) => ({
            id: member.userId,
            status: member.status as 'accepted' | 'invited' | 'declined' | 'maybe',
          })),
          reminder: event.reminder,
        };

        this.isLoading = false;
      },
      error: (err) => {
        this.toastrService.error(
          this.translocoService.translate('modals.calendar-event-view.errors.event-unable-to-get'),
        );
        this.isLoading = false;
      },
    });
  }

  close(saved?: boolean) {
    this.activeModal.close();
  }

  getParticipantsByIsAttending(
    status: 'invited' | 'accepted' | 'declined' | 'maybe',
  ): { status: 'accepted' | 'invited' | 'declined' | 'maybe'; id: string }[] {
    return this.eventData.participants.filter((participant) => participant.status === status);
  }

  getObjectName(): string {
    if (this.eventData.object === 'users') {
      return 'My Space';
    } else if (this.eventData.object === 'spaces') {
      const space = this.store.selectSnapshot(SpacesState.getSpace)(this.eventData.objectId);

      return space?.spaceName;
    } else if (this.eventData.object === 'projects') {
      const project = this.store.selectSnapshot(ProjectsState.getProject)(this.eventData.objectId);

      return project?.projectName;
    }
  }

  getIcon(name: string): string {
    return `${this.ICON_BASE_URL}/${name}.svg`;
  }

  makeUserAttendEvent(status: 'accepted' | 'declined') {
    this.store
      .dispatch(
        new CalendarEventsUpdateStatus({
          id: this.eventData.eventId,
          status,
        }),
      )
      .subscribe({
        next: () => {
          this.eventData.participants = this.eventData.participants.map((participant) => {
            if (participant.id === this.currentUser._id) {
              return {
                ...participant,
                status,
              };
            }

            return participant;
          });
        },
        error: (err) => {
          this.toastrService.error(
            this.translocoService.translate('modals.calendar-event-view.could-not-update-event'),
          );
        },
      });
  }
}
