export default class DragAndDropUtils {
  public static moveItemInArray<T>(arr: Array<T>, oldIndex: number, newIndex: number): Array<T> {
    if (newIndex >= arr.length) {
      let k: number = newIndex - (arr.length + 1);

      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  }
}
