import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Sort, MatSort, MatSortHeader } from '@angular/material/sort';
import { NgScrollbar } from 'ngx-scrollbar';
import { ProjectService } from '../../services/project.service';
import { SpaceService } from '../../services/space.service';
import {
  MatTableDataSource,
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow,
} from '@angular/material/table';
import { ArchiveModalComponent } from '../../../modals/archive-modal/archive-modal.component';
import {
  NgbModal,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../../../pages/full-pages/manage-spaces/services/data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { SpacesState } from '../../store/states/spaces.state';
import { ConfigService } from '../../services/config.service';
import { ToastrService } from 'ngx-toastr';
import { AuthState } from '../../store/states/auth.state';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { CheckPermissionPipe } from '../../pipes/check-permission.pipe';
import { AvatarComponent } from '../../../standalone/components/avatar/avatar.component';
import { SpaceAvatarComponent } from '../space-projects/space-avatar/space-avatar.component';
import { ProjectAvatarComponent } from '../space-projects/project-avatar/project-avatar.component';
import { SvgComponent } from '../../svgs/svg/svg.component';
import { MatIcon } from '@angular/material/icon';
import { NgFor, NgIf } from '@angular/common';

export interface IElement {
  name: string;
  projects: Array<IElement>;
  teammates: number;
  prefix?: number;
  owner: string;
  ownerId: string;
  objectId: string;
  isExpanded: boolean;
  spaceId: string;
  ownerName?: string;
  isArchive: boolean;
  isAccess?: boolean;
  _id: string;
}

interface ExpandedElement {
  spaceId: string;
  projects: MatTableDataSource<IElement>;
}

@Component({
  selector: 'app-table',
  templateUrl: 'table.component.html',
  styleUrls: ['table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgScrollbar,
    MatTable,
    MatSort,
    NgFor,
    MatColumnDef,
    NgIf,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatIcon,
    NgbDropdown,
    SvgComponent,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbTooltip,
    ProjectAvatarComponent,
    SpaceAvatarComponent,
    forwardRef(() => AvatarComponent),
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    CheckPermissionPipe,
  ],
})
export class TableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('scrollableTable') scrollbarRef: NgScrollbar; // Get scrollbar component reference

  @Input() dataSource: Array<IElement>;
  @Input() archive = false;
  @Input() isProject = false;
  projectsDisplay = this.translocoService.translate('menu-item-projects');
  teammatesDisplay = this.translocoService.translate('modals.space.details-teammates');
  ownerDisplay = this.translocoService.translate('manage-spaces.owner');
  prefixDisplay = this.translocoService.translate('manage-spaces.prefix');
  sortedData: MatTableDataSource<IElement>;
  expandedElements: Array<ExpandedElement> = [];
  columnsToDisplay: Array<string> = ['expand', 'name', 'projects', 'teammates', 'owner', 'actions'];
  columnsForExpandToDisplay: Array<string> = ['name', 'prefix', 'teammates', 'owner', 'actions'];
  currentColumnsToDisplay: Array<string> = [];
  actionsDropdownOpen = false;
  activeUnarchiveProject = false;
  destroy$ = new Subject<void>();
  public config: any = {};

  constructor(
    private dataService: DataService,
    public projectService: ProjectService,
    public spaceService: SpaceService,
    public modalService: NgbModal,
    private store: Store,
    private translocoService: TranslocoService,
    protected configService: ConfigService,
    protected toastrService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.config = this.configService.templateConf;
    this.sortedData = new MatTableDataSource(this.dataSource.slice());
    this.mergeDataSourceAndSortedData();
    this.currentColumnsToDisplay = this.isProject
      ? this.columnsForExpandToDisplay
      : this.columnsToDisplay;
    this.dataService
      .getSearchText()
      .pipe(takeUntil(this.destroy$))
      .subscribe((searchValue) => {
        const filter = searchValue?.toLowerCase() || '';

        this.expandedElements.forEach((expandedElement) => {
          expandedElement.projects.filterPredicate = this.filterPredicateForProjects;
          expandedElement.projects.filter = filter;
        });

        if (this.sortedData?.data.length) {
          this.sortedData.filterPredicate = !this.isProject
            ? this.filterPredicateForSpaces
            : this.filterPredicateForProjects;
          this.sortedData.filter = filter;

          if (!searchValue) {
            this.sortedData = new MatTableDataSource(
              this.dataSource
                .map((element) => {
                  if (!this.isProject) {
                    return {
                      ...element,
                      isExpanded: !!this.expandedElements.find(
                        (expandedElement) => expandedElement.spaceId === element._id,
                      ),
                    };
                  }
                  return element;
                })
                .slice(),
            );
          }
        }
      });
  }

  ngOnChanges(): void {
    this.mergeDataSourceAndSortedData();
  }

  mergeDataSourceAndSortedData() {
    if (this.sortedData?.data) {
      this.sortedData = new MatTableDataSource(
        this.dataSource.reduce((acc, element) => {
          const elementFromSortedData = this.sortedData.data.find((elementSorted) => {
            return elementSorted._id === element._id;
          });

          const isFoundElementsInAcc = !!acc.filter((item) =>
            this.isProject
              ? item._id === element._id || item._id === elementFromSortedData?._id
              : !!item.projects.filter(
                  (project) =>
                    !!elementFromSortedData?.projects.filter((pr) => pr._id === project._id)
                      .length || !!element.projects.filter((pr) => pr._id === project._id).length,
                ).length,
          ).length;

          if (elementFromSortedData && !isFoundElementsInAcc) {
            if (
              elementFromSortedData.projects.length > element.projects.length &&
              !this.isProject
            ) {
              const projects = elementFromSortedData.projects.filter((projectSorted) =>
                element.projects.find((project) => project._id === projectSorted._id),
              );
              if (this.expandedElements.length) {
                this.expandedElements = this.expandedElements.map(
                  (expandElement): ExpandedElement => {
                    if (expandElement.spaceId === element._id) {
                      return {
                        spaceId: element._id,
                        projects: new MatTableDataSource(projects),
                      };
                    }
                    return expandElement;
                  },
                );
              }
              acc.push({ ...elementFromSortedData, ...element, projects });
            } else {
              if (!this.isProject) {
                acc.push({
                  ...elementFromSortedData,
                  ...element,
                });
                if (elementFromSortedData.isExpanded) {
                  this.expandedElements = this.expandedElements.map(
                    (expandElement): ExpandedElement => {
                      if (
                        expandElement.spaceId === element._id &&
                        expandElement.projects.data.length !== element.projects.length
                      ) {
                        return {
                          spaceId: element._id,
                          projects: new MatTableDataSource(element.projects),
                        };
                      }
                      return expandElement;
                    },
                  );
                }
              } else {
                acc.push({ ...elementFromSortedData, ...element });
              }
            }
          } else {
            acc.push(element);
          }

          return acc;
        }, [] as Array<IElement>),
      );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  actionsDropdownToggle(e: boolean): void {
    this.actionsDropdownOpen = e;
  }

  changeExpandedElement(element: IElement): void {
    if (!this.isProject) {
      if (!element.isExpanded) {
        this.expandedElements.push({
          spaceId: element._id,
          projects: new MatTableDataSource(element.projects),
        });
      } else {
        const expandedElementToDelete = this.expandedElements.find(
          (expandedElement) => expandedElement.spaceId === element._id,
        );
        const indexOfSpace = this.expandedElements.indexOf(expandedElementToDelete);
        this.expandedElements.splice(indexOfSpace, 1);
      }
      this.sortedData = new MatTableDataSource(
        (this.sortedData.filteredData || this.sortedData.data).map((space: IElement) => {
          if (space._id === element._id) {
            return {
              ...space,
              isExpanded: !space.isExpanded,
            };
          }
          return space;
        }),
      );
    }
  }

  detectProjectSpace(project: any) {
    if (this.archive && project.spaceId) {
      this.activeUnarchiveProject = false;

      this.store
        .select(SpacesState.getArchiveSpaces)
        .pipe(takeUntil(this.destroy$))
        .subscribe((spaces) => {
          this.activeUnarchiveProject = spaces.some((space) => space._id === project.spaceId);
        });
    }
  }

  isExpanded(space: IElement): string {
    return space.isExpanded ? 'expanded' : 'collapsed';
  }
  getSortedProjectsBySpace(space: IElement): MatTableDataSource<IElement> | [] {
    return (
      this.expandedElements.find((expandedElement) => expandedElement?.spaceId === space._id)
        ?.projects || []
    );
  }

  sortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      return;
    }

    if (!this.isProject) {
      this.sortedData = new MatTableDataSource(
        this.sortedData.data.sort((a: IElement, b: IElement) => {
          const isAsc = sort.direction === 'asc';
          switch (sort.active) {
            case 'name':
              return this.compare(a.name, b.name, isAsc);
            case 'projects':
              return this.compare(a.projects.length || 0, b.projects.length, isAsc);
            case 'teammates':
              return this.compare(a.teammates, b.teammates, isAsc);
            case 'owner':
              return this.compare(a.owner, b.owner, isAsc);
            default:
              return 0;
          }
        }),
      );
    } else {
      this.sortedData = this.sortProjects(sort, this.sortedData as MatTableDataSource<IElement>);
    }
  }

  public get permissionStatus() {
    if (this.isProject) {
      return 'project';
    } else {
      return 'spaces';
    }
  }
  public get permissionName() {
    if (this.isProject) {
      return 'projects';
    } else {
      return 'spaces';
    }
  }

  sortExpandedData(sort: Sort, element: IElement) {
    if (!this.isProject) {
      if (!sort.active || sort.direction === '') {
        return;
      }

      this.expandedElements = this.expandedElements.map((expandedElement) => {
        if (expandedElement.spaceId === element._id) {
          return {
            spaceId: element._id,
            projects: this.sortProjects(sort, expandedElement.projects),
          };
        }
        return expandedElement;
      });
    }
  }

  sortProjects(sort: Sort, projects: MatTableDataSource<IElement>): MatTableDataSource<IElement> {
    return new MatTableDataSource(
      projects.data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'name':
            return this.compare(a.name, b.name, isAsc);
          case 'prefix':
            return this.compare(a.prefix || 0, b.prefix, isAsc);
          case 'teammates':
            return this.compare(a.teammates, b.teammates, isAsc);
          case 'owner':
            return this.compare(a.ownerName, b.ownerName, isAsc);
          default:
            return 0;
        }
      }),
    );
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  handlerArchiveSpaceModal(space) {
    const modalRef = this.modalService.open(ArchiveModalComponent, {
      size: 'md',
      centered: true,
    });
    modalRef.componentInstance.pageData = {
      space: space,
      type: 'space',
    };
  }

  handleCheckOwner(owner) {
    const user = this.store.selectSnapshot(AuthState.getUser);
    return owner.ownerId === user._id;
  }

  handlerDeleteSpaceModal(space) {
    const modalRef = this.modalService.open(ArchiveModalComponent, {
      size: 'md',
      centered: true,
    });
    modalRef.componentInstance.pageData = {
      space: space,
      type: 'space',
      delete: true,
    };
  }

  handlerArchiveProjectModal(project) {
    const modalRef = this.modalService.open(ArchiveModalComponent, {
      size: 'md',
      centered: true,
    });
    modalRef.componentInstance.pageData = {
      project: project,
      type: 'project',
    };
  }

  handlerDeleteProjectModal(project) {
    const modalRef = this.modalService.open(ArchiveModalComponent, {
      size: 'md',
      centered: true,
    });
    modalRef.componentInstance.pageData = {
      project: project,
      type: 'project',
      delete: true,
    };
  }

  filterPredicateForProjects(projectElement: IElement, filter) {
    return projectElement.name.toLowerCase().trim().includes(filter);
  }

  filterPredicateForSpaces(space: IElement, filter) {
    return (
      (space.projects.length &&
        space.projects.some((project) => project.name.toLowerCase().trim().includes(filter))) ||
      space.name.toLowerCase().trim().includes(filter)
    );
  }
}
