export const LockedFields = {
  Assignees: 'ticketMembers',
  'Due date': 'dueDate',
  Labels: 'labels',
  Epics: 'epicId',
  Estimation: 'estimate',
  'Release version': 'releaseVersion',
  Parent: 'parentId',
  'Start date': 'startDate',
  'Start date reminder': 'startDateReminder',
};

export enum DefaultFields {
  Assignees = 'Assignees',
  DueDate = 'Due date',
  StartDate = 'StartDate',
  StartDateReminder = 'Start date reminder',
  Labels = 'Labels',
  Epics = 'Epics',
  Estimation = 'Estimation',
  Release = 'Release version',
  Parent = 'Parent',
}
