import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { RoutersConst } from './const/routers.const';
import { AuthState } from '../store/states/auth.state';
import { UrlHelper } from '../utils/url-helper';

export interface UploadFile extends File {
  imageBase64String?: string;
  progress?: number;
  error?: boolean;
}

interface ThreadData {
  threadId?: string;
  messageId?: string;
  linkObject?: string;
  linkObjectId?: string;
}

interface UploadProps {
  file: UploadFile;
  url: string;
  authToken: string;
  chatId?: string;
  text?: string;
  threadData?: ThreadData;
}

interface IUploadFile extends UploadFile {
  index?: number;
}

@Injectable({
  providedIn: 'root',
})
export class UploadFileService {
  public files: BehaviorSubject<IUploadFile[]> = new BehaviorSubject([]);
  public clearChat = new Subject<void>();
  public changedFile = new Subject<void>();
  private apiUrl = environment.api_root;
  private limitedNumberOfFiles = 10;

  constructor(
    private store: Store,
    private http: HttpClient,
    private urlHelper: UrlHelper,
  ) {}

  public setFiles(files: File[]): void {
    let limitedNumberOfFiles = files;
    if (files?.length) {
      // prevFiles - files already in state
      const prevFiles = this.files.value;
      // map index to find file when delete it and remove all out limit
      limitedNumberOfFiles = [...prevFiles, ...files]
        .map((el, i) => {
          return Object.assign(el, { index: i });
        })
        .slice(0, this.limitedNumberOfFiles);
    }
    this.files.next(limitedNumberOfFiles);
  }

  public deleteFile(file: IUploadFile): void {
    const updatedFiles = this.files.value.filter((fileUpload) => fileUpload.index !== file.index);
    this.files.next(updatedFiles);
  }

  public async sendFiles(payload: {
    text?: string;
    chatId?: string;
    threadData?: ThreadData;
    apiUrl?: string;
  }): Promise<void> {
    const authToken = 'Bearer ' + this.store.selectSnapshot(AuthState.getAccessToken);
    this.apiUrl = payload.apiUrl || this.apiUrl;
    const url = this.apiUrl + (payload.chatId ? RoutersConst.ChatUrl : RoutersConst.ThreadUrl);
    const files = this.files.value.map((file) => {
      file.error = false;
      file.progress = 0;
      return file;
    });

    if (payload?.text?.trim() && payload?.text?.includes('http')) {
      const ticketSeparator = 'ticket=';
      const wikiSeparator = '/wiki/';
      const ticketIds = this.urlHelper.getIdsFromTextMessage(payload.text, ticketSeparator);
      const wikiPagesIds = this.urlHelper.getIdsFromTextMessage(payload.text, wikiSeparator);
      if (ticketIds?.length || wikiPagesIds?.length) {
        // change upload message behavior if we have ticket or wiki url in the text
        await this.sendRequest({
          file: null,
          chatId: payload.chatId,
          threadData: payload.threadData,
          url,
          authToken,
          text: payload.text,
        });
        this.clearChat.next();
        payload.text = null;
      }
    }

    for (let i = 0; i < files.length; i++) {
      await this.sendRequest({
        file: files[i],
        chatId: payload.chatId,
        threadData: payload.threadData,
        url,
        authToken,
        text: i === 0 && payload.text,
      });
    }

    const withoutError = this.files.value?.filter((file: any) => file?.error);
    this.files.next(withoutError);
  }

  private async sendRequest(payload: UploadProps): Promise<void> {
    const formData = new FormData();
    if (payload.file) {
      formData.append('file', payload.file);
    }

    if (payload.chatId) {
      formData.append('chatId', payload.chatId);
    }

    if (payload.threadData) {
      formData.append('messageId', payload.threadData?.messageId);
      formData.append('threadId', payload.threadData?.threadId);

      if (payload.threadData.linkObject) {
        formData.append('linkObject', payload.threadData.linkObject);
        formData.append('linkObjectId', payload.threadData.linkObjectId);
      }
    }

    if (payload.text) {
      formData.append('text', payload.text);
    }

    const headers = new HttpHeaders().set('Authorization', payload.authToken);
    const request = new HttpRequest('POST', payload.url, formData, {
      headers: headers,
      reportProgress: true,
    });

    try {
      await this.http
        .request(request)
        .pipe(
          map((event) => {
            if (payload.file) {
              if (event.type === HttpEventType.UploadProgress) {
                const progress = Math.round((100 * event.loaded) / event.total);
                payload.file.progress = progress < 85 ? progress : 85;
              }
              if (event.type === HttpEventType.Response) {
                this.clearChat.next();
                payload.file.progress = 100;
              }
              this.changedFile.next();
            }
          }),
        )
        .toPromise();
    } catch (e) {
      if (payload.file) {
        payload.file.error = true;
      }
      throwError(e.message);
    }
  }
}
