<div
  class="ticket-coloring"
  [class.dark-customize]="config?.layout.variant === 'Dark'"
  *transloco="let t; read: 'modals.board-ticket'"
>
  <div class="ticket-coloring_wrapper">
    <div class="ticket-coloring_header">
      <span class="ticket-coloring_header-title">{{ t('color-title') }}</span>
      <app-svg
        name="times"
        width="8"
        height="8"
        class="ticket-coloring_header-close"
        (click)="closeCustomizeTicket()"
      ></app-svg>
    </div>
    <div class="ticket-coloring__preview">
      <div class="ticket-coloring__preview-ticket" [style.background-color]="ticket.bgColor">
        <div class="ticket-coloring__preview-ticket-content">
          <span class="ticket-coloring__preview-ticket_title">{{
            ticket.title || t('color-ticket-name') | limitTo: 80
          }}</span>
        </div>
        <div class="ticket-coloring__preview-ticket_labels-tags">
          <div class="ticket-coloring__preview-ticket_labels">
            <ng-container *ngIf="ticket.labels; else emptyLabel">
              <span
                *ngFor="let label of ticket.labels"
                class="badge badge-disabled label-badge mr-1"
              >
                {{ getLabel(label) }}
              </span>
            </ng-container>
            <ng-template #emptyLabel>
              <span class="badge badge-disabled label-badge mr-1">Label</span>
            </ng-template>
          </div>
          <div class="ticket-coloring__preview-ticket_tags">
            <div
              *ngIf="ticket.boardAbbreviation; else emptyTag"
              class="d-flex align-items-center py-1"
            >
              <div *ngIf="ticket.estimateValue" class="badge estimation-badge">
                {{ ticket.estimateValue }} {{ ticket.estimateUnit }}
              </div>
              <div *ngIf="ticket.releaseVersion" class="badge version-badge">
                {{ ticket.releaseVersion }}
              </div>
            </div>
            <ng-template #emptyTag>
              <div class="d-flex align-items-center py-1">
                <div class="badge version-badge">Tag</div>
              </div>
            </ng-template>
          </div>
        </div>
        <div class="ticket-coloring__preview-ticket_prefix">
          {{ ticket.boardAbbreviation || 'D' }}-{{ ticket.counter || '1040' }}
        </div>
      </div>
    </div>

    <!--    <div class="ticket-coloring__apply-to-all">-->
    <!--      <button-->
    <!--        class="btn btn-outline w-100"-->
    <!--        type="button"-->
    <!--        (click)="applyToAllTickets()"-->
    <!--      >Apply to all tickets</button>-->
    <!--    </div>-->

    <div class="ticket-coloring__colors">
      <span class="ticket-coloring_sub-title">{{ t('color-hint') }}</span>
      <div class="ticket-coloring__colors-wrapper">
        <div
          *ngFor="let item of colors"
          class="ticket-coloring__colors-item"
          [style.background-color]="item.color"
          [class.none]="!item.color"
          (click)="onColorSelect(item.color)"
          t-id="customize-ticket-color-picker"
        >
          <app-svg *ngIf="item.checked && item.color !== '#ffffff'" name="check"></app-svg>
        </div>
      </div>
    </div>

    <!--    TODO: Upload image-->
    <!--    <div class="ticket-coloring__image">-->
    <!--      <input type="file" class="ticket-coloring__image-input" (change)="onFileSelected($event)" #fileUpload>-->
    <!--      <span class="ticket-coloring_sub-title">Image</span>-->
    <!--      <div *ngIf="fileToUpload || uploadedImage" class="ticket-coloring__image-upload">-->
    <!--        <button *ngIf="uploadedImage && !fileToUpload; else changeImage" (click)="fileUpload.click()">Upload image</button>-->
    <!--        <ng-template #changeImage>-->
    <!--          <app-svg name="image" width="24" height="24"></app-svg>-->
    <!--          <span>{{ fileToUpload.name }}</span>-->
    <!--          <div class="ticket-coloring__image-actions">-->
    <!--            <button (click)="fileUpload.click()">Change image</button>-->
    <!--            <button (click)="removeImage()">Remove image</button>-->
    <!--          </div>-->
    <!--        </ng-template>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</div>
