import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { LaneBoardStateModel } from '../models/LaneBoardState';
import { LaneBoardsService } from '../../../api/services/lane-boards.service';
import {
  LaneBoardColumnCreate,
  LaneBoardColumnDelete,
  LaneBoardColumnUpdate,
  LaneBoardColumnLocalUpdate,
  LaneBoardColumnUpdateOrders,
  LaneBoardCreate,
  LaneBoardLocalUpdate,
  LaneBoardGetById,
  LaneBoardUpdate,
  LaneBoardCardCreate,
  LaneBoardCardUpdate,
  LaneBoardCardDelete,
  LaneBoardCardLocalUpdate,
  LaneBoardCardVoteUpdate,
  LaneBoardSocketUpdate,
  LaneBoardCardLocalUpdateOrder,
  LaneBoardCardRebuildColors,
} from '../actions/lane-board.action';

@State<LaneBoardStateModel>({
  name: 'LaneBoard',
  defaults: {
    _id: '',
    object: '',
    objectId: '',
    tenantName: '',
    title: '',
    ownerUserId: null,
    isActive: true,
    showNames: true,
    showContent: true,
    showVotes: true,
    created_at: null,
    updated_at: null,
    columns: [],
    cards: [],
  },
})
@Injectable()
export class LaneBoardState {
  /**
   * Get notes-board
   * @param  {LaneBoardStateModel} state
   */
  @Selector()
  static getLaneBoard(state: LaneBoardStateModel) {
    return state;
  }

  /**
   * Get notes-board settings
   * @param  {LaneBoardStateModel} state
   */
  @Selector()
  static getLaneBoardSettings({ showNames, showContent, showVotes }: LaneBoardStateModel) {
    return {
      showNames,
      showContent,
      showVotes,
    };
  }

  /**
   * Get notes-board title
   * @param  {LaneBoardStateModel} state
   */
  @Selector()
  static getLaneBoardTitle(state: LaneBoardStateModel) {
    return state.title;
  }

  /**
   * Get notes-board columns
   * @param  {LaneBoardStateModel} state
   */
  @Selector()
  static getLaneBoardColumns(state: LaneBoardStateModel) {
    return state.columns.filter((column) => !column.isDeleted);
  }

  /**
   * Get notes-board columns
   * @param  {LaneBoardStateModel} state
   */
  @Selector()
  static getLaneBoardColumnsAndCards(state: LaneBoardStateModel) {
    return {
      columns: state.columns.filter((column) => !column.isDeleted),
      cards: state.cards,
      creatorId: state.ownerUserId,
    };
  }

  /**
   * Get notes-board object info
   * @param  {LaneBoardStateModel} state
   */
  @Selector()
  static getLaneBoardObjectInfo(state: LaneBoardStateModel) {
    return { object: state.object, objectId: state.objectId };
  }

  /**
   * Get notes-board id
   * @param  {LaneBoardStateModel} state
   */
  @Selector()
  static getLaneBoardId(state: LaneBoardStateModel) {
    return state._id;
  }

  /**
   * Get notes board owner id
   * @param  {LaneBoardStateModel} state
   */
  @Selector()
  static getLaneOwnerId(state: LaneBoardStateModel) {
    return state.ownerUserId;
  }

  constructor(private laneBoardsService: LaneBoardsService) {}

  /**
   * Get by id notes-board request
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {LaneBoardGetById} action
   */
  @Action(LaneBoardGetById)
  get_lane_board_by_id(
    { patchState, dispatch }: StateContext<LaneBoardStateModel>,
    action: LaneBoardGetById,
  ) {
    return this.laneBoardsService
      .laneBoardsControllerLaneBoardsGetById({ id: action.payload })
      .pipe(
        tap((board) => {
          patchState(board);
          dispatch(LaneBoardCardRebuildColors);
        }),
      );
  }

  /**
   * Rebuild cards color
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {LaneBoardCardRebuildColors} action
   */
  @Action(LaneBoardCardRebuildColors)
  rebuild_cards_colors(
    { patchState, getState }: StateContext<LaneBoardStateModel>,
    action: LaneBoardCardRebuildColors,
  ) {
    const { cards, columns } = getState();
    const updatedCards = cards.map((card) => {
      const foundColumn = columns.find((column) => column._id === card.laneBoardColumnId);
      return foundColumn ? { ...card, ...{ color: foundColumn.color } } : card;
    });

    patchState({ cards: updatedCards });
  }

  /**
   * Create notes-board request
   * @param  {setState}: StateContext<BoardsStateModel>
   * @param  {LaneBoardCreate} action
   */
  @Action(LaneBoardCreate)
  create_lane_board({ setState }: StateContext<LaneBoardStateModel>, action: LaneBoardCreate) {
    return this.laneBoardsService
      .laneBoardsControllerLaneBoardsCreate({ body: action.payload })
      .pipe(
        tap((createdBoard) => {
          setState(createdBoard as unknown as LaneBoardStateModel);
        }),
      );
  }

  /**
   * Create notes-board request
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {LaneBoardUpdate} action
   */
  @Action(LaneBoardUpdate)
  update_lane_board(
    { patchState, getState }: StateContext<LaneBoardStateModel>,
    action: LaneBoardUpdate,
  ) {
    const state = getState();

    const payload = {
      id: state._id,
      needToPublish: action.needToPublish,
      body: action.payload,
    };

    return this.laneBoardsService.laneBoardsControllerLaneBoardsUpdate(payload).pipe(
      tap((updatedBoard) => {
        patchState(updatedBoard);
      }),
    );
  }

  /**
   * Create notes-board column request
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {LaneBoardColumnCreate} action
   */
  @Action(LaneBoardColumnCreate)
  create_lane_board_column(
    { patchState, getState }: StateContext<LaneBoardStateModel>,
    action: LaneBoardColumnCreate,
  ) {
    const state = getState();
    const payload = {
      id: state._id,
      body: {
        object: state.object,
        objectId: state.objectId,
        title: 'ENTER NAME',
      },
    };

    return this.laneBoardsService.columnsCreate_1(payload).pipe(
      tap((newColumn) => {
        patchState({ columns: [...state.columns, newColumn] });
      }),
    );
  }

  /**
   * Delete lane-board column request
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {LaneBoardColumnDelete} action
   */
  @Action(LaneBoardColumnDelete)
  delete_lane_board_column(
    { patchState, getState }: StateContext<LaneBoardStateModel>,
    action: LaneBoardColumnDelete,
  ) {
    const state = getState();

    return this.laneBoardsService.columnsDelete_1(action.payload).pipe(
      tap((deletedColumn) => {
        const filteredColumns = state.columns.filter((column) => column._id !== deletedColumn._id);
        patchState({ columns: filteredColumns });
      }),
    );
  }

  /**
   * Update lane-board column request
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {LaneBoardColumnUpdate} action
   */
  @Action(LaneBoardColumnUpdate)
  update_lane_board_column(
    { patchState, getState, dispatch }: StateContext<LaneBoardStateModel>,
    action: LaneBoardColumnUpdate,
  ) {
    const state = getState();
    const { id, columnId, ...body } = action.payload;

    let currentColumn = state.columns.find((column) => column._id === columnId);
    currentColumn = { ...currentColumn, ...body };

    const payload = {
      id,
      columnId,
      body: currentColumn,
    };

    return this.laneBoardsService.laneBoardsControllerUpdateLaneBoardColumn(payload).pipe(
      tap((updatedColumn) => {
        const updatedColumns = state.columns.map((column) =>
          column._id === columnId ? updatedColumn : column,
        );
        patchState({ columns: updatedColumns });
        dispatch(LaneBoardCardRebuildColors);
      }),
    );
  }

  /**
   * Local Update lane-board state
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {LaneBoardSocketUpdate} action
   */
  @Action(LaneBoardSocketUpdate)
  lane_board_socket_update(
    { patchState }: StateContext<LaneBoardStateModel>,
    action: LaneBoardSocketUpdate,
  ) {
    patchState({ ...action.payload });
  }

  /**
   * Local Update lane-board
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {LaneBoardLocalUpdate} action
   */
  @Action(LaneBoardLocalUpdate)
  local_update_lane_board(
    { patchState, getState }: StateContext<LaneBoardStateModel>,
    action: LaneBoardLocalUpdate,
  ) {
    const state = getState();

    patchState({ ...state, ...action.payload });
  }

  /**
   * Local Update lane-board column
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {LaneBoardColumnLocalUpdate} action
   */
  @Action(LaneBoardColumnLocalUpdate)
  local_update_lane_board_column(
    { patchState, getState, dispatch }: StateContext<LaneBoardStateModel>,
    action: LaneBoardColumnLocalUpdate,
  ) {
    const state = getState();
    const { columnId, ...body } = action.payload;
    const updatedColumns = state.columns.map((column) =>
      column._id === columnId ? { ...column, ...body } : column,
    );

    patchState({ columns: updatedColumns });
    dispatch(LaneBoardCardRebuildColors);
  }

  /**
   * Local Update orders lane-board column
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {LaneBoardColumnUpdateOrders} action
   */
  @Action(LaneBoardColumnUpdateOrders)
  local_update_orders_lane_board_column(
    { patchState, getState }: StateContext<LaneBoardStateModel>,
    action: LaneBoardColumnUpdateOrders,
  ) {
    patchState({ columns: action.payload });
  }

  /**
   * Create card notes board
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {LaneBoardCardCreate} action
   */
  @Action(LaneBoardCardCreate)
  lane_board_card_create(
    { patchState, getState, dispatch }: StateContext<LaneBoardStateModel>,
    action: LaneBoardCardCreate,
  ) {
    const state = getState();
    const payload = {
      id: state._id,
      body: {
        object: state.object,
        objectId: state.objectId,
        title: '',
        laneBoardColumnId: action.payload.columnId,
        isBlocked: false,
        order: action.payload.order,
        votes: [],
      },
    };
    return this.laneBoardsService.laneBoardsControllerCreateLaneBoardCard(payload).pipe(
      tap((card) => {
        const updatedCard = { ...card, ...{ editMode: true } };
        patchState({ cards: [...state.cards, updatedCard] });
        dispatch(LaneBoardCardRebuildColors);
      }),
    );
  }

  /**
   * Update card notes board
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {LaneBoardCardUpdate} action
   */
  @Action(LaneBoardCardUpdate)
  lane_board_card_update(
    { patchState, getState, dispatch }: StateContext<LaneBoardStateModel>,
    action: LaneBoardCardUpdate,
  ) {
    const state = getState();
    const payload = {
      id: state._id,
      cardId: action.payload._id,
      body: action.payload,
    };

    return this.laneBoardsService.laneBoardsControllerUpdateLaneBoardCard(payload).pipe(
      tap((resCard) => {
        patchState({
          cards: state.cards.map((card) => (card._id === resCard._id ? resCard : card)),
        });
        dispatch(LaneBoardCardRebuildColors);
      }),
    );
  }

  /**
   * Update card vote notes board
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {LaneBoardCardVoteUpdate} action
   */
  @Action(LaneBoardCardVoteUpdate)
  lane_board_card_update_vote(
    { patchState, getState, dispatch }: StateContext<LaneBoardStateModel>,
    action: LaneBoardCardVoteUpdate,
  ) {
    const state = getState();
    const payload = {
      id: state._id,
      cardId: action.payload._id,
    };

    return this.laneBoardsService.laneBoardsControllerToggleLaneBoardCardVote(payload).pipe(
      tap((resCard) => {
        patchState({
          cards: state.cards.map((card) => (card._id === resCard._id ? resCard : card)),
        });
        dispatch(LaneBoardCardRebuildColors);
      }),
    );
  }

  /**
   * Update card notes board
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {LaneBoardCardLocalUpdate} action
   */
  @Action(LaneBoardCardLocalUpdate)
  lane_board_card_update_local(
    { patchState, getState }: StateContext<LaneBoardStateModel>,
    action: LaneBoardCardLocalUpdate,
  ) {
    const state = getState();
    patchState({
      cards: state.cards.map((card) => (card._id === action.payload._id ? action.payload : card)),
    });
  }

  /**
   * Update card notes board
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {LaneBoardCardLocalUpdateOrder} action
   */
  @Action(LaneBoardCardLocalUpdateOrder)
  lane_board_card_update_local_order(
    { patchState, getState }: StateContext<LaneBoardStateModel>,
    action: LaneBoardCardLocalUpdate,
  ) {
    const state = getState();
    const calcOrderForCards = action.payload.map((item, index) => {
      item.order = index;
      return item;
    });

    const updatedCards = {
      cards: state.cards.map((card) => {
        const foundCard = calcOrderForCards.find((item) => card._id === item._id);
        return foundCard ?? card;
      }),
    };
    patchState(updatedCards);
  }

  /**
   * Delete card notes board
   * @param  {patchState}: StateContext<BoardsStateModel>
   * @param  {LaneBoardCardDelete} action
   */
  @Action(LaneBoardCardDelete)
  lane_board_card_delete(
    { patchState, getState }: StateContext<LaneBoardStateModel>,
    action: LaneBoardCardDelete,
  ) {
    const state = getState();
    const payload = {
      id: state._id,
      cardId: action.payload._id,
      columnId: action.payload.laneBoardId,
    };

    return this.laneBoardsService.laneBoardsControllerDeleteLaneBoardCard(payload).pipe(
      tap((resCard) => {
        patchState({
          cards: state.cards.filter((card) => card._id !== resCard._id),
        });
      }),
    );
  }
}
