import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContextMenuModel } from '../../interface/ContextMenuModel';
import { TranslocoService } from '@ngneat/transloco';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-pin-context-menu',
  templateUrl: './pin-context-menu.component.html',
  styleUrls: ['./pin-context-menu.component.scss'],
  standalone: true,
  imports: [NgFor],
})
export class PinContextMenuComponent implements OnInit {
  @Input() isPossibleDelete = true;
  @Output() deletePin = new EventEmitter();
  @Output() copyUrlPin = new EventEmitter();
  @Output() editPin = new EventEmitter();
  public contextMenuItems: Array<ContextMenuModel> = [
    {
      menuText: this.translocoService.translate('chat.edit-pin-menu'),
      menuEvent: this.editPinMessage.bind(this),
    },
    {
      menuText: this.translocoService.translate('chat.delete-pin-menu'),
      menuEvent: this.deleteItem.bind(this),
    },
    {
      menuText: this.translocoService.translate('chat.copy-pin-menu'),
      menuEvent: this.copyPin.bind(this),
    },
  ];
  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    if (!this.isPossibleDelete) {
      const deleteIndex = this.contextMenuItems.findIndex((option) => option.menuText === 'Delete');
      const editIndex = this.contextMenuItems.findIndex((option) => option.menuText === 'Edit');
      this.contextMenuItems.splice(deleteIndex, 1);
      this.contextMenuItems.splice(editIndex, 1);
    }
  }

  public deleteItem(): void {
    this.deletePin.emit();
  }

  public copyPin(): void {
    this.copyUrlPin.emit();
  }

  public editPinMessage(): void {
    this.editPin.emit();
  }
}
