<ng-container *transloco="let t; read: 'modals.tenant'">
  <div class="modal-header">
    <button
      t-id="project-create-action"
      (click)="closeModal()"
      type="button"
      class="close"
      aria-label="Close"
    >
      <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-title">
      {{ t('title-edit-tenant') }}
    </div>
    <div class="modal-icon">
      <div class="modal-icon_preview">
        <span *ngIf="!iconPreviewURL">
          {{ tenant.name?.charAt(0).toUpperCase() }}
        </span>
        <img *ngIf="iconPreviewURL" src="{{ iconPreviewURL }}" alt="tenant icon" />
        {{ tenant.name }}
      </div>
      <div class="modal-icon_upload">
        <button
          class="btn btn-solid"
          (click)="avatarImageInput.click()"
          t-id="tenant-update-upload-icon"
        >
          {{ iconPreviewURL ? t('btn-change-icon') : t('btn-upload-icon') }}
        </button>
        <button
          class="btn btn-subtle"
          *ngIf="iconPreviewURL"
          (click)="removeIcon()"
          t-id="tenant-update-remove-button"
        >
          {{ t('btn-remove') }}
        </button>
        <span class="modal-icon_restriction">
          {{ t('size-restriction') }}
        </span>
      </div>
      <div class="modal-icon_descr">
        {{ t('icon-description') }}
      </div>
    </div>
    <div class="modal-guide">
      <h2>{{ t('tenant-icon-guidelines-title') }}</h2>
      <span>
        {{ t('tenant-icon-guidelines', { value: tenant.name }) }}
      </span>
      <br /><br />
      <span>{{ t('icon-tips') }}</span>
      <ul class="modal-tips">
        <li>{{ t('icon-tips-1') }}</li>
        <li>{{ t('icon-tips-2') }}</li>
        <li>{{ t('icon-tips-3') }}</li>
        <li>{{ t('icon-tips-4') }}</li>
      </ul>
    </div>
  </div>

  <div class="modal-footer">
    <button
      [disabled]="isLoading"
      (click)="onSubmit()"
      class="btn btn-solid w-100"
      t-id="tenant-update-save-button"
    >
      {{ t('btn-save') }}
    </button>
  </div>
</ng-container>

<input
  #avatarImageInput
  type="file"
  style="display: none"
  accept=".jpg, .jpeg, .png"
  (change)="iconChange($event)"
  t-id="tenant-update-upload-icon-input"
/>
