import {
  Component,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ElementRef,
  ViewEncapsulation,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Subscription, merge, Observable, fromEvent, combineLatest } from 'rxjs';
import { debounceTime, take, tap } from 'rxjs/operators';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { LocalStorageService } from 'ngx-localstorage';
import { ToastrService } from 'ngx-toastr';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';

import { TicketsDbDto } from '../../../../api/models/tickets-db-dto';
import { TicketsLabelsDbDto } from '../../../../api/models/tickets-labels-db-dto';
import { TicketsMembersDbDto } from '../../../../api/models/tickets-members-db-dto';
import { UsersPublicFieldsResDto } from '../../../../api/models/users-public-fields-res-dto';
import { UsersTicketsGetListResDto } from '../../../../api/models/users-tickets-get-list-res-dto';
import { TruncatePipe } from '../../../pipes/truncate.pipe';
import { ConfirmAlert } from '../../../alerts/alerts';
import { ConfigService } from '../../../services/config.service';
import { SocketsService } from '../../../services/sockets.service';
import { WrappedSocket } from 'ngx-socket-io/src/socket-io.service';
import { RecordService } from '../../../services/record.service';
import {
  TicketsDelete,
  TicketsGetUserList,
  TicketsSocketUpdateUserBoard,
} from '../../../store/actions/board.action';
import { AuthState } from '../../../store/states/auth.state';
import { BoardsState } from '../../../store/states/boards.state';
import { SpacesState } from '../../../store/states/spaces.state';
import { ProjectsState } from '../../../store/states/projects.state';
import { BoardTicketModalComponent } from '../../../../modals/board-ticket/board-ticket.component';
import { PriorityEnum } from '../../../../pages/full-pages/board/kanban-board/enums/priority.enum';
import { MinimizeService } from '../../../services/minimize.service';
import { DraftService } from '../../../services/draft.service';
import { BoardsService } from '../../../../pages/full-pages/board/boards.service';
import { TicketsFieldsDbDto } from '../../../../api/models/tickets-fields-db-dto';
import { KanbanBoardTicketComponent } from '../../../../pages/full-pages/board/kanban-board/components/kanban-board-ticket/kanban-board-ticket.component';
import { RxFor } from '@rx-angular/template/for';
import { NgSelectModule } from '@ng-select/ng-select';
import { SvgComponent } from '../../../svgs/svg/svg.component';
import { FormsModule } from '@angular/forms';
import { CustomSpinnerComponent } from '../../custom-spinner/custom-spinner.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { NgClass, NgIf, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { MixpanelService } from '../../../../plugins/mixpanel/mixpanel.service';

enum Column {
  Todo = 'todo',
  InWork = 'inWork',
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-user-board',
  templateUrl: './user-board.component.html',
  styleUrls: ['./user-board.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TruncatePipe, BoardsService],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgClass,
    NgScrollbar,
    NgIf,
    CustomSpinnerComponent,
    FormsModule,
    SvgComponent,
    NgSelectModule,
    NgTemplateOutlet,
    RxFor,
    KanbanBoardTicketComponent,
    AsyncPipe,
  ],
})
export class UserBoardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(PerfectScrollbarComponent, { static: false })
  componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: false })
  directiveRef?: PerfectScrollbarDirective;
  @ViewChild('newColumnField') newColumnField: ElementRef<HTMLInputElement>;
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  @ViewChild(NgbDropdown) dropdown: NgbDropdown;

  platform = 'web';
  orientation = 'portrait';
  scrollConfigY = {
    suppressScrollX: true,
    suppressScrollY: false,
    wheelPropagation: true,
  };
  columns = [
    { title: 'TODO', slug: 'todo' },
    { title: 'IN WORK', slug: 'inWork' },
  ];
  columnsData: {};
  columnsDataKeys: string[];
  initTickets: UsersTicketsGetListResDto;
  tickets: UsersTicketsGetListResDto;
  members: TicketsMembersDbDto[];
  user$: Subscription;
  userData: UsersPublicFieldsResDto;
  ticketsMembers: any[];
  spaces: any[] = [];
  projects: any[] = [];
  labels: any[] = [];
  groupedLabels: any[] = [];
  filteredLabels = [];
  searchedText: string;
  boardTickets: any[] = [];
  boards = [];
  isCheckedBoards: any = [];
  newColumn: string;
  addColumnActive: boolean;
  archiveColumnId: string = null;
  MAX_LINED_UP_BOARD_MEMBERS: number;
  MAX_LINED_UP_BOARD_MEMBERS_MOBILE: number;

  fromNavigation = false;
  isOpenedTicket = false;
  isMobile = false;
  isLoading: Observable<boolean>;
  socket: WrappedSocket;
  isBoardEmpty = false;

  orientationMatchMedia = window.matchMedia('(orientation: portrait)');
  customFieldsConfig: Array<TicketsFieldsDbDto>;
  changeEventListener = (e) => {
    this.orientation = e.matches ? 'portrait' : 'landscape';
    this.ref.detectChanges();
  };

  public isDark$ = new BehaviorSubject(false);

  public todoTickets$ = new BehaviorSubject<Array<TicketsDbDto>>([]);
  public inWorkTickets$ = new BehaviorSubject<Array<TicketsDbDto>>([]);
  protected isLoadingSubject = new BehaviorSubject(false);

  constructor(
    protected configService: ConfigService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private socketsService: SocketsService,
    private localStorage: LocalStorageService,
    private minimizeService: MinimizeService,
    private draftService: DraftService,
    public recordService: RecordService,
    public toastr: ToastrService,
    public ref: ChangeDetectorRef,
    readonly store: Store,
    private translocoService: TranslocoService,
  ) {
    this.MAX_LINED_UP_BOARD_MEMBERS = this.configService.MAX_LINED_UP_BOARD_MEMBERS;
    this.MAX_LINED_UP_BOARD_MEMBERS_MOBILE = this.configService.MAX_LINED_UP_BOARD_MEMBERS_MOBILE;
    this.newColumn = '';
    this.addColumnActive = false;
    this.isLoading = this.isLoadingSubject.asObservable();

    this.user$ = this.store
      .select(AuthState.getUser)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (user) => (this.userData = user),
      });
  }

  ngOnInit() {
    this.configService.templateConf$
      .pipe(untilDestroyed(this))
      .subscribe((config) => this.isDark$.next(config.layout.variant === 'Dark'));
    const ticketId = this.activatedRoute.snapshot.queryParams.ticket;
    if (ticketId) {
      this.fromNavigation = true;
    }

    combineLatest([
      this.store.select(SpacesState.getLoadedSpaces),
      this.store.select(ProjectsState.getLoadedProjects),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([spaces, projects]) => {
        const isSpacesBeenChanged = this.checkIfNeedToUpdateBoardsInput(this.spaces, spaces);
        const isProjectsBeenChanged = this.checkIfNeedToUpdateBoardsInput(this.projects, projects);

        this.spaces = spaces;
        this.projects = projects;

        this.getBoards();
        if (isSpacesBeenChanged || isProjectsBeenChanged) {
          this.getBoards();
        }
      });

    this.initBoard();

    this.socket = this.socketsService.get();

    this.store
      .select(BoardsState.getIsOpenedTicket)
      .pipe(untilDestroyed(this))
      .subscribe((res) => (this.isOpenedTicket = res));

    this.initTicketsSocketListeners();
  }

  ngAfterViewInit() {
    this.orientation = screen.availHeight > screen.availWidth ? 'portrait' : 'landscape';
    this.orientationMatchMedia.addEventListener('change', this.changeEventListener);

    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        untilDestroyed(this),
        debounceTime(this.configService.SEARCH_DEBOUNCE_TIME),
        tap((_) => this.SearchTickets(this.searchedText)),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.user$.unsubscribe();

    this.orientationMatchMedia.removeEventListener('change', this.changeEventListener);
  }

  identify(_index: number, item: TicketsDbDto) {
    return `${item._id}${item?.columnId}`;
  }

  initTicketsSocketListeners(): void {
    merge(
      this.socket.fromEvent('notification:send:ticketsCreate'),
      this.socket.fromEvent('notification:send:ticketsUpdate'),
      this.socket.fromEvent('notification:send:ticketsDelete'),
    )
      .pipe(untilDestroyed(this))
      .subscribe((ticket) => this.store.dispatch(new TicketsSocketUpdateUserBoard(ticket)));
  }

  initBoard() {
    this.store
      .dispatch(new TicketsGetUserList({}))
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.initBoardTickets();

          // a part of deep linking
          // it needs to show up a ticket modal if the board url contains a ticket id, e.g. board?ticket=60be031c440d016f58eb3d01
          if (this.fromNavigation && !this.isOpenedTicket) {
            this.openTicket();
          }

          this.isLoadingSubject.next(false);
        },
        (err) => {
          this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
          this.isLoadingSubject.next(false);
        },
      );
  }

  private openTicket(): void {
    let ticket = this.tickets.todo?.find(
      (t: TicketsDbDto) => t._id === this.activatedRoute.snapshot.queryParams.ticket,
    );

    if (!ticket) {
      ticket = this.tickets.inWork?.find(
        (t: TicketsDbDto) => t._id === this.activatedRoute.snapshot.queryParams.ticket,
      );
    }

    if (ticket) {
      this.fromNavigation = false;
      this.editTicket(ticket);
    }
  }

  private initBoardTickets() {
    this.store
      .select(BoardsState.getUserTicketsList)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.initTickets = res;
        this.tickets = res;
        this.labels = res.labels;
        this.groupedLabels = this.getGroupedLabels(res.labels);
        this.customFieldsConfig = res.customFieldsSettings;

        setTimeout(() => {
          this.prepareColumnsData();
          this.ref.markForCheck();
        }, 100);
      });
  }

  translateTitle(slug): string {
    return this.translocoService.translate(`board.${slug}`);
  }

  getBoards() {
    const spacesProjects = [];
    const isCheckedBoards = this.localStorage.get('isCheckedBoards');
    this.isCheckedBoards = isCheckedBoards || [];

    if (!isCheckedBoards) {
      this.spaces.forEach((space) => {
        this.isCheckedBoards.push(space._id);

        this.projects.forEach((project) => {
          if (project.spaceId === space._id) {
            this.isCheckedBoards.push(project._id);
          }
        });
      });

      this.localStorage.set('isCheckedBoards', this.isCheckedBoards);
    }

    this.spaces.forEach((space) => {
      spacesProjects.push({
        ...space,
        title: space.spaceName,
        isChecked: this.isCheckedBoards.includes(space._id),
      });

      this.projects.forEach((project) => {
        if (project.spaceId === space._id) {
          spacesProjects.push({
            ...project,
            title: project.projectName,
            isChecked: this.isCheckedBoards.includes(project._id),
          });
        }
      });
    });

    this.boards = [...spacesProjects];
  }

  getLinkProjectSprint(ticket): string {
    return `/project/${ticket.breadcrumbs.projectId}/board/backlog`;
  }

  getLinkSpaceSprint(ticket): string {
    return `/space/${ticket.breadcrumbs.spaceId}/board/backlog`;
  }

  getGroupedLabels(labels) {
    // @ts-ignore
    const groupedLabels = labels?.reduce((group: any[], item: TicketsLabelsDbDto) => {
      // @ts-ignore
      const { _id, label } = item;
      group[label] = group[label] ?? [];
      group[label].push(_id);
      return group;
    }, {});

    return Object.keys(groupedLabels)?.map((key) => ({
      label: key,
      ids: groupedLabels[key],
    }));
  }

  loadFilteredTickets() {
    if (this.filteredLabels.length === 0) {
      this.tickets = this.initTickets;
    } else if (this.filteredLabels.length > 0) {
      const tmpTickets = {
        todo: [],
        inWork: [],
        labels: [],
        customFieldsSettings: [],
      };
      const tickets = {
        todo: this.initTickets?.todo || [],
        inWork: this.initTickets?.inWork || [],
        labels: this.initTickets?.labels || [],
      };

      let filteredLabels = [];
      this.filteredLabels.forEach((item) => {
        filteredLabels = [...new Set([...filteredLabels, ...item.ids])];
      });

      tickets.todo.forEach((ticket) => {
        if (ticket.labels) {
          ticket.labels.forEach((labelId) => {
            if (filteredLabels.includes(labelId) && !tmpTickets.todo.includes(ticket)) {
              tmpTickets.todo.push(ticket);
            }
          });
        }
      });

      tickets.inWork.forEach((ticket) => {
        if (ticket.labels) {
          ticket.labels.forEach((labelId) => {
            if (filteredLabels.includes(labelId) && !tmpTickets.inWork.includes(ticket)) {
              tmpTickets.inWork.push(ticket);
            }
          });
        }
      });

      this.tickets = tmpTickets;
    }

    this.prepareColumnsData();
  }

  prepareColumnsData() {
    this.columnsData = {};
    this.columnsDataKeys = [];
    this.boardTickets = [];

    const prepareTickets = (tickets: TicketsDbDto[]) =>
      tickets
        .filter((ticket) =>
          this.isCheckedBoards.includes(ticket?.objectId) && ticket.object === 'projects'
            ? this.projects.find((item) => item._id === ticket.objectId)
            : true,
        )
        .map((ticket) => ({
          ...ticket,
          priority:
            !Number.isInteger(ticket?.priority) && ticket.isBlocked
              ? PriorityEnum.Highest
              : !Number.isInteger(ticket?.priority)
                ? PriorityEnum.Medium
                : ticket?.priority,
          breadcrumbs: this.getBreadcrumbs(ticket),
          labels: ticket.labels ? ticket.labels.map((labelId) => this.getLabel(labelId)) : [],
        }));

    const todoTickets = prepareTickets(this.tickets[Column.Todo]);
    const inWorkTickets = prepareTickets(this.tickets[Column.InWork]);

    this.todoTickets$.next(todoTickets);
    this.inWorkTickets$.next(inWorkTickets);

    this.boardTickets = [
      ...JSON.parse(JSON.stringify(todoTickets)),
      ...JSON.parse(JSON.stringify(inWorkTickets)),
    ];

    for (const column of this.columns) {
      const columnData = {
        displayName: column.title,
        tickets: [],
        slug: column.slug,
      };

      Object.defineProperty(this.columnsData, column.title, {
        value: columnData,
        writable: true,
      });

      this.columnsDataKeys.push(column.title);

      this.columnsData[column.title].tickets = [];
    }

    this.isBoardEmpty = this.boardTickets.length === 0;
  }

  getBreadcrumbs(ticket: TicketsDbDto) {
    const project =
      ticket.object === 'projects'
        ? this.projects.find((item) => item._id === ticket.objectId)
        : null;

    const spaceId = ticket.object === 'spaces' ? ticket.objectId : project?.spaceId;

    return {
      spaceId,
      spaceName: this.spaces.find((space) => space._id === spaceId)?.spaceName,
      projectId: project?._id || null,
      projectName: ticket.object === 'projects' ? project?.projectName : null,
    };
  }

  getLabel(labelId) {
    return this.labels.find((label) => label._id === labelId)?.label;
  }

  private getColumnBoardColor(columnId: string): string {
    const columnColor: any = this.columns?.find((col: any) => col._id === columnId);
    return columnColor?.boardColorClass ? columnColor.boardColorClass : '';
  }

  private handleTicketsColumnsProcessing(
    message: string,
    type: string,
    showMessage: boolean = true,
  ): void {
    if (showMessage) {
      this.toastr.clear();
      this.toastr.success(message, type);
    }
  }

  editTicket(ticket: TicketsDbDto) {
    MixpanelService.trackEvent('User Board: edit ticket', { ticketId: ticket._id });
    const draft = this.draftService.isHasActiveDraft(ticket._id);

    if (draft) {
      this.draftService.openTicketOrDraft(draft, {
        id: ticket._id,
        object: ticket.object as 'spaces' | 'projects',
        objectId: ticket.objectId,
        parentId: ticket.parentId,
        boardColorClass: this.getColumnBoardColor(ticket.columnId),
        boardAbbreviation: ticket.boardAbbreviation,
        counter: ticket.counter,
        userBoardTicketOrder: ticket.order,
      });
    } else {
      const modalRef = this.modalService.open(BoardTicketModalComponent, {
        size: 'xl',
        backdrop: 'static',
        scrollable: true,
        keyboard: false,
        beforeDismiss: () => modalRef.componentInstance.closeImagePreview(true),
      });
      modalRef.componentInstance.ticketData = {
        id: ticket._id,
        object: ticket.object,
        objectId: ticket.objectId,
        parentId: ticket.parentId,
        boardColorClass: this.getColumnBoardColor(ticket.columnId),
        boardAbbreviation: ticket.boardAbbreviation,
        counter: ticket.counter,
        userBoardTicketOrder: ticket.order,
      };

      this.minimizeService.minimizeInit(modalRef);
    }
  }

  deleteTicket(ticketId) {
    MixpanelService.trackEvent('User Board: delete ticket', { ticketId });
    let ticket = this.tickets.todo?.find((item) => item._id === ticketId);

    if (!ticket) {
      ticket = this.tickets.inWork?.find((item) => item._id === ticketId);
    }

    if (ticket) {
      ConfirmAlert(`${this.translocoService.translate('alert.ticket-title')} "${ticket.title}"`, {
        platform: this.platform,
      }).then(
        () => {
          this.store
            .dispatch(new TicketsDelete({ ticketDeleteId: ticketId }))
            .pipe(untilDestroyed(this), take(1))
            .subscribe(
              () =>
                this.handleTicketsColumnsProcessing(
                  this.translocoService.translate('toastr.ticket-deleted'),
                  'Success',
                ),
              (err) =>
                this.toastr.error(
                  err.message,
                  this.translocoService.translate('toastr.title-error'),
                ),
            );
        },
        () => {},
      );
    }
  }

  filterTicketsByLabels() {
    this.loadFilteredTickets();
  }

  SearchTickets(text) {
    this.filteredLabels = [];
    this.store
      .dispatch(
        new TicketsGetUserList({
          ...(text?.trim() ? { search: text.trim() } : {}),
        }),
      )
      .pipe(untilDestroyed(this), take(1))
      .subscribe(() => {
        MixpanelService.trackEvent('User Board: search tickets', { text });
        const tickets = this.store.selectSnapshot(BoardsState.getUserTicketsList);
        this.tickets = JSON.parse(JSON.stringify(tickets));

        this.prepareColumnsData();
        this.ref.markForCheck();
      });
  }

  clearSearch() {
    MixpanelService.trackEvent('User Board: clear search');
    this.searchedText = null;
    this.SearchTickets(null);
    this.searchInput.nativeElement.focus();
  }

  changeSelectedBoards() {
    MixpanelService.trackEvent('User Board: change selected boards');
    this.prepareColumnsData();
    this.localStorage.set('isCheckedBoards', this.isCheckedBoards);
  }

  public getSelectedItemsCount(selected: any[], labels: any[]) {
    return selected.filter((s) => labels.map((c) => c._id).includes(s._id)).length;
  }

  public getSelectedLabelsCount(selected: any[], labels: any[]) {
    return selected.filter((s) => labels.map((c) => c.label).includes(s.label)).length;
  }

  private checkIfNeedToUpdateBoardsInput(prevItems: any[], currentItems: any[]): boolean {
    const isNeedToRunTheLogic = !!(prevItems?.length || currentItems?.length);
    let prevItemsToString, currentItemsToString;
    if (isNeedToRunTheLogic) {
      prevItemsToString = JSON.stringify(prevItems);
      currentItemsToString = JSON.stringify(currentItems);
    }

    return prevItemsToString !== currentItemsToString;
  }
}
