import { Directive, ElementRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import tippy, { Instance, Props, Placement } from 'tippy.js';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DOCUMENT } from '@angular/common';

@UntilDestroy({ checkProperties: true })
@Directive({
  selector: '[appTippy]',
  standalone: true,
  exportAs: 'appTippy',
})
export class TippyDirective implements OnInit, OnDestroy {
  @Input() popoverContent: HTMLElement;
  @Input() popoverPlacement: Placement = 'bottom-start';

  private _tippyInstance: Instance<Props>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
  ) {}

  ngOnInit() {
    this.setupTippyInstance();
  }

  private setupTippyInstance() {
    this._tippyInstance = tippy(this.elementRef.nativeElement, {
      content: this.popoverContent,
      appendTo: 'parent',
      placement: this.popoverPlacement,
      trigger: 'click',
      interactive: true,
      offset: [0, 0],
      duration: 0,
    }) as unknown as Instance<Props>;
  }

  close() {
    this._tippyInstance.hide();
  }

  ngOnDestroy() {
    this._tippyInstance.destroy();
  }
}
