<div class="modal-header d-flex align-items-center">
  <div class="d-flex">
    <app-avatar [userId]="imagePreviewMessage.userId" [ngClass]="'mr-1'"></app-avatar>
    <div class="d-flex flex-column">
      <div class="d-flex ml-1">
        <span
          class="message-username image-preview-name"
          [ngClass]="{
            'image-preview-name': platform === 'web',
            'image-preview-name-mobile': platform !== 'web',
            'message-username-light': config?.layout.variant === 'Light',
            'message-username-dark': config?.layout.variant === 'Dark'
          }"
        >
          {{ getFileName() }}
        </span>
        <span
          class="message-username"
          [ngClass]="{
            'message-username-light': config?.layout.variant === 'Light',
            'message-username-dark': config?.layout.variant === 'Dark'
          }"
        >
          .{{ getFileExtension() }}
        </span>
      </div>

      <div *ngIf="!isOpenNotFromTheChat">
        <span
          class="message-username ml-1"
          [ngClass]="{
            'image-username-light': config?.layout.variant === 'Light',
            'image-username-dark': config?.layout.variant === 'Dark'
          }"
        >
          {{ imagePreviewMessage.userName }}
        </span>
        <span
          *ngIf="imagePreviewMessage.created_at !== ''"
          class="message-date ml-1"
          [ngClass]="{
            'message-date-light': config?.layout.variant === 'Light',
            'message-date-dark': config?.layout.variant === 'Dark'
          }"
        >
          <span>{{ imagePreviewMessage.created_at | date: 'dd.MM.yy' : tz }}</span>
          <span> at </span>
          <span>{{ imagePreviewMessage.created_at | date: 'HH:mm' : tz }}</span>
        </span>
      </div>
    </div>
  </div>

  <div class="d-flex align-items-center">
    <i
      class="fa fa-download image-preview-modal-actions mr-2"
      aria-hidden="true"
      (click)="downloadFile.emit(imagePreviewMessage)"
    ></i>
    <i
      *ngIf="!isOpenNotFromTheChat"
      [class.mr-2]="platform === 'web'"
      class="fa fa-folder-open-o image-preview-modal-actions"
      aria-hidden="true"
      (click)="openDataRoomModal.emit(imagePreviewMessage)"
    ></i>

    <span
      *ngIf="platform === 'web'"
      [ngClass]="{
        'image-preview-modal-actions-divider-light': config?.layout.variant === 'Light',
        'image-preview-modal-actions-divider-dark': config?.layout.variant === 'Dark'
      }"
    ></span>
    <div
      *ngIf="!isNote"
      class="pin"
      aria-label="Pin"
      [class.pinned-icon-dark]="config?.layout.variant === 'Dark'"
      [class.pinned-mobile]="platform !== 'web'"
    >
      <ng-container *ngIf="!isLoadingPin; else loaderPin">
        <svg-icon
          *ngIf="!isPinned && isLimitPins"
          class="pin-icon-modal"
          src="assets/icons/pin/pin-modal.svg"
          [applyClass]="true"
          (click)="pinMedia()"
        ></svg-icon>
        <svg-icon
          *ngIf="isPinned && canUnpinImage"
          class="unpin-icon-modal"
          src="assets/icons/pin/unpin-message.svg"
          [applyClass]="true"
          (click)="unPinMedia()"
        ></svg-icon>
      </ng-container>
      <ng-template #loaderPin>
        <i class="fa fa-spinner fa-spin connection_spinner" aria-hidden="true"></i>
      </ng-template>
    </div>
    <app-svg
      name="times"
      width="15"
      height="15"
      aria-hidden="true"
      class="image-preview-modal-actions svg-icon p-2"
      [class.close-mobile]="platform === 'mobile'"
      (click)="closePreviewModal.emit()"
    ></app-svg>
  </div>
</div>

<div class="modal-body">
  <div class="d-flex" [style.max-height.%]="90" [style.height.%]="100">
    <i
      *ngIf="platform === 'web'"
      class="fa fa-chevron-left image-preview-arrows"
      aria-hidden="true"
      (click)="!isFirstPreviewImage && goToPreviousImage.emit()"
      [ngStyle]="{
        visibility: isFirstPreviewImage ? 'hidden' : 'visible',
        'margin-left': '5%'
      }"
    ></i>

    <div [style.height.%]="100">
      <pinch-zoom
        [style.height.%]="100"
        class="d-flex align-items-center justify-content-center bg-transparent"
        [ngClass]="{
          'image-preview-wrapper': platform === 'web',
          'image-preview-wrapper-mobile': platform !== 'web'
        }"
        [minScale]="1"
        [limit-zoom]="10"
      >
        <img class="image-preview" src="{{ imagePreviewMessage.fileData.url }}" />
      </pinch-zoom>
    </div>

    <i
      *ngIf="platform === 'web'"
      class="fa fa-chevron-right image-preview-arrows"
      aria-hidden="true"
      (click)="!isLastPreviewImage && goToNextImage.emit()"
      [ngStyle]="{
        visibility: isLastPreviewImage ? 'hidden' : 'visible',
        'margin-right': '5%'
      }"
    ></i>
  </div>
</div>
