import { AfterViewInit, ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, NgClass } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgSelectModule } from '@ng-select/ng-select';
import { DEFAULT_USER_STATES, DEFAULT_USER_AVAILBLILITY } from './user-state.defaults';
import { TranslocoService, TranslocoDirective, TranslocoPipe } from '@ngneat/transloco';
import { Subject, map, takeUntil } from 'rxjs';
import { DateTimePickerComponent } from '../../../shared/components/date-time-picker/date-time-picker.component';
import { UsersState } from '../../../shared/store/states/users.state';
import { Store } from '@ngxs/store';
import { UpdateUser, UsersEmojiPicker } from '../../../shared/store/actions/users.action';
import { ConfirmAlert } from '../../../shared/alerts/alerts';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji/data/data.interfaces';
import { Emoji } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { ConfigService } from '../../../shared/services/config.service';
import { SvgComponent } from '../../../shared/svgs/svg/svg.component';
import moment from 'moment-timezone';

@Component({
  selector: 'app-user-state',
  templateUrl: './user-state.component.html',
  styleUrls: ['./user-state.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    OverlayModule,
    PortalModule,
    NgScrollbarModule,
    InfiniteScrollModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgClass,
    TranslocoPipe,
    TranslocoDirective,
    DateTimePickerComponent,
    PickerComponent,
    SvgComponent,
  ],
})
export class UserStateComponent implements OnInit, AfterViewInit {
  userForm: FormGroup;
  titleModal: string;
  userStates = DEFAULT_USER_STATES;
  userAvailabilities = DEFAULT_USER_AVAILBLILITY;
  user: any;
  destroy$: Subject<void> = new Subject<void>();
  _id: string;
  platform: string;
  customStateSection: boolean;
  needToHandleScrollPosition: any;
  emojiSearchMode = false;
  emojiRegex: RegExp = /(^|\s)(?::)(.*?)(?=:|$)/;
  emojiSearchResults: EmojiData[] = [];
  emojiMatch: string;
  emojiPickerImage = 'assets/img/emojis/emojis.png';

  isOpenEmojiPicker = false;
  isOpenThreadEmojiPicker = false;
  isEmojiTouched: boolean;
  selectedEmoji: string;
  emojiIsOpen: boolean;
  customRecentEmojis = [];
  darkMode: boolean;
  currTzAbbr = null;
  currTz: string;

  constructor(
    private store: Store,
    private translocoService: TranslocoService,
    private activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    protected cdr: ChangeDetectorRef,
    private configService: ConfigService,
  ) {
    this.customRecentEmojis = this.configService.CUSTOM_RECENT_EMOJIS;
    this.darkMode = this.configService.templateConf.layout.variant !== 'Light';
  }

  ngOnInit() {
    this.translocoService.load('de').pipe(takeUntil(this.destroy$)).subscribe();
    this.translocoService.load('en').pipe(takeUntil(this.destroy$)).subscribe();
    this.translocoService.setActiveLang('en');
    this.customStateSection = false;
    this.titleModal = this.translocoService.translate('user.state_modal_title');
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.userForm = new FormGroup({
      state: new FormControl(''),
      state_end: new FormControl(''),
      state_available: new FormControl(''),
      state_name: new FormControl(''),
      state_icon: new FormControl(''),
    });

    this.store
      .select(UsersState.getUser)
      .pipe(
        takeUntil(this.destroy$),
        map((filterFn) => filterFn(this._id)),
      )
      .subscribe((user) => {
        if (user && user._id) {
          this.user = user;
          this.userForm.patchValue({
            state: user.state,
            state_end: user.state_end,
            state_available: user.state_available,
            state_name: user.state,
            state_icon: user.state_icon,
          });

          if (user.state_available !== null) {
            this.customStateSection = true;
          }

          if (user?.timezone) {
            this.currTz = user.timezone;
            this.currTzAbbr = moment.tz(user.timezone).format('Z').replace(':', '');
          }

          this.selectedEmoji = user.state_icon;
        }
      });

    this.customStateSection = false;
  }

  close() {
    if (this.userForm.dirty) {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.close-modal-subject'),
        text: this.translocoService.translate('alert.close-modal-text'),
        cancelButtonText: this.translocoService.translate('alert.close-modal-btn-close'),
        showDenyButton: true,
        denyButtonText: this.translocoService.translate('alert.close-modal-btn-discard'),
        denyButtonClass: 'btn-subtle',
        confirmButtonText: this.translocoService.translate('alert.close-modal-btn-save'),
        confirmButtonClass: 'btn-solid',
        platform: this.platform,
      }).then(
        (result) => {
          if (result === 'isDenied') {
            this.activeModal.close();
          }
          if (result === 'isConfirmed') {
            this.save();
          }
        },
        () => {},
      );
    } else {
      this.activeModal.close();
    }
  }

  removeState() {
    this.userForm.patchValue({
      state_end: null,
      state: null,
      state_icon: null,
      state_available: null,
    });

    this.store.dispatch(
      new UpdateUser({
        userId: this.user._id,
        body: { state_end: null, state: null, state_icon: null, state_available: null },
      }),
    );

    this.toastrService.success(
      this.translocoService.translate('toastr.your-state-end-is-remove'),
      this.translocoService.translate('toastr.title-success'),
    );
  }

  save() {
    const user = this.userForm.value;

    const status = DEFAULT_USER_STATES.find((state) => state.label === user.state);

    if (status) {
      user.status = status.hex;
    }

    if (user.state_name) {
      user.state = user.state_name;
    }

    if (user.state_available) {
      const status = DEFAULT_USER_AVAILBLILITY.find(
        (state) => state.label === user.state_available,
      );
      user.state_available = status.label;
      user.status = status.hex;
    }

    user.state_icon = this.selectedEmoji;

    this.store
      .dispatch(new UpdateUser({ userId: this.user._id, body: user }))
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.cdr.detectChanges();
          this.toastrService.success(
            this.translocoService.translate('toastr.message-profile-updated'),
            this.translocoService.translate('toastr.title-success'),
          );

          this.activeModal.close();
        },
        (err) => {
          this.toastrService.error(
            err.message,
            this.translocoService.translate('toastr.title-error'),
          );
        },
      );

    this.customStateSection = false;
  }

  toggleCustomState() {
    this.customStateSection = this.customStateSection ? false : true;
  }

  public emojiPickerToggle(): void {
    this.emojiIsOpen = !this.emojiIsOpen;
  }

  public clearEmoji(): void {
    this.isEmojiTouched = true;
    this.selectedEmoji = '';
  }

  public addEmoji(emojiData: { emoji: EmojiData }): void {
    this.isEmojiTouched = true;
    this.selectedEmoji = emojiData?.emoji?.native;
    this.emojiPickerToggle();
  }

  public emojiPickerImageFn: Emoji['backgroundImageFn'] = (_set: string, _sheetSize: number) =>
    this.emojiPickerImage;
}
