<div [formGroup]="form" *ngIf="fieldConfig.isVisible">
  <legend>{{ displayName }} <span *ngIf="fieldConfig.isMandatory">*</span></legend>
  <fieldset class="form-group position-relative m-0">
    <div
      class="d-flex justify-content-between w-100"
      *transloco="let t; read: 'modals.custom-fields'"
    >
      <app-date-time-picker
        t-id="calendar-event-date-picker-stop"
        placeholder="{{ t('placeholder-set-date') }}"
        startOrEnd="start"
        [formControlName]="fieldConfig.title"
        class="date-picker"
        [dateControlOnly]="fieldConfig.type === FieldType.DatePicker"
        [allDayEvent]="isAllDay"
        [withoutDefaultTime]="withoutDefaultTime"
        [mobile]="platform === 'mobile'"
        [mobileCalendarEvent]="platform === 'mobile'"
        [invitedUser]="false"
        [format]="fieldConfig.format"
        [tabIndexes]="[
          tabIndexes[fieldConfig.order].tabIndex,
          tabIndexes[fieldConfig.order].tabIndex
        ]"
        (dateWithoutTime)="datePickedWithoutTime($event)"
        [scrollToDatePicker]="false"
      ></app-date-time-picker>
    </div>
  </fieldset>
</div>
