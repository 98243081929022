<div
  class="customize-fields"
  [class.customize-dark]="config?.layout.variant === 'Dark'"
  *transloco="let t"
>
  <div class="customize-fields_wrapper">
    <div class="customize-fields_header">
      <div class="customize-fields_header-left">
        <span class="customize-fields_header-title">{{
          t('modals.page-details.title-customize-ticket-fields')
        }}</span>
        <span class="customize-fields_header-subtitle">{{
          t('modals.page-details.subtitle-customize-ticket-fields', {
            value: countCustomFields
          })
        }}</span>
      </div>
      <button class="customize-fields_create-field btn btn-outline" (click)="openFieldModal()">
        {{ t('modals.page-details.btn-add-fields') }}
      </button>
    </div>

    <ng-scrollbar
      track="all"
      pointerEventsMethod="scrollbar"
      [autoHeightDisabled]="false"
      (reachedStart)="onScrollToStart()"
      [style.max-height.px]="562"
      class="customize-fields_content mat-elevation-z8"
    >
      <mat-table
        *ngIf="isFieldsLoaded | async"
        cdkDropList
        class="mat-table-height"
        (cdkDropListDropped)="drop($event)"
        [dataSource]="dataSource"
      >
        <ng-container matColumnDef="name" [sticky]="true">
          <mat-header-cell *matHeaderCellDef class="name">
            {{ t('common.form-name') }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="name">
            <span class="name-title">
              <span>{{ element.displayName }}</span>
              <app-svg
                *ngIf="element.isLocked"
                name="lock"
                width="14"
                height="14"
                fill="#565466"
              ></app-svg>
            </span>
            <span class="name-description">{{ element?.description || '' }}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="mandatory">
          <mat-header-cell *matHeaderCellDef>
            {{ t('modals.page-details.thead-mandatory') }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="custom-control custom-switch mr-n2">
              <input
                type="checkbox"
                class="custom-control-input"
                [id]="'mandatory-switch-' + element._id"
                #checkbox
                [checked]="element.isMandatory"
                (click)="$event.stopPropagation()"
                (change)="onChangeFieldMandatory(element)"
              />
              <label
                [attr.t-id]="'customize-fields-visible-switch-' + element._id"
                class="custom-control-label"
                [for]="'mandatory-switch-' + element._id"
              ></label>
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="onCard">
          <mat-header-cell *matHeaderCellDef>
            {{ t('modals.page-details.on-card') }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.isOnCard ? t('common.yes') : t('common.no') }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef>
            {{ t('modals.page-details.thead-type') }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <app-svg
              t-id="type-field-image"
              class="svg-icon"
              [name]="element.type"
              width="20"
              height="20"
              fill="#565466"
            ></app-svg>
            <span class="ml-1">
              {{ typeTitle(element.type) }}
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="format">
          <mat-header-cell *matHeaderCellDef>
            {{ t('modals.page-details.thead-format') }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div>{{ element.format || t('modals.page-details.text') }}</div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="visible">
          <mat-header-cell *matHeaderCellDef>
            {{ t('modals.page-details.thead-visible') }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index">
            <span class="custom-control custom-switch mr-n2">
              <input
                type="checkbox"
                class="custom-control-input"
                [id]="'visible-switch-' + element._id"
                #checkbox
                [checked]="element.isVisible"
                (click)="$event.stopPropagation()"
                (change)="onChangeFieldVisible(element)"
              />
              <label
                [attr.t-id]="'customize-fields-visible-switch-' + element._id"
                class="custom-control-label"
                [for]="'visible-switch-' + element._id"
              ></label>
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>
            {{ t('modals.page-details.thead-actions') }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button
              t-id="chat-navigation-tree-dot-menu"
              class="btn dropdown d-flex align-items-center p-0"
              ngbDropdown
              placement="auto"
              container="body"
              [autoClose]="true"
            >
              <app-svg
                name="ellipsis"
                width="17"
                height="4"
                [hoverEffect]="true"
                class="dropdown-toggle cursor-pointer"
                ngbDropdownToggle
              ></app-svg>

              <div ngbDropdownMenu class="dropdown-menu">
                <a
                  *ngIf="!element.isLocked"
                  href="javascript:"
                  class="dropdown-item"
                  [ngStyle]="{ display: 'flex', 'align-items': 'center' }"
                  t-id="customize-ticket-field-edit"
                  (click)="openFieldModal(element)"
                >
                  <app-svg name="edit" width="16" height="16" class="mr-2" fill="#787A91"></app-svg>
                  <span class="colorDropDown">{{ t('common.btn-edit') }}</span>
                </a>
                <a
                  *ngIf="!element.isLocked"
                  href="javascript:"
                  class="dropdown-item"
                  [ngStyle]="{ display: 'flex', 'align-items': 'center' }"
                  t-id="customize-ticket-field-remove"
                  (click)="deleteFieldModal(element._id)"
                >
                  <app-svg
                    name="trash"
                    width="16"
                    height="16"
                    class="mr-2 error"
                    fill="#787A91"
                  ></app-svg>
                  <span class="colorDropDown">{{ t('common.btn-remove') }}</span>
                </a>
                <a href="javascript:" class="dropdown-item" *ngIf="element.isLocked">
                  <span class="colorDropDown">{{
                    t('modals.page-details.no-actions-available')
                  }}</span>
                </a>
              </div>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          cdkDrag
          cdkDragLockAxis="y"
          [class.isDisabledDrag]="row.isLocked"
          [cdkDragStartDelay]="50"
          [cdkDragDisabled]="row.isLocked"
        ></mat-row>
      </mat-table>
    </ng-scrollbar>
    <div
      *ngIf="!(isFieldsLoaded | async)"
      class="loading"
      [class.loading-dark]="config?.layout.variant === 'Dark'"
    >
      <app-svg name="spinner" width="38" height="38" fill="#C6C8D7" class="loading-icon"></app-svg>
    </div>
  </div>
  <div class="customize-fields_footer d-flex align-items-center justify-content-end">
    <button class="btn btn-subtle mr-2" type="button" (click)="close.emit()">
      {{ t('common.btn-cancel') }}
    </button>

    <button
      [disabled]="!fieldsToUpdate.length || (isLoading | async)"
      (click)="saveFieldsVisible()"
      class="btn btn-solid"
    >
      <i
        *ngIf="isLoading | async"
        class="fa fa-spinner fa-spin connection_spinner"
        aria-hidden="true"
      ></i>
      {{ (isLoading | async) ? '' : t('common.btn-save') }}
    </button>
  </div>
</div>
