import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  TemplateRef,
  ViewChild,
  OnInit,
  AfterViewInit,
  OnDestroy,
  OnChanges,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  differenceInMinutes,
  startOfHour,
  startOfDay,
  endOfDay,
  isSameMonth,
  endOfWeek,
  startOfWeek,
  getMinutes,
  setMinutes,
} from 'date-fns';
import { combineLatest, merge, Subject, Subscription } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'ngx-localstorage';
import { ToastrService } from 'ngx-toastr';
import { WrappedSocket } from 'ngx-socket-io/src/socket-io.service';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { DragulaService, DragulaModule } from 'ng2-dragula';
import {
  CalendarUtils,
  CalendarView,
  CalendarCommonModule,
  CalendarMonthModule,
  CalendarWeekModule,
  CalendarDayModule,
  CalendarEvent,
} from 'angular-calendar';
import moment from 'moment-timezone';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';

import { UsersPublicFieldsResDto } from '../../../api/models/users-public-fields-res-dto';
import { CalendarEventBasis, CalendarEventException } from './calendar-utils-custom';
import { CheckPermissionPipe } from '../../pipes/check-permission.pipe';
import { RouterTenantPipe } from '../../pipes/router-tenant.pipe';
import { DateTimeHelper } from '../../utils/date-time-helper';
import { RouterQueryService } from '../../services/router-query.service';
import { SocketsService } from '../../services/sockets.service';
import { ConfigService } from '../../services/config.service';
import {
  CalendarEventGet,
  CalendarEventRemove,
  CalendarEventsCheckedCalendarsGet,
  CalendarEventsUpdateStatus,
  CalendarEventExceptions,
  CalendarEventsDataUpdate,
  CalendarEventsGet,
  CalendarEventsSet,
  CalendarEventsCheckedCalendarsUpdate,
  CalendarTicketsGet,
  CalendarTicketsSet,
  GetTaskList,
} from '../../store/actions/calendar-events.action';
import { AuthState } from '../../store/states/auth.state';
import { CalendarEventsState } from '../../store/states/calendar-events.state';
import { CalendarEditEventComponent } from '../../../modals/calendar-edit-event/calendar-edit-event.component';
import { BoardTicketModalComponent } from '../../../modals/board-ticket/board-ticket.component';
import { environment } from '../../../../environments/environment';
import { MinimizeService } from '../../services/minimize.service';
import { LocalStorageKeys } from '../../../types/local-storage-keys.enum';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { SvgComponent } from '../../svgs/svg/svg.component';
import { CommonModule } from '@angular/common';
import { MixpanelService } from '../../../plugins/mixpanel/mixpanel.service';
import {
  CalendarEventsChecksGetListResDto,
  CalendarEventsGetListResDto,
  CalendarTicketsGetListResDto,
} from '../../../api/models';
import { TippyDirective } from '../../directives/tippy.directive';
import { CalendarTasksDto } from '../../../api/models/calendar-tasks-dto';
import { SvgIconComponent } from 'angular-svg-icon';
import { MomentPipe } from './moment.pipe';
import { v4 } from 'uuid';
import { CalendarViewEventComponent } from '../../../modals/calendar-view-event/calendar-view-event.component';

interface TeamplateEvent extends CalendarEvent {
  meta: {
    eventType: 'events';
    object: string;
    objectId: string;
    userName: string;
    description: string;
    place: string;
    guests: string[];
    workdays: boolean;
    timezone: string;
    repeat: string;
    reminder: string;
    addCall: boolean;
    userId: string;
    sendEmail: boolean;
    calendarEventMembers: { userId: string; status: string; userName: string }[];
    groupingEvents?: {
      events: (TeamplateEvent | TeamplateTicket)[];
    };
  };
}

interface TeamplateTicket extends CalendarEvent {
  meta: {
    eventType: 'tickets';
    objectId: string;
    userName: string;
    object: string;
    groupingEvents?: {
      events: (TeamplateEvent | TeamplateTicket)[];
    };
  };
}

interface TeamplateTask extends CalendarEvent {
  meta: {
    eventType: 'tasks';
    objectId: string;
    userName: string;
    object: string;
    groupingEvents?: {
      events: TeamplateTask[];
    };
  };
}

@Component({
  selector: 'app-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss', './calendar-ngdeep.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MomentPipe,
    TranslocoDirective,
    CalendarCommonModule,
    SvgComponent,
    FormsModule,
    NgSelectModule,
    DragAndDropModule,
    DragulaModule,
    CalendarMonthModule,
    CalendarWeekModule,
    CalendarDayModule,
    TippyDirective,
    SvgIconComponent,
  ],
})
export class CalendarComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChild(PerfectScrollbarDirective, { static: false })
  directiveRef?: PerfectScrollbarDirective;
  @ViewChild('scrollableContainer') scrollContainer: ElementRef; // Get scrollbar component reference
  @ViewChild('modalContent') modalContent: TemplateRef<any>;

  @Input() withSubNavbar = true;
  @Input() object: string;
  @Input() objectId: string;
  @Input() readOnly: boolean;
  @Input() calendarTab?: string;

  public readonly moment = moment;
  public readonly endOfWeek = endOfWeek;
  public readonly startOfWeek = startOfWeek;

  config: any = {};
  themeMode: string;
  platform = 'web';
  view: CalendarView | 'month' | 'week' | 'day' = 'week';

  destroy$ = new Subject<void>();
  socket: WrappedSocket;
  dragulaSub = new Subscription();
  notesContainer = 'note-dragula-container';
  eventRefresh: Subject<void> = new Subject();
  viewDate: Date = new Date();
  event: CalendarEvent;
  events: (TeamplateEvent | TeamplateTicket | TeamplateTask)[];
  filteredEvents: CalendarEvent[];
  eventPending: string = null;
  editMode: boolean;
  activeDayIsOpen = false;
  excludeDays: number[] = [0, 6]; // for exclude weekends
  searchQuery = '';

  periodStart = null;
  periodEnd = null;

  tz: string = null;
  currTz: string = null;
  currTzAbbr: string = null;
  allCalendars: CalendarEventsChecksGetListResDto[];
  calendars = [];
  checkedCalendars = [];
  monthViewEvents = [];
  groupedMonthViewEvents = [];
  weekViewEvents = [];
  dayViewEvents = [];
  isPersonalSpace = false;
  hideSearchInput = true;
  user: UsersPublicFieldsResDto;
  lang: string = this.localStorage.get(LocalStorageKeys.language) || 'en';

  addEventOptions = ['Event', 'Task'] as const;

  views = [
    {
      value: 'day',
      label: this.translocoService.translate('calendar.day'),
    },
    {
      value: 'week',
      label: this.translocoService.translate('calendar.week'),
    },
    {
      value: 'month',
      label: this.translocoService.translate('calendar.month'),
    },
  ];

  show = [
    {
      value: 'events',
      label: this.translocoService.translate('calendar.events'),
      selected: true,
    },
    {
      value: 'tickets',
      label: this.translocoService.translate('calendar.tickets'),
      selected: true,
    },
    {
      value: 'tasks',
      label: this.translocoService.translate('calendar.tasks'),
      selected: true,
    },
  ] as const;
  elementsShown: string[] = this.show.map((item) => item.value);
  showAddEventOptions = false;

  private readonly ICON_BASE_URL = 'assets/icons/calendar';

  constructor(
    protected checkPermissionPipe: CheckPermissionPipe,
    protected readonly modal: NgbModal,
    protected readonly toastr: ToastrService,
    protected readonly routerQueryService: RouterQueryService,
    protected readonly configService: ConfigService,
    readonly routerTenantPipe: RouterTenantPipe,
    readonly activatedRoute: ActivatedRoute,
    readonly router: Router,
    readonly store: Store,
    readonly ref: ChangeDetectorRef,
    private actions: Actions,
    private localStorage: LocalStorageService,
    private socketsService: SocketsService,
    private dragulaService: DragulaService,
    private minimizeService: MinimizeService,
    public dtHelper: DateTimeHelper,
    public calendarUtils: CalendarUtils,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
    this.socket = this.socketsService.get();
  }

  ngOnInit() {
    if (environment.is_desktop) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.refreshPeriods(true);
        }
      });
    }

    this.store.dispatch(new CalendarEventsSet());
    this.store.dispatch(new CalendarEventsCheckedCalendarsGet({}));

    this.excludeDays = this.localStorage.get('excludeDays') || [0, 6];

    const query = {
      ...this.activatedRoute.snapshot.queryParams,
      ...this.activatedRoute.snapshot.params,
    };
    if (query.event || query.ticket) {
      this.eventPending = query.event || query.ticket;
    }

    this.actions
      .pipe(takeUntil(this.destroy$), ofActionSuccessful(CalendarEventsUpdateStatus))
      .subscribe(() => this.refreshPeriods(true));

    this.translocoService.events$.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event.type === 'translationLoadSuccess' || event.type === 'langChanged') {
        this.lang = event.payload.langName;
        this.views = this.views.map((view) => ({
          ...view,
          label: this.translocoService.translate(`calendar.${view.value}`),
        }));
      }
    });

    this.store
      .select(AuthState.getUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (user) => {
          this.user = user;
          if (user?.timezone) {
            this.currTz = user.timezone;
            this.currTzAbbr = moment.tz(user.timezone).format('Z').replace(':', '');
          }
          this.tz = moment.tz.guess(true);
        },
      });

    this.store
      .select(CalendarEventsState.getMyCalendars)
      .pipe(takeUntil(this.destroy$))
      .subscribe((calendars) => {
        this.allCalendars = calendars;

        let spacesCal: any[];
        let projectsCal = [];
        const spacesProjectsCal = [];

        spacesCal = calendars
          .filter((item) => item.object === 'spaces')
          .map((item) => ({
            calId: item._id,
            title: item.calendarName,
            id: item.objectId,
          }));

        projectsCal = calendars
          .filter((item) => item.object === 'projects')
          .map((item) => ({
            calId: item._id,
            title: item.calendarName,
            spaceId: item.spaceId,
            id: item.objectId,
          }));

        spacesCal.forEach((space) => {
          spacesProjectsCal.push(space);
          projectsCal.forEach((project) => {
            if (project.spaceId === space.id) {
              spacesProjectsCal.push(project);
            }
          });
        });

        this.calendars = [
          {
            id: 'users',
            title: 'Personal Calendar',
          },
          ...spacesProjectsCal,
        ];

        this.checkedCalendars = calendars
          .map((item) => {
            if (item.isChecked) {
              return item.object === 'users' ? 'users' : item.objectId;
            }
          })
          .filter((item) => item);

        if (this.checkedCalendars.length > 0) {
          this.callPrepareEventsView();
        }

        this.ref.markForCheck();
      });

    combineLatest([
      this.store
        .select(CalendarEventsState.getEvents)
        .pipe(map((filterFn) => filterFn(this.objectId))),
      this.store.select(CalendarEventsState.getTickets),
      this.store.select(CalendarEventsState.getTasks),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([_events, _tickets, _tasks]) => {
        const events = _events.map<TeamplateEvent>((event: CalendarEventsGetListResDto) => {
          const allDay = event.allDay ? { allDay: event.allDay } : {};

          return {
            id: event._id,
            start: this.dtHelper.convertDateForTz(this.currTz, event.start),
            end: this.dtHelper.convertDateForTz(this.currTz, event.end),
            title: event.title,
            calendarEventMembers: event.calendarEventMembers,
            userId: event.userId,
            cssClass: event.object,
            draggable: false,
            resizable: {
              beforeStart: true,
              afterEnd: true,
            },
            actions: [],
            ...allDay,
            meta: {
              userName: event.userName,
              workdays: event.repeat ? event.repeat === 'workdays' : false,
              addCall: event.videoCallId ? true : false,
              sendEmail: event.sendEmail,
              description: event.description,
              userId: event.userId,
              calendarEventMembers: event.calendarEventMembers,
              repeat: event.repeat,
              guests: event.calendarEventMembers?.map(({ userId }) => userId),
              reminder: event.reminder,
              timezone: event.timezone,
              videoCallId: event.videoCallId,
              place: event.place,
              objectId: event.objectId,
              object: event.object,
              eventType: 'events' as const,
            },
          };
        });

        const filteredTickets = this.objectId
          ? _tickets.filter((ticket) => ticket.objectId === this.objectId)
          : _tickets;

        const tickets = filteredTickets.map<TeamplateTicket>(
          (ticket: CalendarTicketsGetListResDto) => {
            const checkIfIsAllDay = (item: CalendarTicketsGetListResDto) => {
              return (
                item.end == null &&
                moment(this.dtHelper.convertDateForTz(this.currTz, item.startDate)).format(
                  'HH:mm',
                ) === '00:00'
              );
            };

            return {
              id: ticket._id,
              start: this.dtHelper.convertDateForTz(
                this.currTz,
                ticket.startDate ?? moment(ticket.end).subtract(30, 'minutes'),
              ),
              end: this.dtHelper.convertDateForTz(
                this.currTz,
                ticket.end ?? moment(ticket.startDate).add(30, 'minutes'),
              ),
              title: ticket.title,
              workdays: false,
              cssClass: 'start-date',
              draggable: true,
              resizable: {
                beforeStart: true,
                afterEnd: true,
              },
              actions: [],
              type: ticket.type,
              allDay: checkIfIsAllDay(ticket),
              meta: {
                userName: ticket.userName,
                objectId: ticket.objectId,
                object: ticket.object,
                eventType: 'tickets' as const,
              },
            };
          },
        );

        const tasks = _tasks.map<TeamplateTask>((task: CalendarTasksDto) => {
          return {
            id: task._id,
            start: this.dtHelper.convertDateForTz(this.currTz, task.start),
            end: this.dtHelper.convertDateForTz(
              this.currTz,
              task.end ?? moment(task.start).add(30, 'minutes'),
            ),
            title: task.title,
            workdays: false,
            cssClass: 'task',
            draggable: true,
            resizable: {
              beforeStart: true,
              afterEnd: true,
            },
            actions: [],
            allDay: task.allDay,
            meta: {
              objectId: task.objectId,
              object: task.object,
              userName: this.user?.name,
              eventType: 'tasks' as const,
            },
          };
        });

        this.events = [events, tickets, tasks].flat();

        this.callPrepareEventsView();

        if (this.events && this.eventPending) {
          const event = this.events.find((item) => item.id === this.eventPending);
          if (event) {
            this.viewEvent(event);
            this.eventPending = null;
          }
        }

        this.filteredEvents = this.filterByDateEvents;
        this.ref.detectChanges();
      });

    this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.themeMode = templateConf.layout.variant;
        this.ref.detectChanges();
      }
    });

    if (this.platform === 'web') {
      this.activatedRoute.url.pipe(takeUntil(this.destroy$)).subscribe((res) => {
        this.isPersonalSpace = res[0].path === 'dash';
        this.hideSearchInput = res[0].path === 'dash';
      });
    }

    this.setSocketListeners();
    this.initDragula();
  }

  ngAfterViewInit() {
    this.scrollToCurrentView();
  }

  ngOnChanges() {
    this.periodStart = null;
    this.periodEnd = null;
    this.refreshPeriods();
  }

  ngOnDestroy() {
    this.store.dispatch(new CalendarTicketsSet([]));
    this.dragulaSub?.unsubscribe();
    this.dragulaService?.destroy(this.notesContainer);
    this.destroy$?.next();
    this.destroy$?.complete();
  }

  selectAddEventOption(event: (typeof this.addEventOptions)[number]) {
    this.addEvent(null, null, false, '', '', event);
  }

  changeView(view) {
    this.view = view;

    this.refreshPeriods();
    this.callPrepareEventsView();

    this.scrollToCurrentView();
    this.ref.detectChanges();
  }

  viewDateRangeStart() {
    if (this.view === 'month' || this.view === 'day') {
      return moment(this.viewDate).startOf(this.view);
    } else if (this.view === 'week') {
      // fix sun->mon start of week
      if (this.viewDate.getDay() === 0) {
        // day is sunday
        return moment(this.viewDate).startOf('week').add(-6, 'day');
      }

      return moment(this.viewDate).startOf('week').add(1, 'day');
    }
  }

  viewDateRangeEnd() {
    if (this.view === 'month' || this.view === 'day') {
      return moment(this.viewDate).endOf(this.view);
    } else if (this.view === 'week') {
      // fix sun->mon start of week
      if (this.viewDate.getDay() === 0) {
        // day is sunday
        return moment(this.viewDate).endOf('week').add(-6, 'day');
      }
      return moment(this.viewDate).endOf('week').add(1, 'day');
    }
  }

  filterCurrentDayEvents() {
    this.refreshPeriods();
    return this.events?.filter((item) => {
      const eventStart = moment(item?.start);
      const eventEnd = moment(item?.end);
      return (
        eventStart.isBetween(this.periodStart, this.periodEnd) ||
        eventEnd.isBetween(this.periodStart, this.periodEnd)
      );
    });
  }

  getIcon(name: string): string {
    return `${this.ICON_BASE_URL}/${name}.svg`;
  }

  getFilteredEvents(currentEvents): (TeamplateEvent | TeamplateTicket | TeamplateTask)[] {
    let filteredEvents: (TeamplateEvent | TeamplateTicket | TeamplateTask)[] = [];
    const user = this.store.selectSnapshot(AuthState.getUser);

    this.checkedCalendars.forEach((calId) => {
      filteredEvents =
        calId === 'users'
          ? [
              ...filteredEvents,
              ...(currentEvents
                ? currentEvents?.filter(
                    (item) =>
                      !item.meta.objectId &&
                      (item.meta.object === 'users' || item.meta.guests?.includes(user._id)),
                  )
                : []),
            ]
          : [
              ...filteredEvents,
              ...(currentEvents
                ? currentEvents?.filter((item) => item.meta?.objectId === calId)
                : []),
            ];
    });

    return filteredEvents;
  }

  getCurrentEvents(
    currentEvents: (TeamplateEvent | TeamplateTicket | TeamplateTask)[],
  ): (TeamplateEvent | TeamplateTicket | TeamplateTask)[] {
    if (!this.isPersonalSpace) {
      return currentEvents;
    }

    return this.checkedCalendars.length > 0 ? this.getFilteredEvents(currentEvents) : [];
  }

  refreshPeriods = (force = false) => {
    const periodStart = this.viewDateRangeStart();
    const periodEnd = this.viewDateRangeEnd();

    if (force || !periodStart?.isSame(this.periodStart) || !periodEnd?.isSame(this.periodEnd)) {
      this.periodStart = periodStart;
      this.periodEnd = periodEnd;

      this.store.dispatch(
        new CalendarEventsGet({
          ...this.checkObjectIsUsers(),
          objectId: this.objectId,
          from: periodStart.toISOString(),
          to: periodEnd.toISOString(),
        }),
      );

      this.store.dispatch(
        new CalendarTicketsGet({
          from: periodStart.toISOString(),
          to: periodEnd.toISOString(),
          objectId: this.objectId,
        }),
      );

      this.store.dispatch(
        new GetTaskList({
          from: periodStart.toISOString(),
          to: periodEnd.toISOString(),
          objectId: this.objectId,
        }),
      );
    }
  };

  checkObjectIsUsers() {
    if (this.object === 'users') {
      return '';
    }

    return { object: this.object || 'users' };
  }

  get filterByDateEvents(): CalendarEvent[] {
    return this.events && this.events.length
      ? this.events.filter(
          (event) =>
            moment(event?.start).isSame(this.viewDate, 'day') ||
            moment(this.viewDate).isBetween(moment(event?.start), moment(event?.end)),
        )
      : [];
  }

  private scrollToCurrentView() {
    if (this.view === 'week' || this.view === 'day') {
      // each hour is 60px high, so to get the pixels to scroll it's just the amount of minutes since midnight
      const minutesSinceStartOfDay = differenceInMinutes(
        startOfHour(new Date()),
        startOfDay(new Date()),
      );

      const headerHeight = this.view === 'week' ? 55 : 0;

      if (this.scrollContainer) {
        this.scrollContainer.nativeElement.scrollTo({
          top: minutesSinceStartOfDay + headerHeight,
          duration: 0,
        });
      }
    } else {
      this.directiveRef?.update();
    }
  }

  changeSelectedCalendars() {
    MixpanelService.trackEvent('Calendar selected calendars change', {
      calendars: this.checkedCalendars,
    });
    this.store.dispatch(
      new CalendarEventsCheckedCalendarsUpdate({
        body: {
          calendarEventsChecksIds: this.checkedCalendars
            .map((checkedCal) => {
              if (checkedCal === 'users') {
                return this.allCalendars.filter((item) => item.object === 'users').pop();
              }
              return this.allCalendars.filter((item) => item.objectId === checkedCal).pop();
            })
            .filter((val) => val)
            .map((item) => item._id),
        },
      }),
    );

    this.callPrepareEventsView();
  }

  changeSelectedEventOption() {
    this.store.dispatch(
      new CalendarEventsCheckedCalendarsUpdate({
        body: {
          calendarEventsChecksIds: this.checkedCalendars
            .map((checkedCal) => {
              if (checkedCal === 'users') {
                return this.allCalendars.filter((item) => item.object === 'users').pop();
              }
              return this.allCalendars.filter((item) => item.objectId === checkedCal).pop();
            })
            .filter((val) => val)
            .map((item) => item._id),
        },
      }),
    );

    this.callPrepareEventsView();
  }

  changeCalendarPeriod() {
    MixpanelService.trackEvent('Calendar period change', {
      period: this.view,
    });

    this.refreshPeriods();

    this.callPrepareEventsView();
  }

  resetCurrentDateView(): void {
    MixpanelService.trackEvent('Calendar reset current date view');
    this.viewDate = new Date();
    this.callPrepareEventsView();
  }

  changeIncludeWeekends(checked) {
    MixpanelService.trackEvent('Calendar include weekends change', {
      includeWeekends: checked,
    });
    this.excludeDays = checked ? [] : [0, 6];
    this.localStorage.set('excludeDays', this.excludeDays);
  }

  callPrepareEventsView() {
    this.filterEventsByQuery(this.searchQuery);
  }

  hourClicked(event) {
    MixpanelService.trackEvent('Calendar hour clicked');
    if (
      this.checkPermissionPipe.transform(
        this.object + '::' + this.objectId + '::calendarEventCreate',
      )
    ) {
      this.addEvent(
        this.dtHelper.convertDateForTz(this.currTz, event.date),
        this.dtHelper.convertDateForTz(this.currTz, event.date, {
          amount: this.view === 'week' ? 60 : 30,
          unit: 'm',
        }),
      );
    }
  }

  dayClicked({ date }, isMobile = false): void {
    MixpanelService.trackEvent('Calendar day clicked');
    if (isSameMonth(date, this.viewDate) && !isMobile) {
      if (
        this.checkPermissionPipe.transform(
          this.object + '::' + this.objectId + '::calendarEventCreate',
        )
      ) {
        this.addEvent(startOfDay(date), endOfDay(date));
      }
    }

    if (isMobile) {
      this.viewDate = date;
      this.filteredEvents = this.filterByDateEvents;
    }
  }

  viewEvent(event: CalendarEvent): void {
    const eventMeta: (TeamplateEvent | TeamplateTicket | TeamplateTask)['meta'] = event.meta;

    MixpanelService.trackEvent('Calendar view event', {
      event: event.id,
      eventType: eventMeta.eventType,
    });

    if (eventMeta.eventType === 'tickets') {
      const modalRef = this.modal.open(BoardTicketModalComponent, {
        size: 'xl',
        backdrop: 'static',
        scrollable: true,
        keyboard: false,
        beforeDismiss: () => modalRef.componentInstance.closeImagePreview(true),
      });
      modalRef.componentInstance.ticketData = {
        id: event.id,
        type: eventMeta.eventType,
        object: eventMeta.object || this.object,
        objectId: eventMeta.objectId || this.objectId,
      };

      this.minimizeService.minimizeInit(modalRef);
    } else if (eventMeta.eventType === 'events') {
      this.routerQueryService.update({ event: event.id });

      const isMyEvent = eventMeta?.userId === this.user._id;
      if (isMyEvent) {
        const modalRef = this.modal.open(CalendarEditEventComponent, {
          windowClass: 'modal-fit-content',
          backdrop: 'static',
          keyboard: false,
        });
        modalRef.componentInstance.modalData = {
          action: 'View event',
          displayName: this.translocoService.translate('calendar.view-event'),
          event,
          type: eventMeta.eventType,
        };
      } else {
        const modalRef = this.modal.open(CalendarViewEventComponent, {
          backdrop: 'static',
          keyboard: false,
          size: 'md',
        });
        modalRef.componentInstance.modalData = {
          eventId: event.id,
          createdBy: eventMeta.userName,
        };
      }
    } else {
      const modalRef = this.modal.open(CalendarEditEventComponent, {
        windowClass: 'modal-fit-content',
        backdrop: 'static',
        keyboard: false,
      });
      modalRef.componentInstance.modalData = {
        action: 'View event',
        displayName: this.translocoService.translate('calendar.view-event'),
        event,
        type: eventMeta.eventType,
      };
    }
  }

  addEvent(
    start: Date = null,
    end: Date = null,
    mobile = false,
    title: string = '',
    description = '',
    event: (typeof this.addEventOptions)[number] = 'Event',
  ): void {
    MixpanelService.trackEvent('Calendar add new event');
    const currentTime = mobile ? new Date(start.setHours(new Date().getHours())) : new Date();
    const defaultStart = setMinutes(currentTime, getMinutes(currentTime) > 30 ? 60 : 30);
    const defaultEnd = setMinutes(defaultStart, getMinutes(defaultStart) > 30 ? 90 : 60);
    const modalRef = this.modal.open(CalendarEditEventComponent, {
      windowClass: 'modal-fit-content',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.modalData = {
      action: 'Add new event',
      displayName: this.translocoService.translate('calendar.add-new-event'),
      type: event,
      event: {
        title,
        start: mobile ? defaultStart : (start ?? defaultStart),
        end: mobile ? defaultEnd : (end ?? defaultEnd),
        allDay: false,
        workdays: false,
        meta: {
          eventType: event === 'Event' ? 'events' : 'tasks',
          object: this.object,
          objectId: this.objectId,
          description,
        },
      },
    };
  }

  changeHoursInDate(date) {
    const offset = moment.tz(this.tz).format('Z');
    const diff = moment(date).diff(moment(date).utcOffset(offset, true), 'minutes');

    return moment(date).add(diff, 'minutes').format('YYYY-MM-DD HH:mm:ss');
  }

  private setSocketListeners(): void {
    this.socket
      .fromEvent('notification:send:calendarEventCreate')
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: CalendarEventBasis) => {
        const periodStart = this.viewDateRangeStart();
        const periodEnd = this.viewDateRangeEnd();

        if (res.objectId === this.objectId || this.object === 'users') {
          this.store.dispatch(
            new CalendarEventGet({
              ...res,
              from: periodStart.toISOString(),
              to: periodEnd.toISOString(),
            }),
          );
        }
      });

    this.socket
      .fromEvent('notification:send:calendarTaskChange')
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: CalendarEventBasis) => {
        const periodStart = this.viewDateRangeStart();
        const periodEnd = this.viewDateRangeEnd();

        if (res.objectId === this.objectId || this.object === 'users') {
          this.store.dispatch(
            new GetTaskList({
              objectId: this.object === 'users' ? undefined : res.objectId,
              from: periodStart.toISOString(),
              to: periodEnd.toISOString(),
            }),
          );
        }
      });

    this.socket
      .fromEvent('notification:send:calendarEventsDelete')
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: CalendarEvent) => this.store.dispatch(new CalendarEventRemove(event)));

    this.socket
      .fromEvent('notification:send:calendarEventsExceptionsCreate')
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: CalendarEventException) =>
        this.store.dispatch(new CalendarEventExceptions(res)),
      );

    this.socket
      .fromEvent('notification:send:calendarEventUpdate')
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: CalendarEventBasis) => {
        const periodStart = this.viewDateRangeStart();
        const periodEnd = this.viewDateRangeEnd();

        if (res.objectId === this.objectId || this.object === 'users') {
          this.store.dispatch(
            new CalendarEventsDataUpdate({
              ...res,
              from: periodStart.toISOString(),
              to: periodEnd.toISOString(),
            }),
          );
        }
      });

    merge(
      this.socket.fromEvent('notification:send:ticketsCreate'),
      this.socket.fromEvent('notification:send:ticketsUpdate'),
      this.socket.fromEvent('notification:send:ticketsDelete'),
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.refreshPeriods(true));
  }

  private initDragula(): void {
    this.dragulaSub.add(
      this.dragulaService
        .drop(this.notesContainer)
        .pipe(takeUntil(this.destroy$))
        .subscribe(({ name, el, target, source, sibling }) => {
          const _elWeekDay = document.querySelector('.cal-hour-segment .list-group-item');
          const _elMonth = document.querySelector('.cal-cell-top .list-group-item');

          const isMonthlyView = !!_elMonth;
          const targetContainerClass = _elMonth ? '.cal-cell-top' : '.cal-hour-segment';
          const eventStartEnd = this.getEventStartEnd(isMonthlyView, target);

          let _noteTitle = document.querySelector(
            `${targetContainerClass} .list-group-item .note-title-container`,
          )?.innerHTML;
          _noteTitle = _noteTitle ? _noteTitle : 'Enter event title';

          const _noteDescription = document.querySelector(
            `${targetContainerClass} .list-group-item .note-desc-container`,
          ).innerHTML;

          el.parentElement.removeChild(!isMonthlyView ? _elWeekDay : _elMonth);

          this.addEvent(
            this.dtHelper.convertDateForTz(this.currTz, eventStartEnd.start),
            this.dtHelper.convertDateForTz(this.currTz, eventStartEnd.end),
            false,
            _noteTitle,
            _noteDescription,
          );
        }),
    );
  }

  private getEventStartEnd(monthView: boolean, target: any): any {
    let start;
    let end;
    let eventTime;

    if (monthView) {
      eventTime = moment()
        .minute(moment().minute() > 30 ? 30 : 0)
        .second(0);
      eventTime = moment(eventTime).set(
        'date',
        moment(target.getAttribute('data-event-date')).date(),
      );

      start = this.dtHelper.convertDateForTz(this.currTz, eventTime);
      end = this.dtHelper.convertDateForTz(this.currTz, eventTime, {
        amount: 30,
        unit: 'm',
      });
    } else {
      const eventDate = target.getAttribute('data-event-date');
      start = this.dtHelper.convertDateForTz(this.currTz, eventDate);
      end = this.dtHelper.convertDateForTz(this.currTz, eventDate, {
        amount: 30,
        unit: 'm',
      });
    }

    return { start, end };
  }

  filterEventsByQuery(query: string): void {
    const currentEvents = this.getCurrentEvents(this.events);

    const filteredEvents = currentEvents.filter(
      (event) =>
        this.elementsShown.includes(event.meta.eventType) &&
        (event?.title.toLowerCase().includes(query.toLowerCase()) ||
          event.meta.userName.toLowerCase().includes(query.toLowerCase())),
    );

    this.searchQuery = query;

    if (this.view === 'month') {
      this.monthViewEvents = filteredEvents;
      this.groupedMonthViewEvents = this.groupEventsByMonth(filteredEvents);
    } else if (this.view === 'week') {
      this.weekViewEvents = this.groupEventsByWeek(filteredEvents);
    } else {
      this.dayViewEvents = filteredEvents;
    }
  }

  groupEventsByWeek(
    events: (TeamplateEvent | TeamplateTask | TeamplateTicket)[],
  ): (TeamplateEvent | TeamplateTask | TeamplateTicket)[] {
    const tasks = events.filter((event) => event.meta.eventType === 'tasks') as TeamplateTask[];
    const otherEvents = events.filter((event) => event.meta.eventType !== 'tasks');

    const groupedTasks: Map<string, TeamplateTask> = new Map();

    for (const task of tasks) {
      const key = `${task.allDay}-${moment(task.start).format('YYYY-MM-DD')}`;

      const existingTask = groupedTasks.get(key);

      if (!task.allDay) {
        groupedTasks.set(v4(), task);
      } else if (existingTask === undefined) {
        groupedTasks.set(key, task);
      } else {
        if (!existingTask.meta.groupingEvents) {
          existingTask.meta.groupingEvents = {
            events: [existingTask],
          };
        }

        existingTask.meta.groupingEvents.events.push(task);
      }
    }

    const normalizedTasks: TeamplateTask[] = [...groupedTasks.values()].map((task) => ({
      ...task,
      ...(task.meta.groupingEvents?.events && {
        title: this.translocoService.translate('calendar.grouped-pending-tasks', {
          amount: task.meta.groupingEvents.events.length,
        }),
      }),
    }));

    return [...normalizedTasks, ...otherEvents];
  }

  groupEventsByMonth(
    events: (TeamplateEvent | TeamplateTask | TeamplateTicket)[],
  ): (TeamplateEvent | TeamplateTask | TeamplateTicket)[] {
    const tasks = events.filter((event) => event.meta.eventType === 'tasks') as TeamplateTask[];
    const otherEvents = events.filter((event) => event.meta.eventType !== 'tasks') as (
      | TeamplateEvent
      | TeamplateTicket
    )[];

    const groupedTasks: Map<string, TeamplateTask> = new Map();

    for (const task of tasks) {
      const key = `${moment(task.start).format('YYYY-MM-DD')}`;

      const existingTask = groupedTasks.get(key);

      if (existingTask !== undefined) {
        if (!existingTask.meta.groupingEvents) {
          existingTask.meta.groupingEvents = {
            events: [existingTask],
          };
        }

        existingTask.meta.groupingEvents.events.push(task);
      } else {
        groupedTasks.set(key, task);
      }
    }

    const otherEventsGroupedByDate: Map<string, (TeamplateEvent | TeamplateTicket)[]> = new Map();

    for (const event of otherEvents) {
      const key = `${moment(event.start).format('YYYY-MM-DD')}`;

      if (!otherEventsGroupedByDate.has(key)) {
        otherEventsGroupedByDate.set(key, []);
      }

      otherEventsGroupedByDate.get(key).push(event);
    }

    for (const [day, event] of otherEventsGroupedByDate.entries()) {
      if (event.length <= 3) {
        continue;
      }

      const [firstEvent, secondEvent, ...restEvents] = event;

      const newEvent = {
        ...firstEvent,
        title: this.translocoService.translate('calendar.grouped-pending-events', {
          amount: restEvents.length,
        }),
        meta: {
          ...firstEvent.meta,
          groupingEvents: {
            events: restEvents,
          },
        },
      } as TeamplateEvent | TeamplateTicket;

      otherEventsGroupedByDate.set(day, [firstEvent, secondEvent, newEvent]);
    }

    const normalizedTasks: TeamplateTask[] = [...groupedTasks.values()].map((task) => ({
      ...task,
      ...(task.meta.groupingEvents?.events && {
        title: this.translocoService.translate('calendar.grouped-pending-tasks', {
          amount: task.meta.groupingEvents.events.length,
        }),
      }),
    }));

    return [normalizedTasks, ...otherEventsGroupedByDate.values()].flat();
  }

  toggleSearchInput(): void {
    MixpanelService.trackEvent('Calendar toggle search input');
    this.searchQuery = '';
    this.filterEventsByQuery(this.searchQuery);
    this.hideSearchInput = !this.hideSearchInput;
  }
}
