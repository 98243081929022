import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import equal from 'deep-equal';
import { TranslocoService } from '@ngneat/transloco';

import { TicketsDbDto } from '../../../api/models/tickets-db-dto';
import { ColumnsDbDto } from '../../../api/models/columns-db-dto';
import { BoardSettingsResDto } from '../../../api/models/board-settings-res-dto';
import { TicketsLabelsDbDto } from '../../../api/models/tickets-labels-db-dto';
import { TicketsFieldsDbDto } from '../../../api/models/tickets-fields-db-dto';
import {
  BoardsGetSettings,
  ColumnsCreate,
  ColumnsDelete,
  ColumnsGetList,
  ColumnsUpdate,
  ColumnsUpdateOrders,
  TicketsClear,
  TicketsDelete,
  TicketsGetList,
  TicketsLabelsGet,
  TicketsMoveAllToArchive,
  TicketsUpdate,
  TicketsUpdateOrders,
} from '../../../shared/store/actions/board.action';
import { BoardTicketModalComponent } from '../../../modals/board-ticket/board-ticket.component';
import { BoardsState } from '../../../shared/store/states/boards.state';
import { UsersPublicFieldsResDto } from '../../../api/models/users-public-fields-res-dto';
import { SprintModal } from '../../../shared/store/models/SprintState';
import { SprintsDbDto } from '../../../api/models/sprints-db-dto';
import { UsersDbDto } from '../../../api/models/users-db-dto';
import { ConfigService, ITemplateConfig } from '../../../shared/services/config.service';
import { ConfirmAlert } from '../../../shared/alerts/alerts';
import { SpaceGetUsersList } from '../../../shared/store/actions/spaces.action';
import { ProjectGetUsersList } from '../../../shared/store/actions/projects.action';
import { SprintsState } from '../../../shared/store/states/sprints.state';
import { FieldGetList } from '../../../shared/store/actions/board-field.action';
import { ChatsSetChatId } from '../../../shared/store/actions/chats.action';
import { RedirectService } from '../../../shared/services/redirect.service';
import { BoardsFieldState } from '../../../shared/store/states/boards-field.state';
import { AuthState } from '../../../shared/store/states/auth.state';
import { ChatsState } from '../../../shared/store/states/chats.state';
import { PriorityEnum } from './kanban-board/enums/priority.enum';
import { MinimizeService } from '../../../shared/services/minimize.service';
import { DraftService } from '../../../shared/services/draft.service';
import { EpicTicketComponent } from '../../../modals/epic-ticket/epic-ticket.component';
import { TicketService } from '../../../shared/services/ticket.service';
import { ToastrHelper } from '../../../shared/utils/toastr-helper';
import { BacklogTicket, BoardTicket } from './board-tickets.types';
import { SelectorEnum } from './board-tickets.enums';

@UntilDestroy({ checkProperties: true })
@Injectable()
export class BoardsService {
  public labels = new BehaviorSubject<Array<TicketsLabelsDbDto>>([]);
  private boardSettings = new BehaviorSubject<BoardSettingsResDto>(null);
  private ticketsFieldsConfig = new BehaviorSubject<Array<TicketsFieldsDbDto>>([]);
  private user = new BehaviorSubject<UsersPublicFieldsResDto>(null);
  public filteredTickets = new Subject<Array<TicketsDbDto>>();
  private dragAndDrop = new Subject();
  public searchedTickets = new BehaviorSubject<TicketsDbDto[]>([]);

  public columns = new BehaviorSubject<Array<ColumnsDbDto>>([]);
  public tickets = new BehaviorSubject<Array<TicketsDbDto>>([]);
  public sprints = new BehaviorSubject<Array<SprintsDbDto | SprintModal>>([]);
  public ticketsArchive = new BehaviorSubject<Array<TicketsDbDto>>([]);
  public users = new BehaviorSubject<Array<UsersDbDto>>([]);
  public activeSprint = new BehaviorSubject<SprintModal | SprintsDbDto>(null);
  public isLoading = new BehaviorSubject(false);

  public columns$ = this.columns.asObservable();
  public tickets$ = this.tickets.asObservable();
  public sprints$ = this.sprints.asObservable();
  public ticketsArchive$ = this.ticketsArchive.asObservable();
  public labels$ = this.labels.asObservable();
  public boardSettings$ = this.boardSettings.asObservable();
  public ticketsFieldsConfig$ = this.ticketsFieldsConfig.asObservable();
  public user$ = this.user.asObservable();
  public users$ = this.users.asObservable();
  public activeSprint$ = this.activeSprint.asObservable();
  public dragAndDropEvents$ = this.dragAndDrop.asObservable();
  public filteredTickets$ = this.filteredTickets.asObservable();

  public moreMembers: any[] = [];
  public ticketsMembers: any[] = [];
  public filteredMembers: any[] = [];
  public filteredLabels: any[] = [];

  public object: 'projects' | 'spaces' = null;
  public objectId: string = null;
  public archiveColumnId: string = null;
  public isMobile = false;
  public isSearch = false;
  public platform = 'web';
  public config: ITemplateConfig = null;
  public view = 'board';
  public chatId: string;
  public isOpenedTicket = false;

  public MAX_LINED_UP_BOARD_MEMBERS: number;
  public MAX_LINED_UP_BOARD_MEMBERS_MOBILE: number;
  public orientation: 'portrait' | 'landscape';
  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private router: Router,
    private configService: ConfigService,
    protected redirectService: RedirectService,
    protected minimizeService: MinimizeService,
    protected draftService: DraftService,
    private ticketService: TicketService,
    private toastrHelper: ToastrHelper,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
  }

  public broadcastColumns(columns: Array<ColumnsDbDto>) {
    this.columns.next(columns);
  }

  public get userData() {
    return this.user.value;
  }

  public broadcastTickets(tickets: Array<TicketsDbDto>) {
    this.tickets.next(
      tickets.map((t) => ({
        ...t,
        priority:
          !Number.isInteger(t?.priority) && t.isBlocked
            ? PriorityEnum.Highest
            : !Number.isInteger(t?.priority)
              ? PriorityEnum.Medium
              : t.priority,
      })),
    );
    if (tickets?.length && this.users.value?.length) {
      this.getBoardMembersList(this.users.value);
    }
  }

  public broadcastTicketsArchive(tickets: Array<TicketsDbDto>) {
    this.ticketsArchive.next(tickets);
  }

  public broadcastLabels(labels: Array<TicketsLabelsDbDto>) {
    this.labels.next(labels);
  }
  public broadcastSettings(boardSettings: BoardSettingsResDto) {
    this.boardSettings.next(boardSettings);
  }

  public broadcastTicketFieldsConfig(ticketsFieldsConfig: Array<TicketsFieldsDbDto>) {
    this.ticketsFieldsConfig.next(ticketsFieldsConfig);
  }

  public broadcastActiveSprint(sprint: SprintsDbDto | SprintModal) {
    this.activeSprint.next(sprint);
  }

  public broadcastSprints(sprint: Array<SprintsDbDto | SprintModal>) {
    this.sprints.next(sprint);
  }

  public broadcastUser(user: UsersPublicFieldsResDto) {
    this.user.next(user);
  }

  public broadcastUsers(users: Array<UsersDbDto>) {
    this.users.next(users);
  }

  public broadcastDragAndDrop(data: any) {
    this.dragAndDrop.next(data);
  }

  public loadData(): void {
    if (this.object === 'spaces') {
      this.store.dispatch(new SpaceGetUsersList({ id: this.objectId, exists: true }));
    } else {
      this.store.dispatch(new ProjectGetUsersList({ id: this.objectId, exists: true }));
    }
    this.store.dispatch(new ColumnsGetList({ object: this.object, objectId: this.objectId }));
    this.store.dispatch(new TicketsClear(this.objectId));

    // this.store.dispatch(
    //   new TicketsGetList({
    //     object: this.object,
    //     objectId: this.objectId,
    //     isArchive: true,
    //   }),
    // );

    this.store.dispatch(new TicketsLabelsGet({ object: this.object, objectId: this.objectId }));
    this.store.dispatch(new BoardsGetSettings({ object: this.object, objectId: this.objectId }));
    this.store.dispatch(new FieldGetList({ object: this.object, objectId: this.objectId }));
    this.store.dispatch(new ChatsSetChatId(this.chatId));
  }

  initSubscribers(): void {
    this.store
      .select(BoardsState.getColumnsList)
      .pipe(untilDestroyed(this))
      .subscribe((columns) => {
        if (columns?.length) {
          this.broadcastColumns(columns);
        }
      });
    this.store
      .select(BoardsState.getTicketsList)
      .pipe(untilDestroyed(this))
      .subscribe((tickets) => {
        this.broadcastTickets(tickets);
      });
    this.store
      .select(BoardsState.getTicketsArchiveList)
      .pipe(untilDestroyed(this))
      .subscribe((tickets) => {
        this.broadcastTicketsArchive(tickets);
      });
    this.store
      .select(BoardsState.getTicketsLabels)
      .pipe(untilDestroyed(this))
      .subscribe((labels) => {
        this.broadcastLabels(labels);
      });
    this.store
      .select(BoardsState.getBoardSettings)
      .pipe(untilDestroyed(this))
      .subscribe((boardSettings) => {
        this.broadcastSettings(boardSettings);
      });
    this.store
      .select(BoardsFieldState.getFieldsList)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.broadcastTicketFieldsConfig(res);
      });

    this.store
      .select(SprintsState.getSprints)
      .pipe(untilDestroyed(this))
      .subscribe((sprints) => {
        this.broadcastSprints(sprints);
      });

    this.store
      .select(AuthState.getUser)
      .pipe(untilDestroyed(this))
      .subscribe((user) => {
        this.broadcastUser(user);
      });

    this.store
      .select(SprintsState.getActiveSprint)
      .pipe(untilDestroyed(this))
      .subscribe((sprint: SprintsDbDto | SprintModal) => {
        this.broadcastActiveSprint(sprint);
      });

    this.chatId = this.store
      .selectSnapshot(ChatsState.getChats)
      .find((chat) => chat.objectId === this.objectId)?._id;
  }

  isEqual<T>(prevItems: Array<T>, nextItems: Array<T>): boolean {
    const prevItemsMap = new Map(prevItems.map((item) => [item['_id'], item]));
    const nextItemsMap = new Map(nextItems.map((item) => [item['_id'], item]));

    if (prevItemsMap.size !== nextItemsMap.size) {
      return false;
    } else {
      for (const [key, value] of prevItemsMap) {
        if (!nextItemsMap.has(key) || !equal(value, nextItemsMap.get(key))) {
          return false;
        }
      }
    }

    return true;
  }

  redirectToActiveSprint(): void {
    if (this.isMobile) {
      const partsOfUrl = this.router.url?.split('?');
      if (partsOfUrl?.length > 1) {
        const [baseUrl, queryParam] = partsOfUrl;
        const condition =
          queryParam === 'tab=tabBoard' ||
          queryParam === 'tab=tabBoard&subTab=kanban' ||
          queryParam === 'tab=tabBoard&subTab=archive' ||
          queryParam === 'tab=tabBoard&subTab=backlog';

        if (condition) {
          this.router.navigate([baseUrl], {
            queryParams: { tab: 'tabBoard', subTab: 'sprint' },
          });
        }
      }
    } else {
      // web
      const boardUrl = this.router.url?.split('/').pop();
      if (boardUrl === 'kanban') {
        const newUrl = this.router.url.replace('kanban', 'sprint');
        this.router.navigate([newUrl]);
      }
    }
    this.redirectService.setRedirectToActiveSprintValue(false);
  }

  redirectToKanbanBoard(): void {
    if (this.isMobile) {
      const partsOfUrl = this.router.url?.split('?');
      const [baseUrl] = partsOfUrl;

      this.router.navigate([baseUrl], {
        queryParams: { tab: 'tabBoard', subTab: 'kanban' },
      });
    }
  }

  public handleTicketsColumnsProcessing(
    message: string,
    type: string,
    showMessage: boolean = true,
  ) {
    if (showMessage) {
      this.toastr.clear();
      this.toastr.success(message, this.translocoService.translate('toastr.title-success'));
    }
  }

  public getLabel(labelId: string): string {
    const labels = this.labels.value.length
      ? this.labels.value
      : this.store.selectSnapshot(BoardsState.getTicketsLabels);

    return labels.find((label) => label._id === labelId)?.label;
  }

  public getParentTicket(parentId): { number: string; title: string } {
    const ticket = this.tickets.value.find((item) => item._id === parentId);
    if (ticket) {
      return {
        number: `${ticket.boardAbbreviation}-${ticket.counter}`,
        title: `Parent ticket: ${ticket.title}`,
      };
    }
    return null;
  }

  public getPrefix() {
    let prefix = '';
    switch (this.object) {
      case 'spaces':
        prefix = 'space';
        break;
      case 'projects':
        prefix = 'project';
        break;
    }
    return prefix;
  }

  identify(index, item) {
    return `${item._id}${item?.columnId}${item.updatedAt}`;
  }

  public moveToArchive(ticket) {
    const body = {
      object: this.object,
      objectId: this.objectId,
      columnId: this.archiveColumnId,
    };

    this.store
      .dispatch(new TicketsUpdate({ ticketUpdateId: ticket._id, body }))
      .pipe(untilDestroyed(this), take(1))
      .subscribe(
        () => {
          const ticketAbbr = `${ticket.boardAbbreviation}-${ticket.counter}`;
          this.handleTicketsColumnsProcessing(null, null, false);
          this.toastr.success(
            this.translocoService.translate('toastr.ticket-moved-to-archive', {
              ticketAbbr,
            }),
            this.translocoService.translate('toastr.title-success'),
            {
              enableHtml: true,
            },
          );
        },
        (err) =>
          this.toastr.error(err.message, this.translocoService.translate('toastr.title-error')),
      );
  }

  public moveAllToArchive() {
    ConfirmAlert(null, {
      subject: this.translocoService.translate('alert.move-all-tickets-to-archive-subject'),
      text: '',
      confirmButtonText: this.translocoService.translate('alert.btn-move-tickets'),
      cancelButtonText: this.translocoService.translate('alert.btn-cancel'),
      platform: this.platform,
    }).then(() => {
      this.store
        .dispatch(
          new TicketsMoveAllToArchive({
            object: this.object,
            objectId: this.objectId,
          }),
        )
        .pipe(untilDestroyed(this), take(1))
        .subscribe(
          () => {
            this.handleTicketsColumnsProcessing(null, null, false);
            this.toastr.success(
              this.translocoService.translate('toastr.tickets-moved-to-archive'),
              this.translocoService.translate('toastr.title-success'),
            );
          },
          (err) =>
            this.toastr.error(err.message, this.translocoService.translate('toastr.title-error')),
        );
    });
  }

  public copyTicketAbbrAndTitle(ticket): void {
    const modalRef = this.modalService.open(BoardTicketModalComponent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
      scrollable: true,
      beforeDismiss: () => modalRef.componentInstance.closeImagePreview(true),
    });
    modalRef.componentInstance.ticketData = {
      id: ticket._id,
      object: this.object || ticket.object,
      objectId: this.objectId || ticket.objectId,
      parentId: ticket.parentId,
      boardColorClass: this.getColumnBoardColor(ticket.columnId),
      boardAbbreviation: ticket.boardAbbreviation,
      counter: ticket.counter,
      tickets: this.tickets.value,
      isCopyTicket: true,
      sprintId: ticket.sprintId || null,
      isNeedToSelectObject: true,
    };

    this.minimizeService.minimizeInit(modalRef);
  }

  addTicket(isEpic: boolean = false, isBacklog = false, sprintId = '') {
    const modalRef = this.modalService.open(BoardTicketModalComponent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
      scrollable: true,
      beforeDismiss: () => modalRef.componentInstance.closeImagePreview(true),
    });
    modalRef.componentInstance.ticketData = {
      id: '',
      object: this.object,
      objectId: this.objectId,
      boardColorClass: 'board-color-grey',
      data: {
        title: '',
        description: '',
        columnId: isBacklog ? this.objectId : null,
        parentId: null,
        estimate: null,
        realTime: '',
        isBlocked: false,
      },
      tickets: this.tickets.value,
      ticketCreator: this.user.value._id,
      showToastMessage: true,
      isEpic,
      ...(sprintId ? { sprintId } : {}),
    };

    this.minimizeService.minimizeInit(modalRef);
  }

  createEpic() {
    const modalRef = this.modalService.open(EpicTicketComponent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
      beforeDismiss: () => modalRef.componentInstance.closeImagePreview(true),
    });
    modalRef.componentInstance.ticketData = {
      id: '',
      object: this.object,
      objectId: this.objectId,
      boardColorClass: 'board-color-grey',
      data: {
        title: '',
        description: '',
        columnId: null,
        parentId: null,
        estimate: null,
        realTime: '',
        isBlocked: false,
      },
      ticketCreator: this.user.value._id,
      isEpic: true,
    };
  }

  public editTicket(ticket: BoardTicket | BacklogTicket) {
    const draft = this.draftService.isHasActiveDraft(ticket._id);

    const object = this.object || (ticket.object as 'projects' | 'spaces');
    const objectId = this.objectId || ticket.objectId;

    if (draft) {
      this.draftService.openTicketOrDraft(draft, {
        id: ticket._id,
        object,
        objectId,
        parentId: ticket.parentId,
        boardColorClass: this.getColumnBoardColor(ticket.columnId),
        boardAbbreviation: ticket.boardAbbreviation,
        counter: ticket.counter,
        tickets: this.tickets.value,
      });
    } else {
      const modalRef = this.modalService.open(BoardTicketModalComponent, {
        size: 'xl',
        backdrop: 'static',
        keyboard: false,
        scrollable: true,
        beforeDismiss: () => modalRef.componentInstance.closeImagePreview(true),
      });
      modalRef.componentInstance.ticketData = {
        id: ticket._id,
        object,
        objectId,
        parentId: ticket.parentId,
        boardColorClass: this.getColumnBoardColor(ticket.columnId),
        boardAbbreviation: ticket.boardAbbreviation,
        counter: ticket.counter,
        tickets: this.tickets.value,
      };

      this.minimizeService.minimizeInit(modalRef);
    }
  }

  public updateTicketsOrders(
    ticketId: string,
    tickets: Array<TicketsDbDto>,
    sprintId = null,
    isBacklog = false,
  ) {
    this.store.dispatch(
      new TicketsUpdateOrders({
        ticketIdToUpdate: ticketId,
        object: this.object,
        objectId: this.objectId,
        sprintId: sprintId,
        tickets,
        isBacklog,
      }),
    );
  }

  public isTicketsInOrder(tickets: Array<BoardTicket | BacklogTicket>, selector: SelectorEnum) {
    const ticketsToCheck = tickets.sort((a, b) => a[selector] - b[selector]);
    for (let i = 0; i < ticketsToCheck.length - 1; i++) {
      // Compare current element with the next element
      if (ticketsToCheck[i][selector] >= ticketsToCheck[i + 1][selector]) {
        return false;
      }
    }

    return true;
  }

  public deleteTicket(ticketId, isArchive = false) {
    const ticket = this.store
      .selectSnapshot(isArchive ? BoardsState.getTicketsArchiveList : BoardsState.getTicketsList)
      .find((item) => item._id === ticketId);
    if (ticket) {
      ConfirmAlert(null, {
        platform: this.platform,
        confirmButtonText: this.translocoService.translate('alert.confirm-button'),
        cancelButtonText: this.translocoService.translate('alert.btn-cancel'),
        text: this.translocoService.translate('alert.default-text'),
        subject: this.translocoService.translate('alert.delete-column', {
          value: `${this.translocoService.translate('alert.ticket-title')} "${ticket.title}"`,
        }),
      }).then(
        () => {
          this.store
            .dispatch(new TicketsDelete({ ticketDeleteId: ticketId }))
            .pipe(untilDestroyed(this), take(1))
            .subscribe(
              () =>
                this.handleTicketsColumnsProcessing(
                  this.translocoService.translate('toastr.ticket-deleted'),
                  'Success',
                ),
              (err) =>
                this.toastr.error(
                  err.message,
                  this.translocoService.translate('toastr.title-error'),
                ),
            );
        },
        () => {},
      );
    }
  }

  public addColumn(newColumn = null) {
    return this.store
      .dispatch(
        new ColumnsCreate({
          columnTitle: newColumn,
          object: this.object,
          objectId: this.objectId,
        }),
      )
      .pipe(untilDestroyed(this), take(1));
  }

  public updateColumn(id, body, columns, type) {
    this.store
      .dispatch(new ColumnsUpdate({ columnUpdateId: id, body: body, columns }))
      .pipe(untilDestroyed(this), take(1))
      .subscribe(
        () => {
          if (type !== 'order') {
            this.toastr.success(
              this.translocoService.translate('toastr.column-has-been-updated'),
              this.translocoService.translate('toastr.title-success'),
            );
          }
        },
        (err) =>
          this.toastr.error(err.message, this.translocoService.translate('toastr.title-error')),
      );
  }

  public updateColumnsOrders(columns: Array<ColumnsDbDto>) {
    this.store.dispatch(
      new ColumnsUpdateOrders({
        object: this.object,
        objectId: this.objectId,
        columns,
      }),
    );
  }

  public deleteColumn(column: ColumnsDbDto) {
    if (column) {
      ConfirmAlert(null, {
        confirmButtonText: this.translocoService.translate('alert.confirm-button'),
        cancelButtonText: this.translocoService.translate('alert.btn-cancel'),
        text: this.translocoService.translate('alert.default-text'),
        subject: this.translocoService.translate('alert.delete-column', {
          value: `${this.translocoService.translate('alert.column-title')} "${column.title}"`,
        }),
        platform: this.platform,
      }).then(
        () => {
          this.store
            .dispatch(new ColumnsDelete({ columnDeleteId: column._id }))
            .pipe(untilDestroyed(this), take(1))
            .subscribe(
              () =>
                this.handleTicketsColumnsProcessing(
                  this.translocoService.translate('toastr.column-deleted'),
                  'Success',
                ),
              (err) =>
                this.toastr.error(
                  err.message,
                  this.translocoService.translate('toastr.title-error'),
                ),
            );
        },
        () => {},
      );
    }
  }

  public getColumnBoardColor(columnId: string): string {
    const columnColor: any = this.store
      .selectSnapshot(BoardsState.getColumnsList)
      ?.find((col: any) => col._id === columnId);
    return columnColor?.boardColorClass ? columnColor.boardColorClass : '';
  }

  public handleChangeTicketWithSprint(
    tickets: Array<TicketsDbDto>,
    activeSprint: SprintModal | SprintsDbDto,
  ) {
    const archiveColumn = this.store
      .selectSnapshot(BoardsState.getColumnsList)
      ?.find((item) => item.title === 'ARCHIVE');
    const archiveColumnId = archiveColumn ? archiveColumn._id : null;
    const sortTicketsByColumns = (
      ticketsToSort: Array<TicketsDbDto>,
    ): Array<{ _id: string; tickets: Array<TicketsDbDto> }> =>
      ticketsToSort.reduce(
        (
          ticketsByColumns: Array<{
            _id: string;
            tickets: Array<TicketsDbDto>;
          }>,
          ticket,
        ) => {
          const index = ticketsByColumns.findIndex(
            (column) => column._id === ticket.columnId && column._id !== archiveColumnId,
          );
          if (index > -1) {
            ticketsByColumns[index].tickets.push(ticket);
          } else {
            ticketsByColumns.push({ _id: ticket.columnId, tickets: [ticket] });
          }
          return ticketsByColumns;
        },
        [],
      );

    const recount = (ticketsToRecount: Array<TicketsDbDto>): Array<TicketsDbDto> =>
      ticketsToRecount
        .sort((a, b) => a.order - b.order)
        .map((t, index) => ({
          ...t,
          order: index,
        }));

    const recountTicketsByColumns = (columnsToRecount) =>
      columnsToRecount.reduce((acc: Array<TicketsDbDto>, column) => {
        acc.push(...recount(column.tickets));
        return acc;
      }, []);

    if (this.isSprintBoard) {
      if (!activeSprint) {
        return [];
      }

      const filteredTicketsByActiveSprint = tickets.filter(
        (ticket) => !ticket.isDeleted && ticket.sprintId === activeSprint._id,
      );

      return recountTicketsByColumns(sortTicketsByColumns(filteredTicketsByActiveSprint));
    } else {
      if (this.isKanbanBoard) {
        const filteredTicketsByBoard = tickets.filter(
          (ticket) => !ticket.isDeleted && !ticket.sprintId,
        );
        return recountTicketsByColumns(sortTicketsByColumns(filteredTicketsByBoard));
      } else {
        return tickets.filter((ticket) => !ticket.isDeleted);
      }
    }
  }

  public get isSprintBoard(): boolean {
    return this.router.url.includes('/sprint') || this.router.url.includes('subTab=sprint');
  }

  public get isBacklog(): boolean {
    return this.router.url.includes('/backlog') || this.router.url.includes('subTab=backlog');
  }

  public get isKanbanBoard(): boolean {
    return (
      this.router.url.includes('/kanban') ||
      (this.router.url.includes('tab=tabBoard') && this.router.url.includes('subTab=kanban'))
    );
  }

  filterTicketsByMember(userId) {
    if (!this.filteredMembers.includes(userId)) {
      this.filteredMembers = [...this.filteredMembers, userId];
    } else {
      this.filteredMembers = this.filteredMembers.filter((id) => id !== userId);
    }

    this.filteredTickets.next(this.filterTickets(this.tickets.value));
  }

  getBoardMembersList(users: Array<UsersDbDto> = []) {
    let tmpTicketsMembers = [];
    let membersWithNumberOfTickets = [];
    let tmpTickets = [];

    const archiveColumn = this.store
      .selectSnapshot(BoardsState.getColumnsList)
      ?.find((item) => item.title === 'ARCHIVE');
    const archiveColumnId = archiveColumn ? archiveColumn._id : null;

    const boardColumnsId = this.store
      .selectSnapshot(BoardsState.getColumnsList)
      ?.filter((item) => item.title !== 'ARCHIVE')
      ?.map((item) => item._id);

    switch (this.view) {
      case 'board':
        tmpTickets = this.tickets.value?.filter((item) => boardColumnsId?.includes(item.columnId));
        break;
      case 'backlog':
        tmpTickets = this.tickets.value?.filter((item) => item.columnId !== archiveColumnId);
        break;
      case 'archive':
        tmpTickets = this.tickets.value?.filter((item) => item.columnId === archiveColumnId);
        break;
      default:
        return;
    }

    tmpTickets?.forEach((ticket) => {
      ticket.ticketsMembers?.forEach(({ userId }) => {
        if (!tmpTicketsMembers.includes(userId)) {
          tmpTicketsMembers = [...tmpTicketsMembers, userId];
          membersWithNumberOfTickets = [
            ...membersWithNumberOfTickets,
            {
              ...users.filter((user) => user['_id'] === userId).pop(),
              numberOfAssignedTickets: 1,
            },
          ];
        } else {
          membersWithNumberOfTickets?.forEach((item) => {
            if (item._id === userId) {
              item.numberOfAssignedTickets++;
            }
          });
        }
      });
    });

    membersWithNumberOfTickets.sort((a, b) =>
      a.numberOfAssignedTickets < b.numberOfAssignedTickets ? 1 : -1,
    );

    this.ticketsMembers = membersWithNumberOfTickets;
    this.moreMembers = this.moreMembers = membersWithNumberOfTickets.filter(
      (_, index) =>
        index >
        (this.isMobile ? this.MAX_LINED_UP_BOARD_MEMBERS_MOBILE : this.MAX_LINED_UP_BOARD_MEMBERS) -
          1,
    );
  }

  public filterTickets(
    ticketsToFilter: TicketsDbDto[] = [],
    isFromKanbanBoard = false,
  ): TicketsDbDto[] {
    let tmpTickets: Array<TicketsDbDto> = [];

    if (this.filteredMembers?.length) {
      const ticketsForSprint = [];
      ticketsToFilter.forEach((ticket) => {
        ticket.ticketsMembers.forEach((member) => {
          if (this.filteredMembers.includes(member.userId) && !ticketsForSprint.includes(ticket)) {
            ticketsForSprint.push(ticket);
          }
        });
      });
    }

    if (this.filteredMembers.length) {
      ticketsToFilter.forEach((ticket) => {
        ticket.ticketsMembers.forEach((member) => {
          if (this.filteredMembers.includes(member.userId) && !tmpTickets.includes(ticket)) {
            const isTicketValid =
              (this.isSprintBoard && ticket.sprintId === this.activeSprint.value._id) ||
              (this.isKanbanBoard && !ticket.sprintId) ||
              (this.isBacklog && !ticket.isDeleted);
            if (isTicketValid) {
              tmpTickets.push(ticket);
            }
          }
        });
      });
    }

    if (this.filteredLabels.length) {
      const tickets =
        !this.filteredMembers.length || !tmpTickets.length ? ticketsToFilter : tmpTickets;
      const newTmpTickets = [];
      tickets.forEach((ticket) => {
        if (ticket.labels) {
          ticket.labels.forEach((labelId) => {
            if (this.filteredLabels.includes(labelId) && !newTmpTickets.includes(ticket)) {
              newTmpTickets.push(ticket);
            }
          });
        }
      });
      tmpTickets = newTmpTickets;
    }

    if (!this.filteredLabels.length && !this.filteredMembers.length) {
      tmpTickets = this.handleChangeTicketWithSprint(this.tickets.value, this.activeSprint.value);
    }

    if (this.searchedTickets.value.length || this.isSearch) {
      tmpTickets = tmpTickets.filter((ticket) =>
        this.searchedTickets.value.some((t) => t._id === ticket._id),
      );
    }

    if (isFromKanbanBoard) {
      // exclude tickets from backlog column
      const backlogColumnId = this.objectId;
      tmpTickets = tmpTickets.filter((ticket) => ticket.columnId !== backlogColumnId);
    }
    return tmpTickets;
  }

  filterTicketsByLabels(labels) {
    this.filteredLabels = labels;
    this.filteredTickets.next(this.filterTickets(this.tickets.value));
  }

  clearFilters() {
    this.filteredMembers = [];
    this.filteredTickets.next(this.filterTickets(this.tickets.value));
  }

  searchTickets(text) {
    this.filteredMembers = [];
    this.store
      .dispatch(
        new TicketsGetList({
          object: this.object,
          objectId: this.objectId,
          ...(text?.trim() ? { search: text.trim() } : {}),
          isInternalState: true,
        }),
      )
      .subscribe(() => {
        this.isSearch = true;
        this.searchedTickets.next(this.ticketService.getTickets());
        this.filteredTickets.next([]);
      });
  }

  clearSearch() {
    this.isSearch = false;
    this.searchedTickets.next([]);
    this.searchTickets(null);
  }

  routerListener(): void {
    const params = this.activatedRoute.snapshot.queryParams;

    if (params.ticket) {
      const filterTicketsByBoard = (tickets) => {
        const boardTickets = tickets.filter((ticket) => !ticket.isDeleted && !ticket.isEpic);
        const ticketsForModal = [];

        if (boardTickets.length) {
          if (this.isSprintBoard) {
            ticketsForModal.push(...boardTickets.filter((ticket) => ticket.sprintId));
          } else if (this.isKanbanBoard) {
            ticketsForModal.push(...boardTickets.filter((ticket) => !ticket.sprintId));
          } else {
            ticketsForModal.push(...boardTickets);
          }
        }

        return ticketsForModal;
      };

      const ticketsInternal = this.tickets.value;

      if (ticketsInternal.length) {
        this.openTicketByURL(params.ticket, filterTicketsByBoard(ticketsInternal));
      } else {
        this.store
          .dispatch(
            new TicketsGetList({
              object: this.object,
              objectId: this.objectId,
              isInternalState: true,
            }),
          )
          .subscribe(() => {
            this.openTicketByURL(
              params.ticket,
              filterTicketsByBoard(this.ticketService.getTickets()),
            );
          });
      }
    }
  }

  private openTicketByURL(ticketId: string, boardTickets: Array<TicketsDbDto>): void {
    if (ticketId && !this.isOpenedTicket && boardTickets.length) {
      const isEpic = !!this.store
        .selectSnapshot(BoardsState.getEpicsList)
        ?.find((epic) => epic._id === ticketId)?.isEpic;

      const draft = this.draftService.isHasActiveDraft(ticketId);

      if (draft) {
        this.draftService.openTicketOrDraft(draft, {
          id: ticketId,
          object: this.object,
          objectId: this.objectId,
          tickets: boardTickets,
          isEpic,
        });
      } else {
        const modalRef = this.modalService.open(BoardTicketModalComponent, {
          size: 'xl',
          backdrop: 'static',
          keyboard: false,
          scrollable: true,
          beforeDismiss: () => modalRef.componentInstance.closeImagePreview(true),
        });
        modalRef.componentInstance.ticketData = {
          id: ticketId,
          object: this.object,
          objectId: this.objectId,
          tickets: boardTickets,
          isEpic,
        };

        this.minimizeService.minimizeInit(modalRef);
      }
    }
  }
}
