<mwl-calendar-day-view
  [viewDate]="event.start"
  [events]="viewEvents$ | async"
  [refresh]="eventRefresh"
  [hourSegments]="hourSegments"
  [hourSegmentHeight]="hourSegmentHeight"
  (eventTimesChanged)="eventTimesChanged($event)"
  (eventClicked)="dayClicked($event)"
  (hourSegmentClicked)="hourClicked($event)"
  [eventTemplate]="defaultTemplate"
  [eventTitleTemplate]="eventTitleTemplate"
  [tooltipTemplate]="tooltipTemplate"
></mwl-calendar-day-view>

<ng-template #eventTitleTemplate let-event="event" let-view="view">
  <div class="flex justify-between h-full px-2 text-black">
    @if (!event.meta.isForeignEvent) {
      <span class="my-auto flex-grow">
        {{ event.title }}
      </span>
      <div class="my-auto">
        {{ event.start | moment: 'H:mm' }} - {{ event.end | moment: 'H:mm' }}
        {{ event.end | moment: 'A' }}
      </div>
    }
  </div>
</ng-template>

<ng-template #tooltipTemplate let-event="event" let-view="view">
  @if (event.meta?.isForeignEvent) {
    <div class="cal-tooltip opacity-100 cal-tooltip-top shadow-xl">
      <div class="bg-white w-48 rounded-lg flex flex-col gap-3 justify-start p-2 shadow-xl">
        <header>
          <span class="text-lg font-bold">
            {{ event.meta.ownerName }}
          </span>
        </header>
        <article class="flex flex-col gap-1">
          <div class="flex gap-1 text-xs align-baseline">
            <svg-icon [src]="getIcon('small-date')" class="w-4 flex justify-start" />
            <span class="flex-grow m-auto"> {{ event.start | moment: 'd MMM yyyy' }} </span>
          </div>
          @for (otherEvent of event.meta.otherOwnerEvents; track $index) {
            <div class="flex gap-1 text-xs align-baseline">
              <svg-icon [src]="getIcon('time')" class="w-4 flex justify-start" />
              <span class="flex-grow m-auto">
                {{ otherEvent.start | moment: 'H:mm' }} {{ otherEvent.end | moment: 'A' }} -
                {{ otherEvent.end | moment: 'H:mm' }}
                {{ otherEvent.end | moment: 'A' }}
              </span>
            </div>
          }
        </article>
      </div>
    </div>
  } @else {
    <div class="cal-tooltip cal-tooltip-top"></div>
  }
</ng-template>

<ng-template
  #defaultTemplate
  let-weekEvent="weekEvent"
  let-tooltipPlacement="tooltipPlacement"
  let-eventClicked="eventClicked"
  let-tooltipTemplate="tooltipTemplate"
  let-tooltipAppendToBody="tooltipAppendToBody"
  let-tooltipDisabled="tooltipDisabled"
  let-tooltipDelay="tooltipDelay"
  let-column="column"
  let-daysInWeek="daysInWeek"
>
  <div
    class="cal-event"
    [ngStyle]="{
      color: weekEvent.event.color?.secondaryText,
      backgroundColor:
        weekEvent.event.meta.isForeignEvent && isHovering(weekEvent.event.meta?.ownerName)
          ? '#D2C5F1'
          : weekEvent.event.color?.secondary,
      borderColor: weekEvent.event.color?.primary,
    }"
    (mouseenter)="hoveringOwner = weekEvent.event.meta?.ownerName"
    (mouseleave)="hoveringOwner = ''"
    [mwlCalendarTooltip]="
      !tooltipDisabled
        ? (weekEvent.event.title
          | calendarEventTitle
            : (daysInWeek === 1 ? 'dayTooltip' : 'weekTooltip')
            : weekEvent.tempEvent || weekEvent.event)
        : ''
    "
    [tooltipPlacement]="tooltipPlacement"
    [tooltipEvent]="weekEvent.tempEvent || weekEvent.event"
    [tooltipTemplate]="tooltipTemplate"
    [tooltipAppendToBody]="tooltipAppendToBody"
    [tooltipDelay]="tooltipDelay"
    (mwlClick)="eventClicked.emit({ sourceEvent: $event })"
    (mwlKeydownEnter)="eventClicked.emit({ sourceEvent: $event })"
    tabindex="0"
    role="application"
  >
    <mwl-calendar-event-actions [event]="weekEvent.tempEvent || weekEvent.event">
    </mwl-calendar-event-actions>
    &ngsp;
    <mwl-calendar-event-title
      [event]="weekEvent.tempEvent || weekEvent.event"
      [customTemplate]="eventTitleTemplate"
      [view]="daysInWeek === 1 ? 'day' : 'week'"
    >
    </mwl-calendar-event-title>
  </div>
</ng-template>
