<nav
  class="navbar navbar-expand-lg navbar-light header-navbar {{ transparentBGClass }}"
  *transloco="let t"
  [ngClass]="{
    'nav-dark border-dark': config?.layout.variant === 'Dark',
    'nav-light border-light': config?.layout.variant === 'Light',
    'navbar-brand-center': menuPosition === 'Top',
    'navbar-static': menuPosition === 'Side' && config?.layout.navbar.type === 'Static',
    'navbar-sticky': menuPosition === 'Side' && config?.layout.navbar.type === 'Fixed',
    'fixed-top': true,
    'no-sidebar': !showSidebar,
    'navbar-z-index': zIndex$ | async
  }"
>
  <div class="container-fluid navbar-wrapper d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center flex-grow-1">
      <div class="d-flex align-items-center flex-grow-1">
        <div
          class="workspace-title d-flex align-items-center"
          *ngIf="!showEditGroupNameInput"
          t-id="navbar-workspace-title"
        >
          <app-space-avatar
            class="max-w-[34px] max-h-[34px] w-[34px] h-[34px] mr-2 aspect-square"
            *ngIf="laneBoardSpace"
            [space]="laneBoardSpace"
            class="space-avatar mr-2"
          >
          </app-space-avatar>
          <app-space-avatar
            class="max-w-[34px] max-h-[34px] w-[34px] h-[34px] mr-2 aspect-square"
            *ngIf="prefix === 'space' && space"
            [space]="space"
            class="space-avatar mr-2"
          >
          </app-space-avatar>
          <app-project-avatar
            class="max-w-[34px] max-h-[34px] w-[34px] h-[34px] mr-2 aspect-square"
            *ngIf="laneBoardProject"
            [project]="laneBoardProject"
            class="project-avatar mr-1"
          >
          </app-project-avatar>
          <app-project-avatar
            class="max-w-[34px] max-h-[34px] w-[34px] h-[34px] mr-2 aspect-square"
            *ngIf="prefix === 'project' && project"
            [project]="project"
            class="project-avatar mr-1"
          >
          </app-project-avatar>
          <app-group-avatar
            class="max-w-[34px] max-h-[34px] w-[34px] h-[34px] mr-2 aspect-square"
            *ngIf="chat?.type === 'group'"
          ></app-group-avatar>
          <app-avatar
            *ngIf="chat?.type === 'direct'"
            [userId]="chat.userId"
            [width]="36"
            [height]="36"
            [statusSize]="9"
            class="mr-2"
          >
          </app-avatar>

          <span class="d-flex flex-column">
            <span class="title">
              <div *ngIf="chat?.type === 'direct'">
                <ng-container *ngIf="chat.userId | userNameAlone | async as user">
                  {{ user.userName }}
                </ng-container>
              </div>

              <div *ngIf="chat?.type !== 'direct'">
                {{ workspaceTitle }}
              </div>
            </span>
            <a
              *ngIf="(prefix === 'project' || chat?.type === 'group') && space && space.chatId"
              [routerLink]="'/chat/' + space.chatId | routerTenant"
              class="sub-title"
            >
              {{ space.spaceName }}
            </a>
          </span>

          <app-svg
            *ngIf="object && objectId && notifyFilter === 'NOTHING'"
            name="bell-slash"
            width="21"
            height="17"
            class="ml-2"
            triggers="hover"
            [ngbTooltip]="t('full-layout.tooltip-notifications-turned-off')"
            placement="bottom"
          ></app-svg>

          <app-svg
            *ngIf="chat?.type === 'group'"
            t-id="chat-edit-name-chat-group"
            name="pen"
            width="16"
            height="15"
            [hoverEffect]="true"
            class="svg-icon ml-2"
            triggers="hover"
            [ngbTooltip]="t('full-layout.tooltip-edit-group-chat-name')"
            placement="bottom"
            (click)="showGroupNameInput()"
          ></app-svg>
        </div>
        <div>
          <input
            #workspaceTitleInput
            t-id="chat-edit-name-chat-group-input"
            type="text"
            class="form-control workspace-title-input"
            [class.show]="showEditGroupNameInput"
            [value]="chat?.chatName"
            (keydown.enter)="onChangeGroupName($event, chatId)"
            (keydown.escape)="
              workspaceTitleInput.value = chat?.chatName; showEditGroupNameInput = false
            "
            (blur)="showEditGroupNameInput = false"
          />
        </div>

        <div *ngIf="(object && objectId) || chat?.type === 'group'" class="vertical-line"></div>

        <div
          *ngIf="chatId || (object && objectId)"
          class="d-flex align-items-center justify-content-between"
        >
          <app-svg
            *ngIf="object && objectId"
            t-id="navbar-info-icon"
            name="info-circle"
            width="16"
            height="16"
            class="svg-icon nav-action ml-1"
            triggers="hover"
            [ngbTooltip]="
              prefix === 'space'
                ? t('full-layout.tooltip-space-info')
                : t('full-layout.tooltip-project-info')
            "
            placement="bottom"
            [hoverEffect]="true"
            (click)="handlerPageDetailsModal('info')"
          ></app-svg>

          <div
            *ngIf="object && objectId"
            t-id="sub-navbar-members-icon"
            class="d-flex align-items-center nav-action"
            (click)="handlerPageDetailsModal('members')"
          >
            <app-svg
              name="user-group"
              width="20"
              height="16"
              class="svg-icon"
              [hoverEffect]="true"
              triggers="hover"
              [ngbTooltip]="t('full-layout.tooltip-manage-members')"
              placement="bottom"
            ></app-svg>
            <span class="counter">{{ chatMembers?.length }}</span>
          </div>
          <div
            *ngIf="chat?.type === 'group'"
            t-id="sub-navbar-members-icon"
            class="d-flex align-items-center nav-action ml-1"
            (click)="manageGroupChatMembers(space._id)"
          >
            <app-svg
              name="user-group"
              width="20"
              height="16"
              class="svg-icon"
              [hoverEffect]="true"
              triggers="hover"
              [ngbTooltip]="t('full-layout.tooltip-manage-members')"
              placement="bottom"
            ></app-svg>
            <span class="counter">{{ chatMembers?.length }}</span>
          </div>

          <div
            *ngIf="object && objectId && numberOfUnreadActivities !== null"
            class="d-flex align-items-center nav-action"
            (click)="handlerPageDetailsModal('activity')"
          >
            <app-svg
              name="activity"
              width="21"
              height="16"
              class="svg-icon"
              [hoverEffect]="true"
              triggers="hover"
              [ngbTooltip]="t('full-layout.tooltip-activity-stream')"
              placement="bottom"
            ></app-svg>
            <span *ngIf="numberOfUnreadActivities > 0" class="counter">
              {{ numberOfUnreadActivities }}
            </span>
          </div>

          <button
            *ngIf="(checkIsPersonal && object && objectId) || chat?.type === 'group'"
            t-id="navbar-manage-icon"
            class="btn dropdown nav-action btn-settings d-flex align-items-center justify-content-center"
            ngbDropdown
            display="static"
            placement="bottom-left"
            [autoClose]="true"
            (openChange)="actionsDropdownToggle($event)"
          >
            <div
              triggers="hover"
              [ngbTooltip]="t('full-layout.tooltip-manage-by-prefix', { value: prefix })"
              placement="bottom"
            >
              <app-svg
                name="cog"
                width="17"
                height="16"
                class="svg-icon dropdown-toggle"
                [hoverEffect]="true"
                ngbDropdownToggle
              ></app-svg>
            </div>

            <div ngbDropdownMenu class="dropdown-menu">
              <div *ngIf="object === 'spaces'">
                <a
                  href="javascript:"
                  class="dropdown-item"
                  (click)="handlerPageDetailsModal('info')"
                  *ngIf="'spaces::' + objectId + '::spacesUpdate' | checkPermission"
                >
                  <app-svg
                    name="pen"
                    width="20"
                    height="15"
                    class="mr-2"
                    aria-hidden="true"
                  ></app-svg>
                  <span>{{ t('full-layout.btn-edit-space') }}</span>
                </a>
                <a
                  href="javascript:"
                  class="dropdown-item"
                  (click)="openInviteMemberModal('spaces', objectId)"
                  *ngIf="'spaces::' + objectId + '::spacesInvite' | checkPermission"
                >
                  <app-svg
                    name="user-plus"
                    width="20"
                    height="16"
                    class="mr-2"
                    aria-hidden="true"
                  ></app-svg>
                  <span>{{ t('full-layout.btn-invite-members') }}</span>
                </a>
                <a
                  href="javascript:"
                  *ngIf="
                    ('spaces::' + objectId + '::spacesToggleArchiveStatus' | checkPermission) &&
                    !space.isPersonal
                  "
                  class="dropdown-item"
                  (click)="handlerArchiveSpaceModal(space)"
                >
                  <app-svg name="archive" width="20" height="14" class="mr-2"></app-svg>
                  <span>{{ t('full-layout.btn-archive-space') }}</span>
                </a>
                <a
                  href="javascript:"
                  class="dropdown-item"
                  (click)="projectService.createClick(space)"
                  *ngIf="'spaces::' + objectId + '::projectCreate' | checkPermission"
                >
                  <app-svg
                    name="folder-tree"
                    width="20"
                    height="16"
                    class="mr-2"
                    aria-hidden="true"
                  ></app-svg>
                  <span>{{ t('full-layout.btn-add-project') }}</span>
                </a>
                <a
                  href="javascript:"
                  class="dropdown-item"
                  (click)="spaceService.chatGroupCreate(space, platform)"
                >
                  <app-svg
                    name="comments-question-check"
                    width="20"
                    height="16"
                    class="mr-2"
                    aria-hidden="true"
                  ></app-svg>
                  <span>{{ t('full-layout.btn-add-group-chat') }}</span>
                </a>
              </div>

              <div *ngIf="object === 'projects'">
                <a
                  href="javascript:"
                  class="dropdown-item"
                  (click)="handlerPageDetailsModal('info')"
                  *ngIf="'projects::' + objectId + '::projectUpdate' | checkPermission"
                >
                  <app-svg
                    name="pen"
                    width="20"
                    height="15"
                    class="mr-2"
                    aria-hidden="true"
                  ></app-svg>
                  <span>{{ t('full-layout.btn-edit-project') }}</span>
                </a>
                <a
                  href="javascript:"
                  class="dropdown-item"
                  (click)="openInviteMemberModal('projects', objectId)"
                  *ngIf="'projects::' + objectId + '::projectInvite' | checkPermission"
                >
                  <app-svg
                    name="user-plus"
                    width="20"
                    height="16"
                    class="mr-2"
                    aria-hidden="true"
                  ></app-svg>
                  <span>{{ t('full-layout.btn-invite-members') }}</span>
                </a>
                <a
                  href="javascript:"
                  *ngIf="'projects::' + objectId + '::projectToggleArchiveStatus' | checkPermission"
                  class="dropdown-item"
                  (click)="handlerArchiveProjectModal(project)"
                >
                  <app-svg name="archive" width="20" height="16" class="mr-2"></app-svg>
                  <span>{{ t('full-layout.btn-archive-project') }}</span>
                </a>
              </div>

              <div *ngIf="chat && chat.type === 'group'">
                <a
                  href="javascript:"
                  class="dropdown-item"
                  (click)="spaceService.editGroupChat(chatId, objectId)"
                >
                  <app-svg
                    name="pen"
                    width="20"
                    height="15"
                    class="mr-2"
                    aria-hidden="true"
                  ></app-svg>
                  <span>{{ 'chat.btn-edit-group' | transloco }}</span>
                </a>
                <a
                  href="javascript:"
                  class="dropdown-item"
                  (click)="spaceService.manageGroupChatMembers(chatId, objectId)"
                >
                  <app-svg
                    name="user-group"
                    width="20"
                    height="16"
                    class="mr-2"
                    aria-hidden="true"
                  ></app-svg>
                  <span>{{ t('full-layout.btn-manage-members') }}</span>
                </a>
                <a
                  *ngIf="'spaces::' + objectId + '::webhookCreate' | checkPermission"
                  href="javascript:"
                  class="dropdown-item"
                  (click)="
                    spaceService.manageGroupChatIntegrations(chatId, space?._id, chat?.chatName)
                  "
                >
                  <app-svg
                    name="plus"
                    width="20"
                    height="16"
                    class="mr-2"
                    aria-hidden="true"
                  ></app-svg>
                  <span>{{ t('full-layout.btn-manage-integrations') }}</span>
                </a>
                <!-- TODO: need add: && 'chats::' + chatId + '::deleteGroup' | checkPermission -->
                <a
                  *ngIf="isGroupChatOwner"
                  href="javascript:"
                  class="dropdown-item"
                  (click)="spaceService.deleteGroupChat(chatId)"
                  t-id="chat-delete-chat-group-action"
                >
                  <app-svg
                    name="trash"
                    width="20"
                    height="16"
                    class="mr-2"
                    aria-hidden="true"
                  ></app-svg>
                  <span>{{ t('full-layout.btn-delete-group-chat') }}</span>
                </a>
              </div>
            </div>
          </button>
        </div>
      </div>

      <div
        class="video-call d-flex align-items-center mr-1"
        [class.has-active-call]="currentCallRoom"
      >
        <ng-container *ngTemplateOutlet="videoCallParticipants"></ng-container>

        <div *ngIf="chatId">
          <div
            *ngIf="!currentCallRoom"
            class="svg-icon call-btn"
            triggers="hover"
            [ngbTooltip]="t('full-layout.tooltip-start-call')"
            placement="bottom"
            (click)="openVideoCall()"
          >
            <app-svg name="phone" [height]="16" [width]="16"></app-svg>
          </div>

          <button
            *ngIf="currentCallRoom"
            class="btn btn-outline btn-sm d-flex align-items-center"
            [class.ripple-button]="
              currentCallRoom.targetObjectId === chatId ||
              currentCallRoom.targetObjectData['objectId'] === objectId
            "
            (click)="openVideoCall(currentCallRoom.videoCallId)"
          >
            <app-svg name="video" class="mr-1" [height]="10" [width]="15"></app-svg>
            <span class="button-text">{{ t('full-layout.btn-join-call') }}</span>
          </button>
        </div>
      </div>
    </div>

    <div class="navbar-container ml-1">
      <div class="navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav d-flex flex-row align-items-center">
          <li
            *ngIf="(recordService.recordStartedFrom | async) === recordStartedFrom.Ticket"
            class="d-flex align-items-center cursor-pointer"
            [ngClass]="{
              'record-badge-light': config?.layout.variant === 'Light',
              'record-badge-dark': config?.layout.variant === 'Dark'
            }"
            (click)="openTicketModal(recordService.ticketRecordData)"
          >
            <span>
              {{
                recordService.ticketRecordData?.boardAbbreviation +
                  '-' +
                  recordService.ticketRecordData?.counter
              }}
            </span>
          </li>

          <li
            *ngIf="recordService.isRecording && screenRecordTime"
            class="nav-item mr-1 record-timer"
          >
            {{ screenRecordTime }}
          </li>

          <li
            *ngIf="platform === 'web' || tauriService.isTauri"
            #recordDropdown="ngbDropdown"
            class="nav-item"
            ngbDropdown
            placement="bottom-left"
            display="static"
            [autoClose]="true"
            (openChange)="actionsDropdownToggle($event)"
          >
            <div
              class="dropdown-screen-record svg-icon"
              triggers="hover"
              [ngbTooltip]="t('full-layout.tooltip-record-screen')"
              placement="bottom"
              [disableTooltip]="actionsDropdownOpen"
            >
              <app-svg
                *ngIf="
                  (!!!recordService.recordedFile && !recordService.isRecording) ||
                  recordService.recordType === 'audio'
                "
                name="record"
                width="24"
                height="24"
                aria-hidden="true"
                (click)="screenRecordActions()"
              ></app-svg>

              <button
                *ngIf="
                  (recordService.isRecording || !!recordService.recordedFile) &&
                  recordService.recordType !== 'audio'
                "
                class="navbar-record"
                (click)="screenRecordActions()"
              >
                <app-svg
                  name="record"
                  width="24"
                  height="24"
                  aria-hidden="true"
                  [ngClass]="{
                    'navbar-record_rec': recordService.isRecording,
                    'navbar-record_completed':
                      !recordService.isRecording && !!recordService.recordedFile
                  }"
                ></app-svg>
              </button>
            </div>
            <ul
              *ngIf="!!!recordService.recordedFile && !recordService.isRecording"
              class="dropdown-menu dropdown-menu-right text-left m-0 pb-0"
              ngbDropdownMenu
            >
              <li
                class="dropdown-item"
                data-toggle="dropdown"
                ngbDropdownItem
                (click)="startScreenRec(false)"
              >
                <app-svg
                  name="screen-record"
                  width="24"
                  height="24"
                  [fill2]="config?.layout.variant === 'Dark' ? '#010101' : '#fff'"
                ></app-svg>
                <span class="ml-1">{{ t('full-layout.btn-record-screen') }}</span>
              </li>
              <li
                class="dropdown-item"
                data-toggle="dropdown"
                ngbDropdownItem
                (click)="startScreenRec(true)"
              >
                <app-svg
                  name="screen-record-with-mic"
                  width="24"
                  height="24"
                  [fill2]="config?.layout.variant === 'Dark' ? '#010101' : '#fff'"
                ></app-svg>
                <span class="ml-1">{{ t('full-layout.btn-record-screen-mic') }}</span>
              </li>
            </ul>
            <ul
              *ngIf="!!recordService.recordedFile"
              class="dropdown-menu dropdown-menu-right text-left m-0 pb-0"
              ngbDropdownMenu
            >
              <li
                class="dropdown-item"
                data-toggle="dropdown"
                ngbDropdownItem
                (click)="recordService.downloadRecord()"
              >
                <app-svg name="download" width="16" height="16" class="mr-2"></app-svg>
                <span>{{ t('full-layout.btn-download-record') }}</span>
              </li>
              <li
                class="dropdown-item"
                data-toggle="dropdown"
                ngbDropdownItem
                (click)="openDataRoomUploader()"
              >
                <app-svg name="folder-plus" width="16" height="16" class="mr-2"></app-svg>
                <span>{{ t('full-layout.btn-upload-to-dataroom') }}</span>
              </li>
              <li
                class="dropdown-item"
                data-toggle="dropdown"
                ngbDropdownItem
                (click)="openTicketModal()"
              >
                <app-svg name="board" width="16" height="16" class="mr-2"></app-svg>
                <span>{{ t('full-layout.btn-convert-to-ticket') }}</span>
              </li>
              <li
                class="dropdown-item"
                data-toggle="dropdown"
                ngbDropdownItem
                (click)="recordService.clearRecord()"
              >
                <app-svg name="trash" width="16" height="16" class="mr-2"></app-svg>
                <span>{{ t('full-layout.btn-delete-record') }}</span>
              </li>
            </ul>
          </li>

          <li class="nav-item mx-1">
            <div class="vertical-line"></div>
          </li>

          <app-notifications></app-notifications>

          <!-- <li class="dropdown nav-item" ngbDropdown placement="bottom-left" display="static" [autoClose]="true">
            <div id="drp-notification" class="nav-link dropdown-toggle dropdown-notification svg-icon" ngbDropdownToggle
              data-toggle="dropdown" (click)="notificationsToggled = true">
              <app-svg name="bell" width="16" height="16"></app-svg>
              <div *ngIf="user?.hasUnreadNotifications" class="notification-badge-circle"></div>
            </div>
            <ul class="notification-dropdown dropdown-menu dropdown-menu-right dropdown-menu-media overflow-hidden m-0"
              ngbDropdownMenu>
              <li>
                <app-action-logs object="users" *ngIf="notificationsToggled"></app-action-logs>
              </li>
            </ul>
          </li> -->

          <li
            class="dropdown nav-item"
            placement="bottom-left"
            display="static"
            ngbDropdown
            [autoClose]="'outside'"
          >
            <a
              class="nav-link dropdown-toggle user-dropdown d-flex align-items-end"
              id="dropdownProfile"
              t-id="navbar-profile-menu"
              href="javascript:"
              data-toggle="dropdown"
              ngbDropdownToggle
            >
              <div class="d-flex align-items-center justify-content-start">
                <div class="user d-md-flex flex-column d-none mr-2">
                  <span class="text-right user-name">{{ user?.userName }}</span>
                  <span class="text-right role-name">{{ actualRoleName }}</span>
                </div>
                <app-avatar
                  *ngIf="user"
                  [userId]="user._id"
                  [notShowProfile]="true"
                  [width]="36"
                  [height]="36"
                ></app-avatar>
              </div>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right text-left m-0 pb-0"
              aria-labelledby="dropdownProfile"
              ngbDropdownMenu
            >
              <div *ngIf="platform === 'web'" class="dropdown-item">
                <div class="d-flex align-items-center">
                  <i class="ft-zoom-in mr-2"></i>
                  <span>{{ t('full-layout.scale') }}</span>
                  <div class="btn-group btn-group-sm ml-4">
                    <button
                      type="button"
                      class="btn btn-subtle zoom-out"
                      [disabled]="zoom === '50%'"
                      (click)="setZoom('out')"
                    >
                      <app-svg name="minus" width="14" height="14" aria-hidden="true"></app-svg>
                    </button>
                    <button type="button" class="btn btn-subtle zoom-100" (click)="setZoom()">
                      {{ zoom || '100%' }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-subtle zoom-in"
                      [disabled]="zoom === '200%'"
                      (click)="setZoom('in')"
                    >
                      <app-svg name="plus" width="14" height="14" aria-hidden="true"></app-svg>
                    </button>
                  </div>
                </div>
              </div>

              <a class="dropdown-item">
                <span class="d-flex align-items-center w-100">
                  <i class="ft-moon mr-2"></i>
                  <span class="flex-grow-1 mr-2">{{ t('full-layout.dark-mode') }}</span>
                  <span class="custom-control custom-switch mr-n2">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="darkModeSwitch"
                      #checkbox
                      [checked]="user?.uiTheme === 'Dark'"
                      (change)="switchDarkMode(checkbox.checked)"
                    />
                    <label
                      t-id="navbar-profile-menu-layout-mode-switch"
                      class="custom-control-label"
                      for="darkModeSwitch"
                    ></label>
                  </span>
                </span>
              </a>

              <!-- <div
                class="dropdown"
                placement="top-left"
                display="static"
                ngbDropdown
                [autoClose]="true"
              >
                <a
                  class="dropdown-item"
                  id="statusDropdown"
                  t-id="navbar-status-menu"
                  href="javascript:"
                  data-toggle="dropdown"
                  ngbDropdownToggle
                >
                  <span class="d-flex align-items-center">
                    <i class="ft-circle mr-2"></i>
                    <span>{{ t('full-layout.btn-set-status') }}</span>
                  </span>
                </a>

                <div
                  class="dropdown-menu dropdown-menu-left m-0 pb-0"
                  aria-labelledby="statusDropdown"
                  ngbDropdownMenu
                >
                  <ul class="dropdown-menu dropdown-menu-right text-left m-0 pb-0" ngbDropdownMenu>
                    <li
                      class="dropdown-item"
                      data-toggle="dropdown"
                      ngbDropdownItem
                      (click)="updateStatus(userStatusTypes.AVAILABLE)"
                    >
                      <div t-id="navbar-available" class="d-flex align-items-center">
                        <span
                          class="badge badge-pill badge-danger status-point"
                          [ngStyle]="{ background: userStatusTypes.AVAILABLE }"
                          >&nbsp;&nbsp;</span
                        >
                        <span class="success">{{ t('full-layout.status-available') }}</span>
                      </div>
                    </li>
                    <li
                      class="dropdown-item"
                      data-toggle="dropdown"
                      ngbDropdownItem
                      (click)="updateStatus(userStatusTypes.DO_NOT_DISTURB)"
                    >
                      <div t-id="navbar-do-not-disturb" class="d-flex align-items-center">
                        <span
                          class="badge badge-pill badge-danger status-point"
                          [ngStyle]="{
                            background: userStatusTypes.DO_NOT_DISTURB
                          }"
                          >&nbsp;&nbsp;</span
                        >
                        <span class="error">{{ t('full-layout.status-do-not-disturb') }}</span>
                      </div>
                    </li>
                    <li
                      class="dropdown-item"
                      data-toggle="dropdown"
                      ngbDropdownItem
                      (click)="updateStatus(userStatusTypes.ABSENT)"
                    >
                      <div t-id="navbar-absent" class="d-flex align-items-center">
                        <span
                          class="badge badge-pill badge-danger status-point"
                          [ngStyle]="{ background: userStatusTypes.ABSENT }"
                          >&nbsp;&nbsp;</span
                        >
                        <span class="warning">{{ t('full-layout.status-absent') }}</span>
                      </div>
                    </li>
                    <li
                      class="dropdown-item"
                      data-toggle="dropdown"
                      ngbDropdownItem
                      (click)="updateStatus(userStatusTypes.OFFLINE)"
                    >
                      <div t-id="navbar-offline" class="d-flex align-items-center">
                        <span
                          class="badge badge-pill badge-danger status-point"
                          [ngStyle]="{ background: userStatusTypes.OFFLINE }"
                          >&nbsp;&nbsp;</span
                        >
                        <span>{{ t('full-layout.status-offline') }}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div> -->

              @if (user?.state) {
                <a class="dropdown-item">
                  <span class="d-flex align-items-center" t-id="navbar-user-state">
                    @if (user.state && user.state !== null) {
                      @if (user.state_end) {
                        <span
                          [appTmpTooltip]="user.state_end | date: 'dd/MM/yyyy HH:mm'"
                          class="cursor-pointer"
                        >
                          @if (user.state_icon) {
                            {{ user.state_icon }}
                          } @else {
                            {{ t('user.state.' + user.state + '.emoji') }}
                          }
                        </span>
                      } @else {
                        @if (user.state_icon) {
                          {{ user.state_icon }}
                        } @else {
                          {{ t('user.state.' + user.state + '.emoji') }}
                        }
                      }
                    } @else {
                      <i class="ft-circle mr-2"></i>
                    }
                    <span class="ml-1">{{ user?.state }}</span>
                  </span>
                </a>
              } @else {
                <a class="dropdown-item" (click)="setStatus()">
                  <span class="d-flex align-items-center" t-id="navbar-user-state">
                    <i class="ft-circle mr-2"></i>
                    <span>{{ t('full-layout.btn-set-status') }}</span>
                  </span>
                </a>
              }

              <a class="dropdown-item" (click)="removeState()" *ngIf="user?.state">
                <span class="d-flex align-items-center" t-id="navbar-clear-status">
                  <i class="ft-delete mr-2"></i>
                  <span>{{ t('full-layout.btn-clear-status') }}</span>
                </span>
              </a>

              <a class="dropdown-item" [routerLink]="'/settings/home' | routerTenant">
                <span class="d-flex align-items-center gap-2" t-id="navbar-settings">
                  <i class="ft-settings"></i>
                  <span>{{ t('full-layout.btn-settings') }}</span>
                </span>
              </a>

              <a class="dropdown-item chat-gpt" *ngIf="checkIsOwner">
                <span
                  class="d-flex align-items-center chat-gpt-btn"
                  t-id="navbar-connect-chat-gpt"
                  (click)="openChatGptSettings()"
                >
                  <svg-icon
                    class="mr-1"
                    src="assets/icons/common/{{ chatGptIconName }}.svg"
                  ></svg-icon>
                  <span>{{ chatGptStatusTitle }}</span>
                </span>
              </a>

              <!-- <a *ngIf="checkIsOwner && !isProduction" class="dropdown-item upgrade-item" (click)="!isFreeSubscription && openUserEdit(3)">
                <span *ngIf="!isFreeSubscription" class="d-flex align-items-center">
                  {{ t('full-layout.my-subscription') }}
                </span>

                <app-button
                  *ngIf="isFreeSubscription"
                  typeButton="button"
                  [label]="t('full-layout.upgrade')"
                  [svgPath]="paymentIcon"
                  customClasses="upgrade"
                  [widthFull]="true"
                  (handleClick)="handleOpenPaymentModal()"
                ></app-button>

              </a> -->

              <a class="dropdown-item logout" (click)="logout()">
                <span class="d-flex align-items-center" t-id="navbar-logout-action">
                  <i class="ft-log-out error mr-2"></i>
                  <span class="error">{{ t('full-layout.btn-logout') }}</span>
                </span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>

<ng-template #dataRoomModalTemplate>
  <app-chat-dataroom-location
    [platform]="platform"
    [file]="uploadFile"
    [messageType]="'direct'"
    (closeDataRoomModal)="closeDataRoomModal()"
  ></app-chat-dataroom-location>
</ng-template>

<ng-template #videoCallParticipants>
  <div
    *ngIf="
      currentCallRoom &&
      (currentCallRoom.targetObjectId === chatId ||
        currentCallRoom.targetObjectData['objectId'] === objectId) &&
      currentCallRoom.callParticipants.length > 0
    "
    class="video-call_participants d-flex align-items-center mr-2"
  >
    <ng-container *ngFor="let member of currentCallRoom?.callParticipants; let i = index">
      <div *ngIf="i < configService.MAX_LINED_UP_BOARD_MEMBERS - 1" class="member">
        <app-avatar
          [userId]="member.userId"
          [width]="33"
          [height]="33"
          [isExternal]="member.isExternal"
        ></app-avatar>
      </div>
    </ng-container>

    <div
      *ngIf="
        currentCallRoom?.callParticipants.length > configService.MAX_LINED_UP_BOARD_MEMBERS - 1
      "
      ngbDropdown
      class="d-inline-block"
      autoClose="outside"
    >
      <div
        ngbDropdownToggle
        data-toggle="dropdown"
        class="dropdown-toggle d-flex justify-content-center align-items-center"
        [ngClass]="{
          'more-members-button-light': configService.templateConf?.layout.variant === 'Light',
          'more-members-button-dark': configService.templateConf?.layout.variant === 'Dark'
        }"
      >
        +{{
          currentCallRoom?.callParticipants.length - (configService.MAX_LINED_UP_BOARD_MEMBERS - 1)
        }}
      </div>
      <div ngbDropdownMenu>
        <span
          *ngFor="
            let moreMember of currentCallRoom?.callParticipants
              | slice
                : configService.MAX_LINED_UP_BOARD_MEMBERS - 1
                : currentCallRoom?.callParticipants.length
          "
        >
          <button ngbDropdownItem class="w-100">
            <span class="d-flex">
              <app-avatar
                class="dropdown-avatar"
                [userId]="moreMember.userId"
                [width]="25"
                [height]="25"
                [ngClass]="'mr-2'"
                [showUserName]="true"
                [isDropdownItem]="true"
                [isExternal]="moreMember.isExternal"
              ></app-avatar>
            </span>
          </button>
        </span>
      </div>
    </div>
  </div>
</ng-template>
