import { TenantsSettingsReqDto } from '../../../api/models/tenants-settings-req-dto';

export class GetTenantsSettings {
  static readonly type = '[Tenants] - Get tenants settings';
  constructor() {}
}

export class SetChatGptApiKey {
  static readonly type = '[Tenants] - Set ChatGpt Api-Key';
  constructor(public payload: TenantsSettingsReqDto) {}
}

export class UpdateChatGptApiKey {
  static readonly type = '[Tenants] - Update ChatGpt Api-Key';
  constructor(public payload: TenantsSettingsReqDto) {}
}

export class DeleteChatGptApiKey {
  static readonly type = '[Tenants] - Delete ChatGpt Api-Key';
  constructor() {}
}
