import { SprintsUpdateReqDto } from '../../../api/models/sprints-update-req-dto';
import { SprintsCreateReqDto } from '../../../api/models/sprints-create-req-dto';

export class SprintGet {
  static readonly type = '[Sprint] Get';
  constructor(public payload) {}
}

export class SprintCreate {
  static readonly type = '[Sprint] Create';
  constructor(public payload: SprintsCreateReqDto) {}
}

export class SprintSocketCreate {
  static readonly type = '[Sprint] SocketCreateSprint';
  constructor(public payload) {}
}

export class SprintUpdate {
  static readonly type = '[Sprint] Update';
  constructor(public payload: { id: string; body: SprintsUpdateReqDto }) {}
}

export class SprintSocketUpdate {
  static readonly type = '[Sprint] SprintSocketUpdate';
  constructor(public payload) {}
}

export class SprintDelete {
  static readonly type = '[Sprint] Delete';
  constructor(public payload) {}
}

export class SprintSocketComplete {
  static readonly type = '[Sprint] SprintSocketComplete';
  constructor(public payload) {}
}
