<div class="view-event-modal" *transloco="let t; read: 'modals.calendar-event-view'">
  <header class="border-b-2 p-2 flex flex-row justify-between items-center modal-header">
    <h5 class="text-2xl mb-0 modal-title">{{ t('view-event') }}</h5>
    <div class="cursor-pointer" (click)="close()">
      <svg-icon [src]="getIcon('close')" class="w-8 flex justify-center m-auto" />
    </div>
  </header>
  @if (isLoading) {
    <div class="modal-body grid place-items-center h-full w-full">
      <span class="w-fit">Loading...</span>
    </div>
  } @else {
    <section class="h-full flex flex-col gap-2 p-3 modal-body">
      <div class="flex flex-col gap-1">
        <div class="flex flex-row gap-2 items-center">
          <div
            class="min-w-[15px] w-[15px] h-[15px] rounded-sm"
            [ngClass]="{
              'bg-blue-500': eventData.object === 'spaces',
              'bg-green-500': eventData.object === 'users',
              'bg-yellow-500': eventData.object === 'projects',
            }"
          ></div>
          <span class="font-bold text-2xl">{{ eventData.title }}</span>
        </div>
        <div>
          {{ eventData.start | date: 'EEEE,	MMM d' }} • {{ eventData.start | date: 'shortTime' }} -
          {{ eventData.end | date: 'shortTime' }}
        </div>
      </div>
      <div class="grid grid-cols-[auto_1fr] items-start gap-1">
        <svg-icon [src]="getIcon('guests')" class="w-8 flex mx-auto py-2" />

        <cdk-accordion class="flex flex-col divide-y">
          <cdk-accordion-item
            #accordionItem="cdkAccordionItem"
            class="py-2 flex flex-col gap-2"
            role="button"
            tabindex="0"
            [attr.id]="'accordion-header'"
            [attr.aria-expanded]="accordionItem.expanded"
            [attr.aria-controls]="'accordion-body'"
          >
            <header
              class="w-full flex flex-row items-center gap-2"
              (click)="accordionItem.toggle()"
            >
              <div class="flex flex-row justify-between items-center w-full">
                <div class="flex flex-col gap-1">
                  <span>{{ eventData.participants.length }} guests</span>
                  <span>{{ getParticipantsByIsAttending('accepted').length }} {{ t('yes') }}</span>
                  <span
                    >{{ getParticipantsByIsAttending('invited').length }} {{ t('awaiting') }}</span
                  >
                </div>
                <i
                  class="ft-chevron-down transition-all duration-300 text-xl"
                  [ngClass]="{
                    'rotate-180': accordionItem.expanded,
                  }"
                ></i>
              </div>
            </header>
            <ng-scrollbar
              [autoHeightDisabled]="false"
              visibility="hover"
              thumbClass="white-scrollbars"
            >
              <ul
                [style.display]="accordionItem.expanded ? '' : 'none'"
                class="flex flex-col gap-1 participant-list py-1"
              >
                @for (participant of eventData.participants; track participant.id) {
                  <li class="flex flex-row gap-2 items-center relative">
                    <app-avatar
                      [userId]="participant.id"
                      [showStatus]="false"
                      [showUserName]="true"
                    />
                    @if (participant.status === 'accepted') {
                      <svg-icon [src]="getIcon('check')" class="w-[5px] absolute top-5 left-5" />
                    }
                  </li>
                }
              </ul>
            </ng-scrollbar>
          </cdk-accordion-item>
        </cdk-accordion>

        <svg-icon [src]="getIcon('description')" class="w-8 flex mx-auto py-2" />
        <ng-scrollbar [autoHeightDisabled]="false" visibility="hover" thumbClass="white-scrollbars">
          <quill-view
            [content]="eventData.description ? eventData.description : t('no-description')"
            format="html"
          ></quill-view>
        </ng-scrollbar>
        <svg-icon [src]="getIcon('reminder')" class="w-8 flex mx-auto py-2" />
        <p class="py-2">
          {{ eventData.reminder === 'without' ? t('no-reminder') : eventData.reminder }}
        </p>
        <svg-icon [src]="getIcon('host')" class="w-8 flex mx-auto py-2" />
        <div class="flex flex-col gap-1 py-2">
          <span class="text-lg">{{ getObjectName() }}</span>
          <span class="text-sm">{{ t('created-by') }}: {{ eventData.createdBy }}</span>
        </div>
      </div>
    </section>
  }
  @if (!isLoading) {
    <footer class="flex justify-between p-3 modal-footer">
      <span>{{ t('going?') }}</span>
      <div class="flex gap-2">
        <button
          class="rounded-md border border-accent-200 py-1 px-2 font-semibold"
          (click)="makeUserAttendEvent('declined')"
        >
          {{ t('no') }}
        </button>
        <button
          class="rounded-md border text-accent-100 bg-primary-500 py-1 px-2 font-semibold"
          (click)="makeUserAttendEvent('accepted')"
        >
          {{ t('yes') }}
        </button>
      </div>
    </footer>
  }
</div>
