import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, pairwise, takeUntil } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
  NgbProgressbar,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownButtonItem,
  NgbDropdownItem,
} from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'ngx-localstorage';
import { ToastrService } from 'ngx-toastr';
import { FileItem, FileUploader, FileUploadModule } from 'ng2-file-upload';

import { environment } from '../../../../environments/environment';
import {
  defaultSortByList,
  defaultSortParams,
  SortDirection,
  sortKey,
  SortParams,
} from '../../data/sort-types';
import {
  BreadcrumbsDto,
  DocumentsDbDto,
  FilesDbDto,
  FoldersDbDto,
  LinksDbDto,
  SpacesDbDto,
  UsersPublicFieldsResDto,
} from '../../../api/models';
import { LaneBoardStateModel } from '../../store/models/LaneBoardState';
import { FilesHelper } from '../../utils/files-helper';
import { HtmlHelper } from '../../utils/html-helper';
import { UrlHelper } from '../../utils/url-helper';
import { ConfirmAlert } from '../../alerts/alerts';
import { CheckPermissionPipe } from '../../pipes/check-permission.pipe';
import { RouterTenantPipe } from '../../pipes/router-tenant.pipe';
import { FilesService } from '../../../api/services/files.service';
import { LaneBoardsService } from '../../../api/services/lane-boards.service';
import { RouterQueryService } from '../../services/router-query.service';
import { ConfigService, ITemplateConfig } from '../../services/config.service';
import { DocumentsService } from '../../services/documents.service';
import { SpaceGetUsersList } from '../../store/actions/spaces.action';
import { ProjectGetUsersList } from '../../store/actions/projects.action';
import { GetUsersListByTenant } from '../../store/actions/users.action';
import { ChatsGetPin } from '../../store/actions/chats.action';
import { LaneBoardGetList } from '../../store/actions/lane-board-list.action';
import {
  DocumentGetUrls,
  DocumentSaveInDataroom,
  DocumentsDelete,
  DocumentsDeleteDocument,
  DocumentsFolderCreate,
  DocumentsFolderDelete,
  DocumentsFoldersGet,
  DocumentsFolderUpdate,
  DocumentsGet,
  DocumentsGetPermissions,
  DocumentsLinkAdd,
  DocumentsLinkDelete,
  DocumentsSearchClear,
  DocumentsSearchSet,
  DocumentsSort,
  DocumentsToggleImageGallery,
  DocumentsUpdateDocument,
  DocumentsUpdateLink,
  DocumentUpdateFile,
} from '../../store/actions/documents.action';
import {
  DocPermissionsClearState,
  FilePermissionsUpdate,
  FolderPermissionsUpdate,
  LinkPermissionsUpdate,
} from '../../store/actions/documents-permissions.action';
import {
  TicketsFilesDelete,
  TicketsFilesUpload,
  TicketsGetFilesList,
  TicketsGetShortList,
} from '../../store/actions/board.action';
import { AuthState } from '../../store/states/auth.state';
import { UsersState } from '../../store/states/users.state';
import { ProjectsState } from '../../store/states/projects.state';
import { SpacesState } from '../../store/states/spaces.state';
import { DocumentsState } from '../../store/states/documents.state';
import { ChatsState } from '../../store/states/chats.state';
import { BoardsState } from '../../store/states/boards.state';
import { FileTypeEnum } from './enums/file-type.enum';
import { ImagePath } from './enums/image-path';
import { DataRoomUtiliesService } from './services/data-room-utilies.service';
import { PreviewMediaComponent } from '../../../modals/data-room-modals/preview-media/preview-media.component';
import { DocumentDataRoomComponent } from '../../../modals/data-room-modals/document-data-room/document-data-room.component';
import { MinimizeService } from '../../services/minimize.service';
import { SocketsService } from '../../services/sockets.service';
import { FullImagePreviewService } from '../../services/full-image-preview.service';
import { PinType } from '../chat/chat-pinned-messages/enums/pin-type.enum';
import { PaginationDummyService } from '../../services/pagination-dummy.service';
import { LocalStorageKeys } from '../../../types/local-storage-keys.enum';
import { DocumentTypes } from '../../enums/document-types';
import { DownloadService } from '../../../api/services/download.service';
import { DocumentLinkPipe } from '../../pipes/document-link.pipe';
import { SafeUrlPipe } from '../../pipes/safe-url.pipe';
import { FromNowPipe } from '../../pipes/from-now.pipe';
import { DataRoomGridComponent } from './components/data-room-grid/data-room-grid.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { DataRoomHeaderComponent } from './components/data-room-header/data-room-header.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { AngularSplitModule } from 'angular-split';
import { FocusDirective } from '../../directives/focus.directive';
import { FormsModule } from '@angular/forms';
import { SvgComponent } from '../../svgs/svg/svg.component';
import { AccessControlDocumentsComponent } from '../access-documents/access-control-documents.component';
import {
  NgIf,
  NgFor,
  NgClass,
  NgStyle,
  NgTemplateOutlet,
  AsyncPipe,
  LowerCasePipe,
} from '@angular/common';
import { MixpanelService } from '../../../plugins/mixpanel/mixpanel.service';

export interface DocumentAccess {
  type: string;
  userIds?: string[];
  roles?: string[];
}

export enum TreeType {
  board = 'Board',
  chat = 'Chat',
  storage = 'Storage',
  projectFiles = 'Project Folders',
  laneBoard = 'Notes Board',
}

type Document = FoldersDbDto | FilesDbDto | LinksDbDto | DocumentsDbDto;

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  providers: [DataRoomUtiliesService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    FileUploadModule,
    NgIf,
    NgFor,
    NgbProgressbar,
    AccessControlDocumentsComponent,
    SvgComponent,
    FormsModule,
    FocusDirective,
    AngularSplitModule,
    NgScrollbar,
    NgClass,
    NgStyle,
    NgTemplateOutlet,
    DataRoomHeaderComponent,
    SvgIconComponent,
    DataRoomGridComponent,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    AsyncPipe,
    LowerCasePipe,
    CheckPermissionPipe,
    FromNowPipe,
    SafeUrlPipe,
    DocumentLinkPipe,
  ],
})
export class DocumentsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('modalContent') modalContent: TemplateRef<any>;
  @ViewChild('mediaViewModalContent') mediaViewModalContent: TemplateRef<any>;
  @ViewChild('folderModalContent') folderModalContent: TemplateRef<any>;
  @ViewChild('linkModalContent') linkModalContent: TemplateRef<any>;
  @ViewChild('AccessControlModal') AccessControlModal: TemplateRef<any>;

  @Input() object: string;
  @Input() objectId: string;

  platform = 'web';
  isMobile = false;
  docAccess = 'public';
  treeType = TreeType;
  config: any = {};
  invitedMembers: string[] = [];
  invitedRoles: any[] = [];
  apiRoot = environment.api_root;
  uploader: FileUploader;
  uploadFiles = [];
  maxFileSize = 1073741825;
  actionsPlacement = 'top-right';

  users: any;
  user: UsersPublicFieldsResDto;
  documents: Document[] = [];
  chatDocuments: Document[];
  boardDocuments: Document[];
  laneBoardLinksList: Document[];
  laneBoards: LaneBoardStateModel[] = [];

  foldersTree: Map<string, FoldersDbDto[]>;
  boardFolders: Map<string, FoldersDbDto[]>;
  openFolders = new Set<string>();
  documentsIsLoaded = false;
  foldersIsLoaded = false;
  galleryIsOpened = false;
  breadcrumbs: BreadcrumbsDto[];
  currentFolder: FoldersDbDto;
  currRootFolder = TreeType.storage;
  viewModalRef: NgbModalRef;
  folderModalRef: NgbModalRef;
  linkModalRef: NgbModalRef;
  AccessControlModalRef: NgbModalRef;
  contentModalRef: NgbModalRef;
  modalDocument: any;
  folderModalData: any;
  linkModalData: any;
  dataRoomsSectionsSize: Record<string, number[]>;
  sortBy: string;
  searchValue: string;
  sortByList = [];
  sortDirection: SortDirection;
  isLoading$: Observable<boolean>;
  isCollapsed = {
    chat: true,
    board: true,
    laneBoard: true,
    storage: false,
    projectFiles: false,
  };

  public currentSpace: SpacesDbDto;
  public isListView = false;
  public removeFile = new BehaviorSubject(null);
  public readonly lang = this.localStorageService.get(LocalStorageKeys.language);
  private destinationFolder: FoldersDbDto;
  private highlightDndClassName: string;
  private draggedDocument: FoldersDbDto | FilesDbDto;
  private draggedElementRef: EventTarget;
  private isLoadingSubject = new BehaviorSubject<boolean>(false);

  destroy$: Subject<void> = new Subject<void>();
  previewModal: NgbModalRef;
  initCompleted = false;

  constructor(
    public filesHelper: FilesHelper,
    public dataRoomUtilies: DataRoomUtiliesService,
    readonly cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private routerTenantPipe: RouterTenantPipe,
    private minimizeService: MinimizeService,
    private router: Router,
    private store: Store,
    private modal: NgbModal,
    private activeModal: NgbActiveModal,
    private configService: ConfigService,
    private checkPermissionPipe: CheckPermissionPipe,
    protected downloadMobile: DownloadService,
    protected routerQueryService: RouterQueryService,
    protected documentsService: DocumentsService,
    protected fileService: FilesService,
    protected toastr: ToastrService,
    private readonly localStorageService: LocalStorageService,
    private laneBoardsService: LaneBoardsService,
    private htmlHelper: HtmlHelper,
    private socketsService: SocketsService,
    private urlHelper: UrlHelper,
    private renderer: Renderer2,
    private elRef: ElementRef,
    public fullImagePreviewService: FullImagePreviewService,
    public paginationDummyService: PaginationDummyService,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
  }

  get isDataRoomEmpty(): boolean {
    return (
      this.documentsIsLoaded &&
      !this.documents.length &&
      ((this.currRootFolder === TreeType.storage && !this.foldersTree?.get('root')) ||
        (this.currRootFolder === TreeType.board && !this.boardFolders?.get('root')) ||
        this.currentFolder?.parentId === TreeType.chat)
    );
  }

  ngOnInit() {
    this.store
      .select(AuthState.getUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => (this.user = res));

    this.dataRoomsSectionsSize = this.localStorageService.get('dataRoomsSectionsSize') || {};
    this.initializeSort();
    this.dataRoomUtilies.initiliazeData();
    this.dataRoomUtilies.environment = environment;

    this.removeFile.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (!data) {
        return;
      }

      this.deleteClicked(data);
    });

    this.activatedRoute.data.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (this.objectId === data.objectData._id && this.initCompleted) {
        return;
      }
      this.object = data.object;
      this.objectId = data.objectData._id;

      const chatId = this.store
        .selectSnapshot(ChatsState.getChats)
        .find((chat) => chat.objectId === this.objectId)?._id;
      if (chatId && !this.store.selectSnapshot(ChatsState.getPinMessages)?.length) {
        this.store.dispatch(new ChatsGetPin({ id: chatId }));
      }

      this.store.dispatch([
        new DocumentsFoldersGet({
          object: this.object,
          objectId: this.objectId,
        }),
        new TicketsGetShortList({
          object: this.object,
          objectId: this.objectId,
          short: 'dataRoom',
        }),
      ]);
      this.paginationDummyService.resetOptions();
      switch (this.object) {
        case 'spaces':
          this.currentSpace = this.store
            .selectSnapshot(SpacesState.getLoadedSpaces)
            .find((space) => space._id === this.objectId);
          this.prepareUsers(
            new SpaceGetUsersList({ id: this.objectId, exists: true }),
            SpacesState.getUsers,
          );
          break;
        case 'projects':
          this.prepareUsers(
            new ProjectGetUsersList({ id: this.objectId, exists: true }),
            ProjectsState.getUsers,
          );
          break;
        case 'tenants':
          this.prepareUsers(new GetUsersListByTenant(), UsersState.getTenantUsers);
          break;
      }

      const query = this.activatedRoute.snapshot.queryParams;
      this.updateCurrentFolder(
        query.tree || TreeType.storage,
        query.folder || null,
        !this.isMobile,
      );
    });

    // this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$), take(1)).subscribe((query) => {
    //   if (query?.folder || (query?.folder && query?.file)) {
    //     this.updateCurrentFolder(query.tree || TreeType.storage, query.folder || null, true);
    //   }
    // });

    this.store
      .select(DocumentsState.getFoldersTree)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => (this.foldersTree = res));

    this.store
      .select(ChatsState.getChatFiles)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.chatDocuments = res;
        if (this.currRootFolder === TreeType.chat) {
          this.getFolderDocuments(this.chatDocuments);
        }
      });

    this.dataRoomUtilies.editLink.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.editLinkClicked(val);
    });

    this.dataRoomUtilies.editFolder.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.folderEditClicked(val);
    });

    this.store
      .dispatch(new LaneBoardGetList({ object: this.object, objectId: this.objectId }))
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ LaneBoardList }) => {
        this.laneBoards = LaneBoardList;
      });

    this.store
      .select(BoardsState.getShortTicketsList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => (this.boardFolders = res));

    this.store
      .select(DocumentsState.getDocuments)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (this.currRootFolder !== TreeType.chat && this.currRootFolder !== TreeType.board) {
          this.getFolderDocuments(res);
        }
      });

    this.store
      .select(DocumentsState.getBreadcrumbs)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.breadcrumbs = result;
        this.breadcrumbs.forEach((item) => {
          if (!this.openFolders.has(item._id)) {
            this.openFolders.add(item._id);
          }
        });
        this.cdr.detectChanges();
      });

    this.store
      .select(DocumentsState.getDocumentsIsLoaded)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.documentsIsLoaded = res;
        this.cdr.detectChanges();
      });

    this.store
      .select(DocumentsState.getFoldersIsLoaded)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.foldersIsLoaded = res;
        this.cdr.detectChanges();
      });

    const socket = this.socketsService.get();
    socket.on('notification:send:documentsUpdate', (res) => {
      const documentUpdate = this.documents.some((document) => document._id === res._id);
      if (documentUpdate) {
        this.documents = this.documents.map((document) =>
          document._id === res._id ? res : document,
        );
      }
    });

    this.uploader = new FileUploader({
      authToken: 'Bearer ' + this.store.selectSnapshot(AuthState.getAccessToken),
      autoUpload: false,
      isHTML5: true,
      url: `${this.apiRoot}/files`,
      maxFileSize: this.maxFileSize,
    });

    this.uploaderErrorHandler();
    this.routerListener();

    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.initCompleted = true;
  }

  ngAfterViewInit() {
    this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
        this.highlightDndClassName = this.updateHighlightDndClassName(templateConf);
        this.cdr.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    const socket = this.socketsService.get();
    socket.removeListener('notification:send:documentsUpdate');

    this.destroy$.next();
    this.destroy$.complete();
  }

  // Getters
  public get isFolderCreated(): string {
    return this.folderModalData?.isAddFolder
      ? this.translocoService.translate('data-room.add-new-folder') // 'Add new folder'
      : this.translocoService.translate('data-room.edit-folder'); // 'Edit folder';
  }

  public getIconFile(document): string {
    if (document.content) {
      return ImagePath.File;
    }
    switch (this.filesHelper.getFileType(document.fileName)) {
      case FileTypeEnum.Image:
        return ImagePath.Image;
      case FileTypeEnum.Video:
        return ImagePath.Video;
      case FileTypeEnum.Music:
        return ImagePath.Music;
      case FileTypeEnum.Archive:
        return ImagePath.Archive;
      case FileTypeEnum.Presentation:
        return ImagePath.Presentation;
      default:
        return ImagePath.File;
    }
  }

  getFolderDocuments(documents) {
    this.documents = documents;
    this.cdr.markForCheck();
  }

  isInAdditionalFolder() {
    return this.currRootFolder === TreeType.chat || this.currRootFolder === TreeType.board;
  }

  isChatFolder() {
    return this.currentFolder?.parentId === TreeType.chat;
  }

  isParentBoardFolder() {
    return this.currentFolder?.parentId === TreeType.board;
  }

  isBoardFolder() {
    return (
      this.currentFolder?.parentId === TreeType.board && this.currentFolder?._id === TreeType.board
    );
  }

  isLaneBoardFolder() {
    return (
      this.currentFolder?.parentId === TreeType.laneBoard &&
      this.currentFolder?._id === TreeType.laneBoard
    );
  }

  isEnabledAction() {
    return (
      !this.currentFolder?.parentId ||
      (!this.isChatFolder() && !this.isBoardFolder() && !this.isLaneBoardFolder())
    );
  }

  private prepareUsers(action, state) {
    this.store.dispatch(action);
    this.store
      .select(state)
      .pipe(
        takeUntil(this.destroy$),
        map((usersArr: any[]) => {
          const usersObj = {};
          for (const userItem of usersArr) {
            usersObj[userItem._id] = userItem;
          }
          return usersObj;
        }),
      )
      .subscribe((users) => (this.users = users));
  }

  toggleFolderOpen(id: string, treeType: TreeType) {
    MixpanelService.trackEvent('Data Room: Toggle Folder Open');
    this.openFolders.has(id) ? this.openFolders.delete(id) : this.openFolders.add(id);

    if (this.currentFolder?._id !== id) {
      this.updateCurrentFolder(treeType, id);
    }
  }

  updateCurrentFolder(type, _id: string = null, needCurrFolder = true) {
    MixpanelService.trackEvent('Data Room: Update Current Folder');
    this.paginationDummyService.setPageType(type);
    if (type === TreeType.projectFiles) {
      return;
    }
    this.currRootFolder = type;

    if (!_id) {
      /* if (this.platform !== 'web') {
        const params = Object.keys(this.isCollapsed);
        params.forEach((item) => {
          this.isCollapsed[item] = true;
        });
      } */

      this.isCollapsed[type.toLowerCase()] = false;
    }

    if (needCurrFolder) {
      switch (type) {
        case TreeType.storage:
          this.store
            .dispatch(
              new DocumentsGet({
                _id: _id ? _id : undefined,
                object: this.object,
                objectId: this.objectId,
              }),
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              () => {
                this.store.dispatch(
                  new DocumentsFoldersGet({
                    object: this.object,
                    objectId: this.objectId,
                  }),
                );

                const query = this.activatedRoute.snapshot.queryParams;
                if (query.file) {
                  const file = this.documents.find((item) => item._id === query.file);
                  if (file) {
                    this.viewClicked(file);
                  }
                }

                this.currentFolder = this.store.selectSnapshot(DocumentsState.getCurrentFolder);
                this.routerQueryService.update({
                  tree: type,
                  folder: this.currentFolder['parentId'] === null ? null : _id,
                });
                if (!this.currentFolder['parentId']) {
                  this.currentFolder = {
                    ...this.currentFolder,
                    parentId: type,
                  };
                }

                if (!this.isListView) {
                  this.store.dispatch(new DocumentGetUrls({ id: this.currentFolder._id }));
                }

                this.cdr.detectChanges();
              },
              (error) =>
                this.toastr.show(
                  error.message ??
                    this.translocoService.translate('toastr.error-while-opening-document'),
                  this.translocoService.translate('toastr.title-error'),
                ),
            );
          break;

        case TreeType.chat:
          this.documentsIsLoaded = false;
          if (this.chatDocuments?.length) {
            this.getChatFiles(type);
          } else {
            this.updateDataChat(type);
          }
          break;

        case TreeType.board:
          this.documentsIsLoaded = false;
          if (this.boardFolders) {
            this.getBoardFiles(type, _id || null);
          } else {
            this.updateDataBoard(type, _id);
          }
          break;

        case TreeType.laneBoard:
          this.documentsIsLoaded = false;
          if (this.laneBoards.length) {
            this.getLaneBoardLinks(type);
          } else {
            this.store
              .dispatch(
                new LaneBoardGetList({
                  object: this.object,
                  objectId: this.objectId,
                }),
              )
              .pipe(takeUntil(this.destroy$))
              .subscribe(({ LaneBoardList }) => {
                this.laneBoards = LaneBoardList;
                this.getLaneBoardLinks(type);
              });
          }
          break;
      }
    }
  }

  updateDataChat(type) {
    MixpanelService.trackEvent('Data Room: Update Data Chat', { type });
    this.paginationDummyService
      .onGetItems({ object: this.object, objectId: this.objectId })
      ?.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.getChatFiles(type);
      });
  }

  updateDataBoard(type, _id) {
    this.store
      .dispatch(
        new TicketsGetShortList({
          object: this.object,
          objectId: this.objectId,
          short: 'dataRoom',
        }),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.getBoardFiles(type, _id || null));
  }

  getLaneBoardLinks(type: TreeType) {
    this.breadcrumbs = [{ _id: null, parentId: TreeType.laneBoard, name: TreeType.laneBoard }];

    const documents = this.laneBoards.map(
      (laneBoard): LinksDbDto => ({
        _id: laneBoard._id,
        created_at: laneBoard.created_at,
        folderId: {},
        inNewWindow: false,
        link: this.routerTenantPipe.transform(`notes-board/${laneBoard._id}/${this.objectId}`),
        name: laneBoard.title,
        object: laneBoard.object,
        objectId: laneBoard.objectId,
        ownerUserId: laneBoard.ownerUserId,
        tenantName: laneBoard.tenantName,
        type: TreeType.laneBoard,
        updated_at: laneBoard.updated_at,
      }),
    );

    this.laneBoardLinksList = this.documentsService.sort(
      documents,
      this.sortBy,
      this.sortDirection,
    );

    this.routerQueryService.update({ tree: type, folder: null });
    this.currentFolder = { ...this.currentFolder, _id: type, parentId: type };
    this.getFolderDocuments(this.laneBoardLinksList);

    this.documentsIsLoaded = true;
  }

  getChatFiles(type: TreeType) {
    this.breadcrumbs = [{ _id: null, parentId: TreeType.chat, name: TreeType.chat }];

    this.routerQueryService.update({ tree: type, folder: null });
    this.currentFolder = { ...this.currentFolder, _id: type, parentId: type };
    this.getFolderDocuments(this.chatDocuments);
    this.documentsIsLoaded = true;
  }

  getBoardFiles(type: TreeType, id: string = null) {
    this.breadcrumbs = [{ _id: null, parentId: TreeType.board, name: TreeType.board }];
    this.boardDocuments = [];
    let currFolder = this.currentFolder;
    if (id) {
      currFolder = this.boardFolders?.get('root').find((folder) => folder._id === id);
      this.breadcrumbs.push({
        _id: currFolder._id,
        parentId: TreeType.board,
        name: currFolder.name,
      });
      this.store
        .dispatch(new TicketsGetFilesList({ id: id }))
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.boardDocuments = this.store.selectSnapshot(BoardsState.getTicketFiles)(id);
          this.documentsIsLoaded = true;

          this.routerQueryService.update({ tree: type, folder: id });
          this.currentFolder = {
            ...currFolder,
            _id: id || type,
            parentId: type,
          };
          this.getFolderDocuments(this.boardDocuments);

          this.cdr.detectChanges();
        });
    } else {
      this.boardDocuments = this.documentsService.sort(
        this.boardFolders?.get('root') || [],
        this.sortBy,
        this.sortDirection,
      );
      this.documentsIsLoaded = true;

      this.routerQueryService.update({ tree: type, folder: id });
      this.currentFolder = { ...currFolder, _id: id || type, parentId: type };
      this.getFolderDocuments(this.boardDocuments);
    }
  }

  folderCreateClicked() {
    MixpanelService.trackEvent('Data Room: Folder Create Clicked');
    this.folderModalData = {
      isAddFolder: true,
      name: '',
      object: this.object,
      objectId: this.objectId,
      docAccess: this.docAccess,
      invitedMembers: this.invitedMembers,
      invitedRoles: this.invitedRoles,
    };

    if (!this.currentFolder) {
      this.updateCurrentFolder(TreeType.storage, null);
    }

    this.folderModalRef = this.modal.open(this.folderModalContent, {
      size: 'md',
      keyboard: false,
      backdrop: 'static',
      windowClass: 'folder-create-modal',
      centered: true,
    });
  }

  folderEditClicked(document) {
    MixpanelService.trackEvent('Data Room: Folder Edit Clicked');
    this.store
      .dispatch(
        new DocumentsGetPermissions({
          documentId: document._id,
          type: this.documentIS(document).toLowerCase(),
        }),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        const permissions = res.Documents.permissions;

        this.invitedMembers = permissions?.userIds
          ? permissions?.userIds.filter((item) => item !== this.user._id)
          : [];

        this.docAccess = document.type;
        this.folderModalData = {
          isAddFolder: false,
          id: document._id,
          name: document.name,
          object: document.object,
          objectId: document.objectId,
          invitedMembers: this.invitedMembers,
          invitedRoles: permissions?.roles,
        };

        this.folderModalRef = this.modal.open(this.folderModalContent, {
          size: 'md',
          keyboard: false,
          backdrop: 'static',
          windowClass: 'folder-create-modal',
          centered: true,
        });
      });
  }

  closeFolderModal() {
    MixpanelService.trackEvent('Data Room: Close Folder Modal');
    this.folderModalRef.close();
    this.folderModalData = null;
    this.resetPermissions();
  }

  folderCreateSubmit() {
    MixpanelService.trackEvent('Data Room: Folder Create Submit');
    this.isLoadingSubject.next(true);
    this.store
      .dispatch(
        new DocumentsFolderCreate({
          body: {
            object: this.object,
            objectId: this.objectId,
            name: this.folderModalData.name,
            parentId: this.currentFolder._id,
            ...this.folderAccessObject(),
          },
        }),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.toastr.success(
            this.translocoService.translate('toastr.folder-created'),
            this.translocoService.translate('toastr.title-success'),
          );
          this.updateCurrentFolder(
            TreeType.storage,
            this.store.selectSnapshot(DocumentsState.getLastCreatedFolderId),
          );
          this.folderModalData = null;
          this.closeModals();
          this.resetPermissions();
          this.isLoadingSubject.next(false);
        },
        (err) => {
          this.folderModalData = null;
          this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
          this.isLoadingSubject.next(false);
        },
      );
  }

  folderEditSubmit() {
    MixpanelService.trackEvent('Data Room: Folder Edit Submit');
    this.isLoadingSubject.next(true);
    this.store
      .dispatch(
        new DocumentsFolderUpdate({
          id: this.folderModalData.id,
          body: {
            name: this.folderModalData.name,
            ...this.folderAccessObject(),
          },
        }),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.toastr.success(
            this.translocoService.translate('toastr.folder-updated'),
            this.translocoService.translate('toastr.title-success'),
          );
          this.updateCurrentFolder(TreeType.storage, this.currentFolder._id);
          this.folderModalData = null;
          this.closeModals();
          this.resetPermissions();
          this.isLoadingSubject.next(false);
        },
        (err) => {
          this.folderModalData = null;
          this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
          this.isLoadingSubject.next(false);
        },
      );
  }

  folderAccessObject() {
    if (this.currentSpace?.isPersonal) {
      return { type: 'private' };
    } else if (this.docAccess === 'private') {
      const accessObj: DocumentAccess = { type: 'private' };

      if (this.invitedMembers.length) {
        accessObj.userIds = this.invitedMembers;
      } else {
        accessObj.userIds = [this.user._id as string];
      }

      if (this.invitedRoles.length) {
        accessObj.roles = this.invitedRoles;
      }

      return accessObj;
    } else {
      return { type: 'public' };
    }
  }

  resetPermissions() {
    this.docAccess = 'public';
    this.invitedMembers = [];
    this.invitedRoles = [];
  }

  private resetAfterFileUploading(): void {
    this.uploader.clearQueue();
    this.destinationFolder = null;
    this.resetPermissions();
  }

  uploadClicked() {
    MixpanelService.trackEvent('Data Room: Upload Clicked');
    if (!this.currentFolder) {
      this.updateCurrentFolder(TreeType.storage, null);
    }

    this.contentModalRef = this.modal.open(this.modalContent, {
      size: 'md',
      keyboard: false,
      backdrop: 'static',
      windowClass: 'upload-modal',
      centered: true,
    });
    this.contentModalRef.result
      .then(() => this.resetAfterFileUploading())
      .catch(() => this.resetAfterFileUploading());
  }

  searchAction(value: string) {
    MixpanelService.trackEvent('Data Room: Search Action', { value });
    this.store
      .dispatch(
        new DocumentsSearchSet({
          search: value,
          object: this.object,
          objectId: this.objectId,
        }),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.searchValue = value;
        const searchDocuments = this.store.selectSnapshot(DocumentsState.getSearchDocuments);
        this.documents = [...searchDocuments];

        this.cdr.detectChanges();
      });
  }

  enableSearch(): void {
    MixpanelService.trackEvent('Data Room: Enable Search');
    this.searchValue = '';
    this.documents = this.store.selectSnapshot(DocumentsState.getDocuments);
    this.store.dispatch(new DocumentsSearchClear({}));
  }

  uploadFileSubmit() {
    MixpanelService.trackEvent('Data Room: Upload File Submit');
    this.isLoadingSubject.next(true);

    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
      item.url =
        this.apiRoot +
        (this.currentFolder.parentId === TreeType.board ? '/boards/tickets-files' : '/files');
    };

    this.uploader.onBuildItemForm = (_, form) => {
      if (this.currentFolder.parentId === TreeType.board) {
        form.append('ticketId', this.currentFolder._id);
      } else {
        form.append('folderId', this.destinationFolder?._id || this.currentFolder._id);

        if (this.docAccess === 'private') {
          form.append('type', 'private');

          const userIds = this.invitedMembers.length
            ? this.invitedMembers
            : [this.user._id as string];
          form.append('userIds', JSON.stringify(userIds));

          if (this.invitedRoles.length) {
            form.append('roles', JSON.stringify(this.invitedRoles));
          }
        }
      }
    };

    this.uploader.uploadAll();

    this.uploader.onCompleteAll = () => {
      this.updateCurrentFolder(this.currRootFolder, this.currentFolder._id);
      this.isLoadingSubject.next(false);
      if (!this.uploadHasError()) {
        this.closeModals();
      }
    };
  }

  uploadHasError() {
    let isError = false;
    this.uploader.queue.forEach((item) => {
      if (item.isError) {
        isError = true;
      }
    });
    return isError;
  }

  viewClicked(document) {
    MixpanelService.trackEvent('Data Room: View Clicked');
    const documentType = this.documentIS(document);
    if (documentType === DocumentTypes.Document) {
      this.routerQueryService.update({ document: document._id });
    } else if (documentType === DocumentTypes.File) {
      this.modalDocument = document;

      if (this.filesHelper.getFileType(document.originalFileName) === 'image') {
        if (document.url) {
          this.openMediaModal();
        } else {
          this.fileService.filesGetUrl({ id: document._id }).subscribe((res) => {
            this.modalDocument = { ...document, url: res.url };
            this.openMediaModal();
          });
        }
      } else {
        this.routerQueryService.update({
          tree: this.currRootFolder,
          file: document._id,
        });
        if (!document.url) {
          this.fileService.filesGetUrl({ id: document._id }).subscribe((res) => {
            this.modalDocument = { ...document, url: res.url };
            this.openMediaModal();
          });
        } else {
          this.openMediaModal();
        }
      }
    } else if (documentType === DocumentTypes.Folder) {
      this.updateCurrentFolder(this.currRootFolder, document._id);
    } else if (documentType === DocumentTypes.Link) {
      if (document.inNewWindow) {
        window.open(document.link, '_blank');
      } else {
        if (document.type === TreeType.laneBoard) {
          this.router.navigate([document.link]);
        } else {
          this.modalDocument = document;
          this.openMediaModal();
        }
      }
    }
  }

  protected openMediaModal(): void {
    MixpanelService.trackEvent('Data Room: Open Media Modal');
    const media = this.documents
      .filter((document: any) => document.originalFileName || document.link)
      .map((document) => (document._id === this.modalDocument._id ? this.modalDocument : document));

    media[0] = { ...media[0], isFirstMedia: true };
    media[media.length - 1] = { ...media[media.length - 1], isLastMedia: true };

    this.previewModal = this.modal.open(PreviewMediaComponent, {
      size: 'xl',
      windowClass: this.isMobile ? 'media-view-modal-mobile' : 'media-view-modal',
      centered: true,
    });

    this.previewModal.componentInstance.previewData = {
      platform: this.platform,
      object: this.object,
      objectId: this.objectId,
      isMobile: this.isMobile,
      currentMedia: this.modalDocument,
    };

    this.previewModal.componentInstance.removeFile = this.removeFile;
    this.previewModal.componentInstance.media = media;
    this.previewModal.componentInstance.pinnedType = this.modalDocument?.link && PinType.Links;
    this.previewModal.componentInstance.isNotDataRoom = this.currRootFolder !== TreeType.storage;

    this.minimizeService.minimizeInit(this.previewModal, {
      width: '360px',
      height: '307px',
      right: '40px',
      borderRadius: '10px',
    });

    this.previewModal.componentInstance.isDestroyed
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        if (val) {
          if (this.isChatFolder()) {
            this.updateDataChat(TreeType.chat);
          } else if (this.isParentBoardFolder()) {
            this.updateDataBoard(TreeType.board, this.currentFolder._id);
          }
        }
      });

    this.previewModal.result.then(
      () => {
        this.routerQueryService.update({
          tree: this.currRootFolder,
          file: null,
        });
      },
      () => {
        this.routerQueryService.update({
          tree: this.currRootFolder,
          file: null,
        });
      },
    );
  }

  galleryClosed() {
    MixpanelService.trackEvent('Data Room: Gallery Closed');
    this.galleryIsOpened = false;
    this.routerQueryService.update({ tree: this.currRootFolder, file: null });
    this.store.dispatch(new DocumentsToggleImageGallery(false));
  }

  breadcrumbClicked(id) {
    MixpanelService.trackEvent('Data Room: Breadcrumb Clicked');
    this.updateCurrentFolder(this.currRootFolder, id);
  }

  docAccessChanged(input: string) {
    this.docAccess = input;
    this.invitedMembers = [];
    this.invitedRoles = [];
  }

  invitedMembersChanged(input: string[]) {
    this.invitedMembers = input;
  }

  invitedRolesChanged(input: string[]) {
    this.invitedRoles = input;
  }

  isLimitedAccess(doc) {
    return doc.type === 'private';
  }

  openAccessModal(doc) {
    MixpanelService.trackEvent('Data Room: Open Access Modal');
    this.modalDocument = doc;
    if (doc.type === 'private') {
      this.docAccess = 'private';
    }
    this.AccessControlModalRef = this.modal.open(this.AccessControlModal, {
      size: 'md',
      keyboard: false,
      backdrop: 'static',
      centered: true,
    });
  }

  closeAccessControlModal() {
    MixpanelService.trackEvent('Data Room: Close Access Control Modal');
    this.AccessControlModalRef.close();
    this.resetPermissions();
    this.store.dispatch(new DocPermissionsClearState({}));
  }

  updateDocPermissions(): void {
    let action: FolderPermissionsUpdate | FilePermissionsUpdate | LinkPermissionsUpdate;
    const payload = {
      id: this.modalDocument._id,
      body: {
        type: this.docAccess,
      },
    };

    if (this.documentIS(this.modalDocument) === 'File') {
      action = new FilePermissionsUpdate(payload);
    } else if (this.documentIS(this.modalDocument) === 'Folder') {
      action = new FolderPermissionsUpdate(payload);
    } else if (this.documentIS(this.modalDocument) === 'Link') {
      action = new LinkPermissionsUpdate(payload);
    }

    this.store
      .dispatch(action)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.updateCurrentFolder(TreeType.storage, this.currentFolder._id);
          this.closeModals();
          this.resetPermissions();
          this.store.dispatch(new DocPermissionsClearState({}));
          this.toastr.success(
            this.translocoService.translate('toastr.permissions-updated'),
            this.translocoService.translate('toastr.title-success'),
          );
        },
        (err) => {
          this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
        },
      );
  }

  /**
   * Delete document button handler (emit confirm alert)
   */
  deleteClicked(document) {
    MixpanelService.trackEvent('Data Room: Delete Clicked');
    const documentType = this.documentIS(document);
    ConfirmAlert(documentType, { platform: this.platform }).then(
      () => {
        let action:
          | DocumentsDelete
          | DocumentsFolderDelete
          | DocumentsLinkDelete
          | TicketsFilesDelete
          | DocumentsDeleteDocument;
        if (documentType === DocumentTypes.File) {
          if (document.content) {
            action = new DocumentsDeleteDocument({
              id: document._id,
              body: { object: this.object, objectId: this.objectId },
            });
          } else if (this.currRootFolder === TreeType.storage) {
            action = new DocumentsDelete({
              id: document._id,
              body: { object: this.object, objectId: this.objectId },
            });
          } else if (this.currRootFolder === TreeType.board) {
            action = new TicketsFilesDelete({ id: document._id });
          }
        } else if (documentType === DocumentTypes.Folder) {
          action = new DocumentsFolderDelete({
            id: document._id,
            body: { object: this.object, objectId: this.objectId },
          });
        } else if (documentType === DocumentTypes.Link) {
          action = new DocumentsLinkDelete({
            id: document._id,
            body: { object: this.object, objectId: this.objectId },
          });
        }
        if (action) {
          this.store
            .dispatch(action)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              () => {
                this.toastr.success(
                  this.translocoService.translate('toastr.entity-deleted', {
                    title: documentType || ' ',
                  }),
                  this.translocoService.translate('toastr.title-success'),
                );
                this.updateCurrentFolder(this.currRootFolder, this.currentFolder._id);
              },
              (err) => {
                this.toastr.error(
                  err.message,
                  this.translocoService.translate('toastr.title-error'),
                );
              },
            );
        }
      },
      () => {},
    );
  }

  /**
   * returns document is file or folder or link
   */
  documentIS(document) {
    return this.dataRoomUtilies.documentIS(document);
  }

  isTypeDocument(document) {
    return document.content;
  }

  documentId(index, document: Document): string {
    return document._id;
  }

  getSectionSize(section: number) {
    return this.dataRoomsSectionsSize?.[this.objectId]?.[section];
  }

  onResizeSplitter({ sizes }) {
    this.dataRoomsSectionsSize[this.objectId] = sizes;
    this.localStorageService.set('dataRoomsSectionsSize', this.dataRoomsSectionsSize);
  }

  public changeViewData() {
    this.isListView = !this.isListView;

    if (!this.isListView && this.currRootFolder === TreeType.storage) {
      this.store.dispatch(new DocumentGetUrls({ id: this.currentFolder._id }));
    }
  }

  onSortChange(event: SortParams): void {
    // todo: last

    this.store.dispatch(new DocumentsSort(event));
    this.sortBy = event.sortBy;
    this.sortDirection = event.direction;
    if (this.currRootFolder === TreeType.chat) {
      this.paginationDummyService
        .onGetItems({
          object: this.object,
          objectId: this.objectId,
          sortBy: this.sortBy,
          sort: this.sortDirection && this.sortDirection.toLowerCase(),
          page: 1,
        })
        ?.pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.getChatFiles(TreeType.chat);
        });
    } else if (this.currRootFolder === TreeType.board) {
      this.boardDocuments = this.documentsService.sort(
        this.boardDocuments,
        event.sortBy,
        event.direction,
      );
      this.getFolderDocuments(this.boardDocuments);
    } else if (this.currRootFolder === TreeType.laneBoard) {
      this.laneBoardLinksList = this.documentsService.sort(
        this.laneBoardLinksList,
        event.sortBy,
        event.direction,
      );
      this.getFolderDocuments(this.laneBoardLinksList);
    }

    if (event.updateDirection) {
      return;
    }

    this.updateSortDirection();
  }

  // Drag and Drop
  fileOver(isFileOver: boolean, element: HTMLElement): void {
    isFileOver
      ? this.renderer.addClass(element, this.highlightDndClassName)
      : this.renderer.removeClass(element, this.highlightDndClassName);
  }

  updateSortDirection() {
    if (this.sortDirection === SortDirection.Ascending) {
      this.sortDirection = SortDirection.Descending;
    } else {
      this.sortDirection = SortDirection.Ascending;
    }
  }

  fileMoved(destinationFolder?: FoldersDbDto): void {
    MixpanelService.trackEvent('Data Room: File Moved');
    this.destinationFolder = destinationFolder;
    this.uploadClicked();
  }

  filesDropped(event) {
    MixpanelService.trackEvent('Data Room: Files Dropped');
    const entries = event.dataTransfer.items;
    this.filesHelper.buildTree(entries, '').then((tree) => {
      this.uploadFiles = [];
      this.getDroppedFiles(tree, '');

      if (this.uploadFiles.length) {
        this.uploader.queue = [];
        this.uploadFiles.forEach((file) => {
          if (file.size < this.maxFileSize) {
            const fileItem = new FileItem(this.uploader, file, { url: '' });
            this.uploader.queue.push(fileItem);
          } else {
            this.toastr.error(
              this.translocoService.translate('toastr.err-message-file-size', {
                size: '1GB',
              }),
              file.name,
            );
          }
        });

        this.uploadClicked();
      }
      this.cdr.markForCheck();
    });
    return false;
  }

  private getDroppedFiles(tree, path) {
    tree.files.forEach((file) => {
      this.uploadFiles.push(file);
    });
    tree.directories.forEach((directory) => {
      const newPath = path + directory.name + '/';
      this.getDroppedFiles(directory, newPath);
    });
  }

  onDragStart(event: DragEvent, document: Document): void {
    this.draggedDocument = document as FoldersDbDto | FilesDbDto;
    this.draggedElementRef = event.target;
  }

  onDragEnter(element: HTMLElement): void {
    this.renderer.addClass(element, this.highlightDndClassName);
  }

  onDragOver(event: DragEvent, element: HTMLElement): void {
    event.preventDefault();
    event.stopPropagation();
    this.renderer.addClass(element, this.highlightDndClassName);
  }

  onDragLeave(element: HTMLElement): void {
    this.renderer.removeClass(element, this.highlightDndClassName);
  }

  onDrop(event: DragEvent, destinationFolder: Document): void {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer.files.length) {
      this.uploader.clearQueue();
      this.uploader.addToQueue(Array.prototype.slice.call(event.dataTransfer.files));
      this.fileMoved(destinationFolder as FoldersDbDto);
    } else if (
      this.draggedDocument._id !== destinationFolder._id &&
      (this.draggedDocument as FoldersDbDto).parentId !== destinationFolder._id &&
      (this.draggedDocument as FilesDbDto).folderId !== destinationFolder._id
    ) {
      if (this.currRootFolder === TreeType.storage) {
        if ((destinationFolder as FoldersDbDto).parentId !== TreeType.board) {
          this.moveDocument(destinationFolder as FoldersDbDto);
        } else {
          this.copyToTicket(destinationFolder._id, TreeType.storage);
        }
      } else if (this.isInAdditionalFolder()) {
        if ((destinationFolder as FoldersDbDto).parentId === TreeType.board) {
          this.copyToTicket(destinationFolder._id, TreeType.chat);
        } else {
          this.copyToDataRoom(destinationFolder._id);
        }
      }
    }

    this.renderer.removeClass(
      this.elRef.nativeElement.querySelector(`.${this.highlightDndClassName}`),
      this.highlightDndClassName,
    );

    this.draggedDocument = null;
  }

  copyToTicket(ticketId, type) {
    MixpanelService.trackEvent('Data Room: Copy To Ticket');
    const body = {
      ticketId,
      fileId: this.draggedDocument['_id'],
      isChatFile: type === TreeType.chat ? true : null,
    };

    this.store
      .dispatch(new TicketsFilesUpload(body))
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.toastr.success(
            this.translocoService.translate('toastr.file-copied'),
            this.translocoService.translate('toastr.title-success'),
          );
          this.cdr.detectChanges();
        },
        (err) => {
          this.toastr.error(err?.message, this.translocoService.translate('toastr.title-error'));
        },
      );
  }

  copyToDataRoom(folderId) {
    MixpanelService.trackEvent('Data Room: Copy To Data Room');
    const body = {
      folderId,
      fileId: this.draggedDocument['_id'],
      fileName: this.draggedDocument['fileName'],
      fileSource: this.currRootFolder === TreeType.board ? 'ticketsFiles' : 'chatsFiles',
      type: 'public',
    };

    this.store
      .dispatch(new DocumentSaveInDataroom({ body }))
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.toastr.success(
            this.translocoService.translate('toastr.file-copied'),
            this.translocoService.translate('toastr.title-success'),
          );
          this.cdr.detectChanges();
        },
        (err) => {
          this.toastr.error(err?.message, this.translocoService.translate('toastr.title-error'));
        },
      );
  }

  // Actions
  addLinkClicked(): void {
    MixpanelService.trackEvent('Data Room: Add Link Clicked');
    this.linkModalData = {
      isAddLink: true,
      name: '',
      link: '',
      inNewWindow: false,
      object: this.object,
      objectId: this.objectId,
      docAccess: this.docAccess,
      invitedMembers: this.invitedMembers,
      invitedRoles: this.invitedRoles,
    };

    if (!this.currentFolder) {
      this.updateCurrentFolder(TreeType.storage, null);
    }

    this.linkModalRef = this.modal.open(this.linkModalContent, {
      size: 'md',
      keyboard: false,
      backdrop: 'static',
      windowClass: 'link-create-modal',
      centered: true,
    });
  }

  getDocumentType(documentType: DocumentTypes[keyof DocumentTypes]): 'link' | 'file' | 'folder' {
    if (documentType === 'Link') {
      return 'link';
    }

    if (documentType === 'File') {
      return 'file';
    }

    if (documentType === 'Folder') {
      return 'folder';
    }
  }

  addDocumentClicked(): void {
    MixpanelService.trackEvent('Data Room: Add Document Clicked');
    const refModal = this.modal.open(DocumentDataRoomComponent, {
      size: 'xl',
      centered: true,
      windowClass: 'document-create-modal',
    });

    refModal.componentInstance.dataDocument = {
      object: this.object,
      objectId: this.objectId,
      isMobile: this.isMobile,
      platform: this.platform,
    };

    refModal.componentInstance.isUpdate.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.updateCurrentFolder(TreeType.storage, this.currentFolder._id);
    });
  }

  editDocumentClicked(document): void {
    MixpanelService.trackEvent('Data Room: Edit Document Clicked');
    const refModal = this.modal.open(DocumentDataRoomComponent, {
      size: 'xl',
      centered: true,
      windowClass: 'document-create-modal',
    });

    refModal.componentInstance.dataDocument = {
      object: this.object,
      objectId: this.objectId,
      platform: this.platform,
      document: document,
      isMobile: this.isMobile,
      isEdit: true,
    };

    refModal.componentInstance.documentForm.setValue({
      title: document.name,
      content: document.content,
    });

    refModal.componentInstance.isUpdate.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.updateCurrentFolder(TreeType.storage, this.currentFolder._id);
    });
  }

  editLinkClicked(document): void {
    MixpanelService.trackEvent('Data Room: Edit Link Clicked');
    this.docAccess = document.type;
    this.linkModalData = {
      isAddLink: false,
      id: document._id,
      name: document.name,
      link: document.link,
      inNewWindow: document.inNewWindow,
      object: document.object,
      objectId: document.objectId,
      invitedMembers: [],
      invitedRoles: [],
    };

    this.store
      .dispatch(
        new DocumentsGetPermissions({
          documentId: document._id,
          type: this.documentIS(document).toLowerCase(),
        }),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        const permissions = res.Documents.permissions;

        this.linkModalData.invitedMembers = permissions?.userIds
          ? permissions?.userIds.filter((item) => item !== (this.user._id as string))
          : [];
        this.linkModalData.invitedRoles = permissions?.roles;

        this.linkModalRef = this.modal.open(this.linkModalContent, {
          size: 'md',
          keyboard: false,
          backdrop: 'static',
          windowClass: 'link-create-modal',
          centered: true,
        });
      });
  }

  clearLink() {
    this.linkModalData = {
      isAddLink: true,
      name: '',
      link: '',
      inNewWindow: false,
      object: this.object,
      objectId: this.objectId,
      docAccess: this.docAccess,
      invitedMembers: this.invitedMembers,
      invitedRoles: this.invitedRoles,
    };
  }

  closeLinkModal(): void {
    MixpanelService.trackEvent('Data Room: Close Link Modal');
    this.linkModalRef.close();
    this.clearLink();
    this.resetPermissions();
  }

  addLinkSubmit(): void {
    MixpanelService.trackEvent('Data Room: Add Link Submit');
    this.isLoadingSubject.next(true);
    const currFolder = this.store.selectSnapshot(DocumentsState.getCurrentFolder);
    this.store
      .dispatch(
        new DocumentsLinkAdd({
          body: {
            object: this.object,
            objectId: this.objectId,
            name: this.linkModalData.name,
            link: this.urlHelper.setHttp(this.linkModalData.link),
            inNewWindow: this.linkModalData.inNewWindow,
            folderId: this.currentFolder ? this.currentFolder._id : currFolder._id,
            ...this.folderAccessObject(),
          },
        }),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res) {
            this.toastr.success(
              this.translocoService.translate('toastr.link-added'),
              this.translocoService.translate('toastr.title-success'),
            );
            this.updateCurrentFolder(TreeType.storage, this.currentFolder._id);
            this.clearLink();
            this.closeModals();
            this.resetPermissions();
            this.isLoadingSubject.next(false);
          } else {
            this.toastr.error(
              this.translocoService.translate('toastr.error-while-adding-link'),
              this.translocoService.translate('toastr.title-error'),
            );
          }
        },
        (error) => {
          let message: string;
          this.clearLink();
          if (Array.isArray(error.error?.message)) {
            message = error.error.message.join(', ');
          } else if (error.error?.message) {
            message = error.error.message;
          } else {
            message =
              error.message ?? this.translocoService.translate('toastr.error-while-adding-link');
          }
          if (!this.isMobile) {
            this.toastr.error(message, this.translocoService.translate('toastr.title-error'));
          } else {
            const showToast: HTMLElement = document.querySelector('.link-create-modal');
            showToast?.click();
          }

          this.isLoadingSubject.next(false);
        },
      );
  }

  editLinkSubmit(): void {
    MixpanelService.trackEvent('Data Room: Edit Link Submit');
    this.isLoadingSubject.next(true);
    const payload = {
      id: this.linkModalData.id,
      body: {
        name: this.linkModalData.name,
        link: this.urlHelper.setHttp(this.linkModalData.link),
        inNewWindow: this.linkModalData.inNewWindow,
        ...this.folderAccessObject(),
      },
    };

    this.store
      .dispatch(new DocumentsUpdateLink(payload))
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.toastr.success(
            'toastr.message-link-updated',
            this.translocoService.translate('toastr.title-success'),
          );
          this.updateCurrentFolder(TreeType.storage, this.currentFolder._id);
          this.clearLink();
          this.closeModals();
          this.resetPermissions();
          this.isLoadingSubject.next(false);
        },
        (error) => {
          let message: string;
          this.clearLink();
          if (Array.isArray(error.error?.message)) {
            message = error.error.message.join(', ');
          } else if (error.error?.message) {
            message = error.error.message;
          } else {
            message =
              error.message ?? this.translocoService.translate('toastr.error-while-updating-link');
          }
          this.toastr.error(message, this.translocoService.translate('toastr.title-error'));
          this.isLoadingSubject.next(false);
        },
      );
  }

  private initializeSort(): void {
    const sortParams: SortParams = this.localStorageService.get(sortKey) || defaultSortParams;
    this.store.dispatch(new DocumentsSort(sortParams));
    this.sortByList = defaultSortByList;
    this.sortBy = sortParams.sortBy;
    this.sortDirection = sortParams.direction;
  }

  private updateHighlightDndClassName(config: ITemplateConfig): string {
    return config.layout.variant === 'Dark' ? 'drag-drop-dark' : 'drag-drop-light';
  }

  private prepareMoveDocumentAction(
    destinationFolder: FoldersDbDto,
  ): DocumentsFolderUpdate | DocumentUpdateFile {
    let payload;
    let action: DocumentsFolderUpdate | DocumentUpdateFile | DocumentsUpdateLink;

    const documentType = this.documentIS(this.draggedDocument);
    if (documentType === DocumentTypes.Folder) {
      payload = {
        id: this.draggedDocument._id,
        body: {
          parentId: destinationFolder._id,
        },
      };
      action = new DocumentsFolderUpdate(payload);
    } else if (this.isTypeDocument(this.draggedDocument)) {
      payload = {
        id: this.draggedDocument._id,
        body: {
          folderId: destinationFolder._id,
          type: this.draggedDocument.type,
        },
      };
      action = new DocumentsUpdateDocument(payload);
    } else if (documentType === DocumentTypes.File) {
      payload = {
        id: this.draggedDocument._id,
        body: {
          folderId: destinationFolder._id,
          type: this.draggedDocument.type,
        },
      };
      action = new DocumentUpdateFile(payload);
    } else if (documentType === DocumentTypes.Link) {
      payload = {
        id: this.draggedDocument._id,
        body: {
          folderId: destinationFolder._id,
          type: this.draggedDocument.type,
        },
      };
      action = new DocumentsUpdateLink(payload);
    }

    return action;
  }

  private moveDocument(destinationFolder: FoldersDbDto): void {
    MixpanelService.trackEvent('Data Room: Move Document');
    const action = this.prepareMoveDocumentAction(destinationFolder);
    if (action) {
      this.renderer.addClass(this.draggedElementRef, 'drag-drop-disabled');
      this.store
        .dispatch(action)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.toastr.success(
              this.translocoService.translate('toastr.document-moved'),
              this.translocoService.translate('toastr.title-success'),
            );
            this.updateCurrentFolder(this.currRootFolder, this.currentFolder._id);
          },
          () => {
            this.toastr.error(
              this.translocoService.translate('toastr.error-while-moving-document'),
              this.translocoService.translate('toastr.title-error'),
            );
            this.renderer.removeClass(this.draggedElementRef, 'drag-drop-disabled');
          },
          () => {
            this.draggedElementRef = null;
            this.draggedDocument = null;
          },
        );
    }
  }

  getFolderIcon(node, type = null) {
    let folderIcon = 'folder';
    if (node?.hasChildren === true) {
      folderIcon += `-has-child`;
    }

    if (!type) {
      folderIcon += `-${node?.type}`;
    } else {
      folderIcon += `-${node?.type}`;
    }
    return folderIcon;
  }

  setSize(folderIcon: string): number {
    return folderIcon === 'folder-private' || folderIcon === 'folder-has-child-private' ? 25 : 20;
  }

  trackById(index, item): string {
    return item._id;
  }

  closeModals(): void {
    MixpanelService.trackEvent('Data Room: Close Modals');
    this.viewModalRef?.close();
    this.folderModalRef?.close();
    this.linkModalRef?.close();
    this.AccessControlModalRef?.close();
    this.contentModalRef?.close();
    this.activeModal.close();
  }

  uploaderErrorHandler(): void {
    // tslint:disable-next-line:no-shadowed-variable
    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      let errMessage: string;
      switch (filter.name) {
        case 'fileSize':
          errMessage = this.translocoService.translate('toastr.err-message-file-size', {
            size: '1GB',
          });
          break;
        default:
          errMessage = this.translocoService.translate('toastr.error-trying-to-upload-file');
      }

      this.toastr.error(errMessage, item.name);
    };
  }

  getMenuPlacement(e): void {
    if (e.target.getBoundingClientRect().top < 300) {
      this.actionsPlacement = 'bottom-right';
    } else {
      this.actionsPlacement = 'top-right';
    }
  }

  routerListener(): void {
    this.router.events
      .pipe(
        filter((e: any) => e instanceof RoutesRecognized),
        pairwise(),
        takeUntil(this.destroy$),
      )
      .subscribe((res: RoutesRecognized[]) => {
        const prevUrl = res[0].url;
        const currUrl = res[1].urlAfterRedirects;
        if (prevUrl.includes('file') && !currUrl.includes('file')) {
          this.closeModals();
        }
      });

    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this.destroy$),
        filter((params) => params.document),
      )
      .subscribe((param) => {
        const document = this.documents.find((doc) => doc._id === param.document);
        if (document) {
          this.editDocumentClicked(document);
        } else {
          // in case when we follow the link and documents in pending status
          this.store
            .select(DocumentsState.getDocuments)
            .pipe(
              takeUntil(this.destroy$),
              filter((v) => v?.length && !this.modal.hasOpenModals()),
            )
            .subscribe((documents) => {
              const document = documents.find((doc) => doc._id === param.document);
              if (document) {
                const query = this.activatedRoute.snapshot.queryParams;
                if (query?.document === document._id) {
                  // in case when someone triggered documents while we are closing the modal
                  this.editDocumentClicked(document);
                }
              }
            });
        }
      });
  }

  download(document: Document) {
    MixpanelService.trackEvent('Data Room: Download');
    this.fullImagePreviewService.dataRoomDownloadFiles({
      ...document,
      fromDataRoom: this.currRootFolder === TreeType.storage,
    });
  }
}
