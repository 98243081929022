<div class="picker" *transloco="let t">
  <div class="picker_header">
    {{ t('modals.screen-record.title-screen-record') }}
  </div>
  <div class="picker_content">
    <div class="picker_tabs">
      <div
        class="picker_tab"
        [class.picker_tab-active]="activeTab === previewTab.Application"
        (click)="filterStreams(previewTab.Application)"
      >
        {{ t('modals.screen-record.tab-application') }}
      </div>
      <div
        class="picker_tab"
        [class.picker_tab-active]="activeTab === previewTab.Screen"
        (click)="filterStreams(previewTab.Screen)"
      >
        {{ t('modals.screen-record.tab-screens') }}
      </div>
    </div>
    <div class="picker_sources">
      <div
        *ngFor="let stream of filteredStreams"
        (click)="selectStream(stream)"
        [class.picker_source-active]="selectedStream?.id === stream.id"
        class="picker_source"
      >
        <img class="picker_image" src="{{ stream.imgUrl }}" alt="stream_preview" />
        <div class="picker_name">{{ stream.name | limitTo: 12 }}</div>
      </div>
    </div>
  </div>
  <div class="picker_footer">
    <button class="picker_button" (click)="cancelSelection()">
      {{ t('common.btn-cancel') }}
    </button>
    <button class="picker_button picker_button-action" (click)="pickStream()">
      {{ t('modals.screen-record.btn-start-record') }}
    </button>
  </div>
</div>
