import { DataRoomSortBy } from './pagination';

export enum SortBy {
  Created = 'created_at',
  Name = 'name',
  Updated = 'updated_at',
}

export enum SortDirection {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export interface SortParams {
  sortBy: string | DataRoomSortBy;
  direction: SortDirection;
  updateDirection?: boolean;
}

export const defaultSortParams: SortParams = {
  direction: SortDirection.Ascending,
  sortBy: SortBy.Name,
};

export const defaultSortByList = [
  { value: 'name', label: 'Name' },
  { value: 'created_at', label: 'Created' },
  { value: 'updated_at', label: 'Modified' },
];

export const sortKey = 'documentsSort';
