import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BreadcrumbsDto } from '../../../../../api/models/breadcrumbs-dto';
import { ConfigService, ITemplateConfig } from '../../../../services/config.service';
import { TreeType } from '../../documents.component';
import { SortDirection, SortParams } from '../../../../data/sort-types';
import { FoldersDbDto } from '../../../../../api/models/folders-db-dto';
import { FilesDbDto } from '../../../../../api/models/files-db-dto';
import { LinksDbDto } from '../../../../../api/models/links-db-dto';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownButtonItem,
  NgbDropdownItem,
} from '@ng-bootstrap/ng-bootstrap';
import { SvgIconComponent } from 'angular-svg-icon';
import { SortingToggleComponent } from '../../../sorting-toggle/sorting-toggle.component';
import { TranslocoDirective } from '@ngneat/transloco';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { DocumentsDbDto } from '../../../../../api/models';

type Document = FoldersDbDto | FilesDbDto | LinksDbDto | DocumentsDbDto;

@Component({
  selector: 'app-data-room-header',
  templateUrl: './data-room-header.component.html',
  styleUrls: ['./data-room-header.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    TranslocoDirective,
    NgClass,
    NgFor,
    SortingToggleComponent,
    SvgIconComponent,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class DataRoomHeaderComponent implements OnInit {
  @Input() object: string;
  @Input() objectId: string;
  @Input() documents: Document[] = [];
  @Input() documentsIsLoaded: boolean;
  @Input() currentFolder: FoldersDbDto;
  @Input() sortDirection: SortDirection;
  @Input() sortBy: string;
  @Input() isListView: boolean;
  @Input() sortByList = [];
  @Input() currRootFolder: string;
  @Input() breadcrumbs: BreadcrumbsDto[];

  @Output() breadcrumbClicked = new EventEmitter();
  @Output() onSortChange = new EventEmitter();
  @Output() uploadClicked = new EventEmitter();
  @Output() folderCreate = new EventEmitter();
  @Output() linkCreate = new EventEmitter();
  @Output() enableSearch = new EventEmitter();
  @Output() searchAction = new EventEmitter();
  @Output() changeViewData = new EventEmitter();
  @Output() documentCreate = new EventEmitter();

  public config: ITemplateConfig;
  public treeType = TreeType;
  public isSearchActive = false;
  public searchValue = new FormControl('', [Validators.required]);

  constructor(
    private configService: ConfigService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit(): void {}

  // Getters
  public get isDark(): boolean {
    return this.config?.layout.variant === 'Dark';
  }

  public get isChatFolder(): boolean {
    return this.currentFolder?.parentId === TreeType.chat;
  }

  public get isBoardFolder(): boolean {
    return (
      this.currentFolder?.parentId === TreeType.board && this.currentFolder?._id === TreeType.board
    );
  }

  public get isLaneBoardFolder(): boolean {
    return (
      this.currentFolder?.parentId === TreeType.laneBoard &&
      this.currentFolder?._id === TreeType.laneBoard
    );
  }

  public get isEnabledAction(): boolean {
    return (
      !this.currentFolder?.parentId ||
      (!this.isChatFolder && !this.isBoardFolder && !this.isLaneBoardFolder)
    );
  }

  // Methods
  public breadcrumbClick(id: string) {
    this.breadcrumbClicked.emit(id);
  }

  public trackById(index, item): string {
    return item._id;
  }

  public sortChange(event: SortParams): void {
    this.onSortChange.emit(event);
    this.cdr.detectChanges();
  }

  public uploadClick(): void {
    this.uploadClicked.emit();
  }

  public addLink(): void {
    this.linkCreate.emit();
  }

  public addFolder(): void {
    this.folderCreate.emit();
  }

  public addDocument(): void {
    this.documentCreate.emit();
  }

  public setSearchState(): void {
    this.isSearchActive = true;
  }

  public deleteSearch(): void {
    this.searchValue.setValue('');
    this.enableSearch.emit();
  }

  public searchDocument(): void {
    if (this.searchValue.invalid) {
      return;
    }
    this.searchAction.emit(this.searchValue.value);
  }

  public changeViewDataRoom(): void {
    this.changeViewData.emit();
  }

  public backToAction(): void {
    this.isSearchActive = false;
    this.searchValue.setValue('');
    this.enableSearch.emit();
  }
}
