import { Component, Input, ChangeDetectorRef } from '@angular/core';
import {
  VgCoreModule,
  VgOverlayPlayModule,
  VgBufferingModule,
  VgControlsModule,
} from 'ngx-videogular';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    VgCoreModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    VgControlsModule,
    VgOverlayPlayModule,
  ],
})
export class VideoPlayerComponent {
  @Input() videoUrl: string;
  @Input() isDownloading: boolean;
  isPlayerReady = false;

  constructor(private cdr: ChangeDetectorRef) {}
}
