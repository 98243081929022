<div
  class="sprint-empty-state"
  [class.ready-for-drop]="isShowDropStyle"
  [class.dark]="isDarkMode"
  *transloco="let t; read: 'board'"
>
  <div class="sprint-empty-state__avatar">
    <img src="assets/img/icons/empty-state.svg" alt="empty-state" />
  </div>
  <p class="sprint-empty-state__description" [class.mobile]="isMobile">
    <ng-container [ngSwitch]="type">
      <!-- Backlog text-->
      <span *ngSwitchCase="BoardType.Backlog">
        {{ t('sprint-empty-state-backlog') }}
      </span>

      <!-- DEFAULT CONTAINER -->
      <span *ngSwitchDefault [innerHTML]="t('sprint-empty-state-default')"></span>
    </ng-container>
  </p>
  <button
    class="btn btn-solid"
    type="button"
    (click)="addTicket()"
    t-id="empty-state-sprint-add-ticket-button"
  >
    <app-svg name="plus" width="11" height="11" class="mr-1"></app-svg>
    <span>{{ t('btn-add-ticket') }}</span>
  </button>
</div>
