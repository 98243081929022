<div
  class="grid-display create-event-modal bg-gray-50 dark:bg-secondary-900 dark:text-white"
  *transloco="let t; read: 'modals.calendar-event'"
  [ngClass]="{
    'modal-height': form.value.type === 'event',
    '!h-fit': form.value.type === 'task',
  }"
>
  <header class="border-b-2 px-3 py-1 flex justify-between pt-2">
    <h4>{{ isCreating() ? t('add-new-event') : t('edit-event') }}</h4>
    <div class="my-auto cursor-pointer" (click)="close()">
      <svg-icon [src]="getIcon('close')" class="w-8 flex justify-center m-auto" />
    </div>
  </header>
  <article *ngIf="vm$ | async as vm" class="h-full grid-display--big-row-before mx-2">
    <section
      class="flex flex-col lg:flex-row min-h-full h-full border-gray-200 border-b overflow-y-auto"
    >
      <section
        [ngClass]="{
          'lg:w-50 border-r-2': eventMoreOptionsIsOpened && form.value.type === 'event',
        }"
        class="pt-2 min-h-full lg:w-[600px] lg:min-w-[600px] lg:overflow-auto scrollbar-hide"
      >
        <form [formGroup]="form" class="w-full flex gap-4 flex-col pr-3 py-1 h-full">
          <div class="flex gap-3">
            <svg-icon [src]="getIcon('host')" class="w-8 flex justify-center m-auto" />
            <ng-select
              formControlName="objectType"
              [items]="vm.objectTypes"
              class="flex-grow"
              bindLabel="label"
              bindValue="value"
              [clearable]="false"
            />
            @if (selectedObjectType === 'spaces') {
              <ng-select
                formControlName="space"
                bindLabel="label"
                bindValue="value"
                [items]="vm.spaces"
                class="flex-grow"
                placeholder="Select space"
              />
            } @else if (selectedObjectType === 'projects') {
              <ng-select
                formControlName="project"
                bindLabel="label"
                bindValue="value"
                [items]="vm.projects"
                class="flex-grow"
                placeholder="Select project"
              />
            }
          </div>
          <div class="flex gap-3">
            <svg-icon [src]="getIcon('title')" class="w-8 flex justify-center m-auto" />
            <input
              type="text"
              formControlName="title"
              class="border-gray-200 border rounded p-1.5 flex-grow placeholder:pl-1.5 text-sm"
              [placeholder]="t('title')"
            />
          </div>
          <div class="flex gap-3">
            <div class="w-8 flex justify-center m-auto"></div>
            <div class="flex-grow flex gap-4">
              <button
                (click)="changeType('event')"
                class="py-1.5 px-2 rounded-md"
                [ngClass]="{ 'bg-primary-100 text-primary-500 ': getEventType() === 'event' }"
              >
                {{ t('events') }}
              </button>
              <button
                (click)="changeType('task')"
                class="py-1.5 px-2 rounded-md"
                [ngClass]="{ 'bg-primary-100 text-primary-500': getEventType() === 'task' }"
              >
                {{ t('tasks') }}
              </button>
            </div>
          </div>
          @if (form.value.type === 'event') {
            <div class="flex flex-col gap-2">
              <div class="flex gap-3">
                <svg-icon [src]="getIcon('guests')" class="w-8 flex justify-center m-auto" />
                <ng-select
                  formControlName="guests"
                  bindLabel="label"
                  bindValue="value"
                  class="flex-grow w-full"
                  [placeholder]="t('guest-placeholder')"
                  [items]="vm.guests"
                  [multiple]="true"
                  [clearable]="true"
                  [addTag]="customGuest"
                >
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    @for (item of items; track item) {
                      <div
                        class="rounded-full flex bg-[#EEEFF8] p-0.5 gap-3 align-middle justify-center px-1"
                      >
                        <app-avatar
                          [userId]="isExternalMember(item.value) ? null : item.value"
                          [height]="20"
                          [width]="20"
                          class="m-auto"
                        />
                        <span class="text-lg">{{ item.label }}</span>
                        <span class="m-auto" (click)="clear(item)" aria-hidden="true">×</span>
                      </div>
                    }
                  </ng-template>
                </ng-select>
              </div>
              <div class="flex gap-3">
                <div class="w-8 flex justify-center m-auto"></div>
                <div class="flex-grow custom-control custom-switch switch-sm">
                  <input
                    type="checkbox"
                    id="sendEmailInvitation"
                    formControlName="sendEmailInvitation"
                    class="custom-control-input"
                  />
                  <label for="sendEmailInvitation" class="custom-control-label select-none text-sm">
                    {{ t('send-email-invitation') }}
                  </label>
                </div>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              @if (eventMoreOptionsIsOpened) {
                <div class="flex flex-col gap-2">
                  <div class="flex gap-3 align-baseline">
                    <svg-icon [src]="getIcon('date')" class="w-8 flex justify-center m-auto" />
                    <label for="fromDate" class="my-auto">{{ t('from') }}</label>
                    <input
                      id="fromDate"
                      type="date"
                      formControlName="fromDate"
                      class="border-gray-200 border rounded p-1.5 flex-grow placeholder:pl-1.5 text-sm"
                      placeholder="From Date"
                    />
                    <label for="toDate" class="my-auto">{{ t('to') }}</label>
                    <input
                      id="toDate"
                      type="date"
                      formControlName="toDate"
                      class="border-gray-200 border rounded p-1.5 flex-grow placeholder:pl-1.5 text-sm"
                      placeholder="To Date"
                    />
                  </div>
                  <div class="flex gap-3 align-baseline">
                    <svg-icon [src]="getIcon('time')" class="w-8 flex justify-center m-auto" />
                    <label for="startTime" class="my-auto">{{ t('from') }}</label>
                    <input
                      id="startTime"
                      type="time"
                      formControlName="startTime"
                      class="border-gray-200 border rounded p-1.5 flex-grow placeholder:pl-1.5 text-sm"
                      placeholder="End time"
                    />
                    <label for="endTime" class="my-auto">{{ t('to') }}</label>
                    <input
                      id="endTime"
                      type="time"
                      formControlName="endTime"
                      class="border-gray-200 border rounded p-1.5 flex-grow placeholder:pl-1.5 text-sm"
                      placeholder="End time"
                    />
                  </div>
                </div>
              } @else {
                <div class="flex gap-3">
                  <svg-icon [src]="getIcon('time')" class="w-8 flex justify-center m-auto" />
                  <input
                    type="date"
                    formControlName="fromDate"
                    class="border-gray-200 border rounded p-1.5 flex-grow placeholder:pl-1.5 text-sm"
                    placeholder="Date"
                  />
                  <input
                    type="time"
                    formControlName="startTime"
                    class="border-gray-200 border rounded p-1.5 flex-grow placeholder:pl-1.5 text-sm"
                    placeholder="Start time"
                  />
                  <input
                    type="time"
                    formControlName="endTime"
                    class="border-gray-200 border rounded p-1.5 flex-grow placeholder:pl-1.5 text-sm"
                    placeholder="End time"
                  />
                </div>
              }

              <div class="flex gap-3">
                <div class="w-8 flex justify-center m-auto"></div>
                <div class="flex-grow custom-control custom-switch switch-sm">
                  <input
                    type="checkbox"
                    id="allDay"
                    formControlName="allDay"
                    class="custom-control-input"
                  />
                  <label for="allDay" class="custom-control-label select-none text-sm">
                    {{ t('all-day') }}
                  </label>
                </div>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <div class="flex gap-3">
                <svg-icon [src]="getIcon('timezone')" class="w-8 flex justify-center m-auto" />
                <ng-select
                  formControlName="timezone"
                  class="flex-grow"
                  [items]="vm.timezones"
                  [placeholder]="t('timezone')"
                  [clearable]="false"
                  bindLabel="label"
                  bindValue="value"
                />
              </div>
              <div class="flex gap-3">
                <div class="w-8 flex justify-center m-auto"></div>
                <span class="flex-grow"
                  >{{ t('local-time-zone') }}:
                  <span class="font-semibold">{{ vm.localTimezone }} </span></span
                >
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <div class="flex gap-3">
                <svg-icon [src]="getIcon('location')" class="w-8 flex justify-center m-auto" />
                <input
                  type="text"
                  formControlName="place"
                  class="border-gray-200 border rounded p-1.5 flex-grow placeholder:pl-1.5 text-sm"
                  [placeholder]="t('place-placeholder')"
                />
              </div>
              <div class="flex gap-3">
                <div class="w-8 flex justify-center m-auto"></div>
                <div class="flex-grow custom-control custom-switch switch-sm">
                  <input
                    type="checkbox"
                    id="videoCall"
                    formControlName="addVideoCall"
                    class="custom-control-input"
                  />
                  <label for="videoCall" class="custom-control-label select-none text-sm">
                    {{ t('add-video-call') }}
                  </label>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex gap-4 flex-grow">
                <svg-icon [src]="getIcon('repeat')" class="w-8 flex justify-center m-auto" />
                <ng-select
                  bindLabel="label"
                  bindValue="value"
                  formControlName="repeat"
                  class="flex-grow"
                  [items]="vm.repeat"
                  [placeholder]="t('repeat')"
                  [clearable]="false"
                />
              </div>
              <div class="flex gap-4 flex-grow">
                <svg-icon [src]="getIcon('reminder')" class="w-8 flex justify-center m-auto" />
                <ng-select
                  bindLabel="label"
                  bindValue="value"
                  formControlName="reminder"
                  class="flex-grow"
                  [items]="vm.reminder"
                  [placeholder]="t('reminder')"
                  [clearable]="false"
                />
              </div>
            </div>
            @if (form.value.repeat !== 'never') {
              <div class="flex gap-4">
                <div class="w-8 flex justify-center"></div>
                <input
                  type="date"
                  formControlName="stopRepeatAt"
                  class="border-gray-200 border rounded p-1.5 flex placeholder:pl-1.5 text-sm w-48"
                  placeholder="Date"
                />
                <div class="flex custom-control custom-switch switch-sm my-auto">
                  <input
                    type="checkbox"
                    id="onlyWorkDays"
                    formControlName="onlyWorkDays"
                    class="custom-control-input"
                  />
                  <label for="onlyWorkDays" class="custom-control-label select-none text-sm">
                    {{ t('repeat-only-work-days') }}
                  </label>
                </div>
              </div>
            }
            <div class="flex gap-3">
              <svg-icon [src]="getIcon('description')" class="w-8 flex justify-center m-auto" />
              <quill-editor
                class="flex-grow"
                formControlName="description"
                [modules]="quillEditorModules"
                (onEditorCreated)="editorDescriptionCreated($event)"
              />
            </div>
          } @else {
            <div class="flex gap-3">
              <svg-icon [src]="getIcon('time')" class="w-8 flex justify-center m-auto" />
              <input
                type="date"
                formControlName="fromDate"
                class="border-gray-200 border rounded p-1.5 flex-grow placeholder:pl-1.5 text-sm"
                placeholder="Date"
              />
              <input
                type="time"
                formControlName="startTime"
                class="border-gray-200 border rounded p-1.5 flex-grow placeholder:pl-1.5 text-sm"
                placeholder="Start time"
              />
              <div class="flex-grow custom-control custom-switch switch-sm my-auto">
                <input
                  type="checkbox"
                  id="allDay"
                  formControlName="allDay"
                  class="custom-control-input"
                />
                <label for="allDay" class="custom-control-label select-none text-sm">
                  {{ t('all-day') }}
                </label>
              </div>
            </div>
            <div class="flex gap-4 flex-grow">
              <svg-icon [src]="getIcon('repeat')" class="w-8 flex justify-center m-auto" />
              <ng-select
                bindLabel="label"
                bindValue="value"
                formControlName="repeat"
                class="flex-grow"
                [items]="vm.repeat"
                placeholder="Repeat"
                [clearable]="false"
              />
              <div class="flex-grow"></div>
            </div>
            @if (form.value.repeat !== 'never') {
              <div class="flex gap-4">
                <div class="w-8 flex justify-center"></div>
                <input
                  type="date"
                  formControlName="stopRepeatAt"
                  class="border-gray-200 border rounded p-1.5 flex placeholder:pl-1.5 text-sm w-48"
                  placeholder="Date"
                />
                <div class="flex custom-control custom-switch switch-sm my-auto">
                  <input
                    type="checkbox"
                    id="onlyWorkDays"
                    formControlName="onlyWorkDays"
                    class="custom-control-input"
                  />
                  <label for="onlyWorkDays" class="custom-control-label select-none text-sm">
                    {{ t('repeat-only-work-days') }}
                  </label>
                </div>
              </div>
            }
            <div class="flex gap-3">
              <svg-icon [src]="getIcon('description')" class="w-8 flex justify-center m-auto" />
              <quill-editor
                class="flex-grow"
                formControlName="description"
                [modules]="quillEditorModules"
                (onEditorCreated)="editorDescriptionCreated($event)"
              />
            </div>
          }
        </form>
      </section>
      @if (eventMoreOptionsIsOpened && form.value.type === 'event') {
        <section
          class="flex-grow flex flex-col gap-3 pt-2 lg:w-[400px] lg:min-w-[400px] min-h-full"
        >
          <div class="flex justify-center gap-3 align-top">
            <button class="rounded-md border border-accent-200 py-1 px-2 font-semibold">
              {{ t('today') }}
            </button>
            <div class="flex gap-3">
              <button (click)="subtractOneDay()">
                <svg-icon
                  [src]="getIcon('left-arrow')"
                  class="w-8 flex justify-center m-auto text-xl"
                />
              </button>
              <span class="text-xl">
                {{ form.value.from | date: 'EE, d LLL yyyy' }}
              </span>
              <button (click)="addOneDay()">
                <svg-icon
                  [src]="getIcon('right-arrow')"
                  class="w-8 flex justify-center m-auto text-xl"
                />
              </button>
            </div>
          </div>
          <section class="overflow-y-auto overflow-x-hidden scrollbar-hide border-r-2">
            <app-calendar-event-modal-calendar-view
              [userId]="userData?._id"
              [formUpdated]="formUpdated.asObservable()"
              [event]="modalEvent"
              (eventChanged)="eventChanged($event)"
              [userName]="userData.userName"
            ></app-calendar-event-modal-calendar-view>
          </section>
        </section>
      }
    </section>

    <div class="flex justify-between py-3">
      @if (!eventMoreOptionsIsOpened && form.value.type === 'event') {
        <button
          class="rounded-md border border-accent-200 py-1 px-2 font-semibold"
          (click)="eventMoreOptionsIsOpened = true"
        >
          {{ t('more-options') }}
        </button>
      } @else {
        <div></div>
      }
      <div class="flex gap-2">
        @if (!isCreating()) {
          <button class="rounded-md py-1 px-2 font-semibold btn-danger" (click)="delete()">
            {{ t('delete') }}
          </button>
        }
        <button
          class="rounded-md border border-accent-200 py-1 px-2 font-semibold"
          (click)="close()"
        >
          {{ t('discard') }}
        </button>
        <button
          class="rounded-md border text-accent-100 bg-primary-500 py-1 px-2 font-semibold"
          (click)="save()"
        >
          {{ t('save') }}
        </button>
      </div>
    </div>
  </article>
</div>
