<ng-container *transloco="let t; read: 'modals.preview-media'">
  <div class="preview__header" [class.isMobile]="isMobile">
    <div *ngIf="isOpenThreadMobile; else mobileThread" class="preview__header-wrapper">
      <div class="preview__header-info">
        <app-avatar [userId]="ownerId"></app-avatar>
        <div class="preview__header-content">
          <div class="preview__header-name" [class.isMinimized-name]="isMinimize">
            {{ nameFile }}
          </div>
          <div class="preview__header-creator">
            <span class="preview__header-creator-name">{{ userOwnerName }}</span>
            <div class="preview__header-creator-date">
              <span>{{ createdAt | date: 'dd.MM.yy' : timeZone }}</span>
              <span> {{ t('at') }} </span>
              <span>{{ createdAt | date: 'HH:mm' : timeZone }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="preview__header-actions">
        <ng-container
          *ngIf="
            !isLoading && !isMobile && !isMinimize && objectId && !isDirect && !isPrivate;
            else loaderPin
          "
        >
          <svg-icon
            *ngIf="!pinModalService.pinnedMedia && pinModalService.isLimitPins && !isEditWiki"
            class="preview__header-icon preview__header-pinned"
            [src]="pinIcon"
            [applyClass]="true"
            (click)="pinMedia()"
          ></svg-icon>
          <svg-icon
            *ngIf="pinModalService.pinnedMedia && pinModalService.unPinMessageAccess"
            class="preview__header-icon preview__header-pinned"
            [src]="unpinIcon"
            [applyClass]="true"
            (click)="pinModalService.unPinMedia()"
          ></svg-icon>
        </ng-container>
        <ng-template #loaderPin>
          <i
            *ngIf="!isMobile && !isMinimize && objectId && !isDirect && !isPrivate"
            class="fa fa-spinner fa-spin connection_spinner"
            aria-hidden="true"
          ></i>
        </ng-template>

        <div
          *ngIf="platform === 'web' && !isMinimize && !isEditWiki"
          class="actions-ticket-minimize svg-icon"
          (click)="minimizeMedia()"
        >
          <svg-icon
            [src]="miniIcon"
            [svgStyle]="{ 'width.px': '16', 'height.px': '16' }"
          ></svg-icon>
        </div>

        <div
          *ngIf="platform === 'web' && isMinimize"
          class="actions-ticket-minimize svg-icon"
          (click)="expandMedia()"
        >
          <svg-icon
            [src]="expandIcon"
            [svgStyle]="{ 'width.px': '16', 'height.px': '16' }"
          ></svg-icon>
        </div>

        <svg-icon
          class="preview__header-icon"
          [src]="closeIcon"
          [applyClass]="true"
          (click)="closeModal()"
        ></svg-icon>
      </div>
    </div>

    <ng-template #mobileThread>
      <div class="preview__header-thread">
        <svg-icon
          (click)="changeStatus()"
          class="preview__header-iconThread"
          src="assets/icons/common/arrow-down.svg"
          [applyClass]="true"
        ></svg-icon>
        <span>{{ t('thread') }}</span>
      </div>
    </ng-template>
  </div>

  <div
    *ngIf="isOpenThreadMobile"
    class="preview__body"
    [ngClass]="{
      'preview__body-mobile': isMobile,
      'preview__body-minimize': isMinimize
    }"
  >
    <div class="preview__body-wrapper">
      <div
        class="preview__body-media"
        (click)="handleShowButton()"
        [class.preview__body-media-ios]="platformOS === 'ios'"
        [class.link-wrapper]="isLink"
      >
        <ng-container *ngIf="!(isLoading$ | async); else loader" [ngSwitch]="typeMedia">
          <!-- Meda Image-->
          <div class="preview__body-wrapperImage" *ngSwitchCase="MediaPreviewType.Image">
            <pinch-zoom
              class="preview__body-image"
              [class.preview__body-mobileImage]="isMobile"
              [minScale]="1"
              [backgroundColor]="'transparent'"
              [limit-zoom]="10"
              [disableZoomControl]="'disable'"
            >
              <img id="{{ modalId }}" [src]="currentMedia.url" alt="Image" />
            </pinch-zoom>
          </div>

          <!-- VIDEO CONTAINER -->
          <div
            class="preview__body-wrapperVideo"
            [class.isMobile-wrapperVideo]="isMobile"
            *ngSwitchCase="MediaPreviewType.Video"
          >
            <div *ngIf="isDownloadingFile">{{ t('downloading video') }}</div>
            <app-video-player
              *ngIf="isVideoLoad"
              [videoUrl]="currentMedia.url"
              [isDownloading]="isDownloadingFile"
            ></app-video-player>
          </div>

          <!-- MUSIC CONTAINER -->
          <div class="preview__body-wrapperMusic" *ngSwitchCase="MediaPreviewType.Music">
            <audio controls>
              <source src="{{ currentMedia.url }}" />
            </audio>
          </div>

          <!-- LINK CONTAINER -->
          <div class="preview__body-wrapperLink" *ngSwitchCase="MediaPreviewType.Link">
            <iframe
              [src]="currentMedia?.link || currentMedia?.url | documentLink | safeUrl"
              class="link"
            ></iframe>
          </div>

          <!-- PDF VIEWER CONTAINER -->
          <div class="preview__body-wrapperPdf" *ngSwitchCase="MediaPreviewType.Pdf">
            <!--FOR OTHERS-->
            <pdf-viewer
              [src]="url"
              [rotation]="0"
              [original-size]="false"
              [show-all]="true"
              [fit-to-page]="false"
              [zoom]="1"
              [zoom-scale]="'page-width'"
              [stick-to-page]="false"
              [render-text]="true"
              [external-link-target]="'blank'"
              [autoresize]="true"
              [show-borders]="false"
              style="width: 100%; height: 100%; display: block"
            ></pdf-viewer>
            <!--FOR DATA ROOM-->
            <!--<iframe *ngIf="!isNotDataRoom" [src]="currentMedia.url + '#toolbar=0&navpanes=0' | safeUrl"  width="100%" height="100%"></iframe>-->
          </div>

          <!-- MSO VIEWER CONTAINER -->
          <div style="width: 100%; height: 100%" *ngSwitchCase="MediaPreviewType.Mso">
            <ngx-doc-viewer
              [url]="currentMedia.url"
              viewer="google"
              style="height: 79vh"
            ></ngx-doc-viewer>
          </div>

          <!-- DEFAULT CONTAINER -->
          <span class="preview__body-wrapperDefault" *ngSwitchDefault>
            <span class="mb-2 text-center"><i class="ft-file font-large-4"></i></span>
            <span class="preview__body-defaultText">{{ t('no-media-preview') }}</span>
          </span>
        </ng-container>
        <ng-template #loader>
          <app-svg
            name="spinner"
            width="38"
            height="38"
            fill="#C6C8D7"
            class="loading-icon"
          ></app-svg>
        </ng-template>
        <ng-container *ngIf="!isMobile || showButtons">
          <div *ngIf="!isFirstMedia && !isMinimize" class="preview__body-left">
            <svg-icon
              (click)="prevMedia()"
              class="preview__body-icon"
              [src]="leftArrowIcon"
              [applyClass]="true"
            ></svg-icon>
          </div>
          <div *ngIf="!isLastMedia && !isMinimize" class="preview__body-right">
            <svg-icon
              (click)="nextMedia()"
              class="preview__body-icon"
              [src]="rightArrowIcon"
              [applyClass]="true"
            ></svg-icon>
          </div>
        </ng-container>
      </div>
      <div
        *ngIf="!isMinimize"
        class="preview__footer"
        [class.preview__footer-ios]="platformOS === 'ios'"
      >
        <div *ngIf="isImage && !isMobile" class="preview__footer-scaling">
          <button
            class="preview__footer-rotate"
            type="button"
            (click)="fullImagePreviewService.onRotateImage()"
          >
            <svg-icon
              class="preview__footer-rotate-icon"
              [src]="rotateIcon"
              [applyClass]="true"
            ></svg-icon>
          </button>
          <div class="preview__footer-zoom">
            <button
              class="preview__footer-zoom-action"
              type="button"
              (click)="fullImagePreviewService.onRange(fullImagePreviewService.zoomState - 1)"
            >
              <svg-icon
                class="preview__footer-zoom-icon"
                [src]="minusIcon"
                [applyClass]="true"
              ></svg-icon>
            </button>
            <input
              class="preview__footer-zoom-range"
              type="range"
              min="0"
              max="9"
              [value]="fullImagePreviewService.zoomState"
              #ranger
              (input)="fullImagePreviewService.onRange(ranger.value)"
            />
            <button
              class="preview__footer-zoom-action"
              type="button"
              (click)="fullImagePreviewService.onRange(fullImagePreviewService.zoomState + 1)"
            >
              <svg-icon
                class="preview__footer-zoom-icon"
                [src]="plusIcon"
                [applyClass]="true"
              ></svg-icon>
            </button>
          </div>
        </div>
        <div class="preview__footer-actions">
          <span *ngIf="!isEditWiki" class="preview__footer-download" (click)="downloadDocument()">
            <svg-icon
              *ngIf="!isLink"
              class="preview__footer-icon"
              [src]="downloadIcon"
              [applyClass]="true"
            ></svg-icon>
          </span>
          <svg-icon
            *ngIf="!isLink && !isNotDataRoom"
            class="preview__footer-icon"
            [src]="threadIcon"
            [applyClass]="true"
            (click)="changeStatus()"
          ></svg-icon>
          <div *ngIf="!isEditWiki" class="preview__footer-more" ngbDropdown>
            <svg-icon
              class="preview__footer-icon"
              [svgStyle]="{ 'width.px': '20', 'height.px': '20' }"
              [src]="ellipsisIcon"
              [applyClass]="true"
              ngbDropdownToggle
            ></svg-icon>

            <div ngbDropdownMenu class="document-actions">
              <button *ngIf="!isNotDataRoom && !isLink" ngbDropdownItem (click)="changeStatus()">
                <span>{{ t('btn-view-thread') }}</span>
              </button>
              <button ngbDropdownItem (click)="copyUrl()">
                <span>{{ t('btn-copy-link-to-file') }}</span>
              </button>
              <button ngbDropdownItem (click)="openInBrowser()">
                <span>{{ t('btn-open-in-browser') }}</span>
              </button>
              <button
                ngbDropdownItem
                *ngIf="isPossiblePin && !isDirect && !isPrivate"
                (click)="actionPinMedia()"
              >
                <span>{{ textForActionPin }}</span>
              </button>
              <button
                *ngIf="userId === ownerId && !isNotDataRoom"
                ngbDropdownItem
                (click)="deleteFile()"
                class="delete-file"
              >
                <span>{{ t('btn-delete-file') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="!isLink && !isMobile"
      class="preview__body-thread"
      [class.show-thread]="isOpenThread"
    >
      <app-chat-thread
        *ngIf="mentionThreadMembers && !isNotDataRoom"
        [apiUrl]="apiUrl"
        [platform]="platform"
        [message]="message"
        [mentionThreadMembers]="mentionThreadMembers"
        [isPreview]="true"
        [object]="object"
        [objectId]="objectId"
        [isDataRoomFiles]="!isNotDataRoom"
        linkObject="files"
        [linkObjectId]="currentMedia._id"
        (clearThreadId)="changeStatus(true)"
      ></app-chat-thread>
    </div>
  </div>

  <div
    class="thread-mobile overflow-hidden"
    *ngIf="isMobile && isOpenThread"
    [class.thread-mobile-web]="platform === 'web'"
  >
    <section id="chat-thread-body">
      <app-chat-thread
        *ngIf="mentionThreadMembers"
        class="d-block"
        [ngStyle]="{ height: '100%' }"
        [apiUrl]="apiUrl"
        [platform]="platform"
        [isPreviewMobile]="true"
        [message]="message"
        [mentionThreadMembers]="mentionThreadMembers"
        [object]="object"
        [objectId]="objectId"
        linkObject="files"
        [linkObjectId]="currentMedia._id"
        [isMobileThread]="true"
      ></app-chat-thread>
    </section>
  </div>
</ng-container>
