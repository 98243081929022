import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { CreateField, UpdateField } from '../../shared/store/actions/board-field.action';
import { takeUntil } from 'rxjs/operators';
import { typeDescription } from './const/typeDescription';
import { FieldType } from './enums/fieldType';
import {
  CDK_DRAG_CONFIG,
  CdkDragDrop,
  moveItemInArray,
  CdkDropList,
  CdkDrag,
} from '@angular/cdk/drag-drop';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment-timezone';
import { BoardsFieldState } from '../../shared/store/states/boards-field.state';
import { ConfigService, ITemplateConfig } from '../../shared/services/config.service';
import { ConfirmAlert } from '../../shared/alerts/alerts';
import { SvgComponent } from '../../shared/svgs/svg/svg.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';

export interface CustomData {
  object: string;
  objectId: string;
}

const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000,
};

@Component({
  selector: 'app-custom-field-modal',
  templateUrl: './custom-field.component.html',
  styleUrls: ['./custom-field.component.scss'],
  providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgScrollbar,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgSelectModule,
    SvgComponent,
    CdkDropList,
    NgFor,
    CdkDrag,
    AsyncPipe,
  ],
})
export class CustomFieldModalComponent implements OnInit, OnDestroy {
  items = [];
  fields = [];
  platform = 'web';
  formatDate = [];
  formatTime = [];
  options = [];
  config: ITemplateConfig;

  customData: CustomData;

  destroy$: Subject<void> = new Subject<void>();
  addFormatPromise: (label: any) => Promise<any>;
  currentOption = new FormControl();
  timeOption = new FormControl();
  formatValue: string;
  createSubmit = false;
  isLoading = new BehaviorSubject(false);
  fieldId = null;

  object: string;
  objectId: string;

  fieldForm: FormGroup = this.formBuilder.group({
    title: ['', Validators.required],
    description: [null],
    type: [null, Validators.required],
    format: [null],
    formatMultiple: [null],
    isMandatory: [false],
    isOnCard: [false],
  });

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private configService: ConfigService,
    private toastr: ToastrService,
    private translocoService: TranslocoService,
    private store: Store,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit(): void {
    this.initializeData(this.customData);
  }

  initializeData(data: CustomData) {
    this.object = data.object;
    this.objectId = data.objectId;
    this.addFormatPromise = (label) => {
      return new Promise((resolve) => {
        resolve(label);
      });
    };

    this.fieldForm.get('type').valueChanges.subscribe((val) => {
      this.formatValue = val;
      this.fieldForm.controls.format.setValue(null);
      this.timeOption.setValue(null);
      this.createSubmit = false;
      this.clearValidation();
    });

    this.store
      .select(BoardsFieldState.getFieldList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((fields) => {
        this.items = this.translationSelector();
        this.formatDate = fields.dateFormats;
        this.formatTime = fields.dateTimeFormats;
        this.fields = fields.fields;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.isLoading.unsubscribe();
  }
  public submitForm() {
    this.createSubmit = true;

    if (!this.fieldForm.valid) {
      return;
    }

    if (!this.timeOption.value && this.isDateTimePicker) {
      return;
    }

    this.createSubmit = false;

    const data = {
      ...this.fieldForm.value,
      object: this.object,
      objectId: this.objectId,
      type: this.formatValue,
    };

    if (this.isDateTimePicker) {
      data.format = `${data.format} ${this.timeOption.value}`;
    }

    this.isLoading.next(true);

    if (this.fieldId) {
      if (
        !this.fields.some((field) => field._id === this.fieldId && this.formatValue === field.type)
      ) {
        ConfirmAlert(null, {
          subject: this.translocoService.translate('alert.custom-field-changing-type-subject'),
          text: this.translocoService.translate('alert.custom-field-changing-type-text'),
          confirmButtonText: this.translocoService.translate('alert.btn-save'),
          confirmButtonClass: 'btn-solid',
          platform: this.platform,
        }).then(
          (result) => {
            if (result === 'isConfirmed') {
              this.store
                .dispatch(new UpdateField({ id: this.fieldId, body: data }))
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                  () => {
                    this.isLoading.next(false);
                    this.toastr.success(
                      this.translocoService.translate('toastr.message-field-updated'),
                      this.translocoService.translate('toastr.title-success'),
                    );
                    this.activeModal.close();
                  },
                  () => {
                    this.isLoading.next(false);
                  },
                );
            }
          },
          () => {
            this.isLoading.next(false);
          },
        );
      } else {
        this.store
          .dispatch(new UpdateField({ id: this.fieldId, body: data }))
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            () => {
              this.isLoading.next(false);
              this.toastr.success(
                this.translocoService.translate('toastr.message-field-updated'),
                this.translocoService.translate('toastr.title-success'),
              );
              this.activeModal.close();
            },
            () => {
              this.isLoading.next(false);
            },
          );
      }
    } else {
      this.store
        .dispatch(new CreateField({ ...data, isVisible: true }))
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.isLoading.next(false);
            this.toastr.success(
              this.translocoService.translate('toastr.message-field-created'),
              this.translocoService.translate('toastr.title-success'),
            );
            this.activeModal.close();
          },
          (err) => {
            this.isLoading.next(false);
            this.toastr.error(err.message, this.translocoService.translate('toastr.title-error'));
          },
        );
    }
  }

  private translationSelector() {
    return typeDescription.map((field) => ({
      ...field,
      title: this.translocoService.translate(`modals.custom-field.title-${field.type}`),
      description: this.translocoService.translate(`modals.custom-field.description-${field.type}`),
    }));
  }

  private clearValidation(): void {
    if (this.isShowFormat && this.isShowFormatSelect) {
      this.fieldForm.get('formatMultiple').clearValidators();
      this.fieldForm.get('format').setValidators(Validators.required);
    } else if (this.isShowFormat && !this.isShowFormatSelect) {
      this.fieldForm.get('format').clearValidators();
      this.fieldForm.get('formatMultiple').setValidators(Validators.required);
    } else {
      this.fieldForm.get('formatMultiple').clearValidators();
      this.fieldForm.get('format').clearValidators();
    }

    this.fieldForm.get('format').updateValueAndValidity();
    this.fieldForm.get('formatMultiple').updateValueAndValidity();
  }

  public pushOption(): void {
    this.options = [...this.options, this.currentOption.value];
    this.fieldForm.get('formatMultiple').setValue(this.options);
    this.currentOption.setValue(null);
  }

  public delete(item): void {
    const index = this.options.indexOf(item);
    if (index !== -1) {
      this.options = this.options.filter((_, i) => i !== index);
      if (!this.options.length) {
        this.fieldForm.get('formatMultiple').setValue(null);
      } else {
        this.fieldForm.get('formatMultiple').setValue(this.options);
      }
    }
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  public getMomentDate(formatValue) {
    return moment(new Date('01/01/1930')).add(14, 'hours').add(25, 'minutes').format(formatValue);
  }

  public get customFormControl() {
    return this.fieldForm.controls;
  }

  public get isShowFormat(): boolean {
    switch (this.formatValue) {
      case FieldType.Text:
        return false;
      case FieldType.TextArea:
        return false;
      case FieldType.Number:
        return false;
      default:
        return true;
    }
  }

  public get isShowFormatSelect(): boolean {
    switch (this.formatValue) {
      case FieldType.Select:
        return false;
      case FieldType.MultiSelect:
        return false;
      case FieldType.Unit:
        return false;
      default:
        return true;
    }
  }

  public get isDateTimePicker(): boolean {
    return this.formatValue === FieldType.DateTimePicker;
  }

  public get checkValidate(): boolean {
    return (
      (this.currentOption.value && this.currentOption.value.trim() === '') ||
      this.currentOption.value === null
    );
  }

  public dragDropOption(event: CdkDragDrop<any[]>): void {
    const arrayForSort = [...this.options];
    moveItemInArray(arrayForSort, event.previousIndex, event.currentIndex);
    this.options = [...arrayForSort];
    this.fieldForm.get('formatMultiple').setValue(arrayForSort);
  }
}
