export const reminderTime = [
  {
    label: '15 min',
    value: [15, 'minutes'],
  },
  {
    label: '30 min',
    value: [30, 'minutes'],
  },
  {
    label: '1 hour',
    value: [1, 'hours'],
  },
  {
    label: '2 hours',
    value: [2, 'hours'],
  },
  {
    label: '1 day',
    value: [1, 'days'],
  },
  {
    label: '2 days',
    value: [2, 'days'],
  },
];
