export enum FileTypeEnum {
  Image = 'image',
  Video = 'video',
  Music = 'music',
  Archive = 'archive',
  Presentation = 'presentation',
  Link = 'link',
}

export enum ClickType {
  SingleClick = 'single',
  DoubleClick = 'double',
}
