<ng-scrollbar
  #scrollable="ngScrollbar"
  [autoHeightDisabled]="false"
  visibility="hover"
  thumbClass="white-scrollbars"
  *transloco="let t; read: 'lane-board'"
  track="all"
>
  <div
    cdkDropList
    cdkDropListGroup
    cdkDropListOrientation="horizontal"
    class="board-lane"
    (cdkDropListDropped)="drop($event)"
  >
    <app-column
      *ngFor="let column of boardColumns"
      [column]="column"
      [cards]="findColumnCards(column?._id)"
      [currentUser]="currentUser"
      [isShowContent]="isShowContent"
      [isShowCreator]="isShowCreator"
      [isShowVotes]="isShowVotes"
      [themeMode]="themeMode"
      t-id="lane-board-column-preview"
    ></app-column>

    <div class="add-column" [class.add-column-dark]="themeMode === 'Dark'">
      <div class="title">{{ t('btn-add-column') }}</div>
      <app-svg
        name="plus"
        width="13"
        height="13"
        [hoverEffect]="true"
        (click)="handleAddColumn()"
        t-id="board-lane-add-column"
      ></app-svg>
    </div>
  </div>
</ng-scrollbar>
