import { AbstractControl, ValidationErrors } from '@angular/forms';
import moment from 'moment';

export const CustomValidators = {
  isDate: (control: AbstractControl): ValidationErrors | null => {
    return control.value && moment(control.value).isValid() ? null : { invalidDate: true };
  },
  isTime: (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return { invalidTime: true };
    }

    const time = control.value.split(':');
    return time.length === 2 ? null : { invalidTime: true };
  },
};
