export const QuillConfig = {
  modules: {
    syntax: false,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      ['link'],
      ['clean'],
    ],
  },
  formats: [
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'code-block',
    'header',
    'list',
    'link',
    'image',
    'indent',
    'alt',
    'width',
    'height',
    'style',
    'table',
    'tr',
    'td',
  ],
};

let maxRows = 10;
let maxCols = 5;
let tableOptions = [];
for (let r = 1; r <= maxRows; r++) {
  for (let c = 1; c <= maxCols; c++) {
    tableOptions.push('newtable_' + r + '_' + c);
  }
}

export const QuillModulesForDescription = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    [{ indent: '-1' }, { indent: '+1' }],
    // [{ align: [] }],
    // [{ color: [] }, { background: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] } /*{ font: [] }*/],
    ['link', 'image'],
    ['clean'],
    [{ table: tableOptions }, { table: 'append-row' }, { table: 'append-col' }],
  ],
  table: true,
};

export const QuillModulesForChat = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block', { list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],
    ['link', 'image', 'clean'],
  ],
};

export const QuillModulesForMobileWiki = {
  toolbar: [
    [
      'bold',
      'italic',
      'underline',
      'strike',
      { list: 'ordered' },
      { list: 'bullet' },
      { list: 'check' },
      'link',
      'image',
      'clean',
      ['table'],
    ],
  ],
  table: true,
};

/**
 * Used to make the ordered list working with patterns like "2. "
 * */
export const processDotKeyChange = (editor) => (range: any, ctx: any) => {
  // cleanup the current line
  editor.deleteText(range.index - ctx.offset, ctx.offset);
  const selection = editor.getSelection(true);
  const listIndent = '      ';
  // Change the dot character to the following to bypass the automatic list item generation.
  // https://www.compart.com/en/unicode/U+2024
  const specialCharacter = '․';
  editor.insertText(selection, `${listIndent}${ctx.prefix.trim()}${specialCharacter}`);

  // Do not display the character entered by the user
  return false;
};
