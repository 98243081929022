import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  GitLabCommit,
  GitLabMergeRequestMessageData,
  GitLabPipeMsgData,
  GitLabPushMessageData,
  WebhookMessageData,
} from '../../../../../interfaces/webhook-message-data';
import { WebhookJobStatusComponent } from '../webhook-job-status/webhook-job-status.component';
import { WebhookPushMessageComponent } from '../webhook-push-message/webhook-push-message.component';
import { WebhookPRMessageComponent } from '../webhook-pr-message/webhook-pr-message.component';
import { NgClass, NgIf } from '@angular/common';

enum GitLabEventTypes {
  MERGE_REQUEST_OPEN = 'merge_request:open',
  MERGE_REQUEST_CLOSE = 'merge_request:close',
  MERGE_REQUEST_MERGE = 'merge_request:merge',
  PUSH = 'push',
  PIPELINE = 'pipeline',
}

@Component({
  selector: 'app-webhook-gitlab-message',
  templateUrl: './webhook-gitlab-message.component.html',
  styleUrls: ['./webhook-gitlab-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    WebhookPRMessageComponent,
    WebhookPushMessageComponent,
    WebhookJobStatusComponent,
  ],
})
export class WebhookGitlabMessageComponent {
  @Input() webhookMessageData: WebhookMessageData;

  @Input() layoutVariant: string;

  public GitLabEventTypes = GitLabEventTypes;

  public get pipeMsgData(): GitLabPipeMsgData {
    return this.webhookMessageData?.data as GitLabPipeMsgData;
  }

  public get mrMessageData(): GitLabMergeRequestMessageData {
    return this.webhookMessageData?.data as GitLabMergeRequestMessageData;
  }

  public get pushMessageData(): GitLabPushMessageData {
    return this.webhookMessageData?.data as GitLabPushMessageData;
  }

  public get isPipeFailed(): boolean {
    return this.pipeMsgData.object_attributes.status === 'failed';
  }

  public get statusLabel(): string {
    switch (this.pipeMsgData?.object_attributes?.status) {
      case 'running':
        return 'IN PROGRESS';
      case 'pending':
        return 'PENDING';
      case 'failed':
        return 'FAILED';
      case 'success':
        return 'SUCCESS';
      default:
        return this.pipeMsgData?.object_attributes?.status;
    }
  }

  public get jobTypeLabel(): string {
    return this.pipeMsgData?.object_attributes?.stages.join(', ');
  }

  public get pipeNumberLabel(): string {
    return `Pipeline #${this.pipeMsgData?.object_attributes?.iid}`;
  }

  public get fullBranchPath(): string {
    const mainName = this.pushMessageData?.project?.path_with_namespace;
    const branchName = this.pushMessageData?.ref;
    return branchName?.replace('ref', mainName);
  }

  public get headCommit(): GitLabCommit {
    return this.pushMessageData?.commits?.length > 0 ? this.pushMessageData?.commits[0] : null;
  }

  public get mrActionTitle(): string {
    let action = '';
    if (this.webhookMessageData?.eventName === this.GitLabEventTypes.MERGE_REQUEST_OPEN) {
      action = 'opened';
    } else if (this.webhookMessageData?.eventName === this.GitLabEventTypes.MERGE_REQUEST_MERGE) {
      action = 'merged';
    } else if (this.webhookMessageData?.eventName === this.GitLabEventTypes.MERGE_REQUEST_CLOSE) {
      action = 'closed';
    } else {
      action = this.mrMessageData?.object_attributes?.action;
    }
    return `Merge request ${action} by`;
  }

  public get showMRDetails(): boolean {
    return this.webhookMessageData?.eventName === this.GitLabEventTypes.MERGE_REQUEST_OPEN;
  }

  public get pushMsgDescription(): string {
    if (this.headCommit?.id) {
      return this.headCommit?.message;
    }
    return `Branch ${this.fullBranchPath} was deleted.`;
  }
}
