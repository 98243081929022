import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EpicsService {
  private showEpics = new BehaviorSubject(false);

  public showEpics$ = this.showEpics.asObservable();

  /* Methods */
  public broadcastShowEpics(state: boolean): void {
    this.showEpics.next(state);
  }
}
