<ng-container *transloco="let t; read: 'modals.wiki'">
  <div class="wiki__link" *ngIf="showLink">
    <svg-icon
      (click)="closeThread()"
      src="assets/icons/common/info-circle.svg"
      [applyClass]="true"
    ></svg-icon>
    <p>{{ t('link-copied') }}</p>
  </div>

  <ng-container *ngIf="!isThread; else thread">
    <div
      class="wiki__header"
      [ngClass]="{
        'pinned-dark': isDarkTheme,
        'edit-mode': isEdit,
        'show-link': showLink
      }"
    >
      <app-wiki-mobile-header
        [isLoadingButton]="wikiService.isSaveOrUpdate$ | async"
        [isEdit]="isEdit"
        [hasUnsavedChange]="isChanged"
        (backAction)="closeModal()"
        (showToastLink)="showToastLink()"
        (handleSave)="onSave()"
      ></app-wiki-mobile-header>
      <div *ngIf="!isEdit" class="add-fab-button add-ticket-btn" (click)="toggleMode()">
        <app-svg name="pen" width="17" height="17" aria-hidden="true"></app-svg>
      </div>
    </div>

    <app-editor
      [isEditMode]="isEdit"
      [object]="wikiService.object"
      [objectId]="wikiService.objectId"
      [isMobile]="true"
      [platform]="platform"
      [initTitle]="title"
      [initContent]="content"
      (hasUnsavedChanges)="onEmitChangesStatus($event)"
    ></app-editor>
  </ng-container>

  <ng-template #thread>
    <div class="wiki__thread">
      <div class="wiki__header-thread">
        <svg-icon
          (click)="closeThread()"
          class="wiki__header-iconThread"
          src="assets/icons/common/arrow-down.svg"
          [applyClass]="true"
        ></svg-icon>
        <span>{{ t('thread') }}</span>
      </div>
      <div
        class="thread-mobile overflow-hidden"
        *ngIf="isOpenThread"
        [class.thread-mobile-web]="platform === 'web'"
      >
        <section id="chat-thread-body">
          <app-chat-thread
            *ngIf="isOpenThread"
            class="d-block"
            [ngStyle]="{ height: '100%' }"
            [apiUrl]="apiUrl"
            [platform]="platform"
            [isPreviewMobile]="true"
            [message]="message"
            [mentionThreadMembers]="wikiService.mentionThreadMembers"
            [object]="wikiService.object"
            [objectId]="wikiService.objectId"
            [heightKeyboard]="heightKeyboard"
            linkObject="wiki-pages"
            [linkObjectId]="wikiService.activeWiki.value._id"
            [isMobileThread]="true"
          ></app-chat-thread>
        </section>
      </div>
    </div>
  </ng-template>
</ng-container>
