import { AfterViewInit, Component, Input, OnInit, ViewChild, forwardRef } from '@angular/core';
import moment from 'moment-timezone';
import { Store } from '@ngxs/store';
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslocoService } from '@ngneat/transloco';

import { ConfigService, ITemplateConfig } from '../../../../../services/config.service';
import { ChatsDeletePin } from '../../../../../store/actions/chats.action';
import { ScrollingChatService } from '../../../../../services/scrolling-chat.service';
import { ChatPinnedService } from '../../chat-pinned.service';
import { ConfirmAlert } from '../../../../../alerts/alerts';
import { MessagePollComponent } from '../../../chat-messages/message-poll/message-poll.component';
import { ChatMessageContentComponent } from '../../../chat-message-content/chat-message-content.component';
import { AvatarComponent } from '../../../../../../standalone/components/avatar/avatar.component';
import { NgFor, NgIf } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-chat-pinned-message',
  templateUrl: './chat-pinned-message.component.html',
  styleUrls: ['./chat-pinned-message.component.scss'],
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownToggle,
    SvgIconComponent,
    NgbDropdownMenu,
    NgFor,
    forwardRef(() => AvatarComponent),
    NgIf,
    ChatMessageContentComponent,
    MessagePollComponent,
  ],
})
export class ChatPinnedMessageComponent implements OnInit, AfterViewInit {
  @Input() messages = [];
  @Input() chatId: string;
  @Input() object: string;
  @Input() objectId: string;
  @ViewChild('dropDown', { static: false, read: NgbDropdown })
  dropdown: NgbDropdown;

  public config: ITemplateConfig;
  public platform = 'web';
  private actionsDropdownOpen = false;
  constructor(
    public pinnedService: ChatPinnedService,
    private configService: ConfigService,
    private store: Store,
    private scrollingService: ScrollingChatService,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.scrollingService.isScrolling.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.dropdown?._open) {
        this.dropdown?.close();
      }
    });
  }

  public get textMessages(): string {
    return this.messages?.length <= 1
      ? this.translocoService.translate('chat.message')
      : this.translocoService.translate('chat.messages');
  }

  public actionsDropdownToggle(e: boolean): void {
    this.actionsDropdownOpen = e;
  }

  public deletePin(message) {
    ConfirmAlert(this.translocoService.translate('alert.pin-title'), {
      subject: this.translocoService.translate('alert.unpin-subject'),
      text: this.translocoService.translate('alert.unpin-text'),
      confirmButtonText: this.translocoService.translate('alert.unpin-btn-text'),
      cancelButtonText: this.translocoService.translate('alert.btn-cancel'),
      platform: this.platform,
    }).then(() => {
      this.store.dispatch(
        new ChatsDeletePin({
          id: this.chatId,
          pinnedMessageId: message._id,
          object: this.object,
          objectId: this.objectId,
        }),
      );
    });
  }

  public formatDate(time) {
    const isToday = moment(time).isSame(moment(), 'day');
    if (isToday) {
      return 'Today ' + moment(time).format('h:mm A').replace(/^0/, '');
    } else {
      return moment(time).format('YY.MM.DD h:mm A');
    }
  }

  public scrollToMessage(message) {
    this.dropdown?.close();
    this.scrollingService.setScrollingMessage(message);
  }
}
