function Memoize(dependencies: any[]) {
  return function (target: any, propertyKey: string, descriptor: any) {
    const originalGetter = descriptor.get;

    descriptor.get = function () {
      const parameterValues = dependencies.map((dependency) => {
        // Hint: Handle dependency like objName.surname
        const splitedDependency = dependency.split('.');
        return splitedDependency.reduce((prev: any, current: any) => prev[current], this);
      });

      // Hint: Handle empty dependency array
      if (!parameterValues.length) {
        return originalGetter.apply(this);
      }

      // Hint: Create uniq cache key
      const cacheKey = JSON.stringify(parameterValues);

      if (!this._memoizeCache) {
        this._memoizeCache = {};
      }

      // Hint: Add in memo object decorator key
      if (!this._memoizeCache[propertyKey]) {
        this._memoizeCache[propertyKey] = {};
      }

      // Hint: Save in current decorator name property cached key and result
      if (!this._memoizeCache[propertyKey][cacheKey]) {
        this._memoizeCache[propertyKey][cacheKey] = originalGetter.apply(this);
      }

      return this._memoizeCache[propertyKey][cacheKey];
    };

    return descriptor;
  };
}

export default Memoize;
