import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject, firstValueFrom, BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngxs/store';

import { CalendarEventGetUserEvents } from '../../../shared/store/actions/calendar-events.action';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarUtils } from 'angular-calendar';
import { CalendarEventsStateModel } from '../../../shared/store/models/CalendarEventsState';
import {
  CalendarEventsGetUserEventsResDto,
  CalendarEventsGetUserEventsUpdatesResDto,
  CalendarEventsUserEvent,
} from '../../../api/models/calendar-events-get-user-events-res-dto';
import { WrappedSocket } from 'ngx-socket-io/src/socket-io.service';
import { SocketsService } from '../../../shared/services/sockets.service';
import { TranslocoService } from '@ngneat/transloco';
import { CustomCalendarUtils } from './custom-calendar-utils';
import moment from 'moment-timezone';

export interface CalendarEventViewEvent {
  guests: string[];
  id: string;
  title: string;
  start: Date;
  end: Date;
  allDay: boolean;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-calendar-event-modal-calendar-view',
  templateUrl: './calendar-event-view.component.html',
  styleUrls: ['./calendar-event-view.component.scss'],
  //INFO(Basilio): Although the components has a module, this override is necessary here
  // because in the module is not overriding the CalendarUtils provider
  providers: [
    {
      provide: CalendarUtils,
      useClass: CustomCalendarUtils,
    },
  ],
})
export class CalendarEventViewComponent implements AfterViewInit {
  @Input() userId: string;
  @Input() userName: string;
  @Input() formUpdated: Observable<CalendarEventViewEvent>;
  @Input() event: CalendarEventViewEvent;

  @Output() eventChanged: EventEmitter<{ start: Date; end: Date }> = new EventEmitter();

  eventsByUserId: Record<string, CalendarEventsUserEvent[]> = {};

  viewEvents$ = new BehaviorSubject<CalendarEvent[]>([]);
  eventRefresh = new Subject<void>();
  hourSegments = 2;
  hourSegmentHeight = 30;

  hoveringOwner: string = '';

  private readonly ICON_BASE_URL = 'assets/icons/calendar';

  constructor(
    private readonly store: Store,
    socketsService: SocketsService,
  ) {
    this.bindSocketUpdates(socketsService.get());
  }

  private bindSocketUpdates(socket: WrappedSocket): void {
    socket
      .fromEvent('notification:send:calendarEventGuestCreate')
      .pipe(untilDestroyed(this))
      .subscribe(({ data, type }: CalendarEventsGetUserEventsUpdatesResDto) => {
        switch (type) {
          case 'remove':
            this.handleDeleteEvent(data);
            break;
          case 'add':
            this.handleAddEvent(data);
            break;
          default:
            throw new Error(`Unknown type: ${type}`);
        }

        this.renderCalendarEvents();
      });
  }

  private handleDeleteEvent({
    eventId: eventDeletingId,
  }: Extract<CalendarEventsGetUserEventsUpdatesResDto, { type: 'remove' }>['data']): void {
    for (const userId of Object.keys(this.eventsByUserId)) {
      this.eventsByUserId[userId] = this.eventsByUserId[userId].filter(
        ({ eventId }) => eventId !== eventDeletingId,
      );
    }
  }

  isHovering(owner: string): boolean {
    return this.hoveringOwner === owner;
  }

  getIcon(name: string): string {
    return `${this.ICON_BASE_URL}/${name}.svg`;
  }

  private handleAddEvent(
    incomingEvent: Extract<CalendarEventsGetUserEventsUpdatesResDto, { type: 'add' }>['data'],
  ): void {
    const userIds = Object.keys(this.eventsByUserId);

    const userIdsFiltered = Object.keys(incomingEvent).filter((userId) => userIds.includes(userId));

    if (userIdsFiltered.length === 0) {
      return;
    }

    const eventsFiltered: CalendarEventsGetUserEventsResDto = {};

    for (const userId of userIdsFiltered) {
      eventsFiltered[userId] = incomingEvent[userId];
    }

    this.persistCalendarEvents(eventsFiltered);
  }

  ngAfterViewInit(): void {
    this.formUpdated.subscribe((event) => {
      const oldEvent = this.event;
      this.event = event;
      this.renderCalendarEvents();

      if (!Array.isArray(event.guests)) {
        return;
      }

      this.deleteRemovedUsers(event);

      if (
        event.guests.some((guest) => this.eventsByUserId[guest] === undefined) ||
        moment(event.start).diff(oldEvent.start, 'days') !== 0
      ) {
        this.getGuestEvents(event);
      }
    });
    this.getGuestEvents(this.event);
  }

  private deleteRemovedUsers(event: CalendarEventViewEvent): void {
    const toRemoveUsers = Object.keys(this.eventsByUserId).filter(
      (guest) => ![this.userId, ...event.guests].includes(guest),
    );

    if (toRemoveUsers.length === 0) {
      return;
    }

    for (const toRemoveUser of toRemoveUsers) {
      delete this.eventsByUserId[toRemoveUser];
    }

    this.renderCalendarEvents();
  }

  private getGuestEvents(event: CalendarEventViewEvent): Promise<void> {
    return firstValueFrom(
      this.store.dispatch(
        new CalendarEventGetUserEvents({
          excludeEventId: event.id,
          userIds: event.guests,
          day: event.start.toISOString(),
        }),
      ),
    ).then(({ CalendarEvents: { guestEvents } }: { CalendarEvents: CalendarEventsStateModel }) => {
      this.persistCalendarEvents(guestEvents);
      this.renderCalendarEvents();
    });
  }

  private persistCalendarEvents(guestEventsByGuestId: CalendarEventsGetUserEventsResDto): void {
    for (const [userId, events] of Object.entries(guestEventsByGuestId)) {
      this.eventsByUserId[userId] = events.map(
        ({ end, eventId, start, userName, isAllDay, ownerUserName, title }) => ({
          eventId,
          ownerUserName,
          start: new Date(start),
          end: new Date(end),
          userName,
          isAllDay,
          title,
        }),
      );
    }
  }

  private renderCalendarEvents(): void {
    const editingEvent: CalendarEvent = {
      id: this.event.id,
      start: this.event.start,
      end: this.event.end,
      title: this.event.title,
      color: { primary: '#2E90FA', secondary: '#EAF4FF' },
      allDay: this.event.allDay,
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
      draggable: true,
      meta: {
        isEditingEvent: true,
      },
    };

    const eventsById = new Map<
      string,
      {
        eventId: string;
        start: Date;
        end: Date;
        userNames: string[];
        allDay: boolean;
        ownerUserName: string;
        title: string;
      }
    >();

    for (const guestEvent of Object.values(this.eventsByUserId).flat()) {
      const eventId = guestEvent.eventId;

      const event = eventsById.get(eventId) ?? {
        eventId,
        start: moment(guestEvent.start).toDate(),
        end: moment(guestEvent.end).toDate(),
        userNames: [],
        allDay: guestEvent.isAllDay,
        ownerUserName: guestEvent.ownerUserName,
        title: guestEvent.title,
      };

      event.userNames.push(guestEvent.userName);

      eventsById.set(eventId, event);
    }

    const eventsMemoizedByOwner = new Map<string, { start: Date; end: Date }[]>();

    const allEvents: CalendarEvent[] = [
      editingEvent,
      ...[...eventsById.values()].map<CalendarEvent>((event) => {
        const isForeignEvent = !event.userNames.includes(this.userName);

        if (!eventsMemoizedByOwner.has(event.ownerUserName)) {
          eventsMemoizedByOwner.set(event.ownerUserName, []);
        }

        const otherOwnerEvents = eventsMemoizedByOwner.get(event.ownerUserName);

        const calendarEvent = {
          start: event.start,
          end: event.end,
          allDay: event.allDay,
          id: event.eventId,
          title: event.title,
          resizable: {
            beforeStart: false,
            afterEnd: false,
          },
          color: event.userNames.includes(this.userName)
            ? { primary: '#D7D7D7', secondary: '#ffffff' }
            : { primary: '#6D43D3', secondary: '#F0ECFB' },
          draggable: false,
          meta: {
            isForeignEvent,
            ownerName: event.ownerUserName,
            otherOwnerEvents,
          },
        };

        otherOwnerEvents.push({ start: calendarEvent.start, end: calendarEvent.end });

        return calendarEvent;
      }),
    ];

    this.viewEvents$.next(allEvents);
    this.eventRefresh.next();
  }

  dayClicked(args: any): void {
    this.eventRefresh.next();
  }

  hourClicked(args: any): void {
    this.eventRefresh.next();
  }

  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    this.eventChanged.emit({ start: newStart, end: newEnd });
  }
}
