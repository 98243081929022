import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';

import { BacklogTicket, BoardTicket } from './board-tickets.types';
import { BoardNameEnum, CurrentTab } from './board-tickets.enums';

import { BoardsState } from '../../../shared/store/states/boards.state';
import { TicketsGetList } from '../../../shared/store/actions/board.action';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TicketsDbDto } from '../../../api/models/tickets-db-dto';
import { distinctUntilChanged } from 'rxjs/operators';
import { BoardsService } from './boards.service';
import { ProjectsState } from '../../../shared/store/states/projects.state';
import { SpacesState } from '../../../shared/store/states/spaces.state';
import { UsersDbDto } from '../../../api/models/users-db-dto';
import { SprintsState } from '../../../shared/store/states/sprints.state';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { PriorityOrderService } from '../../../shared/services/priority-order.service';
import { SprintModal } from '../../../shared/store/models/SprintState';
import { SprintsDbDto } from '../../../api/models/sprints-db-dto';

@UntilDestroy()
@Injectable()
export class BoardTicketsService {
  private activeBoardTicketsSubscription$$: Subscription;
  private activeBoardUsersSubscription$$: Subscription;

  private activeBoardTickets = new BehaviorSubject<BoardTicket[] | BacklogTicket[]>([]);
  public activeBoardTickets$ = this.activeBoardTickets.asObservable();

  private tickets$ = new BehaviorSubject<BoardTicket[] | BacklogTicket[]>([]);

  private ticketMembers = new BehaviorSubject<UsersDbDto[]>([]);
  public ticketMembers$ = this.ticketMembers.asObservable();

  private moreMembers = new BehaviorSubject<UsersDbDto[]>([]);
  public moreMembers$ = this.moreMembers.asObservable();

  private filtersForArchive = new BehaviorSubject({
    members: [],
    labels: [],
    search: '',
  });
  public filtersForArchive$ = this.filtersForArchive.asObservable();

  public filteredMembers: string[] = [];
  public filteredLabels: string[] = [];
  public isSearchMode = false;
  public search = '';
  public filteredBy: string;

  public toggleRenderingStrategy = new EventEmitter();

  constructor(
    private boardService: BoardsService,
    private priorityOrderService: PriorityOrderService,
    private router: Router,
    private store: Store,
  ) {}

  public get boardTickets(): BoardTicket[] | BacklogTicket[] {
    return this.tickets$.value;
  }

  public refreshActiveBoard(): void {
    this.filteredLabels = [];
    this.filteredMembers = [];
    this.filtersForArchive.next({ search: '', members: [], labels: [] });
    this.activeBoardTicketsSubscription$$?.unsubscribe();
    if (!this.store.selectSnapshot(BoardsState.getTicketsList)?.length) {
      this.store.dispatch(
        new TicketsGetList({
          object: this.boardService.object,
          objectId: this.boardService.objectId,
        }),
      );
    }

    this.activeBoardTicketsSubscription$$ = this.store
      .select(BoardsState.getTicketsList)
      .pipe(
        distinctUntilChanged(
          (previous, current) => JSON.stringify(previous) === JSON.stringify(current),
        ),
        untilDestroyed(this),
      )
      .subscribe((tickets) => {
        const currentBoardTickets = this.filterTicketsByBoardType(
          this.selectorName,
          this.remapTickets<BacklogTicket>(this.selectorName, tickets),
        );

        this.tickets$.next(currentBoardTickets);

        if (
          (this.isSearchMode && this.search) ||
          this.filteredMembers.length ||
          this.filteredLabels.length
        ) {
          if (this.isSearchMode && this.search) {
            this.searchTickets(this.search, currentBoardTickets);
          }

          if (this.filteredMembers.length || this.filteredLabels.length) {
            this.filterTickets(currentBoardTickets);
          }
        } else {
          const archiveColumnId = this.store
            .selectSnapshot(BoardsState.getColumnsList)
            .find((item) => item.boardColorClass === 'status-archive')?._id;

          this.activeBoardTickets.next(
            currentBoardTickets.filter((ticket) => ticket.columnId !== archiveColumnId),
          );
        }

        this.refreshUsers();
      });
  }

  private filterTicketsByBoardType<T>(currentTub: CurrentTab, tickets: Array<T>): Array<T> {
    const isBoardTicket = (ticket: BoardTicket | BacklogTicket) =>
      !ticket.sprintId && ticket.columnId !== ticket.objectId;
    const isSprintTicket = (ticket: BoardTicket | BacklogTicket, activeSprintId: string) =>
      ticket.sprintId && ticket.columnId !== ticket.objectId && ticket.sprintId === activeSprintId;

    return tickets.filter((ticket) => {
      const ticketsToCheck = ticket as BoardTicket | BacklogTicket;
      if (!ticketsToCheck.isEpic && !ticketsToCheck.isDeleted) {
        if (currentTub === CurrentTab.Board) {
          return isBoardTicket(ticketsToCheck);
        }
        if (currentTub === CurrentTab.Sprint) {
          const activeSprint = this.store.selectSnapshot(SprintsState.getActiveSprint);

          return isSprintTicket(ticketsToCheck, activeSprint?._id);
        }
        if (currentTub === CurrentTab.Backlog) {
          return ticketsToCheck;
        }
      }
    });
  }
  private remapTickets<T>(
    ticketType: CurrentTab.Board | CurrentTab.Sprint | CurrentTab.Backlog | CurrentTab.Archive,
    tickets: Array<TicketsDbDto>,
  ): Array<T> {
    switch (ticketType) {
      case CurrentTab.Board:
      case CurrentTab.Sprint: {
        return tickets.map((ticket) => ({
          _id: ticket._id,
          sprintId: ticket.sprintId,
          epicId: ticket?.epicId,
          counter: ticket.counter,
          boardAbbreviation: ticket.boardAbbreviation,
          description: ticket.description,
          title: ticket.title,
          objectId: ticket.objectId,
          object: ticket.object,
          columnId: ticket.columnId,
          bgColor: ticket.bgColor,
          parentId: ticket.parentId,
          ownerUserId: ticket.ownerUserId,
          order: ticket.order,
          orderInBacklog: ticket.orderInBacklog,
          isBlocked: ticket.isBlocked,
          priority: ticket.priority,
          previewUrl: ticket.previewUrl,
          dueDate: ticket.dueDate,
          startDate: ticket.startDate,
          releaseVersion: ticket.releaseVersion,
          labels: ticket.labels,
          customFields: ticket.customFields,
          checkListItems: ticket.checkListItems,
          ticketsMembers: ticket.ticketsMembers,
          estimate: ticket.estimate,
          column: ticket.column,
          parentTicket: ticket.parentTicket,
        })) as Array<T>;
      }
      case CurrentTab.Archive:
      case CurrentTab.Backlog: {
        return tickets.map((ticket) => ({
          _id: ticket._id,
          sprintId: ticket.sprintId,
          epicId: ticket?.epicId,
          counter: ticket.counter,
          isEpic: ticket.isEpic,
          boardAbbreviation: ticket.boardAbbreviation,
          description: ticket.description,
          title: ticket.title,
          objectId: ticket.objectId,
          object: ticket.object,
          columnId: ticket.columnId,
          parentId: ticket.parentId,
          ownerUserId: ticket.ownerUserId,
          order: ticket.order,
          orderInBacklog: ticket.orderInBacklog,
          priority: ticket.priority,
          labels: ticket.labels,
          ticketsMembers: ticket.ticketsMembers,
          estimate: ticket.estimate,
        })) as Array<T>;
      }
    }
  }

  public refreshUsers(ticketsArchive?: Array<TicketsDbDto>): void {
    this.activeBoardUsersSubscription$$?.unsubscribe();
    let usersObservable: Observable<UsersDbDto[]>;
    if (this.boardService.object === 'spaces') {
      usersObservable = this.store.select(SpacesState.getUsers);
    } else {
      usersObservable = this.store.select(ProjectsState.getUsers);
    }
    this.activeBoardUsersSubscription$$ = usersObservable.subscribe((users) => {
      if ((users?.length && ticketsArchive) || this.activeBoardTickets.value?.length) {
        const tickets = ticketsArchive || this.activeBoardTickets.value;
        const membersWithNumberOfTickets = users
          .map((user) => ({
            ...user,
            numberOfAssignedTickets: tickets?.filter((ticket) =>
              ticket.ticketsMembers?.some((member) => member.userId === user._id),
            )?.length,
          }))
          .sort((a, b) => b.numberOfAssignedTickets - a.numberOfAssignedTickets);
        const moreMembers = membersWithNumberOfTickets.filter(
          (_, index) =>
            index >
            (this.boardService.isMobile
              ? this.boardService.MAX_LINED_UP_BOARD_MEMBERS_MOBILE
              : this.boardService.MAX_LINED_UP_BOARD_MEMBERS) -
              1,
        );
        this.ticketMembers.next(membersWithNumberOfTickets);
        this.moreMembers.next(moreMembers);
      }
    });
  }

  public filterTicketsByMember(userId: string) {
    if (!this.filteredMembers.includes(userId)) {
      this.filteredMembers = [...this.filteredMembers, userId];
    } else {
      this.filteredMembers = this.filteredMembers.filter((id) => id !== userId);
    }

    this.filtersForArchive.next({
      ...this.filtersForArchive.value,
      members: this.filteredMembers,
    });

    this.filterTickets();
  }

  public filterTicketsByLabels(labels: Array<string>) {
    this.filteredLabels = labels;

    this.filtersForArchive.next({
      ...this.filtersForArchive.value,
      labels: this.filteredLabels,
    });

    this.filterTickets();
  }

  // public filterTicketsBy(filteredBy) {
  //   console.log('filteredBy', filteredBy);
  //   this.filteredBy = filteredBy;

  //   this.filterTickets();
  // }

  private filterTickets(tickets?: Array<BoardTicket | BacklogTicket>): void {
    this.toggleRenderingStrategy.emit();

    const filterByLabels = (ticket: BoardTicket) =>
      this.filteredLabels.every((label) => ticket.labels.includes(label));

    const filterByMembers = (ticket: BoardTicket) =>
      this.filteredMembers.every((memberId) =>
        ticket.ticketsMembers.some((member) => member.userId === memberId),
      );

    const filterTicketsBy = (ticket: BoardTicket) => {
      return ticket[this.filteredBy] !== null && ticket[this.filteredBy] !== undefined;
    };

    if (this.filteredLabels?.length || this.filteredMembers?.length || this.filteredBy) {
      this.activeBoardTickets.next(
        (tickets || this.tickets$.value).filter(
          (ticket) => filterByLabels(ticket) && filterByMembers(ticket) && filterTicketsBy(ticket),
        ),
      );
    } else {
      this.refreshActiveBoard();
    }
  }

  public clearFilters() {
    this.toggleRenderingStrategy.emit();
    this.filteredMembers = [];
    this.filtersForArchive.next({
      ...this.filtersForArchive.value,
      members: [],
    });
    this.filteredBy = '';
    this.filterTickets();
  }

  public searchTickets(search: string, tickets?: Array<BoardTicket | BacklogTicket>) {
    this.toggleRenderingStrategy.emit();
    this.isSearchMode = !!search?.length;
    this.search = search;

    this.filtersForArchive.next({
      ...this.filtersForArchive.value,
      search: search || '',
    });

    this.activeBoardTickets.next(
      search?.length
        ? (tickets || this.boardTickets).filter(
            (ticket) =>
              ticket.title.toLowerCase().trim().includes(search.toLowerCase().trim()) ||
              ticket.description?.toLowerCase().trim().includes(search.toLowerCase().trim()) ||
              `${ticket.boardAbbreviation}-${ticket.counter}`.includes(search),
          )
        : this.boardTickets,
    );
  }

  public clearSearch() {
    this.toggleRenderingStrategy.emit();
    this.isSearchMode = false;
    this.search = '';
    this.refreshActiveBoard();
  }

  public get isTicketsFiltered() {
    return !!(this.filteredLabels?.length || this.filteredMembers?.length || this.isSearchMode);
  }

  public dropTicket(event: CdkDragDrop<Array<TicketsDbDto>>, sprint?: SprintModal | SprintsDbDto) {
    if (
      event.currentIndex !== event.previousIndex ||
      event.container.id !== event.previousContainer.id
    ) {
      const todoColumn = this.store
        .selectSnapshot(BoardsState.getColumnsList)
        .find((column) => column['lDisplayName'] === 'column-todo');

      let columnId = '';
      let sprintId = null;

      if (event.container.id === BoardNameEnum.Backlog) {
        columnId = event.item.data.objectId;
      } else if (event.previousContainer.id === BoardNameEnum.Backlog) {
        columnId = todoColumn._id;

        if (event.container.id !== BoardNameEnum.Board) {
          sprintId = sprint._id;
        }
      } else {
        columnId = event.item.data.columnId;

        if (event.container.id !== BoardNameEnum.Board) {
          sprintId = sprint._id;
        }
      }

      this.priorityOrderService.recountTicketOrders(
        {
          ...event.item.data,
          columnId,
          sprintId,
          orderInBacklog: event.currentIndex,
        },
        {
          withDnd: {
            inBacklog: true,
            isChangeColumn: event.previousContainer !== event.container,
            isTicketsFiltered: this.isTicketsFiltered,
          },
        },
      );
    }
  }

  public get selectorName(): CurrentTab {
    return this.isSprintBoard()
      ? CurrentTab.Sprint
      : this.isKanbanBoard()
        ? CurrentTab.Board
        : this.isBacklog()
          ? CurrentTab.Backlog
          : CurrentTab.Archive;
  }

  public isSprintBoard(): boolean {
    return this.router.url.includes('/sprint') || this.router.url.includes('subTab=sprint');
  }
  public isBacklog(): boolean {
    return this.router.url.includes('/backlog') || this.router.url.includes('subTab=backlog');
  }
  public isKanbanBoard(): boolean {
    return (
      this.router.url.includes('/kanban') ||
      (this.router.url.includes('tab=tabBoard') && this.router.url.includes('subTab=kanban'))
    );
  }

  public setFilteredBy(filteredBy) {
    this.filteredBy = filteredBy;
    this.filterTickets();
  }
}
