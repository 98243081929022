import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CdkDragDrop, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';
import { NgScrollbar } from 'ngx-scrollbar';

// Types
import { DragAndDropActionEnum } from './enums/drag-and-drop-action.enum';
import { ColumnActionEnum } from './enums/column-action.enum';

// Utils
import DragAndDropUtils from '../../../shared/utils/drag-and-drop-utils';

// Services
import { BoardLaneService } from './board-lane.service';
import { ColumnService } from './components/column/column.service';
import { SvgComponent } from '../../svgs/svg/svg.component';
import { ColumnComponent } from './components/column/column.component';
import { NgFor } from '@angular/common';
import { TranslocoDirective } from '@ngneat/transloco';
import { MixpanelService } from '../../../plugins/mixpanel/mixpanel.service';

@Component({
  selector: 'app-board-lane',
  templateUrl: './board-lane.component.html',
  styleUrls: ['./board-lane.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgScrollbar,
    CdkDropList,
    CdkDropListGroup,
    NgFor,
    ColumnComponent,
    SvgComponent,
  ],
})
export class BoardLaneComponent implements OnInit {
  @ViewChild('scrollable') scrollRef: NgScrollbar;

  boardColumns = [];
  scrollConfig = {
    suppressScrollX: false,
    suppressScrollY: false,
    wheelPropagation: true,
  };

  @Input() cards = [];
  @Input() currentUser;
  @Input() isShowContent = true;
  @Input() isShowCreator = true;
  @Input() isShowVotes = true;
  @Input() themeMode: string;

  /*
   * You can define key which will connect cards to column
   * */
  @Input() relationColumnKey = 'columnId';
  @Input() set columns(data) {
    this.boardColumns = data;
    this.boardLaneService.defineColumnStyles(this.boardColumns);
    this.updateScroll();
  }

  constructor(
    private boardLaneService: BoardLaneService,
    private columnService: ColumnService,
  ) {}

  /* Hooks */
  public ngOnInit(): void {
    this.initializeBoard();
  }

  /* Methods */
  public handleAddColumn(): void {
    MixpanelService.trackEvent('Lane Board: add column');
    this.columnService.broadcastClickOnActionItem({
      type: ColumnActionEnum.AddColumn,
    });
  }

  public drop(event: CdkDragDrop<string[]>): void {
    this.boardColumns = DragAndDropUtils.moveItemInArray(
      [...this.boardColumns],
      event.previousIndex,
      event.currentIndex,
    );

    const payload = {
      event,
      droppedItemId: event.item.data._id,
      boardColumns: this.boardColumns,
      updatedOrder: event.currentIndex,
      type: DragAndDropActionEnum.ColumnDrop,
    };

    this.boardLaneService.broadcastDragAndDrop(payload);
  }

  public findColumnCards(columnId: string) {
    if (!columnId || !this.cards) {
      return [];
    }

    const filteredCards = this.cards
      .filter((card) => card[this.relationColumnKey] === columnId)
      .sort((a, b) => (a.order > b.order ? 1 : -1));

    return filteredCards;
  }

  private initializeBoard(): void {
    this.boardLaneService.defineColumnStyles(this.boardColumns);
  }

  private updateScroll(): void {
    setTimeout(() => this.scrollRef?.update(), 0);
  }
}
