<div
  class="threads-info d-flex flex-column justify-content-center"
  *transloco="let t; read: 'chat'"
>
  <div *ngIf="!isMobile" class="switch-threads custom-control custom-switch switch-sm">
    <input
      type="checkbox"
      class="custom-control-input"
      id="threadsSwitch"
      #threadsCheckbox
      [checked]="switchThreadsForChat"
      (change)="toggleThreadsForChat(threadsCheckbox.checked)"
    />
    <label
      class="custom-control-label d-flex align-content-center font-small-1 cursor-pointer"
      for="threadsSwitch"
      t-id="chat-threads-list-only-for"
    >
      <span class="switch-title mr-1">{{ t('form-threads-switch-show') }}</span>
      <span class="workspace-title">{{ workspaceTitle }}</span>
    </label>
  </div>

  <div class="d-flex align-items-center">
    <div class="switch-threads custom-control custom-switch switch-sm">
      <input
        type="checkbox"
        class="custom-control-input"
        id="threadsFilter"
        #threadsFilter
        [checked]="isOnlyUnreadThreads"
        (change)="filterUnreadThreads(threadsFilter.checked)"
      />
      <label
        class="custom-control-label d-flex align-content-center font-small-1 cursor-pointer"
        for="threadsFilter"
      >
        <span class="switch-title mr-1">{{ t('form-threads-filter-show') }}</span>
        <span class="workspace-title">{{ t('form-threads-switch-unread') }}</span>
        <span class="d-flex align-items-center ml-1" [class.flex-grow-1]="isMobile">
          <!-- <span *ngIf="threadsListInfo?.totalUnreadMentions > 0" class="primary-circle">
	          {{ threadsListInfo.totalUnreadMentions }}
	        </span>
	        <span *ngIf="threadsListInfo?.totalUnreadMessages" class="grey-circle ml-1">
		        {{ threadsListInfo.totalUnreadMessages }}
	        </span> -->
        </span>
      </label>
    </div>

    <div class="flex-grow-1"></div>
    <button
      *ngIf="threadsListInfo?.totalUnreadMessages > 0"
      class="btn btn-sm btn-subtle"
      [disabled]="!isLoaded"
      (click)="markAllAsRead($event)"
    >
      {{ t('btn-read-all') }}
    </button>
  </div>
</div>

<div class="thread-list position-relative" *transloco="let t; read: 'chat'">
  <ng-scrollbar
    id="threadList"
    t-id="chat-thread-list-item"
    #scrollableThreads="ngScrollbar"
    class="chat-thread-list scroll-event"
    thumbClass="white-scrollbars"
  >
    <div class="thread-list-body">
      <div *ngFor="let thread of threadsList" class="thread-card">
        <div class="d-flex align-items-start justify-content-start" *ngIf="thread">
          <div class="overflow-hidden w-100">
            <div class="breadcrumbs">
              <app-space-avatar
                *ngIf="thread && thread?.spaceName"
                [space]="thread"
                [isBreadcrumb]="true"
                [containerWidth]="24"
                [containerHeight]="24"
              >
              </app-space-avatar>
              <a
                *ngIf="thread && thread?.spaceChatId"
                [routerLink]="getRouteToChat(thread, true) | routerTenant"
              >
                {{ thread.spaceName }}
              </a>
              <span *ngIf="!thread?.spaceChatId && thread.spaceName">{{ thread.spaceName }}</span>

              <app-svg
                *ngIf="thread.chatId !== thread?.spaceChatId && thread.spaceName"
                class="mx-1"
                name="angle-right"
                [width]="7"
                [height]="7"
                [fill2]="config?.layout.variant === 'Dark' ? '#A8AABD' : '#1E1C25'"
              ></app-svg>
              <app-project-avatar
                *ngIf="thread.projectId"
                [project]="thread"
                [isBreadcrumb]="true"
                [containerWidth]="24"
                [containerHeight]="24"
                class="w-[24px]"
              ></app-project-avatar>
              <app-group-avatar
                *ngIf="
                  thread.chatId !== thread.spaceChatId && thread.spaceName && !thread.projectId
                "
                [isBreadcrumb]="true"
              ></app-group-avatar>
              <app-avatar
                *ngIf="!thread.spaceName"
                [width]="24"
                [height]="24"
                [statusSize]="3"
                [userId]="thread.chatUserId"
              ></app-avatar>
              <a
                *ngIf="thread.chatId !== thread.spaceChatId"
                [routerLink]="getRouteToChat(thread) | routerTenant"
              >
                {{ thread.chatName }}
              </a>

              <app-svg
                *ngIf="(screenRecord.recordObjectId | async) === thread._id"
                name="record"
                width="20"
                height="20"
                aria-hidden="true"
                class="chat-record"
              ></app-svg>
            </div>

            <div class="thread-content" (click)="openThreadSidebar($event, thread)">
              <app-chat-message-content
                [platform]="platform"
                [message]="thread.message"
                [mentionChatMembers]="mentionChatMembers"
                context="threads-list"
                [class.with-linked-obj]="thread.message.linkObject"
              >
              </app-chat-message-content>
            </div>

            <div
              *ngIf="thread.message?.linkObject || getUrl(thread.message)"
              class="with-linked-object d-flex mb-1 flex-wrap"
            >
              <!-- VIEW URL TICKET -->
              <ng-container *ngFor="let urlTicket of thread.message.ticketsUrlData">
                <app-linked-object-badge
                  [platform]="platform"
                  urlReplace="ticket"
                  [message]="thread.message"
                  [data]="urlTicket"
                  [withView]="true"
                  class="mr-2"
                  [disabledAction]="!hasPermissionToTicket(urlTicket)"
                ></app-linked-object-badge>
              </ng-container>
              <!-- VIEW URL TICKET -->

              <!-- VIEW URL WIKI -->
              <ng-container *ngFor="let wiki of thread.message.wikiPagesUrlData">
                <app-linked-object-badge
                  [object]="object"
                  [objectId]="objectId"
                  [platform]="platform"
                  urlReplace="wiki"
                  [message]="thread.message"
                  [data]="wiki"
                  [withView]="true"
                  class="mr-2"
                  [disabledAction]="!hasPermissionToTicket(wiki)"
                ></app-linked-object-badge>
              </ng-container>
              <!-- VIEW URL WIKI -->

              <!-- VIEW FILES -->
              <div *ngIf="getIsFilesObject(thread.message)">
                <app-message-files
                  [isMobile]="isMobile"
                  [message]="thread.message"
                  [platform]="platform"
                  [object]="object"
                  [objectId]="objectId"
                ></app-message-files>
              </div>
              <!-- VIEW FILES -->

              <ng-container
                *ngIf="thread.message.linkObject && thread.message.linkObject !== 'video-calls'"
              >
                <app-linked-object-badge
                  [object]="object"
                  [objectId]="objectId"
                  [message]="thread.message"
                  [platform]="platform"
                  [withView]="false"
                  class="d-inline-block linked-obj-badge"
                >
                </app-linked-object-badge>
              </ng-container>
            </div>

            <div
              class="reply-content d-flex align-items-center"
              [class.has-unread-replies]="
                thread.threadsMessagesInfo?.numberOfUnreadMentions > 0 ||
                thread.threadsMessagesInfo?.numberOfUnreadMessages > 0
              "
              (click)="openThreadSidebar($event, thread)"
            >
              <div class="reply-text">
                {{ thread.threadsMessagesInfo?.messagesCount }}
                {{ thread.threadsMessagesInfo?.messagesCount > 1 ? t('replies') : t('reply') }}
              </div>

              <div class="last-reply">
                {{ t('last-reply') }} {{ getLastReplyText(thread.lastReply) }}
              </div>
              <div class="view-thread">
                {{ t('view-thread') }}
                <app-svg name="right" width="12" height="11"></app-svg>
              </div>

              <div class="flex-grow-1"></div>

              <div class="d-flex">
                <div
                  *ngIf="thread.threadsMessagesInfo?.numberOfUnreadMentions > 0"
                  class="primary-circle ml-1"
                >
                  {{ thread.threadsMessagesInfo.numberOfUnreadMentions }}
                </div>
                <div
                  *ngIf="thread.threadsMessagesInfo?.numberOfUnreadMessages > 0"
                  class="grey-circle ml-1"
                >
                  {{ thread.threadsMessagesInfo.numberOfUnreadMessages }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-scrollbar>

  <ng-container *ngIf="!isLoaded && !threadsList?.length">
    <div class="loading" [class.loading-dark]="config?.layout.variant === 'Dark'">
      <app-svg name="spinner" width="38" height="38" fill="#C6C8D7" class="loading-icon"></app-svg>
    </div>
  </ng-container>
</div>
