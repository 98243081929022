import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';

// Types
import { CardType } from '../../types/card.type';
import { CardActionEnum } from '../../enums/card-action.enum';

// Services
import { CardService } from './card.service';
import { AvatarComponent } from '../../../../../standalone/components/avatar/avatar.component';
import { SvgComponent } from '../../../../svgs/svg/svg.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { ClickOutsideDirective } from '../../../../directives/click-outside.directive';
import {
  NgbTooltip,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
} from '@ng-bootstrap/ng-bootstrap';
import { NgIf } from '@angular/common';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { TranslocoDirective } from '@ngneat/transloco';
import { MixpanelService } from '../../../../../plugins/mixpanel/mixpanel.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    CdkDrag,
    NgIf,
    NgbTooltip,
    FormsModule,
    ClickOutsideDirective,
    ReactiveFormsModule,
    ClickOutsideModule,
    NgbDropdown,
    NgbDropdownToggle,
    SvgComponent,
    NgbDropdownMenu,
    forwardRef(() => AvatarComponent),
  ],
})
export class CardComponent implements OnInit, AfterViewInit {
  @ViewChild('cardTextarea') cardTextarea: ElementRef;

  @Input() card: CardType;
  @Input() currentUser;
  @Input() isShowContent = true;
  @Input() isShowCreator = true;
  @Input() isShowVotes = true;
  @Input() themeMode: string;

  /* Variables */
  public title: FormControl = new FormControl('', Validators.required);
  public readonly cardActionEnum = CardActionEnum;

  constructor(private cardService: CardService) {}

  /* Hooks */
  ngOnInit(): void {
    this.title.patchValue(this.card?.title);
  }

  ngAfterViewInit(): void {
    this.focus();
  }

  /* Getters */
  get cardId(): string {
    return this.card?._id ?? this.card?.id;
  }

  get isCreatorShow(): boolean {
    return (
      this.card.ownerUserId === this.currentUser?._id ||
      (this.card.ownerUserId && this.card.owner && this.isShowCreator)
    );
  }

  get isContentShow(): boolean {
    return this.card.ownerUserId === this.currentUser?._id || this.isShowContent;
  }

  /* Methods */
  public handleClickMenuItem(cardActionType: CardActionEnum, event?): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (!this.title.value || this.title.value.trim() === '') {
      return;
    }

    this.cardService.broadcastClickOnActionItem({
      type: cardActionType,
      payload: { ...this.card, ...{ title: this.title.value.trim() } },
    });
  }

  public async handleDblClick(): Promise<void> {
    MixpanelService.trackEvent('Lane Board: double click on card');
    await navigator.clipboard.writeText(this.title.value);
  }

  public handleEnter(): void {
    this.handleClickMenuItem(CardActionEnum.CloseEditMode);
    MixpanelService.trackEvent('Lane Board: enter pressed');
  }

  public handleClickOutside(): void {
    MixpanelService.trackEvent('Lane Board: click outside card');
    this.handleClickMenuItem(CardActionEnum.CloseEditMode);
  }

  private focus(): void {
    MixpanelService.trackEvent('Lane Board: focus on card');
    if (this.card.editMode && this.cardTextarea && this.cardTextarea.nativeElement) {
      this.cardTextarea.nativeElement.focus();
    }
  }
}
