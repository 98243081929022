<ng-scrollbar
  #scrollableTable="ngScrollbar"
  class="scroll-event scrollable-container"
  visibility="always"
  thumbClass="white-scrollbars"
  *transloco="let t; read: 'manage-spaces'"
>
  <table
    mat-table
    matSort
    (matSortChange)="sortData($event)"
    [dataSource]="sortedData"
    multiTemplateDataRows
    class="mat-elevation-z8"
    [class.dark-table]="config?.layout.variant === 'Dark'"
  >
    <ng-container matColumnDef="{{ column }}" *ngFor="let column of currentColumnsToDisplay">
      <ng-container *ngIf="column === 'expand'">
        <th
          mat-header-cell
          [mat-sort-header]="column"
          *matHeaderCellDef
          [class.table_expand]="column === 'expand'"
        >
          &nbsp;
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="expand"
          [class.table_expand-td]="column === 'expand'"
        >
          <div
            *ngIf="element.projects.length"
            aria-label="expand row"
            (click)="changeExpandedElement(element); $event.stopPropagation()"
            t-id="table-callapse-expand"
            class="mt-1"
          >
            <mat-icon *ngIf="!element.isExpanded">keyboard_arrow_right</mat-icon>
            <mat-icon *ngIf="element.isExpanded">keyboard_arrow_down</mat-icon>
          </div>
        </td>
      </ng-container>
      <ng-container *ngIf="column === 'actions'">
        <th mat-header-cell [mat-sort-header]="column" *matHeaderCellDef>&nbsp;</th>
        <td mat-cell *matCellDef="let element" class="expand">
          <div class="menu-icons d-flex align-items-center mr-1">
            <button
              *ngIf="element.isAccess || isProject"
              t-id="chat-navigation-tree-dot-menu"
              class="btn dropdown d-flex align-items-center p-0"
              ngbDropdown
              placement="auto"
              container="body"
              [autoClose]="true"
              (openChange)="actionsDropdownToggle($event)"
              (click)="detectProjectSpace(element)"
            >
              <app-svg
                name="ellipsis"
                width="17"
                height="4"
                [hoverEffect]="true"
                class="dropdown-toggle cursor-pointer"
                ngbDropdownToggle
              ></app-svg>

              <div *ngIf="!archive" ngbDropdownMenu class="dropdown-menu">
                <a
                  href="javascript:;"
                  class="dropdown-item"
                  *ngIf="'spaces::' + element._id + '::projectCreate' | checkPermission"
                  t-id="chat-navigation-create-project"
                  (click)="projectService.createClick(element)"
                >
                  <app-svg name="plus" width="11" height="11" class="mr-2"></app-svg>
                  <span>{{ t('btn-add-project') }}</span>
                </a>
                <a
                  href="javascript:;"
                  *ngIf="'spaces::' + element._id + '::spacesUpdate' | checkPermission"
                  class="dropdown-item"
                  (click)="spaceService.updateClick(element._id)"
                  t-id="table-edit-space"
                >
                  <app-svg name="pen" width="11" height="11" class="mr-2"></app-svg>
                  <span>{{ t('btn-edit') }}</span>
                </a>
                <a
                  href="javascript:;"
                  class="dropdown-item"
                  (click)="spaceService.leaveClick(element)"
                  t-id="table-leave-space"
                >
                  <i class="ft-log-out mr-2"></i>
                  <span>{{ t('btn-leave') }}</span>
                </a>
                <a
                  href="javascript:;"
                  *ngIf="'spaces::' + element._id + '::spacesToggleArchiveStatus' | checkPermission"
                  class="dropdown-item"
                  (click)="handlerArchiveSpaceModal(element)"
                  t-id="table-archive-space"
                >
                  <app-svg name="archive" width="11" height="11" class="mr-2"></app-svg>
                  <span>{{ t('btn-archive') }}</span>
                </a>
              </div>
              <div *ngIf="archive" ngbDropdownMenu class="dropdown-menu">
                <a
                  href="javascript:;"
                  class="dropdown-item d-flex align-items-center"
                  t-id="table-unarchive-space"
                  [class.blocked-button-active]="activeUnarchiveProject && isProject"
                  *ngIf="
                    (permissionName +
                      '::' +
                      element._id +
                      '::' +
                      permissionStatus +
                      'ToggleArchiveStatus' | checkPermission) || handleCheckOwner(element)
                  "
                  (click)="
                    isProject
                      ? !activeUnarchiveProject && projectService.unArchive(element)
                      : spaceService.unArchiveSpace(element)
                  "
                >
                  <app-svg
                    name="unarchive"
                    width="11"
                    height="14"
                    class="mr-1"
                    [class.blocked-button-table]="activeUnarchiveProject && isProject"
                  ></app-svg>
                  <span [class.blocked-button-table]="activeUnarchiveProject && isProject">
                    {{ t('btn-unarchive') }}
                  </span>
                  <app-svg
                    *ngIf="activeUnarchiveProject && isProject"
                    name="info-circle"
                    width="16"
                    height="16"
                    class="ml-1"
                    triggers="hover"
                    [ngbTooltip]="t('unarchive-space-tooltip')"
                  ></app-svg>
                </a>
                <a
                  *ngIf="
                    (permissionName + '::' + element._id + '::' + permissionStatus + 'Delete'
                      | checkPermission) || handleCheckOwner(element)
                  "
                  href="javascript:;"
                  class="dropdown-item"
                  (click)="
                    isProject
                      ? handlerDeleteProjectModal(element)
                      : handlerDeleteSpaceModal(element)
                  "
                  t-id="table-delete-space"
                >
                  <app-svg name="trash" width="11" height="11" class="mr-2 error"></app-svg>
                  <span class="error">{{ t('btn-delete') }}</span>
                </a>
                <a
                  href="javascript:;"
                  class="dropdown-item"
                  *ngIf="
                    !(
                      permissionName + '::' + element._id + '::' + permissionStatus + 'Delete'
                      | checkPermission
                    ) &&
                    !(
                      permissionName +
                        '::' +
                        element._id +
                        '::' +
                        permissionStatus +
                        'ToggleArchiveStatus' | checkPermission
                    ) &&
                    !handleCheckOwner(element)
                  "
                >
                  <span>{{ t('no-actions-available') }}</span>
                </a>
              </div>
            </button>
          </div>
        </td>
      </ng-container>
      <ng-container *ngIf="column === 'projects'">
        <th mat-header-cell [mat-sort-header]="column" *matHeaderCellDef class="table_projects">
          <img src="assets/img/svg/sort.svg" alt="sort" /> {{ projectsDisplay }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element[column].length }}</td>
      </ng-container>
      <ng-container *ngIf="column === 'teammates'">
        <th mat-header-cell [mat-sort-header]="column" *matHeaderCellDef class="table_projects">
          <img src="assets/img/svg/sort.svg" alt="sort" />
          {{ teammatesDisplay }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.isAccess || isProject ? element[column] : '-' }}
        </td>
      </ng-container>
      <ng-container *ngIf="column === 'name'">
        <th
          mat-header-cell
          [mat-sort-header]="column"
          *matHeaderCellDef
          [class.table_name]="column === 'name'"
        >
          <img src="assets/img/svg/sort.svg" alt="sort" /> {{ column }}
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="d-flex align-items-center"
          [class.table_name-td]="column === 'name'"
        >
          <ng-container *ngIf="isProject; else spaceAvatar">
            <app-project-avatar
              [project]="element"
              class="w-[24px] h-[24px] max-w-[24px] max-h-[24px]"
            ></app-project-avatar>
            <span class="ml-3">{{ element[column] }}</span>
          </ng-container>
          <ng-template #spaceAvatar>
            <app-space-avatar
              [space]="element"
              class="w-[24px] h-[24px] max-w-[24px] max-h-[24px]"
            ></app-space-avatar>
            <span class="ml-3 pt-2">{{ element[column] }}</span>
          </ng-template>
        </td>
      </ng-container>
      <th mat-header-cell [mat-sort-header]="column" *matHeaderCellDef>
        <img src="assets/img/svg/sort.svg" alt="sort" /> {{ ownerDisplay }}
      </th>
      <ng-container *ngIf="column === 'owner'">
        <th mat-header-cell [mat-sort-header]="column" *matHeaderCellDef>
          {{ ownerDisplay }}
        </th>
        <td mat-cell *matCellDef="let element">
          <app-avatar
            class="w-[24px] h-[24px] max-w-[24px] max-h-[24px]"
            *ngIf="element.isAccess || isProject"
            [userId]="element.ownerId"
            [showUserName]="true"
          ></app-avatar>
          <span *ngIf="!(element.isAccess || isProject)">-</span>
        </td>
      </ng-container>
      <th mat-header-cell [mat-sort-header]="column" *matHeaderCellDef>
        {{ column }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="columnsToDisplay.length"
        [class.table-spaceProject]="element"
      >
        <div class="example-element-detail" [@detailExpand]="isExpanded(element)">
          <table
            *ngIf="element?.projects.length"
            matSort
            mat-table
            multiTemplateDataRows
            class="mat-elevation-z8"
            (matSortChange)="sortExpandedData($event, element)"
            [dataSource]="getSortedProjectsBySpace(element)"
          >
            <ng-container
              matColumnDef="{{ column }}"
              *ngFor="let column of columnsForExpandToDisplay"
            >
              <ng-container *ngIf="column === 'name'">
                <th
                  mat-header-cell
                  [mat-sort-header]="column"
                  *matHeaderCellDef
                  class="table-spaceProject table_name-project"
                >
                  <img src="assets/img/svg/sort.svg" alt="sort" /> {{ column }}
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="d-flex align-items-center table_name-td"
                >
                  <app-project-avatar
                    class="w-[24px] h-[24px] max-w-[24px] max-h-[24px]"
                    [project]="element"
                  ></app-project-avatar>
                  <span class="ml-2">{{ element[column] }}</span>
                </td>
              </ng-container>
              <ng-container *ngIf="column === 'prefix'">
                <th mat-header-cell *matHeaderCellDef class="table-spaceProject table_prefix">
                  {{ prefixDisplay }}
                </th>
                <td mat-cell *matCellDef="let element">
                  <span class="ml-2">{{ element[column] }}</span>
                </td>
              </ng-container>
              <ng-container *ngIf="column === 'actions'">
                <th
                  mat-header-cell
                  [mat-sort-header]="column"
                  *matHeaderCellDef
                  class="table-spaceProject"
                ></th>
                <td mat-cell *matCellDef="let element" class="expand">
                  <div class="menu-icons d-flex align-items-center mr-1">
                    <button
                      t-id="chat-navigation-tree-dot-menu"
                      class="btn dropdown d-flex align-items-center p-0"
                      ngbDropdown
                      placement="left"
                      container="body"
                      [autoClose]="true"
                      (openChange)="actionsDropdownToggle($event)"
                      (click)="detectProjectSpace(element)"
                    >
                      <app-svg
                        name="ellipsis"
                        width="17"
                        height="4"
                        [hoverEffect]="true"
                        class="dropdown-toggle cursor-pointer"
                        ngbDropdownToggle
                      ></app-svg>

                      <div *ngIf="!archive" ngbDropdownMenu class="dropdown-menu">
                        <a
                          href="javascript:;"
                          *ngIf="'projects::' + element._id + '::projectUpdate' | checkPermission"
                          class="dropdown-item"
                          (click)="projectService.updateClick(element._id)"
                          t-id="table-edit-project"
                        >
                          <app-svg name="pen" width="11" height="11" class="mr-2"></app-svg>
                          <span>{{ t('btn-edit') }}</span>
                        </a>
                        <a
                          href="javascript:;"
                          class="dropdown-item"
                          (click)="projectService.leaveClick(element)"
                          t-id="table-leave-project"
                        >
                          <i class="ft-log-out mr-2"></i>
                          <span>{{ t('btn-leave') }}</span>
                        </a>
                        <a
                          href="javascript:;"
                          *ngIf="
                            'projects::' + element._id + '::projectToggleArchiveStatus'
                              | checkPermission
                          "
                          class="dropdown-item"
                          (click)="handlerArchiveProjectModal(element)"
                          t-id="table-archive-project"
                        >
                          <app-svg name="archive" width="11" height="11" class="mr-2"></app-svg>
                          <span>{{ t('btn-archive') }}</span>
                        </a>
                      </div>

                      <div *ngIf="archive" ngbDropdownMenu class="dropdown-menu unarchive">
                        <a
                          href="javascript:;"
                          *ngIf="
                            'projects::' + element._id + '::projectToggleArchiveStatus'
                              | checkPermission
                          "
                          class="dropdown-item d-flex align-items-center"
                          [class.blocked-button-active]="activeUnarchiveProject"
                          (click)="!activeUnarchiveProject && projectService.unArchive(element)"
                          t-id="table-unarchive-project"
                        >
                          <app-svg
                            name="unarchive"
                            width="11"
                            height="14"
                            class="mr-2"
                            [class.blocked-button-table]="activeUnarchiveProject"
                          ></app-svg>
                          <span [class.blocked-button-table]="activeUnarchiveProject">
                            {{ t('btn-unarchive') }}
                          </span>
                          <app-svg
                            *ngIf="activeUnarchiveProject"
                            name="info-circle"
                            width="16"
                            height="16"
                            class="ml-1"
                            triggers="hover"
                            [ngbTooltip]="t('unarchive-space-tooltip')"
                          ></app-svg>
                        </a>
                        <a
                          href="javascript:;"
                          *ngIf="'projects::' + element._id + '::projectDelete' | checkPermission"
                          class="dropdown-item"
                          (click)="handlerDeleteProjectModal(element)"
                          t-id="table-delete-project"
                        >
                          <app-svg name="trash" width="11" height="11" class="mr-2"></app-svg>
                          <span class="error">{{ t('btn-delete') }}</span>
                        </a>
                        <a
                          href="javascript:;"
                          class="dropdown-item"
                          *ngIf="
                            !(
                              'projects::' + element._id + '::projectToggleArchiveStatus'
                              | checkPermission
                            )
                          "
                        >
                          <span>{{ t('no-actions-available') }}</span>
                        </a>
                      </div>
                    </button>
                  </div>
                </td>
              </ng-container>
              <th
                mat-header-cell
                [mat-sort-header]="column"
                *matHeaderCellDef
                class="table-spaceProject"
                [class.table_team]="column === 'teammates'"
              >
                <img src="assets/img/svg/sort.svg" alt="sort" />
                {{
                  column === 'teammates'
                    ? teammatesDisplay
                    : column === 'owner'
                      ? ownerDisplay
                      : column
                }}
              </th>
              <ng-container *ngIf="column === 'owner'">
                <th mat-header-cell [mat-sort-header]="column" *matHeaderCellDef>
                  {{ column }}
                </th>
                <td mat-cell *matCellDef="let element">
                  <app-avatar
                    class="w-[24px] h-[24px] max-w-[24px] max-h-[24px]"
                    [userId]="element.ownerId"
                    [showUserName]="true"
                  ></app-avatar></td
              ></ng-container>

              <th mat-header-cell [mat-sort-header]="column" *matHeaderCellDef>
                {{ column }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsForExpandToDisplay"></tr>
            <tr
              mat-row
              *matRowDef="let element; columns: columnsForExpandToDisplay"
              t-id="table-list-item-project"
            ></tr>
          </table>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="currentColumnsToDisplay; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: currentColumnsToDisplay"
      class="example-element-row"
      [class.notAccessSpace]="!element.isAccess && !isProject"
      [class.example-expanded-row]="sortedData.data.includes(element) && element.projects.length"
      t-id="table-list-item"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
</ng-scrollbar>
