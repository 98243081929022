<div class="modal-body pin" [class.pin-dark]="isDarkTheme" *transloco="let t">
  <form [formGroup]="pinForm" (ngSubmit)="submitForm()" class="pinForm">
    <div class="pin__wrapper">
      <h3 class="pin__title">{{ modalTitle }}</h3>
      <div class="pin__name">
        <label for="question">{{ t('common.form-name') }}</label>
        <input
          t-id="question-name"
          id="question"
          [placeholder]="t('common.type-something-placeholder')"
          formControlName="name"
          type="text"
          class="form-control"
        />
        <div
          *ngIf="
            isInvalid && pinForm.controls.name.invalid && pinForm.controls.name.errors?.required
          "
          t-id="login-form-invalid-tenant-alert"
          class="help-block mt-1 text-danger"
        >
          <i class="ft-alert-circle align-middle"></i>
          {{ t('common.form-required') }}
        </div>
      </div>
      <div class="pin__url">
        <label for="question">{{ t('modals.pin.form-url') }}</label>
        <div class="pin__url-input">
          <!--Emoji picker-->
          <div class="pin__url-select" (click)="emojiPickerToggle()">
            <ng-container *ngIf="!icon; else currentIcon">
              <svg-icon
                class="pin__url-icon"
                src="assets/icons/common/clip.svg"
                [applyClass]="true"
              ></svg-icon>
              <svg-icon
                class="pin__url-arrow"
                src="assets/icons/common/arrow-down.svg"
                [applyClass]="true"
              ></svg-icon>
            </ng-container>
            <ng-template #currentIcon>
              <span class="pin__url-text">{{ icon }}</span>
              <svg-icon
                (click)="clearEmoji($event)"
                class="pin__url-cross"
                src="assets/icons/common/cross.svg"
                [applyClass]="true"
              ></svg-icon>
            </ng-template>
          </div>
          <emoji-mart
            *ngIf="isOpenEmojiPicker"
            class="webhook-emoji-picker"
            [darkMode]="isDarkTheme"
            [autoFocus]="true"
            [exclude]="['flags']"
            [isNative]="true"
            [backgroundImageFn]="emojiPickerImageFn"
            (emojiSelect)="addEmoji($event)"
          ></emoji-mart>

          <!--Input text-->
          <input
            t-id="question-name"
            id="url"
            [placeholder]="t('common.type-something-placeholder')"
            formControlName="url"
            mask="A*"
            [patterns]="customPatterns"
            [dropSpecialCharacters]="false"
            prefix="https://"
            type="text"
            class="form-control"
          />
        </div>
        <div
          *ngIf="isInvalid && pinForm.controls.url.invalid && pinForm.controls.url.errors"
          t-id="login-form-invalid-tenant-alert"
          class="help-block mt-1 text-danger"
        >
          <ng-container *ngIf="pinForm.controls.url.errors.required; else invalidUrl">
            <i class="ft-alert-circle align-middle"></i>
            {{ t('common.form-required') }}
          </ng-container>
          <ng-template #invalidUrl>
            <i class="ft-alert-circle align-middle"></i>
            {{ t('modals.pin.form-invalid-url') }}
          </ng-template>
        </div>
      </div>
      <div class="pin__frame">
        <input id="frame-pin" type="checkbox" formControlName="isFrame" />
        <span>{{ t('modals.pin.form-open-in-iframe') }}</span>
      </div>
      <div class="pin__prompt">
        {{ t('modals.pin.form-open-in-iframe-desc') }}
      </div>
    </div>
    <div class="pin__buttons">
      <button class="btn btn-subtle" type="button" (click)="closeModal()">
        {{ t('common.btn-close') }}
      </button>
      <button [disabled]="isLoading || isValidButton" class="btn btn-solid btn-save" type="submit">
        <i
          *ngIf="isLoading"
          class="fa fa-spinner fa-spin connection_spinner"
          aria-hidden="true"
        ></i>
        {{ isLoading ? '' : submitTitle }}
      </button>
    </div>
  </form>
</div>
