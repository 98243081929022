import { Component, Input, OnInit } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';

@Component({
  selector: 'app-empty-state-space',
  templateUrl: './empty-state-space.component.html',
  styleUrls: ['./empty-state-space.component.scss'],
  standalone: true,
  imports: [TranslocoDirective],
})
export class EmptyStateSpaceComponent implements OnInit {
  @Input() isMobile = false;
  constructor() {}

  ngOnInit(): void {}
}
