import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  CDK_DRAG_CONFIG,
  CdkDragDrop,
  moveItemInArray,
  CdkDropList,
  CdkDrag,
} from '@angular/cdk/drag-drop';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import {
  TicketsDeleteChecklist,
  TicketsDeleteChecklistItem,
  TicketUpdateChecklist,
} from '../../store/actions/board.action';
import { takeUntil } from 'rxjs/operators';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { BoardsState } from '../../store/states/boards.state';
import { CheckListItemsDbDto } from '../../../api/models/check-list-items-db-dto';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji/data/data.interfaces';
import { Emoji } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from '../../services/config.service';
import { TicketData } from '../../../modals/board-ticket/board-ticket';
import { ClickOutsideModule } from 'ng-click-outside';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { ChecklistItemComponent } from './checklist-item/checklist-item.component';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { SvgComponent } from '../../svgs/svg/svg.component';
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { NgIf, NgFor } from '@angular/common';

const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000,
};

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss'],
  providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgIf,
    NgbDropdown,
    SvgComponent,
    NgbDropdownToggle,
    NgbDropdownMenu,
    ProgressBarComponent,
    CdkDropList,
    NgFor,
    CdkDrag,
    ChecklistItemComponent,
    FormsModule,
    ReactiveFormsModule,
    PickerComponent,
    ClickOutsideModule,
  ],
})
export class ChecklistComponent implements OnInit, OnDestroy {
  @Input() ticket: TicketData;
  @Input() platform: string;
  destroy$: Subject<void> = new Subject<void>();
  public config: any = {};

  checklistItem: Partial<CheckListItemsDbDto>[] = [];
  emojiPickerImage = 'assets/img/emojis/emojis.png';

  isCreateChecklist = false;
  isCreateChecklistItem = false;
  dragStyle = false;
  isUploading = false;
  isOpenEmojiPicker = false;

  newItem = new FormControl('', [Validators.required, Validators.min(1)]);

  constructor(
    private store: Store,
    private toastr: ToastrService,
    protected configService: ConfigService,
    private actions$: Actions,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.config = this.configService.templateConf;
    if (this.ticket?.draft) {
      const checklistFromDraft = this.ticket.draft.ticket?.checkListItems || [];
      this.store.dispatch(new TicketUpdateChecklist(checklistFromDraft));
    }

    this.actions$
      .pipe(takeUntil(this.destroy$), ofActionSuccessful(TicketsDeleteChecklistItem))
      .subscribe((checkListItem) => {
        if (checkListItem.payload.isConvert) {
          this.toastr.success(
            this.translocoService.translate('toastr.checklist-item-converted'),
            this.translocoService.translate('toastr.item-converted'),
          );
        } else {
          this.toastr.success(
            this.translocoService.translate('toastr.checklist-item-deleted'),
            this.translocoService.translate('toastr.item-deleted'),
          );
        }
      });

    this.store
      .select(BoardsState.getChecklist)
      .pipe(takeUntil(this.destroy$))
      .subscribe((list) => {
        this.checklistItem = list;
        this.isCreateChecklist = !!this.checklistItem.length;
      });
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public createChecklist() {
    this.isCreateChecklist = true;
    this.createChecklistItem();
  }

  public createChecklistItem() {
    this.isCreateChecklistItem = true;
  }
  public addItem(event: any) {
    event.preventDefault();
    this.isOpenEmojiPicker = false;
    if (this.newItem.valid && this.newItem.value.trim()) {
      this.isUploading = true;

      const newChecklist = [
        ...this.checklistItem,
        { text: this.newItem.value, isCompleted: false },
      ];
      this.store
        .dispatch(new TicketUpdateChecklist(newChecklist))
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.newItem.setValue('');
          this.isUploading = false;
        });
    }
  }

  clearItem(event: Event) {
    event.stopPropagation();
    this.newItem.setValue('');
    this.isCreateChecklistItem = false;
  }

  public cancelCreate() {
    this.isCreateChecklistItem = false;
    this.isOpenEmojiPicker = false;

    this.newItem.setValue('');
  }
  public deleteChecklist() {
    this.store
      .dispatch(new TicketsDeleteChecklist({ id: this.ticket.id }))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.toastr.success(
          this.translocoService.translate('toastr.checklist-deleted'),
          this.translocoService.translate('toastr.title-success'),
        );
      });
  }
  emojiPickerToggle() {
    setTimeout(() => (this.isOpenEmojiPicker = !this.isOpenEmojiPicker), 1);
  }

  emojiPickerImageFn: Emoji['backgroundImageFn'] = (_set: string, _sheetSize: number) =>
    this.emojiPickerImage;

  public get progressBar(): number {
    if (!this.checklistItem.length) {
      return 0;
    }
    const oneItemProgress = 100 / this.checklistItem.length;
    return Math.floor(
      this.checklistItem.reduce((acc, item) => (item.isCompleted ? acc + oneItemProgress : acc), 0),
    );
  }
  public dropItemChecklist(event: CdkDragDrop<CheckListItemsDbDto[]>) {
    const arrayForSort = [...this.checklistItem];
    moveItemInArray(arrayForSort, event.previousIndex, event.currentIndex);
    this.checklistItem = [...arrayForSort];

    this.store
      .dispatch(new TicketUpdateChecklist(this.checklistItem))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {});
  }

  public addEmoji(emojiData: { emoji: EmojiData }): void {
    const selectedEmoji = emojiData?.emoji?.native;
    this.newItem.setValue(this.newItem.value + selectedEmoji);
    this.isOpenEmojiPicker = !this.isOpenEmojiPicker;
  }
  public closeEmojiPicker() {
    this.isOpenEmojiPicker = false;
  }
}
