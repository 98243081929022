import { Store, State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { DocumentsPermissionsStateModel } from '../models/DocumentsPermissionsState';
import { FilesService } from '../../../api/services/files.service';
import {
  DocPermissionsGet,
  FilePermissionsUpdate,
  FolderPermissionsUpdate,
  DocPermissionsClearState,
  LinkPermissionsUpdate,
} from '../actions/documents-permissions.action';

@State<DocumentsPermissionsStateModel>({
  name: 'DocumentsPermissions',
  defaults: {
    permissions: {
      permittedRoles: [],
      permittedUsers: [],
    },
  },
})
@Injectable()
export class DocumentsPermissionsState {
  /**
   * get document permissions
   * @param  {DocumentsPermissionsStateModel} state
   */
  @Selector()
  static getDocPermissions(state: DocumentsPermissionsStateModel) {
    return state.permissions;
  }

  constructor(
    private filesService: FilesService,
    private store: Store,
  ) {}

  /**
   * Get events action handler
   * @param  {patchState}: StateContext<DocumentsPermissionsStateModel>
   * @param  {DocPermissionsGet} action
   */
  @Action(DocPermissionsGet)
  documents_permissions_get(
    { patchState }: StateContext<DocumentsPermissionsStateModel>,
    action: DocPermissionsGet,
  ) {
    return this.filesService.documentPermissionsGet(action.payload).pipe(
      tap(
        (result) => {
          patchState({
            permissions: {
              permittedUsers: result.userIds,
              permittedRoles: result.roles,
            },
          });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update file events action handler
   * @param  {patchState}: StateContext<DocumentsPermissionsStateModel>
   * @param  {FilePermissionsUpdate} action
   */
  @Action(FilePermissionsUpdate)
  file_permissions_update(
    { patchState }: StateContext<DocumentsPermissionsStateModel>,
    action: FilePermissionsUpdate,
  ) {
    return this.filesService.filesUpdate(action.payload).pipe(
      tap(
        (result) => {
          patchState({});
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update folder events action handler
   * @param  {patchState}: StateContext<DocumentsPermissionsStateModel>
   * @param  {FolderPermissionsUpdate} action
   */
  @Action(FolderPermissionsUpdate)
  folder_permissions_update(
    { patchState }: StateContext<DocumentsPermissionsStateModel>,
    action: FolderPermissionsUpdate,
  ) {
    return this.filesService.filesUpdateFolder(action.payload).pipe(
      tap(
        (result) => {
          patchState({});
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update link permissions
   * @param  {patchState}: StateContext<DocumentsPermissionsStateModel>
   * @param  {LinkPermissionsUpdate} action
   */
  @Action(LinkPermissionsUpdate)
  link_permissions_update(
    { patchState }: StateContext<DocumentsPermissionsStateModel>,
    action: LinkPermissionsUpdate,
  ) {
    return this.filesService.filesUpdateLink(action.payload).pipe(
      tap(
        (result) => {
          patchState({});
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Clear permissions state
   * @param  {patchState}: StateContext<DocumentsPermissionsStateModel>
   */
  @Action(DocPermissionsClearState)
  doc_permissions_clear_state({ patchState }: StateContext<DocumentsPermissionsStateModel>) {
    patchState({
      permissions: {
        permittedRoles: [],
        permittedUsers: [],
      },
    });
  }
}
