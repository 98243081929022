export enum CurrentTab {
  Sprint = 'activeSprint',
  Board = 'board',
  Backlog = 'backlog',
  Archive = 'archive',
}

export enum BoardNameEnum {
  Backlog = 'Backlog',
  Board = 'Board',
  Archive = 'ARCHIVE',
}

export enum SelectorEnum {
  OrderInBacklog = 'orderInBacklog',
  Order = 'order',
}
