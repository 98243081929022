import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';

// Types
import { ColumnType } from '../../types/column.type';
import { CardType } from '../../types/card.type';
import { ColumnActionEnum } from '../../enums/column-action.enum';
import { DragAndDropActionEnum } from '../../enums/drag-and-drop-action.enum';

// Services
import { ColumnService } from './column.service';
import { BoardLaneService } from '../../board-lane.service';

// Utils
import DragAndDropUtils from '../../../../../shared/utils/drag-and-drop-utils';
import { CardComponent } from '../card/card.component';
import {
  NgbDropdown,
  NgbDropdownToggle,
  NgbTooltip,
  NgbDropdownMenu,
} from '@ng-bootstrap/ng-bootstrap';
import { SvgComponent } from '../../../../svgs/svg/svg.component';
import { NgIf, NgFor } from '@angular/common';
import { CdkDrag, CdkDragHandle, CdkDropList } from '@angular/cdk/drag-drop';
import { TranslocoDirective } from '@ngneat/transloco';
import { MixpanelService } from '../../../../../plugins/mixpanel/mixpanel.service';

@Component({
  selector: 'app-column',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    CdkDrag,
    CdkDragHandle,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    SvgComponent,
    NgbDropdown,
    NgbDropdownToggle,
    NgbTooltip,
    NgbDropdownMenu,
    CdkDropList,
    NgFor,
    CardComponent,
  ],
})
export class ColumnComponent implements OnInit {
  @Input() column: ColumnType;
  @Input() cards: CardType[];
  @Input() currentUser;
  @Input() isShowContent = true;
  @Input() isShowCreator = true;
  @Input() isShowVotes = true;
  @Input() themeMode: string;

  /* Variables */
  public title: FormControl = new FormControl('', Validators.required);
  public color = '#fff';
  public readonly columnActionType = ColumnActionEnum;

  constructor(
    private boardLaneService: BoardLaneService,
    private columnService: ColumnService,
  ) {}

  /* Hooks */
  ngOnInit(): void {
    this.title.patchValue(this.column?.title);

    if (this.column.color) {
      this.color = this.column.color;
    }
  }

  /* Getters */
  get columnId(): string {
    return this.column?.id ?? this.column?._id;
  }

  public get isDisableEditAction(): boolean {
    return !this.title.value.length;
  }

  /* Methods */
  public handleClickMenuItem(columnActionType: ColumnActionEnum): void {
    MixpanelService.trackEvent('Lane Board: column action', {
      columnActionType,
    });
    if (columnActionType === ColumnActionEnum.WasEdit && this.title.valid) {
      this.title.patchValue(this.title.value.trim());
    }

    if (!(columnActionType === ColumnActionEnum.WasEdit)) {
      this.title.patchValue(this.column.title);
    }

    const prepareColumn = {
      ...this.column,
      ...{ title: this.title.value.trim(), color: this.color },
      cards: this.cards,
    };

    this.columnService.broadcastClickOnActionItem({
      type: columnActionType,
      payload: prepareColumn,
    });
  }

  public handleColorChange(event): void {
    MixpanelService.trackEvent('Lane Board: column color changed', {
      color: event.target.value,
    });
    this.color = event.target.value;
    this.handleClickMenuItem(ColumnActionEnum.ChangeColor);
  }

  public handleEnter(): void {
    MixpanelService.trackEvent('Lane Board: enter pressed');
    this.handleClickMenuItem(ColumnActionEnum.WasEdit);
  }

  public sorted(event): void {
    event.item.data.order = event.currentIndex;
  }

  public drop(event): void {
    let cards = null;

    if (event.previousContainer === event.container) {
      cards = DragAndDropUtils.moveItemInArray(
        [...this.cards],
        event.previousIndex,
        event.currentIndex,
      );
    }

    const payload = {
      card: event.item.data,
      columnId: this.columnId,
      order: event.item.data.order,
      type: DragAndDropActionEnum.CardDrop,
      cards,
    };
    this.boardLaneService.broadcastDragAndDrop(payload);
  }
}
