<button
  (click)="openModal()"
  #templateOrigin
  class="rounded-lg flex items-center justify-center mx-2 w-9 h-9 min-h-9 max-h-9 min-w-9 max-w-9 relative hover:bg-secondary-100 dark:hover:bg-secondary-800 transition-all"
>
  <span class="absolute top-2 right-2 flex h-2 w-2" *ngIf="(unreadNotificationsCount$ | async) > 0">
    <span
      class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"
    ></span>
    <span class="relative inline-flex rounded-full h-2 w-2 bg-red-500"></span>
  </span>
  <svg
    width="15"
    height="17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-secondary-600 dark:fill-white hover:fill-primary-500"
  >
    <path
      d="M8.5 1.5v.563c2.25.374 4 2.343 4 4.687v1.063c0 1.406.469 2.78 1.344 3.906l.469.562c.187.25.218.563.093.813a.729.729 0 0 1-.656.406H1.25a.742.742 0 0 1-.688-.406.776.776 0 0 1 .094-.813l.469-.562C2 10.594 2.5 9.219 2.5 7.813V6.75c0-2.344 1.719-4.313 4-4.688V1.5c0-.531.438-1 1-1 .531 0 1 .469 1 1Zm-1.25 2A3.247 3.247 0 0 0 4 6.75v1.063c0 1.5-.438 2.937-1.25 4.187h9.469C11.406 10.75 11 9.312 11 7.812V6.75A3.268 3.268 0 0 0 7.75 3.5h-.5Zm2.25 11c0 .531-.219 1.063-.594 1.438A2.012 2.012 0 0 1 7.5 16.5c-.531 0-1.063-.188-1.438-.563-.375-.374-.562-.906-.562-1.437h4Z"
    />
  </svg>
</button>
<ng-template #templatePortalContent>
  <div
    class="w-[420px] min-h-[100px] max-h-[572px] h-auto rounded-lg border border-color-[#DBDDEB] py-3.5 px-1 mt-2 bg-[#fff] dark:bg-secondary-900 shadow-xl z-50"
    [ngClass]="{
      'sidebar-mode': this.isSidebarOpen && isPlatformDesktop,
      'sidebar-mode-mobile': this.isSidebarOpen && isPlatformMobile
    }"
  >
    <div class="h-7 flex justify-between items-center pt-2 pb-6 border-bottom border-slate-50 px-2">
      <div class="font-semibold text-lg text-[#000] dark:text-secondary-100">
        {{ 'notifications.header-title' | transloco }}
      </div>
      <div class="flex transition-all">
        <div
          (click)="markAsRead()"
          *ngIf="(unreadNotificationsCount$ | async) > 0"
          class="font-semibold px-1 cursor-pointer text-primary-500 hover:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 transition-all"
        >
          {{ 'notifications.links.mark-all-as-read' | transloco }}
        </div>
        <div
          class="font-semibold px-1 cursor-pointer text-primary-500 hover:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 transition-all"
          (click)="isSidebarOpen ? closeSidebar() : openSidebar()"
        >
          @if (isSidebarOpen) {
            {{ 'notifications.links.close' | transloco }}
          } @else {
            {{ 'notifications.links.see-all' | transloco }}
          }
        </div>
      </div>
    </div>

    <section>
      <ng-scrollbar
        [style.min-height]="
          isSidebarOpen
            ? 'auto'
            : (notifications$ | async).length >= 3
              ? '526px'
              : (notifications$ | async).length === 0
                ? '100px'
                : (notifications$ | async).length * 98 + 'px'
        "
        [class.is-sidebar]="isSidebarOpen && isPlatformDesktop"
        [class.is-sidebar-mobile]="isSidebarOpen && isPlatformMobile"
        class="h-auto sidebar-mode:h-screen"
        autoHeightDisabled="true"
      >
        @for (notification of notifications$ | async; track notification._id) {
          <section
            class="w-full min-h py-3.5 flex items-center text-black border-b-2 border-secondary-100 dark:border-opacity-5 pb-2 hover:bg-gray-50 dark:hover:bg-[#1c1a21] transition-all"
          >
            <div class="h-3 w-4 mx-2 cursor-pointer" (click)="markAsRead(notification._id)">
              <span
                [hidden]="!notification.unread"
                class="relative inline-flex rounded-full h-2 w-2 bg-primary-500"
              ></span>
            </div>
            <div
              class="w-full flex items-center"
              (click)="notification.redirect ? linkOpen(notification.redirect, notification) : null"
              [ngClass]="{
                'cursor-pointer': notification.redirect
              }"
            >
              <app-avatar
                [userId]="notification.meta?.author.id"
                [width]="36"
                [height]="36"
              ></app-avatar>
              <div class="w-full px-2 dark:text-secondary-100">
                <div class="flex justify-between">
                  <div *ngIf="notification.meta.spaceName && notification.meta.projectName">
                    {{ notification.meta.spaceName }} •
                    {{ notification.meta.projectName }}
                  </div>
                  <div *ngIf="notification.meta.spaceName && !notification.meta.projectName">
                    {{ notification.meta.spaceName }}
                  </div>
                  <div *ngIf="!notification.meta.spaceName && !notification.meta.projectName">
                    dashboard
                  </div>
                  <div class="max-w-[100px] text-right text-gray-500 dark:text-gray-400">
                    {{ notification.created_at | fromNow }}
                    {{ 'notifications.time-ago' | transloco }}
                  </div>
                </div>
                <p
                  class="capitalize"
                  [innerHTML]="
                    'notifications.message.' + notification.module
                      | transloco: prepareTranslationKeys(notification.meta)
                  "
                ></p>
                <ul *ngIf="notification.meta?.links" class="notification-links">
                  <li
                    *ngFor="let link of notification.meta?.links"
                    [ngClass]="{ 'cursor-pointer': link.url }"
                  >
                    <div *ngIf="link.url">
                      <div class="bull" (click)="linkOpen(link, notification)">
                        &bull;
                        <span title="{{ link.name }}" class="link-name">{{ link.name }}</span>
                      </div>
                    </div>
                    <div *ngIf="!link.url">
                      <div class="bull">
                        &bull;
                        <span title="{{ link.name }}" class="link-name">{{ link.name }}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        } @empty {
          <div class="flex justify-center items-center flex-col pt-3 min-h-[100px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              class="w-12 h-12 stroke-slate-200 dark:stroke-slate-50"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.143 17.082a24.248 24.248 0 0 0 3.844.148m-3.844-.148a23.856 23.856 0 0 1-5.455-1.31 8.964 8.964 0 0 0 2.3-5.542m3.155 6.852a3 3 0 0 0 5.667 1.97m1.965-2.277L21 21m-4.225-4.225a23.81 23.81 0 0 0 3.536-1.003A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6.53 6.53m10.245 10.245L6.53 6.53M3 3l3.53 3.53"
              />
            </svg>
            <div class="text-slate-500">
              {{ 'notifications.message.empty-list' | transloco }}
            </div>
          </div>
        }
      </ng-scrollbar>
    </section>
  </div>
</ng-template>
