import {
  EventEmitter,
  Component,
  Input,
  Output,
  Type,
  ChangeDetectorRef,
  ViewContainerRef,
  ComponentFactoryResolver,
  ViewChild,
  OnDestroy,
  OnInit,
  OnChanges,
} from '@angular/core';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

import {
  TextMessageComponent,
  ImageMessageComponent,
  AudioMessageComponent,
  VideoMessageComponent,
  DocMessageComponent,
} from '../../chat/chat-message-content/index';
import { FilesHelper } from '../../../utils/files-helper';
import { ChatsSocketUpdatedMessage } from '../../../store/actions/chats.action';
import { ThreadsSocketUpdatedMessage } from '../../../store/actions/threads.action';
import { WebhookMessageComponent } from './webhook-message/webhook-message.component';

export enum ChatMessageType {
  TextMessage = 'textMessage',
  ImageMessage = 'imageMessage',
  AudioMessage = 'audioMessage',
  PlayableVideoInWebMessage = 'playableVideoInWebMessage',
  VideoOrDocMessage = 'videoOrDocMessage',
  WebhookMessage = 'webhookMessage',
}

export interface MessageContentTypeComponent {
  messageType?: ChatMessageType;
  component?: Type<
    | TextMessageComponent
    | ImageMessageComponent
    | AudioMessageComponent
    | VideoMessageComponent
    | DocMessageComponent
  >;
  data?: any;
}

@Component({
  selector: 'app-chat-message-content',
  templateUrl: './chat-message-content.component.html',
  styleUrls: ['./chat-message-content.component.scss'],
  standalone: true,
})
export class ChatMessageContentComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('', { static: false }) chatMessageContentContainer: any;

  @Input() platform = 'web';
  @Input() object: string;
  @Input() objectId: string;
  @Input() message: any;
  @Input() mentionChatMembers: string[];
  @Input() context: string = null;
  @Input() isThreadCore = false;

  @Output() imageClicked = new EventEmitter<any>();

  destroy$ = new Subject<void>();
  imageClickedSub$: Subscription;

  messageType: ChatMessageType;

  messageContentTypeComponent: MessageContentTypeComponent[] = [
    {
      messageType: ChatMessageType.TextMessage,
      component: TextMessageComponent,
      data: {},
    },
    {
      messageType: ChatMessageType.ImageMessage,
      component: ImageMessageComponent,
      data: {},
    },
    {
      messageType: ChatMessageType.AudioMessage,
      component: AudioMessageComponent,
      data: {},
    },
    {
      messageType: ChatMessageType.PlayableVideoInWebMessage,
      component: VideoMessageComponent,
      data: {},
    },
    {
      messageType: ChatMessageType.VideoOrDocMessage,
      component: DocMessageComponent,
      data: {},
    },
    {
      messageType: ChatMessageType.WebhookMessage,
      component: WebhookMessageComponent,
      data: {},
    },
  ];

  constructor(
    public cdr: ChangeDetectorRef,
    public viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private actions: Actions,
    private filesHelper: FilesHelper,
  ) {
    this.actions
      .pipe(
        takeUntil(this.destroy$),
        ofActionSuccessful(ChatsSocketUpdatedMessage, ThreadsSocketUpdatedMessage),
      )
      .subscribe(({ payload }) => {
        if (
          this.message._id === payload.message._id &&
          this.message.text !== payload.message.text
        ) {
          this.message = { ...this.message, ...payload.message };
          this.cdr.detectChanges();
        }
      });
  }

  ngOnInit(): void {
    this.handleComponent();
  }

  ngOnChanges(changes): void {
    if (changes.message?.firstChange === false) {
      const current = {
        ...changes.message.currentValue,
        threadsMessagesInfo: undefined,
      };
      const prev = {
        ...changes.message.previousValue,
        threadsMessagesInfo: undefined,
      };

      if (!changes.message.previousValue.messageType && !_.isEqual(current, prev)) {
        this.handleComponent();
      }
    }
  }

  private handleComponent(): void {
    if (this.message?.messageType) {
      this.messageType = this.message.messageType;
    } else {
      this.handleMessageType(this.message);
    }

    this.renderComponent(this.prepareComponent(this.messageType));
  }

  private prepareComponent(chatMessageType: ChatMessageType): MessageContentTypeComponent {
    const _chatMessageType = chatMessageType ? chatMessageType : ChatMessageType.TextMessage;
    const component: MessageContentTypeComponent = this.getComponent(_chatMessageType);

    component.data = this.getComponentData();
    return component;
  }

  private renderComponent(component: MessageContentTypeComponent): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      component.component,
    );
    this.viewContainerRef.clear();
    const viewContainerRef = this.viewContainerRef.createComponent<
      ImageMessageComponent | AudioMessageComponent | VideoMessageComponent | DocMessageComponent
    >(componentFactory);

    viewContainerRef.instance.message = component.data.message;
    viewContainerRef.instance.mentionChatMembers = component.data.mentionChatMembers;
    viewContainerRef.instance.context = component.data.context;
    viewContainerRef.instance.object = component.data.object;
    viewContainerRef.instance.objectId = component.data.objectId;
    viewContainerRef.instance.platform = component.data.platform;

    if (component.messageType === ChatMessageType.ImageMessage) {
      this.setupClickEvents(viewContainerRef.instance);
    }
  }

  private getComponentData(): any {
    return {
      message: this.message,
      mentionChatMembers: this.mentionChatMembers,
      context: this.context,
      platform: this.platform,
      object: this.object,
      objectId: this.objectId,
    };
  }

  private getComponent(messageComponentType: ChatMessageType): MessageContentTypeComponent {
    return this.messageContentTypeComponent.find(
      (component) => component.messageType === messageComponentType,
    );
  }

  private setupClickEvents(
    instance:
      | ImageMessageComponent
      | AudioMessageComponent
      | VideoMessageComponent
      | DocMessageComponent,
  ) {
    this.imageClickedSub$?.unsubscribe();
    this.imageClickedSub$ = (<ImageMessageComponent>instance).imageClicked
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.imageClicked.emit(data);
      });
  }

  private handleMessageType(message: any): void {
    let messageType: ChatMessageType = ChatMessageType.TextMessage;

    if (message?.fileData) {
      if (this.filesHelper.checkFileIsImage(message.fileData.originalFileName)) {
        messageType = ChatMessageType.ImageMessage;
      } else if (this.filesHelper.checkFileIsAudio(message.fileData.originalFileName)) {
        messageType = ChatMessageType.AudioMessage;
      } else if (
        this.filesHelper.checkFileIsPlayableVideoInWeb(message.fileData.originalFileName)
      ) {
        messageType = ChatMessageType.PlayableVideoInWebMessage;
      } else if (this.filesHelper.checkFileIsVideoOrDoc(message.fileData.originalFileName)) {
        messageType = ChatMessageType.VideoOrDocMessage;
      }
    } else if (message?.webhookId) {
      messageType = ChatMessageType.WebhookMessage;
    }

    this.messageType = messageType;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.viewContainerRef.clear();
    this.viewContainerRef.detach();
  }
}
