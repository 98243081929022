<div
  class="chat-gpt-settings-modal"
  [ngClass]="{ mobile: platform === 'mobile', darkTheme: isDarkMode }"
  *transloco="let t; read: 'modals.chat-gpt-settings'"
>
  <div class="chat-gpt-settings-modal_close" (click)="close()">
    <svg-icon src="assets/icons/common/close.svg"></svg-icon>
  </div>
  <div class="chat-gpt-settings-modal__wrapper">
    <span class="chat-gpt-settings-modal_title">{{ settingsTitle }}</span>
    <ng-container *ngIf="isConnected; else connectChatGpt">
      <span class="chat-gpt-settings-modal_sub-title">{{ t('api-key-successfully-set') }}</span>
      <div class="form-group chat-gpt-settings-modal__form connected">
        <input
          t-id="chat-gpt-api-key"
          class="form-control form-control_creation input-md connected"
          type="text"
          placeholder="{{ t('placeholder-api-key') }}"
          [formControl]="apiKey"
        />
        <svg-icon
          class="chat-gpt-connected-icon"
          src="assets/icons/common/check-circle.svg"
        ></svg-icon>
      </div>
      <button
        class="btn btn-outline btn-solid submit chat-gpt-settings-modal__save"
        type="submit"
        (click)="removeChatGptApiKey()"
        t-id="chat-gpt-save-api-key"
      >
        <i
          *ngIf="isUploading"
          class="fa fa-spinner fa-spin connection_spinner"
          aria-hidden="true"
        ></i>
        <span>{{ isUploading ? '' : t('btn-remove-api-key') }}</span>
      </button>
      <span class="chat-gpt-settings-modal_description">
        {{ t('main-description') }}
      </span>
    </ng-container>
    <ng-template #connectChatGpt>
      <ol class="chat-gpt-settings-modal__list">
        <li class="chat-gpt-settings-modal__list-item" [innerHTML]="t('link-account-login')"></li>
        <li
          class="chat-gpt-settings-modal__list-item"
          [innerHTML]="t('link-account-api-keys')"
        ></li>
      </ol>
      <div class="form-group chat-gpt-settings-modal__form">
        <input
          t-id="chat-gpt-api-key"
          class="form-control form-control_creation input-md"
          type="text"
          placeholder="{{ t('placeholder-api-key') }}"
          [formControl]="apiKey"
          required
        />
        <small class="form-text danger" *ngIf="!apiKey.valid && (apiKey.dirty || apiKey.touched)">{{
          t('field-is-required')
        }}</small>
      </div>
      <button
        class="btn btn-solid submit chat-gpt-settings-modal__save"
        type="submit"
        [disabled]="!apiKey.valid"
        (click)="saveChatGptApiKey()"
        t-id="chat-gpt-save-api-key"
      >
        <i
          *ngIf="isUploading"
          class="fa fa-spinner fa-spin connection_spinner"
          aria-hidden="true"
        ></i>
        <span>{{ isUploading ? '' : t('btn-save') }}</span>
      </button>
    </ng-template>

    <span
      class="chat-gpt-settings-modal__policy"
      [innerHTML]="t('link-privacy-policy-information')"
    ></span>
  </div>
</div>
