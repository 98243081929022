import { Injectable } from '@angular/core';

import { BoardSettingsResDto } from '../../../api/models/board-settings-res-dto';
import { TicketsDbDto } from '../../../api/models/tickets-db-dto';

@Injectable({
  providedIn: 'root',
})
export class CustomizeService {
  /* Methods */
  public colorTicket(ticket: TicketsDbDto, settings?: BoardSettingsResDto) {
    return ticket?.bgColor !== 'null'
      ? ticket.bgColor
      : settings?.bgColor
        ? settings.bgColor
        : null;
  }
}
