export const DEFAULT_USER_STATES = [
  {
    emoji: '🟢',
    label: 'online',
    hex: '#40c057',
    name: 'Online',
  },
  {
    emoji: '🏠',
    label: 'working-remotely',
    hex: '#40c057',
    name: 'Working remotely',
  },
  {
    emoji: '🔴',
    label: 'busy',
    hex: '#f55252',
    name: 'Busy',
  },
  {
    emoji: '📅',
    label: 'in-a-meeting',
    hex: '#f55252',
    name: 'In a meeting',
  },
  {
    emoji: '🏖',
    label: 'on-vacation',
    hex: '#616d89',
    name: 'On vacation',
  },
  {
    emoji: '🤒',
    label: 'out-sick',
    hex: '#616d89',
    name: 'Out sick',
  },
  {
    emoji: '⚪️',
    label: 'offline',
    hex: '#616d89',
    name: 'Offline',
  },
];

export const DEFAULT_USER_AVAILBLILITY = [
  {
    label: 'online',
    name: 'Online',
    hex: '#40c057',
  },
  {
    label: 'offline',
    name: 'Offline',
    hex: '#616d89',
  },
  {
    label: 'do-not-disturb',
    name: 'Do not disturb',
    hex: '#f55252',
  },
];
