export const replaceLastSrcWithUrl = (input: string, url: string): string => {
  const targetSrc = '<img src="//:0">';
  const replacementSrc = `<img src="${url}">`;

  if (input === undefined || input === null) {
    return replacementSrc;
  }
  const lastIndex = input.lastIndexOf(targetSrc);

  if (lastIndex !== -1) {
    const beforeLastSrc = input.substring(0, lastIndex);
    const afterLastSrc = input.substring(lastIndex + targetSrc.length);
    return beforeLastSrc + replacementSrc + afterLastSrc;
  } else {
    return input;
  }
};
