import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterTenantPipe } from './router-tenant.pipe';
import { CheckPermissionPipe } from '../pipes/check-permission.pipe';
import { FromNowPipe } from '../pipes/from-now.pipe';
import { HighlightChatMembersPipe } from './highlight-chat-members.pipe';
import { TruncatePipe } from './truncate.pipe';
import { HighlightTicketsPipe } from './highlight-tickets.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { DocumentLinkPipe } from './document-link.pipe';
import { UnreadPipe } from './unread.pipe';
import { CheckIsPinPipe } from './check-isPin.pipe';
import { UserNamePipe } from './user-name.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterTenantPipe,
    CheckPermissionPipe,
    FromNowPipe,
    HighlightChatMembersPipe,
    TruncatePipe,
    HighlightTicketsPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    DocumentLinkPipe,
    UnreadPipe,
    CheckIsPinPipe,
    UserNamePipe,
  ],
  exports: [
    HighlightChatMembersPipe,
    RouterTenantPipe,
    CheckPermissionPipe,
    FromNowPipe,
    TruncatePipe,
    HighlightTicketsPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    DocumentLinkPipe,
    UnreadPipe,
    CheckIsPinPipe,
    UserNamePipe,
  ],
  providers: [RouterTenantPipe, CheckPermissionPipe],
})
export class PipeModule {}
