<div
  #subNavbar
  *transloco="let t; read: 'board'"
  class="card sub-navbar m-0 d-flex flex-row align-items-center"
  [class.sub-navbar-dark]="config?.layout.variant === 'Dark'"
>
  <div
    *ngIf="object === 'spaces' && !showDropdownMenu"
    id="spaceSubNavbar"
    class="sub-navbar-menu nav-tabs d-flex"
  >
    <a
      *ngIf="!!chatId"
      [routerLink]="'/chat/' + chatId | routerTenant"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="active"
      t-id="sub-navbar-chat"
      class="nav-link d-flex align-items-center"
    >
      <app-svg name="chat" width="16" height="14"></app-svg>
      <span>{{ textForChat }}</span>
    </a>
    <a
      *ngIf="'spaces::' + objectId + '::calendarEventGetList' | checkPermission"
      [routerLink]="'/space/' + objectId + '/calendar' | routerTenant"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="active"
      t-id="sub-navbar-calendar"
      class="nav-link d-flex align-items-center"
    >
      <app-svg name="calendar" width="14" height="16"></app-svg>
      <span>{{ t('tab-navbar-calendar') }}</span>
    </a>
    <a
      *ngIf="'spaces::' + objectId + '::ticketsGetList' | checkPermission"
      [routerLink]="'/space/' + objectId + board | routerTenant"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="active"
      t-id="sub-navbar-board"
      class="nav-link d-flex align-items-center"
      (click)="redirectToActiveSprint()"
    >
      <app-svg name="board" width="16" height="16"></app-svg>
      <span>{{ t('tab-navbar-board') }}</span>
    </a>
    <a
      *ngIf="'spaces::' + objectId + '::filesGet' | checkPermission"
      [routerLink]="'/space/' + objectId + '/documents' | routerTenant"
      [routerLinkActiveOptions]="{ exact: false }"
      routerLinkActive="active"
      t-id="sub-navbar-data-room"
      class="nav-link d-flex align-items-center"
    >
      <app-svg name="folder" width="16" height="14"></app-svg>
      <span>{{ t('tab-navbar-dataroom') }}</span>
    </a>
    <a
      [routerLink]="'/space/' + objectId + '/projects' | routerTenant"
      [routerLinkActiveOptions]="{ exact: true }"
      *ngIf="checkIsPersonal"
      routerLinkActive="active"
      t-id="sub-navbar-project"
      class="nav-link d-flex align-items-center"
    >
      <app-svg name="folder-tree" width="16" height="16"></app-svg>
      <span>{{ t('tab-navbar-projects') }}</span>
    </a>
    <a
      [routerLink]="'/space/' + objectId + '/wiki' | routerTenant"
      routerLinkActive="active"
      t-id="sub-navbar-wiki"
      class="nav-link d-flex align-items-center"
    >
      <svg-icon src="assets/icons/wiki/book.svg" class="mr-1 wiki-svg"></svg-icon>
      <span>{{ t('tab-navbar-wiki') }}</span>
    </a>
  </div>

  <div
    *ngIf="object === 'spaces' && showDropdownMenu"
    ngbDropdown
    id="spaceSubMenu"
    class="d-inline-block"
  >
    <div class="sub-navbar-dropdown" id="dropdownSpaceSubNav" ngbDropdownToggle>
      <i class="ft-menu"></i> {{ menuItem }}
    </div>
    <div ngbDropdownMenu aria-labelledby="dropdownSpaceSubNav">
      <a
        *ngIf="!!chatId"
        [routerLink]="'/chat/' + chatId | routerTenant"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active"
        t-id="sub-navbar-chat"
        ngbDropdownItem
        class="d-flex align-items-center"
      >
        <app-svg name="chat" width="16" height="14" class="mr-1"></app-svg>
        <span>{{ t('tab-navbar-chat') }}</span>
      </a>
      <a
        *ngIf="'spaces::' + objectId + '::calendarEventGetList' | checkPermission"
        [routerLink]="'/space/' + objectId + '/calendar' | routerTenant"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active"
        ngbDropdownItem
        t-id="sub-navbar-calendar"
        class="d-flex align-items-center"
      >
        <app-svg name="calendar" width="14" height="16" class="mr-1"></app-svg>
        <span>{{ t('tab-navbar-calendar') }}</span>
      </a>
      <a
        *ngIf="'spaces::' + objectId + '::ticketsGetList' | checkPermission"
        [routerLink]="'/space/' + objectId + board | routerTenant"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active"
        ngbDropdownItem
        t-id="sub-navbar-board"
        class="d-flex align-items-center"
      >
        <app-svg name="board" width="16" height="16" class="mr-1"></app-svg>
        <span>{{ t('tab-navbar-board') }}</span>
      </a>
      <a
        *ngIf="'spaces::' + objectId + '::filesGet' | checkPermission"
        [routerLink]="'/space/' + objectId + '/documents' | routerTenant"
        [routerLinkActiveOptions]="{ exact: false }"
        routerLinkActive="active"
        ngbDropdownItem
        t-id="sub-navbar-data-room"
        class="d-flex align-items-center"
      >
        <app-svg name="folder" width="16" height="14" class="mr-1"></app-svg>
        <span>{{ t('tab-navbar-dataroom') }}</span>
      </a>
      <a
        [routerLink]="'/space/' + objectId + '/projects' | routerTenant"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="checkIsPersonal"
        routerLinkActive="active"
        ngbDropdownItem
        t-id="sub-navbar-project"
        class="d-flex align-items-center"
      >
        <app-svg name="folder-tree" width="16" height="16" class="mr-1"></app-svg>
        <span>{{ t('tab-navbar-projects') }}</span>
      </a>
      <a
        [routerLink]="'/space/' + objectId + '/wiki' | routerTenant"
        routerLinkActive="active"
        ngbDropdownItem
        t-id="sub-navbar-wiki"
        class="d-flex align-items-center"
      >
        <svg-icon src="assets/icons/wiki/book.svg" class="mr-1 wiki-svg"></svg-icon>
        <span>{{ t('tab-navbar-wiki') }}</span>
      </a>
    </div>
  </div>

  <div
    *ngIf="object === 'projects' && !showDropdownMenu"
    id="projectSubNavbar"
    class="sub-navbar-menu nav-tabs d-flex"
  >
    <a
      *ngIf="!!chatId"
      [routerLink]="'/chat/' + chatId | routerTenant"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="active"
      class="nav-link d-flex align-items-center"
    >
      <app-svg name="chat" width="16" height="14"></app-svg>
      <span>{{ t('tab-navbar-chat') }}</span>
    </a>
    <a
      *ngIf="'projects::' + objectId + '::calendarEventGetList' | checkPermission"
      [routerLink]="'/project/' + objectId + '/calendar' | routerTenant"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="active"
      t-id="sub-navbar-project-calendar"
      class="nav-link d-flex align-items-center"
    >
      <app-svg name="calendar" width="14" height="16"></app-svg>
      <span>{{ t('tab-navbar-calendar') }}</span>
    </a>
    <a
      *ngIf="'projects::' + objectId + '::ticketsGetList' | checkPermission"
      [routerLink]="'/project/' + objectId + board | routerTenant"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="active"
      t-id="sub-navbar-project-board"
      class="nav-link d-flex align-items-center"
      (click)="redirectToActiveSprint()"
    >
      <app-svg name="board" width="16" height="16"></app-svg>
      <span>{{ t('tab-navbar-board') }}</span>
    </a>
    <a
      *ngIf="'projects::' + objectId + '::filesGet' | checkPermission"
      [routerLink]="'/project/' + objectId + '/documents' | routerTenant"
      [routerLinkActiveOptions]="{ exact: false }"
      routerLinkActive="active"
      t-id="sub-navbar-project-data-room"
      class="nav-link d-flex align-items-center"
    >
      <app-svg name="folder" width="16" height="14"></app-svg>
      <span>{{ t('tab-navbar-dataroom') }}</span>
    </a>
    <a
      [routerLink]="'/project/' + objectId + '/wiki' | routerTenant"
      routerLinkActive="active"
      t-id="sub-navbar-project-wiki"
      class="nav-link d-flex align-items-center"
    >
      <svg-icon src="assets/icons/wiki/book.svg" class="mr-1 wiki-svg"></svg-icon>
      <span>{{ t('tab-navbar-wiki') }}</span>
    </a>
  </div>

  <div
    *ngIf="object === 'projects' && showDropdownMenu"
    ngbDropdown
    id="projectSubMenu"
    class="d-inline-block"
  >
    <div class="sub-navbar-dropdown" id="dropdownProjectSubNav" ngbDropdownToggle>
      <i class="ft-menu"></i> {{ menuItem }}
    </div>
    <div ngbDropdownMenu aria-labelledby="dropdownProjectSubNav">
      <a
        *ngIf="!!chatId"
        [routerLink]="'/chat/' + chatId | routerTenant"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active"
        ngbDropdownItem
        class="d-flex align-items-center"
      >
        <app-svg name="chat" width="16" height="14" class="mr-1"></app-svg>
        <span>{{ t('tab-navbar-chat') }}</span>
      </a>
      <a
        *ngIf="'projects::' + objectId + '::calendarEventGetList' | checkPermission"
        [routerLink]="'/project/' + objectId + '/calendar' | routerTenant"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active"
        ngbDropdownItem
        t-id="sub-navbar-project-calendar"
        class="d-flex align-items-center"
      >
        <app-svg name="calendar" width="14" height="16" class="mr-1"></app-svg>
        <span>{{ t('tab-navbar-calendar') }}</span>
      </a>
      <a
        *ngIf="'projects::' + objectId + '::ticketsGetList' | checkPermission"
        [routerLink]="'/project/' + objectId + board | routerTenant"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active"
        ngbDropdownItem
        t-id="sub-navbar-project-board"
        class="d-flex align-items-center"
      >
        <app-svg name="board" width="16" height="16" class="mr-1"></app-svg>
        <span>{{ t('tab-navbar-board') }}</span>
      </a>
      <a
        *ngIf="'projects::' + objectId + '::filesGet' | checkPermission"
        [routerLink]="'/project/' + objectId + '/documents' | routerTenant"
        [routerLinkActiveOptions]="{ exact: false }"
        routerLinkActive="active"
        ngbDropdownItem
        t-id="sub-navbar-project-data-room"
        class="d-flex align-items-center"
      >
        <app-svg name="folder" width="16" height="14" class="mr-1"></app-svg>
        <span>{{ t('tab-navbar-dataroom') }}</span>
      </a>
      <a
        [routerLink]="'/project/' + objectId + '/wiki' | routerTenant"
        routerLinkActive="active"
        ngbDropdownItem
        t-id="sub-navbar-project-wiki"
        class="d-flex align-items-center"
      >
        <svg-icon src="assets/icons/wiki/book.svg" class="mr-1 wiki-svg"></svg-icon>
        <span>{{ t('tab-navbar-wiki') }}</span>
      </a>
    </div>
  </div>
</div>
