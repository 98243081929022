import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatsGet, ChatsUpdatePoll } from '../../../../store/actions/chats.action';
import { Store } from '@ngxs/store';
import { SocketsService } from '../../../../services/sockets.service';
import { ChatsMessageVoteReqDto } from './interface/ChatsMessageVoteReqDto';
import { LocalStorageService } from 'ngx-localstorage';
import { PollsVotesDbDto } from './interface/PollsVotesDbDto';
import { ChatsState } from '../../../../store/states/chats.state';
import { map, takeUntil } from 'rxjs/operators';
import moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { Moment } from 'moment-timezone';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PollResultComponent } from '../../../../../modals/poll-result/poll-result.component';
import { ConfigService, ITemplateConfig } from '../../../../services/config.service';
import { AvatarComponent } from '../../../../../standalone/components/avatar/avatar.component';
import { ProgressBarComponent } from '../../../progress-bar/progress-bar.component';
import { NgIf, NgFor } from '@angular/common';
import { SvgComponent } from '../../../../svgs/svg/svg.component';
import { TranslocoDirective } from '@ngneat/transloco';

@Component({
  selector: 'app-message-poll',
  templateUrl: './message-poll.component.html',
  styleUrls: ['./message-poll.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    SvgComponent,
    NgIf,
    NgFor,
    FormsModule,
    ReactiveFormsModule,
    ProgressBarComponent,
    forwardRef(() => AvatarComponent),
  ],
})
export class MessagePollComponent implements OnInit, OnDestroy {
  @Input() message;
  options: string[];
  platform = 'web';
  userId: string;
  chatMembers: any[];
  isLoading = false;
  title: string;
  isBeforeDay = false;
  config: ITemplateConfig;
  dueDatePoll: Moment;
  destroy$: Subject<void> = new Subject<void>();

  chosenAnswer = new FormControl('', Validators.required);
  chosenCoupleAnswer = new FormControl([], Validators.required);

  constructor(
    protected store: Store,
    protected socketsService: SocketsService,
    private localStorage: LocalStorageService,
    protected ref: ChangeDetectorRef,
    private configService: ConfigService,
    private modalsService: NgbModal,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit(): void {
    this.initializeDate();
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  // * Getter *
  get coupleAnswer() {
    return this.chosenCoupleAnswer.value;
  }

  get afterVoteShow(): boolean {
    return this.message?.poll?.isShowResultsAfter;
  }

  get isMultiChoice(): boolean {
    return this.message?.poll?.isMultiChoice;
  }

  get isAnonymous(): boolean {
    return this.message?.poll?.isAnonymous;
  }

  get messagePollId(): string {
    return this.message?.poll?._id;
  }

  get userLength(): number {
    return this.chatMembers?.length;
  }

  get isDueDate(): boolean {
    return !!this.message?.poll?.dueDate;
  }

  get userVoteLength(): number {
    return this.uniqueMembers?.length;
  }

  get isFirstVoteUser(): boolean {
    return this.message?.poll?.isCompleted
      ? false
      : !this.message?.poll?.votes?.some((user) => user.userId === this.userId);
  }

  get isCompleted(): boolean {
    return this.message?.poll?.isCompleted || false;
  }

  get isCreator(): boolean {
    return this.message?.poll?.creatorId === this.userId;
  }

  get uniqueMembers() {
    return this.message?.poll?.votes.filter(
      (value, index, array) => array.findIndex((o) => o.userId === value.userId) === index,
    );
  }

  get dueDate(): string {
    const formattedDueDate = this.dueDatePoll.format('h a');
    return this.isBeforeDay
      ? `on ${this.dueDatePoll.format('DD MMM')} at ${formattedDueDate}`
      : `at ${formattedDueDate}`;
  }

  isVoteUser(index: number): boolean {
    return this.message?.poll?.votes?.some(
      (user) => user.userId === this.userId && user.optionIndex === index,
    );
  }

  getVoteUsers(index: number): Array<PollsVotesDbDto> {
    return this.message?.poll?.votes?.filter((user) => user.optionIndex === index);
  }

  getPercentageOption(index: number): number {
    if (!this.userLength || !this.getVoteUsers(index)?.length) {
      return 0;
    }
    return Math.floor((this.getVoteUsers(index)?.length / this.userVoteLength) * 100);
  }

  checkIsBeforeDay(): boolean {
    const now = moment();
    const duration = moment.duration(this.dueDatePoll.diff(now));
    const hoursLeft = duration.asHours();

    return hoursLeft >= 24;
  }

  // * Method *
  vote(): void {
    const socket = this.socketsService.get();

    const data: ChatsMessageVoteReqDto = {
      messageId: this.message._id,
      votes: this.coupleAnswer.length
        ? this.coupleAnswer.map((answer) => ({
            optionIndex: answer.index,
            userId: this.userId,
          }))
        : [{ optionIndex: this.chosenAnswer.value, userId: this.userId }],
    };

    socket.emit('chats:message:vote', data);
    if (this.platform !== 'web') {
      this.store.dispatch(new ChatsGet());
    }

    this.chosenAnswer.setValue('');
    this.chosenCoupleAnswer.setValue([]);
  }

  choseOption(option, index: number): void {
    const result = { option, index };
    if (this.coupleAnswer.some((answer) => answer.index === result.index)) {
      this.chosenCoupleAnswer.setValue(
        this.coupleAnswer.filter((answer) => answer.index !== result.index),
      );
      return;
    }

    this.chosenCoupleAnswer.setValue([...this.coupleAnswer, result]);
  }

  showResult(): void {
    const modalRef = this.modalsService.open(PollResultComponent, {
      size: 'md',
      centered: true,
      windowClass: 'poll',
    });

    modalRef.componentInstance.pollData = {
      title: this.message?.poll?.title,
      options: this.options.map((option: string, index: number) => ({
        name: option,
        percent: this.getPercentageOption(index),
        voter: this.getVoteUsers(index),
      })),
      chatMembers: this.chatMembers,
    };
  }

  cancelVote(): void {
    const socket = this.socketsService.get();
    socket.emit('chats:message:cancel-vote', { messageId: this.message._id });
  }

  stopPoll(): void {
    this.isLoading = true;
    this.store
      .dispatch(
        new ChatsUpdatePoll({
          id: this.message.pollId,
          body: {
            messageId: this.message._id,
            isCompleted: true,
          },
        }),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        },
      );
  }

  initializeDate(): void {
    this.options = this.message?.poll ? [...this.message?.poll?.options] : [];
    this.title = this.message?.poll?.title;
    this.userId = this.localStorage.get('userId');
    this.dueDatePoll = moment(this.message?.poll?.dueDate);
    this.isBeforeDay = this.checkIsBeforeDay();

    this.store
      .select(ChatsState.getChatMembers)
      .pipe(
        takeUntil(this.destroy$),
        map((filterFn) => filterFn(this.message?.poll?.chatId)),
      )
      .subscribe((members) => (this.chatMembers = members));

    this.ref.detectChanges();
  }
}
