import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Actions, Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';

import { AuthState } from '../../shared/store/states/auth.state';
import { MembersInviteComponent } from '../../shared/components/members-invite/members-invite.component';
import { ConfirmAlert } from '../../shared/alerts/alerts';
import { NgIf } from '@angular/common';
import { SvgComponent } from '../../shared/svgs/svg/svg.component';

@Component({
  selector: 'app-invite-to-space-modal',
  templateUrl: './invite-to-space-modal.component.html',
  styleUrls: ['./invite-to-space-modal.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, SvgComponent, NgIf, MembersInviteComponent],
})
export class InviteToSpaceModalComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  platform: string;
  object: string;
  objectId: string;
  newUsersOnly: boolean;
  @ViewChild('membersInviteComponent') membersComponent: MembersInviteComponent;

  constructor(
    private actions: Actions,
    private store: Store,
    private activeModal: NgbActiveModal,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.getPlatform();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Close modal handler
   */
  close() {
    if (this.membersComponent.invitedMembersList.length) {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.close-modal-subject'),
        text: this.translocoService.translate('alert.close-modal-text'),
        showCancelButton: true,
        cancelButtonText: this.translocoService.translate('alert.close-modal-btn-close'),
        showDenyButton: true,
        denyButtonText: this.translocoService.translate('alert.close-modal-btn-discard'),
        denyButtonClass: 'btn-subtle',
        confirmButtonText: this.translocoService.translate('alert.close-modal-btn-save'),
        confirmButtonClass: 'btn-solid',
        platform: this.platform,
      }).then(
        (result) => {
          if (result === 'isDenied') {
            this.activeModal.close();
          }
          if (result === 'isConfirmed') {
            this.membersComponent.usersInviteClick();
          }
        },
        () => {},
      );
    } else {
      this.activeModal.close();
    }
  }

  getPlatform(): void {
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);
  }
}
