import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { Router, RouterLinkActive, RouterLink } from '@angular/router';
import { Actions, Select, Store } from '@ngxs/store';
import {
  NgbDropdown,
  NgbModal,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { TenantMembersComponent } from '../../../../modals/tenant-members/tenant-members.component';
import { ChatMenuComponent } from './chat-menu.component';
import { RouterTenantPipe } from '../../../pipes/router-tenant.pipe';
import { ConfigService } from '../../../services/config.service';
import { SocketsService } from '../../../services/sockets.service';
import { TauriService } from '../../../services/tauri.service';
import { SpaceService } from '../../../services/space.service';
import { ProjectService } from '../../../services/project.service';
import { RecordService } from '../../../services/record.service';
import { TenantsService } from '../../../../api/services/tenants.service';
import { RedirectService } from '../../../services/redirect.service';
import { TenantsGetListResDto } from '../../../../api/models/tenants-get-list-res-dto';
import { TenantUpdateComponent } from '../../../../modals/tenant-update/tenant-update.component';
import { ArchiveModalComponent } from '../../../../modals/archive-modal/archive-modal.component';
import { LocalStorageService } from 'ngx-localstorage';
import { environment } from '../../../../../environments/environment';
import { SwitchTenant } from '../../../store/actions/auth.action';
import { TenantsState } from '../../../store/states/tenants.state';
import { AuthState } from '../../../store/states/auth.state';
import { TenantsListResDto } from '../../../../api/models/tenants-list-res-dto';
import { TenantClear } from '../../../store/actions/tenants.action';
import { ChatTypes } from './constants/chat-navigation.constants';
import { ProjectOrders } from '../../../store/actions/projects.action';
import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag } from '@angular/cdk/drag-drop';
import Memoize from '../../../decorators/memoize.decorator';
import { ChatsOrdersUpdate } from '../../../store/actions/chats.action';
import { SpaceOrders } from '../../../store/actions/spaces.action';
import { untilDestroyed } from '@ngneat/until-destroy';
import { NgScrollbar } from 'ngx-scrollbar';
import { TranslocoService, TranslocoDirective, TranslocoPipe } from '@ngneat/transloco';
import { EnvironmentService } from '../../../services/environment.service';
import packageInfo from '../../../../../../package.json';
import { UnreadPipe } from '../../../pipes/unread.pipe';
import { CheckPermissionPipe } from '../../../pipes/check-permission.pipe';
import { UserNameAlonePipe } from '../../../../standalone/pipes/user-name.pipe';
import { TmpTooltipDirective } from '../../../../standalone/components/tmp-tooltip/tmp-tooltip.derective';
import { AvatarComponent } from '../../../../standalone/components/avatar/avatar.component';
import { GroupAvatarComponent } from '../../space-projects/group-avatar/group-avatar.component';
import { ProjectAvatarComponent } from '../../space-projects/project-avatar/project-avatar.component';
import { SpaceAvatarComponent } from '../../space-projects/space-avatar/space-avatar.component';
import { TippyDirective } from '../../../directives/tippy.directive';
import { FormsModule } from '@angular/forms';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgIf, NgClass, NgFor, NgTemplateOutlet, AsyncPipe, DatePipe } from '@angular/common';
import { ChatsState } from '../../../store/states/chats.state';
import { ThreadsState } from '../../../store/states/threads.state';
import { Observable } from 'rxjs';
import { SvgComponent } from '../../../svgs/svg/svg.component';
import { MixpanelService } from '../../../../plugins/mixpanel/mixpanel.service';
import { InviteToTenantModalComponent } from '../../../../modals/invite-to-tenant-modal/invite-to-tenant-modal.component';

@Component({
  selector: 'app-chat-navigation',
  styleUrls: ['./chat-navigation.component.scss'],
  templateUrl: './chat-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    NgbDropdown,
    NgbDropdownToggle,
    NgIf,
    SvgIconComponent,
    NgClass,
    NgbDropdownMenu,
    NgScrollbar,
    NgFor,
    RouterLinkActive,
    RouterLink,
    FormsModule,
    NgTemplateOutlet,
    NgbTooltip,
    TippyDirective,
    CdkDropList,
    CdkDrag,
    SpaceAvatarComponent,
    ProjectAvatarComponent,
    GroupAvatarComponent,
    forwardRef(() => AvatarComponent),
    TmpTooltipDirective,
    UserNameAlonePipe,
    AsyncPipe,
    DatePipe,
    RouterTenantPipe,
    CheckPermissionPipe,
    UnreadPipe,
    TranslocoPipe,
    SvgComponent,
  ],
})
export class ChatNavigationComponent extends ChatMenuComponent implements OnDestroy, AfterViewInit {
  platform = 'web';

  @ViewChild(NgbDropdown) tenantSelect: NgbDropdown;
  @ViewChild('scrollbar') scrollbarRef: NgScrollbar;
  dropdownIsOpen: boolean;
  webAppVer: string = `${packageInfo.version || '0'}${environment.build_meta}`;

  currentUser = this.store.selectSnapshot(AuthState.getUser);

  @Select(ChatsState.getAllUnreadMessagesCount) allUnread$: Observable<number>;

  ChatTypes = ChatTypes;

  constructor(
    protected actions: Actions,
    protected store: Store,
    protected router: Router,
    protected cdr: ChangeDetectorRef,
    protected routerTenantPipe: RouterTenantPipe,
    protected configService: ConfigService,
    protected tauriService: TauriService,
    protected socketsService: SocketsService,
    protected toastrService: ToastrService,
    protected modalService: NgbModal,
    public spaceService: SpaceService,
    public projectService: ProjectService,
    public tenantService: TenantsService,
    public screenRecord: RecordService,
    private redirectService: RedirectService,
    private readonly localStorageService: LocalStorageService,
    protected translocoService: TranslocoService,
    protected environmentService: EnvironmentService,
  ) {
    super(
      actions,
      store,
      router,
      cdr,
      routerTenantPipe,
      configService,
      tauriService,
      socketsService,
      toastrService,
      modalService,
      spaceService,
      projectService,
      tenantService,
      translocoService,
      environmentService,
    );

    MixpanelService.setSuperProperties({
      version: this.webAppVer,
    });
  }

  ngAfterViewInit() {
    this.scrollbarRef?.scrolled.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.dropdownRef && this.dropdownRef.isOpen()) {
        this.dropdownRef.close();
      }
    });

    super.ngAfterViewInit();
  }

  @Memoize(['spaces'])
  get personalSpace() {
    return this.spaces.find((space) => space.isPersonal);
  }

  @Memoize(['spaces'])
  get commonSpaces() {
    return this.spaces
      .reduce((acc, item) => {
        if (acc.some((el) => el._id === item._id)) {
          return acc;
        }

        return [...acc, item];
      }, [])
      .filter((space) => !space.isPersonal)
      .sort((a, b) => a.order - b.order);
  }

  handlerTenantMembersModal() {
    this.modalService.open(TenantMembersComponent, {
      size: 'lg',
    });
  }

  handlerDeleteSpaceModal(spaceId) {
    const space = this.spaces.find((item) => item._id === spaceId);
    if (space) {
      const modalRef = this.modalService.open(ArchiveModalComponent, {
        size: 'md',
        centered: true,
      });
      modalRef.componentInstance.pageData = {
        space: space,
        type: 'space',
        delete: true,
      };
    }
  }

  handlerDeleteProjectModal(projectId) {
    const project = this.projects.find((item) => item._id === projectId);
    if (project) {
      const modalRef = this.modalService.open(ArchiveModalComponent, {
        size: 'md',
        centered: true,
      });
      modalRef.componentInstance.pageData = {
        project,
        type: 'project',
        delete: true,
      };
    }
  }

  isBadge(tenant: TenantsGetListResDto): boolean {
    const currentTenant = this.store.selectSnapshot(TenantsState.getTenantName);
    return tenant.badge && tenant.name !== currentTenant;
  }

  changeTenant(tenant: TenantsListResDto): void {
    const tenants = this.store.selectSnapshot(AuthState.getTenantList);
    const currentTenant = tenants.find(
      (ten) => ten.name === this.store.selectSnapshot(AuthState.getUser).tenantName,
    );

    tenants.forEach((ten) => {
      if (currentTenant.name !== ten.name) {
        this.socketsService.get().emit('tenants.unsubscribe.updates', { userId: ten.userId });
      }
    });

    this.store.dispatch(new SwitchTenant({ userId: tenant.userId }));
  }

  editTenant(tenant: TenantsGetListResDto): void {
    this.tenantSelect.toggle();
    this.modalService.open(TenantUpdateComponent, {
      windowClass: 'new-modal',
    }).componentInstance.tenant = tenant;
  }

  hasRole(availableObject: string, availableRoles: string[]): boolean {
    return (this.currentUser.roles as { object: string; roleName: string }[]).some(
      (role) => role.object === availableObject && availableRoles.includes(role.roleName),
    );
  }

  createTenant(): void {
    this.localStorageService.set('isAddTenant', true);

    if (environment.is_desktop) {
      this.store.dispatch(new TenantClear()).subscribe(() => {
        this.router.navigate([this.routerTenantPipe.transform('/auth/login', 'root')]);
      });
    } else if (!environment.subdomains) {
      this.redirectService.go('root', 'auth/login');
    } else {
      this.redirectService.go('', 'auth/login');
    }
  }

  handlerArchiveSpaceModal(space) {
    const modalRef = this.modalService.open(ArchiveModalComponent, {
      size: 'md',
      centered: true,
    });
    modalRef.componentInstance.pageData = {
      space: space,
      type: 'space',
    };
  }

  handlerArchiveProjectModal(project) {
    const modalRef = this.modalService.open(ArchiveModalComponent, {
      size: 'md',
      centered: true,
    });
    modalRef.componentInstance.pageData = {
      project: { ...project, _id: project.objectId },
      type: 'project',
    };
  }

  redirectToActiveSprint() {
    this.redirectService.setRedirectToActiveSprintValue(true);
  }

  openInviteToTenantModal() {
    const modalRef = this.modalService.open(InviteToTenantModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.platform = this.platform;
    modalRef.componentInstance.newUsersOnly = true;
  }

  onDnDChats(event: CdkDragDrop<Array<any>>, chatType: string) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

    const remapAndRecountByKey = (chats: Array<any>, key: string) =>
      chats.map((chat, index) => ({
        _id: chat[key],
        spaceName: chat.spaceName,
        order: index,
      }));
    const getChatId = (str: string, selector: string) => str.replace(selector, '');

    switch (chatType) {
      case ChatTypes.Space: {
        this.store.dispatch(
          new SpaceOrders({
            actionSpaceId: getChatId(event.item.element.nativeElement.id, 'spaceItem-'),
            spaces: remapAndRecountByKey(event.container.data, '_id'),
          }),
        );
        break;
      }
      case ChatTypes.Project: {
        this.store.dispatch(
          new ProjectOrders({
            actionProjectId: getChatId(event.item.element.nativeElement.id, 'projectItem-'),
            projects: remapAndRecountByKey(event.container.data, 'objectId'),
          }),
        );

        break;
      }
      case ChatTypes.GroupChat: {
        // TODO: Updating orders for group chats
        this.store.dispatch(
          new ChatsOrdersUpdate({
            actionGroupChatId: getChatId(event.item.element.nativeElement.id, 'groupChatItem-'),
            groupChats: remapAndRecountByKey(event.container.data, 'chatId'),
          }),
        );

        break;
      }
    }
  }

  protected readonly window = window;
}
