import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  Output,
  ElementRef,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngxs/store';
import { fromEvent } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { TruncatePipe } from '../../../pipes/truncate.pipe';
import { RouterTenantPipe } from '../../../pipes/router-tenant.pipe';
import { ConfigService } from '../../../services/config.service';
import { AuthState } from '../../../store/states/auth.state';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SprintsService } from '../../../../pages/full-pages/board/backlog/sprints.service';
import { EpicsService } from '../../../../pages/full-pages/board/backlog/epics/epics.service';
import { BoardLabels, BoardTypes } from '../../../enums';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { BoardTicketsService } from '../../../../pages/full-pages/board/board-tickets.service';
import { SvgIconComponent } from 'angular-svg-icon';
import { SvgComponent } from '../../../svgs/svg/svg.component';
import {
  NgbTooltip,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownButtonItem,
  NgbDropdownItem,
} from '@ng-bootstrap/ng-bootstrap';
import { AvatarComponent } from '../../../../standalone/components/avatar/avatar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf, NgFor, NgClass, NgStyle } from '@angular/common';
import { MixpanelService } from '../../../../plugins/mixpanel/mixpanel.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-board-actionbar',
  templateUrl: './board-actionbar.component.html',
  styleUrls: [
    './board-actionbar.component.scss',
    '../../../../pages/full-pages/board/backlog/sprint-container/sprint-container.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TruncatePipe],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgIf,
    NgSelectModule,
    FormsModule,
    NgFor,
    forwardRef(() => AvatarComponent),
    NgbTooltip,
    NgbDropdown,
    NgbDropdownToggle,
    NgClass,
    NgbDropdownMenu,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    NgStyle,
    RouterLink,
    SvgComponent,
    ReactiveFormsModule,
    SvgIconComponent,
  ],
})
export class BoardActionBarComponent implements AfterViewInit, OnChanges {
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

  @Input() object: string;
  @Input() objectId: string;
  @Input() isMobile = false;
  @Input() orientation = 'portrait';
  @Input() prefix: string;
  @Input() view: string;
  @Input() ticketsMembers: any[];
  @Input() moreMembers: any[];
  @Input() filteredMembers: any[];
  @Input() labels: any[];

  @Output() addTicket = new EventEmitter();
  @Output() filterTicketsByMember = new EventEmitter();
  @Output() filterTicketsByLabels = new EventEmitter();
  @Output() filterTicketsBy = new EventEmitter();
  @Output() clearFilters = new EventEmitter();
  @Output() SearchTickets = new EventEmitter();
  @Output() clearSearchInput = new EventEmitter();

  MAX_LINED_UP_BOARD_MEMBERS: number;
  MAX_LINED_UP_BOARD_MEMBERS_MOBILE: number;

  showEpics = new FormControl(false);

  config: any = {};
  searchedText: string;
  selectedLabels: string[];
  selectedFilterBy: string[];
  isSearchActive = false;
  views: Record<string, string>[] = Object.keys(BoardLabels).map((key) => {
    return {
      label: key === 'Archive' ? this.translocoService.translate('board.archive') : key,
      value: BoardTypes[key],
    };
  });
  activeSprintDate: string;

  filterTypes = [
    { label: 'Filter By', value: '' },
    { label: 'Start Date', value: 'startDate' },
    { label: 'Due Date', value: 'dueDate' },
  ];

  filteredBy: string = '';

  constructor(
    private store: Store,
    private router: Router,
    private routerTenant: RouterTenantPipe,
    protected configService: ConfigService,
    private epicsService: EpicsService,
    private boardTicketsService: BoardTicketsService,
    public ref: ChangeDetectorRef,
    public sprintsService: SprintsService,
    private readonly translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
    this.MAX_LINED_UP_BOARD_MEMBERS = this.configService.MAX_LINED_UP_BOARD_MEMBERS;
    this.MAX_LINED_UP_BOARD_MEMBERS_MOBILE = this.configService.MAX_LINED_UP_BOARD_MEMBERS_MOBILE;

    this.epicsService.showEpics$.pipe(untilDestroyed(this)).subscribe((state) => {
      this.showEpics.patchValue(state, { emitEvent: false });
    });

    this.showEpics.valueChanges.pipe(untilDestroyed(this)).subscribe((state: boolean) => {
      this.epicsService.broadcastShowEpics(state);
    });

    this.sprintsService.activeSprint$.pipe(untilDestroyed(this)).subscribe((activeSprint) => {
      this.activeSprintDate = this.sprintsService.getSprintDate(activeSprint);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.objectId || changes?.view) {
      this.selectedLabels = [];
    }
  }

  ngAfterViewInit() {
    this.configService.templateConf$.pipe(untilDestroyed(this)).subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
        this.ref.markForCheck();
      }
    });

    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        untilDestroyed(this),
        debounceTime(this.configService.SEARCH_DEBOUNCE_TIME),
        tap((_) => this.SearchTickets.emit(this.searchedText)),
      )
      .subscribe();
  }

  get isBacklog(): boolean {
    return !!this.view.includes(BoardTypes.Backlog);
  }

  get isSprint(): boolean {
    return this.view.includes(BoardTypes.Sprint);
  }

  get isArchive(): boolean {
    return this.view.includes(BoardTypes.Archive);
  }

  get btnLabel(): string {
    return this.isBacklog
      ? this.translocoService.translate('board.btn-add-sprint')
      : this.translocoService.translate('board.btn-add-ticket');
  }

  checkMemberIsFiltered(userId) {
    return this.filteredMembers.includes(userId);
  }

  getLink(path) {
    return this.routerTenant.transform(`/${this.prefix}/${this.objectId}/board/${path}`);
  }

  navigateTo(path) {
    MixpanelService.trackEvent('Board: navigate to', { path });
    if (this.isMobile) {
      const user = this.store.selectSnapshot(AuthState.getUser);
      const url = this.routerTenant.transform(`${this.prefix}/${this.objectId}`, user.tenantName);

      this.router
        .navigate([url], { queryParams: { tab: 'tabBoard', subTab: path } })
        .then(() => this.boardTicketsService.refreshActiveBoard());
    } else {
      this.router.navigate([this.getLink(path)]);
    }
  }

  _filterTicketsByMember(id) {
    MixpanelService.trackEvent('Board: filter tickets by member', { id });
    this.searchedText = null;
    this.filterTicketsByMember.emit(id);
  }

  _filterTicketsByLabels() {
    MixpanelService.trackEvent('Board: filter tickets by labels', { labels: this.selectedLabels });
    this.searchedText = null;
    this.filterTicketsByLabels.emit(this.selectedLabels);
  }

  _filterTicketsBy(filteredBy) {
    MixpanelService.trackEvent('Board: filter tickets by', {
      filteredBy,
    });
    this.boardTicketsService.setFilteredBy(filteredBy);
  }

  public getSelectedItemsCount(selected: any[], labels: any[]) {
    return selected.filter((s) => labels.map((c) => c.id).includes(s.id)).length;
  }

  _clearSearchInput() {
    MixpanelService.trackEvent('Board: clear search input');
    this.isSearchActive = false;
    this.searchedText = null;
    this.clearSearchInput.emit();
    this.searchInput.nativeElement.focus();
  }

  addEntity(event: MouseEvent) {
    MixpanelService.trackEvent('Board: add entity', { isBacklog: this.isBacklog });
    this.isBacklog
      ? this.sprintsService.openCreateSprintModal(event, this.object, this.objectId)
      : this.addTicket.emit();
  }
}
