import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { NavigationEnd, Router, RouterLinkActive, RouterLink } from '@angular/router';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import ResizeObserver from 'resize-observer-polyfill';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ConfigService } from '../../services/config.service';
import { RedirectService } from '../../services/redirect.service';
import { SpacesState } from '../../store/states/spaces.state';
import { Store } from '@ngxs/store';
import { CheckPermissionPipe } from '../../pipes/check-permission.pipe';
import { RouterTenantPipe } from '../../pipes/router-tenant.pipe';
import {
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownItem,
} from '@ng-bootstrap/ng-bootstrap';
import { SvgIconComponent } from 'angular-svg-icon';
import { SvgComponent } from '../../svgs/svg/svg.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-sub-navbar',
  templateUrl: './sub-navbar.component.html',
  styleUrls: ['./sub-navbar.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgIf,
    RouterLinkActive,
    RouterLink,
    SvgComponent,
    SvgIconComponent,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownItem,
    RouterTenantPipe,
    CheckPermissionPipe,
  ],
})
export class SubNavbarComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('subNavbar') subNavbar: ElementRef;
  @ViewChild('workspaceTitleInput') workspaceTitleInput: ElementRef;

  @Input() object: string;
  @Input() objectId: string;
  @Input() chatId: string;
  @Input() menuItem: string;

  config: any = {};
  board = '/board';
  showDropdownMenu = false;
  private space: any;
  private subNavbarResizeObserver: ResizeObserver;

  destroy$: Subject<void> = new Subject<void>();

  constructor(
    readonly router: Router,
    public configService: ConfigService,
    public cdr: ChangeDetectorRef,
    private redirectService: RedirectService,
    private store: Store,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit(): void {
    this.board = this.getBoardType();

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.board = this.getBoardType();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges() {
    if (this.subNavbar) {
      this.subNavbarResizeObserver = new ResizeObserver((entries) => {
        this.showDropdownMenu = entries[0].contentRect.width <= 475;
        this.cdr.markForCheck();
      });
      this.subNavbarResizeObserver.observe(this.subNavbar.nativeElement);
    }
    this.space = this.store
      .selectSnapshot(SpacesState.getLoadedSpaces)
      .find((space) => space._id === this.objectId);
  }

  get checkIsPersonal() {
    return this.space && !this.space.isPersonal;
  }

  get textForChat() {
    return this.checkIsPersonal
      ? this.translocoService.translate('board.text-for-chat-chat') // 'Chat'
      : this.translocoService.translate('board.text-for-chat-pad'); // 'Pad'
  }

  getBoardType(): string {
    if (this.router.url?.includes('/backlog')) {
      return '/board/backlog';
    }
    if (this.router.url?.includes('/archive')) {
      return '/board/archive';
    }
    if (this.router.url?.includes('/sprint')) {
      return '/board/sprint';
    }
    return '/board/kanban';
  }

  redirectToActiveSprint() {
    this.redirectService.setRedirectToActiveSprintValue(true);
  }
}
