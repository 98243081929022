import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

export enum TOASTR_TYPE {
  error = 'error',
  success = 'success',
}

export enum TOASTR_TITLE {
  error = 'Error',
  success = 'Success',
}

interface ICreateToastr {
  message: string;
  title?: string;
  delay?: number;
  override?: Partial<IndividualConfig>;
  type?: TOASTR_TYPE;
  needToClear?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ToastrHelper {
  constructor(private toastr: ToastrService) {}

  private delay(delay = 0): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, delay);
    });
  }

  invokeToastr({
    message,
    title,
    delay,
    type = TOASTR_TYPE.error,
    override,
    needToClear = false,
  }: ICreateToastr): void {
    this.delay(delay).then(() => {
      title = title || TOASTR_TITLE[type];
      if (needToClear) {
        this.toastr.clear();
      }
      this.toastr[type]?.(message, title, override);
    });
  }
}
