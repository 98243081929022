import { Injectable } from '@angular/core';
import { State, Action, Selector, Store, StateContext } from '@ngxs/store';
import { append, patch, iif, updateItem, removeItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { Thread, ThreadsStateModel } from '../models/ThreadsState';
import { ChatsStateModel } from '../models/ChatsState';
import { Message } from '../../components/chat/chat.model';
import { ChatsNewMessageToUpload } from '../actions/chats.action';
import { ChatsService } from '../../../api/services/chats.service';
import {
  ThreadsClearCurrentThreadHeadMessage,
  ThreadsSetMessages,
  ThreadsSocketNewMessage,
  ThreadsSocketNewMessageInit,
  ThreadsUploadFile,
  ThreadsSocketUpdatedMessage,
  ThreadsUpdateMessageDraft,
  ThreadsSetCurrentThreadHeadMessage,
  ThreadsSetCurrentPage,
  ThreadsNewMessageToUpload,
  ThreadsClearData,
  ThreadMessageCreate,
  ThreadCreateWiki,
  MarkAllThreads,
  MarkThreadAsRead,
  MarkThreadAsUnread,
} from '../actions/threads.action';
import { LocalStorageService } from 'ngx-localstorage';
import { AppDatabase } from '../../../standalone/helpers/app.database';
import { firstValueFrom } from 'rxjs';

@State<ThreadsStateModel>({
  name: 'Threads',
  defaults: {
    currentThreadHeadMessage: {
      messageId: null,
      threadId: null,
    },
    threads: [],
    messagesToUpload: [],
  },
})
@Injectable()
export class ThreadsState {
  @Selector()
  static getLastMessageDownloaded(state: ThreadsStateModel) {
    return (threadId: string) => {
      const thread = state.threads.find((el) => el.threadId === threadId);
      if (thread?.messages) {
        return thread?.messages[0]?._id === thread?.lastMessageId;
      }
    };
  }

  @Selector()
  static getCurrentThreadHeadMessage(state: ThreadsStateModel) {
    return state.currentThreadHeadMessage;
  }

  @Selector()
  static getThreadById(state: ThreadsStateModel) {
    return (threadId: string) => {
      return state.threads.find((el) => el.threadId === threadId);
    };
  }

  @Selector()
  static getMessageDraft(state: ThreadsStateModel) {
    return (threadId: string) => state.threads.find((el) => el.threadId === threadId)?.messageDraft;
  }

  @Selector()
  static getMessagesToUpload(state: ThreadsStateModel) {
    return state.messagesToUpload;
  }

  constructor(
    private chatsService: ChatsService,
    private store: Store,
    private toastr: ToastrService,
    private localStorage: LocalStorageService,
    private adb: AppDatabase,
  ) {}

  @Action(ThreadsSetCurrentThreadHeadMessage)
  setCurrentThreadId(
    { patchState }: StateContext<ThreadsStateModel>,
    action: ThreadsSetCurrentThreadHeadMessage,
  ) {
    patchState({
      currentThreadHeadMessage: action.payload,
    });
  }

  @Action(ThreadsNewMessageToUpload)
  newMessageToUpload(
    { setState }: StateContext<ChatsStateModel>,
    { payload }: ChatsNewMessageToUpload,
  ) {
    setState(
      patch({
        messagesToUpload: append<Message>([payload]),
      }),
    );
  }

  @Action(MarkAllThreads)
  mark_all({ getState, setState }: StateContext<ThreadsStateModel>, payload: any) {
    const state = getState();

    setState({
      ...state,
      threads: state.threads.map((thread) =>
        thread.threadId === payload.payload.chatId
          ? {
              ...thread,
              messages:
                thread.messages?.map((message) => ({
                  ...message,
                  isUnread: false,
                })) ?? [],
            }
          : thread,
      ),
    });
  }

  @Action(MarkThreadAsRead)
  mark_as_read(
    { patchState, getState, setState }: StateContext<ThreadsStateModel>,
    { payload }: any,
  ) {
    const state = getState();
    const parsed = {
      ...state,
      threads: state.threads.map((thread) => {
        if (thread.threadId === payload.message.threadId && thread.messages.length > 0) {
          return {
            ...thread,
            messages: thread.messages.map((message) => {
              return message._id === payload.message._id
                ? {
                    ...message,
                    isUnread: false,
                  }
                : message;
            }),
          };
        }
        return thread;
      }),
    };
    setState(parsed);

    return;
  }
  @Action(MarkThreadAsUnread)
  mark_as_unread(
    { patchState, getState, setState }: StateContext<ThreadsStateModel>,
    { payload }: any,
  ) {
    const state = getState();
    const parsed = {
      ...state,
      threads: state.threads.map((thread) => {
        if (thread.threadId === payload.message.threadId && thread.messages.length > 0) {
          return {
            ...thread,
            messages: thread.messages.map((message) => {
              return message._id === payload.message._id
                ? {
                    ...message,
                    isUnread: true,
                  }
                : message;
            }),
          };
        }
        return thread;
      }),
    };
    setState(parsed);

    return;
  }

  @Action(ThreadsSetMessages)
  setMessages(
    { setState, getState }: StateContext<ThreadsStateModel>,
    { payload }: ThreadsSetMessages,
  ) {
    const currentThread = getState().threads.find((el) => el.threadId === payload.threadId);

    setState(
      patch({
        threads: iif<Thread[]>(
          !!currentThread,
          updateItem<Thread>(
            (thread) => thread.threadId === payload.threadId,
            patch({
              messages: [
                ...payload.messages,
                ...(currentThread?.messages
                  ? currentThread.messages?.filter(
                      (item) => !payload.messages.find((itemSub) => itemSub._id === item._id),
                    )
                  : []),
              ],
              lastMessageId: payload.lastMessageId,
              totalCount: payload.totalCount,
            }),
          ),
          append([{ ...payload, currentPage: 1 }]),
        ),
      }),
    );
  }

  @Action(ThreadsClearCurrentThreadHeadMessage)
  clearCurrentThreadId({ patchState }: StateContext<ThreadsStateModel>) {
    patchState({
      currentThreadHeadMessage: {
        threadId: null,
        messageId: null,
      },
    });
  }

  @Action(ThreadsSocketUpdatedMessage)
  socketUpdatedMessage(
    { setState, getState }: StateContext<ThreadsStateModel>,
    { payload }: ThreadsSocketUpdatedMessage,
  ) {
    const currentThreadIndex = getState().threads.findIndex(
      (thread) => thread.threadId === payload.threadMessage.threadId,
    );
    setState(
      patch({
        threads: iif<Thread[]>(
          currentThreadIndex !== -1,
          updateItem<Thread>(
            currentThreadIndex,
            patch<Thread>({
              messages: iif(
                payload.threadMessage.isDeleted,
                removeItem((message) => message._id === payload.threadMessage._id),
                updateItem(
                  (message) => message._id === payload.threadMessage._id,
                  payload.threadMessage,
                ),
              ),
              totalCount: payload.message.threadsMessagesInfo.messagesCount,
            }),
          ),
        ),
      }),
    );
  }

  @Action(ThreadsSocketNewMessage)
  socketNewMessage(
    { setState, getState }: StateContext<ThreadsStateModel>,
    { payload }: ThreadsSocketNewMessage,
  ) {
    setState(
      patch({
        threads: iif<Thread[]>(
          (threads) => threads.some((thread) => thread.threadId === payload.threadMessage.threadId),
          updateItem(
            (thread) => thread.threadId === payload.threadMessage.threadId,
            patch({
              messages: iif(
                (messages: Message[]) =>
                  messages &&
                  payload.threadMessage?._id &&
                  messages[
                    messages.findIndex(
                      (el) => el?.timestamp === payload.timestamp && el?.isUploading,
                    )
                  ]?.isUploading,
                updateItem(
                  (message) => message.timestamp === payload.timestamp && message.isUploading,
                  payload.threadMessage,
                ),
                append([payload.threadMessage]),
              ),
              lastMessageId: payload.threadMessage._id,
              totalCount: iif(
                !!payload.message,
                payload.message?.threadsMessagesInfo?.messagesCount,
              ),
            }),
          ),
        ),
        messagesToUpload: removeItem<Message>((msg) => msg?.timestamp === payload?.timestamp),
      }),
    );
  }

  @Action(ThreadsSocketNewMessageInit)
  socketNewMessageInit(
    { setState, getState }: StateContext<ThreadsStateModel>,
    { payload }: ThreadsSocketNewMessageInit,
  ) {
    setState(
      patch({
        threads: iif<Thread[]>(
          (threads) =>
            threads.some((thread) => thread?.threadId === payload.threadMessage?.threadId),
          updateItem(
            (thread) => thread?.threadId === payload.threadMessage?.threadId,
            patch({
              messages: iif(
                (messages: Message[]) =>
                  messages &&
                  payload.threadMessage?._id &&
                  messages[
                    messages.findIndex(
                      (el) => el?.timestamp === payload.timestamp && el?.isUploading,
                    )
                  ]?.isUploading,
                updateItem(
                  (message) => message.timestamp === payload.timestamp && message.isUploading,
                  payload.threadMessage,
                ),
                append([payload.threadMessage]),
              ),
              totalCount: iif(
                !!payload.message,
                payload.message?.threadsMessagesInfo?.messagesCount,
              ),
            }),
          ),
        ),
        messagesToUpload: removeItem<Message>((msg) => msg?.timestamp === payload?.timestamp),
      }),
    );
  }

  @Action(ThreadsUploadFile)
  uploadFile({ patchState }: StateContext<ThreadsStateModel>, { payload }: ThreadsUploadFile) {
    return this.chatsService.threadsCreate(payload).pipe(
      tap(
        () => {
          this.toastr.clear(payload.toastId);
        },
        (err) => {
          this.toastr.clear(payload.toastId);
          throw err.error;
        },
      ),
    );
  }

  @Action(ThreadsUpdateMessageDraft)
  updateMessageDraft(
    { setState }: StateContext<ThreadsStateModel>,
    { payload }: ThreadsUpdateMessageDraft,
  ) {
    setState(
      patch({
        threads: iif<Thread[]>(
          (threads) => threads.some((thread) => thread.threadId === payload.threadId),
          updateItem(
            (thread) => thread.threadId === payload.threadId,
            patch({ messageDraft: payload.messageDraft }),
          ),
          append([payload]),
        ),
      }),
    );
  }

  @Action(ThreadsSetCurrentPage)
  setCurrentPage(
    { setState }: StateContext<ThreadsStateModel>,
    { payload }: ThreadsSetCurrentPage,
  ) {
    setState(
      patch({
        threads: updateItem<Thread>(
          (threads) => threads.threadId === payload.threadId,
          patch({
            currentPage: payload.currentPage,
          }),
        ),
      }),
    );
  }

  @Action(ThreadsClearData)
  clearThreadsData({ patchState }: StateContext<ThreadsStateModel>) {
    patchState({
      threads: [],
    });
  }

  @Action(ThreadCreateWiki)
  threadCreateWiki() {}

  @Action(ThreadMessageCreate)
  async thread_create_message(
    { dispatch }: StateContext<ThreadsStateModel>,
    { payload }: ThreadMessageCreate,
  ) {
    const offlineMessage: Message[] = (await this.adb.getUploadMessages()) || [];
    if (!offlineMessage.some((msg) => msg.timestamp === payload.timestamp)) {
      this.adb.addUploadMessage(payload);
    }

    return this.chatsService.threadsMessagesCreate({ body: payload }).pipe(
      tap(
        () => {
          this.adb.deleteUploadMessage(payload.timestamp);
        },
        async (err) => {
          if (err.error.message.toLowerCase().includes('wiki')) {
            const message: any = (await this.adb.getUploadMessages()) || [];

            this.adb.deleteUploadMessage(payload.timestamp);

            dispatch(new ThreadCreateWiki({ error: err, body: payload }));
          }
          throw err.error;
        },
      ),
    );
  }
}
