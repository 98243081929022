<div
  class="actions-bar board-actions"
  [class.actions-bar-mobile]="isMobile"
  *transloco="let t; read: 'board'"
>
  <div *ngIf="!isMobile" class="d-flex align-items-center w-100">
    <div class="d-flex align-items-center flex-grow-1">
      <ng-select
        t-id="board-actionbar-view"
        class="board-view"
        bindLabel="label"
        bindValue="value"
        [(ngModel)]="view"
        [items]="views"
        [clearable]="false"
        [searchable]="false"
        [closeOnSelect]="true"
        (change)="navigateTo(view)"
      ></ng-select>

      <ng-select
        class="labels-filter"
        [placeholder]="t('labels-filter-placeholder')"
        bindLabel="label"
        bindValue="_id"
        [multiple]="true"
        [items]="labels"
        [searchable]="true"
        [closeOnSelect]="false"
        [(ngModel)]="selectedLabels"
        [notFoundText]="t('placeholder-select-not-found')"
        (change)="_filterTicketsByLabels()"
      >
        <ng-template ng-multi-label-tmp let-items="items">
          <span class="ng-placeholder d-flex align-items-center">
            <!--            Label<span *ngIf="getSelectedItemsCount(items, labels) > 1">s</span>-->
            {{
              t('labels-filter-option-placeholder', {
                value: getSelectedItemsCount(items, labels) > 1 ? 's' : ''
              })
            }}
            <span class="primary-circle board-filters-circle ml-1">
              {{ getSelectedItemsCount(items, labels) }} / {{ labels?.length }}
            </span>
          </span>
        </ng-template>

        <ng-template ng-option-tmp let-item="item" let-item$="item$">
          <input type="checkbox" class="mr-2" [ngModel]="item$.selected" />
          <span>{{ item.label }}</span>
        </ng-template>
      </ng-select>

      <ng-select
        class="filter-tickets-by"
        [placeholder]="t('filter-by-date-placeholder')"
        bindLabel="label"
        bindValue="value"
        [(ngModel)]="filteredBy"
        [items]="filterTypes"
        [clearable]="false"
        [searchable]="false"
        (change)="_filterTicketsBy(filteredBy)"
      >
      </ng-select>

      <div class="members-container d-flex align-items-center">
        <ng-container *ngFor="let member of ticketsMembers; let i = index">
          <ng-container *ngIf="i < MAX_LINED_UP_BOARD_MEMBERS">
            <div class="member">
              <app-avatar
                [userId]="member._id"
                [width]="24"
                [height]="24"
                [statusSize]="8"
                [border]="checkMemberIsFiltered(member._id)"
                [notShowProfile]="true"
                (click)="_filterTicketsByMember(member._id)"
                placement="top"
                ngbTooltip="{{ member.userName }}"
                container="body"
                [hasBorder]="true"
              ></app-avatar>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="ticketsMembers?.length > MAX_LINED_UP_BOARD_MEMBERS">
          <div ngbDropdown class="d-inline-block" autoClose="outside">
            <div
              ngbDropdownToggle
              class="d-flex align-items-center justify-content-center"
              [ngClass]="{
                'more-members-button-light': config?.layout.variant === 'Light',
                'more-members-button-dark': config?.layout.variant === 'Dark'
              }"
            >
              +{{ ticketsMembers.length - MAX_LINED_UP_BOARD_MEMBERS }}
            </div>

            <div ngbDropdownMenu>
              <span *ngFor="let moreMember of moreMembers; let i = index">
                <button
                  ngbDropdownItem
                  style="width: 100%"
                  (click)="filterTicketsByMember.emit(moreMember._id)"
                >
                  <span class="d-flex">
                    <div class="form-check mr-1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault1"
                        [checked]="checkMemberIsFiltered(moreMember._id)"
                      />
                    </div>

                    <app-avatar
                      [userId]="moreMember._id"
                      [width]="24"
                      [height]="24"
                      [statusSize]="9"
                      [notShowProfile]="true"
                      class="mr-2"
                    ></app-avatar>
                    <span>
                      {{ moreMember.userName }}
                    </span>
                  </span>
                </button>
              </span>
            </div>
          </div>
        </ng-container>

        <button
          class="btn btn-circle"
          type="button"
          triggers="hover"
          [ngbTooltip]="t('add-people-tooltip')"
          placement="top"
          container="body"
          [ngStyle]="{ 'margin-left': moreMembers?.length > 0 ? '-8px' : '' }"
          [routerLink]="getLink('members')"
        >
          <app-svg name="user-plus" width="18" height="14" aria-hidden="true"></app-svg>
        </button>

        <div *ngIf="filteredMembers.length > 0" class="vertical-divider"></div>

        <button
          *ngIf="filteredMembers.length > 0 || filteredBy !== ''"
          type="button"
          class="btn btn-sm btn-subtle clear-filter"
          (click)="clearFilters.emit()"
        >
          {{ t('btn-clear-filter') }}
        </button>
      </div>

      <div class="search-container d-flex align-items-center">
        <input
          #searchInput
          id="searchTickets"
          class="form-control search-field"
          type="text"
          [placeholder]="'&#xE8BD;&nbsp; ' + t('search-tickets-placeholder')"
          required
          [(ngModel)]="searchedText"
        />
        <span
          *ngIf="searchedText?.length > 0"
          class="d-flex justify-content-end align-items-center"
          (click)="_clearSearchInput()"
        >
          <app-svg name="times-circle" class="cursor-pointer" [width]="14" [height]="14"></app-svg>
        </span>
      </div>

      <div *ngIf="isSprint && activeSprintDate" class="sprint-header__date">
        {{ activeSprintDate }}
      </div>

      <div
        *ngIf="isBacklog"
        class="mr-4 custom-control custom-switch switch-md d-flex align-items-center"
      >
        <input
          type="checkbox"
          class="custom-control-input"
          id="epicsSwitch"
          [formControl]="showEpics"
          #epicsSwitch
          t-id="board-actionbar-show-epics"
        />
        <label
          class="custom-control-label d-flex align-content-center font-small-1 cursor-pointer"
          for="epicsSwitch"
        >
          <span class="mr-1 font-medium-1">{{ t('form-epics-switch') }}</span>
        </label>
      </div>
    </div>

    <button
      t-id="board-actionbar-create-ticket-action"
      class="btn btn-solid create-ticket-btn"
      type="button"
      (click)="addEntity($event)"
      *ngIf="!isSprint && !isArchive"
    >
      <svg-icon
        src="assets/icons/common/plus-purple.svg"
        class="actions mr-1"
        [applyClass]="true"
        [class.dark-purple]="config.layout.variant === 'Dark'"
        [svgStyle]="{ 'width.px': '11', 'height.px': '11' }"
      ></svg-icon>
      <span>{{ btnLabel }}</span>
    </button>
  </div>

  <div *ngIf="isMobile" class="filters-mobile d-flex align-items-center mx-2">
    <ng-select
      class="board-view"
      bindLabel="label"
      bindValue="value"
      [(ngModel)]="view"
      [items]="views"
      [clearable]="false"
      [searchable]="false"
      [closeOnSelect]="true"
      (change)="navigateTo(view)"
    ></ng-select>

    <ng-select
      class="labels-filter"
      [placeholder]="t('labels-filter-placeholder')"
      bindLabel="label"
      bindValue="_id"
      [items]="labels"
      [multiple]="true"
      [closeOnSelect]="false"
      [clearable]="false"
      [searchable]="true"
      [(ngModel)]="selectedLabels"
      (change)="_filterTicketsByLabels()"
    >
      <ng-template ng-multi-label-tmp let-items="items">
        <span class="ng-placeholder d-flex align-items-center">
          <!--          Label<span *ngIf="getSelectedItemsCount(items, labels) > 1">s</span>-->
          {{
            t('labels-filter-option-placeholder', {
              value: getSelectedItemsCount(items, labels) > 1 ? 's' : ''
            })
          }}
          <span class="primary-circle board-filters-circle ml-1">
            {{ getSelectedItemsCount(items, labels) }} / {{ labels?.length }}
          </span>
        </span>
      </ng-template>

      <ng-template ng-option-tmp let-item="item" let-item$="item$">
        <input type="checkbox" class="mr-2" [ngModel]="item$.selected" />
        <span>{{ item.label }}</span>
      </ng-template>
    </ng-select>

    <!-- filter by-->
    <ng-select
      class="filter-tickets-by"
      [placeholder]="t('filter-by-date-placeholder')"
      bindLabel="label"
      bindValue="value"
      [(ngModel)]="filteredBy"
      [items]="filterTypes"
      [clearable]="false"
      [searchable]="false"
      (change)="_filterTicketsBy(filteredBy)"
    >
    </ng-select>

    <div class="d-flex flex-grow-1 members-filter">
      <ng-container *ngFor="let member of ticketsMembers; let i = index">
        <div *ngIf="i < MAX_LINED_UP_BOARD_MEMBERS_MOBILE" class="member">
          <app-avatar
            [userId]="member._id"
            [width]="30"
            [height]="30"
            [border]="checkMemberIsFiltered(member._id)"
            [notShowProfile]="true"
            (click)="_filterTicketsByMember(member._id)"
          ></app-avatar>
        </div>
      </ng-container>
      <ng-container *ngIf="ticketsMembers?.length > MAX_LINED_UP_BOARD_MEMBERS_MOBILE">
        <div ngbDropdown class="d-inline-block" autoClose="outside">
          <div
            ngbDropdownToggle
            class="d-flex align-items-center justify-content-center"
            [ngClass]="{
              'more-members-button-light': config?.layout.variant === 'Light',
              'more-members-button-dark': config?.layout.variant === 'Dark'
            }"
          >
            +{{ ticketsMembers.length - MAX_LINED_UP_BOARD_MEMBERS_MOBILE }}
          </div>

          <div ngbDropdownMenu>
            <span *ngFor="let moreMember of moreMembers; let i = index">
              <button
                ngbDropdownItem
                style="width: 100%"
                (click)="filterTicketsByMember.emit(moreMember._id)"
              >
                <span class="d-flex">
                  <div class="form-check mr-1">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault2"
                      [checked]="checkMemberIsFiltered(moreMember._id)"
                    />
                  </div>

                  <app-avatar
                    [userId]="moreMember._id"
                    [width]="24"
                    [height]="24"
                    [statusSize]="9"
                    [notShowProfile]="true"
                    class="mr-2"
                  ></app-avatar>
                  <span>
                    {{ moreMember.userName }}
                  </span>
                </span>
              </button>
            </span>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="search-container-mobile align-items-center" [class.d-flex]="isSearchActive">
      <input
        #searchInput
        id="searchTicketsMobile"
        class="form-control search-field"
        type="text"
        [placeholder]="'&#xE8BD;&nbsp; ' + t('search-tickets-placeholder')"
        required
        [(ngModel)]="searchedText"
      />

      <span class="d-flex justify-content-end align-items-center" (click)="_clearSearchInput()">
        <app-svg name="times-circle" class="cursor-pointer" [width]="14" [height]="14"></app-svg>
      </span>
    </div>

    <div
      *ngIf="!isSearchActive"
      class="active-search svg-icon"
      [class.d-none]="isSearchActive"
      (click)="isSearchActive = true"
    >
      <app-svg name="search" width="14" height="14"></app-svg>
    </div>
  </div>
</div>
