import {
  Component,
  HostListener,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { BehaviorSubject, filter, Subject, Subscription } from 'rxjs';
import { debounceTime, delay } from 'rxjs/operators';
import { EmojiData, EmojiComponent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { SelectionChange, QuillEditorComponent } from 'ngx-quill';
import { ofActionDispatched } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { QuillInitializeService } from '../../../services/quill/quill-init.service';
import { ChatAppFormComponent } from '../chat-app-form/chat-app-form.component';
import { RecordStartedFrom } from '../../../services/record.service';
import {
  ThreadMessageCreate,
  ThreadsNewMessageToUpload,
  ThreadsSocketNewMessageInit,
  ThreadsUpdateMessageDraft,
} from '../../../store/actions/threads.action';
import { ChatsEmojiPicker } from '../../../store/actions/chats.action';
import { ThreadsState } from '../../../store/states/threads.state';
import { DebounceClickDirective } from '../../../directives/debounce-click.directive';
import {
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownItem,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { MentionModule } from 'angular-mentions';
import { FormsModule } from '@angular/forms';
import { MessageFilesComponent } from '../chat-messages/message-files/message-files.component';
import { SvgComponent } from '../../../svgs/svg/svg.component';
import { TranslocoDirective } from '@ngneat/transloco';
import { NgIf, NgClass, NgFor, AsyncPipe } from '@angular/common';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-thread-app-form',
  templateUrl: './thread-app-form.component.html',
  styleUrls: ['../chat-app-form/chat-app-form.component.scss', './thread-app-form.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    TranslocoDirective,
    NgClass,
    SvgComponent,
    NgFor,
    MessageFilesComponent,
    QuillEditorComponent,
    FormsModule,
    MentionModule,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownItem,
    DebounceClickDirective,
    NgbTooltip,
    EmojiComponent,
    AsyncPipe,
  ],
})
export class ThreadAppFormComponent extends ChatAppFormComponent implements OnInit, OnDestroy {
  @Input() platform = 'web';
  @Input() threadId: string;
  @Input() replyingTo: string;
  @Input() mentionChatMembers: any[];
  @Input() onNewThreadCreated: BehaviorSubject<string> = new BehaviorSubject(null);
  @Input() clearAfterUpload: Subject<boolean>;
  @Input() isSeparate = false;
  @Input() isMobileThread = false;
  @Input() isPreview = false;
  @Input() isPreviewMobile = false;
  @Input() placeholder;
  @Input() linkObject;
  @Input() linkObjectId;
  @ViewChild('threadInput', { static: false }) quill: any;

  @Output() inputIsHidden: EventEmitter<boolean> = new EventEmitter<boolean>();

  threadCreatedSub: Subscription;
  clearInputAfterUpload: Subscription;

  ngOnInit() {
    this.isThread = true;

    this.closeThreadEmojiPicker();
    super.ngOnInit();

    this.threadCreatedSub = this.onNewThreadCreated.subscribe((threadId) => {
      if (threadId) {
        this.threadId = threadId;
      }
    });

    this.clearInputAfterUpload = this.clearAfterUpload?.subscribe((needToClear) => {
      if (needToClear) {
        this.onClear();
        this.clearAfterUpload.next(false);
      }
    });

    this.actions
      .pipe(
        untilDestroyed(this),
        ofActionDispatched(ChatsEmojiPicker),
        filter(({ payload }) => payload?.emojiPickerIsOpen && payload?.isThread),
        delay(0),
      )
      .subscribe(() => {
        this.scrollToCenter();
      });
  }

  ngOnDestroy(): void {
    this.threadCreatedSub?.unsubscribe();
    this.clearInputAfterUpload?.unsubscribe();
    this.threadId = null;
  }

  // close thread emoji picker when clicked outside
  @HostListener('document:click', ['$event'])
  onClick(event) {
    const emojiPickerThreadElement = document.querySelector('.emoji-picker-thread');
    const emojiPickerButtonElement = document.querySelector('.thread-emoji-picker-icon');

    if (!emojiPickerButtonElement?.contains(event.target)) {
      if (this.isOpenThreadEmojiPicker && !emojiPickerThreadElement?.contains(event.target)) {
        this.closeThreadEmojiPicker();
      }
    }
  }

  startScreenRecord(isRecordWithMic: boolean, recordStartFrom: RecordStartedFrom): void {
    if (!this.recordService.recordedFile) {
      this.recordService.startScreenRecord(
        isRecordWithMic,
        recordStartFrom,
        this.getObjectIdForRecord(recordStartFrom),
      );
      this.recordListener();
    }
  }

  startAudioRecord(recordStartFrom: RecordStartedFrom) {
    if (!this.recordService.recordedFile) {
      this.recordService.startAudioRecord(
        recordStartFrom,
        this.getObjectIdForRecord(recordStartFrom),
      );
      this.recordListener();
    }
  }

  getObjectIdForRecord(recordStartFrom: RecordStartedFrom): string {
    if (this.linkObject === this.recordStartedFrom.Wiki) {
      return this.linkObjectId;
    } else if (recordStartFrom === this.recordStartedFrom.Ticket) {
      this.recordService.getTicketData();
      return this.linkObjectId;
    } else {
      return this.threadId;
    }
  }

  get linkObjectRecord(): RecordStartedFrom {
    if (this.linkObject === this.recordStartedFrom.Wiki) {
      return this.recordStartedFrom.Wiki;
    } else if (this.linkObject) {
      return this.recordStartedFrom.Ticket;
    } else {
      return this.recordStartedFrom.Thread;
    }
  }

  submitVideo(): void {
    if (this.isTicketFileRecording) {
      this.proceedRecordToTicket();
    } else if (this.isWikiFileRecording) {
      this.proceedRecordToWiki();
    } else {
      this.proceedRecording();
    }
  }

  proceedRecordToWiki(): void {
    this.recordService.stopRecording().then(() => this.recordService.sendFileWiki());
    this.screenRecordTime = '00:00';
    this.screenRecordTimeSub?.unsubscribe();
  }

  proceedRecordToTicket(): void {
    this.recordService.stopRecording().then(() => this.recordService.sendFileToThread());
    this.screenRecordTime = '00:00';
    this.screenRecordTimeSub?.unsubscribe();
  }

  threadEditorCreated(editor: any) {
    this.threadEditor = editor;

    // TODO: what is this for?
    //   - move to QuillInitializeService.handleEditorCreated if possible
    this.threadEditor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
      delta.forEach((e) => {
        if (e && e?.attributes) {
          e.attributes.color = '';
          e.attributes.background = '';
        }
      });
      return delta;
    });

    QuillInitializeService.handleEditorCreated(editor);

    this.checkDraftMessage();
    this.threadEditor.focus();
    this.scrollToCenter();
  }

  get isWeb(): boolean {
    return this.platform === 'web';
  }

  threadEmojiPickerToggle() {
    this.threadEditor.focus();
    this.isOpenThreadEmojiPicker = !this.isOpenThreadEmojiPicker;
    this.needToHandleScrollPosition = this.isOpenThreadEmojiPicker;
    this.store.dispatch(
      new ChatsEmojiPicker({
        emojiPickerIsOpen: this.isOpenThreadEmojiPicker,
        isThread: true,
      }),
    );

    if (!this.isOpenThreadEmojiPicker) {
      this.threadEditor.focus();
      this.isThreadTyping = this.testInput(this.threadMessage);
    }
  }

  toggleEditorToolbar() {
    if (this.editor) {
      this.editor.focus();
    }
    this.showEditorToolbar = !this.showEditorToolbar;
  }

  emojiSelected(emoji: EmojiData) {
    this.replaceCodeToEmoji(emoji, false, 'thread');
    this.resetEmojiSearch('thread');
  }

  textChanged(text, isSet = false) {
    if (this.threadEditor && isSet && text !== undefined) {
      this.threadEditor.root.innerHTML = text;
    }
    this.threadMessage = text;
    this.isThreadTyping = !!text;
    if (this.threadId) {
      this.messageChanged.emit(text);
    }
  }

  editorUpdater(): void {
    this.editorChange.pipe(untilDestroyed(this), debounceTime(500)).subscribe((event: any) => {
      if (event.html !== undefined) {
        this.textChanged(event.html);
      }

      // step 1: set caret position
      this.changeCaretPosition(event);
      // step 2: suggest emoji
      this.suggestEmoji('thread');
      // step 3: save draft
      this.store.dispatch(
        new ThreadsUpdateMessageDraft({
          threadId: this.threadId,
          messageDraft: event.html,
        }),
      );
    });
  }

  /**
   Post message handler
   */
  onAddMessage(submitType: string) {
    this.closeThreadEmojiPicker();

    const messageFetch = this.quill.valueGetter(this.quill.quillEditor, this.quill.editorElem);
    this.textChanged(messageFetch);

    if (this.files?.length) {
      const text = this.threadMessage && this.threadMessage.trim();
      const data = {
        threadId: this.threadId,
        messageId: this.replyingTo,
        linkObject: this.linkObject,
        linkObjectId: this.linkObjectId,
      };
      this.uploadFileService
        .sendFiles({ threadData: data, text, apiUrl: this.apiUrl || '' })
        .then(() => {
          this.uploadFiles = false;
        });
      this.uploadFiles = true;
      return;
    }

    if (this.threadMessage && this.testInput(this.threadMessage)) {
      const date = new Date();
      const threadMessage = {
        threadId: this.threadId,
        created_at: date,
        userId: this.currentUser._id,
        userName: this.currentUser.userName,
        isUploading: true,
        timestamp: date.getTime(),
        text: this.threadMessage.trim(),
      };

      const message: any = this.replyingTo
        ? {
            messageId: this.replyingTo,
            text: threadMessage.text,
            timestamp: date.getTime(),
          }
        : {
            linkObject: this.linkObject,
            linkObjectId: this.linkObjectId,
            text: threadMessage.text,
            timestamp: date.getTime(),
          };

      if (this.isPreview || this.isPreviewMobile) {
        message.object = this.object;
        message.objectId = this.objectId;
      }

      message.threadId = this.threadId;

      this.store.dispatch(new ThreadMessageCreate(message));

      if (this.threadId) {
        // TODO: remove threadId check after implementation for new messages in new thread
        this.store.dispatch([
          new ThreadsSocketNewMessageInit({ threadMessage }),
          new ThreadsNewMessageToUpload(threadMessage),
        ]);
      }

      this.onClear();
    }
  }

  hideThread(): void {
    this.uploadFileService.setFiles([]);
    this.inputIsHidden.emit(true);
  }

  checkDraftMessage(): void {
    const draft = this.store.selectSnapshot(ThreadsState.getMessageDraft)(this.threadId);
    this.caretPosition = draft ? draft.replace(/<[^>]+>/g, '').length : 0;

    this.textChanged(draft || '', true);

    if (this.platform === 'web' && !this.isSeparate) {
      setTimeout(() => this.threadEditor?.setSelection(this.caretPosition), 0);
    }
  }

  selectionChanged($event: SelectionChange) {
    if ($event.range?.index) {
      this.caretPosition = $event.range.index;
    }
  }

  onClear() {
    this.textChanged('', true);
    this.store.dispatch(
      new ThreadsUpdateMessageDraft({
        threadId: undefined,
        messageDraft: null,
      }),
    );
    this.resetEmojiSearch('thread');
    this.showEditorToolbar = false;
  }

  scrollToCenter() {
    this.threadEditor?.container?.parentElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
}
