<ng-container *ngIf="isSeparate || mainMessage">
  <section
    class="thread"
    [ngClass]="{ 'thread-android': platform === 'android', 'thread-ios': platform === 'ios' }"
    *transloco="let t; read: 'chat'"
  >
    <header
      *ngIf="!isSeparate && !isPreviewMobile"
      class="thread-header"
      [ngClass]="{
        'thread-header-light': config?.layout?.variant === 'Light',
        'thread-header-dark': config?.layout?.variant === 'Dark'
      }"
    >
      <span class="d-flex align-items-center">
        <span class="thread-heading flex-grow-1">{{ t('thread-heading') }}</span>

        <app-linked-object-badge
          *ngIf="
            platform !== 'web' && mainMessage.linkObject && mainMessage.linkObject !== 'video-calls'
          "
          [object]="object"
          [objectId]="objectId"
          [message]="mainMessage"
          [platform]="platform"
          [withView]="true"
        ></app-linked-object-badge>

        <a *ngIf="platform === 'web'" class="thread-close p-1" (click)="closeThreadSidebar()">
          <app-svg
            name="times"
            width="10"
            height="10"
            class="svg-icon"
            aria-hidden="true"
          ></app-svg>
        </a>
      </span>

      <span
        *ngIf="platform === 'web' && !isPreview && !isPreviewMobile"
        class="d-flex align-items-center justify-content-between flex-wrap mt-2"
      >
        <span class="breadcrumbs">
          <app-space-avatar
            *ngIf="mainMessage.spaceName"
            [space]="mainMessage.space"
            [isBreadcrumb]="true"
            [containerWidth]="24"
            [containerHeight]="24"
            class="w-[24px] h-[24px]"
          ></app-space-avatar>
          <a
            *ngIf="mainMessage.spaceChatId"
            [routerLink]="getRouteToChat(mainMessage, true) | routerTenant"
          >
            {{ mainMessage.spaceName }}
          </a>
          <span *ngIf="!mainMessage.spaceChatId && mainMessage.spaceName">{{
            mainMessage.spaceName
          }}</span>

          <app-svg
            *ngIf="mainMessage.chatId !== mainMessage.spaceChatId && mainMessage.spaceName"
            name="angle-right"
            [width]="7"
            [height]="7"
            [fill2]="config?.layout.variant === 'Dark' ? '#A8AABD' : '#1E1C25'"
          ></app-svg>

          <app-project-avatar
            *ngIf="mainMessage.project"
            [project]="mainMessage.project"
            [isBreadcrumb]="true"
            [containerWidth]="24"
            [containerHeight]="24"
            class="w-[24px] h-[24px]"
          ></app-project-avatar>
          <app-group-avatar
            class="w-[24px] h-[24px]"
            *ngIf="
              mainMessage.chatId !== mainMessage.spaceChatId &&
              mainMessage.spaceName &&
              !mainMessage.project
            "
            [isBreadcrumb]="true"
          ></app-group-avatar>
          <app-avatar
            class="w-[24px] h-[24px]"
            *ngIf="!mainMessage.spaceName"
            [width]="24"
            [height]="24"
            [statusSize]="3"
            [userId]="mainMessage.chatUserId"
          ></app-avatar>
          <a
            *ngIf="mainMessage.chatId !== mainMessage.spaceChatId"
            [routerLink]="getRouteToChat(mainMessage) | routerTenant"
          >
            {{ mainMessage.chatName }}
          </a>
        </span>

        <app-linked-object-badge
          *ngIf="mainMessage.linkObject && mainMessage.linkObject !== 'video-calls'"
          [object]="object"
          [objectId]="objectId"
          [message]="mainMessage"
          [platform]="platform"
          [withView]="true"
        ></app-linked-object-badge>
      </span>
    </header>

    <div
      *ngIf="!message?.threadsMessagesInfo?.messagesCount && isSeparate"
      class="thread-comments h-100"
    >
      {{ t('no-comments-for-ticket') }}
    </div>

    <app-thread-app-form
      *ngIf="isSeparate && !isMobileThread && !isHidden"
      t-id="chat-thread-add-comment"
      [platform]="platform"
      [threadId]="threadId"
      [apiUrl]="apiUrl"
      [linkObject]="linkObject"
      [linkObjectId]="linkObjectId"
      [mentionChatMembers]="mentionThreadMembers"
      [onNewThreadCreated]="onNewThreadCreated$"
      [isSeparate]="isSeparate"
      [placeholder]="t('ticket-placeholder-thread')"
      (audioRecorded)="onAudioRecorded($event)"
      (messageChanged)="onMessageChanged($event)"
      (scrollBottom)="scrollToBottom()"
      (fileChanged)="onFileChanged($event)"
      (keydown)="scrollToBottom()"
      (inputIsHidden)="isHidden = $event"
    ></app-thread-app-form>

    <button
      *ngIf="isHidden && isSeparate && !isMobileThread"
      class="thread-placeholder"
      type="button"
      (click)="isHidden = !isHidden"
      t-id="chat-thread-add-comment-button"
    >
      {{ t('btn-add-comment') }}
    </button>

    <div
      *ngIf="!isSeparate || (isSeparate && message?.threadsMessagesInfo?.messagesCount)"
      id="thread-messages{{ isSeparate ? '-separate' : '' }}"
      class="messages-wrapper"
      appDragDrop
      component="Threads"
      (draggingFile)="setIsDraggingFile(true)"
    >
      <app-chat-messages
        class="d-block h-100"
        [isSeparate]="isSeparate"
        [readOnly]="!isSeparate"
        [isPreview]="isPreview"
        [isPreviewMobile]="isPreviewMobile"
        [messages]="messages"
        [object]="mainMessage?.object || object"
        [objectId]="mainMessage?.objectId || objectId"
        [isThread]="true"
        [threadId]="threadId"
        [platform]="platform"
        [mentionChatMembers]="mentionThreadMembers"
        [showNoMessagesContainer]="
          (isSeparate && (!mainMessage || numberOfReplies === 0)) || !!!chatMessageCnt
        "
        [isVisible]="isVisible"
        [mainMessage]="mainMessage"
        [numberOfReplies]="numberOfReplies"
        [heightKeyboard]="heightKeyboard"
        [perPage]="perPage"
        [perChat]="perChat"
        [currentPage]="currentPage"
        [chatMessageCnt]="chatMessageCnt"
        [lastMessageLoaded]="lastMessageLoaded"
        (uploadClipboardFile)="uploadClipboardFile($event)"
        (onScrolledToTop)="getNextChatPage()"
        (copyTextToClipboard)="copyTextMobile($event)"
      ></app-chat-messages>
    </div>

    <app-drag-drop-view
      [component]="'Threads'"
      [isDraggingFile]="isDraggingFile"
      (draggingFile)="setIsDraggingFile($event)"
      (FileDropped)="fileDropped($event)"
    ></app-drag-drop-view>

    <emoji-mart
      *ngIf="threadEmojiIsOpen"
      class="emoji-picker-thread"
      title=""
      emoji=""
      [i18n]="i18n"
      [ngStyle]="{
        'margin-top':
          threadEmojiReactionIsOpen && platform === 'web' ? threadEmojiReactionTop + 'px' : ''
      }"
      [ngClass]="{
        'emoji-picker-thread-mobile': platform !== 'web',
        'thread-emoji-reaction': threadEmojiReactionIsOpen && platform === 'web'
      }"
      [backgroundImageFn]="emojiPickerImageFn"
      [autoFocus]="platform === 'web'"
      [isNative]="true"
      [darkMode]="darkMode"
      [exclude]="['flags']"
      [recent]="customRecentEmojis"
      (emojiSelect)="addEmoji($event)"
    ></emoji-mart>

    <app-thread-app-form
      *ngIf="!isSeparate || isMobileThread"
      [replyingTo]="mainMessage?._id"
      [isPreview]="isPreview"
      [isPreviewMobile]="isPreviewMobile"
      [object]="object"
      [objectId]="objectId"
      [apiUrl]="apiUrl"
      [platform]="platform"
      [threadId]="threadId"
      [linkObject]="linkObject"
      [linkObjectId]="linkObjectId"
      [mentionChatMembers]="mentionThreadMembers"
      [onNewThreadCreated]="onNewThreadCreated$"
      [isSeparate]="isSeparate"
      [isMobileThread]="isMobileThread"
      [clearAfterUpload]="clearAfterUpload$"
      (audioRecorded)="onAudioRecorded($event)"
      (messageChanged)="onMessageChanged($event)"
      (scrollBottom)="scrollToBottom()"
      (fileChanged)="onFileChanged($event)"
    ></app-thread-app-form>
  </section>
</ng-container>
