import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component, Input } from '@angular/core';
import { TicketsFieldsDbDto } from '../../../../../api/models/tickets-fields-db-dto';
import { TicketsFieldsValuesReqDto } from '../../../../../api/models/tickets-fields-values-req-dto';
import { TabIndex } from '../../custom-fields.component';
import { TranslocoDirective } from '@ngneat/transloco';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-text-input',
  templateUrl: 'text-input.component.html',
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, TranslocoDirective],
})
export class TextInputComponent {
  @Input() form: FormGroup;
  @Input() fieldConfig: TicketsFieldsDbDto;
  @Input() customFieldValues?: TicketsFieldsValuesReqDto;
  inputFocused: (name: string) => void;
  setFocusedControl: (tabIndex: string) => void;
  tabIndexes: Array<TabIndex>;
  displayName: string;
  get uniqFieldId() {
    return `${this.fieldConfig.title.trim()}-${this.fieldConfig.type.trim()}-${this.fieldConfig.order}`;
  }

  public checkNumber(event) {
    if (this.fieldConfig.type === 'number' && ['e', 'E', '+', '-'].includes(event.key)) {
      event.preventDefault();
    }
  }
}
