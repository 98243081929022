import { Component, Input, ChangeDetectorRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Store } from '@ngxs/store';
import { take, takeUntil } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment-timezone';

import { QuillModulesForDescription, QuillModulesForChat } from '../../data/quill-configuration';
import { ConfigService } from '../../services/config.service';
import { NotepadGet, NotepadUpdate } from '../../store/actions/notepad.action';
import { QuillInitializeService } from '../../services/quill/quill-init.service';
import { AuthState } from '../../store/states/auth.state';
import { FullImagePreviewService, IUser } from '../../services/full-image-preview.service';
import { TranslocoService } from '@ngneat/transloco';
import { ChatImagePreviewComponent } from '../chat/chat-image-preview/chat-image-preview.component';
import { QuillEditorComponent } from 'ngx-quill';
import { NgClass } from '@angular/common';
import { MixpanelService } from '../../../plugins/mixpanel/mixpanel.service';

@Component({
  selector: 'app-notepad',
  templateUrl: './notepad.component.html',
  styleUrls: ['./notepad.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    QuillEditorComponent,
    ChatImagePreviewComponent,
  ],
})
export class NotepadComponent implements OnInit, OnDestroy {
  @ViewChild('dataRoomImagePreviewModal') dataRoomImagePreviewModal;

  @Input() object: string;
  @Input() objectId: string;
  @Input() platform = 'web';

  config: any = {};
  MAX_IMAGE_FILE_SIZE: number;
  editorModules: any;
  notepadForm: FormGroup<{
    content: FormControl<string>;
  }>;
  startContent = '';
  isChanged = false;
  destroy$: Subject<void> = new Subject<void>();
  platformOS = 'web';
  currTzAbbr = null;
  user: IUser;
  previewModal: NgbModalRef;

  constructor(
    public activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private ref: ChangeDetectorRef,
    private store: Store,
    private toastr: ToastrService,
    private configService: ConfigService,
    private quillInitializeService: QuillInitializeService,
    public fullImagePreviewService: FullImagePreviewService,
    private modalsService: NgbModal,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
    this.MAX_IMAGE_FILE_SIZE = this.configService.MAX_IMAGE_FILE_SIZE;

    this.activatedRoute.data.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data.object && data.objectData) {
        this.object = data.object;
        this.objectId = data.objectData._id;
      }
    });
  }

  ngOnInit(): void {
    this.getPlatform();
    this.editorModules = {
      ...(this.platform === 'web' ? QuillModulesForDescription : QuillModulesForChat),
      magicUrl: true,
    };

    this.buildItemForm({ content: '' });

    this.store
      .dispatch(new NotepadGet({ object: this.object, objectId: this.objectId }))
      .pipe(takeUntil(this.destroy$))
      .subscribe((dispatchData) => {
        const data = dispatchData.Notepad;
        const content = data ? data.content : '';
        this.startContent = content;
        this.notepadForm.controls['content'].setValue(content);

        this.ref.detectChanges();
      });

    this.store
      .select(AuthState.getUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.user = user;
        if (user?.timezone) {
          this.currTzAbbr = moment.tz(user.timezone).format('Z').replace(':', '');
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$?.next();
    this.destroy$?.complete();
  }

  private buildItemForm(item: any) {
    this.notepadForm?.reset();

    this.notepadForm = this.formBuilder.group({
      content: [item.content || '', Validators.required],
    });
  }

  getEscapedContent(text) {
    return text.replace(/<[^>]+>/g, '');
  }

  editorChanged(event) {
    const content = event.editor.root.innerHTML;

    this.isChanged =
      this.startContent !== content &&
      this.getEscapedContent(this.startContent) !== this.getEscapedContent(content);
  }

  checkDescriptionImageMaxSize(description: string): boolean {
    return !(description?.length > this.MAX_IMAGE_FILE_SIZE);
  }

  submitForm() {
    MixpanelService.trackEvent('Notepad: Update content');
    const newData = { ...this.notepadForm.value };

    const body = Object.assign({
      ...newData,
      object: this.object,
      objectId: this.objectId,
    });

    if (!this.checkDescriptionImageMaxSize(newData?.content)) {
      this.toastr.error(
        this.translocoService.translate('toastr.size-limit-50mb'),
        this.translocoService.translate('toastr.title-error'),
      );
      return;
    }

    this.store
      .dispatch(new NotepadUpdate({ body }))
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe(
        () => {
          this.isChanged = false;
          this.toastr.success(
            this.translocoService.translate('toastr.content-updated'),
            this.translocoService.translate('toastr.title-success'),
          );
        },
        (err) =>
          this.toastr.error(err.message, this.translocoService.translate('toastr.title-error')),
      );
  }

  editorCreated(editor: any) {
    QuillInitializeService.handleEditorCreated(editor);
  }

  getPlatform(): void {
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);
    this.platformOS = this.store.selectSnapshot(AuthState.getPlatformOS);
  }

  handleClickInEditor(event: MouseEvent) {
    if (event?.target['src']) {
      this.openImagePreview(event.target['src']);
    }
  }

  openImagePreview(imageUrl: string) {
    MixpanelService.trackEvent('Notepad: Open image preview');
    this.fullImagePreviewService.prepareImages(this.notepadForm.controls.content.value, {
      user: this.user,
    });
    this.fullImagePreviewService.openImagePreview(imageUrl);

    if (this.fullImagePreviewService.imagePreview) {
      this.previewModal = this.modalsService.open(this.dataRoomImagePreviewModal, {
        backdrop: 'static',
        windowClass: 'cropper-modal',
      });
      this.fullImagePreviewService.updateModal();
    }
  }
}
