import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';

import { SlideUpToggleAnimation } from './utility/bottom-sheet-animation';
import { AngularBottomSheetConfig } from './utility/bottom-sheet-interface';
import { animateChild, query, transition, trigger } from '@angular/animations';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
  animations: [
    SlideUpToggleAnimation,
    trigger('container', [
      transition(':enter, :leave', [query('@*', animateChild(), { optional: true })]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf],
})
export class BottomSheetComponent {
  @Input() options: AngularBottomSheetConfig;
  @Input() isFullSize = false;
  @Output() close = new EventEmitter();
  @Output() toggleSize = new EventEmitter();
  nativeElement: HTMLElement;

  constructor(public element: ElementRef) {
    this.nativeElement = element.nativeElement;
  }
}
