<ng-container *ngIf="!disabledAction">
  <span
    t-id="linked-object-badge-chat-item"
    class="d-flex align-items-center"
    *transloco="let t"
    [ngClass]="{
      'badge-light': config?.layout.variant === 'Light',
      'badge-dark': config?.layout.variant === 'Dark',
      'cursor-pointer': !disabledAction,
      'cursor-not-allowed': disabledAction
    }"
    (click)="
      isCallLabel ? openVideoCall(message?.linkObjectData?.videoCallId) : viewLinkedEntity(message)
    "
  >
    <span
      [class.wiki-span]="isWikiPage"
      [innerHtml]="
        isCallLabel ? t('full-layout.linked-object-badge-join-call') : getLinkEntity(message)
      "
    ></span>
  </span>
</ng-container>
