import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ElementRef,
  HostListener,
  Renderer2,
} from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { Actions, Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UsersDbDto } from '../../api/models';
import { SocketsService } from '../../shared/services/sockets.service';
import { ChatsPrivateMessagesIsOpening } from '../../shared/store/actions/chats.action';
import { GetUsersListByTenant } from '../../shared/store/actions/users.action';
import { UsersState } from '../../shared/store/states/users.state';
import { AuthState } from '../../shared/store/states/auth.state';
import { AvatarComponent } from '../../standalone/components/avatar/avatar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SvgComponent } from '../../shared/svgs/svg/svg.component';
import { TranslocoDirective } from '@ngneat/transloco';

@Component({
  selector: 'app-tenant-members-modal',
  templateUrl: './tenant-members.component.html',
  styleUrls: ['./tenant-members.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, SvgComponent, NgSelectModule, FormsModule, AvatarComponent],
})
export class TenantMembersComponent implements OnInit, OnDestroy {
  users: any;
  destroy$: Subject<void> = new Subject<void>();
  platform: string;
  allUsers: UsersDbDto[];
  membersForm: FormGroup;
  chatMembers: any[];
  formBuilder: any;

  constructor(
    private store: Store,
    private socketsService: SocketsService,
    private activeModal: NgbActiveModal,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    const userLogin = this.store.selectSnapshot(AuthState.getUser);

    this.store
      .select(UsersState.getTenantUsers)
      .pipe(takeUntil(this.destroy$))
      .subscribe((users) => {
        this.users = users.filter((user) => user.userName !== userLogin.userName);
      });

    this.store.dispatch(new GetUsersListByTenant());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Close modal handler
   */
  close() {
    this.activeModal.close();
  }

  viewDirectChat(userId): void {
    this.store.dispatch(new ChatsPrivateMessagesIsOpening(true));
    this.socketsService.get().emit('chats:getDirectChatIdByUserId', { userId });
    this.close();
  }

  onSelectUser(event: any): void {
    this.viewDirectChat(event?._id);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    const directChatMembersElement = document.getElementById('directChatMembers');
    const modalElement = document.getElementById('dm-members-modal');

    if (
      directChatMembersElement &&
      directChatMembersElement.classList.contains('ng-select-opened')
    ) {
      this.renderer.addClass(modalElement, 'ng-select-panel-resize');
    } else {
      this.renderer.removeClass(modalElement, 'ng-select-panel-resize');
    }
  }
}
