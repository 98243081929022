<div
  class="sprint-modal"
  [formGroup]="sprintForm"
  [class.dark]="config?.layout.variant === 'Dark'"
  [class.sprint-modal-mobile]="platform !== 'web'"
  *transloco="let t; read: 'modals.sprint'"
>
  <div class="sprint-modal__title">
    <app-svg
      *ngIf="platform !== 'web'"
      name="angle-left"
      width="19"
      height="19"
      (click)="closeModal()"
      t-id="sprint-create-edit-title-sprint"
    ></app-svg>
    <span>{{ title }}</span>
  </div>
  <div class="sprint-modal__content">
    <div class="sprint-modal__content__item">
      <label for="sprint-name">{{ t('form-sprint-name') }}</label>
      <input
        type="text"
        id="sprint-name"
        class="form-control"
        placeholder=""
        [placeholder]="t('sprint-name-placeholder')"
        formControlName="name"
        t-id="sprint-create-edit-name-sprint"
      />
      <small
        *ngIf="!sprintForm.controls.name.valid && sprintForm.controls.name.touched"
        class="form-text text-muted danger"
      >
        {{ t('form-required') }}
      </small>
    </div>

    <div class="sprint-modal__content__item sprint-modal__content__date">
      <div>
        <label for="sprint-start-date">{{ t('form-start-date') }}</label>
        <app-date-time-picker
          id="sprint-start-date"
          t-id="calendar-event-date-picker-stop"
          [placeholder]="t('start-date-placeholder')"
          startOrEnd="end"
          formControlName="startDate"
          class="date-picker"
          [mobile]="platform === 'mobile'"
          [mobileCalendarEvent]="platform === 'mobile'"
          [invitedUser]="false"
          [tabIndexes]="['sprint-start-date', 'sprint-start-time']"
          [isPastDateDisabled]="true"
          (setIsPastDate)="setIsPastDate($event)"
        ></app-date-time-picker>
        <small *ngIf="!sprintForm.controls.startDate.valid" class="form-text text-muted danger">
          {{ t('form-required') }}
        </small>
      </div>
    </div>

    <div class="sprint-modal__content__item sprint-modal__content__date">
      <div>
        <label for="sprint-end-date">{{ t('form-end-date') }}</label>
        <app-date-time-picker
          id="sprint-end-date"
          t-id="calendar-event-date-picker-stop"
          [placeholder]="t('end-date-placeholder')"
          startOrEnd="end"
          formControlName="endDate"
          class="date-picker"
          [mobile]="platform === 'mobile'"
          [mobileCalendarEvent]="platform === 'mobile'"
          [invitedUser]="false"
          [tabIndexes]="['sprint-end-date', 'sprint-end-time']"
          [isPastDateDisabled]="true"
        ></app-date-time-picker>
        <small *ngIf="!sprintForm.controls.endDate.valid" class="form-text text-muted danger">
          {{ t('form-required') }}
        </small>
        <small *ngIf="isErrorAboutTimeRange" class="form-text text-muted danger">
          {{ t('form-end-date-warning') }}
        </small>
      </div>
    </div>

    <div class="sprint-modal__content__item">
      <label for="sprint-goals">{{ t('form-sprint-goals') }}</label>
      <textarea
        type="textarea"
        id="sprint-goals"
        class="form-control title-control"
        [placeholder]="t('type-something-placeholder')"
        formControlName="goals"
      ></textarea>
      <small *ngIf="!sprintForm.controls.goals.valid" class="form-text text-muted danger">
        {{ t('form-required') }}
      </small>
    </div>
  </div>

  <div class="sprint-modal__footer">
    <button
      *ngIf="platform !== 'mobile'"
      class="btn btn-outline"
      type="button"
      (click)="closeModal()"
      t-id="sprint-create-edit-cancel-button"
    >
      <span>{{ t('btn-cancel') }}</span>
    </button>

    <button
      class="btn btn-solid submit"
      type="submit"
      [disabled]="isDisabledBtn"
      (click)="createOrSaveSprint()"
      t-id="sprint-create-edit-save-sprint-button"
    >
      <i
        *ngIf="isUploading"
        class="fa fa-spinner fa-spin connection_spinner"
        aria-hidden="true"
      ></i>
      <span>{{ isUploading ? '' : rightBtnLabel }}</span>
    </button>
  </div>
</div>
