export class LaneBoardGetById {
  static readonly type = '[LaneBoard] Get By Id';
  constructor(public payload) {}
}

export class LaneBoardCreate {
  static readonly type = '[LaneBoard] Create';
  constructor(public payload) {}
}

export class LaneBoardLocalUpdate {
  static readonly type = '[LaneBoard] Local Update';
  constructor(public payload) {}
}

export class LaneBoardUpdate {
  static readonly type = '[LaneBoard] Update';
  constructor(
    public payload,
    public needToPublish = false,
  ) {}
}

export class LaneBoardUpdateLocal {
  static readonly type = '[LaneBoard] Local Update';
  constructor(public payload) {}
}

export class LaneBoardColumnDelete {
  static readonly type = '[LaneBoard Column] Delete';
  constructor(public payload) {}
}

export class LaneBoardColumnUpdate {
  static readonly type = '[LaneBoard Column] Update';
  constructor(public payload) {}
}

export class LaneBoardColumnUpdateOrders {
  static readonly type = '[LaneBoard Column] Update Orders';
  constructor(public payload) {}
}

export class LaneBoardSocketUpdate {
  static readonly type = '[LaneBoard] Socket Update';
  constructor(public payload) {}
}

export class LaneBoardColumnLocalUpdate {
  static readonly type = '[LaneBoard Column] Local Update';
  constructor(public payload) {}
}

export class LaneBoardColumnCreate {
  static readonly type = '[LaneBoard Column] Create';
  constructor() {}
}

export class LaneBoardCardCreate {
  static readonly type = '[LaneBoard Card] Create';
  constructor(public payload) {}
}

export class LaneBoardCardUpdate {
  static readonly type = '[LaneBoard Card] Update';
  constructor(public payload) {}
}

export class LaneBoardCardVoteUpdate {
  static readonly type = '[LaneBoard Card] Vote Update';
  constructor(public payload) {}
}

export class LaneBoardCardLocalUpdate {
  static readonly type = '[LaneBoard Card] Local Update';
  constructor(public payload) {}
}

export class LaneBoardCardRebuildColors {
  static readonly type = '[LaneBoard Card] Rebuild colors';
  constructor() {}
}

export class LaneBoardCardLocalUpdateOrder {
  static readonly type = '[LaneBoard Card] Local Update Order';
  constructor(public payload) {}
}

export class LaneBoardCardDelete {
  static readonly type = '[LaneBoard Card] Delete';
  constructor(public payload) {}
}
