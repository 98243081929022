<div class="chat-message-forward" *transloco="let t; read: 'modals.shared-message'">
  <div class="modal-header">
    <h5 class="modal-title">{{ t('title') }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
    </button>
  </div>
  <div
    class="modal-body"
    [ngClass]="{
      'modal-light': config?.layout.variant === 'Light',
      'modal-dark': config?.layout.variant === 'Dark'
    }"
  >
    <div class="form-group">
      <ng-autocomplete
        t-id="chat-forward-modal-member"
        #autocomplete
        [data]="membersChat"
        [searchKeyword]="searchKeyword"
        [placeholder]="t('placeholder-user')"
        notFoundText="No results found."
        [focusFirst]="true"
        [itemTemplate]="itemTemplate"
        [notFoundTemplate]="notFoundTemplate"
        [(ngModel)]="selectedRecipient"
      ></ng-autocomplete>
    </div>

    <quill-editor
      t-id="chat-forward-modal-input"
      class="content-editor"
      bounds="self"
      [placeholder]="t('placeholder-quill')"
      [modules]="editorModules"
      [(ngModel)]="comment"
      (onEditorCreated)="editorCreated($event)"
    ></quill-editor>

    <blockquote>
      <div class="chat-avatar">
        <app-avatar [userId]="message.userId"></app-avatar>
      </div>
      <div class="chat-body">
        <div class="chat-content">
          <!--MESSAGE DETAILS-->
          <div class="message-details">
            <span
              class="message-username"
              [ngClass]="{
                'message-username-light': config?.layout.variant === 'Light',
                'message-username-dark': config?.layout.variant === 'Dark'
              }"
              >{{ message.userName }}</span
            >
            <span
              *ngIf="message.created_at !== ''"
              class="message-date ml-1"
              [ngClass]="{
                'message-date-light': config?.layout.variant === 'Light',
                'message-date-dark': config?.layout.variant === 'Dark'
              }"
              >{{ message.created_at | date: 'dd.MM.yy - HH:mm' : tz }}</span
            >
          </div>
          <app-chat-message-content
            class="chat-message-content"
            [platform]="platform"
            [message]="message"
            [mentionChatMembers]="mentionChatMembers"
            context="forward-message"
          >
          </app-chat-message-content>
        </div>
      </div>
    </blockquote>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-subtle" (click)="close()">
      {{ t('btn-cancel') }}
    </button>
    <button
      type="button"
      class="btn btn-solid share-btn"
      (click)="sendMessage()"
      t-id="chat-forward-modal-share-action"
    >
      {{ t('btn-share') }}
    </button>
  </div>
</div>

<ng-template #itemTemplate let-item>
  <div class="d-flex align-items-center p-1">
    <app-avatar [userId]="item._id"></app-avatar>
    <span class="ml-2" [innerHTML]="item.userName"></span>
  </div>
</ng-template>

<ng-template #notFoundTemplate let-notFound>
  <div [innerHTML]="notFound"></div>
</ng-template>
