import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

import { BoardsState } from '../store/states/boards.state';
import { TicketsGetList, TicketsUpdateOrders } from '../store/actions/board.action';

import { TicketService } from './ticket.service';

import { TicketsDbDto } from '../../api/models/tickets-db-dto';
import { BacklogTicket, BoardTicket } from '../../pages/full-pages/board/board-tickets.types';
import { Injectable } from '@angular/core';
import { PriorityEnum } from '../../pages/full-pages/board/kanban-board/enums/priority.enum';
import { take } from 'rxjs/operators';
import { SelectorEnum } from '../../pages/full-pages/board/board-tickets.enums';

type Ticket = BoardTicket | BacklogTicket;

enum OrderSelector {
  Board = 'order',
  Backlog = 'orderInBacklog',
}

export interface IRecountOptions {
  isChangedColumn?: boolean;
  isChangedPriority?: boolean;
  isMoveTicketToBoardOrBacklog?: boolean;
  isCreateTicket?: boolean;
  isDebug?: boolean;
  moveTo?: 'top' | 'bottom';
  withDnd?: {
    inBoard?: boolean;
    inBacklog?: boolean;
    isChangeColumn?: boolean;
    isTicketsFiltered?: boolean;
  };
}

export interface IRecountTickets {
  next: Array<Ticket>;
  prev: Array<Ticket>;
  backlogNext: Array<Ticket>;
  backlogPrev: Array<Ticket>;
}

@Injectable({ providedIn: 'root' })
export class PriorityOrderService {
  constructor(
    private ticketService: TicketService,
    private store: Store,
  ) {}

  private getLastTicketFromFirstPriorityGroup(
    priority: number,
    tickets: Ticket[],
    orderSelector: OrderSelector,
  ): BoardTicket | BacklogTicket | null {
    return tickets.reduce((acc, ticket) => {
      if (ticket.priority === priority) {
        if (!acc) {
          acc = ticket;
        } else {
          if (acc[orderSelector] === ticket[orderSelector] - 1) {
            acc = ticket;
          }
        }
      }

      return acc;
    }, null as Ticket);
  }

  private detectPriorityOrderToTicketPut(
    priority: number,
    tickets: Ticket[],
    orderSelector: OrderSelector,
  ): number {
    const detectedTicketWithNecessaryPriority = this.getLastTicketFromFirstPriorityGroup(
      priority,
      tickets,
      orderSelector,
    );

    if (detectedTicketWithNecessaryPriority) {
      return detectedTicketWithNecessaryPriority[orderSelector] + 1;
    } else {
      if (priority < PriorityEnum.Highest) {
        return this.detectPriorityOrderToTicketPut(priority + 1, tickets, orderSelector);
      } else {
        return -1;
      }
    }
  }

  private recountTicketsInColumn(
    processTicket: Partial<Ticket>,
    tickets: Ticket[],
    orderSelector: OrderSelector,
    withDnd?: boolean,
  ): Partial<Ticket>[] {
    const detectedTicketOrderWithNecessaryPriority = withDnd
      ? processTicket[orderSelector]
      : this.detectPriorityOrderToTicketPut(processTicket.priority, tickets, orderSelector);

    const prevTicketPosition = tickets.find((ticket) => ticket._id === processTicket._id);

    const needReplaceToTop =
      detectedTicketOrderWithNecessaryPriority <=
      (withDnd ? prevTicketPosition[orderSelector] : processTicket[orderSelector]);

    const processOrders = [
      withDnd ? prevTicketPosition[orderSelector] : processTicket[orderSelector],
      detectedTicketOrderWithNecessaryPriority,
    ];

    const isMinOrder = (ticket: Partial<Ticket>) =>
      needReplaceToTop
        ? ticket[orderSelector] >= Math.min(...processOrders)
        : ticket[orderSelector] > Math.min(...processOrders);

    const isMaxOrder = (ticket: Partial<Ticket>) =>
      ticket[orderSelector] <
      (withDnd && !needReplaceToTop ? Math.max(...processOrders) + 1 : Math.max(...processOrders));

    const recountedTickets = tickets.reduce((acc, ticket) => {
      if (isMinOrder(ticket) && isMaxOrder(ticket)) {
        acc.push({
          ...ticket,
          [orderSelector]: needReplaceToTop ? ticket[orderSelector] + 1 : ticket[orderSelector] - 1,
        });
      }

      return acc;
    }, [] as Partial<Ticket>[]);

    let orderToUpdate = needReplaceToTop
      ? detectedTicketOrderWithNecessaryPriority === -1 ||
        detectedTicketOrderWithNecessaryPriority === 0
        ? detectedTicketOrderWithNecessaryPriority + 1
        : detectedTicketOrderWithNecessaryPriority
      : detectedTicketOrderWithNecessaryPriority - 1;

    orderToUpdate = tickets.length === 1 && !recountedTickets.length ? 0 : orderToUpdate;

    return [
      ...recountedTickets,
      {
        ...processTicket,
        [orderSelector]: withDnd ? detectedTicketOrderWithNecessaryPriority : orderToUpdate,
      },
    ];
  }

  private recountForPreviousColumn(
    ticketToRemove: Partial<Ticket>,
    tickets: Ticket[],
    orderSelector: OrderSelector,
  ) {
    return tickets.reduce((acc, ticket) => {
      if (ticket[orderSelector] > ticketToRemove[orderSelector]) {
        acc.push({ ...ticket, [orderSelector]: ticket[orderSelector] - 1 });
      }

      return acc;
    }, [] as Ticket[]);
  }

  private recountForNextColumn(
    ticketToAdd: Partial<Ticket>,
    tickets: Ticket[],
    orderSelector: OrderSelector,
    withDnd?: boolean,
  ) {
    const detectedTicketOrderWithNecessaryPriority = withDnd
      ? ticketToAdd[orderSelector]
      : this.detectPriorityOrderToTicketPut(ticketToAdd.priority, tickets, orderSelector);

    const recountedTickets = tickets.reduce((acc, ticket) => {
      if (ticket[orderSelector] >= detectedTicketOrderWithNecessaryPriority) {
        acc.push({ ...ticket, [orderSelector]: ticket[orderSelector] + 1 });
      }

      return acc;
    }, [] as Partial<Ticket>[]);

    const orderToUpdate =
      detectedTicketOrderWithNecessaryPriority === -1
        ? 0
        : detectedTicketOrderWithNecessaryPriority;

    recountedTickets.unshift({
      ...ticketToAdd,
      [orderSelector]: orderToUpdate,
    });

    return recountedTickets;
  }

  public async recountTicketOrders(processTicket: Partial<Ticket>, options?: IRecountOptions) {
    const { object, objectId } = processTicket;

    const ticketsSnap: Array<TicketsDbDto> = this.store.selectSnapshot(BoardsState.getTicketsList);
    const ticketsFromService = this.ticketService.getTickets();

    const hasNecessaryTickets = (tickets: Array<Ticket>) =>
      tickets.some((ticket) => ticket.object === object && ticket.objectId === objectId);

    if (!hasNecessaryTickets(ticketsSnap) || !hasNecessaryTickets(ticketsFromService)) {
      this.store.dispatch(
        new TicketsGetList({
          object,
          objectId,
          isArchive: false,
          isInternalState: true,
        }),
      );
      const tickets = await this.ticketService.ticketsForRecount$.pipe(take(2)).toPromise();
      const recountedTickets = await this.handleRecountTickets(tickets, processTicket, options);

      return options.isCreateTicket
        ? recountedTickets?.find((ticket) => !ticket?._id)
        : recountedTickets?.find((ticket) => ticket._id === processTicket._id);
    } else {
      const recountedTickets = await this.handleRecountTickets(
        !hasNecessaryTickets(ticketsSnap) ? ticketsFromService : ticketsSnap,
        processTicket,
        options,
      );

      return options.isCreateTicket
        ? recountedTickets?.find((ticket) => !ticket?._id)
        : recountedTickets?.find((ticket) => ticket._id === processTicket._id);
    }
  }

  private async handleRecountTickets(
    tickets: Array<Ticket>,
    processTicket: Partial<Ticket>,
    options?: IRecountOptions,
  ) {
    const { next, prev, backlogNext, backlogPrev, isNeedToFixOrders } = this.fixOrdersForTickets(
      this.filterTicketsToRecount(tickets, processTicket, options),
    );

    const recount = () => {
      const prevTicketPosition = tickets.find((ticket) => ticket._id === processTicket._id);

      if (options.isChangedPriority) {
        const backlogTickets = this.recountTicketsInColumn(
          processTicket,
          backlogNext,
          OrderSelector.Backlog,
        );
        const boardTickets = this.recountTicketsInColumn(processTicket, next, OrderSelector.Board);

        const ticketsToUpdate = tickets.reduce((acc, ticket) => {
          const recountedBoardTicket = boardTickets.find((t) => t._id === ticket._id);
          const recountedBacklogTicket = backlogTickets.find((t) => t._id === ticket._id);

          if (recountedBoardTicket || recountedBacklogTicket) {
            const ticketToUpdate = { ...ticket };
            if (recountedBoardTicket) {
              ticketToUpdate.order = recountedBoardTicket.order;
            }

            if (recountedBacklogTicket) {
              ticketToUpdate.orderInBacklog = recountedBacklogTicket.orderInBacklog;
            }

            acc.push(ticketToUpdate);
          }

          return acc;
        }, [] as Partial<Ticket>[]);

        if (!options.isDebug) {
          this.store.dispatch(
            new TicketsUpdateOrders({
              ticketIdToUpdate: null,
              object: processTicket.object,
              objectId: processTicket.objectId,
              sprintId: processTicket.sprintId,
              tickets: ticketsToUpdate,
            }),
          );
        }

        return ticketsToUpdate;
      }

      if (options.isChangedColumn) {
        let ticketsToUpdate: Partial<Ticket>[];

        if (options.isMoveTicketToBoardOrBacklog) {
          if (processTicket.columnId !== processTicket.objectId) {
            const prevColumnTickets = this.recountForPreviousColumn(
              processTicket,
              backlogPrev,
              OrderSelector.Backlog,
            );

            const nextBoardTickets = this.recountForNextColumn(
              processTicket,
              next,
              OrderSelector.Board,
            );
            const nextBacklogTickets = this.recountForNextColumn(
              processTicket,
              backlogNext,
              OrderSelector.Backlog,
            );

            const nextColumnTickets = this.mergeBoardWithBacklogTickets(
              tickets,
              nextBoardTickets,
              nextBacklogTickets,
            );

            ticketsToUpdate = [...prevColumnTickets, ...nextColumnTickets];
          } else {
            const prevBoardTickets = this.recountForPreviousColumn(
              processTicket,
              prev,
              OrderSelector.Board,
            );
            const prevBacklogTickets = this.recountForPreviousColumn(
              processTicket,
              backlogPrev,
              OrderSelector.Backlog,
            );

            const prevColumnTickets = this.mergeBoardWithBacklogTickets(
              tickets,
              prevBoardTickets,
              prevBacklogTickets,
            );

            const nextColumnTickets = this.recountForNextColumn(
              processTicket,
              backlogNext,
              OrderSelector.Backlog,
            );

            ticketsToUpdate = [...prevColumnTickets, ...nextColumnTickets];
          }
        } else {
          const prevColumnTickets = this.recountForPreviousColumn(
            processTicket,
            prev,
            OrderSelector.Board,
          );

          const nextColumnTickets = this.recountForNextColumn(
            processTicket,
            next,
            OrderSelector.Board,
          );

          if (prevTicketPosition.priority !== processTicket.priority) {
            const nextBacklogTickets = this.recountTicketsInColumn(
              processTicket,
              backlogNext,
              OrderSelector.Backlog,
            );

            ticketsToUpdate = tickets.reduce((acc, ticket) => {
              const boardTicket = [...prevColumnTickets, ...nextColumnTickets].find(
                (t) => t._id === ticket._id,
              );
              const backlogTicket = nextBacklogTickets.find((t) => t._id === ticket._id);

              if (boardTicket || backlogTicket) {
                const ticketToUpdate = { ...ticket };
                if (boardTicket) {
                  ticketToUpdate.order = boardTicket.order;
                }

                if (backlogTicket) {
                  ticketToUpdate.orderInBacklog = backlogTicket.orderInBacklog;
                }

                acc.push(ticketToUpdate);
              }

              return acc;
            }, [] as Partial<Ticket>[]);
          } else {
            ticketsToUpdate = [...prevColumnTickets, ...nextColumnTickets];
          }
        }

        if (!options.isDebug) {
          this.store.dispatch(
            new TicketsUpdateOrders({
              ticketIdToUpdate: processTicket._id,
              object: processTicket.object,
              objectId: processTicket.objectId,
              sprintId: processTicket.sprintId,
              tickets: ticketsToUpdate,
            }),
          );
        }

        return ticketsToUpdate;
      }

      if (options.isCreateTicket) {
        const nextBoardTickets = this.recountForNextColumn(
          processTicket,
          next,
          OrderSelector.Board,
        );
        const nextBacklogTickets = this.recountForNextColumn(
          processTicket,
          backlogNext,
          OrderSelector.Backlog,
        );

        const nextColumnTickets = this.mergeBoardWithBacklogTickets(
          tickets,
          nextBoardTickets,
          nextBacklogTickets,
        );

        nextColumnTickets.unshift({
          ...processTicket,
          order: nextBoardTickets.find((t) => !t._id).order,
          orderInBacklog: nextBacklogTickets.find((t) => !t._id).orderInBacklog,
        });

        if (!options.isDebug) {
          this.store.dispatch(
            new TicketsUpdateOrders({
              ticketIdToUpdate: processTicket._id,
              object: processTicket.object,
              objectId: processTicket.objectId,
              sprintId: processTicket.sprintId,
              tickets: nextColumnTickets.filter((t) => t._id),
            }),
          );
        }

        return nextColumnTickets;
      }

      if (options.moveTo) {
        let recountedTickets: Partial<Ticket>[] = [];

        switch (options.moveTo) {
          case 'top':
            {
              recountedTickets = next.reduce((acc, ticket) => {
                if (ticket.order >= 0 && ticket.order < processTicket.order) {
                  acc.push({ ...ticket, order: ticket.order + 1 });
                }

                return acc;
              }, [] as Partial<Ticket>[]);

              recountedTickets.unshift({ ...processTicket, order: 0 });
            }
            break;

          case 'bottom':
            {
              recountedTickets = next.reduce((acc, ticket) => {
                if (ticket.order > processTicket.order) {
                  acc.push({ ...ticket, order: ticket.order - 1 });
                }
                return acc;
              }, [] as Partial<Ticket>[]);

              recountedTickets.push({
                ...processTicket,
                order: next.length - 1,
              });
            }
            break;
        }

        if (!options.isDebug) {
          this.store.dispatch(
            new TicketsUpdateOrders({
              ticketIdToUpdate: processTicket._id,
              object: processTicket.object,
              objectId: processTicket.objectId,
              sprintId: processTicket.sprintId,
              tickets: recountedTickets.filter((t) => t._id),
            }),
          );
        }

        return recountedTickets;
      }

      if (options.withDnd) {
        let recountedTickets: Partial<Ticket>[] = [];

        if (options.withDnd.inBoard) {
          if (options.withDnd.isChangeColumn) {
            const nextColumnTickets = this.recountForNextColumn(
              processTicket,
              next,
              OrderSelector.Board,
              !options.withDnd.isTicketsFiltered,
            );
            const prevColumnTickets = this.recountForPreviousColumn(
              prevTicketPosition,
              prev,
              OrderSelector.Board,
            );

            recountedTickets = [...prevColumnTickets, ...nextColumnTickets];
          } else {
            recountedTickets = !options.withDnd.isTicketsFiltered
              ? this.recountTicketsInColumn(
                  processTicket,
                  next,
                  OrderSelector.Board,
                  !options.withDnd.isTicketsFiltered,
                )
              : [];
          }
        }

        if (options.withDnd.inBacklog) {
          if (options.withDnd.isChangeColumn) {
            const nextBacklogTickets = this.recountForNextColumn(
              processTicket,
              backlogNext,
              OrderSelector.Backlog,
              !options.withDnd.isTicketsFiltered,
            );
            const nextBoardTickets = this.recountForNextColumn(
              processTicket,
              next,
              OrderSelector.Board,
            );

            const prevBoardTickets = this.recountForPreviousColumn(
              prevTicketPosition,
              prev,
              OrderSelector.Board,
            );
            const prevBacklogTickets = this.recountForPreviousColumn(
              prevTicketPosition,
              backlogPrev,
              OrderSelector.Backlog,
            );

            recountedTickets = tickets.reduce((acc, ticket) => {
              const nextBoardTicket = nextBoardTickets.find((t) => t._id === ticket._id);
              const nextBacklogTicket = nextBacklogTickets.find((t) => t._id === ticket._id);

              const prevBoardTicket = prevBoardTickets.find((t) => t._id === ticket._id);
              const prevBacklogTicket = prevBacklogTickets.find((t) => t._id === ticket._id);

              if (nextBoardTicket || nextBacklogTicket || prevBoardTicket || prevBacklogTicket) {
                const ticketToUpdate = { ...ticket };

                if (nextBoardTicket) {
                  ticketToUpdate.order = nextBoardTicket.order;
                }

                if (nextBacklogTicket) {
                  ticketToUpdate.orderInBacklog = nextBacklogTicket.orderInBacklog;
                }

                if (prevBoardTicket) {
                  ticketToUpdate.order = prevBoardTicket.order;
                }

                if (prevBacklogTicket) {
                  ticketToUpdate.orderInBacklog = prevBacklogTicket.orderInBacklog;
                }

                if (nextBoardTicket || nextBacklogTicket) {
                  if (ticket._id === processTicket._id) {
                    ticketToUpdate.columnId = processTicket.columnId;
                    ticketToUpdate.sprintId = processTicket.sprintId;
                  }
                }

                acc.push(ticketToUpdate);
              }

              return acc;
            }, [] as Partial<Ticket>[]);
          } else {
            recountedTickets = !options.withDnd.isTicketsFiltered
              ? this.recountTicketsInColumn(
                  processTicket,
                  backlogNext,
                  OrderSelector.Backlog,
                  !options.withDnd.isTicketsFiltered,
                )
              : [];
          }
        }

        if (!options.isDebug) {
          this.store.dispatch(
            new TicketsUpdateOrders({
              ticketIdToUpdate: options.withDnd.isChangeColumn ? processTicket._id : null,
              object: processTicket.object,
              objectId: processTicket.objectId,
              sprintId: processTicket.sprintId,
              tickets: recountedTickets.filter((t) => t._id),
            }),
          );
        }

        return recountedTickets;
      }
    };

    if (isNeedToFixOrders) {
      const fixedTickets = tickets.reduce((acc, ticket) => {
        const nextTicket = next.find((t) => t._id === ticket._id);
        const prevTicket = prev.find((t) => t._id === ticket._id);
        const backlogNextTicket = backlogNext.find((t) => t._id === ticket._id);
        const backlogPrevTicket = backlogPrev.find((t) => t._id === ticket._id);

        const ticketToFix = { ...ticket };

        if (nextTicket || prevTicket || backlogNextTicket || backlogPrevTicket) {
          if (nextTicket) {
            ticketToFix.order = nextTicket.order;
          }

          if (prevTicket) {
            ticketToFix.order = prevTicket.order;
          }

          if (backlogNextTicket) {
            ticketToFix.orderInBacklog = backlogNextTicket.orderInBacklog;
          }

          if (backlogPrevTicket) {
            ticketToFix.orderInBacklog = backlogPrevTicket.orderInBacklog;
          }

          acc.push(ticketToFix);
        }

        return acc;
      }, [] as Ticket[]);

      if (!options.isDebug) {
        await this.store
          .dispatch(
            new TicketsUpdateOrders({
              ticketIdToUpdate: null,
              object: processTicket.object,
              objectId: processTicket.objectId,
              sprintId: processTicket.sprintId,
              tickets: fixedTickets,
            }),
          )
          .toPromise();
      }

      return recount();
    } else {
      return recount();
    }
  }

  private mergeBoardWithBacklogTickets(
    tickets: Partial<Ticket>[],
    boardTickets: Partial<Ticket>[],
    backlogTickets: Partial<Ticket>[],
  ) {
    return tickets.reduce((acc, ticket) => {
      const boardTicket = boardTickets.find((t) => t._id === ticket._id);
      const backlogTicket = backlogTickets.find((t) => t._id === ticket._id);

      const ticketToUpdate = { ...ticket };

      if (boardTicket) {
        ticketToUpdate.order = boardTicket.order;
      }

      if (backlogTicket) {
        ticketToUpdate.orderInBacklog = backlogTicket.orderInBacklog;
      }

      if (boardTicket || backlogTicket) {
        acc.push(ticketToUpdate);
      }

      return acc;
    }, [] as Partial<Ticket>[]);
  }

  private sortTickets(tickets: Ticket[], selector: OrderSelector) {
    return tickets.sort((a, b) => a[selector] - b[selector]);
  }

  private fixOrdersForTickets(tickets: IRecountTickets) {
    let ticketsWithFixedOrders: IRecountTickets = {
      next: [],
      prev: [],
      backlogNext: [],
      backlogPrev: [],
    };

    if (!this.isTicketsInOrder(tickets.next, SelectorEnum.Order)) {
      ticketsWithFixedOrders.next = tickets.next.map((ticket, index) => ({
        ...ticket,
        [SelectorEnum.Order]: index,
      }));
    }

    if (tickets?.prev?.length && !this.isTicketsInOrder(tickets.prev, SelectorEnum.Order)) {
      ticketsWithFixedOrders.prev = tickets.prev.map((ticket, index) => ({
        ...ticket,
        [SelectorEnum.Order]: index,
      }));
    }

    if (
      tickets?.backlogNext?.length &&
      !this.isTicketsInOrder(tickets.backlogNext, SelectorEnum.OrderInBacklog)
    ) {
      ticketsWithFixedOrders.backlogNext = tickets.backlogNext.map((ticket, index) => ({
        ...ticket,
        [SelectorEnum.OrderInBacklog]: index,
      }));
    }

    if (
      tickets?.backlogPrev?.length &&
      !this.isTicketsInOrder(tickets.backlogPrev, SelectorEnum.OrderInBacklog)
    ) {
      ticketsWithFixedOrders.backlogPrev = tickets.backlogPrev.map((ticket, index) => ({
        ...ticket,
        [SelectorEnum.OrderInBacklog]: index,
      }));
    }

    return {
      next: this.sortTickets(
        ticketsWithFixedOrders.next.length ? ticketsWithFixedOrders.next : tickets.next,
        OrderSelector.Board,
      ),
      prev: this.sortTickets(
        ticketsWithFixedOrders.prev.length ? ticketsWithFixedOrders.prev : tickets.prev,
        OrderSelector.Board,
      ),
      backlogNext: this.sortTickets(
        ticketsWithFixedOrders.backlogNext.length
          ? ticketsWithFixedOrders.backlogNext
          : tickets.backlogNext,
        OrderSelector.Backlog,
      ),
      backlogPrev: this.sortTickets(
        ticketsWithFixedOrders.backlogPrev.length
          ? ticketsWithFixedOrders.backlogPrev
          : tickets.backlogPrev,
        OrderSelector.Backlog,
      ),
      isNeedToFixOrders:
        ticketsWithFixedOrders.next.length ||
        ticketsWithFixedOrders.prev.length ||
        ticketsWithFixedOrders.backlogNext.length ||
        ticketsWithFixedOrders.backlogPrev.length,
    };
  }

  private filterTicketsToRecount(
    tickets: Array<Ticket>,
    processTicket: Partial<Ticket>,
    options?: IRecountOptions,
  ): IRecountTickets {
    const prevTicketPosition = tickets.find((ticket) => ticket._id === processTicket._id);
    const archiveColumnId = this.store
      .selectSnapshot(BoardsState.getColumnsList)
      .find((item) => item.boardColorClass === 'status-archive')?._id;

    const isSprintOrBoard = (ticket: Ticket, ticketPosition: Partial<Ticket>) =>
      ((ticketPosition?.sprintId && ticket?.sprintId === ticketPosition?.sprintId) ||
        (!ticketPosition?.sprintId && !ticket?.sprintId)) &&
      ticket.columnId !== ticket.objectId &&
      ticket.columnId !== archiveColumnId &&
      !ticket.isEpic &&
      !ticket.isDeleted;

    const isNextColumn = (ticket: Ticket) => ticket.columnId === processTicket.columnId;
    const isPrevColumn = (ticket: Ticket) => ticket.columnId === prevTicketPosition?.columnId;
    const isBacklog = (ticket: Ticket) => ticket.columnId === processTicket.objectId;

    let ticketsToRecount: IRecountTickets = {
      next: [],
      prev: [],
      backlogNext: [],
      backlogPrev: [],
    };

    if (options.isChangedColumn || options.isCreateTicket || options.moveTo) {
      if (options.isMoveTicketToBoardOrBacklog) {
        ticketsToRecount = tickets.reduce(
          (acc, ticket) => {
            // If previous ticket position was backlog
            if (processTicket.columnId !== processTicket.objectId) {
              if (isSprintOrBoard(ticket, processTicket) && isNextColumn(ticket)) {
                acc.next.push(ticket);
              }

              if (isSprintOrBoard(ticket, processTicket)) {
                acc.backlogNext.push(ticket);
              }

              if (isBacklog(ticket)) {
                acc.backlogPrev.push(ticket);
              }
            } else {
              // If previous ticket position was board

              if (isBacklog(ticket)) {
                acc.backlogNext.push(ticket);
              }

              if (isSprintOrBoard(ticket, processTicket)) {
                acc.backlogPrev.push(ticket);
              }

              if (isSprintOrBoard(ticket, processTicket) && isNextColumn(ticket)) {
                acc.prev.push(ticket);
              }
            }

            return acc;
          },
          {
            next: [],
            prev: [],
            backlogNext: [],
            backlogPrev: [],
          } as IRecountTickets,
        );
      } else {
        ticketsToRecount = tickets.reduce(
          (acc, ticket) => {
            if (isNextColumn(ticket) && isSprintOrBoard(ticket, processTicket)) {
              acc.next.push(ticket);
            }

            if (
              options.isChangedColumn &&
              isPrevColumn(ticket) &&
              isSprintOrBoard(ticket, processTicket)
            ) {
              acc.prev.push(ticket);
            }

            if (
              !options.moveTo &&
              (processTicket.columnId !== processTicket.objectId
                ? isSprintOrBoard(ticket, processTicket)
                : isBacklog(ticket))
            ) {
              acc.backlogNext.push(ticket);
            }

            return acc;
          },
          {
            next: [],
            prev: [],
            backlogNext: [],
            backlogPrev: [],
          } as IRecountTickets,
        );
      }
    }

    if (options.isChangedPriority) {
      ticketsToRecount = tickets.reduce(
        (acc, ticket) => {
          if (isNextColumn(ticket) && isSprintOrBoard(ticket, processTicket)) {
            acc.next.push(ticket);
          }

          if (
            processTicket.columnId !== processTicket.objectId
              ? isSprintOrBoard(ticket, processTicket)
              : isBacklog(ticket)
          ) {
            acc.backlogNext.push(ticket);
          }

          return acc;
        },
        {
          next: [],
          prev: [],
          backlogNext: [],
          backlogPrev: [],
        } as IRecountTickets,
      );
    }

    if (options.withDnd) {
      if (options.withDnd.inBoard) {
        ticketsToRecount = tickets.reduce(
          (acc, ticket) => {
            if (isNextColumn(ticket) && isSprintOrBoard(ticket, processTicket)) {
              acc.next.push(ticket);
            }

            if (
              options.withDnd.isChangeColumn &&
              isPrevColumn(ticket) &&
              isSprintOrBoard(ticket, processTicket)
            ) {
              acc.prev.push(ticket);
            }

            return acc;
          },
          {
            next: [],
            prev: [],
            backlogNext: [],
            backlogPrev: [],
          } as IRecountTickets,
        );
      }

      if (options.withDnd.inBacklog) {
        ticketsToRecount = tickets.reduce(
          (acc, ticket) => {
            if (processTicket.columnId === processTicket.objectId) {
              if (isBacklog(ticket)) {
                acc.backlogNext.push(ticket);
              }
            } else {
              if (isSprintOrBoard(ticket, processTicket)) {
                acc.backlogNext.push(ticket);

                if (options.withDnd.isChangeColumn && isNextColumn(ticket)) {
                  acc.next.push(ticket);
                }
              }
            }

            if (options.withDnd.isChangeColumn) {
              if (prevTicketPosition.columnId === processTicket.objectId) {
                if (isBacklog(ticket)) {
                  acc.backlogPrev.push(ticket);
                }
              } else {
                if (isSprintOrBoard(ticket, prevTicketPosition)) {
                  acc.backlogPrev.push(ticket);

                  if (isPrevColumn(ticket)) {
                    acc.prev.push(ticket);
                  }
                }
              }
            }

            return acc;
          },
          {
            next: [],
            prev: [],
            backlogNext: [],
            backlogPrev: [],
          } as IRecountTickets,
        );
      }
    }

    return {
      prev: this.sortTickets(ticketsToRecount.prev, OrderSelector.Board),
      next: this.sortTickets(ticketsToRecount.next, OrderSelector.Board),
      backlogPrev: this.sortTickets(ticketsToRecount.backlogPrev, OrderSelector.Backlog),
      backlogNext: this.sortTickets(ticketsToRecount.backlogNext, OrderSelector.Backlog),
    };
  }

  private isTicketsInOrder(tickets: Array<BoardTicket | BacklogTicket>, selector: SelectorEnum) {
    const ticketsToCheck = tickets.sort((a, b) => a[selector] - b[selector]);
    for (let i = 0; i < ticketsToCheck.length - 1; i++) {
      // Compare current element with the next element
      if (ticketsToCheck[i][selector] + 1 !== ticketsToCheck[i + 1][selector]) {
        return false;
      }
    }

    return true;
  }
}
