import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { WikiPagesService } from '../../../api/services/wiki-pages.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize, map, take, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { AuthState } from '../../../shared/store/states/auth.state';
import { LocalStorageService } from 'ngx-localstorage';
import {
  ThreadCreateWiki,
  ThreadsSocketNewMessage,
} from '../../../shared/store/actions/threads.action';
import {
  ChatsOpenThreadSidebar,
  ThreadGetMessages,
} from '../../../shared/store/actions/chats.action';
import { SpaceGetUsersList } from '../../../shared/store/actions/spaces.action';
import { ProjectGetUsersList } from '../../../shared/store/actions/projects.action';
import { ThreadsState } from '../../../shared/store/states/threads.state';
import { WikiMobileModalComponent } from '../../../modals/wiki-mobile-modal/wiki-mobile-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SocketsService } from '../../../shared/services/sockets.service';
import { WrappedSocket } from 'ngx-socket-io/src/socket-io.service';

// Interfaces
import { IWikiGet, IWikiUpdate } from '../../../shared/interfaces/wiki';
import { WikiPageDbDto } from '../../../api/models/wiki-page-db-dto';
import { ObjectEnum } from '../../../shared/enums/object.enum';

// Helpers
import { filterWithSub } from '../../../shared/utils/filter-sub-helper';
import { RouterTenantPipe } from '../../../shared/pipes/router-tenant.pipe';
import { HtmlHelper } from '../../../shared/utils/html-helper';
import { deContent, getStarted } from './wiki.constants';
import { WikiPageOrderUpdateReqDto } from '../../../api/models/wiki-page-order-update-req-dto';
import { ConfirmAlert } from '../../../shared/alerts/alerts';
import { EditorService } from '../../../shared/components/editor/editor.service';
import { RecordService } from '../../../shared/services/record.service';
import { TranslocoService } from '@ngneat/transloco';
import { LocalStorageKeys } from '../../../types/local-storage-keys.enum';
import { LanguageEnum } from '../../../shared/enums/language.enum';

@UntilDestroy({ checkProperties: true })
@Injectable({ providedIn: 'root' })
export class WikiService {
  public object: string;
  public objectId: string;
  public userId: string;
  public isMobile = false;
  public isLoadingThreadCreate = false;
  public isNavigate = true;
  public socket: WrappedSocket;
  public totalCount = 0;
  public mentionThreadMembers: any[];

  public heightKeyBoard = new Subject();
  public clearContent = new Subject();
  public handleOpenThread: BehaviorSubject<boolean | string> = new BehaviorSubject(false);
  public createPage$ = new BehaviorSubject(null);
  public usersEdit: BehaviorSubject<string[]> = new BehaviorSubject([]);

  public wikiIdActive = new BehaviorSubject<string>('');
  public isLoadingInit = new BehaviorSubject(true);
  public message = new BehaviorSubject(null);

  public activeWiki: BehaviorSubject<WikiPageDbDto> = new BehaviorSubject(null);
  public activeWiki$ = this.activeWiki.asObservable();

  public isEditMode = new BehaviorSubject(false);
  public isEditMode$ = this.isEditMode.asObservable();

  private isChanged = new BehaviorSubject(false);
  public isChanged$ = this.isChanged.asObservable();

  private isSaveOrUpdate = new BehaviorSubject(false);
  public isSaveOrUpdate$ = this.isSaveOrUpdate.asObservable();

  public sidebarScrolled = new Subject<boolean>();

  private wikis: BehaviorSubject<WikiPageDbDto[]> = new BehaviorSubject([]);
  private env: any;

  constructor(
    private wikiApiService: WikiPagesService,
    private htmlHelper: HtmlHelper,
    private toast: ToastrService,
    private store: Store,
    private router: Router,
    private actions: Actions,
    private routerTenantPipe: RouterTenantPipe,
    private editorService: EditorService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private socketsService: SocketsService,
    private recordService: RecordService,
    private translocoService: TranslocoService,
  ) {
    this.socket = this.socketsService.get();
  }

  // Getters
  public get getWikis(): BehaviorSubject<WikiPageDbDto[]> {
    return this.wikis;
  }

  public get hasUnsavedChanges() {
    return this.isChanged.value;
  }

  // Setters
  public setWikis(wikis) {
    this.wikis.next(wikis);
  }

  public set hasUnsavedChanges(isChanged) {
    this.isChanged.next(isChanged);
  }

  // Methods
  public getSearchPages(text: string) {
    return filterWithSub(this.getWikis.value, text);
  }

  public isGetStarted(wiki) {
    return wiki._id === 'get-started';
  }

  public setEditMode(value?): void {
    this.isEditMode.next(value !== undefined ? value : !this.isEditMode.value);

    if (this.isEditMode.value) {
      this.setSocket();
    }
  }

  public initializeWiki(props: IWikiGet): void {
    // Set data
    this.object = props.object;
    this.objectId = props.objectId;
    this.userId = this.localStorageService.get('userId');
    this.wikiIdActive.next('');

    // Set env
    this.env = this.store.selectSnapshot(AuthState.getEnv);

    // Get list wikis
    this.isLoadingInit.next(true);

    this.wikiApiService
      .wikiPagesGetList(props)
      .pipe(
        tap((wikis) => {
          if (wikis.length) {
            this.setWikis(wikis);
          } else {
            const lang = this.localStorageService.get(LocalStorageKeys.language);
            const initGetStarted = {
              ...getStarted,
              title: this.translocoService.translate('wiki.get-started-title'),
            };

            if (lang && lang === LanguageEnum.Germany) {
              initGetStarted.content = deContent;
            }

            this.setWikis([initGetStarted]);
          }

          if (!this.isMobile && this.isNavigate) {
            this.setActiveWiki(this.getWikis.value[0]).pipe(untilDestroyed(this)).subscribe();
          }
        }),
        untilDestroyed(this),
        finalize(() => this.isLoadingInit.next(false)),
      )
      .subscribe();

    // Set userMembers
    switch (this.object) {
      case 'spaces':
        this.store
          .dispatch(new SpaceGetUsersList({ id: this.objectId, exists: true }))
          .pipe(untilDestroyed(this))
          .subscribe((res) => this.getThreadMentions(res.Spaces.users));
        break;
      case 'projects':
        this.store
          .dispatch(new ProjectGetUsersList({ id: this.objectId, exists: true }))
          .pipe(untilDestroyed(this))
          .subscribe((res) => this.getThreadMentions(res.Projects.users));
        break;
    }

    // Thread subscribe
    this.actions
      .pipe(untilDestroyed(this), ofActionDispatched(ThreadsSocketNewMessage))
      .subscribe(({ payload }) => {
        if (!this.message.value || !this.activeWiki.value?.chatMessage?.length) {
          this.message.next(payload.message);
        }
      });

    this.actions
      .pipe(untilDestroyed(this), ofActionDispatched(ThreadCreateWiki), take(1))
      .subscribe((res) => {
        if (res.payload.error.status === 404) {
          const data = {
            object: this.object,
            objectId: this.objectId,
            title: this.activeWiki.value.title || this.translocoService.translate('wiki.new-doc'),
            content: this.activeWiki.value.content,
          };

          this.createNew(data);
        }
      });

    this.store
      .select(ThreadsState.getThreadById)
      .pipe(
        untilDestroyed(this),
        map((filterFn) => filterFn(this.message.value && this.message.value.threadId)),
      )
      .subscribe((value: any) => {
        this.totalCount = value ? value.totalCount : 0;
      });

    // Thread open
    this.store.dispatch(new ChatsOpenThreadSidebar(true));
  }

  public copyTicketLink() {
    if (this.activeWiki.value) {
      const wiki = this.activeWiki.value;
      const host =
        `http${this.env.ssl ? 's' : ''}://` +
        (this.env.main_host || this.env.base_host !== 'localhost:4200'
          ? `${wiki.tenantName}.${this.env.main_host}`
          : `${this.env.base_host}/${wiki.tenantName}`);
      const ticketUrl = `${host}/${wiki.object.slice(0, -1)}/${wiki.objectId}/wiki/${wiki._id}`;

      this.htmlHelper.copyValueToBuffer(ticketUrl);
      if (!this.isMobile) {
        this.toast.success(
          this.translocoService.translate('toastr.message-link-copied'),
          this.translocoService.translate('toastr.title-success'),
        );
      }
    }
  }

  public removeSocket(): void {
    if (this.activeWiki.value?._id) {
      const data = {
        _id: this.activeWiki.value._id,
        object: this.object,
        objectId: this.objectId,
      };

      this.socket.emit('wiki-pages:removePageEditor', data);
    }
  }

  public setSocket(): void {
    if (this.activeWiki.value?._id) {
      const data = {
        _id: this.activeWiki.value._id,
        object: this.object,
        objectId: this.objectId,
      };
      this.socket.emit('wiki-pages:setPageEditor', data);
    }
  }

  public subscribeSocket(): void {
    this.socket.on('wiki-pages:getPageEditors:response', (event: any) => {
      if (this.activeWiki.value._id && this.activeWiki.value._id === event.wikiPageId) {
        this.usersEdit.next([...event.usersIds]);
      }
    });
  }

  // API
  public createPage(wiki: WikiPageDbDto): void {
    this.wikiApiService
      .wikiPagesCreate({
        body: {
          object: this.object,
          objectId: this.objectId,
          parentId: wiki._id,
          title: 'New page',
        },
      })
      .pipe(
        tap((page) => {
          this.toast.success(
            this.translocoService.translate('toastr.message-wiki-page-added'),
            this.translocoService.translate('toastr.title-success'),
          );
          this.addPage(page);
          this.createPage$.next({ ...page, isCreate: true });

          if (!this.isMobile) {
            if (this.hasUnsavedChanges && this.isEditMode.value) {
              this.changeWiki(page);
            } else {
              this.setActiveWiki(page).pipe(untilDestroyed(this)).subscribe();
            }
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  public createDeletedData(body): void {
    const data = { body };
    const parentId = this.activeWiki.value.parentId;

    if (parentId) {
      data.body.parentId = parentId;
    }

    this.wikiApiService
      .wikiPagesCreate(data)
      .pipe(
        tap((result) => {
          if (result.parentId) {
            this.toast.success(
              this.translocoService.translate('toastr.message-wiki-page-added'),
              this.translocoService.translate('toastr.title-success'),
            );
            this.addPage(result);
            this.createPage$.next(result);
          } else {
            this.toast.success(
              this.translocoService.translate('toastr.message-wiki-document-added'),
              this.translocoService.translate('toastr.title-success'),
            );
            this.addToWikis(result);
          }
          this.hasUnsavedChanges = false;
          this.setActiveWiki(result).pipe(untilDestroyed(this)).subscribe();
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  public deleteWiki(wikiDelete: WikiPageDbDto): Observable<any> {
    return this.wikiApiService
      .wikiPagesDelete({
        object: this.object,
        objectId: this.objectId,
        id: wikiDelete._id,
      })
      .pipe(
        tap(
          (result) => {
            if (result.success) {
              const message = wikiDelete.parentId
                ? this.translocoService.translate('toastr.message-wiki-page-deleted')
                : this.translocoService.translate('toastr.message-wiki-document-deleted');
              this.toast.success(message, this.translocoService.translate('toastr.title-success'));
              this.removeFromWikis(wikiDelete);
            }
          },
          (err) => {
            if (err.status === 404) {
              this.deletedWiki(wikiDelete, false);
            }
          },
        ),
        untilDestroyed(this),
      );
  }

  public updateWiki(props: IWikiUpdate, setActiveWiki = true): Observable<any> {
    return this.wikiApiService.wikiPagesUpdate(props).pipe(
      tap(
        (result) => {
          this.toast.success(
            this.translocoService.translate('toastr.message-wiki-updated'),
            this.translocoService.translate('toastr.title-success'),
          );
          this.updateApiWiki(props, result);

          if (this.activeWiki.value && this.activeWiki.value._id === result._id && setActiveWiki) {
            this.activeWiki.next(result);
          }
        },
        (err) => {
          if (err.status === 404) {
            this.createNew(props.body);
          }
        },
      ),
      untilDestroyed(this),
    );
  }

  public updateOrder(data: WikiPageOrderUpdateReqDto[], currentItem: string) {
    this.wikiApiService
      .wikiPagesOrderUpdate({
        body: { actionItemId: currentItem, items: data },
      })
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  public setActiveWiki(wiki, openModal = true) {
    // Set data
    this.usersEdit.next([]);
    this.isEditMode.next(false);
    this.wikiIdActive.next(wiki._id || null);

    // Get started page
    if (this.isGetStarted(wiki)) {
      this.initWiki(wiki);

      if (this.isMobile) {
        this.openEditorModal();
      }

      return of(null);
    }

    // Socket
    this.removeSocket();
    this.socket.removeListener('wiki-pages:getPageEditors:response');

    // Init page
    return this.wikiApiService
      .wikiPagesGetOne({
        id: wiki._id,
        object: this.object,
        objectId: this.objectId,
      })
      .pipe(
        tap(
          (result: WikiPageDbDto | any) => {
            this.recordService.wikiRecordData = result;
            this.subscribeSocket();
            this.initWiki(result);
            this.threadInit();

            if (this.isMobile && openModal) {
              this.openEditorModal();
            }

            if (result.parentId) {
              this.createPage$.next(result);
            }
          },
          (error) => {
            if (error.status === 404) {
              this.deletedWiki(wiki);
            }
          },
        ),
        untilDestroyed(this),
      );
  }

  public createWiki(): Observable<any> {
    return this.wikiApiService
      .wikiPagesCreate({
        body: {
          object: this.object,
          objectId: this.objectId,
          title: 'New doc',
        },
      })
      .pipe(
        tap((wiki) => {
          this.toast.success(
            this.translocoService.translate('toastr.message-wiki-document-added'),
            this.translocoService.translate('toastr.title-success'),
          );
          this.addToWikis(wiki);

          if (!this.isMobile) {
            if (this.hasUnsavedChanges && this.isEditMode.value) {
              this.changeWiki(wiki);
            } else {
              this.setActiveWiki(wiki).pipe(untilDestroyed(this)).subscribe();
            }
          }
        }),
        untilDestroyed(this),
      );
  }

  public createGetStarted(data: {
    title: string;
    content: string;
    openThread: boolean;
  }): Observable<any> {
    return this.wikiApiService
      .wikiPagesCreate({
        body: {
          object: this.object,
          objectId: this.objectId,
          title: data.title,
          content: data.content,
        },
      })
      .pipe(
        tap((wiki) => {
          this.removeFromWikis({ _id: 'get-started' });
          this.addToWikis(wiki);
          this.setActiveWiki(wiki, false)
            .pipe(untilDestroyed(this))
            .subscribe(() => data.openThread && this.handleOpenThread.next(true));
        }),
        untilDestroyed(this),
      );
  }

  private removeFromWikis(wikiDelete: WikiPageDbDto | any, withoutNavigate = false): void {
    let filteredWiki: WikiPageDbDto[];
    if (wikiDelete.parentId) {
      filteredWiki = this.getWikis.value.map((wiki) => {
        if (wiki._id === wikiDelete.parentId) {
          wiki.children = wiki.children.filter((wikiPage) => wikiPage._id !== wikiDelete._id);
        }

        return wiki;
      });
    } else {
      filteredWiki = this.getWikis.value.filter((wiki) => wiki._id !== wikiDelete._id);
    }

    if (!withoutNavigate && this.activeWiki.value?._id === wikiDelete._id) {
      this.routeNavigate('');
    }

    this.setWikis(filteredWiki);
  }

  private addToWikis(wiki: WikiPageDbDto): void {
    const updatedWikis = [...this.getWikis.value, wiki];
    this.setWikis(updatedWikis);
  }

  private updateApiWiki(props, result): void {
    let updatedArray: WikiPageDbDto[];

    if (result.parentId) {
      updatedArray = this.getWikis.value.map((wiki) => {
        if (wiki._id === result.parentId) {
          wiki.children = wiki.children.map((wikiPage) =>
            wikiPage._id === result._id ? result : wikiPage,
          );
        }
        return wiki;
      });
    } else {
      updatedArray = this.getWikis.value.map((wiki) =>
        wiki._id === props.id ? { ...wiki, ...result } : wiki,
      );
    }
    this.setWikis(updatedArray);
  }

  private addPage(page: WikiPageDbDto): void {
    const wikis = this.wikis.value.map((wiki) => {
      if (wiki._id === page.parentId) {
        wiki.children ? wiki.children.push(page) : (wiki.children = [page]);
      }
      return wiki;
    });

    this.setWikis(wikis);
  }

  private routeNavigate(routePath): void {
    if (this.isMobile) {
      return;
    }

    // Create route for get started
    const route = `${this.object === ObjectEnum.Project ? ObjectEnum.ProjectRoute : ObjectEnum.SpaceRoute}/${
      this.objectId
    }/wiki/${routePath}`;

    this.router.navigate([this.routerTenantPipe.transform(route)]).then();
  }

  private getThreadMentions(users) {
    if (users) {
      this.mentionThreadMembers = [
        'all',
        ...users.filter((item) => item._id !== this.userId).map((item) => item.userName),
      ];
    }
  }

  private threadInit() {
    if (this.activeWiki.value.chatMessage?.threadId) {
      this.message.next(this.activeWiki.value.chatMessage);

      this.store.dispatch(new ThreadGetMessages({ threadId: this.message.value.threadId }));
    }
  }

  private initWiki(wiki) {
    this.hasUnsavedChanges = false;
    this.activeWiki.next(wiki);
    this.routeNavigate(wiki._id);
    this.totalCount = 0;
    this.message.next(null);
    this.handleOpenThread.next(false);
  }

  private openEditorModal() {
    this.modalService.open(WikiMobileModalComponent, {
      size: 'xl',
      centered: true,
      windowClass: 'wiki-mobile',
    });
  }

  private createNew(props): void {
    ConfirmAlert('', {
      platform: this.store.selectSnapshot(AuthState.getPlatform),
      confirmButtonClass: 'btn-solid',
      subject: this.translocoService.translate('alert.create-document-subject'),
      text: this.translocoService.translate('alert.create-document-text'),
      confirmButtonText: this.translocoService.translate('alert.btn-create'),
      cancelButtonText: this.translocoService.translate('alert.btn-discard'),
    }).then(
      () => {
        this.removeFromWikis(this.activeWiki.value, true);
        this.createDeletedData(props);
      },
      () => {
        this.hasUnsavedChanges = false;
        this.removeFromWikis(this.activeWiki.value);
        this.activeWiki.next(null);
      },
    );
  }

  private deletedWiki(wiki, route = true): void {
    ConfirmAlert('', {
      platform: this.store.selectSnapshot(AuthState.getPlatform),
      confirmButtonClass: 'btn-solid',
      subject: this.translocoService.translate('alert.deleted-document-subject'),
      showCancelButton: false,
      text: this.translocoService.translate('alert.deleted-document-text'),
      confirmButtonText: this.translocoService.translate('alert.btn-ok'),
    }).then();

    if (route) {
      this.routeNavigate('');
    } else if (wiki._id === this.wikiIdActive.value) {
      this.routeNavigate('');
    }
    this.removeFromWikis(wiki);
  }

  private changeWiki(wiki): void {
    ConfirmAlert('', {
      platform: this.store.selectSnapshot(AuthState.getPlatform),
      confirmButtonClass: 'btn-solid',
      subject: this.translocoService.translate('alert.created-document-subject'),
      text: this.translocoService.translate('alert.created-document-text'),
      confirmButtonText: this.translocoService.translate('alert.btn-save-and-continue'),
      cancelButtonText: this.translocoService.translate('alert.btn-stay-here'),
    }).then(() => {
      const title = this.editorService.getEditorTitle();
      const content = this.editorService.getEditorContent();
      this.save(title, content);
      this.setActiveWiki(wiki).pipe(untilDestroyed(this)).subscribe();
    });
  }

  public save(title: string, content: string, setActive = true, openThread = false) {
    const data: IWikiUpdate = {
      id: this.activeWiki.value?._id,
      body: {
        object: this.object,
        objectId: this.objectId,
        title,
        content,
      },
    };

    this.isSaveOrUpdate.next(true);

    if (data.id !== 'get-started') {
      this.updateWiki(data, setActive)
        .pipe(
          untilDestroyed(this),
          finalize(() => {
            this.isSaveOrUpdate.next(false);
            this.hasUnsavedChanges = false;
          }),
        )
        .subscribe(() => {
          this.removeSocket();
          this.setEditMode(false);
        });
    } else {
      this.createGetStarted({ title: title, content: content, openThread })
        .pipe(
          untilDestroyed(this),
          finalize(() => {
            this.isLoadingThreadCreate = false;
            this.isSaveOrUpdate.next(false);
            this.hasUnsavedChanges = false;
          }),
        )
        .subscribe();
    }
  }
}
