<div
  (click)="handleAction()"
  (contextmenu)="callContextMenu($event)"
  class="pinned__item-wrapper"
  placement="bottom"
  triggers="hover"
  [ngbTooltip]="tipContent"
  container="body"
  [class.pinned-item-dark]="config?.layout.variant === 'Dark'"
>
  <span class="emoji-icon" *ngIf="item.emojiName; else clipIcon">{{ item.emojiName }}</span>
  <ng-template #clipIcon>
    <svg-icon
      class="pinned__item-icon"
      src="assets/icons/common/clip.svg"
      [applyClass]="true"
    ></svg-icon>
  </ng-template>
  <span>{{ item.name }}</span>
  <svg-icon
    *ngIf="isPossibleDelete"
    (click)="$event.stopPropagation(); deletePin()"
    class="pinned__item-cross"
    src="assets/icons/common/cross.svg"
    [applyClass]="true"
  ></svg-icon>
  <ng-template #tipContent>
    <div class="tooltip-item">
      <div *ngIf="item.name" class="tooltip-name">
        {{ item.name }}
      </div>
      <div *ngIf="!item.isInternalLink" class="tooltip-url">
        {{ item.url }}
      </div>
    </div>
  </ng-template>
</div>
<app-pin-context-menu
  *ngIf="isDisplayContextMenu && !isTicketPin"
  appClickOutside
  [isPossibleDelete]="isPossibleDelete"
  (clickOutside)="clickOutside()"
  (deletePin)="deletePin()"
  (editPin)="editPin()"
  (copyUrlPin)="copyUrlPin()"
  [ngStyle]="rightClickMenuStyle"
></app-pin-context-menu>
