import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { ConfigService } from '../../services/config.service';

import { SortDirection, SortParams } from '../../data/sort-types';
import { FormsModule } from '@angular/forms';
import { TranslocoDirective } from '@ngneat/transloco';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';
import { MixpanelService } from '../../../plugins/mixpanel/mixpanel.service';

@Component({
  selector: 'app-sorting-toggle',
  templateUrl: './sorting-toggle.component.html',
  styleUrls: ['./sorting-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, TranslocoDirective, NgClass, NgSelectModule, FormsModule, AsyncPipe],
})
export class SortingToggleComponent {
  @Input() direction: SortDirection;
  @Input() sortByList = [];
  @Input() sortBy: string;
  @Output() sortChange = new EventEmitter<SortParams>();
  @ViewChild(NgSelectComponent) select: NgSelectComponent;
  config$ = this.configService.templateConf$;

  constructor(
    private configService: ConfigService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  toggleDirection(): void {
    MixpanelService.trackEvent('Sorting Toggle', {
      sortBy: this.sortBy,
      direction: this.direction,
    });
    this.direction =
      this.direction === SortDirection.Ascending
        ? SortDirection.Descending
        : SortDirection.Ascending;
    const sortParams = {
      sortBy: this.sortBy,
      direction: this.direction,
      updateDirection: true,
    };
    this.sortChange.emit(sortParams);
    this.cdr.detectChanges();
  }

  toggleSortBy(): void {
    MixpanelService.trackEvent('Sorting Toggle', {
      sortBy: this.sortBy,
      direction: this.direction,
    });
    const sortParams = { sortBy: this.sortBy, direction: this.direction };
    this.sortChange.emit(sortParams);
  }
}
